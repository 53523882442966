import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Checkbox,
  FormControl,
  FormHelperText,
  FormControlLabel,
} from "@mui/material";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useCustomSnackbar } from "../../components/CustomSnackbar";
import { Waiting } from "../../components/Waiting";
import { useMutation } from "@apollo/client";
import { DELETE_TEST_ASSET } from "../../graphql/mutations";
import { GET_TEST_ASSETS } from "../../graphql/queries";
import { deleteObjectFromCache } from "../../cache";
import { useAppContext } from "../../context/AppContext";
import * as Yup from "yup";

export default function DeleteAssetDialog(props) {
  const { t } = useTranslation();
  const { lsEnqueueSnackbar } = useCustomSnackbar();

  const { store } = useAppContext();

  const handleClose = () => {
    props.setOpenDeleteAsset(false);
    props.setActiveAsset(null);
  };

  // API to delete an asset and remove it from the cache
  const [deleteTestAsset] = useMutation(DELETE_TEST_ASSET, {
    update: (cache, results) => {
      if (results?.data) {
        deleteObjectFromCache(cache, results.data.deleteTestAsset, "id", GET_TEST_ASSETS, "getTestAssets", null, {
          orgId: store.currentOrg.org.id,
          folderId: null,
          assetStatus: [],
        });
      }
    },
  });

  let daLoading = null;
  let daData = [{}];

  const validationSchema = Yup.object({
    confirm: Yup.string().required(t("MustCheckBox")),
  });

  const formik = useFormik({
    initialValues: {
      confirm: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const vars = {
        variables: {
          orgId: store.currentOrg.org.id,
          assetId: props.activeAsset.id,
        },
      };

      // Delete the asset
      deleteTestAsset(vars)
        .then((res) => {
          if (res.errors) {
            lsEnqueueSnackbar(res.errors[0].message, {
              variant: "error",
            });
            setSubmitting(false);
          } else {
            lsEnqueueSnackbar(t("AssetDeleted"), {
              variant: "success",
            });
            setSubmitting(false);
            handleClose();
          }
        })
        .catch((e) => {
          lsEnqueueSnackbar(e.message, {
            variant: "error",
          });
          setSubmitting(false);
        }); // catch network errors
    },
  });

  let pageResults;
  if (daLoading) {
    pageResults = <Waiting />;
  } else if (daData) {
    pageResults = (
      <Dialog open={props.openDeleteAsset} onClose={handleClose} aria-labelledby="form-dialog-title">
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle id="form-dialog-title">
            {t("DeleteAsset", {
              assetName: props.activeAsset ? `'${props.activeAsset.name}'` : "",
            })}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>{t("DeleteAssetDialogText")}</DialogContentText>
            <FormControl>
              {formik.errors.confirm ? (
                <FormHelperText id="confirm" error={true}>
                  {t("MustCheckBoxAsset")}
                </FormHelperText>
              ) : (
                ""
              )}
              <FormControlLabel
                control={
                  <Checkbox
                    name="confirm"
                    type="checkbox"
                    value={formik.values.confirm}
                    onChange={formik.handleChange}
                    error={formik.touched.confirm && Boolean(formik.errors.confirm)}
                  />
                }
                label={t("ConfirmDeleteAsset")}
              />
            </FormControl>{" "}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary" variant="contained">
              {t("Cancel")}
            </Button>
            <Button type="submit" color="primary" variant="contained">
              {t("Confirm")}
            </Button>
          </DialogActions>
          {formik.isSubmitting ? <Waiting /> : ""}
        </form>
      </Dialog>
    );
  } else {
    pageResults = "";
  }

  return pageResults;
}
