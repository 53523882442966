import { useState } from "react";
import {
  Button,
  Grid,
  Typography,
  TextField,
  Dialog,
  Divider
} from "@mui/material";

import { useTranslation } from "react-i18next";
import { useCustomSnackbar } from "../../../components/CustomSnackbar";
import { SEND_UPDATE_ORG_SEND_EMAIL_RESULT } from "../../../graphql/mutations";
import { useMutation } from "@apollo/client";
import { useAppContext } from "../../../context/AppContext";
import ChangeSendEmailForm from "./ChangeSendEmailForm";
import ConfirmationForm from "../../../components/ConfirmationForm";
import OrgEmailTemplatesForm from "./OrgEmailTemplatesForm";

const systemSendEmailAddress = "notifications@examplery.com";


export default function OrgEmailForm({
  opData
}) {
  const { t } = useTranslation();
  const { lsEnqueueSnackbar } = useCustomSnackbar();
  const [customSendEmailPending, setCustomSendEmailPending] =
    useState(opData?.orgProfile?.customSendEmailPending);
  const [openUpdateSendEmailDialog, setOpenUpdateSendEmailDialog] = useState(false);
  const [openCancelPendingSendEmailConfirmation, setOpenCancelPendingSendEmailConfirmation] = useState(false);

  const { store } = useAppContext();

  // Set up the update user profile API
  const [sendUpdateOrgSendEmailResult] = useMutation(SEND_UPDATE_ORG_SEND_EMAIL_RESULT);

  const handleSendEmailAddressChange = () => {
    setOpenUpdateSendEmailDialog(true);
  }

  const handleCancelPendingSendEmailAddressChange = () => {
    setOpenCancelPendingSendEmailConfirmation(true);
  }

  const handleCloseCancelPendingSendEmailConfirmation = async (response) => {
    if (response === "Confirm") {
      // Send a cancel request to the workflow
      try {
        let res = await sendUpdateOrgSendEmailResult({
          variables: {
            orgId: store.currentOrg.org.id,
            result: {
              status: "cancelled"
            }
          }
        });
        if (res.errors) {
          lsEnqueueSnackbar(res.errors[0].message, {
            variant: "error",
          });
        } else {
          setCustomSendEmailPending("");
        }
      } catch (e) {
        lsEnqueueSnackbar(e.message, {
          variant: "error",
        });
      }
      setCustomSendEmailPending("");
    }
    setOpenCancelPendingSendEmailConfirmation(false);
  }

  const handleCloseUpdateSendEmailDialog = () => {
    setOpenUpdateSendEmailDialog(false);
  }

  const handleSetCustomSendEmailPending = (value) => {
    setCustomSendEmailPending(value);
  };

  let pageResults;
  if (opData) {
    pageResults = (
      <>
        <Grid item xs={12}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <Typography variant="h6" sx={{mt: 3}}>
                {t("SendEmailAddress")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                fullWidth
                id="sendemailaddress"
                label={t("SendEmailAddress")}
                name="sendemailaddress"
                value={opData?.orgProfile?.customSendEmail || systemSendEmailAddress}
                disabled={true}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="outlined"
                color="primary"
                onClick={customSendEmailPending ?
                  handleCancelPendingSendEmailAddressChange :
                  handleSendEmailAddressChange}
              >
                {customSendEmailPending ?
                  t("CancelPendingSendEmailAddressChange") :
                  t("ChangeSendEmailAddress")}
              </Button>
            </Grid>
          </Grid>
          {customSendEmailPending && (
            <Grid item xs={12}>
              <Typography
                variant="body2" // Smaller text size
                color="textSecondary" // Different color
                sx={{ ml: 2, mt: 1 }} // Add some margin for better spacing
              >
                {customSendEmailPending} ({t("PendingEmailVerification")})
              </Typography>
            </Grid>
          )}
        </Grid>
        <Divider/>
        <Grid item xs={12}>
          <OrgEmailTemplatesForm/>
        </Grid>
        <ChangeSendEmailForm
          open={openUpdateSendEmailDialog}
          handleClose={handleCloseUpdateSendEmailDialog}
          orgId={store.currentOrg.org.id}
          currentCustomSendEmail={opData?.orgProfile?.customSendEmail}
          setCustomSendEmailPending={handleSetCustomSendEmailPending}
          systemSendEmailAddress={systemSendEmailAddress}
        />
        <Dialog
          open={openCancelPendingSendEmailConfirmation}
          onClose={handleCloseCancelPendingSendEmailConfirmation}
        >
          <ConfirmationForm
            handleConfirmationClose={handleCloseCancelPendingSendEmailConfirmation}
            dialogTitle={t("ConfirmCancelPendingSendEmailChange")}
            dialogContent={t("ConfirmCancelPendingSendEmailText")}
          />
        </Dialog>
      </>
    );
  } else {
    pageResults = "";
  }
  return <div>{pageResults}</div>;
}
