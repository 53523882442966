import React from "react";
import { Waiting } from "../Waiting";
import { useAppContext } from "../../context/AppContext";
import { TestTemplateStatus } from "../../graphql/enums";
import DashboardAvailableToAssign from "./DashboardAvailableToAssign";
import DashboardAssignedByMe from "./DashboardAssignedByMe";
import { useCustomSnackbar } from "../CustomSnackbar";
import { useQuery, useSubscription } from "@apollo/client";
import { handleCacheUpdate } from "../../cache";
import { GET_TEST_TEMPLATES, GET_TEST_INSTANCES_ASSIGNED_BY_ME } from "../../graphql/queries";
import { ON_TESTINSTANCEASSIGNEDBYMEUPDATE_SUBSCRIPTION } from "../../graphql/subscriptions";
import { apolloSubClient } from "../../identity";

export default function DashboardAssignors({activeTab}) {
  const { store } = useAppContext();
  const { lsEnqueueSnackbar } = useCustomSnackbar();

  // Fetch test templates
  const {
    data: utData,
    loading: utLoading,
    refetch: refreshTestTemplates,
  } = useQuery(GET_TEST_TEMPLATES, {
    variables: {
      orgId: store.currentOrg?.org.id,
      templateStatus: [
        TestTemplateStatus.Draft,
        TestTemplateStatus.Active,
        TestTemplateStatus.Deprecated,
        TestTemplateStatus.Retired
      ],
    },
    onError: (e) => {
      lsEnqueueSnackbar(e.message, {
        variant: "error",
      });
    },
  });

  // Fetch test assignments
  const {
    data: taData,
    loading: taLoading,
    refetch: refreshAssignedTestInstances,
  } = useQuery(GET_TEST_INSTANCES_ASSIGNED_BY_ME, {
    variables: {
      orgId: store.currentOrg?.org.id,
      options: {
        filtering: store.assignedByMeFilter?.length > 0 ? store.assignedByMeFilter : null,
      }
    },
    onError: (e) => {
      lsEnqueueSnackbar(e.message, {
        variant: "error",
      });
    },
    fetchPolicy: "network-only"
  });

  useSubscription(ON_TESTINSTANCEASSIGNEDBYMEUPDATE_SUBSCRIPTION, {
    variables: {
      orgId: store.currentOrg?.org.id
    },
    shouldResubscribe: true,
    fetchPolicy: "no-cache",
    client: apolloSubClient,
    onSubscriptionData: (options) => {
      let payload = options.subscriptionData.data.onTestInstanceAssignedByMeUpdate; 
      handleCacheUpdate(
        payload.objectUpdate.action,
        options.client,
        payload.testInstance,
        "id",
        GET_TEST_INSTANCES_ASSIGNED_BY_ME,
        "getTestInstancesAssignedByMe",
        null,
        {
          orgId: store.currentOrg?.org.id,
          options: {
            filtering: store.assignedByMeFilter?.length > 0 ? store.assignedByMeFilter : null,
          }
        },
        payload.objectUpdate.message,
        lsEnqueueSnackbar
      );
    },
  });

  let pageContents;
  if (utLoading || taLoading) {
    pageContents = <Waiting />;
  } else {
    pageContents = (
      <React.Fragment>
        <DashboardAvailableToAssign 
          activeTab={activeTab}
          utData={utData}
          utLoading={utLoading}
          refreshTestTemplates={refreshTestTemplates}
         />
        <DashboardAssignedByMe 
          activeTab={activeTab} 
          utData={utData}
          utLoading={utLoading} 
          taData={taData}
          taLoading={taLoading}
          refreshAssignedTestInstances={refreshAssignedTestInstances} 
        />
      </React.Fragment>  
    );
  }

  return pageContents;
}
