/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

const LevelIndicator = ({ left = 0, folder = false }) => {
  return <div className={folder ? "folder" : null} css={styles(left).levelIndicator}></div>;
};

// position: absolute;
// top: 0;
// bottom: 0;
// left: ${28 + left}px;
// border-left: 1px solid #e0e0e0;
// z-index: 0;

const styles = (left) => ({
  levelIndicator: css`
    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: ${28 + left}px;
      border-left: 1px solid #e0e0e0;
    }
    &.folder::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 70%;
      left: ${28 + left}px;
      border-left: 1px solid #e0e0e0;
    }
    &.folder::after {
      content: "";
      position: absolute;
      top: 70%;
      bottom: 0;
      left: ${28 + left}px;
      border-left: 1px solid #e0e0e0;
    }
  `,
});

export default LevelIndicator;
