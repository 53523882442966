import React, { useState } from "react";
import {
  Button,
  InputLabel,
  Grid,
  FormControl,
  MenuItem,
  Avatar,
  TextField,
  Select,
} from "@mui/material";
import PaletteIcon from "@mui/icons-material/Palette";
import { SketchPicker } from "react-color";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useCustomSnackbar } from "../../../components/CustomSnackbar";
import { UPDATE_ORG_PROFILE } from "../../../graphql/mutations";
import { useMutation } from "@apollo/client";
import { copySiteConfig } from "../../../siteConfig";
import { useAppContext } from "../../../context/AppContext";
import AddAssetForm from "../../HiringManager/AddAsset";


export default function OrgDetailsForm({
  siteConfig,
  setSiteConfig,
  siteConfigRef,
  setOrgName,
  opData,
  asData
}) {
  const inputLabel = React.useRef(null);
  const { t } = useTranslation();
  const { lsEnqueueSnackbar } = useCustomSnackbar();
  const [displayColorPrimaryPicker, setDisplayColorPrimaryPicker] = useState(false);
  const [displayColorSecondaryPicker, setDisplayColorSecondaryPicker] = useState(false);
  const [displayColorThirdPicker, setDisplayColorThirdPicker] = useState(false);
  const [isUploadSplashPageOpen, setIsUploadSplashPageOpen] = useState(false);
  let assetsData = [];

  const { store } = useAppContext();

  // Set up the update user profile API
  const [updateOrgProfile] = useMutation(UPDATE_ORG_PROFILE);

  // Handles opening, closing and state setting for the two color pickers
  const handleClickPrimaryColor = () => {
    setDisplayColorPrimaryPicker(true);
  };

  const handleClosePrimaryColor = () => {
    setDisplayColorPrimaryPicker(false);
  };

  const handleClickSecondaryColor = () => {
    setDisplayColorSecondaryPicker(true);
  };

  const handleCloseSecondaryColor = () => {
    setDisplayColorSecondaryPicker(false);
  };

  const handleClickThirdColor = () => {
    setDisplayColorThirdPicker(true);
  };

  const handleCloseThirdColor = () => {
    setDisplayColorThirdPicker(false);
  };

  const handlePrimaryColorChange = (color, event) => {
    formik.setFieldValue("primarycolor", color.hex);
  };

  const handleSecondaryColorChange = (color, event) => {
    formik.setFieldValue("secondarycolor", color.hex);
  };

  const handleThirdColorChange = (color, event) => {
    formik.setFieldValue("thirdcolor", color.hex);
  };

  const handleSplashPageUploadSuccess = (asset) => {
    formik.setFieldValue("customsplashpage", asset);
  };

  const handleSelectSplashPageChange = (event) => {
    const { value } = event.target;
    if (value === 'upload-new') {
      setIsUploadSplashPageOpen(true);
    } else if (value === 'unset-splashpage') {
      handleSplashPageUploadSuccess(null);
    } else {
      formik.setFieldValue("customsplashpage", value);
    }
  };

  // Additional styles needed for color picker
  const popover = {
    position: "absolute",
    zIndex: "2",
  };

  const cover = {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  };

  // Used to mask region string
  const regionLookup = {
    "us-west-2": "Oregon",
    "us-west-1": "California",
    "us-east-2": "Ohio",
    "us-east-1": "Virginia",
    "ca-central-1": "Central Canada",
  };

  const validationSchema = Yup.object({
    name: Yup.string().max(100, t("OneHundredOrFewerChars")).required(t("Required"))
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: opData?.orgProfile?.name ? opData.orgProfile.name.trim() : "",
      address: opData?.orgProfile?.address ? opData.orgProfile.address.trim() : "",
      city: opData?.orgProfile?.city ? opData.orgProfile.city.trim() : "",
      state: opData?.orgProfile?.state ? opData.orgProfile.state.trim() : "",
      postalcode: opData?.orgProfile?.postalcode ? opData.orgProfile.postalcode.trim() : "",
      country: opData?.orgProfile?.country ? opData.orgProfile.country.trim() : "",
      language: opData?.orgProfile?.language ? opData.orgProfile.language.trim() : "",
      timezone: opData?.orgProfile?.timezone ? opData.orgProfile.timezone.trim() : "",
      region: opData?.orgProfile?.region ? regionLookup[opData.orgProfile.region.trim()] : "",
      primarycolor: opData?.orgProfile?.brandcolors.primarycolor,
      secondarycolor: opData?.orgProfile?.brandcolors.secondarycolor,
      thirdcolor: opData?.orgProfile?.brandcolors.thirdcolor,
      customsplashpage: opData?.orgProfile?.customsplashpage
        ? asData?.getTestAssets?.find(asset => asset.id === opData?.orgProfile.customsplashpage)
        : "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const vars = {
        variables: {
          orgId: store.currentOrg.org.id,
          op: {
            brandcolors: {
              primarycolor: values.primarycolor,
              secondarycolor: values.secondarycolor,
              thirdcolor: values.thirdcolor
            },
            name: values.name,
            address: values.address,
            city: values.city,
            state: values.state,
            postalcode: values.postalcode,
            country: values.country,
            language: values.language,
            timezone: values.timezone,
            customsplashpage: values.customsplashpage?.id || null,
          },
        },
      };

      // Update the org profile on the server
      updateOrgProfile(vars)
        .then((res) => {
          if (res.data) {
            lsEnqueueSnackbar(t("OrgUpdateSuccessful"), {
              variant: "success",
            });

            // if this is an org site, update the site configuration.
            if (siteConfig.orgId) {
              let newSiteConfig = copySiteConfig(siteConfig);
              newSiteConfig.siteName = values.name;
              newSiteConfig.brandcolors = {
                primarycolor: vars.op.brandcolors.primarycolor,
                secondarycolor: vars.op.brandcolors.secondarycolor,
                thirdcolor: vars.op.brandcolors.thirdcolor
              };
              setSiteConfig(newSiteConfig);
              siteConfigRef.current = newSiteConfig;
            }
            setOrgName(values.name);
            // navigate("/app");
          } else if (res.errors) {
            lsEnqueueSnackbar(res.errors[0].message, {
              variant: "error",
            });
            setSubmitting(false);
          }
        })
        .catch((e) => {
          lsEnqueueSnackbar(e.message, {
            variant: "error",
          });
          setSubmitting(false);
        }); // catch network errors
    },
  });

  let pageResults;
  if (opData && asData) {
    assetsData = asData.getTestAssets.slice();
    pageResults = (
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={1}>
                <Avatar
                  variant="square"
                  style={{ backgroundColor: formik.values.primarycolor }}
                >
                  <PaletteIcon />
                </Avatar>
              </Grid>
              <Grid item xs={11}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleClickPrimaryColor}
                >
                  {t("SelectPrimaryColor")}
                </Button>
                {displayColorPrimaryPicker ? (
                  <div style={popover}>
                    <div style={cover} onClick={handleClosePrimaryColor} />
                    <SketchPicker
                      color={formik.values.primarycolor}
                      onChangeComplete={handlePrimaryColorChange}
                    />
                  </div>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={1}>
                <Avatar
                  variant="square"
                  style={{ backgroundColor: formik.values.secondarycolor }}
                >
                  <PaletteIcon />
                </Avatar>
              </Grid>
              <Grid item xs={11}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleClickSecondaryColor}
                >
                  {t("SelectSecondaryColor")}
                </Button>
                {displayColorSecondaryPicker ? (
                  <div style={popover}>
                    <div style={cover} onClick={handleCloseSecondaryColor} />
                    <SketchPicker
                      color={formik.values.secondarycolor}
                      onChangeComplete={handleSecondaryColorChange}
                    />
                  </div>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={1}>
                <Avatar
                  variant="square"
                  style={{ backgroundColor: formik.values.thirdcolor }}
                >
                  <PaletteIcon />
                </Avatar>
              </Grid>
              <Grid item xs={11}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleClickThirdColor}
                >
                  {t("SelectThirdColor")}
                </Button>
                {displayColorThirdPicker ? (
                  <div style={popover}>
                    <div style={cover} onClick={handleCloseThirdColor} />
                    <SketchPicker
                      color={formik.values.thirdcolor}
                      onChangeComplete={handleThirdColorChange}
                    />
                  </div>
                ) : null}
              </Grid>
            </Grid>
          </Grid>          
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="name"
              label={t("OrgName")}
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </Grid>

          {/* <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="address"
                  label={t("Address")}
                  name="address"
                  value={formik.values.address}
                  onChange={formik.handleChange}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="city"
                  label={t("City")}
                  name="city"
                  value={formik.values.city}
                  onChange={formik.handleChange}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl
                  variant="outlined"
                  sx={{
                    margin: 0,
                    width: "100%",
                    display: "flex",
                    flexWrap: "nowrap",
                  }}
                >
                  <InputLabel ref={inputLabel} id="state-label">
                    {t("State")}
                  </InputLabel>
                  <Select
                    label={t("State")}
                    labelId="state-label"
                    id="state"
                    name="state"
                    value={formik.values.state}
                    onChange={formik.handleChange}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={"California"}>California</MenuItem>
                    <MenuItem value={"Ontario"}>Ontario</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="postalcode"
                  label={t("PostalCode")}
                  name="postalcode"
                  value={formik.values.postalcode}
                  onChange={formik.handleChange}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl
                  variant="outlined"
                  sx={{
                    margin: 0,
                    width: "100%",
                    display: "flex",
                    flexWrap: "nowrap",
                  }}
                >
                  <InputLabel ref={inputLabel} id="country-label">
                    {t("Country")}
                  </InputLabel>
                  <Select
                    label={t("Country")}
                    labelId="country-label"
                    id="country"
                    name="country"
                    defaultValue=""
                    value={formik.values.country}
                    onChange={formik.handleChange}
                  >
                    <MenuItem value={"Canada"}>{t("Canada")}</MenuItem>
                    <MenuItem value={"UnitedStates"}>{t("UnitedStates")}</MenuItem>
                  </Select>
                </FormControl>
              </Grid> */}

          <Grid item xs={12}>
            <FormControl
              variant="outlined"
              sx={{
                margin: 0,
                width: "100%",
                display: "flex",
                flexWrap: "nowrap",
              }}
            >
              <InputLabel ref={inputLabel} id="language-label">
                {t("PreferredLanguage")}
              </InputLabel>
              <Select
                label={t("PreferredLanguage")}
                labelId="language-label"
                id="language"
                name="language"
                defaultValue=""
                value={formik.values.language}
                onChange={formik.handleChange}
              >
                <MenuItem value={"English"}>{t("English")}</MenuItem>
                <MenuItem value={"French"}>{t("French")}</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* <Grid item xs={12}>
                <FormControl
                  variant="outlined"
                  sx={{
                    margin: 0,
                    width: "100%",
                    display: "flex",
                    flexWrap: "nowrap",
                  }}
                >
                  <InputLabel ref={inputLabel} id="timezone-label">
                    {t("PreferredTimeZone")}
                  </InputLabel>
                  <Select
                    label={t("PreferredTimeZone")}
                    labelId="timezone-label"
                    id="timezone"
                    name="timezone"
                    defaultValue=""
                    value={formik.values.timezone}
                    onChange={formik.handleChange}
                  >
                    <MenuItem value={"AST"}>AST</MenuItem>
                    <MenuItem value={"EST"}>EST</MenuItem>
                    <MenuItem value={"CST"}>CST</MenuItem>
                    <MenuItem value={"MST"}>MST</MenuItem>
                    <MenuItem value={"PST"}>PST</MenuItem>
                  </Select>
                </FormControl>
              </Grid> */}

          <Grid item xs={12}>
            <FormControl
              variant="outlined"
              sx={{
                margin: 0,
                width: "100%",
                display: "flex",
                flexWrap: "nowrap",
              }}
            >
              <InputLabel id="org-splashpage-label">
                {t("OrgSplashPage")}
              </InputLabel>
              <Select
                label={t("OrgSplashPage")}
                labelId="org-splashpage-label"
                id="customsplashpage"
                name="customsplashpage"
                defaultValue=""
                value={formik.values.customsplashpage || ''}
                onChange={handleSelectSplashPageChange}
              >
                {formik.values.customsplashpage && <MenuItem value="unset-splashpage" sx={{ fontWeight: 'bold' }}>{t("RemoveSplashPage")}</MenuItem>}

                <MenuItem value="upload-new" sx={{ fontWeight: 'bold' }}>{t("UploadNewSplashPage")}</MenuItem>
                {assetsData
                  .filter((i) => i.mimetype === 'text/html')
                  .map((i, index) => (
                    <MenuItem key={index} value={i}>
                      {i.name}
                    </MenuItem>
                  )
                  )}
              </Select>
            </FormControl>
          </Grid>
          <Grid container justifyContent="flex-end">
            <Grid item sx={{ textAlign: "right" }}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                sx={{ marginTop: 3, marginRight: 0, marginBottom: 1.5 }}
              >
                {t("Update")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <AddAssetForm
          isAddAssetOpen={isUploadSplashPageOpen}
          setIsAddAssetOpen={setIsUploadSplashPageOpen}
          onUploadSuccess={handleSplashPageUploadSuccess}
        />
      </form>
    );
  } else {
    pageResults = "";
  }
  return <div>{pageResults}</div>;
}
