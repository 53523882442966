import React from "react";
import ReactDOM from "react-dom";
import "./i18n";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { initApollo } from "./initApollo";
import { ApolloProvider } from "@apollo/client";
import { AppContextWrapper } from "./context/AppContext";
import { AppStreamContextWrapper } from "./context/AppStreamContext";

/**
  Bumping the version so I'm sure the latest
  changes are the ones I'm seeing on the server
*/
console.log("Version: 0.0.7");

const client = initApollo();
ReactDOM.render(
  <ApolloProvider client={client}>
    <AppContextWrapper>
      <AppStreamContextWrapper>
        <App />
      </AppStreamContextWrapper>
    </AppContextWrapper>
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
