import { Grid } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../context/AppContext";
import { useCustomSnackbar } from "./CustomSnackbar";
import useGuardedRoute from "../hooks/useGuardedRoute";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import FileSystem, {
  FileSystemItemType,
  rootFolderName,
  rootFolderPath
} from "./FileSystem/FileSystem";
import { FileTree } from "./FileSystem/FileTree";

const TestTemplateFileBrowser = ({
  testInputs, 
  emptyFolders, 
  onUpdate, 
  selectedItem, 
  setSelectedItem
}) => {
  const { t } = useTranslation();
  const { lsEnqueueSnackbar } = useCustomSnackbar();
  const navigate = useNavigate();

  const { store, actions } = useAppContext();
  const defaultFolderNames = [];

  useGuardedRoute("/app", [store.currentTestTemplate]);

  function getDefaultFolders() {
    return defaultFolderNames.map((defaultFolderName) => {
      return {
        filePath: "/" + defaultFolderName,
        isFolder: true,
        fullpath: "/" + defaultFolderName,
      }
    });
  }

  function transformFiles() {
    const defaultFolders = getDefaultFolders();
    const files = testInputs.map((testInput) => {
      let path = ((testInput.targetPath === "/") ? "" : testInput.targetPath) + 
        (testInput?.asset ? ("/" + testInput.asset.filename) : "");     
      if (path.charAt(0) !== "/") {
        path = "/" + path;
      }
      return {
        id: rootFolderPath + path,
        title: testInput.asset.filename,
        type: (testInput.asset.isFolder) ? FileSystemItemType.AssetFolder :
                                           FileSystemItemType.File,
        path: path,
        children: null,
        testInput: testInput
      }
    });
    let allFiles = [];
    if (defaultFolders) {
      allFiles = allFiles.concat(defaultFolders.map(folder => {
        let folderName = folder.filePath.substring(folder.filePath.lastIndexOf("/") + 1);
        return {
          id: folder.filePath,
          title: folderName,
          type: FileSystemItemType.CreatedFolder,
          path: folder.filePath,
          children: [],
          immutable: true
        }
      }));
    }
    if (emptyFolders) {
      allFiles = allFiles.concat(emptyFolders.map(folder => {
        let path = folder.targetPath || folder.fullpath;
        if (path.charAt(0) !== "/") {
          path = "/" + path;
        }
        let folderName = path.substring(path.lastIndexOf("/") + 1);
        return {
          id: rootFolderPath + path,
          title: folderName,
          type: FileSystemItemType.CreatedFolder,
          path: path,
          children: [],
        }
      }));
    }
    if (files) {
      allFiles = allFiles.concat(files);
    }
    return new FileTree(allFiles, rootFolderPath);
  }

  function onMove(transformedFiles, item, itemOld) {
    let defaultFolders = getDefaultFolders();
    let newTestInputs = collectTestInputs(transformedFiles);
    let allEmptyFolders = collectEmptyFolders(transformedFiles);
    let emptyFolders = allEmptyFolders.filter((folder) => {
      for (let defaultFolder of defaultFolders) {
        if (JSON.stringify(folder) === JSON.stringify(defaultFolder)) {
          return false;
        }
      }
      return true;
    });

    onUpdate(newTestInputs, emptyFolders);
  }

  function onCreateFolder(folder) {
    let newEmptyFolderList = [{
      filePath: folder.path,
      isFolder: true,
      fullpath: folder.path,
    }];
    if (emptyFolders) {
      newEmptyFolderList = newEmptyFolderList.concat(emptyFolders);
    }
    onUpdate(testInputs, newEmptyFolderList);
  }

  function onDelete(newFiles, item) {
    let defaultFolders = getDefaultFolders();
    let newTestInputs = collectTestInputs(newFiles);
    let allEmptyFolders = collectEmptyFolders(newFiles);
    let emptyFolders = allEmptyFolders.filter((folder) => {
      for (let defaultFolder of defaultFolders) {
        if (JSON.stringify(folder) === JSON.stringify(defaultFolder)) {
          return false;
        }
      }
      return true;
    });

    onUpdate(newTestInputs, emptyFolders);
  }

  function onRenameFolder(newFiles, newItemName) {
    let defaultFolders = getDefaultFolders();
    let newTestInputs = collectTestInputs(newFiles);
    let allEmptyFolders = collectEmptyFolders(newFiles);
    let emptyFolders = allEmptyFolders.filter((folder) => {
      for (let defaultFolder of defaultFolders) {
        if (JSON.stringify(folder) === JSON.stringify(defaultFolder)) {
          return false;
        }
      }
      return true;
    });

    onUpdate(newTestInputs, emptyFolders);

  }

  function collectTestInputs(node) {
    let newTestInputs = [];
    if (node?.testInput) {
      let prunedPath = node.path.substring(1);
      node.testInput.targetPath = prunedPath.substring(0, prunedPath.lastIndexOf("/"));
      newTestInputs.push(node.testInput);
    }
    if (node?.children) {
      for (let childNode of node.children) {
        newTestInputs = newTestInputs.concat(collectTestInputs(childNode));
      }
    }
    return newTestInputs;
  }

  function collectEmptyFolders(node) {
    let emptyFolders = [];
    if (isEmptyFolder(node)) {
      emptyFolders.push({
        filePath: node.path,
        isFolder: true,
        fullpath: node.path,
      });
    }
    if (node?.children) {
      for (let childNode of node.children) {
        emptyFolders = emptyFolders.concat(collectEmptyFolders(childNode));
      }
    }
    return emptyFolders;
  }

  function isEmptyFolder(node) {
    // return (node?.type === "createdFolder" && node?.children?.length === 0); 
    if (node?.type === "createdFolder") {
      if (node?.children?.length === 0) {
        return true;
      } else {
        for (let child of node.children) {
          if (child.type !== "createdFolder") {
            return false;
          } else {
            return isEmptyFolder(child);
          }
        }
      }
    } else {
      return false;
    }
  }


  let pageResults;
  if (testInputs) {
    let transformedFiles = transformFiles();
    pageResults = (
      <>
        <DndProvider backend={HTML5Backend}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FileSystem
                    data={transformedFiles}
                    draggable={true}
                    droppable={true}
                    selectable={true}
                    onDelete={onDelete}
                    style={{ height: "250px" }}
                    onMove={onMove}
                    onCreateFolder={onCreateFolder}
                    onRenameFolder={onRenameFolder}
                    rootFolderName={rootFolderName}
                    selectedItem={selectedItem}
                    setSelectedItem={setSelectedItem}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DndProvider>
      </>
    );
  } else {
    pageResults = "";
  }

  return pageResults;
};

export default TestTemplateFileBrowser;
