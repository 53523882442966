import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Grid, Typography, TextField } from "@mui/material";
import { Waiting } from "../../components/Waiting";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useCustomSnackbar } from "../../components/CustomSnackbar";
import { usePasswordReqs } from "../../components/PasswordReqs";
import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";

export default function ResetPasswordForm(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { lsEnqueueSnackbar } = useCustomSnackbar();

  // Set up the reset password mutation
  const RESET_PASSWORD = gql`
    mutation resetPassword($org: GUID, $cred: Credentials!, $code: String!) {
      resetPassword(orgId: $org, credentials: $cred, confirmationCode: $code)
    }
  `;
  const [
    resetPassword,
    { loading: resetPasswordLoading, error: resetPasswordError },
  ] = useMutation(RESET_PASSWORD);

  let passwordReqs;
  passwordReqs = usePasswordReqs(props.siteConfig);

  const validationSchema = Yup.object({
    username: Yup.string().required(t("Required")),
    verificationcode: Yup.string().required(t("Required")),
    password: passwordReqs,
    passwordconfirm: Yup.string()
      .oneOf([Yup.ref("password"), null], t("PasswordsMustMatch"))
      .required(t("Required")),
  });

  const formik = useFormik({
    initialValues: {
      verificationcode: "",
      username: props.currentUsername ? props.currentUsername : "",
      password: "",
      passwordconfirm: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const vars = {
        variables: {
          org: props.siteConfig.orgId,
          cred: {
            username: values.username.trim(),
            password: values.password,
          },
          code: values.verificationcode.trim(),
        },
      };

      // Complete reset of password and navigate user back to sign in
      resetPassword(vars)
        .then((res) => {
          if (res.errors) {
            lsEnqueueSnackbar(res.errors[0].message, {
              variant: "error",
            });
            setSubmitting(false);
          } else {
            lsEnqueueSnackbar(t("PasswordReset"), {
              variant: "success",
            });
            navigate("/app/signin");
          }
        })
        .catch((e) => {
          lsEnqueueSnackbar(e.message, {
            variant: "error",
          });
          setSubmitting(false);
        });
    },
  });

  let pageContent;
  if (resetPasswordLoading) {
    pageContent = <Waiting />;
  } else if (!resetPasswordError) {
    pageContent = (
      <div>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2} paddingLeft="20%" paddingRight="20%">
            <Grid item xs={12}>
              <Typography
                component="h1"
                variant="h5"
                sx={{ marginBottom: 3, fontWeight: "bold" }}
              >
                {t("ResetPassword")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography component="p" sx={{ marginBottom: 3 }}>
                {t("PasswordResetForm")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="username"
                label={t("UserName")}
                name="username"
                value={formik.values.username}
                onChange={formik.handleChange}
                error={
                  formik.touched.username && Boolean(formik.errors.username)
                }
                helperText={formik.touched.username && formik.errors.username}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="verificationcode"
                label={t("VerificationCode")}
                id="verificationcode"
                value={formik.values.verificationcode}
                onChange={formik.handleChange}
                error={
                  formik.touched.verificationcode &&
                  Boolean(formik.errors.verificationcode)
                }
                helperText={
                  formik.touched.verificationcode &&
                  formik.errors.verificationcode
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label={t("Password")}
                type="password"
                id="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="passwordconfirm"
                label={t("ConfirmPassword")}
                type="password"
                id="passwordconfirm"
                value={formik.values.passwordconfirm}
                onChange={formik.handleChange}
                error={
                  formik.touched.passwordconfirm &&
                  Boolean(formik.errors.passwordconfirm)
                }
                helperText={
                  formik.touched.passwordconfirm &&
                  formik.errors.passwordconfirm
                }
              />
            </Grid>

            <Grid container justifyContent="flex-end">
              <Grid item sx={{ textAlign: "right" }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{ marginTop: 3, marginRight: 0, marginBottom: 1.5 }}
                >
                  {t("ResetPassword")}
                </Button>
                <br />
                <Link to="/app/forgotpassword">{t("DidntReceiveEmail")}</Link>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </div>
    );
  } else {
    pageContent = <Typography>{resetPasswordError.message}</Typography>;
  }
  return pageContent;
}
