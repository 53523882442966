import { useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";

/**
 * A hook that checks if all defined dependencies are present (truthy!) for a route, and if not, it will
 * redirect to the route specified in routeTo.
 *
 * Use case: The user accesses a route directly, but the client doesn't have any data present for the route
 * e.g. visiting /app/edittesttemplate, the client doesn't know which template should set for editing, as no
 * data is present either as a query parameter nor as a deeplink. Without the guard, the route will show error.
 *
 * Caveat:
 * This hook simply checks for falsy values and it has limited application. It does the job well
 * to check if some property in the global state is present or null, but obviously, it won't
 * do the job if one member in the dependency array has type "number" and value of 0.
 *
 * @param {string} routeTo
 * @param {array} dependencyArray An array of variables that should be checked for falsy values
 */
const useGuardedRoute = (routeTo, dependencyArray) => {
  const navigate = useNavigate();
  useLayoutEffect(() => {
    const shouldGuard = dependencyArray.some((item) => {
      return !!!item;
    });

    if (shouldGuard) {
      navigate(routeTo);
    }
  }, [dependencyArray, routeTo, navigate]);
};

export default useGuardedRoute;
