import { GET_TEST_INSTANCE_SESSION_URL } from "../graphql/mutations";
import { useMutation } from "@apollo/client";
import { useAppContext } from "../context/AppContext";
import { v4 as uuidv4 } from "uuid";
import axios from 'axios';
import { useEffect, useState, useCallback, useRef } from "react";

const MAX_FETCH_ATTEMPTS = 10;
const FETCH_ATTEMPT_INTERVAL = 2000; // ms

const useSessionUrl = ({
  readOnly = false
}) => {
  const { store } = useAppContext();
  const [testInstanceSessionUrl, setTestInstanceSessionUrl] = useState(null);
  const [testInstanceSessionUrlError, setTestInstanceSessionUrlError] = useState(null);
  const [testInstanceSessionUrlLoading, setTestInstanceSessionUrlLoading] = useState(false);
  const trialError = useRef(null);
  const [getTestInstanceSessionUrl] = useMutation(
    GET_TEST_INSTANCE_SESSION_URL
  );

  const currentTestTemplate = (store.currentTestInstance?.testTemplate?.org) ? 
    store.currentTestInstance.testTemplate : store.currentTestInstanceTemplate;
  
  const fetchTestInstanceSessionUrl = useCallback((location, readOnly, fetchAttempts = 1) => {
    if (fetchAttempts <= MAX_FETCH_ATTEMPTS) {
      /**
       * Sometimes when the user accepts a test instance and lands on this page, the
       * url for the test instance is not yet ready. For that reason, we need to implement
       * a retry mechanism that will keep on trying to fetch the url until it succeeds.
       * We can limit the number of tries to an arbitrary value of 10 and we can try
       * making each request 2 seconds after the previous one has finished.
       */
      getTestInstanceSessionUrl({
        variables: {
          orgId: currentTestTemplate?.org?.id,
          instanceId: store.currentTestInstance.id,
          location: location,
          readOnly: readOnly
        },
      })
        .then((res) => {
          if (res.errors) {
            // log it in console
            console.log("error: ", res.errors[0]);
            trialError.current = res.errors[0];
            console.log(
              `Fetching session URL failed. Attempt ${fetchAttempts} of ${MAX_FETCH_ATTEMPTS}`
            );
            if (fetchAttempts <= MAX_FETCH_ATTEMPTS)
              console.log(`Retrying in ${FETCH_ATTEMPT_INTERVAL}ms.`);

            setTimeout(
              () => fetchTestInstanceSessionUrl(location, readOnly, fetchAttempts + 1),
              FETCH_ATTEMPT_INTERVAL
            );
          } else {
            setTestInstanceSessionUrl(res.data.getTestInstanceSessionUrl);
            setTestInstanceSessionUrlLoading(false);
          }
        })
        .catch((e) => {
          // log it in console
          console.log("error: ", e);
          trialError.current = e;
          console.log(
            `Fetching session URL failed. Attempt ${fetchAttempts} of ${MAX_FETCH_ATTEMPTS}`
          );
          if (fetchAttempts < MAX_FETCH_ATTEMPTS)
            console.log(`Retrying in ${FETCH_ATTEMPT_INTERVAL}ms.`);

          setTimeout(
            () => fetchTestInstanceSessionUrl(location, readOnly, fetchAttempts + 1),
            FETCH_ATTEMPT_INTERVAL
          );
        });
    } else {
      setTestInstanceSessionUrlError(trialError.current);
      setTestInstanceSessionUrlLoading(false);
    }
  }, [
    getTestInstanceSessionUrl,
    setTestInstanceSessionUrl,
    store.currentTestInstance?.id,
    currentTestTemplate?.org?.id,
    trialError
  ]);

  const fetchLocation = async () => {
    /*
      Fetches location information by making a request to cloudfront and capturing gps header response
    */
    try {
      // Add a unique query string parameter to ensure a CloudFront cache miss
      const uniqueParam = `uniqueId=${uuidv4()}`;
      const url = `https://${window.location.hostname}/app/?${uniqueParam}`;
      const response = await axios.get(url, {
          method: 'GET',
          headers: {
              'Cache-Control': 'no-cache', // Instructs the client to bypass local cache
          }
      });
      const latitude = parseFloat(response.headers['cloudfront-viewer-latitude']);
      const longitude = parseFloat(response.headers['cloudfront-viewer-longitude']);
      console.log(latitude);
      console.log(longitude);
      if (!isNaN(latitude) && !isNaN(longitude)) {
        return {latitude: latitude, longitude: longitude};
      }
    } catch (error) {
        console.error('Error. Failed to set the region.', error);
    }
    return null;
  };

  useEffect(() => {
    const processAsync = async () => {
      // Only make the call to get the session URL if it's test-taking request 
      // or it's a website test and readOnly. Then, only make the request once.
      if ((currentTestTemplate?.testPlatform === 'website' || !readOnly) &&
        store.currentTestInstance && !testInstanceSessionUrl && 
        !testInstanceSessionUrlError && !testInstanceSessionUrlLoading) {
          setTestInstanceSessionUrlLoading(true);
          const location = await fetchLocation();
          fetchTestInstanceSessionUrl(location, readOnly);
      }
    }
    processAsync();
  }, [
    store.currentTestInstance, 
    testInstanceSessionUrl, 
    fetchTestInstanceSessionUrl, 
    readOnly,
    testInstanceSessionUrlError,
    testInstanceSessionUrlLoading,
    currentTestTemplate?.testPlatform
  ]);

  return {
    data: testInstanceSessionUrl, 
    error: testInstanceSessionUrlError, 
    loading: testInstanceSessionUrlLoading
  };
};

export default useSessionUrl;