import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import { Button, Grid, TextField, IconButton, Divider, Drawer, Toolbar } from "@mui/material";

import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useCustomSnackbar } from "../../components/CustomSnackbar";
import { Waiting } from "../../components/Waiting";
import CloseIcon from "@mui/icons-material/Close";
import { GET_TEST_ASSETS } from "../../graphql/queries";
import { CREATE_FOLDER_ASSET, GET_TEST_ASSET_UPLOAD_URL } from "../../graphql/mutations";
import { useMutation } from "@apollo/client";
import { addObjectToCache } from "../../cache";
import { handleFileDrop, uploadAssets } from "../../components/Assets/AssetDragDrop";
import AssetDropzone from "../../components/Assets/AssetDropzone";
import { useAppContext } from "../../context/AppContext";
import { useTheme } from "@mui/material/styles";

let getUploadURL;
let createFolder;
let apolloCache;

export default function AddAssetForm(props) {
  const { t } = useTranslation();
  const { lsEnqueueSnackbar } = useCustomSnackbar();
  const theme = useTheme();  

  const [uploadContent, setUploadContent] = useState(null);

  const { store } = useAppContext();

  // API to get an asset upload URL
  const [getAssetUploadURL] = useMutation(GET_TEST_ASSET_UPLOAD_URL, {
    update: (cache, results) => {
      apolloCache = cache;
    },
  });
  getUploadURL = getAssetUploadURL;

  const [createFolderAsset] = useMutation(CREATE_FOLDER_ASSET, {
    update: (cache, results) => {
      apolloCache = cache;
    },
  });
  createFolder = createFolderAsset;

  // Handle closing of dialog
  const closeForm = () => {
    props.setIsAddAssetOpen(false);
    formik.resetForm();
    setUploadContent(null);
  };

  const handleDrop = (e) => {
    if (e.dataTransfer.items) {
      handleFileDrop(e.dataTransfer.items)
        .then((results) => {
          setUploadContent(results);
        })
        .catch((e) => {
          // do something with errors
          console.log(e);
        });
    }
  };

  const validationSchema = Yup.object({
    name: Yup.string().max(2000, t("TwoThousandOrFewerChars")).required(t("Required")),
    description: Yup.string().max(2000, t("TwoThousandOrFewerChars")),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const vars = {
        variables: {
          orgId: store.currentOrg.org.id,
          asset: {
            name: values.name.trim(),
            description: values.description === "" ? null : values.description.trim(),
          },
        },
      };

      uploadAssets(vars, uploadContent, createFolder, getUploadURL)
        .then((res) => {
          let vars = {
            orgId: store.currentOrg.org.id,
            folderId: null,
            assetStatus: [],
          };
          addObjectToCache(apolloCache, res, "id", GET_TEST_ASSETS, "getTestAssets", null, vars);
          if (props.onUploadSuccess) {
            props.onUploadSuccess(res);
          }
          setSubmitting(false);
          closeForm();
        })
        .catch((e) => {
          lsEnqueueSnackbar(e.message, {
            variant: "error",
          });
          setSubmitting(false);
        });
    },
  });

  return (
    <Drawer anchor="right" open={props.isAddAssetOpen} onClose={closeForm} 
      sx={{zIndex: props.zIndex ?? theme.zIndex.drawer}}>
      <form onSubmit={formik.handleSubmit}>
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            {t("AddAsset")}
          </Typography>
          <IconButton
            aria-label="close"
            edge="end"
            sx={{ position: "absolute", right: 1, top: 1, color: "grey" }}
            onClick={closeForm}>
            <CloseIcon />
          </IconButton>
        </Toolbar>

        <Divider />

        <Grid container spacing={2} sx={{ width: 640, flexShrink: 0, padding: 5 }}>
          <Grid item xs={12}>
            <Typography paragraph>{t("AddAssetDialogText")}</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="name"
              variant="outlined"
              required
              fullWidth
              id="name"
              label={t("Name")}
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              name="description"
              variant="outlined"
              required
              fullWidth
              id="description"
              label={t("Description")}
              value={formik.values.description}
              onChange={formik.handleChange}
              error={formik.touched.description && Boolean(formik.errors.description)}
              helperText={formik.touched.description && formik.errors.description}
            />
          </Grid>
          <Grid item xs={12}>
            <AssetDropzone uploadContent={uploadContent} handleDrop={handleDrop} setUploadContent={setUploadContent} />
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item>
                <Button
                  variant="contained"
                  onClick={closeForm}
                  color="secondary"
                  sx={{ marginTop: 3, marginRight: 0, marginBottom: 1.5 }}>
                  {t("Cancel")}
                </Button>
                &nbsp;&nbsp;&nbsp;
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{ marginTop: 3, marginRight: 0, marginBottom: 1.5 }}>
                  {t("Create")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {formik.isSubmitting ? <Waiting /> : ""}
      </form>
    </Drawer>
  );
}
