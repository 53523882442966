import React from "react";
import { useCustomSnackbar } from "../../components/CustomSnackbar";
import { Button, Grid, Typography, TextField } from "@mui/material";
import { Waiting } from "../../components/Waiting";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { ACCEPT_JOIN_ORG_INVITATION } from "../../graphql/mutations";

export default function JoinOrgForm(props) {
  const { t } = useTranslation();
  const { lsEnqueueSnackbar } = useCustomSnackbar();
  const siteConfig = props.siteConfig;

  const [acceptJoinOrgInvitation, { loading: acceptJoinLoading }] = useMutation(
    ACCEPT_JOIN_ORG_INVITATION
  );

  const formik = useFormik({
    initialValues: {
      email: siteConfig.urlQueryParms.email || "",
    },
    onSubmit: async (values, { setSubmitting }) => {
      const vars = {
        variables: {
          email: values.email.trim(),
          orgId: siteConfig.urlQueryParms.orgid || "",
          invitorId: siteConfig.urlQueryParms.userid || "",
          code: siteConfig.urlQueryParms.code || "",
        },
      };

      // Invoke the sign up routine
      acceptJoinOrgInvitation(vars)
        .then((res) => {
          if (res.data) {
            lsEnqueueSnackbar(t("SignUpSuccessful"), {
              variant: "success",
            });
          } else if (res.errors) {
            lsEnqueueSnackbar(res.errors[0].message, {
              variant: "error",
            });
            setSubmitting(false);
          }
        })
        .catch((e) => {
          lsEnqueueSnackbar(e.message, {
            variant: "error",
          });
          setSubmitting(false);
        }); // catch network errors
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography component="h1" variant="h5" sx={{ marginBottom: 3 }}>
            {t("WelcomeToOrg", { siteConfig })}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" sx={{ marginBottom: 3 }}>
            {t("WelcomeToOrgText")}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <TextField
            variant="filled"
            disabled
            fullWidth
            id="email"
            label={t("EmailAddress")}
            name="email"
            helperText={t("CanChangeEmailLater")}
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
          />
        </Grid>
      </Grid>

      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        sx={{ marginTop: 3, marginRight: 0, marginBottom: 1.5 }}>
        {t("Submit")}
      </Button>

      {acceptJoinLoading ? <Waiting /> : ""}
    </form>
  );
}