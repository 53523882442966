import { gql } from "@apollo/client";
import {
  CORE_TEST_ASSET_FIELDS,
  CORE_TEST_INSTANCE_FIELDS,
  CORE_TEST_TEMPLATE_FIELDS,
  CORE_ASSIGNED_BY_TEST_INSTANCE_FIELDS,
  CORE_ORG_FIELDS
} from "../fragments";
/*
 * USER & ORG
 */
// get the user's profile from the server
export const GET_USER_PROFILE = gql`
  query UserProfile {
    userProfile {
      user {
        id
        defaultorg
        email
        username
        firstname
        lastname
        title
        suffix
        gender
        pronoun
        website
        address
        city
        state
        country
        postalcode
        phone
        language
        timezone
        birthdate
        privacy
        avatar
        rights {
          org {
            id
            name
            logo
          }
          roles {
            id
            name
          }
          rights
        }
      }
    }
  }
`;

export const GET_ORG_PROFILE = gql`
  ${CORE_ORG_FIELDS}
  query OrgProfile($orgId: GUID!) {
    orgProfile(orgId: $orgId) {
      ...CoreOrgFields
    }
  }
`;

export const SITE_CONFIG = gql`
  query SiteConfig($sd: String) {
    siteConfig(subdomain: $sd) {
      orgId
      poolId
      clientId
      region
      siteName
      language
      brandcolors {
        primarycolor
        secondarycolor
        thirdcolor
      }
      logo
      pwReqs {
        minlength
        uppercaseReq
        lowercaseReq
        numbersReq
        symbolsReq
      }
      customTsAndCs
    }
  }
`;

export const RESPOND_TO_SIGNIN_CHALLENGE = gql`
  query RespondToSignInChallenge($org: GUID, $resp: ChallengeResponse!) {
    respondToSignInChallenge(orgId: $org, response: $resp) {
      token {
        idToken
        accessToken
        refreshToken
      }
      challenge {
        session
        challengeName
        challengeParameters {
          paramName
          paramValue
        }
      }
    }
  }
`;

// Get a list of all roles
export const ORG_ROLES = gql`
  query OrgRoles($orgId: GUID!) {
    orgRoles(orgId: $orgId) {
      id
      name
      isSystem
      description
      rights
      createdBy
      creationDate
      lastUpdatedBy
      lastUpdateDate
    }
  }
`;

// Get all organization roles
export const GET_ORG_ROLES = gql`
  query OrgRoles($orgId: GUID!) {
    orgRoles(orgId: $orgId) {
      id
      name
      isSystem
      description
      rights
      createdBy
      creationDate
      lastUpdatedBy
      lastUpdateDate
    }
  }
`;

// Get an organization role from its ID.
export const GET_ORG_ROLE = gql`
  query GetOrgRole($ri: ID!, $is: Boolean!) {
    getOrgRole(roleId: $ri, isSystem: $is) {
      id
      name
      type
      description
      isSystem
      orgRights
      pubRights {
        pubId
        title
        rights
      }
      createdBy
      creationDate
      lastUpdatedBy
      lastUpdateDate
    }
  }
`;

// Get Role Users
export const ROLE_USERS = gql`
  query RoleUsers($ri: GUID!, $is: Boolean!, $pid: GUID) {
    roleUsers(roleId: $ri, isSystem: $is, pubId: $pid) {
      id
      username
      firstname
      lastname
      avatar
      roles {
        id
        name
        isSystem
        description
      }
    }
  }
`;

// Get all users
export const GET_USERS = gql`
  query GetUsers($orgId: GUID!, $roles: [GUID!]) {
    getUsers(orgId: $orgId, roles: $roles) {
      id
      defaultorg
      email
      username
      firstname
      lastname
      title
      suffix
      gender
      pronoun
      website
      address
      city
      state
      country
      postalcode
      phone
      language
      timezone
      birthdate
      privacy
      avatar
      rights {
        org {
          id
          name
          logo
        }
        roles {
          id
          name
        }
        rights
      }
    }
  }
`;

// sign in GQL
export const INITIATE_SIGNIN = gql`
  query InitiateSignIn($un: String!, $srpa: String!) {
    initiateSignIn(username: $un, srp_a: $srpa) {
      session
      challengeName
      challengeParameters {
        paramName
        paramValue
      }
    }
  }
`;
/*
 * END USER & ORG
 */

/*
 * TEST TEMPLATE
 */
// Get a list of all test templates
export const GET_TEST_TEMPLATES = gql`
  ${CORE_TEST_TEMPLATE_FIELDS}
  query GetTestTemplates($orgId: GUID!, $templateStatus: [TestTemplateStatus]) {
    getTestTemplates(orgId: $orgId, templateStatus: $templateStatus) {
      ...CoreTestTemplateFields
    }
  }
`;

// Get an individual test template
export const GET_TEST_TEMPLATE = gql`
  ${CORE_TEST_TEMPLATE_FIELDS}
  query GetTestTemplate($orgId: GUID!, $templateId: GUID!) {
    getTestTemplate(orgId: $orgId, templateId: $templateId) {
      ...CoreTestTemplateFields
    }
  }
`;

// Get a list of all apps
export const GET_TEST_APPS = gql`
  query GetTestApps($orgId: GUID!) {
    getTestApps(orgId: $orgId) {
      id
      orgId
      isSystem
      name
      displayName
      developer
      icon
      version
      description
      tags
      extensions {
        extension
        description
      }
      requiresSave
      requiresReservation
      testPlatform
      disabled
      createdBy
      creationDate
      lastUpdatedBy
      lastUpdateDate
    }
  }
`;

/*
 * END TEST TEMPLATE
 */

/*
 * TEST ASSETS
 */
// Get a list of all assets
export const GET_TEST_ASSETS = gql`
  ${CORE_TEST_ASSET_FIELDS}
  query GetTestAssets(
    $orgId: GUID!
    $folderId: GUID
    $assetStatus: [AssetStatus!]
  ) {
    getTestAssets(
      orgId: $orgId
      folderId: $folderId
      assetStatus: $assetStatus
    ) {
      ...CoreTestAssetFields
    }
  }
`;

// Get an individual asset
export const GET_TEST_ASSET = gql`
  ${CORE_TEST_ASSET_FIELDS}
  query GetTestAsset($orgId: GUID!, $assetId: GUID!) {
    getTestAsset(orgId: $orgId, assetId: $assetId) {
      ...CoreTestAssetFields
    }
  }
`;

/*
 * END TEST ASSETS
 */

/*
 * TEST INSTANCE
 */

// Get test instances from test template
export const GET_TEST_TEMPLATE_INSTANCES = gql`
  ${CORE_TEST_INSTANCE_FIELDS}
  query GetTestTemplateInstances(
    $orgId: GUID!
    $templateId: GUID!
    $status: TestInstanceStatus
  ) {
    getTestTemplateInstances(
      orgId: $orgId
      templateId: $templateId
      status: $status
    ) {
      ...CoreTestInstanceFields
    }
  }
`;

// Get test instances assigned to me
export const GET_TEST_INSTANCES_ASSIGNED_TO_ME = gql`
  ${CORE_TEST_INSTANCE_FIELDS}
  query GetTestInstancesAssignedToMe($options: TestInstanceQueryOptions) {
    getTestInstancesAssignedToMe(options: $options) {
      ...CoreTestInstanceFields
    }
  }
`;

// Get test instances assigned by me
export const GET_TEST_INSTANCES_ASSIGNED_BY_ME = gql`
  ${CORE_ASSIGNED_BY_TEST_INSTANCE_FIELDS}
  query GetTestInstancesAssignedByMe($orgId: GUID!, $options: TestInstanceQueryOptions) {
    getTestInstancesAssignedByMe(orgId: $orgId, options: $options) {
      ...CoreAssignedByTestInstanceFields
    }
  }
`;

// Get test instances assigned to me to mark
export const GET_TEST_INSTANCES_FOR_ME_TO_MARK = gql`
  ${CORE_TEST_INSTANCE_FIELDS}
  query GetTestInstancesForMeToMark($options: TestInstanceQueryOptions) {
    getTestInstancesForMeToMark(options: $options) {
      ...CoreTestInstanceFields
    }
  }
`;

// Get a single test instance
export const GET_TEST_INSTANCE = gql`
  ${CORE_TEST_INSTANCE_FIELDS}
  query GetTestInstance($orgId: GUID!, $instanceId: GUID!) {
    getTestInstance(orgId: $orgId, instanceId: $instanceId) {
      ...CoreTestInstanceFields
    }
  }
`; 

// List the contents of a folder associated with a test instance
export const LIST_TEST_INSTANCE_FOLDER_CONTENTS = gql`
  query ListTestInstanceFolderContents(
    $orgId: GUID!
    $instanceId: GUID!
    $path: String!
    $recursive: Boolean
  ) {
    listTestInstanceFolderContents(
      orgId: $orgId
      instanceId: $instanceId
      path: $path
      recursive: $recursive
    ) {
      filename
      folder
      lastUpdateDate
      size
      isFolder
    }
  }
`;

// List the recordings associated with a test instance
export const LIST_TEST_INSTANCE_RECORDING_FILES = gql`
  query ListTestInstanceRecordingFiles($orgId: GUID!, $instanceId: GUID!) {
    listTestInstanceRecordingFiles(orgId: $orgId, instanceId: $instanceId) {
      filename
      lastUpdateDate
      size
    }
  }
`;
/*
 * END TEST INSTANCE
 */

// List the time slots available where there is at least 'duration' amount of time available to schedule the test
export const FIND_TEST_INSTANCE_TIMESLOTS = gql`
  query FindTestInstanceTimeslots(
    $orgId: GUID!
    $instanceId: GUID!
    $start: DateTime
    $end: DateTime
    $duration: Int
  ) {
    findTestInstanceTimeslots(
      orgId: $orgId
      instanceId: $instanceId
      start: $start
      end: $end
      duration: $duration
    ) {
      start
      duration
    }
  }
`;

// Get the list of tags
export const GET_TAGS = gql`
  query GetTags($orgId: GUID!) {
    getTags(orgId: $orgId) {
      id
      name
    }
  }
`;

// Get the list of org email templates
export const GET_ORG_EMAIL_TEMPLATES = gql`
  query GetOrgEmailTemplates($orgId: GUID!) {
    getOrgEmailTemplates(orgId: $orgId) {
      orgId
      emailTemplate {
        key
        language
        name
        description
        parameters {
          key
          description
          isRequired
        }
        subject
        body
      }
      subject
      body
    }
  }
`;
