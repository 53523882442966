import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ApplicationTermsAndConditions = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Grid container spacing={2} paddingLeft="20%" paddingRight="20%">
      <Grid item xs={12}>
        <Typography
          component="h1"
          variant="h5"
          sx={{ marginBottom: 3, fontWeight: "bold" }}
        >
          {t("TermsAndConditionsTitle")}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography component="p">
          {t("ApplicationTermsAndConditions")}
        </Typography>
      </Grid>

      <Grid container spacing={2} justifyContent="flex-end">
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            sx={{ marginTop: 3, marginRight: 0, marginBottom: 1.5 }}
            onClick={() => navigate(-1)}
          >
            Go back
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ApplicationTermsAndConditions;
