import { gql } from "@apollo/client";
import { CORE_TEST_INSTANCE_FIELDS } from "../fragments";

export const ON_USERPROFILEUPDATE_SUBSCRIPTION = gql`
  subscription OnUserProfileUpdate {
    onUserProfileUpdate {
      name
      value
    }
  }
`;

// Subscribe to Org and Pub role updates
export const ON_ORGROLEUPDATE_SUBSCRIPTION = gql`
  subscription OnOrgRoleUpdate($oi: GUID!) {
    onOrgRoleUpdate(orgId: $oi) {
      name
      action
      value
      message {
        type
        content
      }
    }
  }
`;


// Test Instance Subscriptions
export const ON_TESTINSTANCEASSIGNEDBYMEUPDATE_SUBSCRIPTION = gql`
  ${CORE_TEST_INSTANCE_FIELDS}
  subscription OnTestInstanceAssignedByMeUpdate($orgId: GUID!, $filtering: [TestInstanceQueryFilteringOptions!]) {
    onTestInstanceAssignedByMeUpdate(orgId: $orgId, filtering: $filtering) {
      objectUpdate {
        action
        message {
          type
          code
          params
          content    
        }
      }
      testInstance {
        ...CoreTestInstanceFields
      }
    }
  }
`;

export const ON_TESTINSTANCEASSIGNEDTOMEUPDATE_SUBSCRIPTION = gql`
  ${CORE_TEST_INSTANCE_FIELDS}
  subscription OnTestInstanceAssignedToMeUpdate($filtering: [TestInstanceQueryFilteringOptions!]) {
    onTestInstanceAssignedToMeUpdate(filtering: $filtering) {
      objectUpdate {
        action
        message {
          type
          code
          params
          content    
        }
      }
      testInstance {
        ...CoreTestInstanceFields
      }
    }
  }
`;

export const ON_TESTINSTANCEFORMETOMARKUPDATE_SUBSCRIPTION = gql`
  ${CORE_TEST_INSTANCE_FIELDS}
  subscription OnTestInstanceForMeToMarkUpdate($filtering: [TestInstanceQueryFilteringOptions!]) {
    onTestInstanceForMeToMarkUpdate(filtering: $filtering) {
      objectUpdate {
        action
        message {
          type
          code
          params
          content    
        }
      }
      testInstance {
        ...CoreTestInstanceFields
      }
    }
  }
`;
