import React, { useState, useEffect } from "react";
import { Grid, Box, Typography, Tab, Tabs } from "@mui/material";
import DashboardReadyToReview from "./DashboardReadytoReview";
import DashboardAssignors from "./DashboardAssignors";
import DashboardAssignees from "./DashboardAssignees";
import { Waiting } from "../Waiting";
import { t } from "i18next";
import { useAppContext } from "../../context/AppContext";

export const Roles = {
  Owner: "Owner",
  Candidate: "Candidate",
  HiringManager: "Hiring Manager",
  Juror: "Juror",
  Administrator: "Financial Administrator",
  TestDesigner: "Test Designer",
  Recruiter: "Recruiter",
};

export default function DashboardTabs(props) {
  const { store } = useAppContext();
  const [currentRoles, setCurrentRoles] = useState([]);
  const [initialTabState, setInitialTabState] = useState(3);
  const [activeTab, setActiveTab] = useState(initialTabState);

  useEffect(() => {
    if (store.currentOrg) {
      const getCurrentRoles = async () => {
        const res = store.currentOrg.roles.map((role) => role.name);
        setCurrentRoles(await res);
        if (props.selectedTab) {
          setInitialTabState(props.selectedTab);
        } else if (res.includes(Roles.HiringManager) || res.includes(Roles.Owner) ||
           res.includes(Roles.Recruiter) || res.includes(Roles.TestDesigner)) {
          setInitialTabState(0);
        } else if (res.includes(Roles.Juror)) {
          setInitialTabState(2);
        } else {
          setInitialTabState(3);
        }
        setActiveTab(initialTabState);
      };
      getCurrentRoles();
    }
  }, [setCurrentRoles, store.currentOrg, initialTabState, props.selectedTab]);

  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
    props.setSelectedTab(newValue);
  };

  let pageResults;
  if (!store.currentOrg && !initialTabState) {
    pageResults = <Waiting />;
  } else {
    pageResults = (
      <Grid container spacing={2} paddingLeft="5%" paddingRight="5%">
        <Grid item xs={12}>
          <Typography component="h1" variant="h5" sx={{ fontWeight: "bold" }}>
            {t("ManageTests")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={activeTab}
                onChange={handleChangeTab}
                aria-label="tabs"
              >
                {(currentRoles.includes(Roles.HiringManager) ||
                  currentRoles.includes(Roles.Owner) ||
                  currentRoles.includes(Roles.Recruiter) ||
                  currentRoles.includes(Roles.TestDesigner)) && (
                  <Tab
                    label={t("AvailableToAssign")}
                    id="tab-0"
                    aria-controls="tabpanel-0"
                    value={0}
                  />
                )}
                {(currentRoles.includes(Roles.HiringManager) ||
                  currentRoles.includes(Roles.Recruiter) ||
                  currentRoles.includes(Roles.Owner)) && (
                  <Tab
                    label={t("AssignedToOthers")}
                    id="tab-1"
                    aria-controls="tabpanel-1"
                    value={1}
                  />
                )}
                {(currentRoles.includes(Roles.Juror) ||
                  currentRoles.includes(Roles.Owner)) && (
                  <Tab
                    label={t("ReadyToReview")}
                    id="tab-2"
                    aria-controls="tabpanel-2"
                    value={2}
                  />
                )}
                <Tab
                  label={t("AssignedToMe")}
                  id="tab-3"
                  aria-controls="tabpanel-3"
                  value={3}
                />
                <Tab
                  label={t("SubmittedByMe")}
                  id="tab-4"
                  aria-controls="tabpanel-4"
                  value={4}
                />
              </Tabs>
            </Box>
            {(currentRoles.includes(Roles.HiringManager) ||
              currentRoles.includes(Roles.Owner) ||
              currentRoles.includes(Roles.Recruiter) ||
              currentRoles.includes(Roles.TestDesigner)) && (
              <DashboardAssignors activeTab={activeTab} />  
            )}
            {(currentRoles.includes(Roles.Juror) ||
              currentRoles.includes(Roles.Owner)) && (
              <DashboardReadyToReview activeTab={activeTab} />
            )}
            <DashboardAssignees activeTab={activeTab} />
          </Box>
        </Grid>
      </Grid>
    );
  }

  return pageResults;
}
