import React from "react";
import {
  Button,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Grid
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../context/AppContext";

const TestTermsAndConditions = ({
  handleConfirmationClose
}) => {
  const { t } = useTranslation();
  const { store } = useAppContext();

  let pageResults = (
    <Grid item xs={12}>
      <DialogTitle>
        {t("TermsAndConditionsTitle")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <div dangerouslySetInnerHTML={{
            __html:
              store.currentTestInstance?.testTemplate.org.customTsAndCs ||
              `<p>${t("TermsAndConditionsDefault")}</p>`
          }} />
        </DialogContentText>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              sx={{ marginTop: 1, marginRight: 1 }}
              onClick={() => handleConfirmationClose('Cancel')}
            >
              {t("Cancel")}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              sx={{ marginTop: 1 }}
              onClick={() => handleConfirmationClose('Confirm')}
            >
              {t("Accept")}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Grid>
  );

  return pageResults;
};

export default TestTermsAndConditions;
