export const InstructionsType = { Asset: "asset", Text: "text" };

export const TestTemplateStatus = {
  Draft: "draft",
  Active: "active",
  Deprecated: "deprecated",
  Retired: "retired",
};

export const TestInstanceStatus = {
  Created: "created",
  Assigned: "assigned",
  Active: "active",
  Unmarked: "unmarked",
  UnmarkedComplete: "unmarked_complete",
  Marked: "marked",
  Suspended: "suspended",
  Cancelled: "cancelled",
  Inactive: "inactive",
  AssignedPending: "assigned_pending",
  ActivePending: "active_pending",
  UnmarkedPending: "unmarked_pending",
  MarkedPending: "marked_pending",
  UnmarkedCompletePending: "unmarked_complete_pending",
  CancelledPending: "cancelled_pending",
};

// Freeze the enums
Object.freeze(InstructionsType);
Object.freeze(TestTemplateStatus);
Object.freeze(TestInstanceStatus);
