import { FileSystemItemType } from "./FileSystem";

export class FileTree {
    files;
    rootPath;

    constructor(files, rootPath) {
        this.rootPath = rootPath;
        this.files = this.#buildTree(files);
    }

    #buildTree(files) {
        let tree = []
        for(let file of files) {
            this.#addNodeAndMissingParentsR(tree, file);
        }
        return tree;
    }

    /**
     * 
     * @returns {FileTree}
     */
    copy() {
        let newTree = new FileTree([], this.rootPath);
        newTree.files = JSON.parse(JSON.stringify(this.files));
        return newTree;
    }

    /**
     * 
     * @param {*} targetPath 
     * @returns 
     */
    findNodeByPath(targetPath) {
        return this.#findNodeByPathR(this.files, targetPath);
    }

    #findNodeByPathR (elements, targetPath) {
        let allNodesToVist = [];
        let allNodes = [];
        if (targetPath === "" || targetPath === this.rootPath) {
            //Root node
            return {
                children: elements
            };
        }
        for (let node of elements) {
            allNodesToVist.push(node);
        }
        while (allNodesToVist.length > 0) {
            let currentNode = allNodesToVist.pop();
            allNodes.push(currentNode);
            if (currentNode.children) {
                for (let childNode of currentNode.children) {
                    allNodesToVist.push(childNode);
                }
            }
        }
        for (let node of allNodes) {
            if (node.path === targetPath) {
                return node;
            }
        }
    }

    /**
     * Adds a child to a parent in the correct case insensitive alphabetical order
     * @param {*} parentNode 
     * @param {*} childNode 
     */
    addChildNode (parentNode, childNode) {
        // console.log(`Adding node. parent: ${parentNode.path}, child: ${childNode.path}`);
        let added = false;
        let childFileName = childNode.path.substring(childNode.path.lastIndexOf("/") + 1).toUpperCase();
        for (let i = 0; i < parentNode.children.length; i++) {
            let node = parentNode.children[i];
            if (node.path.substring(node.path.lastIndexOf("/") + 1).toUpperCase() > childFileName) {
                parentNode.children.splice(i, 0, childNode);
                added = true;
                break;
            }
        }
        if (!added) {
            parentNode.children.push(childNode);
        }
        let parentPath = (parentNode.path ? parentNode.path : "") + "/";
        this.#updatePaths(parentPath, parentNode.children);
    }

    /**
     * 
     * @param {*} parentNode 
     * @param {*} childNode 
     * @returns 
     */
    removeChildNode (parentNode, childNode) {
        // console.log(`Removing node. parent: ${parentNode.path}, child: ${childNode.path}`);
        for (let i = 0; i < parentNode.children.length; i++) {
            let node = parentNode.children[i];
            if (node.path === childNode.path) {
                parentNode.children.splice(i, 1);
                return;
            }
        }
    }

    /**
     * Adds a node to the graph at the correct level.
     * Adds missing inner parent nodes
     * 
     * @param {*} node 
     */
    addNodeAndMissingParents(node) {
        this.#addNodeAndMissingParentsR(this.files, node);
    }

    #addNodeAndMissingParentsR(elements, node) {
        let parentPath = node.path.substring(0, node.path.lastIndexOf("/"));
        let parent = this.#findNodeByPathR(elements, parentPath);
        if (!parent) {
            parent = {
                id: this.rootPath + parentPath,
                title: parentPath.substring(parentPath.lastIndexOf("/") + 1),
                type: FileSystemItemType.CreatedFolder,
                path: parentPath,
                children: [],
            }
            this.#addNodeAndMissingParentsR(elements, parent);
            this.#updatePaths("/", elements);
        }
        if (!parent.children.find((_node) => _node.path === node.path)) {
            this.addChildNode(parent, node);
        }
    }

    renameNode(node, newName) {
        let parentPath = node.path.substring(0, node.path.lastIndexOf("/") + 1);
        let newPath = parentPath + newName;
        node.id = newPath;
        node.title = newName;
        node.path = newPath;
        this.#updatePaths(node.path + "/", node.children);
    }

    #updatePaths(currentBasePath, elements) {
        for (let node of elements) {
            node.path = currentBasePath + node.title;
            if (node.children) {
                this.#updatePaths(node.path + "/", node.children);
            }
        }
    }

}