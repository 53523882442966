import React, { useState } from "react";
import { read } from "xlsx";
import { useTranslation } from "react-i18next";
import SelectFileButton from "../FileSystem/SelectFileButton";
import ConfirmationForm from "../ConfirmationForm";
import { Dialog } from "@mui/material";

const OpenSpreadsheet = ({
  onOpen
}) => {
  const { t } = useTranslation();
  const [openFileErrorOpen, setOpenFileErrorOpen] = useState(false);
  const [openFileErrorMessage, setOpenFileErrorMessage] = useState(null);

  const selectFile = async (spreadsheetFile) => {
    try {
      const workbook = await processFile(spreadsheetFile);
      if (onOpen) {
        onOpen(workbook);
      }
    } catch (error) {
      setOpenFileErrorMessage(error.message);
      setOpenFileErrorOpen(true);
    }
  };

  const onFileSelect = (e) => {
    if (!e.target.files.length) {
      return;
    }
    selectFile(e.target.files[0]);
  };

  function processFile(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        try {
          const arrayBuffer = event.target.result;
          const workbook = read(new Uint8Array(arrayBuffer), { type: "array" });
          resolve(workbook);
        } catch (error) {
          reject(error);
        }
      };

      reader.onerror = () => reject(reader.error);

      // Read file as ArrayBuffer
      reader.readAsArrayBuffer(file);
    });
  }

  const handleFileErrorClose = (response) => {
    setOpenFileErrorOpen(false);
  };

  return (
    <React.Fragment>
      <SelectFileButton
        onOpen={onFileSelect}
        title={t("ImportSpreadsheet")}
        accept=".csv, .xlsx"
      />
      <Dialog
        open={openFileErrorOpen}
        onClose={handleFileErrorClose}
      >
        <ConfirmationForm
          handleConfirmationClose={handleFileErrorClose}
          dialogTitle={t("OpenSpreadsheetFileErrorTitle")}
          dialogContent={t("OpenSpreadsheetFileErrorContent", { errorMessage: openFileErrorMessage })}
          confirmText={t("Okay")}
          showCancel={false}
        />
      </Dialog>
    </React.Fragment >
  );
};



export default OpenSpreadsheet;