import React from "react";
import { 
  Grid,
  Button,
  Typography,
  Switch
} from "@mui/material";

import { useAppContext } from "../../context/AppContext";
import { t } from "i18next";

export default function DashboardAvailableToAssignToolbar({
  onCreateTestClick,
  isSelectingEnabled,
  setIsSelectingEnabled
}) {
  const { store } = useAppContext();

  return (
    <React.Fragment>
      <Grid item sx={{ marginRight: 2 }}>
        {store.currentOrg?.rights.includes("createTest") && (
          <Button onClick={onCreateTestClick}>
            {t("CreateNew")}
          </Button>
        )}
      </Grid>
      {store.currentOrg?.rights.includes("editTest") && (
        <Grid item sx={{ marginRight: 2 }}>
          <Typography component="label" variant="body2">
            {t("BulkActions")}
          </Typography>
          <Switch
            checked={isSelectingEnabled}
            onChange={(e) => setIsSelectingEnabled(e.target.checked)}
            color="primary"
          />
        </Grid>
      )}
    </React.Fragment>
  );
}
