import { useState } from "react";
import {
  Typography,
  Grid,
  Avatar,
  Tooltip
} from "@mui/material";
import { t } from "i18next";
import { useAppContext } from "../../context/AppContext";
import { useMutation } from "@apollo/client";
import { handleCacheUpdate } from "../../cache";
import { GET_TEST_INSTANCES_FOR_ME_TO_MARK } from "../../graphql/queries";
import TagControl from "../Tags/TagControl";
import { format } from "date-fns";
import { getCurrentDateFormat } from "../../App";


import {
  TAG_TEST_INSTANCES,
  UNTAG_TEST_INSTANCES
} from "../../graphql/mutations";
import { useCustomSnackbar } from "../CustomSnackbar";

export default function DashboardReadyToReviewDrawer({
  testInstance
}) {
  let dateFormat = getCurrentDateFormat();
  const [tags, setTags] = useState((testInstance.tags || []));
  const { store } = useAppContext();
  const { lsEnqueueSnackbar } = useCustomSnackbar();

  const [tagTestInstances] = useMutation(TAG_TEST_INSTANCES, {
    update: (cacheClient, response) => {
      if (response?.data) {
        for (let resp of response.data.tagTestInstances) {
          if (resp.__typename === "TestInstance") {
            handleCacheUpdate(
              "update",
              cacheClient,
              resp,
              "id",
              GET_TEST_INSTANCES_FOR_ME_TO_MARK,
              "getTestInstancesForMeToMark",
              null,
              { orgId: store.currentOrg.org.id }
            );
          } else {
            lsEnqueueSnackbar(resp.message, {
              variant: "error",
            });
          }
        }
      }
    }
  });

  const [untagTestInstances] = useMutation(UNTAG_TEST_INSTANCES, {
    update: (cacheClient, response) => {
      if (response?.data) {
        for (let resp of response.data.untagTestInstances) {
          if (resp.__typename === "TestInstance") {
            handleCacheUpdate(
              "update",
              cacheClient,
              resp,
              "id",
              GET_TEST_INSTANCES_FOR_ME_TO_MARK,
              "getTestInstancesForMeToMark",
              null,
              { orgId: store.currentOrg.org.id }
            );
          } else {
            lsEnqueueSnackbar(resp.message, {
              variant: "error",
            });
          }
        }
      }
    }
  });

  const handleApplyTag = async (tag) => {
    try {
      await tagTestInstances({
        variables: {
          orgId: store.currentOrg.org.id,
          instanceIds: [testInstance.id],
          tagIds: [tag.id]
        }
      });
    } catch (e) {
      lsEnqueueSnackbar(e.message, {
        variant: "error",
      });
    }
  }

  const handleRemoveTag = async (tag) => {
    try {
      await untagTestInstances({
        variables: {
          orgId: store.currentOrg.org.id,
          instanceIds: [testInstance.id],
          tagIds: [tag.id]
        }
      });
    } catch (e) {
      lsEnqueueSnackbar(e.message, {
        variant: "error",
      });
    }
  }

  return (
    <Grid container alignItems="flex-start" justifyContent="flex-start" spacing={3}>
      <Grid item xs={7}>
        <Grid item xs={12}>
          <Grid container alignItems="center" justifyContent="flex-start">
            <Grid item>
              <Typography variant="p">
                <strong>{t("Applications")}: &nbsp;</strong>
              </Typography>
            </Grid>
            {testInstance.testTemplate.apps.map((app, index) => {
              return (
                <Grid item key={index}>
                  <Tooltip
                    title={app.displayName}
                  >
                    <Avatar
                      alt={app.displayName}
                      src={app.icon || app.displayName.charAt(0)}
                      sx={{
                        height: 25,
                        width: 25
                      }}
                      variant="square"
                    />
                  </Tooltip>
                </Grid>
              );
            })}
          </Grid>
        </Grid>

        <Grid item xs={12} sx={{ mt: 1 }}>
          <Grid container alignItems="center" justifyContent="flex-start">
            <Grid item xs={12}>
              <Typography variant="p">
                <strong>{t("Tags")}:&nbsp;</strong>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TagControl
                tags={tags}
                setTags={setTags}
                onApplyTag={(tag) => handleApplyTag(tag)}
                onRemoveTag={(tag) => handleRemoveTag(tag)}
                startInEditMode={false}
                readOnly={!store?.currentOrg?.rights?.includes("assignUserToTest")}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={5}>
        <Grid item>
          <Typography variant="p">
            <strong>{t("StartDate")}:&nbsp;</strong>
          </Typography>

          <Typography variant="p">
            {testInstance.startDate ?
              `${format(new Date(testInstance.startDate), "PPP", { locale: dateFormat })}` :
              t("NotYetStarted")
            }
          </Typography>
        </Grid>

        <Grid item>
          <Typography variant="p">
            <strong>{t("CompletionDate")}:&nbsp;</strong>
          </Typography>

          <Typography variant="p">
            {testInstance.completionDate ?
              `${format(new Date(testInstance.completionDate), "PPP", { locale: dateFormat })}` :
              (["created", "assigned", "active", "suspended"].includes(testInstance.status) ?
                t("NotYetCompleted") : t("NotCompleted"))
            }
          </Typography>
        </Grid>
        
      </Grid>
    </Grid>
  )
}