import { useState } from "react";
import {
  Button,
  Dialog,
  Grid,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useCustomSnackbar } from "../../../components/CustomSnackbar";
import { UPDATE_ORG_PROFILE } from "../../../graphql/mutations";
import { useMutation } from "@apollo/client";
import { useAppContext } from "../../../context/AppContext";
import SunEditor from "suneditor-react";
import ConfirmationForm from "../../../components/ConfirmationForm";


export default function OrgTsAndCsForm({
  opData
}) {
  const { t, i18n } = useTranslation();
  const { lsEnqueueSnackbar } = useCustomSnackbar();
  const { store } = useAppContext();
  const [confirmRevertToSystemDefault, setConfirmRevertToSystemDefault] = useState(false);

  // Set up the update user profile API
  const [updateOrgProfile] = useMutation(UPDATE_ORG_PROFILE);


  const handleOnChangeTsAndCs = (content) => {
    formik.setFieldValue("customTsAndCs", content);
  };

  const handleConfirmRevertToSystemDefaultClose = (response) => {
    setConfirmRevertToSystemDefault(false);

    if (response === 'Confirm') {
      formik.setFieldValue("customTsAndCs", t("TermsAndConditionsDefault"));
    }
  }

  const handleRevertToSystemDefault = () => {
    setConfirmRevertToSystemDefault(true);
  }

  const scriptTagPattern = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>|<script\b[^<]*(?:(?!\/>)[^<]*)*\/>/gi;

  const validationSchema = Yup.object().shape({
    customTsAndCs: Yup.string()
      .test('no-script-tags', t("ScriptTagsForbidden"), value => {
        if (!value) return true; // Skip validation if value is empty
        return !scriptTagPattern.test(value);
      })
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      customTsAndCs: opData?.orgProfile?.customTsAndCs ? opData.orgProfile.customTsAndCs.trim() :
        `<p>${t("TermsAndConditionsDefault")}</p>`
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const vars = {
        variables: {
          orgId: store.currentOrg.org.id,
          op: {
            customTsAndCs: (values.customTsAndCs && 
              values.customTsAndCs !== `<p>${t("TermsAndConditionsDefault")}</p>`) ? 
              values.customTsAndCs : null
          },
        },
      };

      // Update the org profile on the server
      updateOrgProfile(vars)
        .then((res) => {
          if (res.data) {
            lsEnqueueSnackbar(t("OrgTsAndCsUpdateSuccessful"), {
              variant: "success",
            });
            // navigate("/app");
          } else if (res.errors) {
            lsEnqueueSnackbar(res.errors[0].message, {
              variant: "error",
            });
            setSubmitting(false);
          }
        })
        .catch((e) => {
          lsEnqueueSnackbar(e.message, {
            variant: "error",
          });
          setSubmitting(false);
        }); // catch network errors
    },
  });

  let pageResults;
  if (opData) {
    pageResults = (
      <>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <SunEditor
                name="customTsAndCs"
                height="45vh"
                lang={i18n.language === "French" ? "fr" : "en"}
                onChange={handleOnChangeTsAndCs}
                setContents={formik.values.customTsAndCs}
                setDefaultStyle="font-family: Arial; font-size: 16px;"
                setOptions={{
                  buttonList: [
                    ["undo", "redo"],
                    ["font", "fontSize", "formatBlock"],
                    ["paragraphStyle", "blockquote"],
                    [
                      "bold",
                      "underline",
                      "italic",
                      "strike",
                      "subscript",
                      "superscript",
                    ],
                    ["fontColor", "hiliteColor"],
                    ["removeFormat"],
                    "/", // Line break
                    ["outdent", "indent"],
                    ["align", "horizontalRule", "list", "lineHeight"],
                    ["table", "link", "image"],
                    ["fullScreen", "showBlocks", "codeView"],
                    // ["dir", "dir_ltr", "dir_rtl"],
                  ],
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2} justifyContent="space-evently" alignItems="center">
                <Grid item xs={4}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleRevertToSystemDefault}
                    disabled={formik.values.customTsAndCs === `<p>${t("TermsAndConditionsDefault")}</p>`}
                  >
                    {t("RevertToSystemDefault")}
                  </Button>
                </Grid>
                <Grid item xs={8} sx={{ textAlign: "right" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    sx={{ marginTop: 3, marginRight: 0, marginBottom: 1.5 }}
                  >
                    {t("Update")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
        <Dialog
          open={confirmRevertToSystemDefault}
          onClose={handleConfirmRevertToSystemDefaultClose}
        >
          <ConfirmationForm
            handleConfirmationClose={handleConfirmRevertToSystemDefaultClose}
            dialogTitle={t("ConfirmRevertToSystemDefaultTitle")}
            dialogContent={t("ConfirmRevertToSystemDefaultTsAndCsText")}
          />
        </Dialog></>
    );
  } else {
    pageResults = "";
  }
  return <div>{pageResults}</div>;
}
