import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCustomSnackbar } from "../../components/CustomSnackbar";
import { Waiting } from "../../components/Waiting";
import {
  Button,
  InputLabel,
  Grid,
  Typography,
  FormControl,
  MenuItem,
  IconButton,
  Tooltip,
  InputAdornment,
  TextField,
  Select,
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import AvatarForm from "./AvatarForm";
import ChangePasswordDialog from "./ChangePassword";
import ChangeEmailDialog from "./ChangeEmail";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { GET_USER_PROFILE } from "../../graphql/queries";
import { UPDATE_USER_PROFILE } from "../../graphql/mutations";
import { useQuery, useMutation } from "@apollo/client";
import { useAppContext } from "../../context/AppContext";

export default function AccountDetailsForm(props) {
  const { actions, store } = useAppContext();
  const inputLabel = useRef(null);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { lsEnqueueSnackbar } = useCustomSnackbar();
  const [date, setDate] = useState(new Date());
  const handleDateChange = (newDate) => {
    setDate(newDate);
  };

  const phoneRegExp =
    /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/;

  // Create object for update user profile data
  const { data: getUPData, loading: getUPLoading } = useQuery(
    GET_USER_PROFILE,
    {
      fetchPolicy: "no-cache",
      onError: (e) => {
        lsEnqueueSnackbar(e.message, {
          variant: "error",
        });
      },
      onCompleted: () => {
        setDate(getUPData.userProfile.user.birthdate);
      },
    }
  );

  let userProfile = getUPData?.userProfile?.user;

  const validationSchema = Yup.object({
    username: Yup.string().required(t("Required")),
    firstname: Yup.string()
      .max(15, t("FifteenOrFewerChars"))
      .required(t("Required")),
    lastname: Yup.string()
      .max(20, t("TwentyOrFewerChars"))
      .required(t("Required")),
    phone: Yup.string()
      .max(15, t("FifteenOrFewerChars"))
      .matches(phoneRegExp, t("ValidPhone")),
    website: Yup.string().url(t("ValidURL")),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstname: userProfile?.firstname ? userProfile?.firstname.trim() : "",
      lastname: userProfile?.lastname ? userProfile.lastname.trim() : "",
      title: userProfile?.title ? userProfile.title.trim() : "",
      suffix: userProfile?.suffix ? userProfile.suffix.trim() : "",
      gender: userProfile?.gender ? userProfile.gender.trim() : "",
      pronoun: userProfile?.pronoun ? userProfile.pronoun.trim() : "",
      birthdate: userProfile?.birthdate ? userProfile.birthdate : "",
      username: userProfile?.username ? userProfile.username.trim() : "",
      phone: userProfile?.phone ? userProfile.phone.trim() : "",
      website: userProfile?.website ? userProfile.website.trim() : "",
      address: userProfile?.address ? userProfile.address.trim() : "",
      city: userProfile?.city ? userProfile.city.trim() : "",
      state: userProfile?.state ? userProfile.state.trim() : "",
      postalcode: userProfile?.postalcode ? userProfile?.postalcode.trim() : "",
      country: userProfile?.country ? userProfile.country.trim() : "",
      language: userProfile?.language ? userProfile.language.trim() : "",
      timezone: userProfile?.timezone ? userProfile.timezone.trim() : "",
      privacy: userProfile?.privacy ? userProfile.privacy.trim() : "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const vars = {
        variables: {
          up: {
            firstname: values.firstname === "" ? null : values.firstname.trim(),
            lastname: values.lastname === "" ? null : values.lastname.trim(),
            title: values.title === "" ? null : values.title.trim(),
            suffix: values.suffix === "" ? null : values.suffix.trim(),
            gender: values.gender === "" ? null : values.gender.trim(),
            pronoun: values.pronoun === "" ? null : values.pronoun.trim(),
            birthdate: date === "" ? null : date,
            phone: values.phone === "" ? null : values.phone.trim(),
            website: values.website === "" ? null : values.website.trim(),
            address: values.address === "" ? null : values.address.trim(),
            city: values.city === "" ? null : values.city.trim(),
            state: values.state === "" ? null : values.state.trim(),
            postalcode:
              values.postalcode === "" ? null : values.postalcode.trim(),
            country: values.country === "" ? null : values.country.trim(),
            language: values.language === "" ? null : values.language.trim(),
            timezone: values.timezone === "" ? null : values.timezone.trim(),
            privacy: values.privacy === "" ? null : values.privacy.trim(),
          },
        },
      };

      // Update the user profile on the server
      updateUserProfile(vars)
        .then((res) => {
          if (res.errors) {
            lsEnqueueSnackbar(res.errors[0].message, {
              variant: "error",
            });
            setSubmitting(false);
          } else {
            lsEnqueueSnackbar(t("ProfileUpdated"), {
              variant: "success",
            });
            props.setCurrentLanguage(values.language);
            i18n.changeLanguage(values.language);
            // update userDetails state variable with new values
            let newUserDetails = store.userDetails;
            for (let p in values) {
              newUserDetails.user[p] = values[p];
            }
            actions.setUserDetails(newUserDetails);
            navigate("/app");
          }
        })
        .catch((e) => {
          lsEnqueueSnackbar(e.message, {
            variant: "error",
          });
          setSubmitting(false);
        }); // catch network errors
    },
  });

  const [
    updateUserProfile,
    // { loading: updateUPLoading, error: updateUPError },
  ] = useMutation(UPDATE_USER_PROFILE);

  let pageResults;
  if (getUPLoading) {
    pageResults = <Waiting />;
  } else if (getUPData) {
    // setDate(getUPData.userProfile.user.birthdate);
    pageResults = (
      <div>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2} paddingLeft="20%" paddingRight="20%">
            <Grid item xs={12}>
              <Typography
                component="h1"
                variant="h5"
                sx={{ marginBottom: 3, fontWeight: "bold" }}
              >
                {t("AccountDetails")}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <AvatarForm />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                name="firstname"
                variant="outlined"
                required
                fullWidth
                id="firstname"
                label={t("FirstName")}
                value={formik.values.firstname}
                onChange={formik.handleChange}
                error={
                  formik.touched.firstname && Boolean(formik.errors.firstname)
                }
                helperText={formik.touched.firstname && formik.errors.firstname}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="lastname"
                label={t("LastName")}
                value={formik.values.lastname}
                onChange={formik.handleChange}
                error={
                  formik.touched.lastname && Boolean(formik.errors.lastname)
                }
                helperText={formik.touched.lastname && formik.errors.lastname}
              />
            </Grid>

            {/* <Grid item xs={12} sm={6}>
              <FormControl
                variant="outlined"
                sx={{
                  margin: 0,
                  width: "100%",
                  display: "flex",
                  flexWrap: "nowrap",
                }}
              >
                <InputLabel ref={inputLabel} id="title-label">
                  {t("Title")}
                </InputLabel>
                <Select
                  label={t("Title")}
                  labelId="title-label"
                  id="title"
                  name="title"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                >
                  <MenuItem value={"Mr"}>{t("Mr")}</MenuItem>
                  <MenuItem value={"Ms"}>{t("Ms")}</MenuItem>
                  <MenuItem value={"Mx"}>{t("Mx")}</MenuItem>
                </Select>
              </FormControl>
            </Grid> */}

            {/* <Grid item xs={12} sm={6}>
              <FormControl
                variant="outlined"
                sx={{
                  margin: 0,
                  width: "100%",
                  display: "flex",
                  flexWrap: "nowrap",
                }}
              >
                <InputLabel ref={inputLabel} id="suffix-label">
                  {t("Suffix")}
                </InputLabel>
                <Select
                  label={t("Suffix")}
                  labelId="suffix-label"
                  id="suffix"
                  name="suffix"
                  defaultValue=""
                  value={formik.values.suffix}
                  onChange={formik.handleChange}
                >
                  <MenuItem value={"Jr"}>{t("Jr")}</MenuItem>
                  <MenuItem value={"Sr"}>{t("Sr")}</MenuItem>
                  <MenuItem value={"III"}>{"III"}</MenuItem>
                </Select>
              </FormControl>
            </Grid> */}

            {/* <Grid item xs={12} sm={4}>
              <FormControl
                variant="outlined"
                sx={{
                  margin: 0,
                  width: "100%",
                  display: "flex",
                  flexWrap: "nowrap",
                }}
              >
                <InputLabel ref={inputLabel} id="gender-label">
                  {t("Gender")}
                </InputLabel>
                <Select
                  label={t("Gender")}
                  labelId="gender-label"
                  id="gender"
                  name="gender"
                  defaultValue=""
                  value={formik.values.gender}
                  onChange={formik.handleChange}
                >
                  <MenuItem value={"Male"}>{t("Male")}</MenuItem>
                  <MenuItem value={"Female"}>{t("Female")}</MenuItem>
                  <MenuItem value={"Nonbinary"}>{t("Nonbinary")}</MenuItem>
                </Select>
              </FormControl>
            </Grid> */}

            {/* <Grid item xs={12} sm={4}>
              <FormControl
                variant="outlined"
                sx={{
                  margin: 0,
                  width: "100%",
                  display: "flex",
                  flexWrap: "nowrap",
                }}
              >
                <InputLabel ref={inputLabel} id="pronoun-label">
                  {t("Pronoun")}
                </InputLabel>
                <Select
                  label={t("Pronoun")}
                  labelId="pronoun-label"
                  id="pronoun"
                  name="pronoun"
                  defaultValue=""
                  value={formik.values.pronoun}
                  onChange={formik.handleChange}
                >
                  <MenuItem value={"He"}>{t("He")}</MenuItem>
                  <MenuItem value={"She"}>{t("She")}</MenuItem>
                  <MenuItem value={"They"}>{t("They")}</MenuItem>
                </Select>
              </FormControl>
            </Grid> */}

            {/* <Grid item xs={12} sm={4}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  id="birthdate"
                  name="birthdate"
                  label={t("Birthdate")}
                  value={date}
                  onChange={handleDateChange}
                  sx={{ width: '100%' }}
                  disableFuture
                  inputVariant="outlined"
                  fullWidth
                />
              </LocalizationProvider>
            </Grid> */}

            <Grid item xs={12}>
              <TextField
                variant="filled"
                disabled
                fullWidth
                id="username"
                label={t("UserName")}
                name="username"
                value={formik.values.username}
                onChange={formik.handleChange}
                error={
                  formik.touched.username && Boolean(formik.errors.username)
                }
                helperText={formik.touched.username && formik.errors.username}
              />
            </Grid>

            {/* <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                id="phone"
                label={t("PhoneNumber")}
                name="phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Include country code with +">
                        <IconButton aria-label="help">
                          <HelpOutlineIcon />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid> */}

            {/* <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                id="website"
                label={t("Website")}
                name="website"
                value={formik.values.website}
                onChange={formik.handleChange}
                error={formik.touched.website && Boolean(formik.errors.website)}
                helperText={formik.touched.website && formik.errors.website}
              />
            </Grid> */}

            {/* <Grid item xs={12}>
              <Typography
                component="h1"
                variant="h5"
                sx={{ marginTop: 3, marginBottom: 3 }}
              >
                {t("Address")}
              </Typography>
            </Grid> */}

            {/* <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                id="address"
                label={t("Address")}
                name="address"
                value={formik.values.address}
                onChange={formik.handleChange}
              />
            </Grid> */}

            {/* <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                fullWidth
                id="city"
                label={t("City")}
                name="city"
                value={formik.values.city}
                onChange={formik.handleChange}
              />
            </Grid> */}

            {/* <Grid item xs={12} sm={6}>
              <FormControl
                variant="outlined"
                sx={{
                  margin: 0,
                  width: "100%",
                  display: "flex",
                  flexWrap: "nowrap",
                }}
              >
                <InputLabel ref={inputLabel} id="state-label">
                  {t("State")}
                </InputLabel>
                <Select
                  label={t("State")}
                  labelId="state-label"
                  id="state"
                  name="state"
                  defaultValue=""
                  value={formik.values.state}
                  onChange={formik.handleChange}
                >
                  <MenuItem value={"California"}>California</MenuItem>
                  <MenuItem value={"Ontario"}>Ontario</MenuItem>
                </Select>
              </FormControl>
            </Grid> */}

            {/* <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                fullWidth
                id="postalcode"
                label={t("PostalCode")}
                name="postalcode"
                value={formik.values.postalcode}
                onChange={formik.handleChange}
              />
            </Grid> */}

            {/* <Grid item xs={12} sm={6}>
              <FormControl
                variant="outlined"
                sx={{
                  margin: 0,
                  width: "100%",
                  display: "flex",
                  flexWrap: "nowrap",
                }}
              >
                <InputLabel ref={inputLabel} id="country-label">
                  {t("Country")}
                </InputLabel>
                <Select
                  label={t("Country")}
                  labelId="country-label"
                  id="country"
                  name="country"
                  defaultValue=""
                  value={formik.values.country}
                  onChange={formik.handleChange}
                >
                  <MenuItem value={"Canada"}>{t("Canada")}</MenuItem>
                  <MenuItem value={"United_States"}>
                    {t("UnitedStates")}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid> */}

            <Grid item xs={12}>
              <Typography
                component="h1"
                variant="h5"
                sx={{ marginTop: 3, marginBottom: 3 }}
              >
                {t("Preferences")}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <FormControl
                variant="outlined"
                sx={{
                  margin: 0,
                  width: "100%",
                  display: "flex",
                  flexWrap: "nowrap",
                }}
              >
                <InputLabel ref={inputLabel} id="language-label">
                  {t("PreferredLanguage")}
                </InputLabel>
                <Select
                  label={t("PreferredLanguage")}
                  labelId="language-label"
                  id="language"
                  name="language"
                  defaultValue=""
                  value={formik.values.language}
                  onChange={formik.handleChange}
                >
                  <MenuItem value={"English"}>{t("English")}</MenuItem>
                  <MenuItem value={"French"}>{t("French")}</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* <Grid item xs={12}>
              <FormControl
                variant="outlined"
                sx={{
                  margin: 0,
                  width: "100%",
                  display: "flex",
                  flexWrap: "nowrap",
                }}
              >
                <InputLabel ref={inputLabel} id="timezone-label">
                  {t("PreferredTimeZone")}
                </InputLabel>
                <Select
                  label={t("PreferredTimeZone")}
                  labelId="timezone-label"
                  id="timezone"
                  name="timezone"
                  defaultValue=""
                  value={formik.values.timezone}
                  onChange={formik.handleChange}
                >
                  <MenuItem value={"AST"}>AST</MenuItem>
                  <MenuItem value={"EST"}>EST</MenuItem>
                  <MenuItem value={"CST"}>CST</MenuItem>
                  <MenuItem value={"MST"}>MST</MenuItem>
                  <MenuItem value={"PST"}>PST</MenuItem>
                </Select>
              </FormControl>
            </Grid> */}

            {/* <Grid item xs={12}>
              <FormControl
                variant="outlined"
                sx={{
                  margin: 0,
                  width: "100%",
                  display: "flex",
                  flexWrap: "nowrap",
                }}
              >
                <InputLabel ref={inputLabel} id="privacy-label">
                  {t("Privacy")}
                </InputLabel>
                <Select
                  label={t("Privacy")}
                  labelId="privacy-label"
                  id="privacy"
                  name="privacy"
                  defaultValue=""
                  value={formik.values.privacy}
                  onChange={formik.handleChange}
                >
                  <MenuItem value={"RestrictComms"}>{t("Restrict")}</MenuItem>
                  <MenuItem value={"DoNotRestrictComms"}>
                    {t("DoNotRestrict")}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid> */}

            <Grid container justifyContent="flex-end">
              <Grid item sx={{ textAlign: "right" }}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  sx={{ marginTop: 3, marginRight: 0 }}
                >
                  {t("Update")}
                </Button>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <ChangeEmailDialog />
            </Grid>

            <Grid item xs={12} sx={{ marginTop: 2 }}>
              <ChangePasswordDialog siteConfig={props.siteConfig} />
            </Grid>
          </Grid>
        </form>
      </div>
    );
  } else {
    pageResults = "";
  }
  return <div>{pageResults}</div>;
}
