import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  English: {
    translation: {
      AccountDetails: "Account Details",
      ActivatePlugin: "Activate Plugin",
      AcceptTestTitle: "Accept Exercise",
      AcceptTestBody: "Click below to confirm that you wish to accept this exercise. If you change your mind, you can decline the exercise later.",
      AcceptTestRequired: "Accept exercise required?",
      AcceptTestRequiredHelp: "When set, exercise participants are shown, and must accept the terms & conditions before starting the exercise.",
      Accepted: "Accepted",
      Address: "Address",
      AddTranslation: "Add Translation",
      AddNewTag: "Add '{{newTagName}}' as a new tag",
      AddFilter: "Add Filter",
      AlreadyHaveAccount: "Already have an account? Sign in",
      AlreadyHaveCode: "Already have a code? Verify it",
      AssignmentDate: "Assignment Date",
      AssignRights: "Assign Rights",
      AssignRoles: "Assign Roles",
      AssignRolesToUsers: "Assign Roles to Users",
      AssigneeRejectAllowed: "Allow participant to decline?",
      AssigneeRejectAllowedHelp: "When set, participants can decline to do an exercise, or cancel an exercise after they start.",
      Author: "Author",
      AutoSelect: "Auto-select output?",
      AutoSelectPath: "Auto-select path",
      Avatar: "Avatar",
      Birthdate: "Birthdate",
      ByIssue: "By Issue",
      Canada: "Canada",
      CanChangeEmailLater:
        "You will be able to change your e-mail later, once you are signed up",
      ConfirmRevokeJurorAssignmentTitle: "Confirm Revoke Juror Assignment",
      ConfirmRevokeJurorAssignmentContent: 
        "Are you sure you want to revoke the existing juror assignment?",  
      ConfirmArchiveTestTemplateTitle: "Confirm Archive Exercise Templates", 
      ConfirmArchiveTestTemplateBody: "Are you sure you want to archive the selected exercise templates?",
      JurorAssignmentRevoked: "Juror assignment was successfully revoked",  
      ErrorRevokingJurorAssignment: "There was an error when revoking the juror assignment. Please try again.",
      ChangeAvatar: "Change Avatar",
      ChangeAvatarText: "Here is where you change your avatar",
      ChangeEmail: "Change E-mail",
      ChangeEmailDialogText:
        "Complete the form below to change your e-mail address",
      ChangeLogo: "Change Logo",
      ChangeLogoText: "Here is where you change your logo",
      ChangePassword: "Change Password",
      ChangePasswordDialogText:
        "Complete the form below to change your password",
      ChangeSubdomain: "Change Subdomain",
      ChangeSubdomainDialogText:
        "Complete the form below to change your subdomain",
      Channels: "Channels",
      CheckYourEmail: "Check Your E-mail",
      City: "City",
      Close: "Close",
      CodeResent: "Code sent, check your e-mail",
      Comments: "Comments",
      CommentSystem: "Comment System",
      ConfigureOrganization: "Configure Organization",
      Confirm: "Confirm",
      ConfirmAccount: "Confirm Account",
      ConfirmDeleteOrgRole:
        "Check this box and click the button below to confirm you wish to proceed.",
      ConfirmDeletePubRole:
        "Check this box and click the button below to confirm you wish to proceed.",
      ConfirmEmailAddress: "Confirm E-mail Address",
      ConfirmPassword: "Confirm Password",
      Content: "Content",
      ContentTypes: "Content Types",
      Continuous: "Continuous",
      CopyFrom: "Copy From...",
      CopyOrgRole: "Copy Organization Role",
      CopyOrgRoleDialogText:
        "Use this form to create a new organization role based on a copy of an existing one.",
      CopyPubRole: "Copy Publication Role",
      CopyPubRoleDialogText:
        "Use this form to create a new publication role based on a copy of an existing one.",
      Corporation: "Corporation",
      Country: "Country",
      Create: "Create",
      CreateAssignment: "Create Assignment",
      CreateAudience: "Create Audience",
      CreateBundle: "Create Bundle",
      CreateCampaign: "Create Campaign",
      CreateChannel: "Create Channel",
      CreateContentItem: "Create Content Item",
      CreateContentType: "Create Content Type",
      CreateDomain: "Create a custom domain",
      CreateEmailTemplate: "Create E-mail Template",
      CreateGroup: "Create Group",
      CreateList: "Create List",
      CreateOrg: "Create Organization",
      CreateOrgDialogText: "An organization is required to create, manage, and review exercises in Examplery. You must have a paid agreement with Examplery to create an organization.",
      CreateOrgRole: "Create Organization Role",
      CreateOrgRoleDialogText: "Use this form to create an organization role.",
      CreateOwnArticle: "Create Own Article",
      CreateOwnAsset: "Create Own Asset",
      CreateOwnComment: "Create Own Comment",
      CreatePage: "Create Page",
      CreatePub: "Create Publication",
      CreatePublication: "Create Publication",
      CreatePubRole: "Create Publication Role",
      CreatePubRoleDialogText: "Use this form to create a publication role.",
      CreateRole: "Create Role",
      CreateTaxonomy: "Create Taxonomy",
      CreateTemplate: "Create Template",
      CreateType: "Create Type",
      CreateUser: "Create User",
      CreateWorkflow: "Create Workflow",
      CurrentPassword: "Current Password",
      CurrentRoles: "Current Roles",
      CurrentSubdomain: "Custom Subdomain",
      CustomizeName:
        "Enter your first name and last name to customize the person from whom the invitation e-mail is sent.",
      CustomizeOptions: "Customize Options",
      CustomizeTheme: "Customize Theme",
      CustomizeThemeOptions: "Customize Theme Options",
      CustomSubdomain: "Custom Subdomain",
      Dashboard: "Dashboard",
      DeleteAssignment: "Delete Assignment",
      DeleteAudience: "Delete Audience",
      DeleteBundle: "Delete Bundle",
      DeleteCampaign: "Delete Campaign",
      DeleteChannel: "Delete Channel",
      DeleteEmailTemplate: "Delete E-mail Template",
      DeleteGroup: "Delete Group",
      DeleteList: "Delete List",
      DeleteOrgRole: "Delete Organization Role: {{roleName}}",
      DeleteOrgRoleDialogAffectedUsersPrefix:
        "The following users will be affected by this change:",
      DeleteOrgRoleDialogNoAffectedUsers:
        "Currently no users in the system will be affected by this change.",
      DeleteOrgRoleDialogText:
        "Deleting this organization role will remove the privileges associated with the role from all users who have the role.",
      DeleteOrgRoleDialogOrphanWarning:
        "The following users will be left without any role in the system:",
      DeleteOrgRoleDialogOrphanReassurance:
        "No users will be left without any role in the system as a result of this change.",
      DeleteOthersArticle: "Delete Others' Article",
      DeleteOthersAsset: "Delete Others' Asset",
      DeleteOthersComment: "Delete Others' Comment",
      DeleteOwnArticle: "Delete Own Article",
      DeleteOwnAsset: "Delete Own Asset",
      DeleteOwnComment: "Delete Own Comment",
      DeletePage: "Delete Page",
      DeletePlugin: "Delete Plugin",
      DeletePub: "Delete Publication",
      DeletePubRole: "Delete Publication Role",
      DeletePubRoleDialogAffectedUsersPrefix:
        "The following users will be affected by this change:",
      DeletePubRoleDialogNoAffectedUsers:
        "Currently no users in the system will be affected by this change.",
      DeletePubRoleDialogText:
        "Deleting this publication role will remove the privileges associated with the role from all users who have the role.",
      DeletePubRoleDialogOrphanWarning:
        "The following users will be left without any role in the system:",
      DeletePubRoleDialogOrphanReassurance:
        "No users will be left without any role in the system as a result of this change.",
      DeleteTaxononmy: "Delete Taxonomy",
      DeleteTemplate: "Delete Template",
      DeleteTheme: "Delete Theme",
      DeleteTranslation: "Delete Translation",
      DeleteType: "Delete Type",
      DeleteUser: "Delete User",
      DeleteWorkflow: "Delete Workflow",
      Description: "Description",
      Design: "Design",
      Designer: "Designer",
      Details: "Details",
      DidntReceiveEmail: "Didn't receive an e-mail? Send it again",
      DisplayRight: "Display Rights",
      DisplayRightsText: "This role confers the following rights:",
      DoNotRestrict: "Spam me constantly",
      EarliestStartDate: "Earliest Start Time",
      SetEarliestStartDate: "Set an earliest start time",
      EarliestStartDateTooLate: "Earliest start time leaves insufficient time to complete the exercise.",
      EditAssignment: "Edit Assignment",
      EditAudience: "Edit Audience",
      EditBundle: "Edit Bundle",
      EditCampaign: "Edit Campaign",
      EditChannel: "Edit Channel",
      EditEmailTemplate: "Edit E-mail Template",
      EditGroup: "Edit Group",
      EditList: "Edit List",
      Editor: "Editor",
      Editorial: "Editorial",
      EditOrg: "Edit Organization",
      EditOrgRole: "Edit Organization Role",
      EditOrgRoleDialogText:
        "Use this form to edit the properties of an existing organization role.",
      EditOthersArticle: "Edit Others' Article",
      EditOthersAsset: "Edit Others' Asset",
      EditOthersComment: "Edit Others' Comment",
      EditOwnArticle: "Edit Own Article",
      EditOwnAsset: "Edit Own Asset",
      EditOwnComment: "Edit Own Comment",
      EditPage: "Edit Page",
      EditPub: "Edit Publication",
      EditPubRole: "Edit Publication Role",
      EditPubRoleDialogText:
        "Use this form to edit the properties of an existing publication role.",
      EditTags: "Edit Tags",
      EditTaxonomy: "Edit Taxonomy",
      EditTemplate: "Edit Template",
      EditTheme: "Edit Theme",
      EditTranslation: "Edit Translation",
      EditType: "Edit Type",
      EditUser: "Edit User",
      EditUserRoles: "Edit User Roles",
      EditWorkflow: "Edit Workflow",
      EightOrMoreChars: "Must be at least 8 characters",
      Email: "E-mail",
      EmailSubject: "Subject",
      EmailSubjectRequired: "Subject is required",
      EmailBody: "Body",
      EmailTemplate: "E-mail Template",
      EmailTemplateUpdated: "E-mail template was updated",
      EmailTemplateParameters: "Parameters",
      InsertEmailTemplateParameter: "Insert Parameter",
      RevertToSystemDefault: "Revert to System Default",
      ConfirmRevertToSystemDefaultTitle: "Confirm Revert to System Default",
      ConfirmRevertToSystemDefaultText: "Are you sure you want to revert to the system default e-mail template?",
      ConfirmRevertToSystemDefaultTsAndCsText: "Are you sure you want to revert to the system default terms and conditions?",
      EmailTemplates: "E-mail Templates",
      EmailAddress: "E-mail Address",
      EmailChanged: "E-mail address was changed",
      EmailsMustMatch: "E-mail addresses must match",
      English: "English",
      English_translated: "English",
      EnterUsername:
        "Please re-enter your username and submit the form to receive another e-mail.",
      ExportData: "Export Data",
      Female: "Female",
      FifteenOrFewerChars: "Must be 15 or fewer characters",
      FilterExercises: "Filter Exercises",
      FilterList: "Filter list",
      Financial: "Financial",
      FinancialManager: "Financial Manager",
      FirstName: "First Name",
      ForgotPassword: "Forgot Password",
      ForgotPasswordForm:
        "Forgot your password? Provide your user name below to trigger a password reset.",
      ForgotPasswordLink: "I forgot my password...",
      French: "Français",
      French_translated: "French",
      Gender: "Gender",
      GuestContributor: "Guest Contributor",
      He: "He",
      Home: "Home",
      ImportData: "Import Data",
      ImportSpreadsheet: "Import Spreadsheet",
      InstallPlugin: "Install Plugin",
      InstallTheme: "Install Theme",
      InvalidCredentials: "Invalid user name or password",
      InviteCoowner: "Invite a Co-Owner",
      InviteCoowners: "Invite Co-Owners",
      InviteCoownersText:
        "Provide the name and e-mail address for the co-owner you would like to invite. Remember that this person will have the same privileges as you do in the system.",
      InviteStaff: "Invite Staff",
      InviteStaffText:
        "Provide the name and e-mail address for the staff member you would like to invite. You will be able to assign them a role later.",
      Jr: "Jr",
      LastName: "Last Name",
      LastUpdated: "Last Updated",
      LastUpdatedBy: "Last Updated By",
      LegalConsiderations: "Legal Considerations",
      LegalStructure: "Legal Structure",
      LetsGetBasicInformation: "Let's get some basic information...",
      ListOfRights: "Rights Associated with ",
      LLC: "LLC",
      Logo: "Logo", 
      LowerCaseRequired: "Must contain a lowercase character",
      Male: "Male",
      ManageOrganization: "Manage Organization",
      ManagePublications: "Manage Publications",
      ManageRoles: "Manage Roles",
      ManageSystemObjects: "Manage System Objects",
      ManageUsers: "Manage Users",
      Marketer: "Marketer",
      Marketing: "Marketing",
      ModerateComment: "Moderate Comment",
      Mr: "Mr",
      Ms: "Ms",
      MustBeValidEmail: "Must be a valid e-mail address",
      MustCheckBox:
        "You must check the box to proceed.",
      MyAssignments: "My Assignments",
      Name: "Name",
      NewSubdomain: "New Subdomain",
      NoAccount: "Don't have an account yet? Sign up!",
      NoFilterApplied: "No filter applied",
      None: "None",
      NoPageFound: "Page Not Found",
      NoPageFoundText:
        "We're not quite sure how you arrived here, but this is not actually a page of the application.",
      NotStarted: "Not Started",
      NumberRequired: "Must contain a number",
      IntegerRequired: "Must contain an integer",
      GreaterThanZeroRequired: "Must be greater than zero",
      PositiveRequired: "Must be zero or greater",
      HoursOrMinutesRequired: "Either hours or minutes must be greater than zero",
      ApplicationRequired: "At least one application required",
      Okay: "Okay",
      OneHundredOrFewerChars: "Must be 100 or fewer characters",
      OpenSpreadsheetFileErrorTitle: "Error Opening Spreadsheet",
      OpenSpreadsheetFileErrorContent: "The specified file could not be opened.\n{{errorMessage}}",
      ImportAssigneesTitle: "Import Assignees",
      ImportAssigneesExplanation: "To import assignees, you need to specify their location in the spreadsheet. " + 
        "Start by specifying the sheet (i.e. spreadsheet tab), and top row where the table of assignees can be found. " +
        "Then, select the column for each assignee's first name, last name, and email address.",
      WorkSheet: "Sheet",
      ImportDataTopRow: "Top row",
      TopRowContainsHeaders: "Top row contains headers",
      TopRowContainsHeadersHelp: "Set if the top row of the import table contains headers. In this case, the row after the header row will be the first to be imported.",
      SpreadsheetImportColumns: "Import table columns",
      Organization: "Organization",
      OrganizationCreated: "Organization was successfully created",
      OrganizationLevel: "Organization Level",
      OrganizationPending: "Your request to create an Examplery organization has been received. You will be notified by e-mail when your organization has been approved and is available for use.",
      OrganizationPendingTitle: "Organization Pending",
      OrgDetails: "Organization Details",
      OrgName: "Organization Name",
      OrgRoles: "Organization Roles",
      OrgRoleCreated: "Organizaton role was created",
      OrgRoleDeleted: "Organizaton role was deleted",
      OrgRoleUpdated: "Organizaton role was updated",
      OrgRoleDesignerDesc: "Responsible for the organization's brand and style",
      OrgRoleFinancialMgrDesc:
        "Has access to financial data relating to the organization, its staff, and its subscribers",
      OrgRoleMarketerDesc:
        "Drives demand by creating and managing campaigns and outreach",
      OrgRolePublisherDesc:
        "Has access to all aspects of the organization and its publications",
      OrgRoleTechAdminDesc:
        "Manages system functions such as import/export of data and third-party integrations",
      PubRoleContributorDesc:
        "Can create new content and edit their own content",
      PubRoleCopyEditorDesc: "Edits the content created by others",
      PubRoleDesignerDesc:
        "Responsible for layout and look-and-feel of the publication",
      PubRoleEditorDesc:
        "Assigns work, manages workflows and approves publication of content",
      PubRolePublisherDesc: "Has access to all aspects of the publication",
      PubRoleSubscriberDesc:
        "Can read and comment on content according to their subscription tier",
      PubRoleTranslatorDesc: "Can add and edit translations of content",
      OrgUpdateSuccessful: "Organization details were updated successfully",
      OrgTsAndCsUpdateSuccessful: "Organization terms and conditions were updated successfully",
      OrgURL: "Organization URL",
      OriginalSubdomain: "Original Subdomain",
      OwnerInvited: "The new owner was invited",
      Owners: "Owners / Responsible Parties",
      Password: "Password",
      PasswordChanged: "Password changed successfully",
      PasswordReset: "Password reset successfully",
      PasswordResetForm: "Complete the form below to reset your password.",
      PasswordsMustMatch: "Passwords must match",
      Payables: "Payables Account",
      PaymentDetails: "Payment Details",
      Pending: "Pending ...",
      PhoneNumber: "Phone Number",
      PostalCode: "Postal Code",
      Preferences: "Preferences",
      PreferredLanguage: "Preferred Language",
      PreferredTimeZone: "Preferred Time Zone",
      PremiumApplications: "Premium Applications",
      Privacy: "Privacy Preferences",
      ProfileUpdated: "Your account profile has been updated",
      Pronoun: "Pronoun",
      Publication: "Publication",
      PublicationLevel: "Publication Level",
      Publications: "Publications",
      PublishingModel: "Publishing Model",
      PubCreated: "Publication was successfully created",
      PubRoles: "Publication Roles",
      PubRoleCreated: "Publication role was created",
      PubRoleDeleted: "Publication role was deleted",
      PubRoleUpdated: "Publication role was updated",
      PubUpdated: "Publication details were updated",
      Receivables: "Receivables Account",
      RegionWhereAccountWillBeHosted: "Region Where Account Will Be Hosted",
      Required: "Required",
      RemoveSplashPage: "Remove Splash Page",
      ResendCode: "Resend Code",
      ResetPassword: "Reset Password",
      ResetYourPassword: "Reset Your Password",
      Restrict: "Restrict communications",
      Return: "Return",
      RoleType: "Role Type",
      ScriptTagsForbidden: "<script> tags not permitted",
      SelectImage: "Select Image",
      SelectPrimaryColor: "Choose First Brand Color",
      SelectSecondaryColor: "Choose Second Brand Color",
      SelectThirdColor: "Choose Third Brand Color",
      SelectTheme: "Select Theme",
      SendInvitation: "Send Invitation",
      SettingRolesFor: "You are setting roles for ",
      She: "She",
      ShouldHaveReceivedEmail:
        "You should have received an e-mail with a code; enter the code below to confirm that it is you. Make sure you check your spam folder.",
      SignIn: "Sign In",
      SignInSuccessful: "Sign In Successful!",
      SignOut: "Sign Out",
      SignUp: "Sign Up",
      SignUpConfirmed: "Sign up confirmed",
      SignUpContributor: "Sign Up Contributor",
      SignUpCoowner: "Sign Up Co-Owner",
      SignUpSuccessful: "Sign-up successful!",
      SocialLinks: "Social Links",
      Sr: "Sr",
      StaffInvited: "The new staff member was invited",
      State: "State or Province",
      StartDate: "Start Date",
      Subdomain: "Subdomain",
      SubdomainChanged: "Subdomain was changed successfully",
      Submit: "Submit",
      SuccessfullyVerifiedEmail:
        "You’ve successfully verified your e-mail, click below to continue to log in:",
      Suffix: "Suffix",
      SymbolRequired: "Must contain a symbol",
      Tags: "Tags",
      TargetCompletionDate: "Target Completion Date",
      Taxonomies: "Taxonomies",
      Technical: "Technical",
      TechnicalAdministrator: "Technical Administrator",
      TermsAndConditions: "Terms & conditions",
      Title: "Title",
      Translation: "Translation",
      TwentyOrFewerChars: "Must be 20 or fewer characters",
      TwoThousandOrFewerChars: "Must be 2000 or fewer characters",
      UnitedStates: "United States",
      Update: "Update",
      UpdatePlugin: "Update Plugin",
      UpdateTheme: "Update Theme",
      UploadPlugin: "Upload Plugin",
      UploadTheme: "Upload Theme",
      UpperCaseRequired: "Must contain an uppercase character",
      UserInvited: "The user has been invited to join your organization",
      UserName: "User Name",
      UsernameCannotBeEmail: "User Name cannot be the same as E-mail address",
      UserNameOrEmailAddress: "User Name or E-mail Address",
      ValidPhone:
        "Must be a valid phone number with plus sign and country code",
      ValidSubdomain:
        "The subdomain may not contain any special characters or spaces",
      ValidURL: "Must be a valid URL beginning with http or https",
      VerificationCode: "Verification Code",
      ViewFinancials: "View Financials",
      WelcomeBack: "Welcome Back!",
      WelcomeToLeadStory: "Welcome to LeadStory!",
      WelcomeToLeadStoryContributorText:
        "You have been invited to participate in building the next big media brand! Once you provide your initial details, you will have the access privileges assigned to you by the owner.",
      WelcomeToLeadStoryCoownerText:
        "You have been invited to be a co-owner of a LeadStory organization and its associated publications. This means you will have full access to all the capabilities of the platform, and all of its data.",
      WelcomeToOrg: "Welcome to {{siteConfig.siteName}}!",
      WelcomeToOrgCoownerText:
        "You have been invited to be a co-owner of {{siteConfig.siteName}} and its associated publications. This means you will have full access to all the capabilities of the platform, and all of its data.",
      WhereLocated: "Where are you located?",
      Publisher: "Publisher",
      Contributor: "Contributor",
      CopyEditor: "Copy Editor",
      Subscriber: "Subscriber",
      Translator: "Translator",
      CreateSource: "Create Source",
      EditSource: "Edit Source",
      DeleteSource: "Delete Source",
      CreateFeed: "Create Feed",
      EditFeed: "Edit Feed",
      DeleteFeed: "Delete Feed",
      TopicDiscovery: "Topic Discovery",
      OrgRoleEditorDesc: "Can edit the articles of others",
      OrgRoleContributorDesc: "Can create new articles",
      TopicDiscoveryDashboard: "Topic Discovery Dashboard",
      AddSelectedTo: "Add Selected To...",
      SelectAPublication: "Select a Publication",
      SelectAFeed: "Select a Feed",
      ViewFeed: "View Feed",
      ManageFeeds: "Manage Feeds",
      Score: "Score",
      Unattributed: "Unattributed",
      Save: "Save",
      Saving: "Saving...",
      AsOf: "As of...",
      Manage3rdPartyServices: "Manage Third-Party Services",
      ManageSources: "Manage Sources",
      ManageSourceLists: "Manage Source Lists",
      CreateANewIssue: "Create a New Issue",
      ViewSavedStories: "View Saved Stories",
      BuildAFeed: "Build a Feed",
      FeedBuilderInstructions:
        "You can use this form to build complex feeds combining multiple data sources and your own customized queries.",
      MakeDefault: "Make Default",
      Query: "Query",
      UseQueryBuilder: "Use the Query Builder",
      QueryBuilder: "Query Builder",
      QueryBuilderInstructions:
        "Use the components below to build your query. Note that whatever query you build here will replace what was entered previously.",
      BuiltQuery: "Built Query",
      QueryComponents: "Query Components",
      KeywordInstructions:
        "Provide a list of keywords separated by spaces. These will be treated as joined by 'AND'.",
      And: "And",
      Or: "Or",
      Not: "Not",
      OperatorInstructions:
        "Provide groups of terms separated by spaces that you would like joined in an 'OR' condition:",
      AddSourceLists: "Add Source Lists",
      AddSources: "Add Sources",
      ConfirmFeed: "Confirm Feed",
      FeedCopyInstructions:
        "You can use this form to copy a feed for convenience. Please remember to rename the feed before confirming your changes.",
      WriteAQuery: "Write a Query",
      DeleteFeedInstructions: "Do you want to delete ",
      CheckBoxDeleteFeed:
        "You must check the box to proceed with deleting this feed.",
      DeleteFeedConfirm:
        "I understand that this feed will be deleted permanently.",
      EditAFeed: "Edit a Feed",
      EditFeedInstructions: "You can use this form to edit existing feeds.",
      AddATemplate: "Add a Template",
      PlaceholderText: "This is placeholder text.",
      SavedStories: "Saved Stories",
      SavedStoryInstructions:
        "You have no saved stories. You can save stories from the dashboard after viewing a feed.",
      AvailableServices: "Available Services",
      CurrentlyRegisteredTo: "Currently registered to ",
      Register: "Register",
      ChangeRegistration: "Change Registration",
      UnderstandingRegistrationProcess:
        "Understanding the Registration Process",
      RegistrationInstructions:
        "Once you have click the 'Proceed' button below, a new tab will open and you will be asked to authenticate directly with the service you are registering. After you have authenticated, the service will provide a PIN. Return to this window and enter your PIN to complete registration.",
      EnterPIN: "Enter Your PIN",
      PINInstructions:
        "Enter your PIN from the second tab to complete registration.",
      RemoveServiceInstructions:
        "Note that removing this service here will only affect your individual access. To revoke access from this application completely for all users, visit this service's Web site directly.",
      SetOwners: "Set Owners",
      SetOwnersInstructions:
        "Use this dialog to set the owners of this source list.",
      SourceBuilderInstructions:
        "You can use this form to add sources to your feeds.",
      Type: "Type",
      RSS: "RSS Feed",
      Twitter: "Twitter Handle",
      TwitterInstructions:
        "If you know the exact Twitter handle you want to assign as a source, enter it here:",
      TwitterInstructions2:
        "Otherwise, click the button below to search for Twitter handles.",
      Definition: "Definition",
      FindTwitterHandles: "Find Twitter Handles",
      SearchTwitterInstructions:
        "Enter search terms below and select from the Twitter handles found, if any.",
      NothingWasFound: "Nothing was found.",
      RSSInstructions: "If you know the exact RSS feed URL, enter it here:",
      RSSInstructions2:
        "Otherwise, click the button below to locate RSS feeds on a Web page.",
      FindRSSFeedsAtURL: "Find RSS feeds at this URL",
      FindRSSInstructions:
        "Enter the address of a Web page below and select from the RSS feeds found at that location, if any.",
      ConfirmDeleteSource: "Do you want to delete",
      CheckBoxDeleteSource:
        "You must check the box to proceed with deleting this source.",
      DeleteSourceAffirmation:
        "I understand that this source will be deleted permanently.",
      EditASource: "Edit a Source",
      EditSourceInstructions: "You can use this form to edit a source.",
      RSSURL: "RSS URL",
      CreateASourceList: "Create a Source List",
      SourceListBuilderInstructions:
        "You can use this form to create lists out of sources for convenience.",
      CompletionDate: "Completion Date",
      ConfirmList: "Confirm List",
      CopyASourceList: "Copy a Source List",
      CopySourceListInstructions:
        "You can use this form to copy a source list for convenience. Please remember to rename the source list before confirming your changes.",
      DeleteSourceList: "Delete a Source List",
      ConfirmDeleteSourceList: "Do you want to delete",
      DeleteSourceListInstructions:
        "You must check the box to proceed with deleting this source list.",
      DeleteSourceListAffirmation:
        "I understand that this source list will be deleted permanently.",
      EditASourceList: "Edit a Source List",
      EditSourceListInstructions:
        "You can use this form to edit existing source lists.",
      CreateSourceList: "Create Source List",
      AddASource: "Add a Source",
      SelectPublication: "Select a publication...",
      SelectList: "Select a list...",
      AddSelectedToList: "Add Selected to List",
      Sources: "Sources",
      IsSystem: "Is System",
      Cancel: "Cancel",
      RevokeJurorAssignment: "Revoke Assignment",
      Remove: "Remove",
      ManageWinsAndLosses: "Manage Wins and Losses",
      CreateWinOrLoss: "Create Win or Loss",
      CreateWinStory: "Create Win Story",
      ManageTests: "Manage Exercises",
      CreateTest: "Create Exercise",
      InviteUser: "Invite a New User",
      InviteUserText:
        "Provide the name and e-mail address for the user you would like to invite. You will be able to assign them a role later.",
      UserRolesUpdated: "User roles have been updated",
      DaysTestWillBeAvailable: "Days Exercise Will Be Available",
      ApplicationsAvailableDuringTest: "Applications Available During Exercise",
      Application: "Application",
      TestIsPublic: "Make Exercise Publicly Available",
      Settings: "Settings",
      DownloadAllowed: "Download Allowed",
      UploadAllowed: "Upload Allowed",
      CutAllowed: "Cut Allowed",
      PasteAllowed: "Paste Allowed",
      PauseAllowed: "Pause Exercise Allowed",
      TestAssets: "Assets Available During Exercise",
      ApplicationInput: "Exercise Application Input",
      TimeCandidateHasToCompleteTest: "Time Participant Has to Complete Exercise",
      Hours: "Hours",
      Minutes: "Minutes",
      OutputsFromTest: "Outputs from Exercise",
      FileType: "File Type",
      Draft: "Draft",
      Published: "Published",
      Deprecated: "Deprecated",
      Retired: "Retired",
      OtherSettings: "Other Settings",
      UploadInstructions: "Upload Instructions",
      ManageAssets: "Manage Assets",
      ManageTestTemplates: "Manage Exercise Templates",
      CreateTestTemplate: "Create Exercise Template",
      TestTemplates: "Exercise Templates",
      TestTemplate: "Exercise Template",
      EditTestTemplate: "Edit Exercise Template",
      SelectInstructions: "Select Instructions",
      SelectAsset: "Select Asset",
      AddOutput: "Add Output",
      AddAssetDialogText:
        "Choose an asset to upload to the system. You will be able to reuse this asset without having to upload it again.",
      DeleteAsset: "Delete Asset",
      DeleteAssetDialogText: "Are you sure you want to delete this asset?",
      DeleteTestTemplate: "Delete Exercise Template",
      DeleteTestTemplateDialogText:
        "Are you sure you want to delete this exercise template?",
      Assets: "Assets",
      AddAsset: "Add Asset",
      AssetDeleted: "The specified asset has been deleted.",
      EditAssetDialogText:
        "Use this form to edit the information associated with this asset.",
      ConfirmDeleteAsset:
        "I understand that this asset will be permanently deleted.",
      ConfirmDeleteTestTemplate:
        "I understand that this exercise template will be permanently deleted.",
      DragAssetHere: "Drag Asset Here",
      DropItemHere: "Drop Item Here",
      Accept: "Accept",
      Decline: "Decline",
      Resume: "Resume",
      Forfeit: "Decline",
      Discard: "Discard",
      ViewEvaluation: "View Evaluation",
      Revoke: "Revoke",
      CandidateTableHeader: "Assigned Exercises",
      NoTestsMessage: "You have completed all required exercises! Feel free to log out of the application.",
      NoSubmittedTestsMessage: "You have not yet submitted any exercises. Please be sure that you have accepted and finished the exercises assigned to you in the Assigned to Me tab.",
      SubmittedTests: "Submitted Exercises",
      TestName: "Exercise Name",
      TestDescription: "Exercise Description",
      TestInstances: "Exercise Instances",
      Status: "Status",
      Activate: "Activate",
      Edit: "Edit",
      Delete: "Delete",
      Copy: "Copy",
      CopyUrl: "Copy URL to clipboard",
      Copied: "Copied",
      Assign: "Assign",
      Recall: "Recall",
      Archive: "Archive",
      HiringManagerTableHeader: "Exercise Templates",
      CreateNew: "Create New",
      JurorTableHeader: "Exercises to Review",
      AssignedBy: "Assigned By",
      DueDate: "Due Date",
      TimeRemaining: "Time Remaining",
      draft: "Draft",
      active: "Active",
      deprecated: "Deprecated",
      retired: "Retired",
      created: "Created",
      assigned: "Assigned",
      unmarked: "Unmarked",
      marked: "Marked",
      suspended: "Suspended",
      cancelled: "Cancelled",
      inactive: "Inactive",
      TestTemplateUpdated: "Exercise template updated",
      TestTemplateDeleted: "Exercise template deleted",
      TestTemplateCopied: "Exercise template copied",
      TestTemplateActivated: "Exercise template activated",
      TestTemplateArchived: "Exercise template archived",
      TestTemplateRecalled: "Exercise template recalled",
      TestDeclinedSuccessfully: " declined successfully",
      DeclineTestHeading: "Decline Exercise",
      ForfeitTestHeading: "Decline Exercise",
      DiscardTestHeading: "Discard Exercise",
      RevokeTestHeading: "Revoke Exercise",
      DeclineTestBody: "Click below to confirm that you wish to decline this exercise. You will not be able to reverse this action.",
      ForfeitTestBody: "Click below to confirm that you wish to decline this exercise. You will not be able to reverse this action.",
      DiscardTestBody: "Click below to confirm that you wish to discard this exercise. You will not be able to reverse this action.",
      RevokeTestBody: "Click below to confirm that you wish to revoke this exercise. You will need to assign the exercise again if you change your mind.",
      GoBack: "Go Back",
      TestInstanceAnswerSubmitted: "Exercise instance answer submitted",
      TestInstanceAnswerRemoved: "Exercise instance answer removed",
      TestSubmitted: "Exercise submitted",
      SubmitTestHeading: "Submit Exercise",
      SubmitTestBodyNoTimeRemaining:
        "When you submit the exercise, your results will be finalized and the results will be evaluated by an expert.",
      SubmitWebsiteTestBodyNoTimeRemaining:
        "The available time to complete the exercise has elapsed. Your responses have been saved. Click the submit button to submit your answers.",
      SubmitTestBodyTimeRemaining:
        "When you submit the exercise, your results will be finalized and the results will be evaluated by an expert.",
      SubmitWebsiteTestBodyTimeRemaining:
        "Ready to Submit? The responses you've provided have already been saved. However, you can chose to edit them by clicking “Continue Exercise” below or, if you've completed the exercise, click “SUBMIT”.",
      FileToSubmit: "File to Submit",
      ReturnToDashboard: "Return to Dashboard",
      ReturnToTest: "Continue Exercise",
      AppstreamHeader: "Your Exercise Is In Progress",
      AppstreamBody: "Please refer to the following instructions",
      TestIntroduction: "This is introductory text explaining the exercise.",
      DownloadTestIntroductionFileExplanation:
        "You can download the instructions for the exercise using the link below.",
      WorkTime: "Work Time",
      WorkTimeRemaining: "Work Time Remaining",
      PauseTest: "Pause Exercise",
      FinishTest: "Finish Exercise",
      RichText: "Rich Text",
      Instructions: "Instructions",
      TargetPath: "Target Path",
      Sequence: "Sequence",
      Extension: "Extension",
      TermsAndConditionsTitle: "Terms and Conditions",
      TermsAndConditionsDefault:
        "By using this application, you understand that you are giving permission to Examplery and to the organization offering you this exercise to record, store, and analyze your activity within this Web application for the purpose of determining your level of skill with the applications and tools provided in the exercise. You are not granting any intellectual property rights to your work or output in this exercise, nor giving any license for the organization offering you this exercise to resell or otherwise make commercial use of your work for any purpose beyond assessment of your skills.",
      AcceptAndBegin: "Accept and Begin",
      AddParticipant: "Add Participant",
      AssignCandidateConfirmation: "Assign Participants Confirmation",
      AssignCandidateConfirmationExplanation:
        "By submitting this form, you will trigger an e-mail inviting each participant to do the exercise.",
      AssignCandidate: "Assign Participant",
      AssignCandidates: "Assign Participants",
      AssignCandidateExplanation:
        "Fill out the form to invite a participant to do an exercise.",
      AssignCandidatesExplanation:
        "Fill out the form to invite participants to do an exercise.",
      CopyTestTemplate: "Copy Exercise Template",
      ChooseFolder: "Choose Folder",
      ChooseFile: "Choose File",
      Owner: "Owner",
      Candidate: "Participant",
      HiringManager: "Hiring Manager",
      Juror: "Juror",
      FinancialAdministrator: "Financial Administrator",
      TestDesigner: "Exercise Designer",
      Recruiter: "Recruiter",
      ReplaceAsset: "Replace Asset",
      or: "or",
      InProgress: "In Progress",
      CompletedNotSubmitted: "Completed, Not Submitted",
      NotCompleted: "Not completed",
      NotYetStarted: "Not yet started",
      NotYetCompleted: "Not yet completed",       
      NotApplicable: "N/A",
      Evaluated: "Evaluated",
      ChangeSendEmailAddressExplanation: "Specify the organization email address that Examplery should use to send notifications. You will have to verify that you are the owner of the email address. Leave the fields blank to reset to the Examplery system default email address.",
      CheckYourEmailPendingSendEmailExplanation: "An email has been sent to {{email}}. Follow the instructions in the email to verify your email address.",
      OrgSendEmailVerifiedSuccessTitle: "Email Successfully Verified",
      OrgSendEmailVerifiedSuccessBody: "You have successfully verified your email address. All future notifications for your Examplery organization will come from: {{email}}.",
      OrgSendEmailVerifiedFailureTitle: "Email Verification Failed",
      OrgSendEmailVerifiedFailureBody: "We were unable to verify email address: {{email}}. Please check your email address and try again.",
      OrgSendEmailClearedSuccess: "Send email address reset to Examplery default email address successfully.",
      OrgSplashPage: "Organization Splash Page",
      UploadNewSplashPage: "Upload New Splash Page",
      CanCloseBrowserWindow: "You can now close this browser window.",
      PendingEvaluation: "Pending Evaluation",
      PendingEmailVerification: "Pending E-mail Verification",
      NoTestTemplatesMessage: "There are no exercise templates.",
      WelcomeToOrgText:
        "Welcome to the organization! You will now have access to a number of features.",
      HiringManagerAssignments: "Assigned Exercises",
      AssignCandidateForm: "Assign Participant",
      FullScreen: "Full Screen",
      EndSession: "End Session",
      RefreshEnvironment: "Refresh Environment",
      ReviewSubmittedTest: "Review Submitted Exercise",
      ReviewSubmittedTestTitle: "Review Submitted Exercise",
      TestAnswers: "Exercise Answers",
      TestRecordings: "Exercise Recordings",
      WorkTimeElapsed: "Work Time Elapsed",
      TestSessionEnded: "Exercise Session Ended",
      TestSessionEndedDescription: "You can return to the dashboard.",
      TestSessionErrorTitle: "An Error Has Occurred",
      TestSessionErrorText:
        "Please contact your administrator for more information.",
      EnvironmentStarting: "Spinning up the environment...",
      WelcomeToEnvironment: "Your exercise is in progress",
      WelcomeToEnvironmentDescription: "Enjoy demonstrating your skills!",
      ApplicationPaletteText: "Please select an application to begin...",
      NoAssignmentsMessage: "There are no assigned exercises.",
      DashboardPlaceholderText: "Welcome to Examplery!",
      ApplicationTermsAndConditions: "By using this service, you agree that your use of this service and any software that may be provided during your use of this service is limited to the purpose of assessing skills. In addition, you agree that information about your use of the service may be collected for analysis and future improvement by the service.",
      ReviewAppTerms: "Review terms and conditions",
      DashboardTitle: "Join the premium technical and creative screening platform.",
      DashboardSubtitle: "Examplery is a better way to match technical and creative candidates to companies looking to hire the best talent on the market.",
      RecruiterDashDescription: "Are you a recruiter hiring for an amazing company who is building a diverse workforce and looking for the best people with tried and tested skills for the job?",
      CandidateDashDescription: "Are you an incredible talent who is tired of your résumé getting lost and who wants the opportunity to demonstrate your specialized skills?",
      OrganizationDashDescription: "Are you a company looking to save countless recruiting hours by white-labeling our testing platform into your recruiting workflow?",
      ReadyToSignUp: "Ready to sign up?",
      LanguageAndRegionSettings: "Language and Region Settings",
      LanguageAndRegionSettingsDescription: "Set your language, time zone, and region preferences.",
      Language: "Language",
      LanguageDescription: "Set your language preference.",
      ContactInformation: "Contact Information",
      ContactInformationDescription: "Please provide your name, a user name of your choice, and your e-mail address.",
      PasswordDescription: "Choose a secure password.",
      TermsAndConditionsDescription: "Read and agree to the terms and conditions.",
      Reset: "Reset",
      TestAssigned: "The exercise was assigned.",
      TestAssignmentErrors: "One or more exercises were not assigned. Check the assignees error information icon for details.",
      FortyOrFewerChars: "Must be 40 or fewer characters",
      BrowseFiles: "Browse Files",
      RegionalSettings: "Regional Settings",
      ExitFullScreen: "Exit Full Screen",
      Paste: "Paste",
      Due: "Due",
      TotalTime: "Total Time",
      TestSettings: "Exercise Settings",
      AppSwitcher: "Switch Applications",
      BulkActions: "Bulk Actions",
      Available: "Available",
      Submitted: "Submitted",
      AssignedToMe: "Assigned to Me",
      AssignedToOthers: "Assigned to Others",
      RowsPerPage: "Rows Per Page",
      Review: "Review",
      MarkingDueDate: "Marking Due Date",
      Actions: "Actions",
      TestTemplateCreated: "Exercise Template Created",
      AvailableToAssign: "Available to Assign",
      ReadyToReview: "Ready to Review",
      SubmittedByMe: "Submitted by Me",
      OrganizationDetails: "Organization Details",
      InviteToOrganization: "Invite to Organization",
      ManageTestAssets: "Manage Exercise Assets",
      DeadlinePassed: "Deadline Passed",
      SignOutConfirmation: "Confirm Sign Out",
      SignOutConfirmationExplanation: "Please confirm that you actually want to sign out of the application, or click Cancel to go back to using the software.",
      SelectAssets: "Select Assets",
      SelectAssetDialogText: "Use the form below to select assets to include in the exercise template.",
      SubmitTestConfirmation: "Are you ready to submit this exercise?",
      SubmitTestConfirmationExplanation:
        "Are You Sure? If you click “Confirm”, you will conclude the exercise and your responses cannot be changed. If you'd like to return to your responses, press “Cancel”.",
      ReservationTimeRemaining: "Reserved Time Remaining",
      FileBrowser: "File Browser",
      TestInstructions: "Instructions",
      ShowInstructions: "Show Instructions",
      ErrorWithAssignmentTime: "The assigned date does not provide enough time for the user to actually complete the exercise.",
      ExtendTestInstanceTitle: "Extend Exercise Instance",
      ExtendTestInstanceExplanation: "Select the new target completion date for this exercise.",
      TestInstanceExtended: "The exercise instance completion time was successfully extended.",
      ErrorWithTestInstanceExtension: "The attempt to extend the exercise instance has failed.",
      ErrorDownloadReport: "The attempt to download the exercise instance report has failed.",
      AddTime: "Add Time",
      CancelTestAcceptanceExplanation: "If you are not ready to accept these terms and conditions, you may cancel and return to the dashboard.",
      AcceptAndStartTestExplanation: "If you are ready to accept these terms, and would like to start the exercise now, you may do so.",
      AcceptAndStartTest: "Accept and Start Exercise",
      AcceptAndMakeReservationExplanation: "If you are ready to accept these terms, and would like to make a reservation to do the exercise, please select your first reservation from the available times below. If insufficient time is available to complete the exercise in one reserved session, you will have the opportunity to reserve additional time once your first reservation has elapsed.",
      AcceptAndMakeReservation: "Accept and Make Reservation",
      ReserveTime: "Reserve Time",
      ReservationMade: "Reservation made",
      MakeReservationTitle: "Choose When to Begin Your Exercise",
      DoNotStartOrMakeReservationExplanation: "If you are not yet ready to begin your exercise, you can cancel and return to the dashboard. You will be able to reserve time to do your exercise at your convenience.",
      StartTestExplanation: "There is time available to start your exercise now. If you would like to do so, you can start whenever you would like. A reservation for the full remaining exercise time will be made for you automatically.",
      StartTestNow: "Start Exercise Now",
      MakeReservationExplanation: "If you would like to make a reservation to do your exercise at a later time, choose your preferred start time from the list of available options below, and confirm your reservation.",
      ReservationDate: "Reservation Date",
      SelectedTimeExceedsWorkTimeRemaining: "The reserved time you attempted to select exceeds the total time remaining for the exercise. Please select a time within the indicated bars.",
      MustReserveEntireTestTime: "The reserved time you attempted to select is less than the duration of the exercise. Please select a time at one of the indicated bars.",
      CancelReservationHeading: "Cancel Reservation",
      CancelReservationBody: "You have a current reservation but may cancel it. Click below to confirm if you would like to cancel your reservation. You will have the opportunity to make a new reservation.",
      StartTest: "Start Exercise",
      UpcomingReservation: "Reserved for",
      TestAccepted: "The exercise was successfully accepted.",
      CancelReservation: "Cancel Reservation",
      ReservationDeclined: "The reservation was cancelled",
      NoTestsToReviewMessage: "There are no exercises ready to review.",
      OptionsDuringTest: "Options Available During Exercise",
      GeneralInformation: "General Information",
      BasicSettings: "Basic Settings",
      Inputs: "Inputs",
      Applications: "Applications",
      OutputsTitle: "Outputs",
      RubricsTitle: "Rubric",
      ScorecardTitle: "Scorecard",
      Categories: "Categories",
      AddCategory: "Add Category",
      HasOverallScore: "Exercise has an Overall Score",
      HasCategoryScore: "Exercise is Scored at the Level of Individual Categories",
      OverallScoreRange: "Overall Score Range",
      OverallScoreDetails: "Details for Overall Score",
      CategoryScoreRange: "Score Range",
      CategoryScoreDetails: "Score Details",
      CancelTestInstanceTitle: "Cancel Exercise",
      CancelTestInstanceExplanation: "By confirming this action, you will cancel this exercise. The participant will no longer be able to do the exercise. You will be able to re-invite the participant.",
      TestInstanceCancelled: "The exercise instance was cancelled.",
      ErrorWithTestInstanceUncancellation: "There was an error with cancelling the exercise instance.",
      unmarked_complete: "Complete",
      ViewResults: "View Results",
      DownloadReport: "Download Report",
      DownloadTestInstanceReportTitle: "Generate and Download Report",
      DownloadTestInstanceReportExplanation: "Generate and download a PDF report for the selected exercise instance.",
      DownloadTestInstanceReportExplanationMultiple: "Generate and download a zip file containing PDF reports for the {{numReports}} selected exercise instances.",
      DownloadRollupReportExplanation: "Generate and download a PDF report that aggregates (i.e.: rolls up) the results for the {{numReports}} selected exercise instances.",
      ReportTemplates: "Report Templates",
      UploadReportTemplate: "Upload Report Template",
      Upload: "Upload",
      ChooseReportTemplate: "Choose a custom template",
      DefineReportCompareWith: "Specify custom peer group",
      CompareWithHelp: "The report includes statistics comparing the exercise participant's scores to a peer group. You can specify which exercises are in the peer group by specifying a set of filter rules. If you don't specify a peer group, the peer group when generating a report for a single exercise will be all marked exercises for the exercise template, while the peer group when generating reports for multiple exercises will be the set of exercises for which reports are being generated.",
      ReportType: "Report type",
      TestInstanceReport: "Exercise report",
      RollupReport: "Roll-up report",
      MarkTest: "Mark Exercise",
      AssignJuror: "Assign Juror",
      AssignJurorExplanation: "Use this form to select a juror to assign to mark this exercise. If you do not see the desired juror in the list of options to select, you may need to add that individual to your organization.",
      AssignJurorConfirmation: "Assign Juror Confirmation",
      AssignJurorConfirmationExplanation: "Please confirm that you would like to assign the indicated juror to mark this exercise.",
      AssignmentAccepted: "The assignment was accepted.",
      AssignmentRejected: "The assignment was rejected.",
      ErrorWithAcceptingAssignment: "There has been an error with accepting the assignment.",
      ErrorWithRejectingAssignment: "There has been an error with rejecting the assignment",
      AcceptAssignmentTitle: "Accept Assignment",
      AcceptAssignmentExplanation: "Please confirm that you accept the assignment to evaluate the indicated exercise.",
      RejectAssignmentTitle: "Reject Assignment",
      RejectAssignmentExplanation: "Please confirm that you reject the assignment to evaluate the indicated exercise. You will no longer have the opportunity to evaluate the exercise.",
      MarkTestTitle: "Mark Exercise",
      TestOutputs: "Exercise Outputs",
      TestMarked: "The exercise was marked.",
      OverallScore: "Overall Score",
      WeightedCategoryScore: "Weighted Category Score",
      TestInstructionsJurorComment: "The following instructions were provided to the exercise participant:",
      TestScoresSaved: "The exercise scores have been saved.",
      TestScoresSubmitted: "The exercise scores have been submitted.",
      SaveScores: "Save Scores",
      SubmitScores: "Submit Final Scores",
      TestAssignedJuror: "The exercise was assigned to the juror.",
      JurorAssigned: "Juror Assigned",
      RichTextEditorInstructions: "Please note that you can paste images into the rich text editor.",
      NoScoreProvided: "No score was provided for this exercise.",
      MainDashDescription: "Let job candidates demonstrate their skills with the same software they'll use on the job",
      OutputNotSubmitted: "No output was provided",
      OutOf: "out of",
      AvailableForTest: "available for the exercise",
      TestAlertThresholdReached: "Alert: {{timeRemaining}} remaining on the exercise.",
      TestWarningThresholdReached: "Warning: {{timeRemaining}} remaining on the exercise.",
      ReservationAlertThresholdReached: "Alert: {{timeRemaining}} remaining before your reservation expires.",
      ReservationWarningThresholdReached: "Warning: {{timeRemaining}} remaining before your reservation expires.",
      TestPlatform: "Exercise Delivery Platform:",
      AppStream: "Streaming applications",
      WebSite: "Web application",
      ChangeTestPlatformTitle: "Change Exercise Delivery Platform",
      ChangeTestPlatformExplanation: "Changing the exercise delivery platform will cause the exercise's applications, inputs, outputs, and some options to be reset. Are you sure you want to change the exercise platform?",
      Test: "Exercise",
      InAny: "In any",
      InAll: "In all",
      NotIn: "Not in",
      StartsWith: "Starts with",
      NotStartsWith: "Not starts with",
      FilterField: "Field",
      FilterOperator: "Operation",
      FilterValues: "Values",
      IncompleteFilterRules: "Filter rule is incomplete.",
      SendEmailAddress: "Send Email From",
      ChangeSendEmailAddress: "Change Send Email Address",
      CancelPendingSendEmailAddressChange: "Cancel Pending Change",
      ConfirmCancelPendingSendEmailChange: "Confirm Cancel Pending Send Email Change",
      ConfirmCancelPendingSendEmailText: "Are you sure you want to cancel the pending send email address change?",
      NewSendEmailAddressSameAsCurrent: "The specified send email address is the same as the current send email address."
    },
  },
  French: {
    translation: {
      AcceptTestTitle: "Accepter l'exercice",
      AcceptTestBody: "Cliquez ci-dessous pour confirmer que vous souhaitez accepter cet exercice. Si vous changez d'avis, vous pourrez refuser l'exercice plus tard.",
      Accepted: "Accepté",
      AcceptTestRequired: "Acceptation de l'exercice requise?",
      AcceptTestRequiredHelp: "Lorsque cette option est activée, les participants à l'exercice doivent lire et accepter les conditions générales avant de commencer l'exercice.",
      AccountDetails: "Détails du compte",
      ActivatePlugin: "Activer le plugin",
      Address: "Adresse postale",
      AddTranslation: "Ajouter une traduction",
      AddNewTag: "Ajouter '{{newTagName}}' comme nouveau tag",
      AddFilter: "Ajouter un filtre",
      AlreadyHaveAccount: "Vous avez déjà un compte? Se connecter",
      AlreadyHaveCode: "Tu as déjà un code? Vérifiez-le",
      AssignmentDate: "Date d'attribution",
      AssignRights: "Attribuer des droits",
      AssignRoles: "Attribuer des rôles",
      AssignRolesToUsers: "Attribuer des rôles aux utilisateurs",
      AssigneeRejectAllowed: "Autoriser le participant à refuser?",
      AssigneeRejectAllowedHelp: "Lorsque cette option est activée, les participants peuvent refuser de faire un exercice ou annuler un exercice après l'avoir commencé.",
      Author: "Auteur",
      AutoSelect: "Auto-sélectionner la sortie?",
      AutoSelectPath: "Chemin d'auto-sélection",
      Avatar: "Avatar",
      Birthdate: "Date de naissance",
      ByIssue: "Par numéro",
      Canada: "Canada",
      CanChangeEmailLater:
        "Vous pourrez modifier votre adresse ultérieurement, une fois inscrit",
      ConfirmRevokeJurorAssignmentTitle: "Confirmer la révocation de l’attribution du juré",
      ConfirmRevokeJurorAssignmentContent: 
        "Êtes-vous sûr de vouloir révoquer l’attribution actuelle du juré ?",      
      ConfirmArchiveTestTemplateTitle: "Confirmer l'archivage des modèles d'exercice", 
      ConfirmArchiveTestTemplateBody: "Êtes-vous sûr de vouloir archiver les modèles d'exercice sélectionnés ?",  
      JurorAssignmentRevoked: "L'attribution du juré a été révoquée avec succès.",
      ErrorRevokingJurorAssignment: 
        "Une erreur s'est produite lors de la révocation de l'attribution du juré. Veuillez réessayer.",        
      ChangeAvatar: "Changer d'avatar",
      ChangeAvatarText: "Voici où vous changez votre avatar",
      ChangeEmail: "Changer l'adresse d'email'",
      ChangeEmailDialogText:
        "Remplissez le formulaire ci-dessous pour changer votre adresse d'email'",
      ChangeLogo: "Changer d'logo",
      ChangeLogoText: "Voici où vous changez votre logo",
      ChangePassword: "Changer le mot de passe",
      ChangePasswordDialogText:
        "Remplissez le formulaire ci-dessous pour changer votre mot de passe",
      ChangeSubdomain: "Changer le sous-domaine",
      ChangeSubdomainDialogText:
        "Remplissez le formulaire ci-dessous pour changer votre sous-domaine",
      Channels: "Chaînes",
      CheckYourEmail: "Vérifiez votre courriel s'il vous plaît",
      City: "Ville",
      Close: "Fermer",
      CodeResent: "Code envoyé, vérifiez votre courriel",
      Comments: "Commentaire",
      CommentSystem: "Système de commentaire",
      ConfigureOrganization: "Configurer l'organisation",
      CompletionDate: "Date d'achèvement",
      Confirm: "Confirmer",
      ConfirmAccount: "Confirmer le compte",
      ConfirmDeleteOrgRole:
        "Cochez cette case et cliquez sur le bouton ci-dessous pour confirmer que vous souhaitez continuer.",
      ConfirmDeletePubRole:
        "Cochez cette case et cliquez sur le bouton ci-dessous pour confirmer que vous souhaitez continuer.",
      ConfirmEmailAddress: "Vérifier l’adresse d'email'",
      ConfirmPassword: "Confirmer le mot de passe",
      Content: "Contenu",
      ContentTypes: "Types de contenu",
      Continuous: "Continu",
      CopyFrom: "Copie de ...",
      CopyOrgRole: "Copier le rôle de l'organisation",
      CopyOrgRoleDialogText:
        "Utilisez ce formulaire pour créer un nouveau rôle d'organisation basé sur une copie d'un rôle existant.",
      CopyPubRole: "Copier le rôle de publication",
      CopyPubRoleDialogText:
        "Utilisez ce formulaire pour créer un nouveau rôle de publication basé sur une copie d'un rôle existant.",
      Corporation: "Société",
      Country: "Pays",
      Create: "Créer",
      CreateAssignment: "Créer une affectation",
      CreateAudience: "Créer une audience",
      CreateBundle: "Créer un bundle",
      CreateCampaign: "Créer une campagne",
      CreateChannel: "Créer une chaîne",
      CreateContentItem: "Créer un élément de contenu",
      CreateContentType: "Créer un type de contenu",
      CreateDomain: "Créer un domaine personnalisé",
      CreateEmailTemplate: "Créer un modèle de message",
      CreateGroup: "Créer un groupe",
      CreateList: "Créer une liste",
      CreateOrg: "Créer une organisation",
      CreateOrgDialogText: "Une organisation est nécessaire pour créer, gérer et réviser des exercices dans Examplery. Vous devez avoir un accord payant avec Examplery pour créer une organisation.",
      CreateOrgRole: "Créer un rôle d'organisation",
      CreateOrgRoleDialogText:
        "Utilisez ce formulaire pour créer un rôle d'organisation.",
      CreateOwnArticle: "Créer son propre article",
      CreateOwnAsset: "Créer son propre élément",
      CreateOwnComment: "Créer son propre commentaire",
      CreatePage: "Créer une page",
      CreatePub: "Créer une publication",
      CreatePublication: "Créer une publication",
      CreatePubRole: "Créer un rôle de publication",
      CreatePubRoleDialogText:
        "Utilisez ce formulaire pour créer un rôle de publication.",
      CreateRole: "Créer un rôle",
      CreateTaxonomy: "Créer une taxonomie",
      CreateTemplate: "Créer un modèle",
      CreateType: "Créer un type",
      CreateUser: "Créer un utilisateur",
      CreateWorkflow: "Créer un workflow",
      CurrentPassword: "Mot de passe actuel",
      CurrentRoles: "Rôles actuels",
      CurrentSubdomain: "Sous-domaine actuel",
      CustomizeName:
        "Entrez votre prénom et votre nom pour personnaliser la personne à partir de laquelle l'e-mail d'invitation est envoyé.",
      CustomizeOptions: "Personnaliser les options",
      CustomizeTheme: "Personnaliser le thème",
      CustomizeThemeOptions: "Personnaliser les options de thème",
      CustomSubdomain: "Sous-domaine personnalisé",
      Dashboard: "Tableau de bord",
      DeleteAssignment: "Supprimer l'affectation",
      DeleteAudience: "Supprimer l'audience",
      DeleteBundle: "Supprimer le bundle",
      DeleteCampaign: "Supprimer la campagne",
      DeleteChannel: "Supprimer la chaîne",
      DeleteEmailTemplate: "Supprimer le modèle de message",
      DeleteGroup: "Supprimer le groupe",
      DeleteList: "Supprimer la liste",
      DeleteOrgRole: "Supprimer le rôle d'organisation: {{roleName}}",
      DeleteOrgRoleDialogAffectedUsersPrefix:
        "Les utilisateurs suivants seront concernés par ce changement:",
      DeleteOrgRoleDialogNoAffectedUsers:
        "Actuellement, aucun utilisateur du système ne sera affecté par ce changement.",
      DeleteOrgRoleDialogText:
        "La suppression de ce rôle d'organisation supprimera les privilèges associés au rôle de tous les utilisateurs qui ont le rôle.",
      DeleteOrgRoleDialogOrphanWarning:
        "Les utilisateurs suivants seront laissés sans aucun rôle dans le système:",
      DeleteOrgRoleDialogOrphanReassurance:
        "Aucun utilisateur ne sera laissé sans aucun rôle dans le système à la suite de ce changement.",
      DeleteOthersArticle: "Supprimer l'article des autres",
      DeleteOthersAsset: "Supprimer l'élément des autres",
      DeleteOthersComment: "Supprimer le commentaire des autres",
      DeleteOwnArticle: "Supprimer son propre article",
      DeleteOwnAsset: "Supprimer votre propre élément",
      DeleteOwnComment: "Supprimer son propre commentaire",
      DeletePage: "Supprimer la page",
      DeletePlugin: "Supprimer le plugin",
      DeletePub: "Supprimer la publication",
      DeletePubRole: "Supprimer le rôle de publication",
      DeletePubRoleDialogAffectedUsersPrefix:
        "Les utilisateurs suivants seront concernés par ce changement:",
      DeletePubRoleDialogNoAffectedUsers:
        "Actuellement, aucun utilisateur du système ne sera affecté par ce changement.",
      DeletePubRoleDialogText:
        "La suppression de ce rôle de publication supprimera les privilèges associés au rôle de tous les utilisateurs qui ont le rôle.",
      DeletePubRoleDialogOrphanWarning:
        "Les utilisateurs suivants seront laissés sans aucun rôle dans le système:",
      DeletePubRoleDialogOrphanReassurance:
        "Aucun utilisateur ne sera laissé sans aucun rôle dans le système à la suite de ce changement.",
      DeleteTaxononmy: "Supprimer la taxonomie",
      DeleteTemplate: "Supprimer le modèle",
      DeleteTheme: "Supprimer le thème",
      DeleteTranslation: "Supprimer la traduction",
      DeleteType: "Supprimer le type",
      DeleteUser: "Supprimer l'utilisateur",
      DeleteWorkflow: "Supprimer le workflow",
      Description: "La description",
      Design: "Modèle",
      Designer: "Designer",
      Details: "Détails",
      DidntReceiveEmail: "Vous n'avez pas reçu d'message? Envoyer à nouveau",
      DisplayRight: "Droits d'affichage",
      DisplayRightsText: "Ce rôle confère les droits suivants:",
      DoNotRestrict: "Envoyer beaucoup de messages",
      EarliestStartDate: "Heure de début la plus tôt",
      EarliestStartDateTooLate: "La heure de début la plus tôt laisse un temps insuffisant pour terminer l'exercice.",
      SetEarliestStartDate: "Définir une heure de début la plus tôt",
      EditAssignment: "Modifier une affectation",
      EditAudience: "Modifier l'audience",
      EditBundle: "Modifier le bundle",
      EditCampaign: "Modifier la campagne",
      EditChannel: "Modifier la chaîne",
      EditEmailTemplate: "Modifier le modèle de message",
      EditGroup: "Modifier un groupe",
      EditList: "Modifier la liste",
      Editor: "Éditeur",
      Editorial: "Éditoriale",
      EditOrg: "Modifier l'organisation",
      EditOrgRole: "Modifier le rôle de l'organisation",
      EditOrgRoleDialogText:
        "Utilisez ce formulaire pour modifier les propriétés d'un rôle d'organisation existant.",
      EditOthersArticle: "Modifier l'article des autres",
      EditOthersAsset: "Modifier l'élément des autres",
      EditOthersComment: "Modifier le commentaire des autres",
      EditOwnArticle: "Modifier son propre article",
      EditOwnAsset: "Modifier son propre élément",
      EditOwnComment: "Modifier son propre commentaire",
      EditPage: "Modifier la page",
      EditPub: "Modifier la publication",
      EditPubRole: "Modifier le rôle de publication",
      EditPubRoleDialogText:
        "Utilisez ce formulaire pour modifier les propriétés d'un rôle de publication existant.",
      EditTags: "Modifier les tags",
      EditTaxonomy: "Modifier une taxonomie",
      EditTemplate: "Modifier le modèle",
      EditTheme: "Modifier le thème",
      EditTranslation: "Modifier la traduction",
      EditType: "Modifier le type",
      EditUser: "Modifier l'utilisateur",
      EditUserRoles: "Modifier les rôles des utilisateurs",
      EditWorkflow: "Modifier un workflow",
      EightOrMoreChars: "Utilisez au moins 8 caractères",
      Email: "Email",
      EmailSubject: "Objet",
      EmailSubjectRequired: "L'objet est requis",
      EmailBody: "Corps",
      EmailTemplate: "Modèle d'e-mail",
      EmailTemplateUpdated: "Le modèle d'e-mail a été mis à jour",
      EmailTemplateParameters: "Paramètres",
      InsertEmailTemplateParameter: "Insérer le paramètre",
      RevertToSystemDefault: "Réinitialiser aux valeurs par défaut du système",
      ConfirmRevertToSystemDefaultTitle: "Confirmer la réinitialisation aux valeurs par défaut du système",
      ConfirmRevertToSystemDefaultText: "Êtes-vous sûr de vouloir réinitialiser le modèle d'e-mail aux valeurs par défaut du système ?",
      ConfirmRevertToSystemDefaultTsAndCsText: "Êtes-vous sûr de vouloir réinitialiser les termes et conditions par défaut du système ?",
      EmailTemplates: "Modèles d'email",
      EmailAddress: "Adresse d'email'",
      EmailChanged: "L'adresse d'email' a été modifiée",
      EmailsMustMatch: "Les adresses d'email doivent correspondre",
      English_translated: "Anglais",
      EnterUsername:
        "Veuillez saisir à nouveau votre nom d'utilisateur et soumettre le formulaire pour recevoir un autre message.",
      ExportData: "Exporter des données",
      Female: "Femelle",
      FifteenOrFewerChars: "Utilisez inférieur à 15 caractères",
      FilterExercises: "Filtrer les exercices",
      FilterList: "Filtrer la liste",
      Financial: "Financier",
      FinancialManager: "Manager financier",
      FirstName: "Prénom",
      ForgotPassword: "Mot de passe oublié",
      ForgotPasswordForm:
        "Mot de passe oublié? Indiquez votre nom d'utilisateur ci-dessous pour déclencher une réinitialisation du mot de passe",
      ForgotPasswordLink: "J'ai oublié mon mot de passe",
      French: "Français",
      French_translated: "Français",
      Gender: "Sexe",
      GuestContributor: "Contributeur invité",
      He: "Il",
      Home: "Page d'accueil",
      ImportData: "Importer des données",
      ImportSpreadsheet: "Importer tableur",
      InstallPlugin: "Installer le plugin",
      InstallTheme: "Installer le thème",
      InvalidCredentials: "Nom d'utilisateur ou mot de passe invalide",
      InviteCoowner: "Inviter un autre copropriétaire",
      InviteCoowners: "Inviter les copropriétaires",
      InviteCoownersText:
        "Indiquez le nom et une adresse d'email pour le copropriétaire que vous souhaitez inviter. N'oubliez pas que cette personne aura les mêmes privilèges que vous dans le système.",
      InviteStaff: "Inviter le personnel",
      InviteStaffText:
        "Indiquez le nom et une adresse e-mail pour le membre du personnel que vous souhaitez inviter. Vous pourrez leur attribuer un rôle ultérieurement.",
      Jr: "Jr",
      LastName: "Nom de famille",
      LastUpdated: "Dernière mise à jour",
      LastUpdatedBy: "Dernière mise à jour par",
      LegalConsiderations: "Considérations légales",
      LegalStructure: "Structure légale",
      LetsGetBasicInformation: "Commençons avec des informations de base...",
      ListOfRights: "Droits associés à ",
      LLC: "LLC",
      Logo: "Logo", 
      LowerCaseRequired: "Doit contenir une lettre majuscule",
      Male: "Mâle",
      ManageOrganization: "Modifier l'organisation",
      ManagePublications: "Gérer les publications",
      ManageRoles: "Gérer les rôles",
      ManageSystemObjects: "Gérer les objets système",
      ManageUsers: "Gérer les utilisateurs",
      Marketer: "Responsable marketing",
      Marketing: "Commercialisation",
      ModerateComment: "Commentaire modéré",
      Mr: "M",
      Ms: "Mme",
      MustBeValidEmail: "L'adresse d'email doit être valide",
      MustCheckBox:
        "Vous devez cocher la case pour procéder.",
      MyAssignments: "Mes missions",
      Name: "Nom",
      NewSubdomain: "Sous-domaine noveau",
      NoAccount:
        "Vous n'avez pas encore de compte? S'il vous plait enregistrez vous!",
      NoFilterApplied: "Aucun filtre appliqué", 
      None: "Aucun",
      NotStarted: "Non commencé",
      NoPageFound: "La page est introuvable",
      NoPageFoundText:
        "Nous ne savons pas comment vous êtes arrivé ici, mais ce n'est pas en fait une page de l'application.",
      NumberRequired: "Doit contenir un chiffre",
      IntegerRequired: "Doit contenir un entier",
      GreaterThanZeroRequired: "Doit être supérieur à zéro",
      PositiveRequired: "Doit être égal ou supérieur à zéro",
      HoursOrMinutesRequired: "Les heures ou les minutes doivent être supérieures à zéro",
      ApplicationRequired: "Au moins une application requise",
      Organization: "Organisation",
      OrganizationCreated: "L'organization a été créé",
      OrganizationLevel: "Niveau organisation",
      OrganizationPending: "Votre demande de création d'une organisation Examplery a été reçue. Vous serez notifié par e-mail lorsque votre organisation aura été approuvée et sera disponible pour utilisation.",
      OrganizationPendingTitle: "Organisation en Attente",
      OrgDetails: "Détails de l'organisation",
      OrgName: "Nom de l'organisation",
      OrgRoles: "Rôles d'organisation",
      OrgRoleCreated: "Le rôle d'organisation a été créé",
      OrgRoleDeleted: "Le rôle d'organisation a été supprimé",
      OrgRoleUpdated: "Le rôle de l'organisation a été mis à jour",
      OrgRoleDesignerDesc:
        "Responsable de la marque et du style de l'organisation",
      OrgRoleFinancialMgrDesc:
        "A accès aux données financières relatives à l'organisation, à son personnel et à ses abonnés",
      OrgRoleMarketerDesc:
        "Stimule la demande en créant et en gérant des campagnes et une sensibilisation",
      OrgRolePublisherDesc:
        "A accès à tous les aspects de l'organisation et de ses publications",
      OrgRoleTechAdminDesc:
        "Gère les fonctions du système telles que l'importation / l'exportation de données et les intégrations tierces",
      PubRoleContributorDesc:
        "Peut créer un nouveau contenu et modifier son propre contenu",
      PubRoleCopyEditorDesc: "Modifie le contenu créé par d'autres",
      PubRoleDesignerDesc:
        "Responsable de la mise en page et de l'apparence de la publication",
      PubRoleEditorDesc:
        "Attribue le travail, gère les flux de travail et approuve la publication du contenu",
      PubRolePublisherDesc: "A accès à tous les aspects de la publication",
      PubRoleSubscriberDesc:
        "Can read and comment on content according to their subscription tier",
      PubRoleTranslatorDesc:
        "Peut lire et commenter le contenu en fonction de son niveau d'abonnement",
      Okay: "OK",
      OneHundredOrFewerChars: "Utilisez inférieur à 100 caractères",
      OpenSpreadsheetFileErrorTitle: "Erreur lors de l'ouverture du tableur",
      OpenSpreadsheetFileErrorContent: "Le fichier spécifié n'a pas pu être ouvert.\n{{errorMessage}}",
      ImportAssigneesTitle: "Importer les assignés",
      ImportAssigneesExplanation: "Pour importer des assignés, vous devez spécifier leur emplacement dans le tableur." + 
        " Commencez par indiquer la feuille (c'est-à-dire l'onglet du tableur) et la première ligne où se trouve le tableau des assignés." + 
        " Ensuite, sélectionnez la colonne pour le prénom, le nom de famille et l'adresse e-mail de chaque assigné.",
      WorkSheet: "Feuille",
      ImportDataTopRow: "Première ligne",
      TopRowContainsHeaders: "La première ligne contient des en-têtes",
      TopRowContainsHeadersHelp: "Définissez si la première ligne du tableau d'importation contient des en-têtes. Dans ce cas, la ligne suivant la ligne des en-têtes sera la première à être importée.",
      SpreadsheetImportColumns: "Colonnes du tableau de données à importer",
      OrgUpdateSuccessful:
        "Les détails de l'organisation ont été mis à jour avec succès",
      OrgTsAndCsUpdateSuccessful: "Les termes et conditions de l'organisation ont été mis à jour avec succès",
      OrgURL: "URL de l'organisation",
      OriginalSubdomain: "Sous-domaine",
      OwnerInvited: "Le nouveau copropriétaire a été invité",
      Owners: "Propriétaires",
      Password: "Mot de passe",
      PasswordChanged: "Le changement de mot de passe a réussi",
      PasswordReset: "Le mot de passe a été réinitialisé avec succès",
      PasswordResetForm:
        "Remplissez le formulaire ci-dessous pour réinitialiser votre mot de passe.",
      PasswordsMustMatch: "Les mots de passe doivent correspondre",
      Payables: "Compte créditeur",
      PaymentDetails: "Détails de paiement",
      Pending: "En attente de ...",
      PhoneNumber: "Numéro de téléphone",
      PostalCode: "Numéro postal",
      Preferences: "Préférences",
      PreferredLanguage: "Langue par défaut",
      PreferredTimeZone: "Fuseau horaire par défaut",
      PremiumApplications: "Applications Premium",
      Privacy: "Préférences de confidentialité",
      ProfileUpdated: "Les détails de votre compte ont été mis à jour",
      Pronoun: "Pronom",
      Publication: "Publication",
      PublicationLevel: "Niveau de publication",
      Publications: "Publications",
      PublishingModel: "Modèle de publication",
      PubCreated: "Publication was successfully created",
      PubRoles: "Rôles de publication",
      PubRoleCreated: "Le rôle de publication a été créé",
      PubRoleDeleted: "Le rôle de publication a été supprimé",
      PubRoleUpdated: "Le rôle de publication a été mis à jour",
      PubUpdated: "Les détails de la publication ont été mis à jour",
      Receivables: "Compte à recevoir",
      RegionWhereAccountWillBeHosted: "Région où le compte sera hébergé",
      RemoveSplashPage: "Supprimer la page d'accueil",
      Required: "Champ obligatoire",
      ResendCode: "Renvoyer le code",
      ResetPassword:
        "Réinitialiser votre mot de passe",
      ResetYourPassword:
        "Réinitialiser votre mot de passe",
      Restrict: "Restreindre les communications",
      Return: "Revenir",
      RoleType: "Type de rôle",
      ScriptTagsForbidden: "Les balises <script> ne sont pas autorisées.",
      SelectImage: "Sélectionner l'image",
      SelectPrimaryColor: "Choisissez une première couleur de marque",
      SelectSecondaryColor: "Choisissez une deuxième couleur de marque",
      SelectThirdColor: "Choisissez une troisième couleur de marque",
      SelectTheme: "Sélectionne un thème",
      SendInvitation: "Envoyer l'invitation",
      SettingRolesFor: "Vous définissez des rôles pour ",
      She: "Elle",
      ShouldHaveReceivedEmail:
        "Vous devriez avoir reçu un message avec un code; entrez le code ci-dessous pour confirmer qu'il s'agit bien de vous. Assurez-vous de vérifier votre dossier spam ...",
      SignIn: "Se connecter",
      SignInSuccessful: "La connexion a réussi!",
      SignOut: "Déconnexion",
      SignUp: "S'inscrire",
      SignUpConfirmed: "Inscription confirmée",
      SignUpContributor: "inscription de contributeur",
      SignUpCoowner: "Inscription copropriétaire",
      SignUpSuccessful: "L'inscription a réussi!",
      SocialLinks: "Profils de réseaux sociaux",
      Sr: "Sr",
      StaffInvited: "Le nouveau membre du personnel a été invité",
      State: "Etat ou province",
      StartDate: "Date de début",
      Subdomain: "Le sous-domaine",
      SubdomainChanged: "Le sous-domaine a été modifié avec succès",
      Submit: "Envoyer",
      SuccessfullyVerifiedEmail:
        "Vous avez bien vérifié la réception du message, cliquez ci-dessous pour continuer à vous connecter:",
      Suffix: "Suffixe",
      SymbolRequired: "Doit contenir un signe de ponctuation",
      Tags: "Tags",
      TargetCompletionDate: "Date de fin prévue",
      Taxonomies: "Taxonomies",
      Technical: "Technique",
      TechnicalAdministrator: "Administrateur technique",
      TermsAndConditions: "Termes & conditions",
      Title: "Titre",
      Translation: "Traduction",
      TwentyOrFewerChars: "Utilisez inférieur à 20 caractères",
      TwoThousandOrFewerChars: "Utilisez inférieur à 2000 caractères",
      UnitedStates: "États Unis",
      Update: "Réviser",
      UpdatePlugin: "Mettre à jour le plug in",
      UpdateTheme: "Mettre à jour le thème",
      UploadPlugin: "Télécharger le plugin",
      UploadTheme: "Télécharger le thème",
      UpperCaseRequired: "Doit contenir une lettre majuscule",
      UserInvited: "Le utilisateur a été invité à rejoindre votre organisation",
      UserName: "Nom d'utilisateur",
      UserNameOrEmailAddress: "Nom d'utilisateur ou adresse d'email",
      UsernameCannotBeEmail: "Le nom d'utilisateur ne peut pas être l'adresse d'email",
      ValidPhone:
        "Doit être un numéro de téléphone valide avec le signe plus et le code du pays",
      ValidSubdomain:
        "Le sous-domaine ne doit contenir aucun caractère spécial ni espace",
      ValidURL: "Doit être une URL valide commençant par http ou https",
      VerificationCode: "Code de vérification",
      ViewFinancials: "Afficher les états financiers",
      WelcomeBack: "Vous voilà de retour, bienvenue!",
      WelcomeToLeadStory: "Bienvenue dans LeadStory!",
      WelcomeToLeadStoryContributorText:
        "Vous avez été invité à participer à la construction de la prochaine grande marque médiatique! Une fois que vous aurez fourni vos informations initiales, vous aurez les privilèges d'accès qui vous sont attribués par le propriétaire.",
      WelcomeToLeadStoryCoownerText:
        "Vous avez été invité à être copropriétaire de une organisation LeadStory et de ses publications associées. Cela signifie que vous aurez un accès complet à toutes les capacités de la plateforme et à toutes ses données.",
      WelcomeToOrg: "Bienvenue dans {{siteConfig.siteName}}!",
      WelcomeToOrgCoownerText:
        "Vous avez été invité à être copropriétaire de {{siteConfig.siteName}} et de ses publications associées. Cela signifie que vous aurez un accès complet à toutes les capacités de la plateforme et à toutes ses données.",
      WhereLocated: "Où vous situez-vous?",
      Publisher: "Directeur de la publication",
      Contributor: "Donateur",
      CopyEditor: "Éditeur de copie",
      Subscriber: "Abonné",
      Translator: "Traducteur",
      CreateSource: "Créer une source",
      EditSource: "Modifier une source",
      DeleteSource: "Supprimer une source",
      CreateFeed: "Créer une fil",
      EditFeed: "Modifier une fil",
      DeleteFeed: "Supprimer une fil",
      TopicDiscovery: "Découverte de sujet",
      OrgRoleEditorDesc: "Peut éditer les articles des autres",
      OrgRoleContributorDesc: "Peut créer de nouveaux articles",
      TopicDiscoveryDashboard: "Tableau de bord de découverte de sujet",
      AddSelectedTo: "Ajouter la sélection à...",
      SelectAPublication: "Sélectionnez une publication",
      SelectAFeed: "Sélectionnez un flux",
      ViewFeed: "Afficher le flux",
      ManageFeeds: "Gérer les flux",
      Score: "Score",
      Unattributed: "Non attribué",
      Save: "Sauvegarder",
      Saving: "En cours de sauvegarde...",
      AsOf: "À partir du...",
      Manage3rdPartyServices: "Gérer les services tiers",
      ManageSources: "Gérer les sources",
      ManageSourceLists: "Gérer les listes de sources",
      CreateANewIssue: "Créer un nouveau numéro",
      ViewSavedStories: "Afficher les histoires enregistrées",
      BuildAFeed: "Créer un flux",
      FeedBuilderInstructions:
        "Vous pouvez utiliser ce formulaire pour créer des flux complexes combinant plusieurs sources de données et vos propres requêtes personnalisées.",
      MakeDefault: "Faire défaut",
      Query: "Requete",
      UseQueryBuilder: "Utilisez le générateur de requêtes",
      QueryBuilder: "Générateur de requêtes",
      QueryBuilderInstructions:
        "Utilisez les composants ci-dessous pour créer votre requête. Notez que toute requête que vous créez ici remplacera ce qui a été saisi précédemment.",
      BuiltQuery: "Requête construite",
      QueryComponents: "Composants de requête",
      KeywordInstructions:
        "Fournissez une liste de mots clés séparés par des espaces. Ceux-ci seront traités comme joints par ET.",
      And: "Et",
      Or: "Ou",
      Not: "Ne pas",
      OperatorInstructions:
        "Fournissez des groupes de termes séparés par des espaces que vous souhaitez joindre dans une condition OU:",
      AddSourceLists: "Ajouter des listes de sources",
      AddSources: "Ajouter des sources",
      ConfirmFeed: "Confirmer le flux",
      FeedCopyInstructions:
        "Vous pouvez utiliser ce formulaire pour copier un flux pour plus de commodité. N'oubliez pas de renommer le flux avant de confirmer vos modifications.",
      WriteAQuery: "Rédiger une requête",
      DeleteFeedInstructions: "Voulez-vous supprimer ",
      CheckBoxDeleteFeed: "Vous devez cocher la case pour supprimer ce flux.",
      DeleteFeedConfirm:
        "Je comprends que ce flux sera supprimé définitivement.",
      EditAFeed: "Modifier un flux",
      EditFeedInstructions:
        "Vous pouvez utiliser ce formulaire pour modifier les flux existants.",
      AddATemplate: "Ajouter un modèle",
      PlaceholderText: "Ceci est un texte d'espace réservé.",
      SavedStories: "Histoires enregistrées",
      SavedStoryInstructions:
        "Vous n'avez aucune histoire enregistrée. Vous pouvez enregistrer des histoires à partir du tableau de bord après avoir consulté un flux.",
      AvailableServices: "Services disponibles",
      CurrentlyRegisteredTo: "Actuellement inscrit à ",
      Register: "S'inscrire",
      ChangeRegistration: "Changer d'inscription",
      UnderstandingRegistrationProcess: "Comprendre le processus d'inscription",
      RegistrationInstructions:
        "Une fois que vous aurez cliqué sur le bouton «Continuer» ci-dessous, un nouvel onglet s'ouvrira et il vous sera demandé de vous authentifier directement auprès du service que vous vous inscrivez. Une fois que vous vous êtes authentifié, le service fournira un code PIN. Revenez à cette fenêtre et entrez votre code PIN pour terminer l'enregistrement.",
      EnterPIN: "Entrez votre code PIN",
      PINInstructions:
        "Entrez votre code PIN dans le deuxième onglet pour terminer l'enregistrement.",
      RemoveServiceInstructions:
        "Notez que la suppression de ce service ici n'affectera que votre accès individuel. Pour révoquer complètement l'accès de cette application pour tous les utilisateurs, visitez directement le site Web de ce service.",
      SetOwners: "Définir les propriétaires",
      SetOwnersInstructions:
        "Utilisez cette boîte de dialogue pour définir les propriétaires de cette liste source.",
      SourceBuilderInstructions:
        "Vous pouvez utiliser ce formulaire pour ajouter des sources à vos flux.",
      Type: "Genre",
      RSS: "Flux RSS",
      Twitter: "Poignée Twitter",
      TwitterInstructions:
        "Si vous connaissez le poignée Twitter exact que vous souhaitez attribuer comme source, saisissez-le ici:",
      TwitterInstructions2:
        "Sinon, cliquez sur le bouton ci-dessous pour rechercher des poignées Twitter.",
      Definition: "Définition",
      FindTwitterHandles: "Trouver des poignées Twitter",
      SearchTwitterInstructions:
        "Entrez les termes de recherche ci-dessous et sélectionnez parmi les poignées Twitter trouvées, le cas échéant.",
      NothingWasFound: "Rien n'a été trouvé.",
      RSSInstructions:
        "Si vous connaissez l'URL exacte du flux RSS, saisissez-la ici:",
      RSSInstructions2:
        "Sinon, cliquez sur le bouton ci-dessous pour localiser les flux RSS sur une page Web.",
      FindRSSFeedsAtURL: "Trouvez des flux RSS à cette URL",
      FindRSSInstructions:
        "Entrez l'adresse d'une page Web ci-dessous et sélectionnez parmi les flux RSS trouvés à cet endroit, le cas échéant.",
      ConfirmDeleteSource: "Voulez-vous supprimer",
      CheckBoxDeleteSource:
        "Vous devez cocher la case pour procéder à la suppression de cette source.",
      DeleteSourceAffirmation:
        "Je comprends que cette source sera supprimée définitivement.",
      EditASource: "Modifier une source",
      EditSourceInstructions:
        "Vous pouvez utiliser ce formulaire pour modifier une source.",
      RSSURL: "URL RSS",
      CreateASourceList: "Créer une liste source",
      SourceListBuilderInstructions:
        "Vous pouvez utiliser ce formulaire pour créer des listes à partir de sources pour plus de commodité.",
      ConfirmList: "Confirmer la liste",
      CopyASourceList: "Copier une liste source",
      CopySourceListInstructions:
        "Vous pouvez utiliser ce formulaire pour copier une liste de sources pour plus de commodité. N'oubliez pas de renommer la liste source avant de confirmer vos modifications.",
      DeleteSourceList: "Supprimer une liste source",
      ConfirmDeleteSourceList: "Voulez-vous supprimer",
      DeleteSourceListInstructions:
        "Vous devez cocher la case pour procéder à la suppression de cette liste source.",
      DeleteSourceListAffirmation:
        "Je comprends que cette liste source sera supprimée définitivement.",
      EditASourceList: "Modifier une liste source",
      EditSourceListInstructions:
        "Vous pouvez utiliser ce formulaire pour modifier des listes de sources existantes.",
      CreateSourceList: "Créer une liste source",
      AddASource: "Ajouter une source",
      SelectPublication: "Sélectionnez une publication...",
      SelectList: "Sélectionnez une liste...",
      AddSelectedToList: "Ajouter la sélection à la liste",
      Sources: "Sources",
      IsSystem: "Appartient au système",
      Cancel: "Annuler",
      RevokeJurorAssignment: "Révoquer l'attribution",
      Remove: "Retirer",
      ManageWinsAndLosses: "Gérer les victoires ou les pertes",
      CreateWinOrLoss: "Créer une victoire ou une perte",
      CreateWinStory: "Créer l'histoire d'une victoire",
      ManageTests: "Gérer les exercices ",
      CreateTest: "Créer un exercice ",
      InviteUser: "Inviter un nouvel utilisateur ",
      InviteUserText:
        "Indiquez le nom et une adresse e-mail pour le utilisateur que vous souhaitez inviter. Vous pourrez leur attribuer un rôle ultérieurement.",
      UserRolesUpdated: "Les rôles des utilisateurs ont été mis à jour",
      DaysTestWillBeAvailable: "L'exercice de jours sera disponible",
      ApplicationsAvailableDuringTest:
        "Applications disponibles pendant l'exercice",
      Application: "Application",
      TestIsPublic: "Rendre l'exercice accessible au public",
      Settings: "Paramètres",
      DownloadAllowed: "Téléchargement autorisé",
      UploadAllowed: "Téléversement autorisé",
      CutAllowed: "Coupe autorisée",
      PasteAllowed: "Coller autorisé",
      PauseAllowed: "Autoriser la pause de l'exercice",
      TestAssets: "Actifs disponibles pendant l'exercice",
      ApplicationInput: "Entrée d'Application d'Exercice",
      TimeCandidateHasToCompleteTest:
        "Le temps dont dispose le participant pour terminer l'exercice",
      Hours: "Heures",
      Minutes: "Minutes",
      OutputsFromTest: "Sorties de l'exercice",
      FileType: "Type de fichier",
      Draft: "Brouillon",
      Published: "Publié",
      Deprecated: "Obsolète",
      Retired: "Retraité",
      OtherSettings: "Autres réglages",
      UploadInstructions: "Téléverser les instructions",
      ManageAssets: "Gérer les éléments",
      ManageTestTemplates: "Gérer les modèles d'exercice",
      CreateTestTemplate: "Créer un modèle d'exercice",
      TestTemplates: "Modèles d'exercice",
      TestTemplate: "Modèle d'exercice",
      EditTestTemplate: "Modifier le modèle d'exercice",
      SelectInstructions: "Sélectionnez les instructions",
      SelectAsset: "Sélectionnez un élément",
      AddOutput: "Ajouter une sortie",
      AddAssetDialogText:
        "Choisissez un élément à télécharger dans le système. Vous pourrez réutiliser cet élément sans avoir à le télécharger à nouveau.",
      DeleteAsset: "Supprimer l'élément",
      DeleteAssetDialogText: "Voulez-vous vraiment supprimer cet élément?",
      DeleteTestTemplate: "Supprimer le modèle d'exercice",
      DeleteTestTemplateDialogText:
        "Voulez-vous vraiment supprimer ce modèle d'exercice?",
      Assets: "Éléments",
      AddAsset: "Ajouter un élément",
      AssetDeleted: "L'élément spécifié a été supprimé.",
      EditAssetDialogText:
        "Utilisez ce formulaire pour modifier les informations associées à cet élément.",
      ConfirmDeleteAsset:
        "Je comprends que cet élément sera définitivement supprimé.",
      ConfirmDeleteTestTemplate:
        "Je comprends que ce modèle d'exercice sera définitivement supprimé.",
      DragAssetHere: "Faites glisser l'actif ici",
      DropItemHere: "Déposer l'article ici",
      Accept: "Accepter",
      Decline: "Refuser",
      Resume: "Continuer",
      Forfeit: "Refuser",
      Discard: "Jeter",
      ViewEvaluation: "Voir l'évaluation",
      Revoke: "Révoquer",
      CandidateTableHeader: "Exercices assignés",
      NoTestsMessage: "Vous avez terminé tous les exercices demandés! N'hésitez pas à vous déconnecter de l'application.",
      NoSubmittedTestsMessage: "Vous n'avez pas encore soumis d'exercices. Veuillez vous assurer que vous avez accepté et terminé les exercices qui vous ont été assignés dans l'onglet Attribué à moi.",
      SubmittedTests: "Exercices soumis",
      TestName: "Nom de l'exercice",
      TestDescription: "Description de l'exercice",
      TestInstances: "Instances d'exercice",
      Status: "Statut",
      Activate: "Activer",
      Edit: "Éditer",
      Delete: "Supprimer",
      Copy: "Copier",
      CopyUrl: "Copier l'URL dans le presse-papiers",
      Copied: "Copié",
      Assign: "Attribuer",
      Recall: "Rappeler",
      Archive: "Archiver",
      HiringManagerTableHeader: "Modèles d'exercice",
      CreateNew: "Créer un nouveau",
      JurorTableHeader: "Exercices pour évaluer",
      AssignedBy: "Assigné par",
      DueDate: "Date d'échéance",
      TimeRemaining: "Temps restant",
      draft: "L'ébauche",
      active: "Actif",
      deprecated: "Obsolète",
      retired: "Retraité",
      created: "Établi",
      assigned: "Attribué",
      unmarked: "Non évalué",
      marked: "Évalué",
      suspended: "Suspendu",
      cancelled: "Annulé",
      inactive: "Inactif",
      TestTemplateUpdated: "Modèle d'exercice mis à jour",
      TestTemplateDeleted: "Modèle d'exercice supprimé",
      TestTemplateCopied: "Modèle d'exercice copié",
      TestTemplateActivated: "Modèle d'exercice activé",
      TestTemplateArchived: "Modèle d'exercice archivé",
      TestTemplateRecalled: "Modèle d'exercice rappelé",      
      TestDeclinedSuccessfully: " refusé avec succès",
      DeclineTestHeading: "Refuser l'exercice",
      ForfeitTestHeading: "Refuser l'exercice",
      DiscardTestHeading: "Jeter l'exercice",
      RevokeTestHeading: "Révoquer l'exercice",
      DeclineTestBody: "Cliquez ci-dessous pour confirmer que vous souhaitez refuser cet exercice. Vous ne pourrez pas annuler cette action.",
      ForfeitTestBody: "Cliquez ci-dessous pour confirmer que vous souhaitez refuser cet exercice. Vous ne pourrez pas annuler cette action.",
      DiscardTestBody: "Cliquez ci-dessous pour confirmer que vous souhaitez supprimer cet exercice. Vous ne pourrez pas annuler cette action.",
      RevokeTestBody: "Cliquez ci-dessous pour confirmer que vous souhaitez révoquer cet exercice. Vous devrez refaire l'exercice si vous changez d'avis.",
      GoBack: "Retourner",
      TestInstanceAnswerSubmitted: "Réponse de l'instance d'exercice envoyée",
      TestInstanceAnswerRemoved: "Réponse de l'instance d'exercice supprimée.",
      TestSubmitted: "Exercice soumis",
      SubmitTestHeading: "Soumettre l'exercice",
      SubmitTestBodyNoTimeRemaining:
        "Lorsque vous soumettez l'exercice, vos résultats seront finalisés et les résultats seront évalués par un expert.",
      SubmitWebsiteTestBodyNoTimeRemaining:
        "Le temps disponible pour terminer l'exercice est écoulé. Vos réponses ont été enregistrées. Cliquez sur le bouton de soumission pour envoyer vos réponses.",
      SubmitTestBodyTimeRemaining:
        "Lorsque vous soumettez l'exercice, vos résultats seront finalisés et les résultats seront évalués par un expert.",
      SubmitWebsiteTestBodyTimeRemaining:
        "Prêt à envoyer ? Les réponses que vous avez fournies ont déjà été enregistrées. Cependant, vous pouvez choisir de les modifier en cliquant sur « Continuer l'exercice » ci-dessous ou, si vous avez terminé l'exercice, cliquez sur « ENVOYER ».",
      FileToSubmit: "Fichier à soumettre",
      ReturnToDashboard: "Retour au tableau de bord",
      ReturnToTest: "Continuer l'exercice",
      AppstreamHeader: "Votre exercice est en cours",
      AppstreamBody: "Veuillez vous référer aux instructions suivantes",
      TestIntroduction: "Ceci est un texte d'introduction expliquant l'exercice.",
      DownloadTestIntroductionFileExplanation:
        "Vous pouvez télécharger les instructions pour l'exercice en utilisant le lien ci-dessous.",
      WorkTime: "Temps de travail",
      WorkTimeRemaining: "Temps de travail restant",
      PauseTest: "Interrompre l'exercice",
      FinishTest: "Terminer l'exercice",
      RichText: "Texte riche",
      Instructions: "Instructions",
      TargetPath: "Chemin cible",
      Sequence: "Séquence",
      Extension: "Extension",
      TermsAndConditionsTitle: "Termes et conditions",
      TermsAndConditionsDefault:
        "En utilisant cette application, vous comprenez que vous autorisez Examplery et l'organisation qui vous propose cet exercice à enregistrer, stocker et analyser votre activité au sein de cette application Web dans le but de déterminer votre niveau de compétence avec les applications et les outils fournis. dans l'épreuve. Vous n'accordez aucun droit de propriété intellectuelle sur votre travail ou vos résultats dans cet exercice, et vous n'accordez aucune licence à l'organisation qui vous propose cet exercice pour revendre ou faire un usage commercial de votre travail à des fins autres que l'évaluation de vos compétences.",
      AcceptAndBegin: "Accepter et commencer",
      AddParticipant: "Ajouter un participant",
      AssignCandidateConfirmation: "Confirmation d'attribution des participants",
      AssignCandidateConfirmationExplanation:
        "En soumettant ce formulaire, vous enverrez un e-mail invitant chaque participant à faire l'exercice.",
      AssignCandidate: "Attribuer un participant",
      AssignCandidates: "Attribuer des participants",
      AssignCandidateExplanation:
        "Remplissez le formulaire pour inviter un participant à faire un exercice.",
      AssignCandidatesExplanation:
        "Remplissez le formulaire pour inviter des participants à faire un exercice.",
      CopyTestTemplate: "Copier le modèle d'exercice",
      ChooseFolder: "Choisir le dossier",
      ChooseFile: "Choisir le fichier",
      Owner: "Propriétaire",
      Candidate: "Participant",
      HiringManager: "Gestionnaire d'embauche",
      Juror: "Juré",
      FinancialAdministrator: "Administrateur financier",
      TestDesigner: "Concepteur d'exercices",
      Recruiter: "Recruteur",
      ReplaceAsset: "Remplacer l'actif",
      or: "ou",
      InProgress: "En cours",
      CompletedNotSubmitted: "Terminé, non soumis",
      NotCompleted: "Non terminé",
      NotYetStarted: "Pas encore commencé",
      NotYetCompleted: "Pas encore terminé",       
      NotApplicable: "N/A",
      Evaluated: "Évalué",
      ChangeSendEmailAddressExplanation: "Spécifiez l'adresse email de l'organisation qu'Examplery doit utiliser pour envoyer des notifications. Vous devrez vérifier que vous êtes le propriétaire de cette adresse email. Laissez les champs vides pour réinitialiser l'adresse email par défaut du système Examplery.",
      CheckYourEmailPendingSendEmailExplanation: "Un email a été envoyé à {{email}}. Suivez les instructions dans l'email pour vérifier votre adresse email.",
      OrgSendEmailVerifiedSuccessTitle: "Email vérifié avec succès",
      OrgSendEmailVerifiedSuccessBody: "Vous avez vérifié votre adresse email avec succès. Toutes les notifications futures pour votre organisation Examplery proviendront de : {{email}}.",
      OrgSendEmailVerifiedFailureTitle: "Échec de la vérification de l'email",      
      OrgSendEmailVerifiedFailureBody: "Nous n'avons pas pu vérifier l'adresse email : {{email}}. Veuillez vérifier votre adresse email et réessayer.",
      OrgSendEmailClearedSuccess: "",
      OrgSplashPage: "Page d'accueil de l'organisation",
      UploadNewSplashPage: "Téléverser une nouvelle page d'accueil",
      CanCloseBrowserWindow: "Vous pouvez maintenant fermer cette fenêtre de navigateur.",      
      PendingEvaluation: "En attente d'évaluation",
      PendingEmailVerification: "En attente de vérification d'email",
      NoTestTemplatesMessage: "Il n'y a pas de modèles d'exercice.",
      WelcomeToOrgText:
        "Bienvenue dans l'organisation! Vous aurez désormais accès à un certain nombre de fonctionnalités.",
      HiringManagerAssignments: "Exercices assignés",
      AssignCandidateForm: "Attribuer un participant",
      FullScreen: "Plein écran",
      EndSession: "Terminer la session",
      RefreshEnvironment: "Actualiser l'environnement",
      ReviewSubmittedTest: "Examiner l'exercice soumis",
      ReviewSubmittedTestTitle: "Examiner l'exercice soumis",
      TestAnswers: "Réponses au exercice",
      TestRecordings: "Enregistrements de l'exercice",
      WorkTimeElapsed: "Temps de travail écoulé",
      TestSessionEnded: "Session d'exercice terminée",
      TestSessionEndedDescription: "Vous pouvez revenir au tableau de bord.",
      TestSessionErrorTitle: "Une erreur est survenue",
      TestSessionErrorText:
        "Veuillez contacter votre administrateur pour plus d'informations.",
      EnvironmentStarting: "Démarrage de l'environnement...",
      WelcomeToEnvironment: "Votre exercice est en cours",
      WelcomeToEnvironmentDescription: "Amusez-vous à démontrer vos compétences!",
      ApplicationPaletteText: "Veuillez sélectionner une application pour commencer...",
      NoAssignmentsMessage: "Il n'y a pas d'exercices assignés.",
      DashboardPlaceholderText: "Bienvenue à Examplery!",
      ApplicationTermsAndConditions: "En utilisant ce service, vous acceptez que votre utilisation de ce service et de tout logiciel pouvant être fourni lors de votre utilisation de ce service soit limitée à des fins d'évaluation des compétences. En outre, vous acceptez que des informations sur votre utilisation du service puissent être collectées à des fins d'analyse et d'amélioration future par le service.",
      ReviewAppTerms: "Revoir les termes et conditions",
      DashboardTitle: "Rejoignez la plateforme de sélection technique et créative premium.",
      DashboardSubtitle: "Examplery est un meilleur moyen de mettre en relation des candidats techniques et créatifs avec des entreprises cherchant à embaucher les meilleurs talents du marché.",
      RecruiterDashDescription: "Êtes-vous un recruteur qui embauche pour une entreprise incroyable qui construit une main-d'œuvre diversifiée et recherche les meilleures personnes avec des compétences éprouvées pour le poste?",
      CandidateDashDescription: "Êtes-vous un talent incroyable qui en a marre que votre curriculum vitae se perde et qui veut avoir l'occasion de démontrer vos compétences spécialisées?",
      OrganizationDashDescription: "Êtes-vous une entreprise qui cherche à économiser d'innombrables heures de recrutement en ajoutant notre plateforme d'exercice en marque blanche à votre flux de travail de recrutement?",
      ReadyToSignUp: "Êtes-vous prêt à vous inscrire?",
      LanguageAndRegionSettings: "Paramètres de langue et de région",
      LanguageAndRegionSettingsDescription: "Définissez vos préférences de langue, de fuseau horaire et de région.",
      Language: "Langue",
      LanguageDescription: "Définissez vos préférence de langue",
      ContactInformation: "Coordonnées",
      ContactInformationDescription: "Veuillez fournir votre nom, votre adresse e-mail, et créer un nom d'utilisateur.",
      PasswordDescription: "Choisissez un mot de passe sécurisé.",
      TermsAndConditionsDescription: "Lisez et acceptez les termes et conditions.",
      Reset: "Réinitialiser",
      TestAssigned: "L'exercice a été attribué.",
      TestAssignmentErrors: "Un ou plusieurs exercices n'ont pas été attribués. Vérifiez l'icône d'information d'erreur des assignés pour plus de détails.",
      FortyOrFewerChars: "Utilisez inférieur à 20 caractères",
      BrowseFiles: "Parcourir les fichiers",
      RegionalSettings: "Paramètres régionaux",
      ExitFullScreen: "Quitter le mode plein écran",
      Paste: "Coller",
      Due: "Date d'échéance",
      TotalTime: "Temps total",
      TestSettings: "Paramètres d'exercice",
      AppSwitcher: "Changer d'application",
      BulkActions: "Actions simultanées",
      Available: "Disponible",
      Submitted: "Soumis",
      AssignedToMe: "Assigné à moi",
      AssignedToOthers: "Assigné à d'autres",
      RowsPerPage: "Lignes par page",
      Review: "Revue",
      MarkingDueDate: "Date limite de notation",
      Actions: "Actions",
      AvailableToAssign: "Disponible pour assignation",
      ReadyToReview: "Prêt à évaluer",
      SubmittedByMe: "Soumis par moi",
      OrganizationDetails: "Détails de l'organisation",
      InviteToOrganization: "Inviter à l'organisation",
      ManageTestAssets: "Gérer les actifs d'exercice",
      DeadlinePassed: "Date limite dépassée",
      SignOutConfirmation: "Confirmer la déconnexion",
      SignOutConfirmationExplanation: "Veuillez confirmer que vous souhaitez réellement vous déconnecter de l'application ou cliquez sur Annuler pour recommencer à utiliser le logiciel.",
      SelectAssets: "Choisissez les actifs",
      SelectAssetDialogText: "Utilisez le formulaire ci-dessous pour sélectionner les actifs à inclure dans le modèle d'exercice.",
      SubmitTestConfirmation: "Êtes-vous prêt à soumettre cet exercice?",
      SubmitTestConfirmationExplanation:
        "Êtes-vous sûr ? Si vous cliquez sur « Confirmer », vous terminerez l'exercice et vos réponses ne pourront plus être modifiées. Si vous souhaitez revenir à vos réponses, appuyez sur « Annuler ».",
      ReservationTimeRemaining: "Temps réservé restant",
      FileBrowser: "Navigateur de fichiers",
      TestInstructions: "Instructions",
      ShowInstructions: "Afficher les instructions",
      ErrorWithAssignmentTime: "La date attribuée ne laisse pas suffisamment de temps à l'utilisateur pour terminer l'exercice.",
      ExtendTestInstanceTitle: "Étendre l'instance d'exercice",
      ExtendTestInstanceExplanation: "Sélectionnez la nouvelle date d'achèvement cible pour cet exercice.",
      TestInstanceExtended: "Le délai d'exécution de l'instance d'exercice a été prolongé avec succès.",
      ErrorWithTestInstanceExtension: "La tentative d'extension de l'instance d'exercice a échoué.",
      ErrorDownloadReport: "La tentative de téléchargement du rapport de l'instance d'exercice a échoué.",
      AddTime: "Ajouter du temps",
      CancelTestAcceptanceExplanation: "Si vous n'êtes pas prêt à accepter ces termes et conditions, vous pouvez annuler et revenir au tableau de bord.",
      AcceptAndStartTestExplanation: "Si vous êtes prêt à accepter ces conditions et souhaitez commencer l'exercice maintenant, vous pouvez le faire.",
      AcceptAndStartTest: "Accepter et démarrer l'exercice",
      AcceptAndMakeReservationExplanation: "Si vous êtes prêt à accepter ces conditions et que vous souhaitez effectuer une réservation pour faire l'exercice, veuillez sélectionner votre première réservation parmi les horaires disponibles ci-dessous. Si le temps disponible est insuffisant pour terminer l'exercice dans une session réservée, vous aurez la possibilité de réserver du temps supplémentaire une fois votre première réservation écoulée.",
      AcceptAndMakeReservation: "Accepter et faire une réservation",
      ReserveTime: "Faire une réservation",
      ReservationMade: "Réservation faite",
      MakeReservationTitle: "Choisissez quand commencer votre exercice",
      DoNotStartOrMakeReservationExplanation: "Si vous n'êtes pas encore prêt à commencer votre exercice, vous pouvez annuler et revenir au tableau de bord. Vous pourrez réserver du temps pour faire votre exercice à votre convenance.",
      StartTestExplanation: "Il y a du temps disponible pour commencer votre exercice maintenant. Si vous le souhaitez, vous pouvez commencer quand vous le souhaitez. Une réservation pour la totalité du temps restant de l'examen sera effectuée automatiquement pour vous.",
      StartTestNow: "Commencer l'exercice maintenant",
      MakeReservationExplanation: "Si vous souhaitez effectuer une réservation pour faire votre exercice ultérieurement, choisissez votre heure de début préférée dans la liste des options disponibles ci-dessous et confirmez votre réservation.",
      ReservationDate: "Date de réservation",
      SelectedTimeExceedsWorkTimeRemaining: "Le temps réservé que vous avez essayé de sélectionner dépasse le temps total restant pour l'exercice. Veuillez sélectionner une heure dans les barres indiquées.",
      MustReserveEntireTestTime: "La plage horaire réservée que vous avez essayé de sélectionner est inférieure à la durée de l'exercice. Veuillez choisir un moment parmi les barres indiquées.",
      CancelReservationHeading: "Annuler une réservation",
      CancelReservationBody: "Vous avez une réservation en cours mais vous pouvez l'annuler. Cliquez ci-dessous pour confirmer si vous souhaitez annuler votre réservation. Vous aurez la possibilité de faire une nouvelle réservation.",
      StartTest: "Commencer l'exercice",
      UpcomingReservation: "Réservé à",
      TestAccepted: "L'exercice a été accepté avec succès.",
      CancelReservation: "Annuler le réservation",
      ReservationDeclined: "La réservation a été annulée",
      NoTestsToReviewMessage: "Il n'y a pas d'exercices prêts à être examinés.",
      OptionsDuringTest: "Options disponibles pendant l'exercice",
      GeneralInformation: "Informations générales",
      BasicSettings: "Paramètres de base",
      Inputs: "Contributions",
      Applications: "Applications",
      OutputsTitle: "Productions",
      RubricsTitle: "Rubrique",
      ScorecardTitle: "Fiche de score",
      Categories: "Catégories",
      AddCategory: "Ajouter une catégorie",
      HasOverallScore: "L'exercice a un score global",
      HasCategoryScore: "L'exercice est noté au niveau des catégories individuelles",
      OverallScoreRange: "Plage de notes globales",
      OverallScoreDetails: "Détails pour la note globale",
      CategoryScoreRange: "Fourchette de notes",
      CategoryScoreDetails: "Détails du score",
      CancelTestInstanceTitle: "Annuler l'exercice",
      CancelTestInstanceExplanation: "En confirmant cette action, vous annulerez cet exercice. Le participant ne pourra plus faire l'exercice. Vous pourrez réinviter le participant.",
      TestInstanceCancelled: "L'instance d'exercice a été annulée.",
      ErrorWithTestInstanceUncancellation: "Une erreur s'est produite lors de l'annulation de l'instance d'exercice.",
      unmarked_complete: "Complet",
      ViewResults: "Voir les résultats",
      DownloadReport: "Télécharger le rapport",
      DownloadTestInstanceReportTitle: "Générer et télécharger le rapport",
      DownloadTestInstanceReportExplanation: "Générer et télécharger un rapport PDF pour l'instance d'exercice sélectionnée.",
      DownloadTestInstanceReportExplanationMultiple: "Générer et télécharger un fichier zip contenant des rapports PDF pour les {{numReports}} instances d'exercice sélectionnées.",
      DownloadRollupReportExplanation: "Générer et télécharger un rapport PDF qui regroupe les résultats des {{numReports}} instances d'exercice sélectionnées.",
      ReportTemplates: "Modèles de rapport",
      UploadReportTemplate: "Téléverser un modèle de rapport",
      Upload: "Téléverser",
      ChooseReportTemplate: "Choisir un modèle personnalisé.",
      DefineReportCompareWith: "Spécifier un groupe de pairs personnalisé",
      CompareWithHelp: "Le rapport comprend des statistiques comparant les scores du participant à un groupe de pairs. Vous pouvez spécifier quels exercices font partie du groupe de pairs en définissant un ensemble de règles de filtrage. Si vous ne spécifiez pas de groupe de pairs, le groupe de pairs lors de la génération d'un rapport pour un exercice unique sera tous les exercices notés pour le modèle d'exercice, tandis que le groupe de pairs lors de la génération de rapports pour plusieurs exercices sera l'ensemble des exercices pour lesquels des rapports sont générés.",
      ReportType: "Type de rapport",
      TestInstanceReport: "Rapport d'exercice",
      RollupReport: "Rapport global",
      MarkTest: "Marquer l'exercice",
      AssignJuror: "Désigner un juré",
      AssignJurorExplanation: "Utilisez ce formulaire pour sélectionner un juré à désigner pour noter cet exercice. Si vous ne voyez pas le juré souhaité dans la liste des options à sélectionner, vous devrez peut-être ajouter cette personne à votre organisation.",
      AssignJurorConfirmation: "Attribuer une confirmation de juré",
      AssignJurorConfirmationExplanation: "Veuillez confirmer que vous souhaitez désigner le juré indiqué pour corriger cet exercice.",
      AssignmentAccepted: "Le devoir a été acceptée.",
      AssignmentRejected: "Le devoir a été rejetée.",
      ErrorWithAcceptingAssignment: "Une erreur s'est produite lors de l'acceptation du devoir.",
      ErrorWithRejectingAssignment: "Une erreur s'est produite lors du rejet du devoir.",
      AcceptAssignmentTitle: "Accepter le devoir",
      AcceptAssignmentExplanation: "Veuillez confirmer que vous acceptez le devoir d'évaluer l'exercice indiqué.",
      RejectAssignmentTitle: "Rejeter le devoir",
      RejectAssignmentExplanation: "Veuillez confirmer que vous rejetez le devoir d'évaluer l'exercice indiqué. Vous n'aurez plus la possibilité d'évaluer l'exercice.",
      MarkTestTitle: "Marquer l'exercice",
      TestOutputs: "Sorties de l'exercice",
      TestMarked: "L'exercice était notée.",
      OverallScore: "Score global",
      WeightedCategoryScore: "Score pondéré par catégorie",
      TestInstructionsJurorComment: "Les instructions suivantes ont été fournies au participant:",
      TestScoresSaved: "Les résultats des exercices ont été enregistrés.",
      TestScoresSubmitted: "Les résultats des exercices ont été soumis.",
      SaveScores: "Enregistrer les scores",
      SubmitScores: "Soumettre les scores finaux",
      TestAssignedJuror: "L'exercice a été attribué au juré.",
      JurorAssigned: "Juré désigné",
      RichTextEditorInstructions: "Veuillez noter que vous pouvez coller des images dans l'éditeur de texte enrichi.",
      NoScoreProvided: "Aucun score n'a été fourni pour cet exercice.",
      MainDashDescription: "Laissez les candidats démontrer leurs compétences avec le même logiciel qu'ils utiliseront au travail",
      OutputNotSubmitted: "Aucun contenu n'a été fourni",
      OutOf: "hors de",
      AvailableForTest: "disponible pour essai",
      TestAlertThresholdReached: "Alerte : {{timeRemaining}} restantes pour l'exercice.",
      TestWarningThresholdReached: "Attention : {{timeRemaining}} restantes pour l'exercice.",
      ReservationAlertThresholdReached: "Alerte: {{timeRemaining}} restantes avant l'expiration de votre réservation.",
      ReservationWarningThresholdReached: "Attention: {{timeRemaining}} restantes avant l'expiration de votre réservation.",
      TestPlatform: "Plateforme de Livraison de l'Exercice:",
      AppStream: "Applications en streaming",
      WebSite: "Application Web",
      ChangeTestPlatformTitle: "Changer la Plateforme de Livraison de l'Exercice",
      ChangeTestPlatformExplanation: "Changer la plateforme de livraison de l'exercice entraînera la réinitialisation des applications, des saisies, des résultats, et de certaines options de l'exercice. Êtes-vous sûr(e) de vouloir changer la plateforme de l'exercice?",
      Test: "Exercice",
      InAny: "Dans",
      InAll: "Dans tous",
      NotIn: "Pas dans",
      StartsWith: "Commence par",
      NotStartsWith: "Ne commence pas par",
      FilterField: "Champ",
      FilterOperator: "Opération",
      FilterValues: "Valeurs",
      IncompleteFilterRules: "La règle de filtrage est incomplète.",
      SendEmailAddress: "Envoyer l'email depuis",
      ChangeSendEmailAddress: "Changer l'adresse email d'envoi",
      CancelPendingSendEmailAddressChange: "Annuler la modification en cours",
      ConfirmCancelPendingSendEmailChange: "Confirmer l'annulation de la modification en cours",
      ConfirmCancelPendingSendEmailText: "Êtes-vous sûr de vouloir annuler la modification en cours de l'adresse email d'envoi ?",
      NewSendEmailAddressSameAsCurrent: "L'adresse email d'envoi spécifiée est la même que l'adresse email d'envoi actuelle."
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "English",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
