import DOMPurify from "dompurify";
import parse from "html-react-parser";

export const parseInstructionsString = (instructionsString) => {
  const cleanHtmlString = DOMPurify.sanitize(instructionsString, {
    USE_PROFILES: { html: true },
  });
  const html = parse(cleanHtmlString);
  return html;
};

export function downloadInstructions(
  setIsUrlLoading,
  getTestInstanceInstructionsDownloadURL,
  orgId,
  instanceId,
  lsEnqueueSnackbar) {

  setIsUrlLoading(true);
  getTestInstanceInstructionsDownloadURL({
    variables: {
      orgId: orgId,
      instanceId: instanceId,
    },
  })
    .then((res) => {
      if (res.errors) {
        lsEnqueueSnackbar(res.errors[0].message, {
          variant: "error",
        });
      } else {
        let url = res.data.getTestInstanceInstructionsDownloadURL;
        const win = window.open(url, "_blank");
        win.focus();
      }
      setIsUrlLoading(false);
    })
    .catch((e) => {
      lsEnqueueSnackbar(e.message, {
        variant: "error",
      });
      setIsUrlLoading(false);
    }); // catch network errors;
}

export async function getInstructionsDownloadURL(
  getTestInstanceInstructionsDownloadURL,
  orgId,
  instanceId) {

  let url;  
  try {
    let res = await getTestInstanceInstructionsDownloadURL({
      variables: {
        orgId: orgId,
        instanceId: instanceId,
      }
    });
    if (res.errors) {
      throw new Error(res.errors[0].message);
    } else {
      url = res.data.getTestInstanceInstructionsDownloadURL;
    }
  } catch (e) {
    throw e;
  }  
  return url;  
}