import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from "@mui/material";
import { useFormik } from "formik";
import { t } from "i18next";
import { useMutation } from "@apollo/client";
import { handleCacheUpdate } from "../../cache";
import { GET_TEST_INSTANCES_ASSIGNED_BY_ME } from "../../graphql/queries";

import { REJECT_TEST_INSTANCE } from "../../graphql/mutations";
import { useCustomSnackbar } from "../../components/CustomSnackbar";
import { useAppContext } from "../../context/AppContext";

export default function CancelTestInstanceForm({
  open,
  handleClose,
  orgId,
  instanceId
}) {
  const { lsEnqueueSnackbar } = useCustomSnackbar();
  const { store } = useAppContext();

  const [rejectTestInstance] = useMutation(REJECT_TEST_INSTANCE, {
    update: (cacheClient, response) => {
      if (response?.data) {
        handleCacheUpdate(
          "delete",
          cacheClient,
          response.data.rejectTestInstance,
          "id",
          GET_TEST_INSTANCES_ASSIGNED_BY_ME,
          "getTestInstancesAssignedByMe",
          null,
          { 
            orgId: orgId,
            options: {
              filtering: store.assignedByMeFilter?.length > 0 ? store.assignedByMeFilter : null,
            }
          }
        );
      }
    },
  });

  const formik = useFormik({
    initialValues: {
      targetCompletionDate: new Date(),
    },
    onSubmit: async (values, { setSubmitting }) => {
      const vars = {
        variables: {
          orgId: orgId,
          instanceId: instanceId,
        },
      };

      if (open) {
        rejectTestInstance(vars)
          .then((res) => {
            if (res.errors) {
              lsEnqueueSnackbar(res.errors[0].message, {
                variant: "error",
              });
              setSubmitting(false);
            } else {
              lsEnqueueSnackbar(t("TestInstanceCancelled"), {
                variant: "success",
              });
              setSubmitting(false);
              handleClose();
            }
          })
          .catch((e) => {
            lsEnqueueSnackbar(t("ErrorWithTestInstanceCancellation"), {
              variant: "error",
            });
            setSubmitting(false);
            handleClose();
          }); // catch network errors
      }
    },
  });

  let pageContents = (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle id="form-dialog-title">
          {t("CancelTestInstanceTitle")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("CancelTestInstanceExplanation")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="secondary"
            variant="contained"
          >
            {t("Cancel")}
          </Button>
          <Button
            onClick={formik.handleSubmit}
            color="primary"
            variant="contained"
          >
            {t("Confirm")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );

  return pageContents;
}
