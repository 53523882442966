import { Grid } from "@mui/material";
import React, { useEffect, useState, useCallback } from "react";
import { useAppContext } from "../../context/AppContext";
import { useCustomSnackbar } from "../../components/CustomSnackbar";
import { Waiting } from "../../components/Waiting";
import { useMutation } from "@apollo/client";
import { gqlCli } from "../../identity";

import {
  LIST_TEST_INSTANCE_FOLDER_CONTENTS,
} from "../../graphql/queries";
import {
    GET_TEST_INSTANCE_FILE_DOWNLOAD_URL,
} from "../../graphql/mutations";
import useGuardedRoute from "../../hooks/useGuardedRoute";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import FileSystem, {
  FileSystemItemType,
  rootFolderName,
  rootFolderPath
} from "../../components/FileSystem/FileSystem";
import { formatBytesToString } from "../../utils";
import { FileTree } from "../FileSystem/FileTree";

const TestInstanceFileBrowser = ({
  draggable, 
  selectable, 
  downloadable, 
  droppable, 
  isShown,
  fhGet,
  setFHGet,
  fhData,
  setFHData,
  submittingTest = false
}) => {
  const { lsEnqueueSnackbar } = useCustomSnackbar();
  const { store } = useAppContext();
  const [fhLoading, setFHLoading] = useState(false);
  const [previousIsShown, setPreviousIsShown] = useState(false);
  const [transformedFiles, setTransformedFiles] = useState(new FileTree([]));
  const [selectedItem, setSelectedItem] = useState(null);
  const [hasWaited, setHasWaited] = useState(false);

  const appStreamFileCopyWaitTime = 5000;

  // Teemporary fix for delay when AppStream copies files back from the windows image to S3:
  // Wait before retreieving files. Only do this if we're submitting a test, and the test is
  // an appstream test
  useEffect(() => {
    if (store.currentTestInstance?.testTemplate.testPlatform === 'appstream' && 
      submittingTest) { 
      if (!hasWaited) {
        const timerId = setInterval(() => {
          setHasWaited(true);
        }, appStreamFileCopyWaitTime);
        return () => clearInterval(timerId);
      }
    } else {
      setHasWaited(true);
    }
  }, [hasWaited, 
    store.currentTestInstance?.testTemplate.testPlatform, 
    submittingTest]);

  const getFileHierarchy = useCallback(async () => {
    setFHLoading(true);
    try {
      let fileHierarchy = await getTestFileHierarchy(
        store.currentTestInstance?.testTemplate.org.id,
        store.currentTestInstance?.id,
        rootFolderPath
      );
      setFHLoading(false);
      setFHData(fileHierarchy);
    } catch (e) {
      console.log(e);
      setFHLoading(false);
    }
  }, [setFHLoading, store.currentTestInstance?.testTemplate.org.id,
    store.currentTestInstance?.id, setFHData]);

  useEffect(() => {
    if (isShown && !previousIsShown && hasWaited) {
      // console.log("Component has been toggled, refresh data");
      setFHGet(false);
      getFileHierarchy();
    }
    setPreviousIsShown(isShown);
  }, [isShown, previousIsShown, setFHGet, hasWaited, getFileHierarchy]);

  useGuardedRoute("/app", [store.currentTestInstance]);

  useEffect(() => {
    if (fhGet && hasWaited) {
      setFHGet(false);
      getFileHierarchy();
    }  
  }, [fhGet, setFHGet, getFileHierarchy, hasWaited]);


  const downloadFile = (event, item) => {
    // console.log(`Downloading testFile: ${item.path}`);
    getTestInstanceFileDownloadURL({
        variables: {
            orgId: store.currentTestInstance?.testTemplate.org.id,
            instanceId: store.currentTestInstance.id,
            path: item.id,
            isFolder: false,
        }
    })
      .then((res) => {
          if (res.errors) {
              lsEnqueueSnackbar(res.errors[0].message, {
                  variant: "error",
              });
          } else {
              let url = res.data.getTestInstanceFileDownloadURL;
              const win = window.open(url, "_blank");
              win.focus();
          }
      });
  }

  const [getTestInstanceFileDownloadURL] = useMutation(
    GET_TEST_INSTANCE_FILE_DOWNLOAD_URL
  );

  // Transform paths into file system friendly data
  useEffect(() => {
    let allFiles = []
    if (fhData) {
      allFiles = fhData.map((file) => {
        return {
          id: file.fullpath,
          path: file.fullpath.substring(rootFolderPath.length),
          title: file.filename,
          subtitle: file.isFolder ? "" : formatBytesToString(file.size),
          type: file.isFolder ? FileSystemItemType.CreatedFolder : FileSystemItemType.File,
          children: [],
        }
      });
    }
    const fileTree = new FileTree(allFiles, rootFolderPath);

    setTransformedFiles(fileTree);
  }, [fhData]);

  let pageResults;
  if (fhLoading || !hasWaited) {
    pageResults = <Waiting />;
  } else if (fhData) {
    pageResults = (
      <>
        <DndProvider backend={HTML5Backend}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FileSystem
                rootFolderName={rootFolderName}
                data={transformedFiles}
                draggable={draggable}
                droppable={droppable}
                selectable={selectable}
                onDownload={downloadable ? downloadFile: null}
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
              />
            </Grid>
          </Grid>
        </DndProvider>
      </>
    );
  } else {
    pageResults = "";
  }

  return pageResults;
};

async function getTestFileHierarchy(orgId, instanceId, path) {
  let res = [];
  let vars = {
    orgId: orgId,
    instanceId: instanceId,
    path: path,
    recursive: true
  };

  let queryRes = await gqlCli.query({
    query: LIST_TEST_INSTANCE_FOLDER_CONTENTS,
    fetchPolicy: "no-cache",
    variables: vars
  });

  if (queryRes.error) {
    throw queryRes.error.message;
  }
  if (queryRes.data) {
    for (let tf of queryRes.data.listTestInstanceFolderContents) {
      let rootPath = (path === '/') ? '' : (path.endsWith('/') ? 
        path.substring(0, path.length - 1) : path);
      tf.fullpath = rootPath + tf.folder + (tf.folder.endsWith('/') ? '' : '/') + tf.filename;
      res.push(tf);
    }
  }
  return res;
}

export default TestInstanceFileBrowser;
