import React, { useState, useRef, useEffect, forwardRef } from "react";
import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  MenuList,
  ListItemIcon,
  ListItemText,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  // Typography,
  Divider,
  Tooltip,
  Chip
} from "@mui/material";
import AppsIcon from "@mui/icons-material/Apps";
import MenuIcon from "@mui/icons-material/Menu";
import Slide from '@mui/material/Slide';
// import ContentCopyIcon from "@mui/icons-material/ContentCopy";
// import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import PauseCircleOutlineOutlinedIcon from '@mui/icons-material/PauseCircleOutlineOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
// import LanguageIcon from "@mui/icons-material/Language";
// import SettingsIcon from "@mui/icons-material/Settings";
// import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import SummarizeIcon from "@mui/icons-material/SummarizeOutlined";
import FilledPushPinIcon from "@mui/icons-material/PushPin";
import OutlinedPushPinIcon from "@mui/icons-material/PushPinOutlined";
import { useAppStreamContext } from "../../context/AppStreamContext";
import { useAppContext } from "../../context/AppContext";
import { t } from "i18next";
import { MAC_SCREEN_TOP, isMac } from "../../views/Candidate/TestInstance";

export const isFullScreen = () => {
  return document.fullscreenElement ||
    document.webkitFullscreenElement ||
    document.msFullscreenElement;
};


const AppStreamToolbar = forwardRef(({
  testAppsData,
  currentReservation,
  instanceTimeRemaining,
  reservationTimeRemaining,
  formattedInstanceTimeRemaining,
  formattedReservationTimeRemaining,
  showToolbar,
  setShowToolbar,
  toolbarPinned,
  setToolbarPinned,
  setToolbarExited,
  handlePauseTest,
  handleFinishTest,
  setToolbarHeight,
  timerThreshold
}, ref) => {

  // Measure the height of the toolbar
  useEffect(() => {
    const toolbarNode = ref.current;
    const toolbarHeight = toolbarNode ? toolbarNode.getBoundingClientRect().height : 0;
    setToolbarHeight(toolbarHeight);
  }, [ref, setToolbarHeight]);


  const handleMouseLeave = (event) => {
    if (isFullScreen() && !toolbarPinned && (!isMac || event.clientY > MAC_SCREEN_TOP)) {
      setShowToolbar(false);
    }
  };

  const onToolbarExited = () => {
    setToolbarExited(true);
  };

  return (
    <div
      ref={ref}
      onMouseLeave={handleMouseLeave}
    >
      {isFullScreen() ?
        <Slide
          direction="down"
          in={toolbarPinned || showToolbar}
          onExited={onToolbarExited}
        >
          <FixedAppStreamToolbar
            ref={ref}
            testAppsData={testAppsData}
            currentReservation={currentReservation}
            formattedInstanceTimeRemaining={formattedInstanceTimeRemaining}
            formattedReservationTimeRemaining={formattedReservationTimeRemaining}
            instanceTimeRemaining={instanceTimeRemaining}
            reservationTimeRemaining={reservationTimeRemaining}
            toolbarPinned={toolbarPinned}
            setToolbarPinned={setToolbarPinned}
            handlePauseTest={handlePauseTest}
            handleFinishTest={handleFinishTest}
            timerThreshold={timerThreshold}
          />
        </Slide> :
        <FixedAppStreamToolbar
          ref={ref}
          testAppsData={testAppsData}
          currentReservation={currentReservation}
          formattedInstanceTimeRemaining={formattedInstanceTimeRemaining}
          formattedReservationTimeRemaining={formattedReservationTimeRemaining}
          instanceTimeRemaining={instanceTimeRemaining}
          reservationTimeRemaining={reservationTimeRemaining}
          toolbarPinned={toolbarPinned}
          setToolbarPinned={setToolbarPinned}
          handlePauseTest={handlePauseTest}
          handleFinishTest={handleFinishTest}
          timerThreshold={timerThreshold}
        />}
    </div>
  );
});

const FixedAppStreamToolbar = forwardRef(({
  testAppsData,
  currentReservation,
  formattedInstanceTimeRemaining,
  formattedReservationTimeRemaining,
  instanceTimeRemaining,
  reservationTimeRemaining,
  toolbarPinned,
  setToolbarPinned,
  handlePauseTest,
  handleFinishTest,
  timerThreshold
}, ref) => {

  const { store: asStore, actions: asActions } = useAppStreamContext();
  const { store } = useAppContext();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleLaunchApp = (appId) => {
    if (store.currentTestInstance?.testTemplate.testPlatform === 'appstream') {
      asStore.appStreamEmbededRef.current.launchApp(appId);
    }
  };

  const handlePinToggle = () => {
    setToolbarPinned(!toolbarPinned);
  };

  const handleFullscreen = () => {
    const exitFunc = document.exitFullscreen || document.webkitExitFullscreen;
    const exitFullscreen = exitFunc.bind(document);

    if (!isFullScreen()) {
      const appStreamContainer = asStore.appStreamContainerElement.current;

      // Supports most browsers and their versions.
      const requestMethod =
        appStreamContainer.requestFullScreen ||
        appStreamContainer.webkitRequestFullScreen ||
        appStreamContainer.mozRequestFullScreen ||
        appStreamContainer.msRequestFullScreen;

      if (requestMethod) {
        // Native full screen.
        requestMethod.call(appStreamContainer);
      }
    } else {
      exitFullscreen();
    }
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  return (
    <Grid ref={ref} container alignItems="center" justifyContent="flex-start"
      sx={{ bgcolor: store.siteConfig.brandcolors.thirdcolor, padding: 1 }}>
      <Grid item xs={6}>
        <Box sx={{ flexGrow: 1 }}>
          {isFullScreen() &&
            <IconButton onClick={handlePinToggle}>
              {toolbarPinned ? <FilledPushPinIcon /> : <OutlinedPushPinIcon />}
            </IconButton>
          }
          {testAppsData?.map((r) => (
            <IconButton
              aria-label={r.displayName}
              size="small"
              key={r.id}
              onClick={() => handleLaunchApp(r.name)}
            >
              <Avatar alt={r.displayName} src={r.icon} variant="square" />
            </IconButton>
          ))}
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Grid container alignItems="center" justifyContent="flex-end">
          {asStore.appStreamContainerElement?.current && (
            <>
              {(currentReservation &&
                <Tooltip
                  title={t("ReservationTimeRemaining")}
                  placement="bottom"
                  arrow
                  PopperProps={{
                    container: anchorRef.current
                  }}
                >
                  <Chip
                    icon={<EventOutlinedIcon />}
                    label={formattedReservationTimeRemaining}
                    color={reservationTimeRemaining > timerThreshold.warning ? "success" :
                      reservationTimeRemaining > timerThreshold.alert ? "warning" : "error"}
                    sx={{
                      mr: 1
                    }}
                  />
                </Tooltip>
              )}
              <Tooltip
                title={t("TimeRemaining")}
                placement="bottom"
                arrow
                PopperProps={{
                  container: anchorRef.current
                }}
              >
                <Chip
                  icon={<TimerOutlinedIcon />}
                  label={formattedInstanceTimeRemaining}
                  color={instanceTimeRemaining > timerThreshold.warning ? "success" :
                    instanceTimeRemaining > timerThreshold.alert ? "warning" : "error"}
                />
              </Tooltip>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<SummarizeIcon />}
                onClick={(event) => {
                  handleClose(event);
                  asActions.setIsInstructionsPopupShown(true);
                }}
                size="small"
                sx={{ ml: 1 }}
              >
                {t("ShowInstructions")}
              </Button>
              <Button
                variant="contained"
                color="primary"
                startIcon={<CheckCircleOutlinedIcon />}
                onClick={handleFinishTest}
                size="small"
                sx={{ ml: 1 }}
              >
                {t("FinishTest")}
              </Button>
              {store.currentTestInstance?.testTemplate.allowPause &&
                <Tooltip
                  title={t("PauseTest")}
                  placement="bottom"
                  arrow
                  PopperProps={{
                    container: anchorRef.current
                  }}
                >
                  <IconButton onClick={handlePauseTest}>
                    <PauseCircleOutlineOutlinedIcon sx={{ color: "white" }} />
                  </IconButton>
                </Tooltip>
              }
              {isFullScreen() ?
                (<Tooltip
                  title={t("ExitFullScreen")}
                  placement="bottom"
                  arrow
                  PopperProps={{
                    container: anchorRef.current
                  }}
                >
                  <IconButton onClick={handleFullscreen}>
                    <CloseFullscreenIcon sx={{ color: "white" }} />
                  </IconButton>
                </Tooltip>) :
                (<Tooltip
                  title={t("FullScreen")}
                  placement="bottom"
                  arrow
                >
                  <IconButton onClick={handleFullscreen}>
                    <OpenInFullIcon sx={{ color: "white" }} />
                  </IconButton>
                </Tooltip>)
              }
            </>
          )}
          <IconButton
            ref={anchorRef}
            id="composition-button"
            aria-controls={open ? "composition-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
          >
            <MenuIcon sx={{ color: "white" }} />
          </IconButton>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            placement="bottom-start"
            transition
            disablePortal
            sx={{
              position: "absolute",
              zIndex: (theme) => theme.zIndex.drawer + 10,
            }}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom-start" ? "left top" : "left bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={open}
                      id="composition-menu"
                      aria-labelledby="composition-button"
                      onKeyDown={handleListKeyDown}
                    >
                      {/* <MenuItem
                        onClick={(event) => {
                          handleClose(event);
                          asStore.appStreamInstance.Elements.COPY_LOCAL_BUTTON();
                        }}
                      >
                        <ListItemIcon>
                          <ContentCopyIcon />
                        </ListItemIcon>
                        <ListItemText>{t("Copy")}</ListItemText>
                      </MenuItem>
                      <MenuItem
                        onClick={(event) => {
                          handleClose(event);
                          asStore.appStreamInstance.Elements.PASTE_REMOTE_BUTTON();
                        }}
                      >
                        <ListItemIcon>
                          <ContentPasteIcon />
                        </ListItemIcon>
                        <ListItemText>{t("Paste")}</ListItemText>
                      </MenuItem> */}
                      <MenuItem
                        disabled={store.currentTestInstance?.testTemplate.testPlatform === 'website'}
                        onClick={(event) => {
                          handleClose(event);
                          asActions.setIsFileBrowserPopupShown(true);
                        }}
                      >
                        <ListItemIcon>
                          <FolderOpenIcon />
                        </ListItemIcon>
                        <ListItemText>{t("BrowseFiles")}</ListItemText>
                      </MenuItem>
                      <MenuItem
                        onClick={(event) => {
                          handleClose(event);
                          asActions.setIsInstructionsPopupShown(true);
                        }}
                      >
                        <ListItemIcon>
                          <SummarizeIcon />
                        </ListItemIcon>
                        <ListItemText>{t("ShowInstructions")}</ListItemText>
                      </MenuItem>
                      <MenuItem
                        disabled={store.currentTestInstance?.testTemplate.testPlatform === 'website'}
                        onClick={(event) => {
                          handleClose(event);
                          asStore.appStreamEmbededRef.current.launchAppSwitcher();
                        }}
                      >
                        <ListItemIcon>
                          <AppsIcon />
                        </ListItemIcon>
                        <ListItemText>{t("AppSwitcher")}</ListItemText>
                      </MenuItem>
                      <Divider />

                      <MenuItem
                        onClick={handleFinishTest}
                      >
                        <ListItemIcon>
                          <CheckCircleOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText>{t("FinishTest")}</ListItemText>
                      </MenuItem>
                      <MenuItem
                        disabled={!store.currentTestInstance?.testTemplate.allowPause}
                        onClick={handlePauseTest}
                      >
                        <ListItemIcon>
                          <PauseCircleOutlineOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText>{t("PauseTest")}</ListItemText>
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Grid>
      </Grid>
    </Grid>
  );
});

export default AppStreamToolbar;
