import {
  Grid,
  Snackbar,
  Alert,
  Button
  // Tab,
  // Tabs,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import AppStream from "../../components/TestInstance/AppStream";
import { useAppContext } from "../../context/AppContext";
import { useAppStreamContext } from "../../context/AppStreamContext";
import { useCustomSnackbar } from "../../components/CustomSnackbar";
import { Waiting } from "../../components/Waiting";
import { RECORD_TEST_INSTANCE_SESSION_CONNECTION_STATUS } from "../../graphql/mutations";
import {
  GET_TEST_INSTANCE,
  GET_TEST_INSTANCES_ASSIGNED_TO_ME,
} from "../../graphql/queries";
import { useLazyQuery, useMutation } from "@apollo/client";
import useGuardedRoute from "../../hooks/useGuardedRoute";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
// import { format } from "date-fns";
import { enCA, frCA } from "date-fns/locale";
import { getCurrentLanguage } from "../../App";
import AppStreamToolbar from "../../components/TestInstance/AppStreamToolbar";
import { isFullScreen } from "../../components/TestInstance/AppStreamToolbar";
import TestInstanceFileBrowser from "../../components/TestInstance/TestInstanceFileBrowser";
import ScopedPopup from "../../components/ScopedPopup";
import { handleCacheUpdate } from "../../cache";
import { gqlCli } from "../../identity";
import WebsiteTest from "../../components/TestInstance/WebsiteTest";
import TestInstructions from "../../components/TestInstance/TestInstructions";
import useSessionUrl from "../../hooks/useSessionUrl";
import { config } from "../../config";

const timerThreshold = {
  warning: 5 * 60,    // warning at 5 minutes
  alert: 60           // alert at 1 minute
};

export const MAC_SCREEN_TOP = 40;
export const isMac =
  window.navigator.userAgent.toLowerCase().indexOf("mac") !== -1;

const TestInstance = () => {
  const { store, actions } = useAppContext();
  const { store: asStore, actions: asActions } = useAppStreamContext();
  const { lsEnqueueSnackbar } = useCustomSnackbar();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [time, setTime] = useState(0);
  const [sessionActive, setSessionActive] = useState(false);
  const [sessionStartDate, setSessionStartDate] = useState(null);
  const [instanceUpdated, setInstanceUpdated] = useState(false);
  const [showToolbar, setShowToolbar] = useState(true);
  const [toolbarPinned, setToolbarPinned] = useState(true);
  const [toolbarExited, setToolbarExited] = useState(false);
  const [currentDatetime, setCurrentDatetime] = useState(Date.now());
  // const [reservationTimeRemaining, setReservationTimeRemaining] = useState(0);
  // const resTimeRemaining = useRef(0);
  const [fhGet, setFHGet] = useState(true);
  const [showTimerWarning, setShowTimerWarning] = useState(false);
  const [timerWarningInfo, setTimerWarningInfo] = useState(null);
  const [timerWarningShown, setTimerWarningShown] = useState(false);
  const [timerAlertShown, setTimerAlertShown] = useState(false);
  const [fhData, setFHData] = useState(null);

  const toolbarRef = useRef(null);
  const [toolbarHeight, setToolbarHeight] = useState(0);
  const [testInstanceUpdated, setTestInstanceUpdated] = useState(false);
  const [session, setSession] = useState(null);
  const [instanceTimeRemaining, setInstanceTimeRemaining] = useState(getTimeRemaining());
  const [hasInstructionsAutoPoppedUp, setHasInstructionsAutoPoppedUp] = useState(false);
  
  // refresh the test instance before starting the test to get accurate time remaining data
  const [getTestInstance, { data: instData, loading: instLoading }] = useLazyQuery(GET_TEST_INSTANCE, {
    variables: {
      orgId: store.currentTestInstance?.testTemplate.org.id,
      instanceId: store.currentTestInstance?.id,
    },
    onError: (e) => {
      lsEnqueueSnackbar(e.message, {
        variant: "error",
      });
    },
    fetchPolicy: "network-only",
  });

  const graceTime = 5 * 60;  // 5 minutes grace time for reservations

  const currentReservation = store.currentTestInstance?.reservations?.find((r) => {
    let now = Date.now();
    let start = new Date(r.timeslot.start).getTime();
    return now >= start && now < start + (r.timeslot.duration + graceTime) * 1000;
  });

  function getTimeRemaining() {
    let timeRemaining;
    if (sessionStartDate) {
      timeRemaining = Math.max(store.currentTestInstance?.workTimeRemaining -
        Math.trunc((Date.now() - sessionStartDate.getTime()) / 1000), 0);
    } else {
      timeRemaining = store.currentTestInstance?.workTimeRemaining;
    }
    return timeRemaining;
  }

  const handleSessionActive = (isActive) => {
    setSessionActive(isActive);
    if (isActive && !sessionStartDate) {
      if (store.currentTestInstance?.testSession?.status === "connected") {
        setSessionStartDate(new Date(store.currentTestInstance.testSession.connectionDate));
      } else {
        setSessionStartDate(new Date(Date.now()));
      }
    }
  };

  const handleCloseTimerWarning = () => {
    setShowTimerWarning(false);
  };

  useEffect(() => {
    if (!hasInstructionsAutoPoppedUp && config.autoShowInstructionsPopupWhenTestStarts) {
      setHasInstructionsAutoPoppedUp(true);
      asActions.setIsInstructionsPopupShown(true);
    }
  }, [hasInstructionsAutoPoppedUp, asActions]);
  
  let reservationTimeRemaining;
  if (currentReservation) {
    // include 10 minutes grace time
    reservationTimeRemaining = currentReservation.timeslot.duration + graceTime -
      (currentDatetime - new Date(currentReservation.timeslot.start).getTime()) / 1000;

    if (reservationTimeRemaining > instanceTimeRemaining) {
      reservationTimeRemaining = instanceTimeRemaining;
    } else if (reservationTimeRemaining < 0) {
      reservationTimeRemaining = 0;  
    } 
  } else {
    reservationTimeRemaining = 0;  
  } 


  const formatTime = (time) =>
    `${String(Math.floor(time / 3600)).padStart(1, "0")}:${String(
      Math.floor((time % 3600) / 60)
    ).padStart(2, "0")}:${String(Math.floor((time % 3600) % 60)).padStart(
      2,
      "0"
    )}`;

  const formattedInstanceTimeRemaining = formatTime(instanceTimeRemaining);
  const formattedReservationTimeRemaining = formatTime(reservationTimeRemaining);

  let thresholdTime;
  let thresholdType;
  let messageTimeRemaining;
  if (instanceTimeRemaining <= reservationTimeRemaining || !currentReservation) {
    thresholdTime = instanceTimeRemaining;
    thresholdType = 'Test';
    messageTimeRemaining = formattedInstanceTimeRemaining;
  } else {
    thresholdTime = reservationTimeRemaining;
    thresholdType = 'Reservation';
    messageTimeRemaining = formattedReservationTimeRemaining;
  }

  if (thresholdTime <= timerThreshold.alert && !timerAlertShown) {
    setTimerWarningInfo({
      severity: "error",
      messageKey: `${thresholdType}AlertThresholdReached`
    });
    setTimerAlertShown(true);
    setShowTimerWarning(true);
  } else if (thresholdTime <= timerThreshold.warning && !timerWarningShown) {
    setTimerWarningInfo({
      severity: "warning",
      messageKey: `${thresholdType}WarningThresholdReached`
    });
    setTimerWarningShown(true);
    setShowTimerWarning(true);
  }

  useGuardedRoute("/app", [store.currentTestInstance]);

  let currentLanguage = getCurrentLanguage();
  let dateFormat;
  if (currentLanguage === "English") {
    dateFormat = enCA;
  } else if (currentLanguage === "French") {
    dateFormat = frCA;
  }

  const {
    data: testInstanceSessionUrl, 
    error: tisurlError, 
    loading: tisurlLoading 
  } = useSessionUrl({ readOnly: false});

  const [recordTestInstanceSessionConnectionStatus] = useMutation(
    RECORD_TEST_INSTANCE_SESSION_CONNECTION_STATUS
  );

  const handleMouseMove = (event) => {
    if (
      isFullScreen() &&
      (event.screenY === 0 ||
        (isMac && event.screenY > 0 && event.screenY <= MAC_SCREEN_TOP + 10)) &&
      !showToolbar
    ) {
      setShowToolbar(true);
      setToolbarExited(false);
    }
  };

  const sendConnectionStatusRequest = async (status) => {
    const orgId = store.currentTestInstance?.testTemplate?.org?.id;
    const instanceId = store.currentTestInstance?.id;
    if (!orgId || !instanceId) {
      console.error("Org ID or Instance ID is missing. Cannot update session information.");
      return;
    }
    try {
      let response = await recordTestInstanceSessionConnectionStatus({
        variables: {
          orgId,
          instanceId,
          status,
        },
      });
      let sessionResponse = response?.data?.recordTestInstanceSessionConnectionStatus;
      console.log('recordConnectionStatusRequest sent successfully', sessionResponse);
      if (sessionResponse) {
        if (sessionResponse && (sessionResponse.status !== session?.status || sessionResponse.connectionDate !== session?.connectionDate)) {
          setSession(sessionResponse);
        }
      }
      return response;
    } catch (error) {
      console.error('recordConnectionStatusRequest failed', error);
    }
  };

  // keep track of whether or not we're on the test taking screen
  useEffect(() => {
    actions.setTakingTest(true);

    return () => actions.setTakingTest(false);
  }, [actions]);

  useEffect(() => {
    if (instData && !instanceUpdated) {
      // update the instance in the cache
      let testInstance = instData.getTestInstance;
      handleCacheUpdate(
        "update",
        gqlCli,
        testInstance,
        "id",
        GET_TEST_INSTANCES_ASSIGNED_TO_ME,
        "getTestInstancesAssignedToMe",
        null,
        {}
      );
      // update the instance in the store
      actions.setCurrentTestInstance(testInstance);
      setInstanceUpdated(true);
    }
  }, [instData, actions, instanceUpdated]);

  /**
   * Setup a listener to the document to make the
   * AppStream container resizing on full screen
   * and when exiting full screen.
   *
   * Fullscreen triggered in AppStreamToolbar.js
   */
  useEffect(() => {
    const exitHandler = () => {
      // if exiting full screen
      if (
        !document.webkitIsFullScreen &&
        !document.mozFullScreen &&
        !document.msFullscreenElement
      ) {
        asActions.setIsAppStreamFullscreen(false);
      } else {
        // if entering full screen
        asActions.setIsAppStreamFullscreen(true);
      }
    };

    if (document.addEventListener) {
      document.addEventListener("fullscreenchange", exitHandler, false);
      document.addEventListener("mozfullscreenchange", exitHandler, false);
      document.addEventListener("MSFullscreenChange", exitHandler, false);
      document.addEventListener("webkitfullscreenchange", exitHandler, false);
    }

    return () => {
      // remove the exit listener when leaving the view
      document.removeEventListener("fullscreenchange", exitHandler);
      document.removeEventListener("mozfullscreenchange", exitHandler);
      document.removeEventListener("MSFullscreenChange", exitHandler);
      document.removeEventListener("webkitfullscreenchange", exitHandler);
    };
  }, [asActions]);

  useEffect(() => {
    const timerId = setInterval(() => {
      if (sessionActive) {
        setInstanceTimeRemaining((t) => t - 1);
        if (instanceTimeRemaining - 1 <= 0) {
          handleFinishTest();
        }
      }
    }, 1000);
    return () => clearInterval(timerId);
  }, [sessionActive]);

  useEffect(() => {
    var timerId = setInterval(() => setCurrentDatetime(Date.now()), 500);
    return () => clearInterval(timerId);
  });

  /**
   * 
   * @param {"pause" | "submit"} sessionEndReason 
   */
  const endSession = (sessionEndReason) => {
    let nextUrl;
    if (sessionEndReason === "pause") {
      nextUrl = "/app";
    } else if (sessionEndReason === "submit") {
      nextUrl = "/app/submitTest"
    } else {
      throw new Error(`Invalid sessionEndReason. Must be [pause|submit]. Provided: ${sessionEndReason}.`);
    }
    if (asStore.appStreamEmbededRef.current) {
      asStore.appStreamEmbededRef.current.addEventListener(
        window.AppStream.Embed.Events.SESSION_STATE_CHANGE,
        (event) => {
          let status = event[window.AppStream.Embed.EventParams.STATUS];
          if (
            window.AppStream.Embed.SessionStatus[status] ===
            window.AppStream.Embed.SessionStatus.Disconnected
          ) {
            console.log(`Session disconnected because of ${sessionEndReason}.`);
            setSessionActive(false);
            navigate(nextUrl);
          }
        }
      );
      asStore.appStreamEmbededRef.current.destroy();
      asStore.appStreamEmbededRef.current = null;
      setSessionActive(false);
      navigate(nextUrl);
    } else {
      setSessionActive(false);
      navigate(nextUrl);
    }

  }

  const handlePauseTest = () => {
    endSession("pause");
  };

  const handleFinishTest = () => {
    endSession("submit");
  };

  const [activeTab, setActiveTab] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    if (testInstanceSessionUrl && !testInstanceUpdated) {
      getTestInstance();
      setTestInstanceUpdated(true);
    }
  }, [
    testInstanceSessionUrl, 
    testInstanceUpdated, 
    getTestInstance, 
    setTestInstanceUpdated
  ]);

  useEffect(() => {
    let intervalId;
    
    const startHeartbeat = () => {
      if (!sessionActive) return;
      intervalId = setInterval(() => {
        sendConnectionStatusRequest('connected');
      }, 5000);
    };
  
    if (sessionActive) {
      // Start the heartbeat only if the session is active
      startHeartbeat();
    }
  
    return () => {
      // Cleanup function to stop the heartbeat
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [sessionActive]);

  useEffect(() => {
    if (session) {
      getTestInstance().then(res => {
        const fetchedTestInstance = res.data?.getTestInstance;
        if (fetchedTestInstance) {
          actions.setCurrentTestInstance(fetchedTestInstance);
          if (session.status === 'connected') {
            setSessionStartDate(new Date(session.connectionDate));
          } else if (fetchedTestInstance.workTimeRemaining === 0) {
            handleFinishTest();
          } else {
            handlePauseTest();
          }
        }
      });
    }
  }, [session]);

  useEffect(() => {
    if (sessionStartDate && store.currentTestInstance) {
      setInstanceTimeRemaining(getTimeRemaining());
    }
  }, [sessionStartDate, store.currentTestInstance]);
  
  useEffect(() => {  
    return () => {
      // This cleanup function will be called when the component unmounts
      sendConnectionStatusRequest('disconnected').then(() => {
        console.log('Connection status set to disconnected');
      }).catch(error => {
        console.error('Failed to update connection status to disconnected', error);
      });
    };
  }, []); // The empty dependency array means this effect runs only on mount and unmount  


  let pageResults;

  if (instLoading || tisurlLoading) {
    pageResults = <Waiting />;
  } else if (tisurlError) {
    pageResults = (
      <ScopedPopup
        open={true}
        persistent={true}
        title={t("TestSessionErrorTitle")}
        description={t("TestSessionErrorText")}
        backdrop="solid"
      >
        <Button variant="contained" onClick={() => navigate("/app")}>
          {t("ReturnToDashboard")}
        </Button>
      </ScopedPopup>
    ); 
  } else {
    pageResults = (
      <Grid container spacing={2} onMouseMove={handleMouseMove}>
        <Grid item xs={12}>
          {/* The whole container goes full screen */}
          <div
            ref={asStore.appStreamContainerElement}
            style={{
              position: "relative",
              width: "100%",
              overflow: isFullScreen() ? "visible" : "hidden"
            }}
          >
            <div
              style={{
                zIndex: "1",
                position: isFullScreen() ? "fixed" : "static",
                left: "0",
                top:
                  isFullScreen() && isMac ? MAC_SCREEN_TOP.toString() + "px" : 0,
                width: "100%",
                overflow:
                  !isFullScreen() ||
                    !toolbarExited ||
                    showToolbar ||
                    toolbarPinned
                    ? "visible"
                    : "hidden",
                height:
                  !isFullScreen() ||
                    !toolbarExited ||
                    showToolbar ||
                    toolbarPinned
                    ? "auto"
                    : isMac
                      ? "5px"
                      : "1px",
              }}
            >
              <AppStreamToolbar
                ref={toolbarRef}
                testAppsData={store.currentTestInstance?.testTemplate.apps}
                currentReservation={currentReservation}
                formattedInstanceTimeRemaining={formattedInstanceTimeRemaining}
                formattedReservationTimeRemaining={formattedReservationTimeRemaining}
                instanceTimeRemaining={instanceTimeRemaining}
                reservationTimeRemaining={reservationTimeRemaining}
                showToolbar={showToolbar}
                setShowToolbar={setShowToolbar}
                toolbarPinned={toolbarPinned}
                setToolbarPinned={setToolbarPinned}
                setToolbarExited={setToolbarExited}
                handlePauseTest={handlePauseTest}
                handleFinishTest={handleFinishTest}
                setToolbarHeight={setToolbarHeight}
                timerThreshold={timerThreshold}
              />
            </div>
            <div
              style={{
                zIndex: "0",
                position: isFullScreen() ? "fixed" : "static",
                top:
                  isMac && isFullScreen()
                    ? MAC_SCREEN_TOP.toString() + "px"
                    : "0",
                width: "100%",
              }}
            >
              {store.currentTestInstance.testTemplate.testPlatform === 'appstream' ?
                (<AppStream
                  sessionUrl={testInstanceSessionUrl}
                  hideToolbar={true}
                  setSessionActive={handleSessionActive}
                  toolbarHeight={toolbarHeight}
                >
                  <ScopedPopup
                    open={asStore.isFileBrowserPopupShown}
                    onClose={() => asActions.setIsFileBrowserPopupShown(false)}
                    persistent={false}
                    title={t("FileBrowser")}
                    backdrop="transparent"
                  >
                    <div style={{
                      height: "400px",
                      overflow: "auto"
                    }}
                    >
                      <TestInstanceFileBrowser
                        downloadable={store.currentTestInstance?.testTemplate.allowDownload}
                        selectable={true}
                        isShown={asStore.isFileBrowserPopupShown}
                        fhGet={fhGet}
                        setFHGet={setFHGet}
                        fhData={fhData}
                        setFHData={setFHData}
                      />
                    </div>
                  </ScopedPopup>
                </AppStream>) :
                <WebsiteTest
                  sessionUrl={testInstanceSessionUrl}
                  toolbarHeight={toolbarHeight}
                  setSessionActive={handleSessionActive}
                />
              }
              <TestInstructions toolbarHeight={toolbarHeight}/>
            </div>
            {/* Note: can't use lsEnqueueSnackbar - won't show in full screen mode*/}
            <Snackbar
              open={showTimerWarning}
              onClose={handleCloseTimerWarning}
              autoHideDuration={10000}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              sx={{ zIndex: 2 }}
            >
              <Alert
                onClose={handleCloseTimerWarning}
                severity={timerWarningInfo?.severity || "warning"}
                variant="filled"
              >
                {timerWarningInfo ?
                  t(timerWarningInfo.messageKey, { timeRemaining: messageTimeRemaining }) :
                  ""}
              </Alert>
            </Snackbar>
          </div>
        </Grid>
      </Grid>
    )
  }

  return pageResults;
};

export default TestInstance;
