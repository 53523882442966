import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Grid,
  Typography,
  TextField,
  Dialog
} from "@mui/material";
import { Waiting } from "../../components/Waiting";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useCustomSnackbar } from "../../components/CustomSnackbar";
import { CREATE_ORG } from "../../graphql/mutations";
import { useMutation } from "@apollo/client";
import { useAppContext } from "../../context/AppContext";
import ConfirmationForm from "../../components/ConfirmationForm";


export default function CreateOrgForm(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { lsEnqueueSnackbar } = useCustomSnackbar();
  const [orgPending, setOrgPending] = useState(false);

  const { store } = useAppContext();

  const [createOrg, { loading: createOrgLoading }] = useMutation(CREATE_ORG);

  const handleCreateOrgPendingClose = () => {
    setOrgPending(false);
    navigate("/app");
  };

  const validationSchema = Yup.object({
    name: Yup.string().max(20, t("TwentyOrFewerChars")).required(t("Required")),
  });

  // create a unique initial org name based on the user's first and last name 
  const orgNameBase = store.userDetails.user.firstname + " " + store.userDetails.user.lastname; 
  const orgNames = store.userDetails.user?.rights?.map((right) => right.org.name)
    .filter((orgName) => orgName.startsWith(orgNameBase));
  let initialOrgName = orgNameBase;  
  for (let i = 1; orgNames.includes(initialOrgName); ++i) {
    initialOrgName = `${orgNameBase} (${i})`;
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: initialOrgName
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const vars = {
        variables: {
          name: values.name
        }
      };

      // Update the org profile on the server
      createOrg(vars)
        .then((res) => {
          if (res.data) {
            if (res.data.createOrg["__typename"] === 'Org') {
              // The org creation was created
              // props.setOrgName(values.name);
              lsEnqueueSnackbar(t("OrganizationCreated"), {
                variant: "success",
              });
              navigate("/app");
            } else {
              // The org creation is pending
              setOrgPending(true);
            }
          } else if (res.errors) {
            lsEnqueueSnackbar(res.errors[0].message, {
              variant: "error",
            });
            setSubmitting(false);
          }
        })
        .catch((e) => {
          lsEnqueueSnackbar(e.message, {
            variant: "error",
          });
          setSubmitting(false);
        }); // catch network errors
    },
  });

  // Determine if the user is the owner of any orgs already?
  const isFirstTimeOwner = store.userDetails?.user?.rights?.filter((right) => right.roles
    .find((role) => role.name === 'Owner')).length === 0;

  let pageResults = (
    <>
      {createOrgLoading && <Waiting />}
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2} paddingLeft="20%" paddingRight="20%">
          <Grid item xs={12}>
            <Typography
              component="h1"
              variant="h5"
              sx={{ marginBottom: 3, fontWeight: "bold" }}
            >
              {t("CreateOrg")}
            </Typography>
          </Grid>
          {isFirstTimeOwner && 
            <Grid item xs={12} sx={{ marginBottom: "20px" }}>
              <Typography
                variant="p"
              >
                {t("CreateOrgDialogText")}
              </Typography>
            </Grid>
          }
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="name"
              label={t("OrgName")}
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </Grid>
          <Grid container justifyContent="flex-end">
            <Grid item sx={{ textAlign: "right" }}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                sx={{ marginTop: 3, marginRight: 0, marginBottom: 1.5 }}
              >
                {t("Create")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Dialog
          open={orgPending}
          onClose={handleCreateOrgPendingClose}
        >
          <ConfirmationForm
            handleConfirmationClose={handleCreateOrgPendingClose}
            dialogTitle={t("OrganizationPendingTitle")}
            dialogContent={t("OrganizationPending")}
            showCancel={false}
          />
        </Dialog>
      </form>
    </>
  );
  return pageResults;
}
