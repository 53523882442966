import React from "react";
import { useNavigate } from "react-router-dom";
import { useCustomSnackbar } from "../../components/CustomSnackbar";
import { Typography, Button, Grid, TextField } from "@mui/material";
import { Waiting } from "../../components/Waiting";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client";

export default function ResendCodeSignUpForm(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { lsEnqueueSnackbar } = useCustomSnackbar();

  // Set up mutation to resend confirmation code
  const RESEND_CONFIRMATION = gql`
    mutation resendConfirmationCode($username: String!) {
      resendConfirmationCode(username: $username)
    }
  `;
  const [
    resendConfirmationCode,
    {
      loading: resendConfirmationCodeLoading,
      error: resendConfirmationCodeError,
    },
  ] = useMutation(RESEND_CONFIRMATION);

  const validationSchema = Yup.object({
    username: Yup.string().required(t("Required")),
  });

  const formik = useFormik({
    initialValues: {
      username: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const vars = {
        variables: {
          username: values.username.trim(),
        },
      };

      // Resend confirmation code and navigate to check email screen
      resendConfirmationCode(vars)
        .then((res) => {
          if (res.errors) {
            lsEnqueueSnackbar(res.errors[0].message, {
              variant: "error",
            });
            setSubmitting(false);
          } else {
            lsEnqueueSnackbar(t("CodeResent"), {
              variant: "success",
            });
            navigate("/app/checkemail");
          }
        })
        .catch((e) => {
          console.log(e);
          setSubmitting(false);
        });
    },
  });

  let pageContent;
  if (resendConfirmationCodeLoading) {
    pageContent = <Waiting />;
  } else if (!resendConfirmationCodeError) {
    pageContent = (
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2} paddingLeft="20%" paddingRight="20%">
          <Grid item xs={12}>
            <Typography
              component="h1"
              variant="h5"
              sx={{ marginBottom: 3, fontWeight: "bold" }}
            >
              {t("ResendCode")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography component="p" sx={{ marginBottom: 3 }}>
              {t("EnterUsername")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="username"
              label={t("UserName")}
              name="username"
              value={formik.values.username}
              onChange={formik.handleChange}
              error={formik.touched.username && Boolean(formik.errors.username)}
              helperText={formik.touched.username && formik.errors.username}
            />
          </Grid>
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ marginTop: 3, marginRight: 0, marginBottom: 1.5 }}
              >
                {t("ResendCode")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    );
  } else {
    pageContent = (
      <Typography>{resendConfirmationCodeError.message}</Typography>
    );
  }

  return <div>{pageContent}</div>;
}
