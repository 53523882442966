import React, { useState } from "react";
import {
  Grid,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Avatar,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useTranslation } from "react-i18next";
import { Waiting } from "../../components/Waiting";
import { useCustomSnackbar } from "../../components/CustomSnackbar";
import { GET_USERS, ORG_ROLES } from "../../graphql/queries";
import { useQuery } from "@apollo/client";
import EditUserRolesForm from "./ManageUserRoles";
import { useAppContext } from "../../context/AppContext";

export default function ManageUsersForm(props) {
  const { t } = useTranslation();
  const { lsEnqueueSnackbar } = useCustomSnackbar();
  const [openEditUserRoles, setOpenEditUserRoles] = useState(false);
  const [activeUserData, setActiveUserData] = useState(null);

  const { store } = useAppContext();

  // Handle opening of dialogs
  function handleOpenEditUserRoles(data) {
    setActiveUserData(data);
    setOpenEditUserRoles(true);
  }

  const handleRefresh = () => {
    refreshUsers();
  };

  const { data: arData, loading: arLoading } = useQuery(ORG_ROLES, {
    variables: {
      orgId: store.currentOrg.org.id,
    },
    onError: (e) => {
      lsEnqueueSnackbar(e.message, {
        variant: "error",
      });
    },
  });

  const {
    data: auData,
    loading: auLoading,
    refetch: refreshUsers,
  } = useQuery(GET_USERS, {
    variables: {
      orgId: store.currentOrg.org.id,
    },
    onError: (e) => {
      lsEnqueueSnackbar(e.message, {
        variant: "error",
      });
    },
  });

  let userData = [];

  let pageResults;
  if (auLoading || arLoading) {
    pageResults = <Waiting />;
  } else if (auData && arData) {
    userData = auData.getUsers.slice();

    pageResults = (
      <Grid container spacing={2} paddingLeft="20%" paddingRight="20%">
        <Grid item xs={12}>
          <Grid container alignItems="baseline">
            <Grid item xs={8}>
              <Typography
                component="h1"
                variant="h5"
                sx={{ marginBottom: 3, fontWeight: "bold" }}
              >
                {t("AssignRoles")}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <IconButton onClick={handleRefresh}>
                    <RefreshIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          {openEditUserRoles ? (
            <EditUserRolesForm
              openEditUserRoles={openEditUserRoles}
              setOpenEditUserRoles={setOpenEditUserRoles}
              activeUserData={activeUserData}
              setActiveUserData={setActiveUserData}
              arData={arData}
              arLoading={arLoading}
            />
          ) : (
            ""
          )}
        </Grid>

        <Grid item xs={12}>
          <List>
            {userData
              .sort((a, b) =>
                (a.lastname + a.firstname)
                  .toUpperCase()
                  .localeCompare((b.lastname + b.firstname).toUpperCase())
              )
              .map((d, idx) => {
                return (
                  <div key={idx}>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar
                          alt={d.firstname + " " + d.lastname}
                          src={d.avatar}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        disableTypography={true}
                        primary={
                          <Typography variant="subtitle1">
                            {d.firstname +
                              " " +
                              d.lastname +
                              " (" +
                              d.username +
                              ") "}
                          </Typography>
                        }
                        secondary={
                          <span sx={{ color: "darkGrey", paddingRight: 10 }}>
                            {orgRolesToString(
                              d.rights.find((o) => {
                                return o.org.id === store.currentOrg.org.id;
                              })?.roles,
                              t
                            )}
                          </span>
                        }
                      />
                      <ListItemSecondaryAction>
                        <IconButton
                          onClick={() => {
                            handleOpenEditUserRoles(d);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </div>
                );
              })}
          </List>
        </Grid>
      </Grid>
    );
  } else {
    pageResults = "";
  }

  return pageResults;
}

function orgRolesToString(roles, t) {
  let orgRoleString = roles.reduce((roleStr, role) => {
    return `${roleStr}${t(role.name.replaceAll(" ", ""))}, `;
  }, "");

  const i = orgRoleString.lastIndexOf(", ");
  orgRoleString =
    i >= 0 ? orgRoleString.substr(0, i) : orgRoleString + t("None");

  return orgRoleString;
}
