import { put as axiosput } from "axios";

export function handleFileDrop(items) {
  let res = [];
  for (let item of items) {
    if (item.kind === "file") {
      let entry = item.webkitGetAsEntry();
      if (entry.isDirectory) {
        res.push(traverseDirectory(entry));
      } else {
        res.push(entry);
      }
    }
  }
  return Promise.all(res);
}

function traverseDirectory(entry) {
  const reader = entry.createReader();
  // Resolved when the entire directory is traversed
  return new Promise((resolve, reject) => {
    const iterationAttempts = [];
    iterationAttempts.push(entry);

    function readEntries() {
      reader.readEntries(
        (entries) => {
          if (!entries.length) {
            // Done iterating this particular directory
            resolve(Promise.all(iterationAttempts));
          } else {
            // Add a list of promises for each directory entry.  If the entry is itself
            // a directory, then that promise won't resolve until it is fully traversed.
            iterationAttempts.push(
              Promise.all(
                entries.map((ientry) => {
                  if (ientry.isFile) {
                    return ientry;
                  }
                  return traverseDirectory(ientry);
                })
              )
            );
            // Try calling readEntries() again for the same dir, according to spec
            readEntries();
          }
        },
        (error) => reject(error)
      );
    }
    readEntries();
  });
}

export async function uploadAssets(
  vars,
  uploadContent,
  createFolder,
  getUploadURL
) {
  let newAsset;
  try {
    newAsset = await traverseUploadContent(
      vars,
      uploadContent,
      null,
      createFolder,
      getUploadURL
    );
  } catch (e) {
    throw e;
  }

  return newAsset;
}

async function traverseUploadContent(
  vars,
  uploadContent = [],
  parentFolder,
  createFolder,
  getUploadURL
) {
  let returnAsset;

  for (let item of uploadContent) {
    if (item instanceof Array) {
      // it's a folder. The item is an array with the first element
      // being the folder entry and subsequent elements are the folder's
      // contents
      let folder = item[0];
      let folderContents = item.slice(1)[0];

      // create the folder
      let createFolderVars = {
        variables: {
          orgId: vars.variables.orgId,
          fd: {
            name: vars.variables.asset.name,
            filename: folder.name,
            description: vars.variables.asset.description,
            parentFolder: parentFolder,
          },
        },
      };

      // if updating an existing folder, make sure to include the folder's id
      if (vars.variables.asset.id) {
        createFolderVars.variables.fd.id = vars.variables.asset.id;
      }

      let newFolder;
      try {
        newFolder = await createFolder(createFolderVars);
      } catch (e) {
        console.log(e);
        throw e;
      }

      // once the parent folder has been created, don't reuse the asset id.
      if (vars.variables.asset.id) {
        delete vars.variables.asset.id;
      }

      // handle the folder contents. Note: We don't need to return
      // the results of traversUploadContent because we only care
      // about adding the top level folder (or object) to the cache.
      // Also, we don't have to wrap the traverseUploadContent call
      // in try/catch, because exceptions will be automatically
      // propagated upward.
      traverseUploadContent(
        vars,
        folderContents,
        newFolder.data.createFolderAsset.id,
        createFolder,
        getUploadURL
      );

      if (!parentFolder) {
        returnAsset = newFolder.data.createFolderAsset;
      }
    } else {
      // it's a file. Get the file object.
      let fileObject;
      if (item instanceof File) {
        // it's already File (coming from manual upload not drop)
        fileObject = item;
      } else {
        try {
          fileObject = await getFileObject(item);
        } catch (e) {
          console.log(e); // TBD do something with errors
          throw e;
        }
      }

      // Use the file object data to get an upload URL
      let newAsset;
      vars.variables.asset.filename = item.name;
      vars.variables.asset.mimetype = getMimeType(fileObject);
      vars.variables.asset.size = fileObject.size;
      vars.variables.asset.parentFolder = parentFolder;
      let origName;
      let origDescription;
      if (parentFolder) {
        origName = vars.variables.asset.name;
        vars.variables.asset.name = `${vars.variables.asset.name} - ${item.name}`;

        origDescription = vars.variables.asset.description;
        vars.variables.asset.description = null;
      }

      try {
        newAsset = await getUploadURL(vars);
        if (newAsset.errors) {
          throw newAsset.errors[0];
        }
      } catch (e) {
        console.log(e);
        throw e; // TBD do something with errors
      } finally {
        if (parentFolder) {
          vars.variables.asset.name = origName;
          vars.variables.asset.description = origDescription;
        }
      }

      let uploadURL = newAsset.data.getTestAssetUploadURL.uploadURL;
      // upload the file using axios
      // extract the cache-control setting from the upload URL
      let cacheControl = uploadURL.substr(uploadURL.indexOf("max-age%3D"));
      cacheControl = cacheControl.substr(
        "max-age%3D".length,
        cacheControl.indexOf("&") - "max-age%3D".length
      );

      // upload the image using the upload URL
      const options = {
        headers: {
          "Content-Type": vars.variables.asset.mimetype,
          "x-amz-acl": "public-read",
          "cache-control": "max-age=" + cacheControl,
        },
      };

      try {
        await axiosput(uploadURL, fileObject, options);
      } catch (e) {
        console.log(e);
        throw e;
      }

      // We've successfully uploaded the asset. If it was a top level
      if (!parentFolder) {
        returnAsset = newAsset.data.getTestAssetUploadURL.asset;
      }
    }
  }
  return returnAsset;
}

async function getFileObject(fileEntry) {
  return new Promise((resolve, reject) => {
    fileEntry.file(
      (f) => {
        resolve(f);
      },
      (e) => {
        reject(e);
      }
    );
  });
}

const extensionToMimetype = {
  hbs: 'text/x-handlebars-template',
  handlebars: 'text/x-handlebars-template'
};

function getMimeType(fileObject) {
  if (fileObject.type && fileObject.type !== "") {
    return fileObject.type;
  } else {
    let i = fileObject.name.lastIndexOf('.');
    if (i === -1) {
      return "";    
    } else {
      let extension = fileObject.name.substring(i + 1);
      if (extension in extensionToMimetype) {
        return extensionToMimetype[extension];
      } else {
        return "";    
      }  
    }
  }
}
