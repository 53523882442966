import { useEffect, useState, useRef } from "react";
import {
  Box,
  Dialog
} from "@mui/material";
import DashboardTable from "./DashboardTable";
import { useNavigate } from "react-router-dom";
import { Waiting } from "../Waiting";
import { t } from "i18next";
import { useAppContext } from "../../context/AppContext";
import { TestInstanceStatus } from "../../graphql/enums";
import DashboardAssignedByMeDrawer from "./DashboardAssignedByMeDrawer";
import ExtendTestInstanceForm from "../../views/HiringManager/ExtendTestInstanceForm";
import CancelTestInstanceForm from "../../views/HiringManager/CancelTestInstanceForm";
import DashboardAssignedByMeToolbar from "./DashboardAssignedByMeToolbar";
import DownloadTestInstanceReportForm from "../../views/HiringManager/DownloadTestInstanceReportForm";
import ConfirmationForm from "../ConfirmationForm";
import { evaluateFilter } from "../../components/InstanceQuery/InstanceFilter";
import { handleCacheUpdate } from "../../cache";
import { GET_TEST_INSTANCES_ASSIGNED_BY_ME } from "../../graphql/queries";
import { REJECT_JUROR_ASSIGNMENT } from "../../graphql/mutations";
import { useMutation } from "@apollo/client";
import { useCustomSnackbar } from "../CustomSnackbar";

export default function DashboardAssignedByMe({
  activeTab,
  utData,
  utLoading,
  taData,
  taLoading,
  refreshAssignedTestInstances
}) {
  const { actions, store } = useAppContext();
  const navigate = useNavigate();
  const [assignmentsData, setAssignmentsData] = useState(false);
  const currentTestInstance = useRef(null);
  const currentOrg = useRef(null);
  const reportInstances = useRef(null);
  const currentTestTemplate = useRef(null);
  const { lsEnqueueSnackbar } = useCustomSnackbar();
  const [openConfirmCancelAssignment, setOpenConfirmCancelAssignment] = useState(false);

  const [rejectJurorAssignment] = useMutation(REJECT_JUROR_ASSIGNMENT, {
    update: (cacheClient, response) => {
      if (response?.data) {
        handleCacheUpdate(
          "update",
          cacheClient,
          response.data.rejectJurorAssignment,
          "id",
          GET_TEST_INSTANCES_ASSIGNED_BY_ME,
          "getTestInstancesAssignedByMe",
          null,
          {
            orgId: store.currentOrg?.org.id,
            options: {
              filtering: store.assignedByMeFilter?.length > 0 ? store.assignedByMeFilter : null
            }
          }
        );
      }
    }
  });

  const [openExtendTestInstanceDialog, setOpenExtendTestInstanceDialog] =
    useState(false);
  const [openDownloadTestInstanceReportDialog, setOpenDownloadTestInstanceReportDialog] =
    useState(false);

  const [openCancelTestInstanceDialog, setOpenCancelTestInstanceDialog] =
    useState(false);

  function handleOpenExtendTestInstanceDialog() {
    setOpenExtendTestInstanceDialog(true);
  }

  function handleCloseExtendTestInstanceDialog() {
    setOpenExtendTestInstanceDialog(false);
  }

  function handleOpenDownloadTestInstanceReportDialog() {
    setOpenDownloadTestInstanceReportDialog(true);
  }

  function handleCloseDownloadTestInstanceReportDialog() {
    setOpenDownloadTestInstanceReportDialog(false);
    reportInstances.current = null;
  }


  function handleOpenCancelTestInstanceDialog() {
    setOpenCancelTestInstanceDialog(true);
  }

  function handleCloseCancelTestInstanceDialog() {
    setOpenCancelTestInstanceDialog(false);
  }

  const handleBulkActionClick = (action, selectedRows) => {
    switch (action) {
      case "downloadReport":
        reportInstances.current = selectedRows.map((row) => row.instance.id);
        currentOrg.current = selectedRows[0]?.template?.org?.id;
        handleOpenDownloadTestInstanceReportDialog();
        break;
      default:
        break;
    }
  };

  const assignmentsHeaders = [
    { key: "assignee", value: t("Assignee"), type: "string", sortable: true },
    { key: "testName", value: t("TestName"), type: "string", sortable: true },
    { key: "assignmentDate", value: t("AssignmentDate"), type: "date", sortable: true },
    { key: "status", value: t("Status"), type: "string", sortable: true },
  ];

  const handleRefreshAssignedTestInstances = () => {
    refreshAssignedTestInstances();
  };

  const handleRowActionClick = (action, row) => {
    switch (action) {
      case "addtime":
        handleExtendTestInstance(row.instance, row.template);
        break;
      case "cancel":
        handleCancelTestInstance(row.instance, row.template);
        break;
      case "assignJuror":
        handleAssignJuror(row.instance, row.template);
        break;
      case "cancelJurorAssignment":
        handleCancelJurorAssignment(row.instance, row.template);
        break;
      case "viewResults":
        handleViewResults(row.instance, row.template);
        break;
      case "downloadReport":
        handleDownloadReport(row.instance, row.template);
        break;
      default:
        console.log(action, row);
    }
  };

  // Create the table rows for assignments
  useEffect(() => {
    const filteredRows = taData?.getTestInstancesAssignedByMe?.filter(
      (s) => evaluateFilter(store.assignedByMeFilter, s)
    );
    const assignmentsRows = filteredRows?.map((testInstance) => {
      const { id, assignee, invitee, status, assignmentDate } = testInstance;
      const testTemplate = utData?.getTestTemplates.find((template) => {
        return template.id === testInstance.testTemplate.id;
      });
      const scorecard = testInstance.scorecards?.find((sc) => {
        return ['assigned', 'active', 'created', 'active_pending'].indexOf(sc.status) >= 0;
      });
      const statusLabel = (status === 'unmarked' && scorecard)? t('JurorAssigned') : t(status);

      return {
        id,
        fields: [
          {
            key: "assignee",
            value: assignee
              ? `${assignee?.firstname} ${assignee?.lastname}`
              : `${invitee?.firstname} ${invitee?.lastname}`,
            type: "string"
          },
          { key: "testName", value: testTemplate?.name, type: "string" },
          { key: "assignmentDate", value: assignmentDate, type: "date" },
          { key: "status", value: statusLabel, type: "string" },
        ],
        actions: getAssignmentsTableActions(status, testInstance, testTemplate),
        instance: testInstance,
        template: testTemplate,
        statusPending: (scorecard && (scorecard.status === 'active_pending' || 
          scorecard.status === 'created'))
      };
    });

    setAssignmentsData(assignmentsRows);
  }, [taData, utData, store.assignedByMeFilter]);

  /**
   * Generate row level actions correspong to the assigned tests data provided.
   *
   * @param {TestInstanceStatus} status
   * @returns {Array} actions
   */

  const getAssignmentsTableActions = (status, testInstance, testTemplate) => {
    switch (status) {
      case TestInstanceStatus.Created:
        return [{ key: "cancel", value: t("Cancel"), color: "error" }];
      case TestInstanceStatus.Assigned:
        return [{ key: "cancel", value: t("Cancel"), color: "error" }];
      case TestInstanceStatus.Suspended:
        return [
          { key: "addtime", value: t("AddTime") },
          { key: "cancel", value: t("Cancel"), color: "error" },
        ];
      case TestInstanceStatus.Unmarked:
        let scorecard = testInstance?.scorecards?.find((sc) => {
          return ['assigned', 'active', 'created', 'active_pending'].indexOf(sc.status) >= 0;
        });
        let scorecardReady = (scorecard && ['assigned', 'active'].indexOf(scorecard.status) !== -1);
        if (scorecardReady) {
          return [{ key: "cancelJurorAssignment", value: t("RevokeJurorAssignment"), color: "error" }];
        } else if (!scorecard && testTemplate?.rubric) {
          return [{ key: "assignJuror", value: t("AssignJuror") }];
        } else {
          return [];
        }
      case TestInstanceStatus.UnmarkedComplete:
        return [{ key: "viewResults", value: t("ViewResults") }];
      case TestInstanceStatus.Marked:
        return [
          { key: "viewResults", value: t("ViewResults") },
          { key: "downloadReport", value: t("DownloadReport") }
        ];
      default:
        return [];
    }
  };

  /**
   * Dynamically create data for the bulk actions, corresponding
   * to the rows selected.
   *
   * @param {Array} selectedRows
   * @returns Array of DashboardTable friendly data for the bulk actions
   */
  const generateBulkActions = (selectedRows) => {

    // The only bulk action is "Download Report". ALl tests have to be in the marked state for this
    // action to show.
    let showDownloadReport = true;
    selectedRows.forEach((row) => {
      if (row.instance.status !== 'marked') {
        showDownloadReport = false;
      }
    });

    return [
      {
        key: "downloadReport",
        text: t("DownloadReport"),
        visible: showDownloadReport,
        disabled: false
      }
    ];
  };

  const handleAssignJuror = (instance, template) => {
    actions.setCurrentTestInstance(instance, template);
    navigate("/app/assignJuror");
  };

  const handleCancelJurorAssignment = (instance, template) => {
    actions.setCurrentTestInstance(instance, template);
    setOpenConfirmCancelAssignment(true);
  };

  const handleConfirmCancelAssignmentClose = (response) => {
    if (response === 'Confirm') {
      let testInstance = store.currentTestInstance;
      let scorecard = testInstance?.scorecards?.find((sc) => {
        return ['assigned', 'active'].indexOf(sc.status) >= 0;
      });

      if (scorecard) {
        rejectJurorAssignment({
          variables: {
            orgId: store.currentOrg?.org.id,
            instanceId: testInstance.id,
            scorecardId: scorecard.id
          }
        })
          .then((res) => {
            if (res.errors) {
              lsEnqueueSnackbar(res.errors[0].message, {
                variant: "error",
              });
            } else {
              lsEnqueueSnackbar(t("JurorAssignmentRevoked"), {
                variant: "success",
              });
            }
          })
          .catch((e) => {
            lsEnqueueSnackbar(t("ErrorRevokingJurorAssignment"), {
              variant: "error",
            });
          }); // catch network errors
      } else {
        lsEnqueueSnackbar(t("ErrorRevokingJurorAssignment"), {
          variant: "error",
        });
      }
    }
    setOpenConfirmCancelAssignment(false);
  };

  const handleViewResults = (instance, template) => {
    actions.setCurrentTestInstance(instance, template);
    navigate("/app/reviewtest");
  };

  const handleDownloadReport = (instance, template) => {
    currentTestInstance.current = instance.id;
    currentOrg.current = template.org.id;
    currentTestTemplate.current = template;
    handleOpenDownloadTestInstanceReportDialog();
  };

  const handleExtendTestInstance = (instance, template) => {
    currentTestInstance.current = instance.id;
    currentOrg.current = template.org.id;
    handleOpenExtendTestInstanceDialog();
  };

  const handleCancelTestInstance = (instance, template) => {
    currentTestInstance.current = instance.id;
    currentOrg.current = template.org.id;
    handleOpenCancelTestInstanceDialog();
  };


  let pageContents;
  if (taLoading || utLoading) {
    pageContents = <Waiting />;
  } else {
    pageContents = activeTab === 1 ? (
      <div
        role="tabpanel"
        id="tabpanel-1"
        aria-labelledby="tab-1"
      >
        <Box>
          <DashboardTable
            title={t("HiringManagerAssignments")}
            headers={assignmentsHeaders}
            data={assignmentsData}
            onRowActionClick={handleRowActionClick}
            isLoading={taLoading || utLoading}
            emptyText={t("NoAssignmentsMessage")}
            onRefresh={handleRefreshAssignedTestInstances}
            generateBulkActions={generateBulkActions}
            onBulkActionClick={handleBulkActionClick}
            defaultOrderBy="assignmentDate"
            defaultOrderType="date"
            defaultOrder="desc"
            isCollapsible={true}
            drawerContents={(row) =>
              <DashboardAssignedByMeDrawer
                testInstance={row.instance}
              />
            }
            toolbar={(isSelectingEnabled, setIsSelectingEnabled) =>
              <DashboardAssignedByMeToolbar
                isSelectingEnabled={isSelectingEnabled}
                setIsSelectingEnabled={setIsSelectingEnabled}
              />
            }
          />
        </Box>
        <ExtendTestInstanceForm
          open={openExtendTestInstanceDialog}
          handleClose={handleCloseExtendTestInstanceDialog}
          orgId={currentOrg.current}
          instanceId={currentTestInstance.current}
        />
        <CancelTestInstanceForm
          open={openCancelTestInstanceDialog}
          handleClose={handleCloseCancelTestInstanceDialog}
          orgId={currentOrg.current}
          instanceId={currentTestInstance.current}
        />
        <DownloadTestInstanceReportForm
          open={openDownloadTestInstanceReportDialog}
          handleClose={handleCloseDownloadTestInstanceReportDialog}
          orgId={currentOrg.current}
          instanceIds={reportInstances.current || [currentTestInstance.current]}
          testTemplate={currentTestTemplate.current}
        />
        <Dialog
          open={openConfirmCancelAssignment}
          onClose={handleConfirmCancelAssignmentClose}
        >
          <ConfirmationForm
            handleConfirmationClose={handleConfirmCancelAssignmentClose}
            dialogTitle={t("ConfirmRevokeJurorAssignmentTitle")}
            dialogContent={t("ConfirmRevokeJurorAssignmentContent")}
          />
        </Dialog>

      </div>
    ) : "";
  }

  return pageContents;
}
