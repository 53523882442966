import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Radio,
  Tabs,
  Tab,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DescriptionIcon from "@mui/icons-material/Description";
import FolderIcon from "@mui/icons-material/Folder";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../context/AppContext";
import useGuardedRoute from "../../hooks/useGuardedRoute";
import { useFormik } from "formik";
import { InstructionsType } from "../../graphql/enums";
import { formatBytesToString, formatSecondsToString } from "../../utils";
import { parseInstructionsString, downloadInstructions } from "../../instructions";
import { useState } from "react";
import {
  LIST_TEST_INSTANCE_RECORDING_FILES,
  GET_TEST_INSTANCES_FOR_ME_TO_MARK
} from "../../graphql/queries";
import { useMutation, useQuery } from "@apollo/client";
import { useCustomSnackbar } from "../../components/CustomSnackbar";
import { handleCacheUpdate } from "../../cache";
import {
  GET_TEST_INSTANCE_OUTPUT_DOWNLOAD_URL,
  GET_TEST_INSTANCE_RECORDING_DOWNLOAD_URL,
  GET_TEST_INSTANCE_INSTRUCTIONS_DOWNLOAD_URL,
  SET_TEST_INSTANCE_SCORES,
  SUBMIT_TEST_INSTANCE_SCORES,
} from "../../graphql/mutations";
import useSessionUrl from "../../hooks/useSessionUrl";
import WebsiteTest from "../../components/TestInstance/WebsiteTest";
import { Waiting } from "../../components/Waiting";
import ScopedPopup from "../../components/ScopedPopup";

const MarkTestForm = () => {
  const { t } = useTranslation();
  const { store } = useAppContext();
  const navigate = useNavigate();
  const { lsEnqueueSnackbar } = useCustomSnackbar();

  const [recordingURLLoaders, setRecordingURLLoaders] = useState({});
  const [outputURLLoaders, setOutputURLLoaders] = useState({});
  const [isUrlLoading, setIsUrlLoading] = useState(false);
  const [setOrSubmit, setSetOrSubmit] = useState("set");
  const [shouldSubmit, setShouldSubmit] = useState(false);

  const testInstructions = store.currentTestInstance?.testTemplate.instructions;
  const rubric = store.currentTestInstance?.testTemplate.rubric;
  const scorecard = store.currentTestInstance?.scorecards?.find((sc) => {
    return sc.juror.id === store.userDetails.user.id &&
      ['assigned', 'active', 'active_pending'].indexOf(sc.status) >= 0;
  });

  const {
    data: testInstanceSessionUrl,
    error: tisurlError,
    loading: tisurlLoading
  } = useSessionUrl({ readOnly: true });

  const [activeTab, setActiveTab] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
  };

  useGuardedRoute("/app", [store.currentTestInstance]);

  // Fetch test recordings
  const { data: rData, loading: rLoading } = useQuery(
    LIST_TEST_INSTANCE_RECORDING_FILES,
    {
      variables: {
        orgId: store.currentTestInstance?.testTemplate.org.id,
        instanceId: store.currentTestInstance?.id,
      },
      onError: (e) => {
        lsEnqueueSnackbar(e.message, {
          variant: "error",
        });
      },
    }
  );

  const [getTestInstanceOutputDownloadURL] = useMutation(
    GET_TEST_INSTANCE_OUTPUT_DOWNLOAD_URL
  );
  const [getTestInstanceRecordingDownloadURL] = useMutation(
    GET_TEST_INSTANCE_RECORDING_DOWNLOAD_URL
  );
  const [getTestInstanceInstructionsDownloadURL] = useMutation(
    GET_TEST_INSTANCE_INSTRUCTIONS_DOWNLOAD_URL
  );
  const [setTestInstanceScores] = useMutation(SET_TEST_INSTANCE_SCORES);
  const [submitTestInstanceScores] = useMutation(SUBMIT_TEST_INSTANCE_SCORES, {
    update: (cacheClient, response) => {
      if (response?.data) {
        handleCacheUpdate(
          "delete",
          cacheClient,
          response.data.submitTestInstanceScores,
          "id",
          GET_TEST_INSTANCES_FOR_ME_TO_MARK,
          "getTestInstancesForMeToMark",
          null,
          {
            options: {
              filtering: [
                {
                  field: 'status',
                  operation: 'eq',
                  values: {
                    stringValues: [
                      'unmarked'
                    ]
                  }
                }
              ]
            }
          }
        );
      }
    },
  });

  const handleInstructionsDownload = () => {
    downloadInstructions(
      setIsUrlLoading,
      getTestInstanceInstructionsDownloadURL,
      store.currentTestInstance?.testTemplate.org.id,
      store.currentTestInstance.id,
      lsEnqueueSnackbar);
  };

  const handleDownloadOutput = (output) => {
    setOutputURLLoaders((prevState) => ({ ...prevState, [output.id]: true }));
    getTestInstanceOutputDownloadURL({
      variables: {
        orgId: store.currentTestInstance?.testTemplate.org.id,
        instanceId: store.currentTestInstance.id,
        outputId: output.id,
      },
    })
      .then((res) => {
        if (res.errors) {
          lsEnqueueSnackbar(res.errors[0].message, {
            variant: "error",
          });
        } else {
          let url = res.data.getTestInstanceOutputDownloadURL;
          window.open(url, "_blank");
          // win.focus();
        }
        setOutputURLLoaders((prevState) => ({
          ...prevState,
          [output.id]: false,
        }));
      })
      .catch((e) => {
        lsEnqueueSnackbar(e.message, {
          variant: "error",
        });
        setOutputURLLoaders((prevState) => ({
          ...prevState,
          [output.id]: false,
        }));
      }); // catch network errors;
  };

  const handleDownloadRecording = (file) => {
    setRecordingURLLoaders((prevState) => ({
      ...prevState,
      [file.filename]: true,
    }));
    getTestInstanceRecordingDownloadURL({
      variables: {
        orgId: store.currentTestInstance?.testTemplate.org.id,
        instanceId: store.currentTestInstance.id,
        path: file.filename,
      },
    })
      .then((res) => {
        if (res.errors) {
          lsEnqueueSnackbar(res.errors[0].message, {
            variant: "error",
          });
        } else {
          let url = res.data.getTestInstanceRecordingDownloadURL;
          window.open(url, "_blank");
          // win.focus();
        }
        setRecordingURLLoaders((prevState) => ({
          ...prevState,
          [file.filename]: false,
        }));
      })
      .catch((e) => {
        lsEnqueueSnackbar(e.message, {
          variant: "error",
        });
        setRecordingURLLoaders((prevState) => ({
          ...prevState,
          [file.filename]: false,
        }));
      }); // catch network errors;
  };

  const timeRemaining = store.currentTestInstance?.workTimeRemaining;
  const maxWorkTime = store.currentTestInstance?.testTemplate.maxWorkTime;
  const workTimeElapsedString = formatSecondsToString(
    maxWorkTime - timeRemaining
  );
  const totalTimeElapsedString = formatSecondsToString(maxWorkTime);
  const percentageTimeUsed = (maxWorkTime - timeRemaining) / maxWorkTime * 100;

  let initialCategoryScores;
  if (scorecard?.categoryScores && scorecard?.categoryScores.length > 0) {
    initialCategoryScores = scorecard?.categoryScores.map((cs) => ({
      rubricCategory: cs.rubricCategory,
      comments: cs.comments,
      score: cs.score,
    })).sort((a, b) => {
      let rcA = rubric?.categories.find(c => c.id === a.rubricCategory);
      let rcB = rubric?.categories.find(c => c.id === b.rubricCategory);
      return rcA.sequence - rcB.sequence;
    });
  } else if (rubric?.categories) {
    initialCategoryScores = rubric?.categories.map((c) => ({
      rubricCategory: c.rubricCategory,
      comments: "",
      score: null,
    })).sort((a, b) => {
      return a.sequence - b.sequence;
    });
  } else initialCategoryScores = null;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      overallScore: scorecard?.overallScore || Number(scorecard?.overallScore) === 0 ? scorecard?.overallScore : null,
      overallComments: scorecard?.comments ? scorecard?.comments : "",
      categoryScores: initialCategoryScores,
    },
    onSubmit: async (values, { setSubmitting }) => {
      const vars = {
        variables: {
          orgId: store.currentOrg.org.id,
          instanceId: store.currentTestInstance.id,
          scores: {
            overallScore: Number(values.overallScore),
            comments: values.overallComments,
            categoryScores: categoryScores,
          },
        },
      };
      if (shouldSubmit === false) {
        setTestInstanceScores(vars)
          .then((res) => {
            if (res.errors) {
              lsEnqueueSnackbar(res.errors[0].message, {
                variant: "error",
              });
              setSubmitting(false);
            } else {
              lsEnqueueSnackbar(t("TestScoresSaved"), {
                variant: "success",
              });
              setSubmitting(false);
            }
          })
          .catch((e) => {
            lsEnqueueSnackbar(e.message, {
              variant: "error",
            });
            setSubmitting(false);
          });
        setSetOrSubmit("submit");
      } else {
        submitTestInstanceScores({
          variables: {
            orgId: store.currentOrg.org.id,
            instanceId: store.currentTestInstance.id,
          },
        })
          .then((res) => {
            if (res.errors) {
              lsEnqueueSnackbar(res.errors[0].message, {
                variant: "error",
              });
              setSubmitting(false);
            } else {
              lsEnqueueSnackbar(t("TestScoresSubmitted"), {
                variant: "success",
              });
              setSubmitting(false);
              navigate("/app");
            }
          })
          .catch((e) => {
            lsEnqueueSnackbar(e.message, {
              variant: "error",
            });
            setSubmitting(false);
          });
      }
    },
  });

  let categoryScores = [];
  if (formik.values.categoryScores) {
    for (let s of formik.values.categoryScores) {
      if (s.score !== "") {
        categoryScores.push({
          rubricCategory: s.rubricCategory,
          score: Number(s.score),
          comments: s.comments,
        });
      }
    }
  }

  return (
    <>
      <Grid container paddingLeft="5%" paddingRight="5%">
        <Grid item xs={12}>
          <Typography
            component="h1"
            variant="h5"
            sx={{ marginBottom: 3, fontWeight: "bold" }}
          >
            {t("MarkTestTitle")}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={activeTab}
                onChange={handleChangeTab}
                aria-label="tabs"
              >
                <Tab
                  label={t("TestInstructions")}
                  id="tab-0"
                  aria-controls="tabpanel-0"
                />
                <Tab
                  label={t("TestOutputs")}
                  id="tab-1"
                  aria-controls="tabpanel-1"
                />
                <Tab
                  label={t("ScorecardTitle")}
                  id="tab-2"
                  aria-controls="tabpanel-2"
                />
              </Tabs>
            </Box>
            <div
              role="tabpanel"
              id="tabpanel-0"
              aria-labelledby="tab-0"
              style={{
                overflowY: "auto",
                height: "65vh",
                display: activeTab === 0 ? 'block' : 'none'
              }}
            >
              <Box sx={{ p: 3 }}>
                {testInstructions && (
                  <>
                    <Grid item>
                      <Typography variant="p" sx={{ fontWeight: "bold" }}>
                        {t("TestInstructions")}
                      </Typography>
                    </Grid>
                    <Grid item sx={{ marginTop: 2 }}>
                      <Typography variant="p">
                        {t("TestInstructionsJurorComment")}
                      </Typography>
                    </Grid>
                    {testInstructions.type === InstructionsType.Text && (
                      <Grid item sx={{ marginTop: 2 }}>
                        {parseInstructionsString(testInstructions.text)}
                      </Grid>
                    )}
                    {testInstructions.type === InstructionsType.Asset &&
                      testInstructions.asset && (
                        <>
                          <Grid item sx={{ marginTop: 2 }}>
                            <Typography
                              component="p"
                              sx={{ marginBottom: 3 }}
                            >
                              {t("DownloadTestIntroductionFileExplanation")}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <ListItem
                              secondaryAction={
                                <IconButton
                                  edge="end"
                                  aria-label="download"
                                  onClick={handleInstructionsDownload}
                                  disabled={isUrlLoading}
                                >
                                  {isUrlLoading ? (
                                    <CircularProgress size={24} />
                                  ) : (
                                    <FileDownloadIcon />
                                  )}
                                </IconButton>
                              }
                            >
                              <ListItemAvatar>
                                <DescriptionIcon />
                              </ListItemAvatar>
                              <ListItemText
                                primary={testInstructions.asset.filename}
                                secondary={formatBytesToString(
                                  testInstructions.asset.size
                                )}
                              />
                            </ListItem>
                          </Grid>
                        </>
                      )}
                  </>
                )}
              </Box>
            </div>
            <div
              role="tabpanel"
              id="tabpanel-1"
              aria-labelledby="tab-1"
              style={{
                overflowY: "auto",
                height: "65vh",
                display: activeTab === 1 ? 'block' : 'none'
              }}

            >
              {store.currentTestInstanceTemplate?.testPlatform === 'appstream' ? (
                <Box sx={{ p: 3 }}>
                  <Grid item>
                    <Grid container columnSpacing={4}>
                      {/* Test & Candidate Info */}
                      <Grid item xs={4}>
                        <Typography component="p" sx={{ marginTop: 3 }}>
                          <strong>{t("TestName") + ":"}</strong>
                        </Typography>
                        <Typography component="p">
                          {store.currentTestInstance?.testTemplate.name}
                        </Typography>
                        <Typography component="p" sx={{ marginTop: 3 }}>
                          <strong>{t("Candidate") + ":"}</strong>
                        </Typography>
                        <Typography component="p">
                          {`${store.currentTestInstance?.assignee.firstname} ${store.currentTestInstance?.assignee.lastname}`}
                        </Typography>
                        <Typography component="p" sx={{ marginTop: 3 }}>
                          <strong>{t("WorkTimeElapsed") + ":"}</strong>
                        </Typography>
                        <Typography component="p">
                          {workTimeElapsedString}<br /><strong>{t("OutOf") + ":"}</strong><br />{totalTimeElapsedString} {t("AvailableForTest")} ({Math.round(percentageTimeUsed)}%)
                        </Typography>
                      </Grid>
                      {/* Outputs */}
                      <Grid item xs={4}>
                        <List
                          sx={{
                            width: "100%",
                          }}
                        >
                          <ListItem>
                            <ListItemText
                              primary={t("TestAnswers")}
                              sx={{ textDecoration: "underline" }}
                            />
                          </ListItem>
                          {store.currentTestInstance?.outputs?.map((output) => {
                            return (
                              <ListItem
                                key={output.id}
                                secondaryAction={
                                  <IconButton
                                    edge="end"
                                    aria-label="download"
                                    onClick={() => handleDownloadOutput(output)}
                                    disabled={!!outputURLLoaders[output.id]}
                                  >
                                    {!!outputURLLoaders[output.id] ? (
                                      <CircularProgress size={24} />
                                    ) : (
                                      output.path ? <FileDownloadIcon /> : ""
                                    )}
                                  </IconButton>
                                }
                              >
                                <ListItemAvatar>
                                  {output.testOutput.isFolder ? (
                                    <FolderIcon />
                                  ) : (
                                    <DescriptionIcon />
                                  )}
                                </ListItemAvatar>
                                <ListItemText
                                  primary={output.testOutput.name}
                                  secondary={output.path || <em>{t("OutputNotSubmitted")}</em>}
                                />
                              </ListItem>
                            );
                          })}
                        </List>
                      </Grid>
                      {/* Recordings */}
                      <Grid item xs={4}>
                        <List
                          sx={{
                            width: "100%",
                          }}
                        >
                          <ListItem>
                            <ListItemText
                              primary={t("TestRecordings")}
                              sx={{ textDecoration: "underline" }}
                            />
                          </ListItem>

                          {rLoading && (
                            <Grid item xs={12} align="center">
                              <CircularProgress color="secondary" />
                            </Grid>
                          )}

                          {rData?.listTestInstanceRecordingFiles.map((file) => {
                            return (
                              <ListItem
                                key={file.filename}
                                secondaryAction={
                                  <IconButton
                                    edge="end"
                                    aria-label="download"
                                    onClick={() =>
                                      handleDownloadRecording(file)
                                    }
                                    disabled={
                                      !!recordingURLLoaders[file.filename]
                                    }
                                  >
                                    {!!recordingURLLoaders[file.filename] ? (
                                      <CircularProgress size={24} />
                                    ) : (
                                      <FileDownloadIcon />
                                    )}
                                  </IconButton>
                                }
                              >
                                <ListItemAvatar>
                                  <PlayCircleFilledWhiteIcon />
                                </ListItemAvatar>
                                <ListItemText
                                  primary={file.filename}
                                  secondary={formatBytesToString(file.size)}
                                />
                              </ListItem>
                            );
                          })}
                        </List>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              ) :
                (
                  <Grid container alignContent="flex-start" justifyContent="flex-start">
                    {/* Test & Candidate Info */}
                    <Grid item xs={12} sx={{marginLeft: 2}}>
                      <Typography sx={{ marginTop: 3 }}>
                        <strong>{t("TestName") + ": "}</strong>{store.currentTestInstanceTemplate?.name}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <strong>{t("Candidate") + ": "}</strong>{store.currentTestInstance?.assignee.firstname} {store.currentTestInstance?.assignee.lastname}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{marginLeft: 2}}>
                      <Typography>
                        <strong>{t("WorkTimeElapsed") + ": "}</strong>{workTimeElapsedString} <strong>{t("OutOf")}:</strong> {totalTimeElapsedString} {t("AvailableForTest")} ({Math.round(percentageTimeUsed)}%)
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      {tisurlLoading ?
                        <Waiting /> :
                        (tisurlError ?
                          <ScopedPopup
                            open={true}
                            persistent={true}
                            title={t("TestSessionErrorTitle")}
                            description={t("TestSessionErrorText")}
                            backdrop="solid"
                          >
                            <Button variant="contained" onClick={() => navigate("/app")}>
                              {t("ReturnToDashboard")}
                            </Button>
                          </ScopedPopup> :
                          <WebsiteTest
                            sessionUrl={testInstanceSessionUrl}
                            toolbarHeight={0}
                            testInstance={store.currentTestInstance}
                          />)}
                    </Grid>
                  </Grid>
                )
              }
            </div>
            <div
              role="tabpanel"
              id="tabpanel-2"
              aria-labelledby="tab-2"
              style={{
                overflowY: "auto",
                height: "65vh",
                display: activeTab === 2 ? 'block' : 'none'
              }}
            >
              <form onSubmit={formik.handleSubmit}>
                <Box sx={{ p: 3 }}>
                  {rubric.hasOverallScore &&
                    <div>
                      <Grid item>
                        <Typography variant="p" sx={{ fontWeight: "bold" }}>
                          {t("OverallScore")}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <RadioGroup
                          aria-labelledby="overallScore-label"
                          value={formik.values.overallScore}
                          name="overallScore"
                          onChange={formik.handleChange}
                        >
                          {rubric?.overallScoreDetails?.map((sd) => {
                            return (
                              <FormControlLabel
                                key={sd.id}
                                value={sd.score}
                                control={<Radio />}
                                label={(
                                  <div style={{ whiteSpace: 'pre-line' }}>
                                    {sd.score + (sd.name !== '' ? " - " +
                                      sd.name : "") + ": " + sd.description}
                                  </div>
                                )}
                              />
                            );
                          })}
                        </RadioGroup>
                      </Grid>
                      <Grid item xs={12} sx={{ marginBottom: 4 }}>
                        <TextField
                          variant="outlined"
                          required
                          fullWidth
                          multiline
                          name="overallComments"
                          label={t("Comments")}
                          value={formik.values.overallComments}
                          onChange={formik.handleChange}
                        />
                      </Grid>
                    </div>
                  }
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      {rubric?.categories
                        ? rubric.categories.map((c, cindex) => {
                          return (
                            <div key={c.id}>
                              <Grid item xs={12}>
                                <Typography
                                  variant="p"
                                  sx={{ fontWeight: "bold" }}
                                >
                                  {c.name}
                                </Typography>
                                <Typography variant="p">
                                  {" (" +
                                    t("Weight") +
                                    ": " +
                                    c.weight +
                                    ")"}
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography variant="body1" style={{ whiteSpace: 'pre-line' }}>
                                  {c.description}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sx={{ display: "none" }}>
                                <TextField
                                  name={`categoryScores.${cindex}.rubricCategory`}
                                  value={
                                    formik.values.categoryScores[cindex]
                                      .rubricCategory
                                  }
                                  onChange={formik.handleChange}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <FormControl sx={{ marginBottom: 1 }}>
                                  <RadioGroup
                                    aria-labelledby={
                                      c.id + "-" + cindex + "-label"
                                    }
                                    value={
                                      formik.values.categoryScores[cindex]
                                        .score
                                    }
                                    name={`categoryScores.${cindex}.score`}
                                    onChange={formik.handleChange}
                                  >
                                    {c.scoreDetails.map((d) => {
                                      return (
                                        <FormControlLabel
                                          key={d.id}
                                          value={d.score}
                                          control={<Radio />}
                                          label={(
                                            <div style={{ whiteSpace: 'pre-line' }}>
                                              {d.score + (d.name !== '' ? " - " +
                                                d.name : "") +
                                                ": " + d.description}
                                            </div>
                                          )}
                                        />
                                      );
                                    })}
                                  </RadioGroup>
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} sx={{ marginBottom: 4 }}>
                                <TextField
                                  variant="outlined"
                                  required
                                  fullWidth
                                  multiline
                                  name={`categoryScores.${cindex}.comments`}
                                  label={t("Comments")}
                                  value={
                                    formik.values.categoryScores[cindex]
                                      .comments || ''
                                  }
                                  onChange={formik.handleChange}
                                />
                              </Grid>
                            </div>
                          );
                        })
                        : ""}
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container justifyContent="flex-end">
                      <Grid item>
                        <Button
                          type="button"
                          onClick={() => {
                            setShouldSubmit(false);
                            formik.submitForm();
                          }}
                          variant="contained"
                          color="primary"
                          sx={{ marginTop: 1 }}
                        >
                          {t("SaveScores")}
                        </Button>
                        &nbsp;&nbsp;&nbsp;
                        <Button
                          type="button"
                          onClick={() => {
                            setShouldSubmit(true);
                            formik.submitForm();
                          }}
                          variant="contained"
                          color="primary"
                          sx={{ marginTop: 1 }}
                          disabled={setOrSubmit === "set"}
                        >
                          {t("SubmitScores")}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </form>
            </div>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default MarkTestForm;
