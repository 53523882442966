import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useCustomSnackbar } from "../../components/CustomSnackbar";
import { usePasswordReqs } from "../../components/PasswordReqs";
import {
  Box,
  Button,
  InputLabel,
  Grid,
  Typography,
  FormControl,
  MenuItem,
  TextField,
  Select,
  Step,
  Stepper,
  StepLabel,
  StepContent,
} from "@mui/material";
import { Waiting } from "../../components/Waiting";
import { useFormik } from "formik";
import * as Yup from "yup";
import { t } from "i18next";
import { useMutation } from "@apollo/client";
import { SIGN_UP_TAKE_TEST } from "../../graphql/mutations";

export default function SignUpTestTakerForm(props) {
  const inputLabel = useRef(null);
  const navigate = useNavigate();
  const { lsEnqueueSnackbar } = useCustomSnackbar();
  const siteConfig = props.siteConfig;

  const steps = [
    {
      label: t("ContactInformation"),
      description: t("ContactInformationDescription"),
    },
    {
      label: t("Password"),
      description: t("PasswordDescription"),
    },
    {
      label: t("Language"),
      description: t("LanguageDescription"),
    },
    {
      label: t("TermsAndConditionsTitle"),
      description: t("TermsAndConditionsDescription"),
    },
  ];

  const [signUpTakeTest, { loading: signUpLoading }] =
    useMutation(SIGN_UP_TAKE_TEST);

  const validationSchema = Yup.object({
    username: Yup.string()
      .max(40, t("FortyOrFewerChars"))
      .required(t("Required")),
    firstname: Yup.string()
      .max(15, t("FifteenOrFewerChars"))
      .required(t("Required")),
    lastname: Yup.string()
      .max(20, t("TwentyOrFewerChars"))
      .required(t("Required")),
    password: usePasswordReqs(props.siteConfig),
    passwordconfirm: Yup.string()
      .oneOf([Yup.ref("password"), null], t("PasswordsMustMatch"))
      .required(t("Required")),
    language: Yup.string().required(t("Required")),
    // timezone: Yup.string().required(t("Required")),
    // region: Yup.string().required(t("Required")),
  });

  const formik = useFormik({
    initialValues: {
      firstname: siteConfig.urlQueryParms.firstname || "",
      lastname: siteConfig.urlQueryParms.lastname || "",
      username: "",
      password: "",
      passwordconfirm: "",
      email: siteConfig.urlQueryParms.email || "",
      language: "",
      timezone: "EST",
      region: siteConfig.urlQueryParms.region || "Canada",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const vars = {
        variables: {
          sud: {
            username: values.username.trim(),
            email: values.email.trim(),
            password: values.password,
            firstname: values.firstname.trim(),
            lastname: values.lastname.trim(),
            language: values.language,
            timezone: values.timezone,
            region: siteConfig.urlQueryParms.region,
          },
          insId: siteConfig.urlQueryParms.testinstanceid || "",
        },
      };

      // Invoke the sign up routine
      signUpTakeTest(vars)
        .then((res) => {
          if (res.data) {
            lsEnqueueSnackbar(t("SignUpSuccessful"), {
              variant: "success",
            });
            navigate("/app/signin");
          } else if (res.errors) {
            lsEnqueueSnackbar(res.errors[0].message, {
              variant: "error",
            });
            setSubmitting(false);
          }
        })
        .catch((e) => {
          lsEnqueueSnackbar(e.message, {
            variant: "error",
          });
          setSubmitting(false);
        }); // catch network errors
    },
  });

  const [activeStep, setActiveStep] = useState(0);

  const handleNext1 = () => {
    if (
      !formik.errors.firstname &&
      !formik.errors.lastname &&
      !formik.errors.username &&
      !formik.errors.email &&
      !formik.errors.emailconfirm
    ) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleNext2 = () => {
    if (!formik.errors.password && !formik.errors.passwordconfirm) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleNext3 = () => {
    if (
      !formik.errors.language &&
      !formik.errors.timezone &&
      !formik.errors.region
    ) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <>
      {signUpLoading ? <Waiting /> : ""}
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2} paddingLeft="10%" paddingRight="10%">
          <Grid
            item
            lg={3}
            sx={{ borderRight: { xs: 0, sm: 1 }, borderColor: "primary.main" }}
          >
            <Grid container spacing={2} paddingLeft="10%" paddingRight="10%">
              <Grid item xs={12}>
                <Stepper
                  activeStep={activeStep}
                  orientation="vertical"
                  sx={{ marginBottom: 3 }}
                >
                  {steps.map((step, index) => (
                    <Step key={step.label}>
                      <StepLabel>{step.label}</StepLabel>
                      <StepContent>
                        <Typography>{step.description}</Typography>
                      </StepContent>
                    </Step>
                  ))}
                </Stepper>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={9}>
            <Grid
              container
              spacing={2}
              paddingLeft="10%"
              paddingRight="10%"
              sx={{ display: activeStep === 0 ? "flex" : "none" }}
            >
              <Grid item xs={12}>
                <Typography
                  component="h1"
                  variant="h5"
                  sx={{ marginBottom: 3, fontWeight: "bold" }}
                >
                  {t("ContactInformation")}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="fname"
                  name="firstname"
                  variant="outlined"
                  fullWidth
                  id="firstname"
                  label={t("FirstName")}
                  value={formik.values.firstname}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.firstname && Boolean(formik.errors.firstname)
                  }
                  helperText={
                    formik.touched.firstname && formik.errors.firstname
                  }
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="lastname"
                  label={t("LastName")}
                  name="lastname"
                  autoComplete="lname"
                  value={formik.values.lastname}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.lastname && Boolean(formik.errors.lastname)
                  }
                  helperText={formik.touched.lastname && formik.errors.lastname}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="username"
                  label={t("UserName")}
                  name="username"
                  autoComplete="username"
                  value={formik.values.username}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.username && Boolean(formik.errors.username)
                  }
                  helperText={formik.touched.username && formik.errors.username}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  disabled
                  fullWidth
                  id="email"
                  label={t("EmailAddress")}
                  name="email"
                  value={formik.values.email}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={t("CanChangeEmailLater")}
                />
              </Grid>

              <Grid container justifyContent="flex-end">
                <Grid item sx={{ textAlign: "right" }}>
                  <Box sx={{ marginBottom: 2 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext1}
                      sx={{ marginTop: 3, marginRight: 1 }}
                    >
                      {t("Continue")}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              paddingLeft="10%"
              paddingRight="10%"
              sx={{ display: activeStep === 1 ? "flex" : "none" }}
            >
              <Grid item xs={12}>
                <Typography
                  component="h1"
                  variant="h5"
                  sx={{ marginBottom: 3, fontWeight: "bold" }}
                >
                  {t("Password")}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  fullWidth
                  name="password"
                  label={t("Password")}
                  type="password"
                  id="password"
                  value={formik.values.password}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  fullWidth
                  name="passwordconfirm"
                  label={t("ConfirmPassword")}
                  type="password"
                  id="passwordconfirm"
                  value={formik.values.passwordconfirm}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.passwordconfirm &&
                    Boolean(formik.errors.passwordconfirm)
                  }
                  helperText={
                    formik.touched.passwordconfirm &&
                    formik.errors.passwordconfirm
                  }
                />
              </Grid>
              <Grid container justifyContent="flex-end">
                <Grid item sx={{ textAlign: "right" }}>
                  <Box sx={{ marginBottom: 2 }}>
                    <Button
                      onClick={handleBack}
                      sx={{ marginTop: 3, marginRight: 1 }}
                    >
                      {t("Back")}
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext2}
                      sx={{ marginTop: 3, marginRight: 1 }}
                    >
                      {t("Continue")}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              paddingLeft="10%"
              paddingRight="10%"
              sx={{ display: activeStep === 2 ? "flex" : "none" }}
            >
              <Grid item xs={12}>
                <Typography
                  component="h1"
                  variant="h5"
                  sx={{ marginBottom: 3, fontWeight: "bold" }}
                >
                  {t("Language")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  variant="outlined"
                  required
                  sx={{
                    margin: 0,
                    width: "100%",
                    display: "flex",
                    flexWrap: "nowrap",
                  }}
                >
                  <InputLabel ref={inputLabel} id="language-label">
                    {t("PreferredLanguage")}
                  </InputLabel>
                  <Select
                    label={t("PreferredLanguage")}
                    labelId="language-label"
                    id="language"
                    name="language"
                    value={formik.values.language}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.language && Boolean(formik.errors.language)
                    }
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={"English"}>{t("English")}</MenuItem>
                    <MenuItem value={"French"}>{t("French")}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {/* <Grid item xs={12}>
                <FormControl
                  variant="outlined"
                  required
                  sx={{
                    margin: 0,
                    width: "100%",
                    display: "flex",
                    flexWrap: "nowrap",
                  }}
                >
                  <InputLabel ref={inputLabel} id="timezone-label">
                    {t("PreferredTimeZone")}
                  </InputLabel>
                  <Select
                    label={t("PreferredTimeZone")}
                    labelId="timezone-label"
                    id="timezone"
                    name="timezone"
                    value={formik.values.timezone}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.timezone && Boolean(formik.errors.timezone)
                    }
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={"AST"}>AST</MenuItem>
                    <MenuItem value={"EST"}>EST</MenuItem>
                    <MenuItem value={"CST"}>CST</MenuItem>
                    <MenuItem value={"MST"}>MST</MenuItem>
                    <MenuItem value={"PST"}>PST</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl
                  variant="outlined"
                  required
                  disabled
                  sx={{
                    margin: 0,
                    width: "100%",
                    display: "flex",
                    flexWrap: "nowrap",
                  }}
                >
                  <InputLabel ref={inputLabel} id="region-label">
                    {t("RegionWhereAccountWillBeHosted")}
                  </InputLabel>
                  <Select
                    label={t("RegionWhereAccountWillBeHosted")}
                    labelId="region-label"
                    id="region"
                    name="region"
                    value={formik.values.region}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.region && Boolean(formik.errors.region)
                    }
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={"Canada"}>{t("Canada")}</MenuItem>
                    <MenuItem value={"United_States"}>
                      {t("UnitedStates")}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid> */}

              <Grid container justifyContent="flex-end">
                <Grid item sx={{ textAlign: "right" }}>
                  <Box sx={{ marginBottom: 2 }}>
                    <Button
                      onClick={handleBack}
                      sx={{ marginTop: 3, marginRight: 1 }}
                    >
                      {t("Back")}
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext3}
                      sx={{ marginTop: 3, marginRight: 1 }}
                    >
                      {t("Continue")}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              paddingLeft="10%"
              paddingRight="10%"
              sx={{ display: activeStep === 3 ? "flex" : "none" }}
            >
              <Grid item xs={12}>
                <Typography
                  component="h1"
                  variant="h5"
                  sx={{ marginBottom: 3, fontWeight: "bold" }}
                >
                  {t("TermsAndConditionsTitle")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography component="p">
                  {t("ApplicationTermsAndConditions")}
                </Typography>
              </Grid>
              <Grid container justifyContent="flex-end">
                <Grid item sx={{ textAlign: "right" }}>
                  <Button
                    onClick={handleBack}
                    sx={{ marginTop: 3, marginRight: 1 }}
                  >
                    {t("Back")}
                  </Button>
                  {activeStep === 3 ? (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleReset}
                      sx={{ marginTop: 3, marginRight: 1 }}
                    >
                      {t("Reset")}
                    </Button>
                  ) : (
                    ""
                  )}
                  &nbsp;&nbsp;&nbsp;
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{ marginTop: 3, marginRight: 1 }}
                  >
                    {t("Submit")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  );
}
