export const calculateTestInstanceRemainingTime = (testInstance) => {
    let remainingTime = testInstance.workTimeRemaining;
    if (testInstance.testSession && testInstance.testSession.status === 'connected') {
      const connectionDate = new Date(testInstance.testSession.connectionDate);
      const currentDate = new Date();
      const timeElapsed = Math.floor((currentDate - connectionDate) / 1000);
      remainingTime -= timeElapsed;
    }
    return Math.max(remainingTime, 0);
}
