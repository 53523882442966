import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
} from "@mui/material";
import { Waiting } from "../../components/Waiting";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { VERIFY_ORG_SEND_EMAIL } from "../../graphql/mutations";

export default function SendEmailVerificationForm({ 
  siteConfig, 
  setCurrentLanguage
}) {
  const { t, i18n } = useTranslation();
  const [resultSent, setResultSent] = useState(false);

  const [verifyOrgSendEmail, { loading, error }] = useMutation(
    VERIFY_ORG_SEND_EMAIL
  );

  // Send the results once as the form is loading
  useEffect(() => {
    setCurrentLanguage(siteConfig.urlQueryParms.language);
    i18n.changeLanguage(siteConfig.urlQueryParms.language);

    if (!resultSent) {
      setResultSent(true);
      verifyOrgSendEmail({
        variables: {
          orgId: siteConfig.urlQueryParms.orgid,
          uid: siteConfig.urlQueryParms.uid,
          verificationCode: siteConfig.urlQueryParms.code,
        },
      });
    }
  }, [
    resultSent, 
    siteConfig.urlQueryParms.orgid, 
    siteConfig.urlQueryParms.language, 
    siteConfig.urlQueryParms.uid,
    siteConfig.urlQueryParms.code,
    verifyOrgSendEmail,
    i18n, 
    setCurrentLanguage
  ]);

  let pageResults;
  if (loading) {
    pageResults = <Waiting />;
  } else if (error) {
    pageResults = (
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          {t("OrgSendEmailVerifiedFailureTitle")}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {t("OrgSendEmailVerifiedFailureBody", {email: siteConfig.urlQueryParms.email})}<br/><br/>{t("CanCloseBrowserWindow")}
        </Typography>
      </Grid>
    );
  } else { 
    pageResults = (
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          {t("OrgSendEmailVerifiedSuccessTitle")}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {t("OrgSendEmailVerifiedSuccessBody", {email: siteConfig.urlQueryParms.email})}<br/><br/>{t("CanCloseBrowserWindow")}
        </Typography>
      </Grid>
    );
  }
  return pageResults;
}