import { gql } from "@apollo/client";
import {
  CORE_TEST_ASSET_FIELDS,
  CORE_TEST_INSTANCE_FIELDS,
  CORE_ASSIGNED_BY_TEST_INSTANCE_FIELDS,
  CORE_TEST_TEMPLATE_FIELDS,
  MODIFY_STATUS_TEST_TEMPLATE_FIELDS,
  CORE_ORG_FIELDS
} from "../fragments";

/*
 * USER
 */
export const UPDATE_USER_PROFILE = gql`
  mutation UpdateUserProfile($up: UserDefinition!) {
    updateUserProfile(userProfile: $up) {
      user {
        defaultorg
        email
        username
        firstname
        lastname
        title
        suffix
        gender
        pronoun
        website
        address
        city
        state
        country
        postalcode
        phone
        language
        timezone
        birthdate
        privacy
        avatar
      }
    }
  }
`;

// sign out GQL
export const SIGN_OUT = gql`
  mutation SignOut($at: String!) {
    signOut(accessToken: $at)
  }
`;

// Sign up as an invitee
export const INVITEE_SIGN_UP = gql`
  mutation InviteeSignUp($sud: InviteeSignUpData!) {
    inviteeSignUp(inviteeSignUpData: $sud)
  }
`;

// Sign up as a test taker
export const SIGN_UP_TAKE_TEST = gql`
  mutation SignUpTakeTest($sud: SignUpData!, $insId: GUID!) {
    signUpTakeTest(signUpData: $sud, instanceId: $insId)
  }
`;

// Join an Org when user already has an account
export const ACCEPT_JOIN_ORG_INVITATION = gql`
  mutation AcceptJoinOrgInvitation(
    $email: EmailAddress!
    $orgId: GUID!
    $invitorId: GUID!
    $code: String!
  ) {
    acceptJoinOrgInvitation(
      email: $email
      orgId: $orgId
      invitorId: $invitorId
      confirmationCode: $code
    )
  }
`;

// Update a user's roles
export const UPDATE_USER_ROLES = gql`
  mutation UpdateUserRoles($orgId: GUID!, $ui: GUID!, $roles: [GUID!]) {
    updateUserRoles(orgId: $orgId, userId: $ui, roles: $roles) {
      id
      defaultorg
      email
      username
      firstname
      lastname
      title
      suffix
      gender
      pronoun
      website
      address
      city
      state
      country
      postalcode
      phone
      language
      timezone
      birthdate
      privacy
      avatar
      rights {
        org {
          id
          name
          logo
        }
        roles {
          id
          name
        }
        rights
      }
    }
  }
`;


/*
 * END USER
 */


/*
 * ORGANIZATION
 */

// Create an organization
export const CREATE_ORG = gql`
  ${CORE_ORG_FIELDS}
  mutation CreateOrg($name: String!) {
    createOrg(name: $name) {
      ... on Org {
        ...CoreOrgFields
      }
      ... on ExampleryWarning {
        message
        code
      }
    }
  }
`;

export const UPDATE_ORG_PROFILE = gql`
  ${CORE_ORG_FIELDS}
  mutation UpdateOrgProfile($orgId: GUID!, $op: OrgProfile!) {
    updateOrgProfile(orgId: $orgId, orgProfile: $op) {
      ...CoreOrgFields
    }
  }
`;

export const UPDATE_ORG_SEND_EMAIL = gql`
  ${CORE_ORG_FIELDS}
  mutation UpdateOrgSendEmail($orgId: GUID!, $sendEmail: EmailAddress) {
    updateOrgSendEmail(orgId: $orgId, sendEmail: $sendEmail) {
      ...CoreOrgFields
    }
  }
`;

export const SEND_UPDATE_ORG_SEND_EMAIL_RESULT = gql`
  mutation SendUpdateOrgSendEmailResult($orgId: GUID!, $result: OrgSendEmailMessage!) {
    sendUpdateOrgSendEmailResult(orgId: $orgId, result: $result)
  }
`;

export const VERIFY_ORG_SEND_EMAIL = gql`
  mutation VerifyOrgSendEmail($orgId: GUID!, $uid: GUID!, $verificationCode: String!) {
    verifyOrgSendEmail(orgId: $orgId, uid: $uid, verificationCode: $verificationCode)
  }
`;

export const UPDATE_ORG_SUBDOMAIN = gql`
  mutation UpdateOrgSubdomain($orgId: GUID!, $subdomain: String) {
    updateOrgSubdomain(orgId: $orgId, subdomain: $subdomain)
  }
`;

export const UPDATE_ORG_EMAIL_TEMPLATE = gql`
  mutation UpdateOrgEmailTemplate($orgId: GUID!, $key: String!, $language: Language!, $message: EmailTemplateDefinition) {
    updateOrgEmailTemplate(orgId: $orgId, key: $key, language: $language, message: $message) {
      orgId
      emailTemplate {
        key
        language
        name
        subject
        body
      }
      subject
      body
    }
  }
`;

/*
 * END ORGANIZATION
 */

/*
 * TEST TEMPLATE
 */

// Create a new test template
export const CREATE_TEST_TEMPLATE = gql`
  ${CORE_TEST_TEMPLATE_FIELDS}
  mutation CreateTestTemplate(
    $orgId: GUID!
    $testTemplate: TestTemplateDefinition!
  ) {
    createTestTemplate(orgId: $orgId, testTemplate: $testTemplate) {
      ...CoreTestTemplateFields
    }
  }
`;

// Update a test template
export const UPDATE_TEST_TEMPLATE = gql`
  ${CORE_TEST_TEMPLATE_FIELDS}
  mutation UpdateTestTemplate(
    $orgId: GUID!
    $testTemplate: TestTemplateUpdate!
  ) {
    updateTestTemplate(orgId: $orgId, testTemplate: $testTemplate) {
      ...CoreTestTemplateFields
    }
  }
`;

// Delete a test template
export const DELETE_TEST_TEMPLATES = gql`
  ${MODIFY_STATUS_TEST_TEMPLATE_FIELDS}
  mutation DeleteTestTemplates($orgId: GUID!, $templateIds: [GUID!]!) {
    deleteTestTemplates(orgId: $orgId, templateIds: $templateIds) {
      ... on TestTemplate {
        __typename
        ...ModifyStatusTestTemplateFields
      }
      ... on ExampleryError {
        __typename
        message
      }
    }
  }
`;

// Recall a test template
export const RECALL_TEST_TEMPLATES = gql`
  ${MODIFY_STATUS_TEST_TEMPLATE_FIELDS}
  mutation RecallTestTemplates($orgId: GUID!, $templateIds: [GUID!]!) {
    recallTestTemplates(orgId: $orgId, templateIds: $templateIds) {
      ... on TestTemplate {
        __typename
        ...ModifyStatusTestTemplateFields
      }
      ... on ExampleryError {
        __typename
        message
      }
    }
  }
`;

// Retire a test template
export const RETIRE_TEST_TEMPLATES = gql`
  ${MODIFY_STATUS_TEST_TEMPLATE_FIELDS}
  mutation RetireTestTemplates($orgId: GUID!, $templateIds: [GUID!]!) {
    retireTestTemplates(orgId: $orgId, templateIds: $templateIds) {
      ... on TestTemplate {
        __typename
        ...ModifyStatusTestTemplateFields
      }
      ... on ExampleryError {
        __typename
        message
      }
    }
  }
`;

// Copy a test template
export const COPY_TEST_TEMPLATE = gql`
  ${CORE_TEST_TEMPLATE_FIELDS}
  mutation CopyTestTemplate($orgId: GUID!, $testTemplate: TestTemplateUpdate!) {
    copyTestTemplate(orgId: $orgId, testTemplate: $testTemplate) {
      ...CoreTestTemplateFields
    }
  }
`;

// Activate Test Template
export const ACTIVATE_TEST_TEMPLATES = gql`
  ${MODIFY_STATUS_TEST_TEMPLATE_FIELDS}
  mutation ActivateTestTemplates($orgId: GUID!, $templateIds: [GUID!]!) {
    activateTestTemplates(orgId: $orgId, templateIds: $templateIds) {
      ... on TestTemplate {
        __typename
        ...ModifyStatusTestTemplateFields
      }
      ... on ExampleryError {
        __typename
        message
      }
    }
  }
`;

/*
 * END TEST TEMPLATE
 */

/*
 * TEST ASSETS
 */

// Delete an asset
export const DELETE_TEST_ASSET = gql`
  ${CORE_TEST_ASSET_FIELDS}
  mutation DeleteTestAsset($orgId: GUID!, $assetId: GUID!) {
    deleteTestAsset(orgId: $orgId, assetId: $assetId) {
      ...CoreTestAssetFields
    }
  }
`;

// Get an asset upload URL
export const GET_TEST_ASSET_UPLOAD_URL = gql`
  ${CORE_TEST_ASSET_FIELDS}
  mutation GetTestAssetUploadURL($orgId: GUID!, $asset: AssetDefinition!) {
    getTestAssetUploadURL(orgId: $orgId, asset: $asset) {
      uploadURL
      asset {
        ...CoreTestAssetFields
      }
    }
  }
`;

// Create a folder asset
export const CREATE_FOLDER_ASSET = gql`
  ${CORE_TEST_ASSET_FIELDS}
  mutation CreateFolderAsset($orgId: GUID!, $fd: FolderDefinition!) {
    createFolderAsset(orgId: $orgId, folder: $fd) {
      ...CoreTestAssetFields
    }
  }
`;

// Update an asset
export const UPDATE_TEST_ASSET = gql`
  ${CORE_TEST_ASSET_FIELDS}
  mutation UpdateTestAsset($orgId: GUID!, $asset: AssetUpdate!) {
    updateTestAsset(orgId: $orgId, asset: $asset) {
      ...CoreTestAssetFields
    }
  }
`;
/*
 * END TEST ASSETS
 */

/*
 * TEST INSTANCE
 */

// Get test session url
export const RECORD_TEST_INSTANCE_SESSION_CONNECTION_STATUS = gql`
  mutation RecordTestInstanceSessionConnectionStatus($orgId: GUID!, $instanceId: GUID!, $status: TestInstanceSessionStatus!) {
    recordTestInstanceSessionConnectionStatus(orgId: $orgId, instanceId: $instanceId, status: $status) {
      testInstanceId
      status
      connectionDate
    }
  }
`;

// Record Test Session Connection Status
export const GET_TEST_INSTANCE_SESSION_URL = gql`
  mutation GetTestInstanceSessionUrl($orgId: GUID!, $instanceId: GUID!, $location: LocationInput, $readOnly: Boolean) {
    getTestInstanceSessionUrl(orgId: $orgId, instanceId: $instanceId, location: $location, readOnly: $readOnly)
  }
`;

// Get test session recording url
export const GET_TEST_INSTANCE_RECORDING_DOWNLOAD_URL = gql`
  mutation GetTestInstanceRecordingDownloadURL(
    $orgId: GUID!
    $instanceId: GUID!
    $path: String!
  ) {
    getTestInstanceRecordingDownloadURL(
      orgId: $orgId
      instanceId: $instanceId
      path: $path
    )
  }
`;

// Get test outputs url
export const GET_TEST_INSTANCE_OUTPUT_DOWNLOAD_URL = gql`
  mutation GetTestInstanceOutputDownloadURL(
    $orgId: GUID!
    $instanceId: GUID!
    $outputId: GUID!
  ) {
    getTestInstanceOutputDownloadURL(
      orgId: $orgId
      instanceId: $instanceId
      outputId: $outputId
    )
  }
`;

// Assign a test instance to a candidate
export const ASSIGN_TEST_INSTANCE = gql`
  ${CORE_TEST_INSTANCE_FIELDS}
  mutation AssignTestInstance(
    $orgId: GUID!
    $templateId: GUID!
    $instanceOptions: TestInstanceDefinition!
    $assignee: Invitee!
    $tagIds:[GUID!]
  ) {
    assignTestInstance(
      orgId: $orgId
      templateId: $templateId
      instanceOptions: $instanceOptions
      assignee: $assignee
      tagIds: $tagIds
    ) {
      ...CoreTestInstanceFields
    }
  }
`;

// Extend time of a test instance assigned to a candidate
export const EXTEND_TEST_INSTANCE = gql`
  ${CORE_TEST_INSTANCE_FIELDS}
  mutation ExtendTestInstance(
    $orgId: GUID!
    $instanceId: GUID!
    $completionDate: DateTime!
  ) {
    extendTestInstance(
      orgId: $orgId
      instanceId: $instanceId
      completionDate: $completionDate
    ) {
      ...CoreTestInstanceFields
    }
  }
`;

// Accept a test instance assigned to me
export const ACCEPT_TEST_INSTANCE = gql`
  ${CORE_TEST_INSTANCE_FIELDS}
  mutation AcceptTestInstance($orgId: GUID!, $instanceId: GUID!) {
    acceptTestInstance(orgId: $orgId, instanceId: $instanceId) {
      ...CoreTestInstanceFields
    }
  }
`;

// Reject a test instance assigned to me
export const REJECT_TEST_INSTANCE = gql`
  ${CORE_TEST_INSTANCE_FIELDS}
  mutation RejectTestInstance($orgId: GUID!, $instanceId: GUID!) {
    rejectTestInstance(orgId: $orgId, instanceId: $instanceId) {
      ...CoreTestInstanceFields
    }
  }
`;

// Retrive the URL of the instructions file of a test instance
export const GET_TEST_INSTANCE_INSTRUCTIONS_DOWNLOAD_URL = gql`
  mutation GetTestInstanceInstructionsDownloadURL(
    $orgId: GUID!
    $instanceId: GUID!
  ) {
    getTestInstanceInstructionsDownloadURL(
      orgId: $orgId
      instanceId: $instanceId
    )
  }
`;

// Retrive the URL of a file associated with a test instance
export const GET_TEST_INSTANCE_FILE_DOWNLOAD_URL = gql`
  mutation GetTestInstanceFileDownloadURL(
    $orgId: GUID!
    $instanceId: GUID!
    $path: String!
    $isFolder: Boolean!
  ) {
    getTestInstanceFileDownloadURL(
      orgId: $orgId
      instanceId: $instanceId
      path: $path
      isFolder: $isFolder
    )
  }
`;

// Select files corresponding to expected outputs in a test instance
export const SET_TEST_INSTANCE_ANSWER = gql`
  ${CORE_TEST_INSTANCE_FIELDS}
  mutation SetTestInstanceAnswer(
    $orgId: GUID!
    $instanceId: GUID!
    $outputId: GUID!
    $path: String
  ) {
    setTestInstanceAnswer(
      orgId: $orgId
      instanceId: $instanceId
      outputId: $outputId
      path: $path
    ) {
      ...CoreTestInstanceFields
    }
  }
`;

// Submit a test instance
export const SUBMIT_TEST_INSTANCE = gql`
  ${CORE_TEST_INSTANCE_FIELDS}
  mutation SubmitTestInstance($orgId: GUID!, $instanceId: GUID!) {
    submitTestInstance(orgId: $orgId, instanceId: $instanceId) {
      ...CoreTestInstanceFields
    }
  }
`;

// Reserve a test instance timeslot
export const RESERVE_TEST_INSTANCE_TIMESLOT = gql`
  mutation ReserveTestInstanceTimeslot(
    $orgId: GUID!
    $instanceId: GUID!
    $start: DateTime!
    $duration: Int!
  ) {
    reserveTestInstanceTimeslot(
      orgId: $orgId
      instanceId: $instanceId
      start: $start
      duration: $duration
    )
  }
`;

// Release an existing reservation
export const RELEASE_RESERVATION = gql`
  mutation ReleaseReservation(
    $orgId: GUID!
    $instanceId: GUID!
    $reservationId: GUID!
  ) {
    releaseReservation(
      orgId: $orgId
      instanceId: $instanceId
      reservationId: $reservationId
    )
  }
`;

export const ASSIGN_TEST_INSTANCE_JUROR = gql`
  ${CORE_TEST_INSTANCE_FIELDS}
  mutation AssignJuror(
    $orgId: GUID!
    $instanceId: GUID!
    $judgingOptions: JudgingOptions!
    $jurorId: GUID!
  ) {
    assignJuror(
      orgId: $orgId
      instanceId: $instanceId
      judgingOptions: $judgingOptions
      jurorId: $jurorId
    ) {
      ...CoreTestInstanceFields
    }
  }
`;

// Accept juror assignment
export const ACCEPT_JUROR_ASSIGNMENT = gql`
  ${CORE_TEST_INSTANCE_FIELDS}
  mutation AcceptJurorAssignment($orgId: GUID!, $instanceId: GUID!) {
    acceptJurorAssignment(orgId: $orgId, instanceId: $instanceId) {
      ...CoreTestInstanceFields
    }
  }
`;

// Reject juror assignment
export const REJECT_JUROR_ASSIGNMENT = gql`
  ${CORE_TEST_INSTANCE_FIELDS}
  mutation RejectJurorAssignment(
    $orgId: GUID!
    $instanceId: GUID!
    $scorecardId: GUID!
  ) {
    rejectJurorAssignment(
      orgId: $orgId
      instanceId: $instanceId
      scorecardId: $scorecardId
    ) {
      ...CoreTestInstanceFields
    }
  }
`;

// Set test instance scores
export const SET_TEST_INSTANCE_SCORES = gql`
  ${CORE_TEST_INSTANCE_FIELDS}
  mutation SetTestInstanceScores(
    $orgId: GUID!
    $instanceId: GUID!
    $scores: TestScoreValues!
  ) {
    setTestInstanceScores(
      orgId: $orgId
      instanceId: $instanceId
      scores: $scores
    ) {
      ...CoreTestInstanceFields
    }
  }
`;

// Submit test instance scores
export const SUBMIT_TEST_INSTANCE_SCORES = gql`
  ${CORE_TEST_INSTANCE_FIELDS}
  mutation SubmitTestInstanceScores($orgId: GUID!, $instanceId: GUID!) {
    submitTestInstanceScores(orgId: $orgId, instanceId: $instanceId) {
      ...CoreTestInstanceFields
    }
  }
`;

/*
 * END TEST INSTANCE
 */

/*
 * TAGS
 */
// Create a new tag
export const CREATE_TAG = gql`
  mutation CreateTag($orgId: GUID!, $tag: TagDefinition!) {
    createTag(orgId: $orgId, tag: $tag) {
      id
      name
    }
  }
`;

// Assign tags to a set of test instances
export const TAG_TEST_INSTANCES = gql`
  ${CORE_ASSIGNED_BY_TEST_INSTANCE_FIELDS}
  mutation TagTestInstances($orgId: GUID!, $instanceIds: [GUID!]!, $tagIds:[GUID!]!) {
    tagTestInstances(orgId: $orgId, instanceIds: $instanceIds, tagIds: $tagIds) {
      ... on ExampleryError {
        message
        code
        path
        stack
      }
      ... on TestInstance {
        ...CoreAssignedByTestInstanceFields
      }
    }
  }
`;

// Remove tags from a set of test instances
export const UNTAG_TEST_INSTANCES = gql`
  ${CORE_ASSIGNED_BY_TEST_INSTANCE_FIELDS}
  mutation UntagTestInstances($orgId: GUID!, $instanceIds: [GUID!]!, $tagIds:[GUID!]!) {
    untagTestInstances(orgId: $orgId, instanceIds: $instanceIds, tagIds: $tagIds) {
      ... on ExampleryError {
        message
        code
        path
        stack
      }
      ... on TestInstance {
        ...CoreAssignedByTestInstanceFields
      }
    }
  }
`;
/*
 * END TAGS
 */

/*
 * REPORTS
 */

// Get download URL for test instance reports
export const GET_TEST_INSTANCES_REPORT_DOWNLOAD_URL = gql`
  mutation GetTestInstancesReportDownloadURL($orgId: GUID!, $instanceIds: [GUID!]!, 
    $reportTemplateId: GUID, $compareWith: [TestInstanceQueryFilteringOptions!]) {
    getTestInstancesReportDownloadURL(orgId: $orgId, instanceIds: $instanceIds, 
      reportTemplateId: $reportTemplateId, compareWith: $compareWith)
  }  
`;

// Get download URL for test instance rollup reports
export const GET_TEST_INSTANCES_ROLLUP_REPORT_DOWNLOAD_URL = gql`
  mutation GetTestInstancesRollupReportDownloadURL($orgId: GUID!, $instanceIds: [GUID!]!, 
    $reportTemplateId: GUID) {
    getTestInstancesRollupReportDownloadURL(orgId: $orgId, instanceIds: $instanceIds, 
      reportTemplateId: $reportTemplateId)
  }  
`;

/*
 * END REPORTS
 */

// Retrieve the URL of the org splash page
export const GET_ORG_SPLASH_PAGE_DOWNLOAD_URL = gql`
  mutation GetOrgSplashPageDownloadURL(
    $orgId: GUID!
  ) {
    getOrgSplashPageDownloadURL(
      orgId: $orgId
    )
  }
`;