import { useRef, useState } from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import DescriptionIcon from "@mui/icons-material/Description";
import { t } from "i18next";
import SelectFileButton from "../FileSystem/SelectFileButton";

const AssetDropzone = ({ handleDrop, uploadContent, setUploadContent }) => {
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const folderInputElement = useRef(null);

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDraggingOver(true);
  };
  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDraggingOver(false);
  };
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const onDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setIsDraggingOver(false);
    if (e.dataTransfer.items) {
      handleDrop(e);
    }
  };

  const handleFileSelect = (e) => {
    if (!e.target.files.length) {
      return;
    }
    setUploadContent(e.target.files);
  };

  const handleFolderSelect = (e) => {
    if (!e.target.files.length) {
      console.log("No files selected");
      return;
    }

    const folderStructure = {};
    [...e.target.files].forEach((file) => {
      /*
        Normalize the paths into a folder structure in a form of object.
        Folders are regular keys, files are stored in $fileList array possibly inside each key.
        e.g.
        INPUT:
        [
            {File1: /folderA/File1},
            {File2: /folderA/folderB/File2},
            {File3: /folderA/folderC/File3},
        ]
        OUTPUT:
        {
            folderA: {
                folderB: {
                    $fileList: [File2]
                },
                folderC: {
                    $fileList: [File3]
                },
                $fileList: [File1]
            }
        }

        CAVEAT: Empty folders are completely ignored. It's the way the input element works.
    */
      const path = file.webkitRelativePath.split("/");
      let currentNode = folderStructure;
      path.forEach((segment, index) => {
        if (index < path.length - 1) {
          currentNode[segment] = currentNode[segment] || {};
        } else {
          currentNode.$fileList
            ? currentNode.$fileList.push(file)
            : (currentNode.$fileList = [file]);
        }
        currentNode = currentNode[segment];
      });
    });

    /*
        Transform the folder structure to the structure expected in the upload chain
    */
    const transformData = (currentFolder, currentArray) => {
      let keys = Object.keys(currentFolder);
      keys.forEach((key) => {
        if (key === "$fileList") {
          currentArray.push(...currentFolder.$fileList);
        } else {
          currentArray.unshift([{ name: key, isFolder: true }, []]);
          transformData(currentFolder[key], currentArray[0][1]);
        }
      });
    };

    let output = [];
    transformData(folderStructure, output);

    setUploadContent(output);
  };

  const handleReplaceAsset = () => {
    /*
      We need to clear the value of the input elements so the same file can be chosen against
      else the input element doesn't fire the change event
     */
    folderInputElement.current.value = null;
    setUploadContent(null);
  };

  return (
    <div
      onDrop={onDrop}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      className={`drag-upload`}
      css={styles.dragUpload}>
      <input
        id="folder-upload-button"
        directory=""
        webkitdirectory=""
        name="fileList"
        multiple
        type="file"
        ref={folderInputElement}
        style={{ display: "none" }}
        onChange={handleFolderSelect}
      />
      <div className="drag-upload-content">
        {!uploadContent && !isDraggingOver && (
          <>
            <h3 className="message">{t("DragAssetHere")}</h3>
            <p className="message">{t("or")}</p>
            <SelectFileButton
              onOpen={handleFileSelect}
              title={t("ChooseFile")}
              buttonVariant = "contained"
            />
            <label htmlFor="folder-upload-button">
              <Button
                className="upload-button"
                variant="contained"
                component="span">
                {t("ChooseFolder")}
              </Button>
            </label>
          </>
        )}
        {uploadContent && !isDraggingOver && (
          <>
            <List>
              <ListItem
                secondaryAction={
                  <Button
                    onClick={handleReplaceAsset}
                    variant="text"
                    component="span">
                    {t("ReplaceAsset")}
                  </Button>
                }>
                <ListItemAvatar>
                  {uploadContent[0] instanceof Array ? (
                    <FolderIcon />
                  ) : (
                    <DescriptionIcon />
                  )}
                </ListItemAvatar>
                <ListItemText
                  primary={
                    uploadContent[0] instanceof Array
                      ? uploadContent[0][0].name
                      : uploadContent[0].name
                  }
                  secondary={
                    uploadContent[0] instanceof Array
                      ? `${uploadContent[0][1]?.length || "0"} sub-items`
                      : "File"
                  }
                />
              </ListItem>
            </List>
          </>
        )}
      </div>
      {isDraggingOver && (
        <div className="drag-upload-backdrop">
          <h3 className="message">{t("DropItemHere")}</h3>
        </div>
      )}
    </div>
  );
};

const styles = (() => {
  const variables = {
    $grey: `rgba(0, 0, 0, .5)`,
  };

  return {
    dragUpload: css`
      border-radius: 3px;
      background-image: repeating-linear-gradient(
          0deg,
          ${variables.$grey},
          ${variables.$grey} 23px,
          transparent 23px,
          transparent 43px,
          ${variables.$grey} 43px
        ),
        repeating-linear-gradient(
          90deg,
          ${variables.$grey},
          ${variables.$grey} 23px,
          transparent 23px,
          transparent 43px,
          ${variables.$grey} 43px
        ),
        repeating-linear-gradient(
          180deg,
          ${variables.$grey},
          ${variables.$grey} 23px,
          transparent 23px,
          transparent 43px,
          ${variables.$grey} 43px
        ),
        repeating-linear-gradient(
          270deg,
          ${variables.$grey},
          ${variables.$grey} 23px,
          transparent 23px,
          transparent 43px,
          ${variables.$grey} 43px
        );
      background-size: 3px 100%, 100% 3px, 3px 100%, 100% 3px;
      background-position: 0 0, 0 0, 100% 0, 0 100%;
      background-repeat: no-repeat;

      max-height: 320px;
      height: 180px;
      position: relative;
      text-align: center;

      .drag-upload-content {
        height: 100%;
        margin: auto;
        padding: 16px;
      }

      .drag-upload-backdrop {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: lightgreen;
        opacity: 0.8;
        display: flex;
        align-items: center;
        justify-content: center;
        pointer-events: none;
      }

      .message {
        text-align: center;
        color: ${variables.$grey};
        pointer-events: none;
      }

      .upload-button {
        margin: 0 8px;
      }
    `,
  };
})();

export default AssetDropzone;
