import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Grid,
  Typography,
  TextField,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel
} from "@mui/material";
import { useCustomSnackbar } from "../../components/CustomSnackbar";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { CREATE_TEST_TEMPLATE } from "../../graphql/mutations";
import { GET_TEST_TEMPLATES } from "../../graphql/queries";
import { TestTemplateStatus } from "../../graphql/enums";
import { useMutation } from "@apollo/client";
import { handleCacheUpdate } from "../../cache";
import { useAppContext } from "../../context/AppContext";

export default function CreateTestTemplateForm(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { lsEnqueueSnackbar } = useCustomSnackbar();

  const { store, actions } = useAppContext();

  // API to create a new test templte and add it to the cache
  const [createTestTemplate] = useMutation(CREATE_TEST_TEMPLATE, {
    update: (cache, results) => {
      if (results?.data) {
        handleCacheUpdate(
          "create",
          cache,
          results.data.createTestTemplate,
          "id",
          GET_TEST_TEMPLATES,
          "getTestTemplates",
          null,
          {
            orgId: store.currentOrg?.org.id,
            templateStatus: [
              TestTemplateStatus.Draft,
              TestTemplateStatus.Active,
              TestTemplateStatus.Deprecated,
              TestTemplateStatus.Retired
            ],
          }
        );
      }
    },
  });

  const validationSchema = Yup.object({
    name: Yup.string().required(t("Required")),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      testPlatform: "appstream"
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const vars = {
        variables: {
          orgId: store.currentOrg.org.id,
          testTemplate: {
            name: values.name.trim(),
            description:
              values.description === "" ? null : values.description.trim(),
            testPlatform: values.testPlatform
          },
        },
      };

      // Create the new test template
      createTestTemplate(vars)
        .then((res) => {
          if (res.errors) {
            lsEnqueueSnackbar(res.errors[0].message, {
              variant: "error",
            });
            setSubmitting(false);
          } else {
            lsEnqueueSnackbar(t("TestTemplateCreated"), {
              variant: "success",
            });
            setSubmitting(false);
            actions.setCurrentTestTemplate(res.data.createTestTemplate);
            navigate("/app/edittesttemplate");
          }
        })
        .catch((e) => {
          lsEnqueueSnackbar(e.message, {
            variant: "error",
          });
          setSubmitting(false);
        }); // catch network errors
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2} paddingLeft="20%" paddingRight="20%">
        <Grid item xs={12}>
          <Typography
            component="h1"
            variant="h5"
            sx={{ marginBottom: 3, fontWeight: "bold" }}
          >
            {t("CreateTestTemplate")}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <TextField
            name="name"
            variant="outlined"
            required
            fullWidth
            id="name"
            label={t("Name")}
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            name="description"
            variant="outlined"
            multiline
            fullWidth
            id="description"
            label={t("Description")}
            value={formik.values.description}
            onChange={formik.handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <FormLabel id="test-platform-radio-label">{t("TestPlatform")}</FormLabel>
          <RadioGroup
            name="testPlatform"
            aria-labelledby="test-platform-radio-label"
            defaultValue="appstream"
            id="testPlatform"
            onChange={formik.handleChange}
            value={formik.values.testPlatform}
          >
            <FormControlLabel value="appstream" control={<Radio />} label={t("AppStream")} />
            <FormControlLabel value="website" control={<Radio />} label={t("WebSite")} />
          </RadioGroup>
        </Grid>

        <Grid container justifyContent="flex-end">
          <Grid item sx={{ textAlign: "right" }}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              sx={{ marginTop: 3, marginRight: 0, marginBottom: 1.5 }}
            >
              {t("Create")}
            </Button>
          </Grid>
        </Grid>

      </Grid>
    </form>
  );
}
