import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import DashboardTable from "./DashboardTable";
import { useNavigate } from "react-router-dom";
import { Waiting } from "../Waiting";
import { t } from "i18next";
import { useAppContext } from "../../context/AppContext";
import { TestInstanceStatus } from "../../graphql/enums";

export default function DashboardSubmittedByMe({
  activeTab,
  tData,
  tLoading,
  refreshMyTestInstances
}) {
  const { actions } = useAppContext();
  const navigate = useNavigate();

  const [candidateSubmittedTableData, setCandidateSubmittedTableData] = useState([]);

  const candidateSubmittedTableHeaders = [
    { key: "name", value: t("TestName"), sortable: true, type: "string" },
    { key: "assignedBy", value: t("AssignedBy"), sortable: true, type: "string" },
    { key: "completionDate", value: t("CompletionDate"), sortable: true, type: "date" },
    { key: "status", value: t("Status"), sortable: false, type: "string" },
  ];

  const handleRefreshMyTestInstances = () => {
    refreshMyTestInstances();
  };

  const handleRowActionClick = (action, row) => {
    switch (action) {
      case "resume":
        handleResumeTest(row.instance);
        break;
      case "submit":
        handleSubmitTest(row.instance);
        break;
      case "accept":
        handleAcceptTest(row.instance);
        break;
      case "decline":
        handleDeclineTest(row.instance);
        break;
      case "forfeit":
        handleForfeitTest(row.instance);
        break;
      case "discard":
        handleDiscardTest(row.instance);
        break;
      case "revoke":
        handleRevokeTest(row.instance);
        break;
      case "viewResults":
        handleViewResults(row.instance);
        break;

      default:
        console.log(action, row);
    }
  };

  // map the received data to table row friendly data for both tables
  useEffect(() => {
    if (tData?.getTestInstancesAssignedToMe) {
      const testInstances = tData.getTestInstancesAssignedToMe.filter(instance => 
        [TestInstanceStatus.Unmarked, TestInstanceStatus.UnmarkedComplete, 
        TestInstanceStatus.Marked, TestInstanceStatus.UnmarkedPending,
        TestInstanceStatus.UnmarkedCompletePending].includes(instance.status));

      const submitted = [];
      const CandidateTestInstanceStatusText = {
        [TestInstanceStatus.Marked]: t("Evaluated"),
        [TestInstanceStatus.Unmarked]: t("PendingEvaluation"),
        [TestInstanceStatus.UnmarkedComplete]: t("Complete"),
        [TestInstanceStatus.UnmarkedPending]: t("PendingEvaluation"),
        [TestInstanceStatus.UnmarkedCompletePending]: t("Complete"),
      };

      testInstances.forEach((testInstance) => {
        const deadlineDatetime = new Date(
          testInstance.finalTargetCompletionDate
        );
        const currentDatetime = new Date();
        // accept action is disabled when the deadline has passed or the test doesn't have work time left.
        const isDeadlineReached =
          currentDatetime > deadlineDatetime ||
          testInstance.workTimeRemaining === 0;

        // Submitted tests table
        let actions = getCandidateTestInstanceActionsPerStatus(testInstance.status, isDeadlineReached);
        const testTableRow = {
          id: testInstance.id,
          fields: [
            { key: "name", value: testInstance.testTemplate.name, type: "string" },
            {
              key: "assignedBy",
              value:
                testInstance.assignedBy.firstname +
                " " +
                testInstance.assignedBy.lastname,
              type: "string"
            },
            { key: "completionDate", value: testInstance.completionDate || new Date().toISOString(), type: "date" },
            { key: "status", value: CandidateTestInstanceStatusText[testInstance.status], type: "string" }
          ],
          actions,
          instance: testInstance,
        };
        submitted.push(testTableRow);
      });
      setCandidateSubmittedTableData(submitted);
    }
  }, [tData]);

  const getCandidateTestInstanceActionsPerStatus = (
    status,
    isDeadlineReached
  ) => {
    switch (status) {
      case TestInstanceStatus.Marked:
      case TestInstanceStatus.UnmarkedComplete:
        // return [{ key: "viewResults", value: t("ViewResults") }];
        return [];
      case TestInstanceStatus.Unmarked:
        return [
          // { key: "revoke", value: "Revoke" }
        ];
      case TestInstanceStatus.Cancelled:
        return [];

      default:
        return [];
    }
  };

  const handleAcceptTest = (testInstance) => {
    // Don't allow beginning of the test if no time is left
    const deadlineDatetime = new Date(testInstance.finalTargetCompletionDate);
    const currentDatetime = new Date();
    if (
      currentDatetime > deadlineDatetime ||
      testInstance.workTimeRemaining === 0
    )
      return;
    actions.setCurrentTestInstance(testInstance);
    navigate("/app/testTermsAndConditions");
  };

  const handleDeclineTest = (testInstance) => {
    actions.setCurrentTestInstance(testInstance);
    navigate("/app/declineTest");
  };

  const handleForfeitTest = (testInstance) => {
    actions.setCurrentTestInstance(testInstance);
    navigate("/app/forfeitTest");
  };

  const handleDiscardTest = (testInstance) => {
    actions.setCurrentTestInstance(testInstance);
    navigate("/app/discardTest");
  };

  const handleRevokeTest = (testInstance) => {
    actions.setCurrentTestInstance(testInstance);
    navigate("/app/revokeTest");
  };

  const handleResumeTest = (testInstance) => {
    actions.setCurrentTestInstance(testInstance);
    navigate("/app/testInstructionsBeforeTest");
  };

  const handleSubmitTest = (testInstance) => {
    actions.setCurrentTestInstance(testInstance);
    navigate("/app/submitTest");
  };

  const handleViewResults = (instance) => {
    actions.setCurrentTestInstance(instance);
    navigate("/app/reviewtest");
  };

  let pageContents;
  if (tLoading) {
    pageContents = <Waiting />;
  } else {
    pageContents = (
      <div
        role="tabpanel"
        hidden={activeTab !== 4}
        id="tabpanel-4"
        aria-labelledby="tab-4"
      >
        <Box>
          <DashboardTable
            title={t("SubmittedTests")}
            headers={candidateSubmittedTableHeaders}
            data={candidateSubmittedTableData}
            onRowActionClick={handleRowActionClick}
            isloading={tLoading}
            emptyText={t("NoSubmittedTestsMessage")}
            onRefresh={handleRefreshMyTestInstances}
            defaultOrder="desc"
            defaultOrderBy="completionDate"
            defaultOrderType="date"
          />
        </Box>
      </div>
    );
  }

  return pageContents;
}
