import React, { useState } from "react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Typography
} from "@mui/material";
import { useField } from "formik";
import { useTranslation } from "react-i18next";
import AddAssetForm from "../../views/HiringManager/AddAsset";

const TestTemplateInstructionsForm = React.memo(({
  assets,
  handleContinue
}) => {

  const filterInstructions = (allAssets) => allAssets
    .filter((asset) => asset.mimetype === "application/pdf")
    .sort((a, b) => a.name.localeCompare(b.name));

  const { t, i18n } = useTranslation();
  const [isTextField] = useField("instructions.isText");
  const [instructionsTextField, instructionsTextMeta, instructionsTextHelpers] = 
    useField("instructions.text");
  const [instructionsAssetField, instructionsAssetMeta, instructionsAssetHelpers] =
    useField("instructions.assetId");
  const [isUploadInstructionsOpen, setIsUploadInstructionsOpen] = useState(false);
  const [instructionsData, setInstructionsData] = useState(filterInstructions([...assets]));

  const handleInstructionTextChange = (content) => {
    instructionsTextHelpers.setValue(content);
  };

  const handleOpenUploadInstructions = () => {
    setIsUploadInstructionsOpen(true);
  };

  const handleInstructionsUploadSuccess = (asset) => {
    let newInstructionsData = [...instructionsData];
    newInstructionsData.push(asset);
    setInstructionsData(filterInstructions(newInstructionsData));
    instructionsAssetHelpers.setValue(asset.id);
  };

  let pageResults = (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {t("Asset")}
          <Switch
            {...isTextField}
            checked={isTextField.value}
            name="instructions.isText"
            color="primary"
          />
          {t("RichText")}
        </Grid>

        {isTextField.value ? (
          <Grid container>
            <Grid item xs={12}>
              <SunEditor
                name="instructions.text"
                lang={i18n.language === "French" ? "fr" : "en"}
                height="15vh"
                onChange={handleInstructionTextChange}
                defaultValue={instructionsTextField.value}
                setDefaultStyle="font-family: Arial; font-size: 16px;"
                setOptions={{
                  buttonList: [
                    ["undo", "redo"],
                    ["font", "fontSize", "formatBlock"],
                    ["paragraphStyle", "blockquote"],
                    [
                      "bold",
                      "underline",
                      "italic",
                      "strike",
                      "subscript",
                      "superscript",
                    ],
                    ["fontColor", "hiliteColor"],
                    ["removeFormat"],
                    "/", // Line break
                    ["outdent", "indent"],
                    ["align", "horizontalRule", "list", "lineHeight"],
                    ["table", "link", "image"],
                    ["fullScreen", "showBlocks", "codeView"],
                    // ["dir", "dir_ltr", "dir_rtl"],
                  ],
                }}
              />
              {!!instructionsTextMeta.error && (
                <FormHelperText error={true}>
                  {instructionsTextMeta.error}
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={12}>
              <br />
              <Typography variant="body2">
                {t("RichTextEditorInstructions")}
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <>
            <Grid item xs={10}>
              <FormControl
                variant="outlined"
                sx={{
                  margin: 0,
                  width: "100%",
                  display: "flex",
                  flexWrap: "nowrap",
                }}
              >
                <InputLabel id="instructions-label">
                  {t("Instructions")}
                </InputLabel>
                <Select
                  {...instructionsAssetField}
                  label={t("Instructions")}
                  labelId="instructions-label"
                  id="instructions"
                  defaultValue=""
                  required
                  onChange={(e) => {
                    instructionsAssetHelpers.setError(null);
                    instructionsAssetHelpers.setValue(e.target.value);
                  }}
                  error={instructionsAssetMeta.touched && !!instructionsAssetMeta.error}
                >
                  {instructionsData.map((i, index) => (
                    <MenuItem key={index} value={i.id}>
                      {i.name}
                    </MenuItem>
                  ))}
                </Select>
                {!!instructionsAssetMeta.error && (
                  <FormHelperText error={true}>
                    {instructionsAssetMeta.touched && instructionsAssetMeta.error}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <Button
                fullWidth={true}
                style={{ height: "56px" }}
                variant="outlined"
                onClick={() => {
                  handleOpenUploadInstructions();
                }}
              >
                {t("UploadInstructions")}
              </Button>
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleContinue}
                sx={{ marginTop: 1 }}
              >
                {t("Continue")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <AddAssetForm
        isAddAssetOpen={isUploadInstructionsOpen}
        setIsAddAssetOpen={setIsUploadInstructionsOpen}
        onUploadSuccess={handleInstructionsUploadSuccess}
      />
    </React.Fragment>
  );
  return pageResults;
});

export default TestTemplateInstructionsForm;