import React, { useState, useEffect } from "react";
import {
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Switch,
  FormControl,
  FormHelperText,
  FormControlLabel,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton
} from "@mui/material";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import DeleteIcon from "@mui/icons-material/Delete";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useCustomSnackbar } from "../../components/CustomSnackbar";
import * as Yup from "yup";
import { ASSIGN_TEST_INSTANCE } from "../../graphql/mutations";
import {
  GET_TEST_INSTANCES_ASSIGNED_TO_ME,
  GET_TEST_INSTANCES_ASSIGNED_BY_ME,
} from "../../graphql/queries";
import { useMutation } from "@apollo/client";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { handleCacheUpdate } from "../../cache";
import useGuardedRoute from "../../hooks/useGuardedRoute";
import { useAppContext } from "../../context/AppContext";
import TagControl from "../../components/Tags/TagControl";
import ImportSpreadsheet from "../../components/Spreadsheet/ImportSpreadsheet";

const AssignCandidateForm = () => {
  const { t } = useTranslation();
  const { lsEnqueueSnackbar } = useCustomSnackbar();
  const navigate = useNavigate();

  const { store } = useAppContext();

  useGuardedRoute("/app", [store.currentTestTemplate]);

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [assignmentErrors, setAssignmentErrors] = useState([]);
  const [tags, setTags] = useState([]);
  const [waiting, setWaiting] = useState(false);

  const handleOpenConfirmationDialog = () => {
    setOpenConfirmationDialog(true);
  };

  const handleCloseConfirmationDialog = () => {
    setOpenConfirmationDialog(false);
  };

  const handleAddAssignee = () => {
    formik.setFieldValue("assignees", [
      ...formik.values.assignees,
      { firstName: "", lastName: "", email: "" }
    ]);
  };

  const handleRemoveAssignee = (index) => {
    const updatedAssignees = formik.values.assignees.filter((_, i) => i !== index);
    formik.setFieldValue("assignees", updatedAssignees);
  };

  const handleToggleHasEarliestStartDate = (event) => {
    formik.setFieldValue("hasEarliestStartDate", event.target.checked);
    if (!event.target.checked) {
      formik.setFieldValue("earliestStartDate", null);
      formik.setFieldTouched("earliestStartDate", false);
    } else {
      formik.setFieldValue("earliestStartDate", new Date());
    }
  }

  const handleImportData = (data) => {
    if (data.length > 0) {
      const assignees = data.map((row) => ({
        firstName: row.firstName,
        lastName: row.lastName,
        email: row.email,
      }));
      formik.setFieldValue("assignees", assignees);
    }
  }

  const [assignTestInstance, { loading: atiLoading }] = useMutation(ASSIGN_TEST_INSTANCE, {
    update: (cacheClient, response) => {
      if (response?.data) {
        handleCacheUpdate(
          "create",
          cacheClient,
          response.data.assignTestInstance,
          "id",
          GET_TEST_INSTANCES_ASSIGNED_TO_ME,
          "getTestInstancesAssignedToMe",
          null,
          {}
        );

        handleCacheUpdate(
          "create",
          cacheClient,
          response.data.assignTestInstance,
          "id",
          GET_TEST_INSTANCES_ASSIGNED_BY_ME,
          "getTestInstancesAssignedByMe",
          null,
          {
            orgId: store.currentOrg?.org.id,
            options: {
              filtering: store.assignedByMeFilter?.length > 0 ? store.assignedByMeFilter : null,
            }
          }
        );
      }
    },
  });

  const validationSchema = Yup.object({
    assignees: Yup.array().of(
      Yup.object({
        firstName: Yup.string().required(t("Required")),
        lastName: Yup.string().required(t("Required")),
        email: Yup.string().email(t("MustBeValidEmail")).required(t("Required")),
      })
    ),
    targetCompletionDate: Yup.date().nullable().required(t("Required")),
    hasEarliestStartDate: Yup.boolean(),
    earliestStartDate: Yup.date()
      .nullable()
      .when('hasEarliestStartDate', {
        is: true,
        then: Yup.date()
          .nullable()
          .required(t("Required"))
          .test(
            'sufficient-time-for-test',
            t('EarliestStartDateTooLate'),
            function (value) {
              const { targetCompletionDate } = this.parent;
              return value && targetCompletionDate
                ? new Date(value.getTime() + store.currentTestTemplate.maxWorkTime * 1000) <=
                targetCompletionDate
                : true;
            }
          ),
        otherwise: Yup.date().nullable(),
      }),
  });

  const formik = useFormik({
    initialValues: {
      assignees: [{ firstName: "", lastName: "", email: "" }],
      targetCompletionDate: new Date(new Date().setHours(23, 59, 59, 999)),
      hasEarliestStartDate: false,
      earliestStartDate: null,
      requiresAcceptance: false,
      allowAssigneeReject: false
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      let results;
      try {
        setAssignmentErrors([]);
        setWaiting(true);
        results = await assignTests(values, assignTestInstance, store, tags);
        setWaiting(false);
        let newAssignees = [...values.assignees];
        let newResults = [...results];

        for (let i = 0; i < newResults.length; ++i) {
          let result = newResults[i];
          if (result.status === 'fulfilled' && !result?.value.errors) {
            results.splice(i - newResults.length + results.length, 1);
            newAssignees.splice(i - values.assignees.length + newAssignees.length, 1);
          }
        }

        if (newAssignees.length === 0) {
          lsEnqueueSnackbar(t("TestAssigned"), {
            variant: "success"
          });
          setSubmitting(false);
          navigate("/app");
        } else if (newAssignees.length === values.assignees.length) {
          lsEnqueueSnackbar(results[0].value.errors[0].message, {
            variant: "error"
          });
        } else {
          lsEnqueueSnackbar(t("TestAssignmentErrors"), {
            variant: "warning"
          });
        }
        setSubmitting(false);
        setOpenConfirmationDialog(false);
        formik.setFieldValue("assignees", newAssignees);
        setAssignmentErrors(results.map((result) => result.status === 'fulfilled' ?
          result.value.errors[0].message :
          result.reason));
      } catch (error) {
        lsEnqueueSnackbar(error.message, {
          variant: "error",
        });
        setSubmitting(false);
        setOpenConfirmationDialog(false);
      }
    }
  });

  useEffect(() => {
    if (formik.submitCount > 0 && Object.keys(formik.errors).length > 0) {
      handleCloseConfirmationDialog();
    }
  }, [formik.submitCount, formik.errors]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2} paddingLeft="5%" paddingRight="5%">
        <Grid item xs={12}>
          <Typography
            component="h1"
            variant="h5"
            sx={{ marginBottom: 2, fontWeight: "bold" }}
          >
            {t("AssignCandidates")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography component="p" sx={{ marginBottom: 1 }}>
            {t("AssignCandidatesExplanation")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
            <Grid item>
              <ImportSpreadsheet 
                dialogTitle={t("ImportAssigneesTitle")}
                dialogExplanation={t("ImportAssigneesExplanation")}
                importDataNames={[
                  {name: "firstName", label: t("FirstName")},
                  {name: "lastName", label: t("LastName")},
                  {name: "email", label: t("EmailAddress")},
                ]}
                onSubmit={handleImportData}
              />
            </Grid>
            <Grid item>
              <Button onClick={handleAddAssignee} variant="outlined">
                {t("AddParticipant")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {/* Dynamic Assignee List */}
        <Grid item xs={12} >
          <TableContainer component={Paper} sx={{
             maxHeight: "30vh", 
             overflowY: "auto", 
             boxShadow: "none",
             mb: 2 
          }}>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow sx={{
                  "& .MuiTableCell-root": {
                    backgroundColor: "#f5f5f5",
                  }
                }}>
                  <TableCell>{t("FirstName")}</TableCell>
                  <TableCell>{t("LastName")}</TableCell>
                  <TableCell>{t("EmailAddress")}</TableCell>
                  <TableCell/>
                  <TableCell/>
                </TableRow>
              </TableHead>
              <TableBody>
                {formik.values.assignees.map((assignee, index) => (
                  <TableRow key={index} hover>
                    <TableCell>
                      <TextField
                        fullWidth
                        size="small"
                        name={`assignees[${index}].firstName`}
                        value={assignee.firstName}
                        onChange={formik.handleChange}
                        error={formik.touched.assignees?.[index]?.firstName && Boolean(formik.errors.assignees?.[index]?.firstName)}
                        helperText={formik.touched.assignees?.[index]?.firstName && formik.errors.assignees?.[index]?.firstName}

                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        size="small"
                        name={`assignees[${index}].lastName`}
                        value={assignee.lastName}
                        onChange={formik.handleChange}
                        error={formik.touched.assignees?.[index]?.lastName && Boolean(formik.errors.assignees?.[index]?.lastName)}
                        helperText={formik.touched.assignees?.[index]?.lastName && formik.errors.assignees?.[index]?.lastName}

                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        size="small"
                        name={`assignees[${index}].email`}
                        value={assignee.email}
                        onChange={formik.handleChange}
                        error={formik.touched.assignees?.[index]?.email && Boolean(formik.errors.assignees?.[index]?.email)}
                        helperText={formik.touched.assignees?.[index]?.email && formik.errors.assignees?.[index]?.email}

                      />
                    </TableCell>
                    <TableCell>
                      {(formik.values.assignees.length > 1 || index > 0) &&
                        <IconButton edge="end" aria-label="delete" size="small"
                          onClick={() => handleRemoveAssignee(index)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      }
                    </TableCell>
                    <TableCell>
                      {(assignmentErrors && assignmentErrors.length > 0) &&
                        <Tooltip
                          title={assignmentErrors[index]}
                          arrow
                        >
                          <ErrorOutlineIcon color="error"/>
                        </Tooltip>
                      }
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <FormControl
              fullWidth
              error={formik.touched.targetCompletionDate && Boolean(formik.errors.targetCompletionDate)}
            >
              <DatePicker
                id="targetCompletionDate"
                name="targetCompletionDate"
                label={t("TargetCompletionDate")}
                value={formik.values.targetCompletionDate}
                onChange={(value) =>
                  formik.setFieldValue("targetCompletionDate", value)
                }
                disablePast
                sx={{ width: '100%' }}
                inputVariant="outlined"
              />
              <FormHelperText>
                {formik.touched.targetCompletionDate && formik.errors.targetCompletionDate}
              </FormHelperText>
            </FormControl>
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="flex-start" alignItems="center" spacing={5}>
            <Grid item>
              <Grid container alignItems="center">
                <Grid item>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formik.values.requiresAcceptance}
                        onChange={formik.handleChange}
                        name="requiresAcceptance"
                        color="primary"
                      />
                    }
                    label={t("AcceptTestRequired")}
                  />
                </Grid>
                <Grid item>
                  <Tooltip
                    title={t("AcceptTestRequiredHelp")}
                    arrow
                  >
                    <HelpOutlineIcon fontSize="small" sx={{ display: 'block', margin: 'auto' }} />
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container alignItems="center">
                <Grid item>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formik.values.allowAssigneeReject}
                        onChange={formik.handleChange}
                        name="allowAssigneeReject"
                        color="primary"
                      />
                    }
                    label={t("AssigneeRejectAllowed")}
                  />
                </Grid>
                <Grid item>
                  <Tooltip
                    title={t("AssigneeRejectAllowedHelp")}
                    arrow
                  >
                    <HelpOutlineIcon fontSize="small" sx={{ display: 'block', margin: 'auto' }} />
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container alignItems="center" sx={{ mb: 1 }}>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={formik.values.hasEarliestStartDate}
                    onChange={handleToggleHasEarliestStartDate}
                    name="hasEarliestStartDate"
                    color="primary"
                  />
                }
                label={t("SetEarliestStartDate")}
              />
            </Grid>
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <FormControl
                  fullWidth
                  error={formik.values.hasEarliestStartDate && formik.touched.earliestStartDate &&
                    Boolean(formik.errors.earliestStartDate)}
                >
                  <DateTimePicker
                    id="earliestStartDate"
                    name="earliestStartDate"
                    label={t("EarliestStartDate")}
                    value={formik.values.earliestStartDate}
                    onChange={(value) =>
                      formik.setFieldValue("earliestStartDate", value)
                    }
                    sx={{ width: '100%' }}
                    inputVariant="outlined"
                    error={formik.values.hasEarliestStartDate && formik.touched.earliestStartDate &&
                      Boolean(formik.errors.earliestStartDate)}
                    disabled={!formik.values.hasEarliestStartDate}
                  />
                  <FormHelperText>
                    {formik.touched.earliestStartDate && formik.errors.earliestStartDate}
                  </FormHelperText>
                </FormControl>
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TagControl
            tags={tags}
            setTags={setTags}
            onApplyTag={() => { return; }}
            onRemoveTag={() => { return; }}
            startInEditMode={false}
          />
        </Grid>
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => navigate("/app")}
              sx={{ marginTop: 3 }}
            >
              {t("Cancel")}
            </Button>
          </Grid>    
          <Grid item>
            <Button
              onClick={() => {
                handleOpenConfirmationDialog();
              }}
              variant="contained"
              color="primary"
              sx={{ marginTop: 3 }}
            >
              {t("Confirm")}
            </Button>
          </Grid>      
        </Grid>

        {/* confirmation dialog */}
        <Dialog
          open={openConfirmationDialog}
          onClose={handleCloseConfirmationDialog}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                {t("AssignCandidateConfirmation")}
              </Grid>
              <Grid item>
                {waiting && <CircularProgress size={24} />}
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t("AssignCandidateConfirmationExplanation")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseConfirmationDialog}
              color="secondary"
              variant="contained"
            >
              {t("Cancel")}
            </Button>
            <Button
              onClick={formik.handleSubmit}
              color="primary"
              variant="contained"
              disabled={atiLoading}
            >
              {t("Confirm")}
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </form>
  );
};

/**
 * Assigns tests to the specified assignees.
 * @param {Object} values - The form values.
 * @param {Function} assignTestInstance - The GraphQL mutation function for assigning tests.
 * @param {Object} store - The current context store 
 * @param {Array} tags - The tags to be associated with the assigned tests.
 * @returns {Promise} A Promise that resolves with the results of the assignment operations. 
 */
async function assignTests(values, assignTestInstance, store, tags) {
  let promises = [];
  for (let i = 0; i < values.assignees.length; i++) {
    const vars = {
      variables: {
        orgId: store.currentOrg.org.id,
        templateId: store.currentTestTemplate.id,
        instanceOptions: {
          targetCompletionDate: new Date(values.targetCompletionDate.setHours(23, 59, 59, 999)), // set to the end of the day
          earliestStartDate: values.hasEarliestStartDate ?
            new Date(values.earliestStartDate.setSeconds(0, 0)) : null,
          requiresAcceptance: values.requiresAcceptance,
          allowAssigneeReject: values.allowAssigneeReject
        },
        assignee: {
          firstname: values.assignees[i].firstName,
          lastname: values.assignees[i].lastName,
          email: values.assignees[i].email
        },
        tagIds: tags.map((tag) => tag.id)
      },
    };

    promises.push(assignTestInstance(vars));
  }
  let res = await Promise.allSettled(promises);

  return res;
}

export default AssignCandidateForm;
