import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import OpenSpreadsheet from "./OpenSpreadsheet";
import { utils } from "xlsx";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";


const ImportSpreadsheet = ({
  onSubmit,
  dialogTitle,
  dialogExplanation,
  importDataNames,
}) => {
  const { t } = useTranslation();
  const [openImportDialog, setOpenImportDialog] = useState(false);
  const [workbook, setWorkbook] = useState(null);
  const [tab, setTab] = useState("");
  const [topRow, setTopRow] = useState("");
  const [hasHeaderRow, setHasHeaderRow] = useState(false);
  const [sheetData, setSheetData] = useState(null);
  const [sheetWidth, setSheetWidth] = useState(0);
  const [dataColumns, setDataColumns] = useState([]);

  // Validation errors state
  const [errors, setErrors] = useState({
    tab: false,
    topRow: false,
    dataColumns: [],
  });

  const handleCloseImportDialog = () => {
    setWorkbook(null);
    setTab("");
    setTopRow("");
    setHasHeaderRow(false);
    setSheetData(null);
    setDataColumns([]);
    setErrors({ tab: false, topRow: false, dataColumns: [] });
    setOpenImportDialog(false);
  }

  const handleImport = () => {

    // Validate fields
    const validationErrors = {
      tab: tab === "",
      topRow: topRow === "",
      dataColumns: dataColumns.map((col) => col === ""),
    };

    setErrors(validationErrors);

    // Check if there are any errors
    if (validationErrors.tab || validationErrors.topRow || validationErrors.dataColumns.includes(true)) {
      return;
    }

    // build the return data set
    let importData = [];
    let blankRowFound = false;
    for (let i = topRow + (hasHeaderRow ? 1 : 0); i < sheetData.length && !blankRowFound; ++i) {
      let importItem = {};
      for (let j = 0; j < importDataNames.length; ++j) {
        if (sheetData[i][dataColumns[j]] !== "") {
          importItem[importDataNames[j].name] = sheetData[i][dataColumns[j]];
        }
      }
      if (Object.keys(importItem).length > 0) {
        importData.push(importItem);
      } else {
        blankRowFound = true;
      }
    }

    // return the import data
    if (onSubmit) {
      onSubmit(importData);
    }

    handleCloseImportDialog();
  }

  // Converts a column index to a spreadseet column name
  function indexToColumn(index) {
    let column = '';
    const baseCharCode = 'A'.charCodeAt(0); //  char code for 'A'
    while (index >= 0) {
      column = String.fromCharCode((index % 26) + baseCharCode) + column;
      index = Math.floor(index / 26) - 1;
    }
    return column;
  }

  useEffect(() => {
    if (workbook) {
      const sheetName = workbook.SheetNames[tab];
      const sheet = workbook.Sheets[sheetName];

      // Convert to JSON
      const data = utils.sheet_to_json(sheet, { header: 1, raw: false });

      // Determine the sheet width from the longest row and set the column headers
      const sheetWidth = data.reduce((acc, row) => (row.length > acc) ? row.length : acc, 0);
      setSheetWidth(sheetWidth);

      let dataColums = new Array(importDataNames.length).fill("");
      setDataColumns(dataColums);

      setSheetData(data);
    }
  }, [tab, workbook, importDataNames]);

  const handleChangeTab = (event) => {
    setTab(event.target.value);
    setTopRow(0);
    setHasHeaderRow(false);
  }

  const handleChangeTopRow = (event) => {
    setTopRow(event.target.value);
  }

  const handleOnOpenSpreadSheet = (workbook) => {
    setWorkbook(workbook);

    if (workbook.SheetNames.length === 1) {
      setTab(0);
      setTopRow(0);
    } else {
      setTab("");
    }

    setOpenImportDialog(true);
  }

  const handleHasHeaderRowChange = (event) => {
    setHasHeaderRow(event.target.checked);
  }
  const handleChangeDataColumn = (index, value) => {
    let dataColumnsCopy = [...dataColumns];
    dataColumnsCopy[index] = value;
    setDataColumns(dataColumnsCopy);

    // Clear validation error for this column
    const updatedErrors = { ...errors };
    updatedErrors.dataColumns[index] = false;
    setErrors(updatedErrors);
  }

  const columnHeaders = (sheetWidth) ? Array.from({ length: sheetWidth }, (_, i) => indexToColumn(i)) : [];

  return (
    <React.Fragment>
      <OpenSpreadsheet
        onOpen={handleOnOpenSpreadSheet}
      />
      <Dialog
        open={openImportDialog}
        onClose={handleCloseImportDialog}
        aria-labelledby="form-dialog-title"
        maxWidth="75vw"
        fullWidth={true}
        sx={{
          p: 2
        }}
      >
        <DialogTitle id="form-dialog-title">
          {dialogTitle}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1} alignItems="flex-start" justifyContent="flex-start">
            <Grid item xs={12} sx={{ mb: 1 }}>
              <Grid container spacing={2} alignItems="center" justifyContent="flex-start">
                <Grid item xs={12}>
                  <DialogContentText>
                    {dialogExplanation}
                  </DialogContentText>
                </Grid>
                <Grid item>
                  <FormControl sx={{ minWidth: "20vw" }} disabled={workbook?.SheetNames.length === 1}>
                    <InputLabel id="tab-field-label">{t("WorkSheet")}</InputLabel>
                    <Select
                      labelId="tab-field-label"
                      id="tab-field"
                      value={tab}
                      label={t("WorkSheet")}
                      onChange={handleChangeTab}
                      disabled={workbook?.SheetNames.length === 1}
                    >
                      {workbook?.SheetNames
                        .map((tab, index) => (<MenuItem key={index} value={index}>{tab}</MenuItem>))
                      }
                    </Select>
                    {errors.tab && <FormHelperText error={true}>{t("Required")}</FormHelperText>}
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl sx={{ minWidth: "20vw" }} disabled={tab === ""}>
                    <InputLabel id="top-row-label">{t("ImportDataTopRow")}</InputLabel>
                    <Select
                      labelId="top-row-label"
                      id="top-row-field"
                      value={topRow}
                      label={t("ImportDataTopRow")}
                      onChange={handleChangeTopRow}
                      disabled={tab === ""}
                    >
                      {sheetData?.map((_, index) => (<MenuItem key={index} value={index}>{index + 1}</MenuItem>))
                      }
                    </Select>
                    {errors.topRow && (
                      <FormHelperText error={true}>{t("Required")}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item>
                  <Grid container alignItems="center">
                    <Grid item>
                      <FormControl sx={{ minWidth: "20vw" }} >
                        <FormControlLabel control={
                          <Switch
                            checked={hasHeaderRow}
                            onChange={handleHasHeaderRowChange}
                            disabled={tab === ""}
                          />
                        } label={t("TopRowContainsHeaders")} />
                      </FormControl>
                    </Grid>
                    <Grid item>
                      <Tooltip
                        title={t("TopRowContainsHeadersHelp")}
                        arrow
                      >
                        <HelpOutlineIcon fontSize="small" sx={{ display: 'block', margin: 'auto' }} />
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {tab !== "" &&
              <>
                <Grid item xs={12}>
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: 500,
                      color: "text.secondary",
                    }}
                  >
                    {t("SpreadsheetImportColumns") + ":"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{ mb: 1 }}>
                  <Grid container spacing={2} alignItems="center" justifyContent="flex-start">
                    {importDataNames.map((name, index) =>
                      <Grid item key={index}>
                        <FormControl fullWidth disabled={tab === ""}>
                          <InputLabel id={`data-column-label-${index}`}>{name.label}</InputLabel>
                          <Select
                            labelId={`data-column-label-${index}`}
                            id={`data-column-${index}`}
                            value={dataColumns[index] ?? ""}
                            label={name.label}
                            onChange={(event) => handleChangeDataColumn(index, event.target.value)}
                            disabled={tab === ""}
                            sx={{
                              minWidth: "15vw"
                            }}
                          >
                            {hasHeaderRow ?
                              (sheetData[topRow]?.map((colHeading, index) => (
                                <MenuItem
                                  key={colHeading} value={index}
                                >
                                  {colHeading}
                                </MenuItem>
                              ))) :
                              (columnHeaders?.map((col, index) => (
                                <MenuItem key={index} value={index}>
                                  {col}
                                </MenuItem>)))
                            }
                          </Select>
                          {errors.dataColumns[index] && (
                            <FormHelperText error={true}>{t("Required")}</FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </>
            }
            {tab !== "" &&
              <Grid item xs={12}>
                <TableContainer
                  component={Paper}
                  sx={{
                    maxHeight: "25vh",
                    overflow: "auto",
                    boxShadow: "none",
                    mb: 2
                  }}
                >
                  <Table stickyHeader size="small">
                    <TableHead>
                      <TableRow sx={{
                        "& .MuiTableCell-root": {
                          backgroundColor: "#f5f5f5",
                          fontSize: "0.8rem"
                        }
                      }}>
                        <TableCell
                          key="row-number-space"
                          sx={{
                            position: "sticky",
                            left: 0,
                            zIndex: 2,
                            backgroundColor: "#f5f5f5",
                            fontSize: "0.8rem"
                          }}
                        />
                        {columnHeaders.map((cell, index) =>
                          <TableCell
                            key={`column-header-${index}`}
                            sx={{
                              textAlign: "center",
                              fontWeight: "bold",
                              zIndex: 1,
                              fontSize: "0.8rem"
                            }}
                          >
                            {cell}
                          </TableCell>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sheetData?.slice(0).map((row, index) =>
                        <TableRow key={index}>
                          <TableCell key={`row-number-${index + 1}`} sx={{
                            position: "sticky",
                            left: 0,
                            zIndex: 1,
                            backgroundColor: "#f5f5f5",
                            fontWeight: "bold",
                            fontSize: "0.8rem"
                          }}>
                            {index + 1}
                          </TableCell>
                          {Array.from({ length: sheetWidth }, (_, colIndex) => (
                            <TableCell
                              key={`R${index + 1}C${colIndex + 1}`}
                              sx={{
                                border: "1px solid #ddd",
                                fontSize: "0.8rem"
                              }}
                            >
                              {row[colIndex] === undefined ? " " : row[colIndex]}
                            </TableCell>
                          ))}
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            }
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseImportDialog}
            color="secondary"
            variant="contained"
          >
            {t("Cancel")}
          </Button>
          <Button
            onClick={handleImport}
            color="primary"
            variant="contained"
          >
            {t("Import")}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default ImportSpreadsheet;