import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
} from "@mui/material";
import DashboardTable, { isStatusWaiting, setRowStatusWaiting } from "./DashboardTable";
import { useNavigate } from "react-router-dom";
import { Waiting } from "../Waiting";
import { t } from "i18next";
import { useFormik } from "formik";
import { useAppContext } from "../../context/AppContext";
import { TestInstanceStatus } from "../../graphql/enums";
import { useQuery, useMutation, useSubscription } from "@apollo/client";
import { apolloSubClient } from "../../identity";
import { handleCacheUpdate } from "../../cache";
import { GET_TEST_INSTANCES_FOR_ME_TO_MARK } from "../../graphql/queries";
import { ON_TESTINSTANCEFORMETOMARKUPDATE_SUBSCRIPTION } from "../../graphql/subscriptions";
import {
  ACCEPT_JUROR_ASSIGNMENT,
  REJECT_JUROR_ASSIGNMENT,
} from "../../graphql/mutations";
import { useCustomSnackbar } from "../CustomSnackbar";
import DashboardReadyToReviewDrawer from "./DashboardReadyToReviewDrawer"

export default function DashboardAssignedByMe(props) {
  const { actions, store } = useAppContext();
  const navigate = useNavigate();
  const { lsEnqueueSnackbar } = useCustomSnackbar();
  const [assignmentsData, setAssignmentsData] = useState([]);

  const [openRejectAssignmentDialog, setOpenRejectAssignmentDialog] =
    useState(false);
  const [openAcceptAssignmentDialog, setOpenAcceptAssignmentDialog] =
    useState(false);

  function handleOpenAcceptAssignmentDialog() {
    setOpenAcceptAssignmentDialog(true);
  }

  function handleCloseAcceptAssignmentDialog() {
    setOpenAcceptAssignmentDialog(false);
  }

  function handleOpenRejectAssignmentDialog() {
    setOpenRejectAssignmentDialog(true);
  }

  function handleCloseRejectAssignmentDialog() {
    setOpenRejectAssignmentDialog(false);
  }

  const assignmentsHeaders = [
    { key: "assignee", value: t("Assignee"), sortable: true, type: "string" },
    { key: "testName", value: t("TestName"), sortable: true, type: "string" },
    { key: "markingDueDate", value: t("MarkingDueDate"), sortable: true, type: "date" },
    { key: "status", value: t("Status"), sortable: true, type: "string" }
  ];

  // Fetch test assignments
  const {
    data: tmData,
    loading: tmLoading,
    refetch: refreshAssignedTestInstances,
  } = useQuery(GET_TEST_INSTANCES_FOR_ME_TO_MARK, {
    variables: {
      options: {
        filtering: [
          {
            field: 'status',
            operation: 'eq',
            values: {
              stringValues: [
                'unmarked'
              ]
            }
          }
        ]
      }
    },
    onError: (e) => {
      lsEnqueueSnackbar(e.message, {
        variant: "error",
      });
    },
  });

  useSubscription(ON_TESTINSTANCEFORMETOMARKUPDATE_SUBSCRIPTION, {
    shouldResubscribe: true,
    fetchPolicy: "no-cache",
    client: apolloSubClient,
    onSubscriptionData: (options) => {
      let payload = options.subscriptionData.data.onTestInstanceForMeToMarkUpdate;
      handleCacheUpdate(
        payload.objectUpdate.action,
        options.client,
        payload.testInstance,
        "id",
        GET_TEST_INSTANCES_FOR_ME_TO_MARK,
        "getTestInstancesForMeToMark",
        null,
        {
          options: {
            filtering: [
              {
                field: 'status',
                operation: 'eq',
                values: {
                  stringValues: [
                    'unmarked'
                  ]
                }
              }
            ]
          }
        },
        payload.objectUpdate.message,
        lsEnqueueSnackbar
      );
    },
  });

  const [rejectJurorAssignment] = useMutation(REJECT_JUROR_ASSIGNMENT, {
    update: (cacheClient, response) => {
      if (response?.data) {
        handleCacheUpdate(
          "delete",
          cacheClient,
          response.data.rejectJurorAssignment,
          "id",
          GET_TEST_INSTANCES_FOR_ME_TO_MARK,
          "getTestInstancesForMeToMark",
          null,
          {
            options: {
              filtering: [
                {
                  field: 'status',
                  operation: 'eq',
                  values: {
                    stringValues: [
                      'unmarked'
                    ]
                  }
                }
              ]
            }
          }
        );
      }
    }
  });

  const [acceptJurorAssignment] = useMutation(ACCEPT_JUROR_ASSIGNMENT, {
    update: (cacheClient, response) => {
      if (response?.data) {
        handleCacheUpdate(
          "update",
          cacheClient,
          response.data.acceptJurorAssignment,
          "id",
          GET_TEST_INSTANCES_FOR_ME_TO_MARK,
          "getTestInstancesForMeToMark",
          null,
          {
            options: {
              filtering: [
                {
                  field: 'status',
                  operation: 'eq',
                  values: {
                    stringValues: [
                      'unmarked'
                    ]
                  }
                }
              ]
            }
          }
        );
        // setRowStatusWaiting(assignmentsData, setAssignmentsData, response.data.acceptJurorAssignment, false);
      }
    },
  });

  const handleRefreshAssignedTestInstances = () => {
    refreshAssignedTestInstances();
  };

  const handleRowActionClick = (action, row) => {
    switch (action) {
      case "acceptAssignment":
        handleAcceptAssignment(row.instance);
        break;
      case "rejectAssignment":
        handleRejectAssignment(row.instance);
        break;
      case "viewResults":
        handleViewResults(row.instance);
        break;
      case "markTest":
        handleMarkTest(row.instance);
        break;

      default:
        console.log(action, row);
    }
  };

  const getScorecard = useCallback((testInstance) => {
    return testInstance?.scorecards?.find((sc) => {
      return sc.juror.id === store.userDetails.user.id &&
        ['cancelled', 'cancelled_pending', 'inactive'].indexOf(sc.status) === -1;
    });
  }, [store.userDetails.user.id]);

  /**
   * Generate row level actions corresponding to the assigned tests data provided.
   *
   * @param {TestInstanceStatus} status
   * @returns {Array} actions
   */
  const getAssignmentsTableActions = useCallback((status, testInstance) => {
    let scorecard = getScorecard(testInstance);
    switch (status) {
      case TestInstanceStatus.Assigned:
        return [];
      case TestInstanceStatus.Unmarked:
        if (scorecard?.status === "assigned") {
          return [
            {
              key: "acceptAssignment",
              value: t("Accept"),
            },
            { key: "rejectAssignment", value: t("Reject"), color: "error" },
          ];
        } else if (scorecard?.status === "active") {
          return [{ key: "markTest", value: t("MarkTest") }];
        } else {
          return [{ key: "pending", value: t("Pending") }];
        }
      case TestInstanceStatus.Marked:
        return [{ key: "viewResults", value: t("ViewResults") }];
      default:
        return [];
    }
  }, [getScorecard]);


  // Create the table rows for assignments
  useEffect(() => {
    const assignmentsRows = tmData?.getTestInstancesForMeToMark?.filter((testInstance) => {
      return testInstance.status === 'unmarked';
    })
      .map(
        (testInstance) => {
          const { id, assignee, invitee, testTemplate, status } = testInstance;
          let scorecard = getScorecard(testInstance);

          return {
            id,
            statusPending: scorecard?.status.includes("_pending") || scorecard?.status === 'created',
            statusWaiting: isStatusWaiting(assignmentsData, testInstance),
            fields: [
              {
                key: "assignee",
                value: assignee
                  ? `${assignee?.firstname} ${assignee?.lastname}`
                  : `${invitee?.firstname} ${invitee?.lastname}`,
                type: "string"  
              },
              { key: "testName", value: testTemplate?.name, type: "string" },
              { key: "markingDueDate", value: scorecard?.targetCompletionDate, type: "date"},
              { key: "status", value: t(status), type: "string" },
            ],
            actions: getAssignmentsTableActions(status, testInstance),
            instance: testInstance,
          };
        }
      );
    setAssignmentsData(assignmentsRows);
  }, [tmData, getAssignmentsTableActions, getScorecard]);

  const handleViewResults = (instance) => {
    actions.setCurrentTestInstance(instance);
    navigate("/app/reviewtest");
  };

  const handleMarkTest = (instance) => {
    actions.setCurrentTestInstance(instance);
    navigate("/app/marktest");
  };

  const handleAcceptAssignment = (instance) => {
    handleOpenAcceptAssignmentDialog();
    formik.setFieldValue("orgId", instance.testTemplate.org.id);
    formik.setFieldValue("instanceId", instance.id);
  };

  const handleRejectAssignment = (instance) => {
    handleOpenRejectAssignmentDialog();
    formik.setFieldValue("orgId", instance.testTemplate.org.id);
    formik.setFieldValue("instanceId", instance.id);
    formik.setFieldValue("scorecard", instance.scorecards.find((sc) => {
      return sc.juror.id === store.userDetails.user.id &&
        ['assigned', 'active', 'created', 'active_pending'].indexOf(sc.status) >= 0;
    }));
  };

  const formik = useFormik({
    initialValues: {},
    onSubmit: async (values, { setSubmitting }) => {
      const vars = {
        variables: {
          orgId: values.orgId,
          instanceId: values.instanceId
        },
      };

      if (openAcceptAssignmentDialog) {
        acceptJurorAssignment(vars)
          .then((res) => {
            if (res.errors) {
              lsEnqueueSnackbar(res.errors[0].message, {
                variant: "error",
              });
              setSubmitting(false);
            } else {
              lsEnqueueSnackbar(t("AssignmentAccepted"), {
                variant: "success",
              });
              setSubmitting(false);
              setOpenAcceptAssignmentDialog(false);
            }
          })
          .catch((e) => {
            lsEnqueueSnackbar(t("ErrorWithAcceptingAssignment"), {
              variant: "error",
            });
            setSubmitting(false);
            setOpenAcceptAssignmentDialog(false);
          }); // catch network errors
      } else if (openRejectAssignmentDialog) {
        vars.variables.scorecardId = values.scorecard.id;
        rejectJurorAssignment(vars)
          .then((res) => {
            if (res.errors) {
              lsEnqueueSnackbar(res.errors[0].message, {
                variant: "error",
              });
              setSubmitting(false);
            } else {
              lsEnqueueSnackbar(t("AssignmentRejected"), {
                variant: "success",
              });
              setSubmitting(false);
              setOpenRejectAssignmentDialog(false);
            }
          })
          .catch((e) => {
            lsEnqueueSnackbar(t("ErrorWithRejectingAssignment"), {
              variant: "error",
            });
            setSubmitting(false);
            setOpenRejectAssignmentDialog(false);
          }); // catch network errors
      }
    },
  });

  let pageContents;
  if (tmLoading) {
    pageContents = <Waiting />;
  } else {
    pageContents = (
      <div
        role="tabpanel"
        hidden={props.activeTab !== 2}
        id="tabpanel-2"
        aria-labelledby="tab-2"
      >
        <Box>
          <DashboardTable
            title={t("JurorHeader")}
            headers={assignmentsHeaders}
            data={assignmentsData}
            onRowActionClick={handleRowActionClick}
            isLoading={tmLoading}
            emptyText={t("NoTestsToReviewMessage")}
            onRefresh={handleRefreshAssignedTestInstances}
            isCollapsible={true}
            drawerContents={(row) =>
              <DashboardReadyToReviewDrawer
                testInstance={row.instance}
              />
            }
            defaultOrderBy="markingDueDate"
            defaultOrder="asc"
            defaultOrderType="date"
          />
        </Box>
        <Dialog
          open={openRejectAssignmentDialog}
          onClose={handleCloseRejectAssignmentDialog}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            {t("RejectAssignmentTitle")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t("RejectAssignmentExplanation")}
            </DialogContentText>
            <Box sx={{ paddingTop: 3 }}>
              <form onSubmit={formik.handleSubmit}>
                <TextField
                  name="orgId"
                  sx={{ display: "none" }}
                  id="orgId"
                  value={formik.values.orgId}
                />
                <TextField
                  name="instanceId"
                  sx={{ display: "none" }}
                  id="instanceId"
                  value={formik.values.instanceId}
                />
              </form>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseRejectAssignmentDialog}
              color="secondary"
              variant="contained"
            >
              {t("Cancel")}
            </Button>
            <Button
              onClick={formik.handleSubmit}
              color="primary"
              variant="contained"
            >
              {t("Confirm")}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openAcceptAssignmentDialog}
          onClose={handleCloseAcceptAssignmentDialog}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            {t("AcceptAssignmentTitle")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t("AcceptAssignmentExplanation")}
            </DialogContentText>
            <Box sx={{ paddingTop: 3 }}>
              <form onSubmit={formik.handleSubmit}>
                <TextField
                  name="orgId"
                  sx={{ display: "none" }}
                  id="orgId"
                  value={formik.values.orgId}
                />
                <TextField
                  name="instanceId"
                  sx={{ display: "none" }}
                  id="instanceId"
                  value={formik.values.instanceId}
                />
              </form>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseAcceptAssignmentDialog}
              color="secondary"
              variant="contained"
            >
              {t("Cancel")}
            </Button>
            <Button
              onClick={formik.handleSubmit}
              color="primary"
              variant="contained"
            >
              {t("Confirm")}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  return pageContents;
}
