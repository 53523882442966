import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions, 
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  TextField,
  Tooltip
} from "@mui/material";

import { useTranslation } from "react-i18next";
import { getCurrentLanguage } from "../../../App";
import ConfirmationForm from "../../../components/ConfirmationForm";
import Editor, { useMonaco } from '@monaco-editor/react';


export default function EditOrgEmailTemplateForm({
  emailMessage, 
  emailSubject, 
  emailMessageForEdit, 
  setEmailMessageForEdit,
  emailSubjectForEdit, 
  setEmailSubjectForEdit,
  selectedOET, 
  oetData,
  inEditMode, 
  setInEditMode,
  handleUpdateEmailTemplate
}) {
  const { t } = useTranslation();
  const [selectedParm, setSelectedParm] = useState(null); // selected parameter
  const [isEmailBodyFocused, setIsEmailBodyFocused] = useState(false);
  const [isParametersFocused, setIsParametersFocused] = useState(false);
  const [lastFocusedElement, setLastFocusedElement] = useState(null); // Track the last focused element

  const [editor, setEditor] = useState(null);

  const [confirmRevertToSystemDefault, setConfirmRevertToSystemDefault] = useState(false);

  const handleEmailBodyFocus = useCallback(() => {
    setIsEmailBodyFocused(true);
    setLastFocusedElement("emailBody");
  }, []);
  const handleEmailSubjectFocus = useCallback(() => setLastFocusedElement("emailSubject"), []);
  const handleEmailBodyBlur = useCallback(() => setIsEmailBodyFocused(false), []);
  const handleParametersFocus = useCallback(() => setIsParametersFocused(true), []);
  const handleParametersBlur = useCallback(() => setIsParametersFocused(false), []);

  const monaco = useMonaco();
  const textFieldRef = useRef(null);


  useEffect(() => {
    if (monaco && editor) {
      const handleFocus = () => handleEmailBodyFocus();
      const handleBlur = () => handleEmailBodyBlur();

      // Attach focus and blur event listeners
      editor.onDidFocusEditorText(handleFocus);
      editor.onDidBlurEditorText(handleBlur);
    }
  }, [monaco, editor, handleEmailBodyFocus, handleEmailBodyBlur]);

  const handleEditorDidMount = (createdEditor) => {
    setEditor(createdEditor);
  };

  const handleExitEditMode = () => {
    setInEditMode(false);
  };

  const handleDoubleClickParm = (event, parm) => {
    insertParm(parm);
  }

  const insertParm = useCallback((parm) => {
    if (parm) {
      const parameterText = `{{${parm.key}}}`;

      if (lastFocusedElement === "emailSubject") {
        const input = textFieldRef.current;
        const cursorPosition = input.selectionStart;
        const newValue = emailSubjectForEdit.slice(0, cursorPosition) + parameterText +
          emailSubjectForEdit.slice(cursorPosition);
        setEmailSubjectForEdit(newValue);

        // Refocus the input field and set the cursor position
        setTimeout(() => {
          input.focus();
          input.setSelectionRange(
            cursorPosition + parameterText.length,
            cursorPosition + parameterText.length
          );
        }, 0);
      } else if (lastFocusedElement === "emailBody") {
        const position = editor.getPosition();
        const range = new monaco.Range(
          position.lineNumber,
          position.column,
          position.lineNumber,
          position.column
        );
        editor.executeEdits("", [
          {
            range,
            text: parameterText,
            forceMoveMarkers: true,
          },
        ]);

        // Refocus the editor
        setTimeout(() => {
          editor.focus();
        }, 0);
      }
    }
  }, [editor, emailSubjectForEdit, lastFocusedElement, monaco, setEmailSubjectForEdit]);

  const handleInsertParm = useCallback(() => insertParm(selectedParm), [insertParm, selectedParm]);

  const handleSelectParm = (event, parm) => {
    setSelectedParm(parm);
  };

  const handleConfirmRevertToSystemDefaultClose = (response) => {
    setConfirmRevertToSystemDefault(false);

    if (response === 'Confirm') {
      setEmailSubjectForEdit(selectedOET.emailTemplate.subject);
      setEmailMessageForEdit(selectedOET.emailTemplate.body);
    }
  }

  const handleRevertToSystemDefault = () => {
    setConfirmRevertToSystemDefault(true);
  };

  let pageResults;
  if (oetData) {
    pageResults = (
      <>
        <Dialog
          open={inEditMode}
          onClose={handleExitEditMode}
          maxWidth="xl"
          fullWidth
        >
          <DialogTitle id="form-dialog-title">
            {t("EditEmailTemplate")}
          </DialogTitle>
          <DialogContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: 'calc(100vh - 64px - 48px - 16px)'
            }}
          >
            <Grid container spacing={2} justifyContent="flex-start" alignItems="flex-start">
              <Grid item xs={9}>
                <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
                  <Grid item xs={12}>
                    <FormControl fullWidth error={emailSubjectForEdit === ''}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        id="emailSubject"
                        label={t("EmailSubject")}
                        name="emailSubject"
                        value={emailSubjectForEdit}
                        onChange={(event) => setEmailSubjectForEdit(event.target.value)}
                        helperText={emailSubjectForEdit === '' ? t("EmailSubjectRequired") : ''}
                        FormHelperTextProps={{
                          sx: { color: emailSubjectForEdit === '' ? 'red' : 'inherit' }
                        }}
                        sx={{ mt: 2 }}
                        inputRef={textFieldRef}
                        onFocus={handleEmailSubjectFocus}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel shrink sx={{
                        position: 'absolute',
                        top: '15px',
                        left: '-5px',
                        backgroundColor: '#fff',
                        px: 1
                      }}>
                        {t("EmailBody")}
                      </InputLabel>
                      <Box sx={{
                        flexGrow: 1,
                        height: "50vh",
                        border: 1,
                        borderRadius: 1,
                        p: 2,
                        position: 'relative',
                        mt: 2,
                        borderColor: isEmailBodyFocused ? 'primary.main' : 'grey.500',
                        borderWidth: isEmailBodyFocused ? 2 : 1,
                        '&:hover': {
                          borderColor: isEmailBodyFocused ? 'primary.main' : 'black',
                        },

                      }}>
                        <Editor
                          height="100%"
                          width="100%"
                          defaultLanguage="handlebars"
                          value={emailMessageForEdit}
                          onChange={(msg) => setEmailMessageForEdit(msg)}
                          options={{
                            wordWrap: "on",
                            scrollBeyondLastLine: false,
                            automaticLayout: true
                          }}
                          onMount={handleEditorDidMount}
                        />
                      </Box>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Grid container spacing={2} direction="column" justifyContent="flex-start" alignItems="flex-end">
                  <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined" sx={{ position: 'relative' }}>
                      <InputLabel shrink sx={{
                        position: 'absolute',
                        top: '15px',
                        left: '-5px',
                        backgroundColor: '#fff',
                        px: 1
                      }}>
                        {t("EmailTemplateParameters")}
                      </InputLabel>
                      <Box sx={{
                        flexGrow: 1,
                        height: "300px",
                        border: 1,
                        borderRadius: 1,
                        p: 2,
                        position: 'relative',
                        mt: 2,
                        borderColor: isParametersFocused ? 'primary.main' : 'grey.500',
                        borderWidth: isParametersFocused ? 2 : 1,
                        '&:hover': {
                          borderColor: isParametersFocused ? 'primary.main' : 'black',
                        },
                      }}>
                        {
                          selectedOET &&
                          <List
                            sx={{
                              width: "100%",
                              bgcolor: "background.paper",
                              position: "relative",
                              overflow: "auto",
                              maxHeight: 300,
                            }}
                            component="nav"
                            onFocus={handleParametersFocus}
                            onBlur={handleParametersBlur}
                          >
                            {selectedOET.emailTemplate.parameters
                              .sort((a, b) => a.key.localeCompare(b.key))
                              .map((parm) => {
                                let toolTip = selectedOET.emailTemplate.language === getCurrentLanguage() ?
                                  parm.description :
                                  oetData.getOrgEmailTemplates.find((srcOET) =>
                                    srcOET.emailTemplate.key === selectedOET.emailTemplate.key &&
                                    srcOET.emailTemplate.language === getCurrentLanguage()).emailTemplate
                                    .parameters.find((srcParm) => srcParm.key === parm.key).description;
                                if (parm.isRequired) {
                                  toolTip += ` (${t("Required")})`;
                                }
                                return (
                                  <React.Fragment key={parm.key}>
                                    <ListItem sx={{
                                      height: 25,
                                    }}>
                                      <ListItemButton
                                        sx={{ height: 25 }}
                                        selected={selectedParm?.key === parm.key}
                                        onClick={(event) => handleSelectParm(event, parm)}
                                        onDoubleClick={(event) => handleDoubleClickParm(event, parm)}
                                      >
                                        <Tooltip title={toolTip} placement="right-start">
                                          <ListItemText
                                            primary={(parm.isRequired) ? <strong>{parm.key}</strong> : parm.key}
                                          />
                                        </Tooltip>
                                      </ListItemButton>
                                    </ListItem>
                                  </React.Fragment>
                                );
                              })}
                          </List>
                        }
                      </Box>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
                      <Grid item xs={12}>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={handleInsertParm}
                        >
                          {t("InsertEmailTemplateParameter")}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ pr: 3 }}>
            <Grid container spacing={2} justifyContent="space-evently" alignItems="center">
              <Grid item xs={4}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleRevertToSystemDefault}
                  disabled={
                    emailMessageForEdit === selectedOET?.emailTemplate.body &&
                    emailSubjectForEdit === selectedOET?.emailTemplate.subject}
                >
                  {t("RevertToSystemDefault")}
                </Button>
              </Grid>
              <Grid item xs={8}>
                <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
                  <Grid item>
                    <Button
                      onClick={handleExitEditMode}
                      color="secondary"
                      variant="contained"
                    >
                      {t("Cancel")}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      onClick={handleUpdateEmailTemplate}
                      color="primary"
                      variant="contained"
                      disabled={emailMessageForEdit === emailMessage && emailSubjectForEdit === emailSubject}
                    >
                      {t("Save")}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
        <Dialog
          open={confirmRevertToSystemDefault}
          onClose={handleConfirmRevertToSystemDefaultClose}
        >
          <ConfirmationForm
            handleConfirmationClose={handleConfirmRevertToSystemDefaultClose}
            dialogTitle={t("ConfirmRevertToSystemDefaultTitle")}
            dialogContent={t("ConfirmRevertToSystemDefaultText")}
          />
        </Dialog>
      </>
    );
  } else {
    pageResults = "";
  }
  return <div>{pageResults}</div>;
}