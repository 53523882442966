import { Button, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../context/AppContext";
import { useCustomSnackbar } from "../../components/CustomSnackbar";
import { useMutation } from "@apollo/client";
import { GET_TEST_INSTANCES_ASSIGNED_TO_ME } from "../../graphql/queries";
import { REJECT_TEST_INSTANCE } from "../../graphql/mutations";
import { handleCacheUpdate } from "../../cache";
import useGuardedRoute from "../../hooks/useGuardedRoute";

const RejectTestConfirmation = ({ mode }) => {
  const { t } = useTranslation();
  const { lsEnqueueSnackbar } = useCustomSnackbar();
  const navigate = useNavigate();

  const { store } = useAppContext();

  const [rejectTestInstance] = useMutation(REJECT_TEST_INSTANCE, {
    update: (cacheClient, response) => {
      if (response?.data) {
        handleCacheUpdate(
          "delete",
          cacheClient,
          response.data.rejectTestInstance,
          "id",
          GET_TEST_INSTANCES_ASSIGNED_TO_ME,
          "getTestInstancesAssignedToMe",
          null,
          {}
        );
      }
    },
  });

  useGuardedRoute("/app", [store.currentTestInstance]);

  const handleConfirm = () => {
    rejectTestInstance({
      variables: {
        orgId: store.currentTestInstance?.testTemplate.org.id,
        instanceId: store.currentTestInstance.id,
      },
    })
      .then((res) => {
        if (res.errors) {
          lsEnqueueSnackbar(res.errors[0].message, {
            variant: "error",
          });
        } else {
          lsEnqueueSnackbar(
            `${store.currentTestInstance.testTemplate.name +
            " " +
            t("TestDeclinedSuccessfully")
            }`,
            {
              variant: "success",
            }
          );
          navigate("/app");
        }
      })
      .catch((e) => {
        lsEnqueueSnackbar(e.message, {
          variant: "error",
        });
      }); // catch network errors
  };

  let pageHeading = "",
    pageBody = "";

  switch (mode) {
    case "decline":
      pageHeading = t("DeclineTestHeading");
      pageBody = (
        <Typography component="p" sx={{ marginBottom: 3 }}>
          {t("DeclineTestBody")}
        </Typography>
      );
      break;
    case "forfeit":
      pageHeading = t("ForfeitTestHeading");
      pageBody = (
        <Typography component="p" sx={{ marginBottom: 3 }}>
          {t("ForfeitTestBody")}
        </Typography>
      );
      break;
    case "discard":
      pageHeading = t("DiscardTestHeading");
      pageBody = (
        <Typography component="p" sx={{ marginBottom: 3 }}>
          {t("DiscardTestBody")}
        </Typography>
      );
      break;
    case "revoke":
      pageHeading = t("RevokeTestHeading");
      pageBody = (
        <Typography component="p" sx={{ marginBottom: 3 }}>
          {t("RevokeTestBody")}
        </Typography>
      );
      break;

    default:
      break;
  }

  return (
    <Grid container spacing={2} paddingLeft="20%" paddingRight="20%">
      <Grid item xs={12}>
        <Typography
          component="h1"
          variant="h5"
          sx={{ marginBottom: 3, fontWeight: "bold" }}
        >
          {pageHeading}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        {pageBody}
      </Grid>

      <Grid container spacing={2} justifyContent="flex-end">
        <Grid item>
          <Button
            variant="contained"
            color="secondary"
            sx={{ marginTop: 3, marginRight: 0, marginBottom: 1.5 }}
            onClick={() => navigate(-1)}
          >
            {t("Cancel")}
          </Button>
        </Grid>
        <Grid item>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ marginTop: 3, marginRight: 0, marginBottom: 1.5 }}
            onClick={handleConfirm}
          >
            {t("Confirm")}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RejectTestConfirmation;
