import React, { useEffect, useCallback, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  AppBar,
  Button,
  Menu,
  MenuItem,
  IconButton,
  Toolbar,
  Box,
  Drawer,
  Grid,
  Avatar,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import LanguageIcon from "@mui/icons-material/Language";
import BusinessIcon from "@mui/icons-material/Business";
import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupsIcon from "@mui/icons-material/Groups";
import CollectionsIcon from "@mui/icons-material/Collections";
import LogoutIcon from "@mui/icons-material/Logout";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useTranslation } from "react-i18next";
import { clearToken, getAccessToken, apolloSubClient } from "./identity";
import { useMutation, useSubscription } from "@apollo/client";
import { SIGN_OUT, UPDATE_USER_PROFILE } from "./graphql/mutations";
import { ON_USERPROFILEUPDATE_SUBSCRIPTION } from "./graphql/subscriptions";
import { useAppContext } from "./context/AppContext";
import { Roles } from "./components/Dashboard/DashboardTabs";

export default function MainAppBar(props) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  // const [mobileOpen, setMobileOpen] = useState(false);

  // const handleDrawerToggle = () => {
  //   setMobileOpen(!mobileOpen);
  // };

  const handleDrawerOpen = () => {
    props.setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    props.setDrawerOpen(false);
  };

  const { actions, store } = useAppContext();

  const drawerWidth = props.signedIn && props.drawerOpen ? 270 : 0;
  // const container = window !== undefined ? () => window().document.body : undefined;

  // Change language on use of language selection menu
  const handleChangeLanguage = (event) => {
    i18n.changeLanguage(event);
    props.setCurrentLanguage(event);
    // if the user is signed in, update their language in their user profile
    if (props.signedIn) {
      updateUserProfile({
        variables: { up: { language: event } },
      });
    }
  };

  // Change org on use of org selection menu
  const handleChangeOrg = (event) => {
    if (
      props.signedIn &&
      (!store.currentOrg || event.org.id !== store.currentOrg.org.id)
    ) {
      actions.setCurrentOrg(event);
      updateUserProfile({
        variables: { up: { defaultorg: event.org.id } },
      });
    }
  };

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  function handleOpenDialog() {
    setOpenConfirmationDialog(true);
  }

  function handleCloseDialog() {
    setOpenConfirmationDialog(false);
  }

  // set up the update User Profile API to set the language
  const [updateUserProfile] = useMutation(UPDATE_USER_PROFILE);

  // Handle opening and closing of dropdown menus
  const [anchorElTranslate, setAnchorElTranslate] = useState(null);
  const [anchorElOrg, setAnchorElOrg] = useState(null);
  const [anchorElAcct, setAnchorElAcct] = useState(null);

  const handleClickTranslate = (event) => {
    setAnchorElTranslate(event.currentTarget);
  };

  const handleClickOrg = (event) => {
    setAnchorElOrg(event.currentTarget);
  };

  const handleClickAcct = (event) => {
    setAnchorElAcct(event.currentTarget);
  };

  const handleCloseTranslate = () => {
    setAnchorElTranslate(null);
  };

  const handleCloseOrg = () => {
    setAnchorElOrg(null);
  };

  const handleCloseAcct = () => {
    setAnchorElAcct(null);
  };

  // set up the SignOut API
  const [signOut] = useMutation(SIGN_OUT);

  const setCurrentLanguage = props.setCurrentLanguage;
  const currentLanguage = props.getCurrentLanguage();
  const currentAvatar = store.userDetails.user.avatar;
  const resetAppState = props.resetAppState;

  let currOrg = store.currentOrg;
  let defOrg = store.userDetails.user.defaultorg;
  let userRights = store.userDetails.user.rights;

  const handleSignOut = useCallback(async () => {
    await signOut({ variables: { at: getAccessToken() } });
    clearToken();
    actions.setCurrentOrg(null);
    resetAppState();
    navigate("/app");
  }, [navigate, signOut, resetAppState, actions]);

  useEffect(() => {
    if (!currOrg && defOrg) {
      let org = userRights.find((o) => {
        return o.org.id === defOrg;
      });
      actions.setCurrentOrg(org);
    }
  }, [currOrg, defOrg, userRights, actions.setCurrentOrg, actions]);

  // set up the onUserProfileUpdate Subscription hook
  useSubscription(ON_USERPROFILEUPDATE_SUBSCRIPTION, {
    shouldResubscribe: true,
    fetchPolicy: "no-cache",
    client: apolloSubClient,
    onSubscriptionData: ({ subscriptionData }) => {
      const onUPData = subscriptionData.data;
      if (onUPData) {
        let newUserDetails = { ...store.userDetails };
        for (let UPupdate of onUPData.onUserProfileUpdate) {
          switch (UPupdate.name) {
            case "avatar":
              if (currentAvatar !== UPupdate.value) {
                newUserDetails.avatar = UPupdate.value;
                actions.setUserDetails(newUserDetails);
              }
              break;
            case "language":
              if (UPupdate.value !== currentLanguage) {
                newUserDetails.language = UPupdate.value;
                i18n.changeLanguage(UPupdate.value);
                setCurrentLanguage(UPupdate.value);
                actions.setUserDetails(newUserDetails);
              }
              break;
            default:
              newUserDetails[UPupdate.name] = UPupdate.value;
              actions.setUserDetails(newUserDetails);
          }
        }
      }
    },
  });

  const forceSignOut = props.forceSignOut;
  const setForceSignOut = props.setForceSignOut;
  useEffect(() => {
    if (forceSignOut) {
      handleSignOut();
      setForceSignOut(false);
    }
  }, [forceSignOut, setForceSignOut, handleSignOut]);

  // Determine if certain elements in appbar should be active depending on how user arrives at page
  const controlsActive =
    window.location.pathname !== "/app/signuptaketest" &&
    window.location.pathname !== "/app/signupstaff";
  let alt = props.siteConfigRef.current.siteName;
  let appbarLogo;

  let displayLogo;
  if (props.siteConfigRef.current.orgId && !props.logo) {
    displayLogo = (
      <Typography variant="h5" sx={{ color: "black", textDecoration: "none" }}>
        {alt}
      </Typography>
    );
  } else {
    displayLogo = <img src={props.logo} alt={alt} height="30" />;
  }
  if (controlsActive) {
    appbarLogo = <Link to="/app">{displayLogo}</Link>;
  } else {
    appbarLogo = displayLogo;
  }

  let appbarContents;
  if (!props.signedIn) {
    if (controlsActive) {
      appbarContents = (
        <React.Fragment>
          &nbsp;&nbsp;
          <Link to="/app/signin">{t("SignIn")}</Link>
          &nbsp; &nbsp; &nbsp;
          <Button
            color="primary"
            variant="contained"
            onClick={() => navigate("/app/signup")}
          // disabled={true}
          >
            {t("SignUp")}
          </Button>
        </React.Fragment>
      );
    } else {
      appbarContents = <span></span>;
    }
  } else {
    appbarContents = <span></span>;
  }

  let navContents;
  if (props.signingIn || !props.signedIn) {
    navContents = "";
  } else {
    navContents = (
      <React.Fragment>
        <Grid
          container
          spacing={2}
          alignContent="flex-start"
          sx={{ height: "50%" }}
        >
          <Grid item xs={12}>
            <List>
              <ListItemButton onClick={() => navigate("/app")}>
                <ListItemIcon>
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText
                  primary={t("ManageTests")}
                  sx={{ color: "common.black" }}
                />
              </ListItemButton>
              <React.Fragment>
                <ListItem>
                  <ListItemIcon>
                    <GroupsIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={t("ManageOrganization")}
                    sx={{ color: "common.black" }}
                  />
                </ListItem>
                <ListItemButton
                  sx={{ pl: 9 }}
                  onClick={() => navigate("/app/createorganization")}
                >
                  <ListItemText
                    secondary={t("CreateOrg")}
                    sx={{ color: "common.black" }}
                  />
                </ListItemButton>
                {store.currentOrg && store.currentOrg.roles.find((r) => r.name === Roles.Owner) &&
                  (<>
                    <ListItemButton
                      sx={{ pl: 9 }}
                      onClick={() => navigate("/app/organization")}
                    >
                      <ListItemText
                        secondary={t("ConfigureOrganization")}
                        sx={{ color: "common.black" }}
                      />
                    </ListItemButton>
                    <ListItemButton
                      sx={{ pl: 9 }}
                      onClick={() => navigate("/app/invitestaff")}
                    >
                      <ListItemText
                        secondary={t("InviteToOrganization")}
                        sx={{ color: "common.black" }}
                      />
                    </ListItemButton>
                    <ListItemButton
                      sx={{ pl: 9 }}
                      onClick={() => navigate("/app/users")}
                    >
                      <ListItemText
                        secondary={t("AssignRoles")}
                        sx={{ color: "common.black" }}
                      />
                    </ListItemButton>
                  </>)
                }
              </React.Fragment>
              {store.currentOrg && store.currentOrg.rights.indexOf("editTest") > -1 ? (
                <React.Fragment>
                  <ListItemButton onClick={() => navigate("/app/assets")}>
                    <ListItemIcon>
                      <CollectionsIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={t("ManageTestAssets")}
                      sx={{ color: "common.black" }}
                    />
                  </ListItemButton>
                </React.Fragment>
              ) : (
                ""
              )}
            </List>
          </Grid>
        </Grid>
        <Grid container alignContent="flex-end" sx={{ height: "50%" }}>
          <Grid item xs={12}>
            <List>
              <Divider />
            </List>
          </Grid>
          <Grid
            container
            alignItems="center"
            sx={{ paddingTop: 1, paddingBottom: 2 }}
          >
            <Grid item>
              <Avatar
                alt={
                  store.userDetails.user.firstname +
                  " " +
                  store.userDetails.user.lastname
                }
                sx={{ objectFit: "cover", marginLeft: 1 }}
                src={store.userDetails.user.avatar}
              />
            </Grid>
            <Grid item>
              <Menu
                id="acctmenu"
                anchorEl={anchorElAcct}
                keepMounted
                open={Boolean(anchorElAcct)}
                onClose={handleCloseAcct}
              >
                <MenuItem
                  key={store.userDetails.id}
                  onClick={() => {
                    handleCloseAcct();
                    navigate("/app/account");
                  }}
                >
                  {t("AccountDetails")}
                </MenuItem>
              </Menu>
              <Typography sx={{ paddingLeft: 1 }} onClick={handleClickAcct}>
                {store.userDetails.user.firstname +
                  " " +
                  store.userDetails.user.lastname}
              </Typography>
            </Grid>
            <Grid item sx={{ flexGrow: 1 }}>
              &nbsp;
            </Grid>
            <Grid item>
              <IconButton
                onClick={() => {
                  handleOpenDialog();
                }}
              >
                <LogoutIcon />
              </IconButton>
              <Dialog
                open={openConfirmationDialog}
                onClose={handleCloseDialog}
                aria-labelledby="form-dialog-title"
              >
                <DialogTitle id="form-dialog-title">
                  {t("SignOutConfirmation")}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    {t("SignOutConfirmationExplanation")}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={handleCloseDialog}
                    color="secondary"
                    variant="contained"
                  >
                    {t("Cancel")}
                  </Button>
                  <Button
                    onClick={handleSignOut}
                    color="primary"
                    variant="contained"
                  >
                    {t("Confirm")}
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }

  return (store.takingTest ? "" :
    <React.Fragment>
      <AppBar
        color="common"
        position="fixed"
        sx={{
          transition:
            (["margin", "width"],
            {
              easing: "sharp",
              duration: "leavingScreen",
            }),
          ...(props.drawerOpen && {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: `${drawerWidth}px`,
            transition:
              (["margin", "width"],
              {
                easing: "easeOut",
                duration: "enteringScreen",
              }),
          }),
        }}
      // sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar variant="dense">
          {props.signedIn ? (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerOpen}
              // onClick={handleDrawerToggle}
              sx={{ mr: 2, ...(props.drawerOpen && { display: "none" }) }}
            // sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
          ) : (
            ""
          )}
          <Box sx={{ flexGrow: 1, paddingTop: 1 }}>
            <Box sx={{ ...(props.drawerOpen && { display: "none" }) }}>
              {appbarLogo}
            </Box>
          </Box>
          <Avatar
            style={{ objectFit: "cover" }}
            sx={{ color: "primary.main", backgroundColor: "white" }}
            aria-controls="translatemenu"
            aria-haspopup="true"
            onClick={handleClickTranslate}
          >
            <LanguageIcon />
          </Avatar>
          <Menu
            id="translatemenu"
            anchorEl={anchorElTranslate}
            keepMounted
            open={Boolean(anchorElTranslate)}
            onClose={handleCloseTranslate}
          >
            <MenuItem
              onClick={() => {
                handleCloseTranslate();
                handleChangeLanguage("English");
              }}
            >
              {t("English")}
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleCloseTranslate();
                handleChangeLanguage("French");
              }}
            >
              {t("French")}
            </MenuItem>
          </Menu>
          <Typography
            onClick={handleClickTranslate}
            sx={{ display: { xs: "none", sm: "block" } }}
          >
            {i18n.language === "English" ? t("English") : t("French")}{" "}
          </Typography>
          &nbsp;&nbsp;
          {store.userDetails.user.rights && store.currentOrg ? (
            <React.Fragment>
              <Avatar
                style={{ objectFit: "cover" }}
                sx={{ color: "primary.main", backgroundColor: "white" }}
                aria-controls="orgmenu"
                aria-haspopup="true"
                onClick={handleClickOrg}
              >
                <BusinessIcon />
              </Avatar>
              <Menu
                id="orgmenu"
                anchorEl={anchorElOrg}
                keepMounted
                open={Boolean(anchorElOrg)}
                onClose={handleCloseOrg}
              >
                {store.userDetails.user.rights.map((o) => {
                  return (
                    <MenuItem
                      key={o.org.id}
                      onClick={() => {
                        handleCloseOrg();
                        handleChangeOrg(o);
                      }}
                    >
                      {o.org.name}
                    </MenuItem>
                  );
                })}
              </Menu>
              <Typography
                onClick={handleClickOrg}
                sx={{ display: { xs: "none", sm: "block" } }}
              >
                {props.orgName ? props.orgName : store.currentOrg.org.name}
              </Typography>
            </React.Fragment>
          ) : (
            ""
          )}
          {appbarContents}
        </Toolbar>
      </AppBar>
      {props.signedIn ? (
        // <Drawer
        //   variant="temporary"
        //   open={mobileOpen}
        //   onClose={handleDrawerToggle}
        //   ModalProps={{
        //     keepMounted: true, // Better open performance on mobile.
        //   }}
        //   sx={{
        //     display: { xs: "block", sm: "none" },
        //     "& .MuiDrawer-paper": {
        //       boxSizing: "border-box",
        //       width: drawerWidth,
        //     },
        //   }}
        // >
        //   {navContents}
        // </Drawer>
        <Drawer
          variant="persistent"
          anchor="left"
          sx={{
            width: drawerWidth,
            // flexShrink: 0,
            // display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open={props.drawerOpen}
        >
          <Toolbar variant="dense">
            <IconButton onClick={handleDrawerClose} sx={{ mr: 2 }}>
              <ChevronLeftIcon />
            </IconButton>
            <Box
              sx={{
                paddingTop: 1,
                alignItems: "left",
              }}
            >
              {displayLogo}
            </Box>
          </Toolbar>
          <Divider />
          {navContents}
        </Drawer>
      ) : (
        ""
      )}
    </React.Fragment>
  );
}
