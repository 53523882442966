import SnippetFolderIcon from "@mui/icons-material/SnippetFolder";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import { Collapse, IconButton, List, ListItemButton, ListItemIcon, ListItemText, TextField } from "@mui/material";
import { useState } from "react";
import LevelIndicator from "./LevelIndicator";
import useDroppable from "../../hooks/useDroppable";
import { useDrag } from "react-dnd";
import { FileSystemItemType } from "./FileSystem";
import { Check, Edit, FileDownload, UploadFile, Cancel, } from "@mui/icons-material";

const FolderAssetItem = ({
  children,
  item,
  level = 1,
  selected,
  onClick,
  onDrop,
  draggable,
  droppable,
  acceptTypes,
  onDelete,
  onUpload,
  onCreateFolder,
  onRenameFolder,
  onSetEditable,
  immutable,
  edited,
}) => {
  const [open, setOpen] = useState(true);
  const [newName, setNewName] = useState({
    value: item.title,
    error: "",
  });

  const { dropRef, style } = useDroppable(onDrop, acceptTypes, item.path);

  const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
    type: item.type,
    item: {
      ...item,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }), [item]);

  const handleClick = (event) => {
    setOpen(!open);
    onClick(event);
  };

  const handleNameChange = (event) => {
    let validNameRegex = /^[a-zA-Z0-9_ \\-\\(\\)]+$/;
    let newName = event.target.value;
    if (validNameRegex.test(newName)) {
      setNewName({
        value: newName,
        error: "",
      });
    } else {
      setNewName({
        value: newName,
        error: "Invalid name: can only contain letters, numbers, underscores, hyphens, paranetheses, and spaces",
      });

    }
  }

  const levelIndicators = (() => {
    const indicators = [];
    for (let i = 0; i < level; i++) {
      indicators.push(<LevelIndicator folder={!(i < level - 1)} left={i * 24} key={i + Date.now()} />);
    }
    return indicators;
  })();

  const opacity = isDragging ? 0.4 : 1;

  return (
    <div ref={(draggable && !immutable) ? dragPreview : null} style={{ opacity }}>
      <div ref={(draggable && !immutable) ? drag : null}>
        <ListItemButton
          ref={droppable ? dropRef : null}
          selected={selected}
          onClick={onClick}
          sx={{ paddingLeft: `${(item.type === FileSystemItemType.CreatedFolder ? 16 : 40) + 
            (level - 1) * 24}px`, ...(droppable && { ...style }) }}>
          {levelIndicators}
          {item.type === FileSystemItemType.CreatedFolder ?
            (open ? (
              <ExpandLess sx={{ zIndex: 1 }} onClick={handleClick} />
            ) : (
              <ExpandMore sx={{ zIndex: 1 }} onClick={handleClick} />
            )) : ""}
          <ListItemIcon onClick={handleClick}>
            {item.type === FileSystemItemType.CreatedFolder ? <FolderOpenIcon /> : <SnippetFolderIcon />}
          </ListItemIcon>

          {selected && edited && !immutable ? 
              <TextField
                error={newName.error ? true : false}
                defaultValue={item.title}
                helperText={newName.error}
                onChange={handleNameChange}
                />
            :
            <ListItemText primary={item.title} secondary={item.subtitle} />
          }
          { selected && !edited && !immutable && onRenameFolder && 
            item.type === FileSystemItemType.CreatedFolder ? 
              <IconButton edge="end" aria-label="edit" size="small" onClick={(event) => onSetEditable(event, true)}>
                <Edit />
              </IconButton>
            : ""
          }

          { selected && edited && !immutable && !newName.error && onRenameFolder && 
            item.type === FileSystemItemType.CreatedFolder ? 
              <IconButton edge="end" aria-label="save" size="small" onClick={(event) => onRenameFolder(event, newName.value)}>
                <Check />
              </IconButton>
            : ""
          }

          { selected && edited && !immutable && onRenameFolder && 
            item.type === FileSystemItemType.CreatedFolder ? 
              <IconButton edge="end" aria-label="cancel" size="small" onClick={(event) => onSetEditable(event, false)}>
                <Cancel />
              </IconButton>
            : ""
          }

          {selected && (
            <>
              
              { onCreateFolder && item.type === FileSystemItemType.CreatedFolder ?
                <IconButton edge="end" aria-label="create" size="small" onClick={onCreateFolder}>
                  <CreateNewFolderIcon />
                </IconButton>
                : ""
              }

              { onUpload && item.type === FileSystemItemType.CreatedFolder ?
                <IconButton edge="end" aria-label="upload" size="small">
                  <UploadFile />
                </IconButton>
                : ""
              }

              { (onDelete && !immutable) ? 
                <IconButton edge="end" aria-label="delete" size="small" onClick={(event) => onDelete(event, item)}>
                  <DeleteIcon />
                </IconButton>
                : ""
              }
            </>
          )}
        </ListItemButton>
        <Collapse in={open} timeout="auto">
          <List component="div" disablePadding>
            {children}
          </List>
        </Collapse>
      </div>
    </div>
  );
};

export default FolderAssetItem;
