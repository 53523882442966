import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useCustomSnackbar } from "../../components/CustomSnackbar";
import { Waiting } from "../../components/Waiting";
import { useMutation } from "@apollo/client";
import { DELETE_TEST_TEMPLATES } from "../../graphql/mutations";
import { GET_TEST_TEMPLATES } from "../../graphql/queries";
import { TestTemplateStatus } from "../../graphql/enums";
import { deleteObjectFromCache } from "../../cache";
import * as Yup from "yup";
import { useAppContext } from "../../context/AppContext";

export default function DeleteTestTemplateDialog(props) {
  const { t } = useTranslation();
  const { lsEnqueueSnackbar } = useCustomSnackbar();

  const { store } = useAppContext();

  const handleClose = () => {
    props.setOpenDeleteTestTemplate(false);
    props.setTestTemplateIdsForBulkDelete(null);
  };

  // API to delete a test template and remove it from the cache
  const [deleteTestTemplates] = useMutation(DELETE_TEST_TEMPLATES, {
    update: (cache, results) => {
      if (results?.data) {
        deleteObjectFromCache(
          cache,
          results.data.deleteTestTemplates,
          "id",
          GET_TEST_TEMPLATES,
          "getTestTemplates",
          null,
          {
            orgId: store.currentOrg.org.id,
            templateStatus: [
              TestTemplateStatus.Draft,
              TestTemplateStatus.Active,
              TestTemplateStatus.Deprecated,
              TestTemplateStatus.Retired
            ],
          }
        );
      }
    },
  });

  let dtLoading = null;
  let dtData = [{}];

  const validationSchema = Yup.object({
    confirm: Yup.boolean().oneOf([true], t("MustCheckBox")).required(t("MustCheckBox")),
  });

  const formik = useFormik({
    initialValues: {
      confirm: false,
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const vars = {
        variables: {
          orgId: store.currentOrg.org.id,
          templateIds: props.testTemplateIdsForBulkDelete,
        },
      };

      // Delete the test template
      deleteTestTemplates(vars)
        .then((res) => {
          if (res.errors) {
            lsEnqueueSnackbar(res.errors[0].message, {
              variant: "error",
            });
            setSubmitting(false);
          } else {
            lsEnqueueSnackbar(t("TestTemplateDeleted"), {
              variant: "success",
            });
            setSubmitting(false);
            handleClose();
          }
        })
        .catch((e) => {
          lsEnqueueSnackbar(e.message, {
            variant: "error",
          });
          setSubmitting(false);
        }); // catch network errors
    },
  });

  let pageResults;
  if (dtLoading) {
    pageResults = <Waiting />;
  } else if (dtData) {
    pageResults = (
      <Dialog open={props.openDeleteTestTemplate} onClose={handleClose} aria-labelledby="form-dialog-title">
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle id="form-dialog-title">
            {t("DeleteTestTemplate", {
              testTemplateName: store.currentTestTemplate ? `'${store.currentTestTemplate.name}'` : "",
            })}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>{t("DeleteTestTemplateDialogText")}</DialogContentText>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    name="confirm"
                    type="checkbox"
                    value={formik.values.confirm}
                    onChange={formik.handleChange}
                  />
                }
                label={t("ConfirmDeleteTestTemplate")}
              />
              {formik.errors.confirm ? (
                <FormHelperText id="confirm" error={true}>
                  {t("MustCheckBox")}
                </FormHelperText>
              ) : (
                ""
              )}
            </FormControl>{" "}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary" variant="contained">
              {t("Cancel")}
            </Button>
            <Button type="submit" color="primary" variant="contained">
              {t("Confirm")}
            </Button>
          </DialogActions>
          {formik.isSubmitting ? <Waiting /> : ""}
        </form>
      </Dialog>
    );
  } else {
    pageResults = "";
  }

  return pageResults;
}
