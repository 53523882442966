// Apollo Modules
import { ApolloClient, ApolloLink } from "@apollo/client";
import { InMemoryCache } from "@apollo/client/cache";
import { HttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { Observable } from "@apollo/client";
import { getNewToken, getIdToken, setUpConnectionSubs, gqlCli } from "./identity";
import { getCurrentLanguage, siteConfigRef, setSiteConfig, signedInRef } from "./App";
import { config } from "./config";
import { createClient } from "graphql-ws";
import { print } from "graphql";
import { enqueueMsg } from "./router";

import { v4 as uuidv4 } from "uuid";

export var clientId = uuidv4(); // unique id of the instance of the client application
var tokenRefreshRequired = false;
var wsConnectRetryCount = 0;
var initialWsConnection = true;
const wsRetryAttempts = 5;
const wsPingInterval = 5 * 60 * 1000; // keep alive every 5 minutes

const defaultOptions = {
  watchQuery: {
    errorPolicy: "all",
  },
  query: {
    errorPolicy: "all",
  },
  mutate: {
    errorPolicy: "all",
  },
};

const cache = new InMemoryCache({
  typePolicies: {
    User: {
      fields: {
        roles: {
          merge: false,
        }
      },
    },
    OrgEmailTemplate: {
      keyFields: ["orgId", "emailTemplate", ["key", "language"]]
    },
    EmailTemplate: {
      keyFields: ["key", "language"]
    },
    TestTemplate: {
      fields: {
        outputs: {
          merge: false,
        },
        inputs: {
          merge: false,
        },
        apps: {
          merge: false,
        },
      }
    },
    Query: {
      fields: {
        getOrgEmailTemplates: {
          merge: false,
        },
        getTestTemplates: {
          merge: false,
        },
        getTestAssets: {
          merge: false,
        },
        getTestInstancesAssignedByMe: {
          merge: false,
        },
        getTestInstancesAssignedToMe: {
          merge: false,
        },
        getTestInstancesForMeToMark: {
          merge: false,
        },
      },
    },
  },
});

// Initialize the Apollo http environment
// Inputs: None
// Returns: the new Apollo http Client
export function initApollo() {
  const currentLanguage = getCurrentLanguage();

  // Apollo connection initialization
  let httpHeaders = { language: currentLanguage };
  httpHeaders["client-id"] = clientId;
  const httpLink = new HttpLink({
    uri: config.exampleryServerURI,
    credentials: "include",
    headers: httpHeaders,
  });

  const errLink = onError(
    ({ graphQLErrors, networkError, operation, forward }) => {
      if (graphQLErrors) {
        for (let err of graphQLErrors) {
          switch (err.extensions.code) {
            case "TOKEN_EXPIRED": // JWT token has expired
              // Let's refresh token through async request
              return new Observable((observer) => {
                getNewToken()
                  .then(() => {
                    const newToken = getIdToken();
                    const oldHeaders = operation.getContext().headers;
                    operation.setContext({
                      headers: {
                        ...oldHeaders,
                        authorization: `Bearer ${newToken}`,
                      },
                    });
                  })
                  .then(() => {
                    const subscriber = {
                      next: observer.next.bind(observer),
                      error: observer.error.bind(observer),
                      complete: observer.complete.bind(observer),
                    };

                    // Retry last failed request
                    forward(operation).subscribe(subscriber);
                  })
                  .catch((error) => {
                    observer.error(error);
                    
                    if (error.extensions.code === 'NO_REFRESH_TOKEN' ||
                        error.extensions.code === 'TOKEN_EXPIRED') {
                      // No refresh or client token available, force user to login again
                      window.location.pathname = "/app/signin";
                    }
                  });
              });
            default:
              break;
          }
        }
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }
    }
  );

  const authLink = setContext((_, { headers }) => {
    // get the identity token if it exists
    const id_token = getIdToken();
    // return the headers to the context so httpLink can read them
    let httpHeaders = {
      ...headers,
      authorization: id_token ? `Bearer ${id_token}` : "",
      language: getCurrentLanguage(),
    };
    httpHeaders["client-id"] = clientId;
    return {
      headers: httpHeaders,
    };
  });

  return new ApolloClient({
    cache: cache,
    link: authLink.concat(errLink).concat(httpLink),
    defaultOptions: defaultOptions,
    // connectToDevTools: true,
  });
}

// Initialize the Apollo subscription (i.e. websocket) environment
// Inputs: None
// Returns: an object with the following properties:
//    subClient - the new SubscriptionClient
//    apolloSubClient - the new websocket based Apollo Client
export async function createSubscriptionClient() {
  const subClientOptions = {
    url: config.exampleryServerWSURI,
    lazy: false,
    retryAttempts: wsRetryAttempts,
    keepAlive: wsPingInterval,
    connectionParams: async () => {
      if (tokenRefreshRequired) {
        try {
          await getNewToken();
        } catch (e) {
          if (e.extensions?.code !== 'NO_REFRESH_TOKEN') {
            console.error("Error refreshing tokens for subscriptions: ", e);
          }
          throw e;
        }  
        tokenRefreshRequired = false;
      }
      let id_token = getIdToken();
      return {
        authorization: id_token ? `Bearer ${id_token}` : "",
        language: getCurrentLanguage(),
        clientId: clientId
      };
    },
    shouldRetry: (error) => {
      // If still signed in, we might have been disconnected if either the server forced a 
      // disconnect (max connect time, or max idle time - 1005), or the client disconnected
      // (token expired - 1001), then reconnect as long as we haven't tried the max number
      // of times.  
      if (signedInRef.current && (error.code === 1001 || error.code === 1005)) { 
        return ++wsConnectRetryCount % wsRetryAttempts !== 0; 
      } else {
        wsConnectRetryCount = 0;
        return false;
      }
    },
    onNonLazyError: (error) => {
      if (error && error.extensions?.code !== 'NO_REFRESH_TOKEN') {
        console.log("Websocket startup error: ", error);
      }
    },
    on: {
      connected: (socket, payload) => {
        // On connection, if the server indicates that the token has expired, indicated
        // that a token refresh is required and then close the socket. The graphql-ws 
        // protocol will try to reconnect, at which time, we'll refresh the token. 
        wsConnectRetryCount = 0;
        if (payload && payload.message === "TOKEN_EXPIRED") {
          tokenRefreshRequired = true;
          socket.close();
        } else {
          // This may be a reconnect due to a server timeout. If this isn't the first connection
          // then we need to resubscribe to the OnConnection, and potentially OnSiteConfig 
          // subscriptions 
          if (!initialWsConnection) {  // resubscribe if this is a reconnect
            connectionSubsResubscribe();
          } else {
            initialWsConnection = false;
          }
        }

        async function connectionSubsResubscribe() {
          // resubscribe to all connection related subscriptions
          try {
            await setUpConnectionSubs(gqlCli, siteConfigRef, setSiteConfig, enqueueMsg, false);
          } catch (e) {
            console.log(e);
          }
        }
      },
      error: (error) => {
        if (error.extensions?.code !== 'NO_REFRESH_TOKEN') {
          console.error("in socket error listener. error: ", error);
        }
      }
    }
  };

  if (config.exampleryServerURI.toUpperCase().indexOf("LOCALHOST") === -1) {
    subClientOptions.webSocketImpl = WebSocket;
  }
  // let subClient = createClient(subClientOptions);
  let wsLink = new WebSocketLink(subClientOptions);

  const apolloSubClient = new ApolloClient({
    cache: cache,
    link: wsLink,
    defaultOptions: defaultOptions,
    // connectToDevTools: true,
  });

  let result = {
    subClient: wsLink.client,
    apolloSubClient: apolloSubClient,
  };

  return result;
}

// modified from: https://www.npmjs.com/package/graphql-ws
class WebSocketLink extends ApolloLink {
  constructor(options) {
    super();
    this.client = createClient(options);
  }

  request(operation) {
    return new Observable((sink) => {
      return this.client.subscribe(
        { ...operation, query: print(operation.query) },
        {
          next: sink.next.bind(sink),
          complete: sink.complete.bind(sink),
          error: (err) => {     
            if (err instanceof CloseEvent) {
              if (!tokenRefreshRequired) {
                sink.error(
                  new Error(
                    `Socket closed with event ${err.code}` + err.reason
                      ? `: ${err.reason}` // reason will be available on clean closes
                      : ""
                  )
                );
                  }
            } else if (err instanceof Array) {
              sink.error(
                new Error(err.map(({ message }) => message).join(", "))
              );
            } else {
              if (err.extensions?.code !== "NO_REFRESH_TOKEN") {      
                sink.error(err);
              }  
            }
          },
        }
      );
    });
  }
}
