import React from "react";
import { Toolbar } from "@mui/material";
import SplashPage from "./components/Dashboard/SplashPage";
import DashboardTabs from "./components/Dashboard/DashboardTabs";
import { Waiting } from "./components/Waiting";
import CustomSplashPage from "./components/CustomSplashPage";

export default function Dashboard(props) {
  let pageContents;
  if (props.signingIn) {
    pageContents = <Waiting />;
  } else {
    if (!props.signedIn) {
      pageContents = (
        <React.Fragment>
          <Toolbar />
          <CustomSplashPage
            pageId="splashPage"
            defaultComponent={SplashPage}
          />
        </React.Fragment>
      );
    } else {
      pageContents = <DashboardTabs selectedTab={props.selectedTab} setSelectedTab={props.setSelectedTab} />;
    }
  }

  return pageContents;
}
