import { Box, Button, List, ListSubheader } from "@mui/material";
import FileAssetItem from "./FileAssetItem";
import FolderAssetItem from "./FolderAssetItem";
import { useState } from "react";
import { FileTree } from "./FileTree";

export const FileSystemItemType = {
  File: "file",
  AssetFolder: "assetFolder",
  CreatedFolder: "createdFolder",
};

const FileSystemItemComponent = {
  file: FileAssetItem,
  assetFolder: FolderAssetItem,
  createdFolder: FolderAssetItem,
};

export const rootFolderName = "Test Files";
export const rootFolderPath = "/Test Files"; 

/**
 *
 * @param {Object} params
 * @param {FileTree} params.data
 * @returns
 */
const FileSystem = ({
  draggable,
  droppable,
  selectable,
  data,
  headingActions,
  header,
  style,
  downloadable,
  deletable,
  onDelete,
  onDownload,
  onMove,
  onCreateFolder,
  onRenameFolder,
  rootFolderName,
  selectedItem,
  setSelectedItem
}) => {
  const [editedItem, setEditedItem] = useState(null);

  const handleListItemClick = (event, item) => {
    if (selectable) {
      setSelectedItem(item);
      if (editedItem && editedItem !== item.id) {
        setEditedItem(null);
      }
    }
  };

  function handleSetEditable(event, item, enabled) {
    if (enabled) {
      setEditedItem(item.id);
    } else {
      setEditedItem(null);
    }
  }


  function handleCreateFolderClick(event, item) {
    // console.log("Creating new folder under path:", item.path);
    let elementsCopy = data.copy();
    let parent = elementsCopy.findNodeByPath(item.path);
    let name = generateUniqueName(parent, "Untitled");
    let path = item.path + "/" + name;
    let newFolder = {
      id: rootFolderName + path,
      title: name,
      type: FileSystemItemType.CreatedFolder,
      path: path,
      children: [],
    };
    if (newFolder.id.charAt[0] !== '/') {
      newFolder.id = '/' + newFolder.id;
    }

    elementsCopy.addChildNode(parent, newFolder);
    onCreateFolder(newFolder);
  }

  function generateUniqueName(parent, name, item = null) {
    let parentPath = parent.path || "";
    let searchpath = (parentPath + "/" + name).toUpperCase();
    let foundChild = parent.children.find((child) => child.path.toUpperCase() === searchpath);
    if (foundChild && foundChild !== item) {
      // find an unused name in the form "name (#)" - case insensitive search because
      // the windows file system is case insensitive
      const regex = new RegExp(`^${parentPath}/${name} \\(\\d+\\)$`, 'i');
      const usedIndexes = new Set();
      
      for (let child of parent.children) {
        if (child !== item) {
          const match = child.path.match(regex);
          if (match) {
            let strNum = child.path.substring(child.path.lastIndexOf("(") + 1, 
              child.path.lastIndexOf(")"));
            let index = parseInt(strNum);
            usedIndexes.add(index);
          }
        }
      }
    
      let lowestUnusedIndex = 1;
      while (usedIndexes.has(lowestUnusedIndex)) {
        lowestUnusedIndex++;
      }
      name = `${name} (${lowestUnusedIndex})`;
    }    
    return name;
  }

  function handleDelete(event, item) {
    // console.log("Deleting item ", item.path);
    let elementsCopy = data.copy();
    let parent = elementsCopy.findNodeByPath(
      item.path.substring(0, item.path.lastIndexOf("/"))
    );
    elementsCopy.removeChildNode(parent, item);
    onDelete(elementsCopy.findNodeByPath(""), item);
  }

  function handleRenameFolder(event, item, newName) {
    // console.log(`Renaming folder ${item.path} to ${newName}`);
    let elementsCopy = data.copy();
    let node = elementsCopy.findNodeByPath(item.path);
    let parent = elementsCopy.findNodeByPath(
      item.path.substring(0, item.path.lastIndexOf("/"))
    );
    let name = generateUniqueName(parent, newName, node);
    elementsCopy.renameNode(node, name);
    onRenameFolder(elementsCopy.findNodeByPath(""), node);
  }

  function moveItem(item, targetPath) {
    // console.log(`Moving item from: ${item.path} to ${targetPath}`);
    if (item.path === targetPath) {
      // console.log(`Skipping, item is already at path ${targetPath}`);
      return;
    }
    let elementsCopy = data.copy();
    let itemCopy = JSON.parse(JSON.stringify(item));
    let oldItemCopy = JSON.parse(JSON.stringify(item));

    let oldParentPath = itemCopy.path.substring(
      0,
      itemCopy.path.lastIndexOf("/")
    );
    let newParent = elementsCopy.findNodeByPath(targetPath);
    let oldParent = elementsCopy.findNodeByPath(oldParentPath);

    elementsCopy.addChildNode(newParent, itemCopy);
    elementsCopy.removeChildNode(oldParent, oldItemCopy);
    onMove(elementsCopy.findNodeByPath(""), itemCopy, oldItemCopy);
  }

  const renderElements = (elementsArray) => {
    return elementsArray?.map((el) => {
      const TagName = FileSystemItemComponent[el.type];
      return (
        <TagName
          key={el.id}
          item={el}
          selected={el.id === selectedItem?.id}
          edited={el.id === editedItem}
          level={(el.level || el.id?.split("/").length) - 1}
          onClick={(event) => handleListItemClick(event, el)}
          onDrop={moveItem}
          draggable={draggable}
          droppable={droppable}
          onDelete={onDelete ? handleDelete : null}
          onDownload={onDownload}
          onCreateFolder={
            onCreateFolder
              ? (event) => handleCreateFolderClick(event, el)
              : null
          }
          immutable={el.immutable ? el.immutable : false}
          onSetEditable={
            onRenameFolder
              ? (event, enabled) => handleSetEditable(event, el, enabled)
              : null
          }
          onRenameFolder={
            onRenameFolder
              ? (event, newName) => handleRenameFolder(event, el, newName)
              : null
          }
        >
          {el.children ? renderElements(el.children) : null}
        </TagName>
      );
    });
  };

  return (
    <Box>
      <List
        sx={{
          width: "100%",
          height: "100%",
          bgcolor: "background.paper",
          overflow: "auto",
        }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader
            component="div"
            align="right"
            id="nested-list-subheader"
            style={{ minHeight: "32px", zIndex: 3 }}
          >
            {headingActions?.map((action) => (
              <Button
                size="small"
                startIcon={action.icon}
                onClick={action.callback}
              >
                {action.title}
              </Button>
            ))}
          </ListSubheader>
        }
      >
        {renderElements([
          {
            id: "/",
            title: rootFolderName,
            type: FileSystemItemType.CreatedFolder,
            level: -1,
            path: "",
            children: data.files,
            immutable: true,
          },
        ])}
      </List>
    </Box>
  );
};

export default FileSystem;
