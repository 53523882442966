import {
  Box,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  Grid
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import { t } from "i18next";
import { useMutation } from "@apollo/client";
import { useCustomSnackbar } from "../../../components/CustomSnackbar";
import { UPDATE_ORG_SEND_EMAIL } from "../../../graphql/mutations";
import { useEffect, useState } from "react";
import ConfirmationForm from "../../../components/ConfirmationForm";

export default function ChangeSendEmailForm({
  open,
  handleClose,
  orgId,
  currentCustomSendEmail,
  setCustomSendEmailPending,
  systemSendEmailAddress
}) {
  const { lsEnqueueSnackbar } = useCustomSnackbar();

  const [updateOrgSendEmail, { loading: uoseLoading }] = useMutation(UPDATE_ORG_SEND_EMAIL);
  const [previousOpenState, setPreviousOpenState] = useState(open);
  const [checkEmailOpen, setCheckEmailOpen] = useState(false);


  const handleCloseCheckEmail = () => {
    setCheckEmailOpen(false);
  };

  const validationSchema = Yup.object({
    customSendEmail: Yup.string()
      .email(t("MustBeValidEmail"))
      .nullable(), // Allows null value
    emailconfirm: Yup.string()
      .oneOf([Yup.ref("customSendEmail"), null], t("EmailsMustMatch"))
      .when('customSendEmail', {
        is: (value) => value?.length > 0,
        then: Yup.string().required(t("Required"))
      })
  });

  const formik = useFormik({
    initialValues: {
      customSendEmail: currentCustomSendEmail || "",
      emailconfirm: currentCustomSendEmail || "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      if (currentCustomSendEmail === values.customSendEmail ||
        (!currentCustomSendEmail && values.customSendEmail === systemSendEmailAddress)
      ) {
        lsEnqueueSnackbar(t("NewSendEmailAddressSameAsCurrent"), {
          variant: "warning",
        });
        return;
      }
      const vars = {
        variables: {
          orgId: orgId,
          sendEmail: (values.customSendEmail && values.customSendEmail !== "") ?
            values.customSendEmail : null
        }
      };

      updateOrgSendEmail(vars)
        .then((res) => {
          if (res.errors) {
            lsEnqueueSnackbar(res.errors[0].message, {
              variant: "error",
            });
            setSubmitting(false);
          } else {
            setCustomSendEmailPending(values.customSendEmail);
            setSubmitting(false);
            handleClose();
            if (res?.data?.updateOrgSendEmail?.customSendEmailPending) {
              setCheckEmailOpen(true);
            } else {
              lsEnqueueSnackbar(t("OrgSendEmailClearedSuccess"), {
                variant: "success",
              });
            }
          }
        })
        .catch((e) => {
          lsEnqueueSnackbar(t("ErrorUpdatingOrgSendEmail"), {
            variant: "error",
          });
          setSubmitting(false);
          handleClose();
        }); // catch network errors
    },
  });


  useEffect(() => {
    if (open && !previousOpenState) {
      formik.resetForm({
        values: {
          customSendEmail: currentCustomSendEmail || "",
          emailconfirm: currentCustomSendEmail || ""
        }
      });
    }
    setPreviousOpenState(open);
  }, [open, currentCustomSendEmail, previousOpenState, formik]);

  let pageContents = (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle id="form-dialog-title">
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                {t("ChangeSendEmailAddress")}
              </Grid>
              <Grid item>
                {uoseLoading && <CircularProgress size={24} />}
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t("ChangeSendEmailAddressExplanation")}
            </DialogContentText>
            <Box sx={{ paddingTop: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="customSendEmail"
                    label={t("EmailAddress")}
                    name="customSendEmail"
                    value={formik.values.customSendEmail}
                    onChange={formik.handleChange}
                    placeholder={formik.values.customSendEmail ? "" : systemSendEmailAddress}
                    error={formik.touched.customSendEmail && Boolean(formik.errors.customSendEmail)}
                    helperText={formik.touched.customSendEmail && formik.errors.customSendEmail}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="emailconfirm"
                    label={t("ConfirmEmailAddress")}
                    name="emailconfirm"
                    value={formik.values.emailconfirm}
                    onChange={formik.handleChange}
                    placeholder={formik.values.emailconfirm ? "" : systemSendEmailAddress}
                    error={formik.touched.emailconfirm && Boolean(formik.errors.emailconfirm)}
                    helperText={formik.touched.emailconfirm && formik.errors.emailconfirm}
                  />
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              color="secondary"
              variant="contained"
            >
              {t("Cancel")}
            </Button>
            <Button
              onClick={formik.handleSubmit}
              color="primary"
              variant="contained"
              disabled={formik.isSubmitting || uoseLoading}
            >
              {t("Confirm")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog
        open={checkEmailOpen}
        onClose={handleCloseCheckEmail}
      >
        <ConfirmationForm 
          handleConfirmationClose={handleCloseCheckEmail}
          dialogTitle={t("CheckYourEmail")}
          dialogContent={t("CheckYourEmailPendingSendEmailExplanation", 
            { email: formik.values.customSendEmail})}
          showCancel={false}
        />
      </Dialog>
    </>
  );

  return pageContents;
}