import React from "react";
import { Waiting } from "../Waiting";
import DashboardSubmittedByMe from "./DashboardSubmittedByMe";
import DashboardAssignedToMe from "./DashboardAssignedToMe";
import { useQuery, useSubscription } from "@apollo/client";
import { GET_TEST_INSTANCES_ASSIGNED_TO_ME } from "../../graphql/queries";
import { ON_TESTINSTANCEASSIGNEDTOMEUPDATE_SUBSCRIPTION } from "../../graphql/subscriptions";
import { useCustomSnackbar } from "../CustomSnackbar";
import { apolloSubClient } from "../../identity";
import { handleCacheUpdate } from "../../cache";

export default function DashboardAssignees({activeTab}) {
  const { lsEnqueueSnackbar } = useCustomSnackbar();

  // Fetch test instances assigned to me
  const {
    data: tData,
    loading: tLoading,
    refetch: refreshMyTestInstances,
  } = useQuery(GET_TEST_INSTANCES_ASSIGNED_TO_ME, {
    variables: {},
    onError: (e) => {
      lsEnqueueSnackbar(e.message, {
        variant: "error",
      });
    },
    fetchPolicy: "network-only"
  });

  useSubscription(ON_TESTINSTANCEASSIGNEDTOMEUPDATE_SUBSCRIPTION, {
    shouldResubscribe: true,
    fetchPolicy: "no-cache",
    client: apolloSubClient,
    onSubscriptionData: (options) => {
      let payload = options.subscriptionData.data.onTestInstanceAssignedToMeUpdate; 
      handleCacheUpdate(
        payload.objectUpdate.action,
        options.client,
        payload.testInstance,
        "id",
        GET_TEST_INSTANCES_ASSIGNED_TO_ME,
        "getTestInstancesAssignedToMe",
        null,
        {},
        payload.objectUpdate.message,
        lsEnqueueSnackbar
      );
    },
  });

  let pageContents;
  if (tLoading) {
    pageContents = <Waiting />;
  } else {
    pageContents = (
      <React.Fragment>
        <DashboardAssignedToMe 
          activeTab={activeTab}
          tData={tData}
          tLoading={tLoading} 
          refreshMyTestInstances={refreshMyTestInstances}
        />
        <DashboardSubmittedByMe 
          activeTab={activeTab} 
          tData={tData}
          tLoading={tLoading} 
          refreshMyTestInstances={refreshMyTestInstances}
        />
      </React.Fragment>  
    );
  }

  return pageContents;
}
