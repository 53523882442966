// Adds a newly created object to the cache
// Inputs:
//   cacheClient - Required Object - The cache or Apollo client that owns the cache
//   newObject   - Required Object - The new object to add to the cache
//   idField     - Required String - The name of the object field that contains the unique id
//   gqlQuery    - Required Object - The graphql query object that was used to
//                                   populate the cache (e.g ORG_ROLES)
//   objectType  - Required String - The type of object stored in the cache. This is generally the
//                                   name of the query in the GraphQL schema (e.g. orgRoles)
//   field       - Optional String - The name of the field that contains the list of objects
//                                   to be updated. If the list is a top level query result,
//                                   set field to null.
//   variables   - Optional Object - the variables to be passed to the gqlQuery
// Returns:
//   none
export function addObjectToCache(
  cacheClient,
  newObject,
  idField,
  gqlQuery,
  objectType,
  field = null,
  variables = null
) {
  let query = {
    query: gqlQuery,
  };
  if (variables) {
    query["variables"] = variables;
  }

  const queryResults = cacheClient.readQuery(query);
  let existingObjects = queryResults ? queryResults[objectType] : null;

  let fieldName;
  if (field) {
    existingObjects = existingObjects ? existingObjects[field] : null;
    fieldName = field;
  } else {
    fieldName = objectType;
  }

  if (existingObjects) {
    // add the item to the cache if it's not already there
    if (
      !existingObjects.find((x) => {
        return x[idField] === newObject[idField];
      })
    ) {
      let data = {};
      data[fieldName] = [...existingObjects, newObject];

      // write the data back to the cache
      if (field) {
        let topCopy = {};
        topCopy[objectType] = { ...queryResults[objectType] };
        topCopy[objectType][fieldName] = data[fieldName];
        data = topCopy;
      }
      query["data"] = data;
      cacheClient.writeQuery(query);
    }
  }
}

// Delete an object from the cache
// Inputs:
//   cacheClient - Required Object - The cache or Apollo client that owns the cache
//   objectToDelete - Required Object - The object to remove from the cache
//   idField     - Required String - The name of the object field that contains the unique id
//   gqlQuery    - Required Object - The graphql query object that was used to
//                                   populate the cache (e.g ORG_ROLES)
//   objectType  - Required String - The type of object stored in the cache. This is generally the
//                                   name of the query in the GraphQL schema (e.g. orgRoles)
//   field       - Optional String - The name of the field that contains the list of objects
//                                      to be updated. If the list is a top level query result,
//                                      set field to null.
//   variables   - Optional Object - the variables to be passed to the gqlQuery
// Returns:
//   none
export function deleteObjectFromCache(
  cacheClient,
  objectToDelete,
  idField,
  gqlQuery,
  objectType,
  field = null,
  variables = null
) {
  let query = {
    query: gqlQuery,
  };
  if (variables) {
    query["variables"] = variables;
  }

  const queryResults = cacheClient.readQuery(query);
  let existingObjects = queryResults ? queryResults[objectType] : null;
  let fieldName;
  if (field) {
    existingObjects = existingObjects ? existingObjects[field] : null;
    fieldName = field;
  } else {
    fieldName = objectType;
  }

  if (existingObjects) {
    let deleteIds = [];
    if (objectToDelete instanceof Array) {
      deleteIds = objectToDelete.map((object) => object[idField]);
    } else {
      deleteIds.push(objectToDelete[idField]);
    }

    let data = {};
    // delete the item from the cache
    data[fieldName] = existingObjects.filter((x) => {
      return !deleteIds.includes(x[idField]);
    });

    // write the data back to the cache
    if (field) {
      let topCopy = {};
      topCopy[objectType] = { ...queryResults[objectType] };
      topCopy[objectType][fieldName] = data[fieldName];
      data = topCopy;
    }
    query["data"] = data;
    cacheClient.writeQuery(query);
  }
}

// Update an object in the cache. Normally, Apollo manages this automatically, but sometimes
// (e.g. when updates happen as a result of a subscription), we have to do the cache update
// manually.
// Inputs:
//   cacheClient    - Required Object - The cache or Apollo client that owns the cache
//   objectToUpdate - Required Object - The object to update in the cache
//   idField        - Required String - The name of the object field that contains the unique id
//   gqlQuery       - Required Object - The graphql query object that was used to
//                                      populate the cache (e.g ORG_ROLES)
//   objectType     - Required String - The type of object stored in the cache. This is generally the
//                                      name of the query in the GraphQL schema (e.g. orgRoles)
//   field          - Optional String - The name of the field that contains the list of objects
//                                      to be updated. If the list is a top level query result,
//                                      set field to null.
//   variables      - Optional Object - the variables to be passed to the gqlQuery
// Returns:
//   none
export function updateObjectInCache(
  cacheClient,
  objectToUpdate,
  idField,
  gqlQuery,
  objectType,
  field = null,
  variables = null
) {
  let query = {
    query: gqlQuery,
  };
  if (variables) {
    query["variables"] = variables;
  }
  const queryResults = cacheClient.readQuery(query);
  let existingObjects = queryResults ? queryResults[objectType] : null;
  let fieldName;
  if (field) {
    existingObjects = existingObjects ? existingObjects[field] : null;
    fieldName = field;
  } else {
    fieldName = objectType;
  }

  // update the item in the cache
  if (existingObjects) {
    let data = {};
    data[fieldName] = [...existingObjects];

    let i = data[fieldName].findIndex((x) => {
      return x[idField] === objectToUpdate[idField];
    });

    let tempObj = { ...data[fieldName][i] };
    if (i >= 0) {
      // update the individual fields from ObjectToUpdate
      for (let field in objectToUpdate) {
        tempObj[field] = objectToUpdate[field];
      }
      data[fieldName][i] = tempObj;

      // write the data back to the cache
      if (field) {
        let topCopy = {};
        topCopy[objectType] = { ...queryResults[objectType] };
        topCopy[objectType][fieldName] = data[fieldName];
        data = topCopy;
      }
      query["data"] = data;
      cacheClient.writeQuery(query);
    }
  }
}

// Update an object in the cache. If it's not present, insert the object. 
// Inputs:
//   cacheClient    - Required Object - The cache or Apollo client that owns the cache
//   object         - Required Object - The object to update or insert in the cache
//   idField        - Required String - The name of the object field that contains the unique id
//   gqlQuery       - Required Object - The graphql query object that was used to
//                                      populate the cache (e.g ORG_ROLES)
//   objectType     - Required String - The type of object stored in the cache. This is generally the
//                                      name of the query in the GraphQL schema (e.g. orgRoles)
//   field          - Optional String - The name of the field that contains the list of objects
//                                      to be updated. If the list is a top level query result,
//                                      set field to null.
//   variables      - Optional Object - the variables to be passed to the gqlQuery
// Returns:
//   none
export function upsertObjectInCache(
  cacheClient,
  object,
  idField,
  gqlQuery,
  objectType,
  field = null,
  variables = null
) {
  let query = {
    query: gqlQuery,
  };
  if (variables) {
    query["variables"] = variables;
  }
  const queryResults = cacheClient.readQuery(query);
  let existingObjects = queryResults ? queryResults[objectType] : null;
  if (field) {
    existingObjects = existingObjects ? existingObjects[field] : null;
  }

  // update the item in the cache if it exists, otherwise, insert it
  if (existingObjects && existingObjects.find((x) => {
        return x[idField] === object[idField];
    })) {
    updateObjectInCache(cacheClient, object, idField, gqlQuery, objectType, field, variables);
  } else {
    addObjectToCache(cacheClient, object, idField, gqlQuery, objectType, field, variables);
  }
}

// Standard subscriptoin handler to update the cache based on create, update and delete
// messages.
// Inputs:
//   action         - Required String - The action to perform against the cache:
//                                      "create", "update", update_create (upsert) or "delete"
//   cacheClient    - Required Object - The cache or Apollo client that owns the cache
//   object         - Required Object - The object to add to, update in or delete from the cache.
//   idField        - Required String - The name of the object field that contains the unique id
//   gqlQuery       - Required Object - The graphql query object that was used to
//                                      populate the cache (e.g ORG_ROLES)
//   objectType     - Required String - The type of object stored in the cache. This is generally the
//                                      name of the query in the GraphQL schema (e.g. orgRoles)
//   field          - Optional String - The name of the field that contains the list of objects
//                                      to be updated. If the list is a top level query result,
//                                      set field to null.
//   variables      - Optional Object - the variables passed to the gqlQuery
//   message        - Optional Object - A message object containing "content" and "type" fields used
//                                      to fuel a snackbar message shown to the user.
//   lsEnqueueSnackbar - Optional function - The lsEnqueueSnackbar function. Required if mesasge is not
//                                           null. We pass this function in because we can't use the
//                                           snackbar hook outside a react element.
// Returns:
//   none
export function handleCacheUpdate(
  action,
  cacheClient,
  object,
  idField,
  gqlQuery,
  objectType,
  field = null,
  variables = null,
  message = null,
  lsEnqueueSnackbar = null
) {  
  switch (action) {
    case "create":
      addObjectToCache(cacheClient, object, idField, gqlQuery, objectType, field, variables);
      break;
    case "delete":
      deleteObjectFromCache(cacheClient, object, idField, gqlQuery, objectType, field, variables);
      break;
    case "update":
      updateObjectInCache(cacheClient, object, idField, gqlQuery, objectType, field, variables);
      break;
    case "update_create":
      upsertObjectInCache(cacheClient, object, idField, gqlQuery, objectType, field, variables);
      break;      
    default:
      break;
  }
  if (message) {
    lsEnqueueSnackbar(message.content, {
      variant: message.type,
    });
  }
}
