import React, { useState } from "react";
import {
  Typography,
  Grid,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  Hidden,
  Tooltip
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCustomSnackbar } from "../../components/CustomSnackbar";
import { Waiting } from "../../components/Waiting";
import FolderIcon from "@mui/icons-material/Folder";
import DescriptionIcon from "@mui/icons-material/Description";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AddAssetForm from "./AddAsset";
import EditAssetForm from "./EditAsset";
import DeleteAssetDialog from "./DeleteAsset";
import { GET_TEST_ASSETS } from "../../graphql/queries";
import { useQuery } from "@apollo/client";
import { useAppContext } from "../../context/AppContext";
import { CopyToClipboard } from 'react-copy-to-clipboard';

export default function ManageAssetsForm(props) {
  const { t } = useTranslation();
  const { lsEnqueueSnackbar } = useCustomSnackbar();
  const [isAddAssetOpen, setIsAddAssetOpen] = useState(false);
  const [openEditAsset, setOpenEditAsset] = useState(false);
  const [openDeleteAsset, setOpenDeleteAsset] = useState(false);
  const [activeAsset, setActiveAsset] = useState(null);
  const [copied, setCopied] = useState(false);

  const { store } = useAppContext();

  const handleRefresh = () => {
    refreshAssets();
  };

  function handleOpenAddAsset(data) {
    setActiveAsset(data);
    setIsAddAssetOpen(true);
  }

  function handleOpenEditAsset(data) {
    setActiveAsset(data);
    setOpenEditAsset(true);
  }

  function handleOpenDeleteAsset(data) {
    setActiveAsset(data);
    setOpenDeleteAsset(true);
  }

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset the copied state after 2 seconds
  };

  // Retrieve all assets
  const {
    data: asData,
    loading: asLoading,
    refetch: refreshAssets,
  } = useQuery(GET_TEST_ASSETS, {
    variables: {
      orgId: store.currentOrg.org.id,
      folderId: null,
      assetStatus: [],
    },
    onError: (e) => {
      lsEnqueueSnackbar(e.message, {
        variant: "error",
      });
    },
  });

  let assetData = [];
  let pageResults;
  if (asLoading) {
    pageResults = <Waiting />;
  } else if (asData) {
    assetData = asData.getTestAssets;
    pageResults = (
      <Grid container spacing={2} paddingLeft="20%" paddingRight="20%">
        <Grid item xs={12}>
          <Grid container alignItems="baseline">
            <Grid item xs={8}>
              <Typography
                component="h1"
                variant="h5"
                sx={{ marginBottom: 3, fontWeight: "bold" }}
              >
                {t("ManageTestAssets")}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <IconButton onClick={handleRefresh}>
                    <RefreshIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container alignItems="baseline">
            <Grid item xs={8}>
              <Typography
                variant="h6"
                sx={{ marginTop: 3, textDecoration: "underline" }}
              >
                {t("Assets")}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Hidden xsDown>
                    <Button variant="outlined" onClick={handleOpenAddAsset}>
                      {t("AddAsset")}
                    </Button>
                  </Hidden>
                  <Hidden smUp>
                    <IconButton onClick={handleOpenAddAsset}>
                      <AddIcon />
                    </IconButton>
                  </Hidden>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <List>
            {assetData.map((d) => {
              return (
                <div key={d.id}>
                  <ListItem>
                    <ListItemAvatar>
                      {d.isFolder ? <FolderIcon /> : <DescriptionIcon />}
                    </ListItemAvatar>
                    <ListItemText
                      primary={d.name}
                      secondary={
                        (d.description ? d.description + " \u2014 " : "") +
                        t("LastUpdated") +
                        ": " +
                        d.lastUpdateDate.substr(0, 10)
                      }
                    />
                    <ListItemSecondaryAction>
                      <span>
                        <IconButton
                          onClick={() => {
                            handleOpenEditAsset(d);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            handleOpenDeleteAsset(d);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                        <CopyToClipboard text={d.url} onCopy={handleCopy}>
                          <Tooltip title={copied ? t("Copied") : t("CopyUrl")}>
                            <IconButton>
                              <ContentCopyIcon fontSize="small"/>
                            </IconButton>
                          </Tooltip>
                        </CopyToClipboard>
                      </span>
                    </ListItemSecondaryAction>
                  </ListItem>
                </div>
              );
            })}
          </List>

          {isAddAssetOpen ? (
            <AddAssetForm
              isAddAssetOpen={isAddAssetOpen}
              setIsAddAssetOpen={setIsAddAssetOpen}
            />
          ) : (
            ""
          )}

          {openEditAsset ? (
            <EditAssetForm
              openEditAsset={openEditAsset}
              setOpenEditAsset={setOpenEditAsset}
              activeAsset={activeAsset}
              setActiveAsset={setActiveAsset}
            />
          ) : (
            ""
          )}

          {openDeleteAsset ? (
            <DeleteAssetDialog
              openDeleteAsset={openDeleteAsset}
              setOpenDeleteAsset={setOpenDeleteAsset}
              activeAsset={activeAsset}
              setActiveAsset={setActiveAsset}
            />
          ) : (
            ""
          )}
        </Grid>
      </Grid>
    );
  } else {
    pageResults = "";
  }

  return pageResults;
}
