import * as Yup from "yup";
import { useTranslation } from "react-i18next";

export function usePasswordReqs(siteConfig) {
  const { t } = useTranslation();
  let passwordReqs;
  // Ensure password validation on client matches what is on server
  const reqs = siteConfig.pwReqs;
  passwordReqs = Yup.string()
    .min(reqs.minlength, t("EightOrMoreChars"))
    .required(t("Required"));
  if (reqs.uppercaseReq) {
    passwordReqs = passwordReqs.matches(/(?=.*[A-Z])/, t("UpperCaseRequired"));
  }
  if (reqs.lowercaseReq) {
    passwordReqs = passwordReqs.matches(/(?=.*[a-z])/, t("LowerCaseRequired"));
  }
  if (reqs.numbersReq) {
    passwordReqs = passwordReqs.matches(/(?=.*\d)/, t("NumberRequired"));
  }
  if (reqs.symbolsReq) {
    passwordReqs = passwordReqs.matches(
      /(?=.*[@$!%*#?_&])/,
      t("SymbolRequired")
    );
  }
  return passwordReqs;
}
