import React, { useState } from "react";
import {
  Button,
  Grid,
  IconButton,
  Typography
} from "@mui/material";
import { useField } from "formik";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import SnippetFolderIcon from "@mui/icons-material/SnippetFolder";
import DescriptionIcon from "@mui/icons-material/Description";
import TestTemplateFileBrowser from "../TestTemplateFileBrowser";
import SelectAssetForm from "../../views/HiringManager/SelectAsset";

const TestTemplateInputsForm = React.memo(({
  testPlatform,
  handleContinue
}) => {
  const { t } = useTranslation();
  const [isSelectAssetOpen, setIsSelectAssetOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [inputsField, , inputsHelpers] = useField("inputs");
  const [inputEmptyFoldersField, , inputEmptyFoldersHelpers] = useField("inputEmptyFolders");

  const handleOpenSelectAsset = () => {
    setIsSelectAssetOpen(true);
  };

  const handleInputUpdate = (newInputList, newEmptyFolderList) => {
    inputsHelpers.setValue(newInputList);
    inputEmptyFoldersHelpers.setValue(newEmptyFolderList);
  };

  const handleRemoveWebsiteInput = () => {
    inputsHelpers.setValue([]);
  };

  const handleSelectedAssets = (assetList) => {
    const formAssets = assetList.map((asset) => ({
      id: asset.id,
      asset,
      targetPath: selectedItem?.path || "",
      isFolder: asset.isFolder,
    }));
    if (testPlatform === 'appstream') {
      inputsHelpers.setValue([...inputsField.value, ...formAssets]);
    } else if (testPlatform === 'website') {
      inputsHelpers.setValue(formAssets);
    }
  };

  let pageResults = (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid
            container
            alignItems="center"
            justifyContent="flex-end"
            spacing={2}
          >
            <Grid item xs={10}>
              <Typography component="p" sx={{ marginBottom: 3 }}>
                {(testPlatform === "appstream") ? t("TestAssets") : t("ApplicationInput")}
              </Typography>
            </Grid>

            <Grid item xs={2}>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Button
                    variant="outlined"
                    onClick={() => { handleOpenSelectAsset(); }}
                    sx={{ display: { xs: "none", sm: "inline-flex" } }}
                  >
                    {t("SelectAsset")}
                  </Button>
                  <IconButton
                    onClick={() => { handleOpenSelectAsset(); }}
                    sx={{ display: { xs: "inline-flex", sm: "none" } }}
                  >
                    <AddIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            {testPlatform === 'appstream' ?
              (inputsField.value ? (
                <TestTemplateFileBrowser
                  testInputs={inputsField.value}
                  emptyFolders={inputEmptyFoldersField.value}
                  onUpdate={handleInputUpdate}
                  selectedItem={selectedItem}
                  setSelectedItem={setSelectedItem}
                />
              ) : (
                ""
              )) : (
                inputsField.value?.length ? (
                  <Grid container alignItems="center" justifyContent="flex-start">
                    <Grid item xs={1}>
                      {inputsField.value[0].asset.isFolder ?
                        <SnippetFolderIcon sx={{ color: 'gray' }} /> :
                        <DescriptionIcon sx={{ color: 'gray' }} />}
                    </Grid>
                    <Grid item xs={8}>
                      <Typography variant="p">
                        {inputsField.value[0].asset.filename}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton
                        onClick={() => handleRemoveWebsiteInput()}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                ) : ""
              )
            }
          </Grid>
        </Grid>
        <SelectAssetForm
          isSelectAssetOpen={isSelectAssetOpen}
          setIsSelectAssetOpen={setIsSelectAssetOpen}
          onAssetSubmit={handleSelectedAssets}
        />
        <Grid item xs={12}>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleContinue}
                sx={{ marginTop: 1 }}
              >
                {t("Continue")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
  return pageResults;
});

export default TestTemplateInputsForm;      