import {
  Button
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useRef } from "react";

const SelectFileButton = ({
  onOpen,
  title,
  buttonVariant = "outlined",
  accept = "*",
  inputName = "fileList",
  buttonProps = null
}) => {
  const { t } = useTranslation();
  const fileInputRef = useRef(null);

  const handleFileSelect = (e) => {
    if (!e.target.files.length) {
      return;
    }

    if (onOpen) {
      onOpen(e);
    }
  };

  const buttonClick = (e) => {
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  return (
    <>
      <input
        id="file-select-button"
        name={inputName}
        type="file"
        accept={accept}
        style={{ display: "none" }}
        ref={fileInputRef}
        onChange={handleFileSelect}
      />
      <label htmlFor="file-select-button">
        <Button 
          variant={buttonVariant} 
          component="span" 
          onClick={buttonClick}
          {...buttonProps}
        >
          {t(title)}
        </Button>
      </label>

    </>
  );
};

export default SelectFileButton;