import { createContext, useContext, useState } from "react";

const AppContext = createContext(null);

export const AppContextWrapper = (props) => {
  const [store, setStore] = useState({
    currentTestInstance: null,
    currentTestInstanceTemplate: null,
    currentOrg: null,
    currentTestTemplate: null,
    userDetails: { user: {} },
    takingTest: false,
    siteConfig: null,
    subdomain: null,
    assignedByMeFilter: [{
      field: "status",
      operation: "notin",
      values: {
        stringValues: ['cancelled']
      }
    }]
  });

  const [actions] = useState({
    setCurrentTestInstance: (testInstance, template = null) => {
      setStore((prevStore) => ({
        ...prevStore,
        currentTestInstance: testInstance,
        currentTestInstanceTemplate: template || testInstance.testTemplate
      }));
    },
    setCurrentOrg: (org) => {
      setStore((prevStore) => ({ ...prevStore, currentOrg: org }));
    },
    setCurrentTestTemplate: (testTemplate) => {
      setStore((prevStore) => ({ ...prevStore, currentTestTemplate: testTemplate }));
    },
    setUserDetails: (user) => {
      setStore((prevStore) => ({ ...prevStore, userDetails: user }));
    },
    setTakingTest: (state) => {
      setStore((prevStore) => ({ ...prevStore, takingTest: state}));
    },
    setSiteConfig: (siteConfig) => {
      setStore((prevStore) => ({ ...prevStore, siteConfig: siteConfig }));
    },
    setSubdomain: (subdomain) => {
      setStore((prevStore) => ({ ...prevStore, subdomain }));
    },
    setAssignedByMeFilter: (filter) => {
      setStore((prevStore) => ({ ...prevStore, assignedByMeFilter: filter }));
    }
  });

  return <AppContext.Provider value={{ store, actions }}>{props.children}</AppContext.Provider>;
};

export const useAppContext = () => useContext(AppContext);
