import { t } from "i18next";
import {
  Grid,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
} from "@mui/material";

const ConfirmationForm = ({
  handleConfirmationClose,
  dialogTitle,
  dialogContent,
  confirmText,
  cancelText,
  showCancel = true
}) => {

  let pageResults = (
    <Grid item xs={12}>
      <DialogTitle>
        {dialogTitle}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {dialogContent}
        </DialogContentText>
        <Grid container justifyContent="flex-end">
          <Grid item>
            {showCancel && 
              <Button
                variant="contained"
                color="secondary"
                onClick={() => { handleConfirmationClose('Cancel') }}
                sx={{ marginTop: 1, marginRight: 1 }}
              >
                {cancelText || t("Cancel")}
              </Button>
            }
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleConfirmationClose('Confirm')}
              sx={{ marginTop: 1 }}
            >
              {confirmText || t("Confirm")}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Grid>
  );

  return pageResults;
};

export default ConfirmationForm;