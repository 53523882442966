import { gql } from "@apollo/client";

export const CORE_TEST_TEMPLATE_FIELDS = gql`
  fragment CoreTestTemplateFields on TestTemplate {
    id
    org {
      id
      name
      logo
      customTsAndCs
    }
    name
    description
    status
    apps {
      id
      orgId
      name
      displayName
      developer
      icon
      version
      description
      tags
      isSystem
      extensions {
        extension
        description
      }
      autoStart
      requiresSave
      requiresReservation
      testPlatform
      disabled

    }
    inputs {
      id
      asset {
        id
        orgId
        name
        description
        parentFolder
        mimetype
        filename
        isFolder
        size
        url
        encoding
        status
      }
      targetPath
    }
    outputs {
      id
      name
      sequence
      isFolder
      extension
      autoSelectPath
    }
    maxWorkTime
    allowDownload
    allowUpload
    allowCut
    allowPaste
    allowPause
    lastUpdateDate
    instructions {
      type
      asset {
        id
        name
        filename
        mimetype
        isFolder
        size
        url
        description
        status
      }
      text
    }
    instanceTotals {
      count
      status
    }
    rubric {
      hasOverallScore
      hasCategoryScore
      overallScoreRange {
        low
        high
      }
      overallScoreDetails {
        id
        score
        name
        description
      }
      categories {
        id
        name
        description
        sequence
        scoreRange {
          low
          high
        }
        weight
        scoreDetails {
          id
          score
          name
          description
        }
      }
    }
    requiresSave
    requiresReservation
    testPlatform
  }
`;

export const MODIFY_STATUS_TEST_TEMPLATE_FIELDS = gql`
  fragment ModifyStatusTestTemplateFields on TestTemplate {
    id
    status
  }
`;

export const CORE_TEST_ASSET_FIELDS = gql`
  fragment CoreTestAssetFields on Asset {
    id
    orgId
    name
    filename
    mimetype
    isFolder
    size
    url
    encoding
    description
    parentFolder
    status
    createdBy
    creationDate
    lastUpdatedBy
    lastUpdateDate
  }
`;

export const CORE_ORG_FIELDS = gql`
  fragment CoreOrgFields on Org {
    id
    name
    subdomain
    customsubdomain
    customSendEmail
    customSendEmailPending
    address
    city
    state
    country
    postalcode
    timezone
    language
    region
    brandcolors {
      primarycolor
      secondarycolor
      thirdcolor
    }
    logo
    customsplashpage
    customTsAndCs
  }
`;

export const CORE_TEST_INSTANCE_FIELDS = gql`
  fragment CoreTestInstanceFields on TestInstance {
    id
    testTemplate {
      id
      org {
        id
        name
        logo
        customTsAndCs
        brandcolors {
          primarycolor
          secondarycolor
          thirdcolor
        }
      }
      name
      maxWorkTime
      allowDownload
      allowUpload
      allowCut
      allowPaste
      allowPause
      instructions {
        type
        asset {
          id
          name
          filename
          mimetype
          isFolder
          size
          url
          description
          status
        }
        text
      }
      rubric {
        hasOverallScore
        hasCategoryScore
        overallScoreRange {
          low
          high
        }
        overallScoreDetails {
          id
          score
          name
          description
        }
        categories {
          id
          name
          description
          sequence
          scoreRange {
            low
            high
          }
          weight
          scoreDetails {
            id
            score
            name
            description
          }
        }
      }
      outputs {
        id
        name
        sequence
        isFolder
        extension
        autoSelectPath
      }
      apps {
        id
        name
        displayName
        icon
        requiresSave
        requiresReservation
        testPlatform
        disabled
      }
      requiresSave
      requiresReservation
      testPlatform
    }
    assignedBy {
      id
      firstname
      lastname
    }
    assignee {
      id
      firstname
      lastname
    }
    assignmentDate
    invitee {
      firstname
      lastname
    }
    requiresAcceptance
    allowAssigneeReject
    initialTargetCompletionDate
    finalTargetCompletionDate
    earliestStartDate
    startDate
    completionDate
    status
    outputs {
      id
      path
      testOutput {
        id
        name
        isFolder
        extension
        sequence
        autoSelectPath
      }
    }
    workTimeRemaining
    reservations {
      id
      timeslot {
        start
        duration
      }
      licenses
    }
    scorecards {
      id
      juror {
        id
        firstname
        lastname
      }
      overallScore
      weightedCategoryScore
      comments
      status
      creationDate
      lastUpdateDate
      targetCompletionDate
      completionDate
      categoryScores {
        rubricCategory
        score
        comments
      }
    }
    tags {
      id
      name
    }
    testSession {
      connectionDate
      status
    }
  }
`;  

  export const CORE_ASSIGNED_BY_TEST_INSTANCE_FIELDS = gql`
  fragment CoreAssignedByTestInstanceFields on TestInstance {
    id
    testTemplate {
      id
      apps {
        id
        displayName
        icon
      }
    }
    assignedBy {
      id
      firstname
      lastname
    }
    assignee {
      id
      firstname
      lastname
    }
    assignmentDate
    invitee {
      firstname
      lastname
    }
    requiresAcceptance
    allowAssigneeReject
    initialTargetCompletionDate
    finalTargetCompletionDate
    earliestStartDate
    startDate
    completionDate
    status
    outputs {
      id
      path
      testOutput {
        id
        name
        isFolder
        extension
        autoSelectPath
      }
    }
    workTimeRemaining
    reservations {
      id
      timeslot {
        start
        duration
      }
      licenses
    }
    scorecards {
      id
      juror {
        id
        firstname
        lastname
      }
      overallScore
      weightedCategoryScore
      comments
      status
      creationDate
      lastUpdateDate
      targetCompletionDate
      completionDate
      categoryScores {
        rubricCategory
        score
        comments
      }
    }
    tags {
      id
      name
    }
  }
`;
