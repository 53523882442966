import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Typography, Button, Grid, TextField } from "@mui/material";
import { Waiting } from "../../components/Waiting";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useCustomSnackbar } from "../../components/CustomSnackbar";
import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";

export default function ForgotPasswordForm(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { lsEnqueueSnackbar } = useCustomSnackbar();

  // Set up the forgot password routine
  const FORGOT_PASSWORD = gql`
    mutation ForgotPassword($org: GUID, $user: String!) {
      forgotPassword(orgId: $org, username: $user)
    }
  `;
  const [
    forgotPassword,
    { loading: forgotPasswordLoading, error: forgotPasswordError },
  ] = useMutation(FORGOT_PASSWORD);

  let pageContent;

  const validationSchema = Yup.object({
    username: Yup.string().required(t("Required")),
  });

  const formik = useFormik({
    initialValues: {
      username: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const vars = {
        variables: {
          user: values.username,
          org: props.siteConfig.orgId,
        },
      };

      // Initiate the password reset process
      forgotPassword(vars)
        .then((res) => {
          if (res.errors) {
            lsEnqueueSnackbar(res.errors[0].message, {
              variant: "error",
            });
            setSubmitting(false);
          } else {
            lsEnqueueSnackbar(t("CodeResent"), {
              variant: "success",
            });
            props.setCurrentUsername(values.username);
            navigate("/app/resetpassword");
          }
        })
        .catch((e) => {
          lsEnqueueSnackbar(e.message, {
            variant: "error",
          });
          setSubmitting(false);
        });
    },
  });

  if (forgotPasswordLoading) {
    pageContent = <Waiting />;
  } else if (!forgotPasswordError) {
    pageContent = (
      <div>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2} paddingLeft="20%" paddingRight="20%">
            <Grid item xs={12}>
              <Typography component="h1" variant="h5" sx={{ marginBottom: 3, fontWeight: "bold" }}>
                {t("ForgotPassword")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography component="p" sx={{ marginBottom: 3 }}>
                {t("ForgotPasswordForm")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="username"
                label={t("UserName")}
                name="username"
                value={formik.values.username}
                onChange={formik.handleChange}
                error={
                  formik.touched.username && Boolean(formik.errors.username)
                }
                helperText={formik.touched.username && formik.errors.username}
              />
            </Grid>

            <Grid container justifyContent="flex-end">
              <Grid item sx={{ textAlign: "right" }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{ marginTop: 3, marginRight: 0, marginBottom: 1.5 }}
                >
                  {t("ResetPassword")}
                </Button>
                <br />
                <Link to="/app/resetpassword">{t("AlreadyHaveCode")}</Link>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </div>
    );
  } else {
    pageContent = (
      <Typography variant="body1">{forgotPasswordError.message}</Typography>
    );
  }
  return <div>{pageContent}</div>;
}
