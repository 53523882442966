import React, { useState } from "react";
import {
  Typography,
  Button,
  Grid,
  Checkbox,
  IconButton,
  Divider,
  Drawer,
  Toolbar,
  FormControlLabel,
} from "@mui/material";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useCustomSnackbar } from "../../components/CustomSnackbar";
import { Waiting } from "../../components/Waiting";
import CloseIcon from "@mui/icons-material/Close";
import AddAssetForm from "./AddAsset";
import { GET_TEST_ASSETS } from "../../graphql/queries";
import { useQuery } from "@apollo/client";
import { useAppContext } from "../../context/AppContext";

export default function SelectAssetForm(props) {
  const { t } = useTranslation();
  const { lsEnqueueSnackbar } = useCustomSnackbar();
  const [isAddAssetOpen, setIsAddAssetOpen] = useState(false);

  const { store } = useAppContext();

  // Handle closing of dialog
  const closeForm = () => {
    props.setIsSelectAssetOpen(false);
  };

  const handleOpenAddAsset = () => {
    setIsAddAssetOpen(true);
  };

  // Retrieve all assets
  const { data: asData, loading: asLoading } = useQuery(GET_TEST_ASSETS, {
    variables: {
      orgId: store.currentOrg.org.id,
      folderId: null,
      assetStatus: [],
    },
    onError: (e) => {
      lsEnqueueSnackbar(e.message, {
        variant: "error",
      });
    },
  });

  let assetData = [];

  const formik = useFormik({
    initialValues: {
      assets: [],
    },
    onSubmit: async (values, { setSubmitting }) => {
      const vars = {
        variables: {
          orgId: store.currentOrg.org.id,
          testTemplate: {
            id: store.currentTestTemplate.id,
            inputs: values.assets
              ? values.assets.map((r) => {
                  return JSON.parse(r);
                })
              : null,
          },
        },
      };
      props.onAssetSubmit(vars.variables.testTemplate.inputs);
      setSubmitting(false);
      formik.setFieldValue("assets", []);
      closeForm();
    },
  });

  let pageResults;
  if (asLoading) {
    pageResults = <Waiting />;
  } else if (asData) {
    assetData = asData.getTestAssets.slice();
    pageResults = (
      <Drawer anchor="right" open={props.isSelectAssetOpen} onClose={closeForm}>
        <form onSubmit={formik.handleSubmit}>
          <Toolbar>
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              {t("SelectAssets")}
            </Typography>
            <IconButton
              aria-label="close"
              edge="end"
              sx={{ position: "absolute", right: 1, top: 1, color: "grey" }}
              onClick={closeForm}>
              <CloseIcon />
            </IconButton>
          </Toolbar>

          <Divider />

          <Grid container spacing={2} sx={{ width: 640, flexShrink: 0, padding: 5 }}>
            <Grid item xs={12}>
              <Typography paragraph>{t("SelectAssetDialogText")}</Typography>
            </Grid>
            <Grid item xs={12}>
              {assetData
                .sort((a, b) => a.name.toUpperCase().localeCompare(b.name.toUpperCase()))
                .map((r) => {
                  return (
                    <Grid item xs={12} key={r.id}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            name="assets"
                            value={JSON.stringify(r)}
                            type="checkbox"
                            onChange={formik.handleChange}
                          />
                        }
                        label={r.name + " (" + r.filename + ")"}
                      />
                    </Grid>
                  );
                })}
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2} justifyContent="flex-end">
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={closeForm}
                    color="secondary"
                    sx={{ marginTop: 3, marginRight: 0, marginBottom: 1.5 }}>
                    {t("Cancel")}
                  </Button>
                  &nbsp;&nbsp;&nbsp;
                  <Button
                    variant="contained"
                    onClick={handleOpenAddAsset}
                    color="secondary"
                    sx={{ marginTop: 3, marginRight: 0, marginBottom: 1.5 }}>
                    {t("AddAsset")}
                  </Button>
                  &nbsp;&nbsp;&nbsp;
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{ marginTop: 3, marginRight: 0, marginBottom: 1.5 }}>
                    {t("Select")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {formik.isSubmitting ? <Waiting /> : ""}
        </form>
        <AddAssetForm isAddAssetOpen={isAddAssetOpen} setIsAddAssetOpen={setIsAddAssetOpen} />
      </Drawer>
    );
  } else {
    pageResults = "";
  }

  return pageResults;
}
