import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Typography,
  FormControlLabel,
  IconButton,
  TextField,
  Slider,
  Switch
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useField } from "formik";
import { useTranslation } from "react-i18next";

const TestTemplateRubricForm = React.memo(() => {
  const { t } = useTranslation();
  const [hasOverallScoreField] = useField("hasOverallScore");
  const [overallScoreDetailsField, , overallScoreDetailsHelpers] = useField("overallScoreDetails");
  const [overallScoreRangeField, , overallScoreRangeHelpers] = useField("overallScoreRange");
  const [hasCategoryScoreField] = useField("hasCategoryScore");
  const [categoriesField, , categoriesHelpers] = useField("categories");
  const [expandedIndex, setExpandedIndex] = useState(categoriesField.value?.length === 1 ? 0 : null);

  const handleOverallScoreRangeChange = (event, newValue) => {
    overallScoreRangeHelpers.setValue(newValue);
    let overallScoreDetails = [...overallScoreDetailsField.value];
    let rows = Array.from(
      { length: (newValue[1] - newValue[0]) / 1 + 1 },
      (_, i) => newValue[0] + i * 1
    );
    rows.forEach((r) => {
      if (!overallScoreDetails.find((d) => d.score === r)) {
        overallScoreDetails.push({
          score: r,
          name: "",
          description: "",
        });
      }
    });
    let newOverallScoreDetails = overallScoreDetails
      .filter(function (scores_el) {
        return (
          rows.filter(function (rows_el) {
            return rows_el === scores_el.score;
          }).length > 0
        );
      })
      .sort((a, b) => a.score - b.score);
    overallScoreDetailsHelpers.setValue(newOverallScoreDetails);
  };

  const handleAddCategory = () => {
    const categories = [...categoriesField.value];
    categories.push({
      sequence: "",
      name: "",
      description: "",
      scoreRange: [0, 1],
      weight: "",
      categoryScoreDetails: [
        {
          score: 0,
          name: "",
          description: "",
        },
        {
          score: 1,
          name: "",
          description: "",
        },
      ],
    });

    categoriesHelpers.setValue(categories);
    setExpandedIndex(categories.length - 1);
  };

  let pageResults = (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography component="p">{t("RubricsTitle")}</Typography>
      </Grid>

      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Switch
              {...hasOverallScoreField}
              checked={hasOverallScoreField.value}
              color="primary"
            />
          }
          label={t("HasOverallScore")}
        />
      </Grid>

      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Switch
              {...hasCategoryScoreField}
              checked={hasCategoryScoreField.value}
              color="primary"
            />
          }
          label={t("HasCategoryScore")}
        />
      </Grid>

      {hasOverallScoreField.value ? (
        <>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <Typography component="p" sx={{ marginBottom: 1 }}>
                  {t("OverallScoreRange")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Slider
                  {...overallScoreRangeField}
                  onChangeCommitted={handleOverallScoreRangeChange}
                  valueLabelDisplay="auto"
                  step={1}
                  marks
                  min={0}
                  max={10}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography component="p" sx={{ marginBottom: 2 }}>
              {t("OverallScoreDetails")}
            </Typography>
          </Grid>

          <OverallScoreDetailsList
            t={t}
            overallScoreDetailsField={overallScoreDetailsField}
          />
        </>
      ) : (
        ""
      )}
      {hasCategoryScoreField.value ? (
        <Grid item xs={12} sx={{ marginTop: 5 }}>
          <Grid
            container
            alignItems="center"
            justifyContent="flex-end"
            spacing={2}
          >
            <Grid item xs={10}>
              <Typography component="p" sx={{ marginBottom: 3 }}>
                {t("Categories")}
              </Typography>
            </Grid>

            <Grid item xs={2}>
              <Grid
                container
                justifyContent="flex-end"
                sx={{ marginBottom: 3 }}
              >
                <Grid item>
                  <Button
                    variant="outlined"
                    onClick={handleAddCategory}
                    sx={{ display: { xs: "none", sm: "inline-flex" } }}
                  >
                    {t("AddCategory")}
                  </Button>
                  <IconButton
                    onClick={handleAddCategory}
                    sx={{ display: { xs: "inline-flex", sm: "none" } }}
                  >
                    <AddIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <CategoriesList
            categoriesField={categoriesField}
            categoriesHelpers={categoriesHelpers}
            expandedIndex={expandedIndex}
            setExpandedIndex={setExpandedIndex}
            t={t}
          />
        </Grid>
      ) : (
        ""
      )}
    </Grid>
  );
  return pageResults;
});

const OverallScoreDetailsList = React.memo(({ t, overallScoreDetailsField }) => {
  return (
    <Grid container spacing={2}>
      {overallScoreDetailsField.value.map((_, index) => (
        <OverallScoreDetailItem key={index} index={index} t={t} />
      ))}
    </Grid>
  );
});

const OverallScoreDetailItem = React.memo(({ index, t }) => {
  const [scoreField, scoreMeta] = useField(`overallScoreDetails.${index}.score`);
  const [descriptionField, descriptionMeta] = useField(`overallScoreDetails.${index}.description`);
  const [nameField, nameMeta] = useField(`overallScoreDetails.${index}.name`);

  return (
    <Grid item xs={12}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={1}>
          <TextField
            variant="outlined"
            fullWidth
            id={`score${index}`}
            label={t("Score")}
            disabled
            {...scoreField}
            error={!!scoreMeta.error}
            helperText={scoreMeta.error}
          />
        </Grid>
        <Grid item xs={7}>
          <TextField
            variant="outlined"
            fullWidth
            required
            id={`description${index}`}
            label={t("Description")}
            multiline
            {...descriptionField}
            error={!!descriptionMeta.error}
            helperText={descriptionMeta.error}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            variant="outlined"
            fullWidth
            id={`name${index}`}
            label={t("Name")}
            {...nameField}
            error={!!nameMeta.error}
            helperText={nameMeta.error}
          />
        </Grid>
      </Grid>
    </Grid>
  );
});

const CategoriesList = React.memo(({
  categoriesField,
  categoriesHelpers,
  expandedIndex,
  setExpandedIndex,
  t
}) => {

  const handleExpand = (index) => {
    setExpandedIndex(index === expandedIndex ? null : index);
  };

  return (
    <Grid container spacing={2}>
      {categoriesField.value.map((category, index) => (
        <Grid item xs={12} key={index}>
          <Accordion
            expanded={expandedIndex === index}
            onChange={() => handleExpand(index)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>
                {category.name}
              </Typography>
            </AccordionSummary>
            {expandedIndex === index && (
              <AccordionDetails>
                <Category
                  key={index}
                  index={index}
                  categoriesField={categoriesField}
                  categoriesHelpers={categoriesHelpers}
                  t={t}
                />
              </AccordionDetails>
            )}
          </Accordion>
        </Grid>
      ))}
    </Grid>
  );
});

const Category = React.memo(({
  index,
  categoriesField,
  categoriesHelpers,
  t
}) => {
  const [sequenceField] = useField(`categories.${index}.sequence`);
  const [nameField, nameMeta] = useField(`categories.${index}.name`);
  const [descriptionField, descriptionMeta] = useField(`categories.${index}.description`);
  const [scoreField] = useField(`categories.${index}.scoreRange`);
  const [weightField, weightMeta] = useField(`categories.${index}.weight`);
  const [scoreDetailsField] = useField(`categories.${index}.categoryScoreDetails`);

  const handleCategoryScoreRangeChange = (event, newValue, currentCategory) => {
    let updatedCategories = [...categoriesField.value];
    updatedCategories[currentCategory] = {
      ...updatedCategories[currentCategory],
      scoreRange: newValue,
    };
    categoriesHelpers.setValue(updatedCategories);

    let categoryScoreDetails = [...categoriesField.value[currentCategory].categoryScoreDetails];
    let rows = Array.from(
      { length: (newValue[1] - newValue[0]) / 1 + 1 },
      (_, i) => newValue[0] + i * 1
    );
    rows.forEach((r) => {
      if (!categoryScoreDetails.find((d) => d.score === r)) {
        categoryScoreDetails.push({
          score: r,
          name: "",
          description: "",
        });
      }
    });
    let newCategoryScoreDetails = categoryScoreDetails
      .filter(function (scores_el) {
        return (
          rows.filter(function (rows_el) {
            return rows_el === scores_el.score;
          }).length > 0
        );
      })
      .sort((a, b) => a.score - b.score);

    updatedCategories = [...categoriesField.value];
    updatedCategories[currentCategory] = {
      ...updatedCategories[currentCategory],
      categoryScoreDetails: newCategoryScoreDetails,
    };
    categoriesHelpers.setValue(updatedCategories);
  };

  const handleRemoveCategory = (index) => {
    const categories = [...categoriesField.value];
    categories.splice(index, 1);
    categoriesHelpers.setValue(categories);
  };

  return (
    <Grid
      container
      spacing={2}
      alignItems="flex-start"
      sx={{ marginBottom: 8 }}
    >

      <Grid item xs={12}>
        <TextField
          {...sequenceField}
          variant="outlined"
          fullWidth
          id={"csequence" + index}
          label={t("Sequence")}
          sx={{ display: "none" }}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
          <Grid item xs={11}>
            <TextField
              {...nameField}
              variant="outlined"
              fullWidth
              required
              id={"cname" + index}
              label={t("Name")}
              error={nameMeta.touched && !!nameMeta.error}
              helperText={nameMeta.touched && nameMeta.error}
            />
          </Grid>
          {categoriesField.value?.length > 1 && (
            <Grid item xs={1}>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <IconButton
                    onClick={() =>
                      handleRemoveCategory(index)
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid item xs={11}>
        <TextField
          {...descriptionField}
          variant="outlined"
          fullWidth
          id={"cdescription" + index}
          label={t("Description")}
          multiline
          error={!!descriptionMeta.error}
          helperText={descriptionMeta.error}
        />
      </Grid>

      <Grid item xs={11}>
        <TextField
          {...weightField}
          variant="outlined"
          fullWidth
          required
          id={"cweight" + index}
          label={t("Weight")}
          error={weightMeta.touched && !!weightMeta.error}
          helperText={weightMeta.touched && weightMeta.error}
        />
      </Grid>

      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={11}>
            <Typography component="p" sx={{ marginBottom: 1 }}>
              {t("CategoryScoreRange")}
            </Typography>
          </Grid>
          <Grid item xs={11}>
            <Slider
              {...scoreField}
              onChangeCommitted={
                (event, newValue) => { handleCategoryScoreRangeChange(event, newValue, index); }
              }
              valueLabelDisplay="auto"
              step={1}
              marks
              min={0}
              max={10}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={11}>
            <Typography component="p" sx={{ marginBottom: 2 }}>
              {t("CategoryScoreDetails")}
            </Typography>
          </Grid>

          <Grid item xs={11}>
            <CategoryScoreDetailsList
              scoreDetailsField={scoreDetailsField}
              index={index}
              t={t}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});

const CategoryScoreDetailsList = React.memo(({
  scoreDetailsField,
  index,
  t
}) => {
  return (
    <Grid container spacing={2} alignItems="flex-start" sx={{ marginBottom: 8 }}>
      {scoreDetailsField.value.map((_, csindex) => (
        <Grid item xs={12} key={csindex}>
          <CategoryScoreDetailsItem
            csindex={csindex}
            index={index}
            t={t}
          />
        </Grid>
      ))}
    </Grid>
  );
});

const CategoryScoreDetailsItem = React.memo(({
  csindex,
  index,
  t
}) => {
  const [scoreField, scoreMeta] = useField(`categories.${index}.categoryScoreDetails.${csindex}.score`);
  const [descriptionField, descriptionMeta] =
    useField(`categories.${index}.categoryScoreDetails.${csindex}.description`);
  const [nameField, nameMeta] = useField(`categories.${index}.categoryScoreDetails.${csindex}.name`);

  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
    >
      <Grid item xs={1}>
        <TextField
          {...scoreField}
          variant="outlined"
          fullWidth
          id={"csscore" + csindex}
          label={t("Score")}
          disabled
          error={!!scoreMeta.error}
          helperText={scoreMeta.error}
        />
      </Grid>
      <Grid item xs={7}>
        <TextField
          {...descriptionField}
          variant="outlined"
          fullWidth
          required
          id={"csdescription" + csindex}
          label={t("Description")}
          multiline
          error={descriptionMeta.touched && !!descriptionMeta.error}
          helperText={descriptionMeta.touched && descriptionMeta.error}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          {...nameField}
          variant="outlined"
          fullWidth
          id={"csname" + csindex}
          label={t("Name")}
          error={!!nameMeta.error}
          helperText={nameMeta.error}
        />
      </Grid>
    </Grid>
  );
});

export default TestTemplateRubricForm;
