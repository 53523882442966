import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
} from "@mui/material";
import { Waiting } from "../../components/Waiting";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { SEND_UPDATE_ORG_SEND_EMAIL_RESULT } from "../../graphql/mutations";

export default function SendEmailVerifiedFailureForm({ 
  siteConfig, 
  setCurrentLanguage
}) {

  const { t, i18n } = useTranslation();
  const [resultSent, setResultSent] = useState(false);

  const [sendUpdateOrgSendEmailResult, { loading }] = useMutation(
    SEND_UPDATE_ORG_SEND_EMAIL_RESULT
  );

  // Send the results once as the form is loading
  useEffect(() => {
    setCurrentLanguage(siteConfig.urlQueryParms.language);
    i18n.changeLanguage(siteConfig.urlQueryParms.language);
    if (!resultSent) {
      setResultSent(true);
      sendUpdateOrgSendEmailResult({
        variables: {
          orgId: siteConfig.urlQueryParms.orgid,
          result: {
            status: "failure"
          }
        },
      });
    }
  }, [
    resultSent, 
    siteConfig.urlQueryParms.orgid, 
    siteConfig.urlQueryParms.language, 
    sendUpdateOrgSendEmailResult,
    i18n, 
    setCurrentLanguage
  ]);

  let pageResults;
  if (loading) {
    pageResults = <Waiting />;
  } else { 
    pageResults = (
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          {t("OrgSendEmailVerifiedFailureTitle")}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {t("OrgSendEmailVerifiedFailureBody", {email: siteConfig.urlQueryParms.email})}<br/><br/>{t("CanCloseBrowserWindow")}
        </Typography>
      </Grid>
    );
  }
  return pageResults;
}