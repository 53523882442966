import React, { useEffect, useState } from "react";
import { 
  Box, 
  Dialog 
} from "@mui/material";
import DashboardTable from "./DashboardTable";
import { useNavigate } from "react-router-dom";
import { Waiting } from "../Waiting";
import { t } from "i18next";
import { useAppContext } from "../../context/AppContext";
import { TestInstanceStatus, TestTemplateStatus } from "../../graphql/enums";
import { useMutation } from "@apollo/client";
import { handleCacheUpdate } from "../../cache";
import { GET_TEST_TEMPLATES } from "../../graphql/queries";
import {
  ACTIVATE_TEST_TEMPLATES,
  RECALL_TEST_TEMPLATES,
  RETIRE_TEST_TEMPLATES,
} from "../../graphql/mutations";
import { useCustomSnackbar } from "../CustomSnackbar";
import DeleteTestTemplateDialog from "../../views/HiringManager/DeleteTestTemplate";
import DashboardAvailableToAssignToolbar from "./DashboardAvailableToAssignToolbar";
import DashboardAvailableToAssignDrawer from "./DashboardAvailableToAssignDrawer";
import ConfirmationForm from "../ConfirmationForm";


export default function DashboardAvailableToAssign({
  activeTab,
  utData,
  utLoading,
  refreshTestTemplates
}) {
  const { store, actions } = useAppContext();
  const navigate = useNavigate();
  const { lsEnqueueSnackbar } = useCustomSnackbar();
  const [isDeleteTestTemplateDialogOpen, setIsDeleteTestTemplateDialogOpen] = useState(false);
  const [availableTestTemplatesData, setAvailableTestTemplatesData] = useState(false);
  const [testTemplateIdsForBulkDelete, setTestTemplateIdsForBulkDelete] = useState(false);
  const [confirmArchiveOpen, setConfirmArchiveOpen] = useState(false);
  const [selectedTemplates, setSelectedTemplates] = useState([]);

  const availableTestTemplatesHeaders = [
    { key: "name", value: t("TestName"), sortable: true, type: "string" },
    { key: "description", value: t("TestDescription"), sortable: false, type: "string" },
    { key: "instances", value: t("TestInstances"), sortable: false, type: "string" },
    { key: "status", value: t("Status"), sortable: true, type: "string" },
  ];

  const [activateTestTemplates] = useMutation(ACTIVATE_TEST_TEMPLATES);
  const [recallTestTemplates] = useMutation(RECALL_TEST_TEMPLATES);
  const [retireTestTemplates] = useMutation(RETIRE_TEST_TEMPLATES, {
    update: (cacheClient, response) => {
      if (response?.data?.retireTestTemplates) {
        for (let payload of response.data.retireTestTemplates) {
          if (payload.__typename === "TestTemplate") {
            let cacheRequest =
              payload.status === "retired" ? "delete" : "update";
            handleCacheUpdate(
              cacheRequest,
              cacheClient,
              response.data.retireTestTemplates,
              "id",
              GET_TEST_TEMPLATES,
              "getTestTemplates",
              null,
              {
                orgId: store.currentOrg?.org.id,
                templateStatus: [
                  TestTemplateStatus.Draft,
                  TestTemplateStatus.Active,
                  TestTemplateStatus.Deprecated,
                  TestTemplateStatus.Retired
                ],
              }
            );
          } else if (payload.__typename === "ExampleryError") {
            lsEnqueueSnackbar(payload.message, {
              variant: "error",
            });
          }
        }
      }
    },
  });

  const handleRefreshTemplates = () => {
    refreshTestTemplates();
  };

  const handleConfirmArchiveClose = (response) => {
    setConfirmArchiveOpen(false);
    if (response === 'Confirm') {
      handleRetireTemplates(selectedTemplates);
    }
    setSelectedTemplates([]);
  };

  const handleArchiveTestTemplates = (templates) => {
    setSelectedTemplates(templates);
    setConfirmArchiveOpen(true);
  };

  const handleRowActionClick = (action, row) => {
    switch (action) {
      case "edit":
        // instance is the whole actual test template object
        handleEditTestTemplate(row.instance);
        break;
      case "assignTestCandidate":
        navigate("/app/assignCandidateConfirmation");
        break;
      case "assignTest":
        handleAssignTestInstance(row.instance);
        break;
      case "activate":
        handleActivateTemplates([row.instance.id]);
        break;
      case "recall":
        handleRecallTemplates([row.instance.id]);
        break;
      case "archive":
        handleArchiveTestTemplates([row.instance.id]);
        break;
      case "copy":
        handleCopyTemplate(row.instance);
        break;
      case "delete":
        actions.setCurrentTestTemplate(row.instance);
        handleDeleteTestTemplates([row.instance.id]);
        break;

      default:
        console.log(action, row);
    }
  };

  const handleBulkActionClick = (action, selectedRows) => {
    switch (action) {
      case "activate":
        handleActivateTemplates(selectedRows.map((row) => row.id));
        break;
      case "recall":
        handleRecallTemplates(selectedRows.map((row) => row.id));
        break;
      case "archive":
        handleArchiveTestTemplates(selectedRows.map((row) => row.id));
        break;
      case "delete":
        handleDeleteTestTemplates(selectedRows.map((row) => row.id));
        break;
      default:
        break;
    }
  };

  // Create the table rows for test template table
  useEffect(() => {
    const availableTTData = utData?.getTestTemplates.filter((testTemplate) => {
      return testTemplate.status !== TestTemplateStatus.Retired;
    })
    .map((testTemplate) => {
      const { id, name, description, status, instanceTotals } = testTemplate;

      let countAssigned = 0,
        countInProgress = 0,
        countComplete = 0;

      // iterate through all items (statuses) and count instances
      // can be simplified if we don't do sums, but count per status
      instanceTotals.forEach((item) => {
        if (item.status === TestInstanceStatus.Assigned) {
          countAssigned += 1;
          return;
        }
        if (
          [TestInstanceStatus.Active, TestInstanceStatus.Suspended].includes(
            item.status
          )
        ) {
          countInProgress += 1;
          return;
        }
        if (
          [
            TestInstanceStatus.Unmarked,
            TestInstanceStatus.UnmarkedComplete,
            TestInstanceStatus.Marked,
            TestInstanceStatus.Cancelled,
          ].includes(item.status)
        ) {
          countComplete += 1;
          return;
        }
      });

      const instancesField = `${countAssigned} assigned, ${countInProgress} in progress, ${countComplete} complete`;
      // recall action is disabled when there have been instances created of the template
      const isRecallDisabled = !!(
        countAssigned ||
        countInProgress ||
        countComplete
      );

      return {
        id,
        fields: [
          { key: "name", value: name, type: "string" },
          { key: "description", value: description, type: "string" },
          { key: "instances", value: instancesField, type: "string" },
          { key: "status", value: t(status), type: "string" },
        ],
        actions: getTestTemplatesTableActions(status, isRecallDisabled),
        instance: testTemplate,
      };
    });

    setAvailableTestTemplatesData(availableTTData);
  }, [utData]);

  /**
   * Generate row level actions correspong to the test templates data provided.
   *
   * @param {TestTemplateStatus} status
   * @param {Boolean} isRecallDisabled
   * @returns
   */
  const getTestTemplatesTableActions = (status, isRecallDisabled) => {
    let menu;
    switch (status) {
      case TestTemplateStatus.Draft:
        if (store.currentOrg.rights.includes("editTest")) {
          menu = [
            { key: "activate", value: t("Activate") },
            { key: "edit", value: t("Edit") },
            { key: "delete", value: t("Delete") },
            { key: "copy", value: t("Copy") },
          ];
        }
        break;

      case TestTemplateStatus.Active:
        menu = [];
        if (store.currentOrg.rights.includes("assignUserToTest")) {
          menu.push({ key: "assignTest", value: t("Assign") });
        }
        if (store.currentOrg.rights.includes("editTest")) {
          menu.push({ key: "recall", value: t("Recall"), disabled: isRecallDisabled });
          menu.push({ key: "archive", value: t("Archive") });
          menu.push({ key: "copy", value: t("Copy") });
        }
        break;

      case TestTemplateStatus.Deprecated:
        if (store.currentOrg.rights.includes("editTest")) {
          menu = [
            { key: "activate", value: t("Activate") },
            { key: "copy", value: t("Copy") },
          ];
        }
        break;

      default:
        break;
    }
    return menu;  
  };

  /**
   * Dynamically create data for the bulk actions, corresponding
   * to the rows selected.
   *
   * @param {Array} selectedRows
   * @returns Array of DashboardTable friendly data for the bulk actions
   */
  const generateBulkActions = (selectedRows) => {
    let showActivate = false,
      showDelete = false,
      showRecall = false,
      showArchive = false;
    let isActivateDisabled = false,
      isDeleteDisabled = false,
      isRecallDisabled = false,
      isArchiveDisabled = false;

    /**
     * Initially, the values are all false. By iterating once through the selected values,
     * when certain conditions are met, we are turning some variables to true.
     *
     * The conditions follow similar pattern; If any of the selected rows have certain status,
     * the bulk actions for that status will be shown. If all of the selected rows share an action,
     * that action will be enabled. The only exception is the "recall" action that gets disabled
     * if any row has the recall option disabled on row level (see the useEffect hook)
     */
    selectedRows.forEach((row) => {
      if (row.instance.status === TestTemplateStatus.Draft) {
        showActivate = showDelete = true;
        isRecallDisabled = isArchiveDisabled = true;
        return;
      }
      if (row.instance.status === TestTemplateStatus.Active) {
        showRecall = showArchive = true;
        isActivateDisabled = isDeleteDisabled = true;

        // Check if the row has recall disabled on row level only if the bulk recall is not disabled already
        // to prevent unnecessary looping through the row actions. If so, disable on bulk level.
        if (
          !isRecallDisabled &&
          row.actions.find((a) => a.key === "recall").disabled
        ) {
          isRecallDisabled = true;
        }
        return;
      }
      if (row.instance.status === TestTemplateStatus.Deprecated) {
        showActivate = true;
        isDeleteDisabled = isRecallDisabled = isArchiveDisabled = true;
      }
    });

    return [
      {
        key: "activate",
        text: t("Activate"),
        visible: showActivate,
        disabled: isActivateDisabled,
      },
      {
        key: "recall",
        text: t("Recall"),
        visible: showRecall,
        disabled: isRecallDisabled,
      },
      {
        key: "archive",
        text: t("Archive"),
        visible: showArchive,
        disabled: isArchiveDisabled,
      },
      {
        key: "delete",
        text: t("Delete"),
        visible: showDelete,
        disabled: isDeleteDisabled,
      },
    ];
  };

  const handleEditTestTemplate = (template) => {
    actions.setCurrentTestTemplate(template);
    navigate("/app/edittesttemplate");
  };

  const handleDeleteTestTemplates = (templates) => {
    setTestTemplateIdsForBulkDelete(templates);
    setIsDeleteTestTemplateDialogOpen(true);
  };

  const handleAssignTestInstance = (template) => {
    actions.setCurrentTestTemplate(template);
    navigate("/app/assignCandidate");
  };

  const handleActivateTemplates = (templates) => {
    activateTestTemplates({
      variables: {
        orgId: store.currentOrg?.org.id,
        templateIds: templates,
      },
    })
      .then((res) => {
        if (res.errors) {
          lsEnqueueSnackbar(res.errors[0].message, {
            variant: "error",
          });
        } else {
          let statuses = res.data.activateTestTemplates;
          for (let status of statuses) {
            if (status["__typename"] === 'ExampleryError') {
              lsEnqueueSnackbar(status.message, {
                variant: "error",
              });      
            } else {
              lsEnqueueSnackbar(t("TestTemplateActivated"), {
                variant: "success",
              });    
            }
          }
        }
      })
      .catch((e) => {
        lsEnqueueSnackbar(e.message, {
          variant: "error",
        });
      }); // catch network errors
  };

  const handleRecallTemplates = (templates) => {
    recallTestTemplates({
      variables: {
        orgId: store.currentOrg?.org.id,
        templateIds: templates,
      },
    })
      .then((res) => {
        if (res.errors) {
          lsEnqueueSnackbar(res.errors[0].message, {
            variant: "error",
          });
        } else {
          let statuses = res.data.recallTestTemplates;
          for (let status of statuses) {
            if (status["__typename"] === 'ExampleryError') {
              lsEnqueueSnackbar(status.message, {
                variant: "error",
              });      
            } else {
              lsEnqueueSnackbar(t("TestTemplateRecalled"), {
                variant: "success",
              });    
            }
          }
        }
      })
      .catch((e) => {
        lsEnqueueSnackbar(e.message, {
          variant: "error",
        });
      }); // catch network errors
  };

  const handleRetireTemplates = (templates) => {
    retireTestTemplates({
      variables: {
        orgId: store.currentOrg?.org.id,
        templateIds: templates,
      },
    })
      .then((res) => {
        if (res.errors) {
          lsEnqueueSnackbar(res.errors[0].message, {
            variant: "error",
          });
        } else {
          let statuses = res.data.retireTestTemplates;
          for (let status of statuses) {
            if (status["__typename"] === 'ExampleryError') {
              lsEnqueueSnackbar(status.message, {
                variant: "error",
              });      
            } else {
              lsEnqueueSnackbar(t("TestTemplateArchived"), {
                variant: "success",
              });    
            }
          }
        }
      })
      .catch((e) => {
        lsEnqueueSnackbar(e.message, {
          variant: "error",
        });
      }); // catch network errors
  };

  const handleCopyTemplate = (template) => {
    actions.setCurrentTestTemplate(template);
    navigate("/app/copytesttemplate");
  };

  const handleAddNewTest = () => {
    navigate("/app/createtesttemplate");
  };

  let pageContents;
  if (utLoading) {
    pageContents = <Waiting />;
  } else {
    pageContents = (
      <div
        role="tabpanel"
        hidden={activeTab !== 0}
        id="tabpanel-0"
        aria-labelledby="tab-0"
      >
        <Box>
          <DashboardTable
            title={t("HiringManagerTableHeader")}
            headers={availableTestTemplatesHeaders}
            data={availableTestTemplatesData}
            onRowActionClick={handleRowActionClick}
            isLoading={utLoading}
            emptyText={t("NoTestTemplatesMessage")}
            generateBulkActions={generateBulkActions}
            onBulkActionClick={handleBulkActionClick}
            onRefresh={handleRefreshTemplates}
            isCollapsible={true}
            drawerContents={(row) =>
              <DashboardAvailableToAssignDrawer
                testTemplate={row.instance}
              />
            }
            toolbar={(isSelectingEnabled, setIsSelectingEnabled) =>
              <DashboardAvailableToAssignToolbar
                onCreateTestClick={handleAddNewTest} 
                isSelectingEnabled={isSelectingEnabled}
                setIsSelectingEnabled={setIsSelectingEnabled}
              />  
            }
            defaultOrder="asc"
            defaultOrderBy="name"
            defaultOrderType="string"
          />
        </Box>
        {isDeleteTestTemplateDialogOpen && (
          <DeleteTestTemplateDialog
            openDeleteTestTemplate={isDeleteTestTemplateDialogOpen}
            setOpenDeleteTestTemplate={setIsDeleteTestTemplateDialogOpen}
            setTestTemplateIdsForBulkDelete={setTestTemplateIdsForBulkDelete}
            testTemplateIdsForBulkDelete={testTemplateIdsForBulkDelete}
          />
        )}
        <Dialog
          open={confirmArchiveOpen}
          onClose={handleConfirmArchiveClose}
        >
          <ConfirmationForm
            handleConfirmationClose={handleConfirmArchiveClose}
            dialogTitle={t("ConfirmArchiveTestTemplateTitle")}
            dialogContent={t("ConfirmArchiveTestTemplateBody")}
            confirmText={t("Archive")}
            showCancel={true}
          />
        </Dialog>
      </div>
    );
  }

  return pageContents;
}
