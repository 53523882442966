import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Avatar,
  Button,
  Grid,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ImageIcon from "@mui/icons-material/Image";
import { Waiting } from "../../../components/Waiting";
import { useCustomSnackbar } from "../../../components/CustomSnackbar";
import { put as axiosput } from "axios";
import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { copySiteConfig } from "../../../siteConfig";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../../context/AppContext";
import SelectFileButton from "../../../components/FileSystem/SelectFileButton";

export default function OrgLogoForm(props) {
  const { t } = useTranslation();
  const { lsEnqueueSnackbar } = useCustomSnackbar();
  const origLogo = props.orgProfile ? props.orgProfile.current.logo : "";
  const [input, setInput] = useState({});
  const [logoLoaded, setLogoLoaded] = useState(false);
  const [openLogo, setOpenLogo] = useState(false);
  const [newLogoSrc, setNewLogoSrc] = useState("");
  const [newLogoSrcFile, setNewLogoSrcFile] = useState(null);
  const [logoSrc, setLogoSrc] = useState("");
  const [logoImageUploading, setLogoImageUploading] = useState(false);

  const { store } = useAppContext();

  // Ensures that logo is visible immediately on being set
  useEffect(() => {
    if (props.orgProfile) {
      setLogoSrc(props.orgProfile.current.logo);
    }
  }, [props]);

  useEffect(() => {
    if (!logoLoaded && props.orgProfile.current.logo !== "") {
      setInput({ ...input, logoPath: props.orgProfile.current.logo });
      setLogoLoaded(true);
    }
  }, [logoLoaded, props.orgProfile, input]);

  // Set up the get Logo Upload URL mutation
  const GET_ORG_LOGO_UPLOAD_URL = gql`
    mutation GetOrgLogoUploadURL($orgId: GUID!, $av: FileInfo!) {
      getOrgLogoUploadURL(orgId: $orgId, logo: $av) {
        uploadURL
        downloadURL
      }
    }
  `;

  const [getOrgLogoUploadURL, { loading: getOrgLogoUploadURLLoading }] = useMutation(GET_ORG_LOGO_UPLOAD_URL);

  // Handle selection of a new logo. The client request the logo upload URL and then
  // uploads the file.
  const handleConfirmLogo = async () => {
    if (newLogoSrc !== "") {
      const vars = {
        variables: {
          orgId: store.currentOrg.org.id,
          av: {
            size: newLogoSrcFile.size,
            mimetype: newLogoSrcFile.type,
          },
        },
      };

      setLogoImageUploading(true);
      // get the upload (and download) urls
      let result;
      try {
        result = await getOrgLogoUploadURL(vars);
      } catch (e) {
        lsEnqueueSnackbar(e.message, {
          variant: "error",
        });
      }
      if (!getOrgLogoUploadURLLoading) {
        if (result.errors && result.errors.length !== 0) {
          lsEnqueueSnackbar(result.errors[0].message, {
            variant: "error",
          });
        } else {
          const uploadURL = result.data.getOrgLogoUploadURL.uploadURL;
          const downloadURL = result.data.getOrgLogoUploadURL.downloadURL;

          // extract the cache-control setting from the upload URL
          let cacheControl = uploadURL.substr(uploadURL.indexOf("max-age%3D"));
          cacheControl = cacheControl.substr("max-age%3D".length, cacheControl.indexOf("&") - "max-age%3D".length);

          // upload the image using the upload URL
          const options = {
            headers: {
              "Content-Type": newLogoSrcFile.type,
              "x-amz-acl": "public-read",
              "cache-control": "max-age=" + cacheControl,
            },
          };
          try {
            await axiosput(uploadURL, newLogoSrcFile, options);
          } catch (e) {
            console.log(e);
            lsEnqueueSnackbar(e.message, {
              variant: "error",
            });
          }

          let op = { ...props.orgProfile.current };
          op.logo = downloadURL;
          props.setOrgProfile(op);

          // if this is an organization site, update the org logo in the siteconfig
          if (props.siteConfig.orgId) {
            let newSiteConfig = copySiteConfig(props.siteConfig);
            newSiteConfig.logo = downloadURL;
            props.setSiteConfig(newSiteConfig);
            props.siteConfigRef.current = newSiteConfig;
            props.setLogo(downloadURL);
          }
          setLogoSrc(downloadURL);
        }
      }
      setNewLogoSrc("");
      setNewLogoSrcFile(null);
      setLogoImageUploading(false);
    }
    setOpenLogo(false);
  };

  // Handles opening and closing of logo dialog
  const handleClickOpenLogo = () => {
    setOpenLogo(true);
  };

  const handleCloseLogo = () => {
    setInput({ ...input, logoPath: origLogo });
    setOpenLogo(false);
  };

  // Manages capture of state from interaction with file input field
  const handleInputChange = (e) => {
    console.info(e);
    if (e.target) {
      let value = e.target.value;
      if (e.target.name === "logoPath") {
        if (e.target.files && e.target.files.length !== 0 && e.target.validity.valid) {
          value = URL.createObjectURL(e.target.files[0]);
          setNewLogoSrc(value);
          setNewLogoSrcFile(e.target.files[0]);
          setInput({ ...input, [e.target.name]: value });
        }
      } else {
        setInput({ ...input, [e.target.name]: value });
      }
    }
  };

  return (
    <React.Fragment>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={1}>
          <Avatar variant="square" src={logoSrc} sx={{ height: "auto", width: "auto" }}>
            <ImageIcon />
          </Avatar>
        </Grid>
        <Grid item xs={11}>
          <Button variant="outlined" color="primary" onClick={handleClickOpenLogo}>
            {t("ChangeLogo")}
          </Button>
        </Grid>
      </Grid>
      <Dialog open={openLogo} onClose={handleCloseLogo} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          {t("ChangeLogo")}
          <IconButton
            aria-label="close"
            sx={{ position: "absolute", right: 1, top: 1, color: "grey" }}
            onClick={handleCloseLogo}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{t("ChangeLogoText")}</DialogContentText>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <Avatar variant="square" src={input.logoPath} sx={{ height: "auto", width: 40 }}>
              <ImageIcon fontSize="large" />
            </Avatar>
          </div>
          {getOrgLogoUploadURLLoading || logoImageUploading ? <Waiting /> : ""}
        </DialogContent>
        <DialogActions>
          <SelectFileButton
              onOpen={handleInputChange}
              title={t("SelectImage")}
              buttonVariant="contained"
              inputName="logoPath"
              accept="image/*"
          />
          <Button onClick={handleConfirmLogo} variant="contained" color="primary">
            {t("Confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
