// Modal.js
import React from "react";
import ReactDOM from "react-dom";
import {
  Backdrop,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Grid,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

const modalRoot = document.getElementById("modal-root");

const Modal = ({
  open,
  onClose,
  persistent,
  backdrop,
  title,
  description,
  children,
}) => {
  const { t } = useTranslation();

  if (!open) return null;

  const handleClose = (event) => {
    event.stopPropagation();
    onClose();
  };

  const modalContent = (
    <Card
      sx={{ minWidth: 275, zIndex: 2 }}
      onClick={(event) => event.stopPropagation()}
    >
      <CardHeader
        action={
          !persistent ? (
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          ) : null
        }
        title={title}
        subheader={description}
      />
      <CardContent>
        {children}

        {!persistent ? (
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                sx={{ marginTop: 3, marginRight: 0, marginBottom: 1.5 }}
                onClick={handleClose}
              >
                {t("Close")}
              </Button>
            </Grid>
          </Grid>
        ) : null}
      </CardContent>
    </Card>
  );

  let content;

  if (backdrop === "none") {
    content = (
      <div
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 1300,
        }}
      >
        {modalContent}
      </div>
    );
  } else if (backdrop === "transparent") {
    content = (
      <Backdrop
        sx={{
          color: "#fff",
          position: "fixed",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={open}
        onClick={!persistent ? handleClose : null}
      >
        {modalContent}
      </Backdrop>
    );
  } else if (backdrop === "solid") {
    content = (
      <div
        style={{
          position: "fixed",
          background: "rgba(255,255,255,1)",
          top: "0",
          bottom: "0",
          left: "0",
          right: "0",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 1300,
        }}
      >
        {modalContent}
      </div>
    );
  }

  return ReactDOM.createPortal(content, modalRoot);
};

export default Modal;
