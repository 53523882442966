import React, { useState } from "react";
import {
  Button,
  Grid,
  Paper,
  Typography,
  TextField,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../context/AppContext";
import { useMutation, useQuery } from "@apollo/client";
import { handleCacheUpdate } from "../../cache";
import { Waiting } from "../../components/Waiting";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { RESERVE_TEST_INSTANCE_TIMESLOT } from "../../graphql/mutations";
import { FIND_TEST_INSTANCE_TIMESLOTS } from "../../graphql/queries";
import useGuardedRoute from "../../hooks/useGuardedRoute";
import { useCustomSnackbar } from "../../components/CustomSnackbar";
import { format, startOfDay } from "date-fns";
import { enCA, frCA } from "date-fns/locale";
import { getCurrentLanguage } from "../../App";

const MakeReservation = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { lsEnqueueSnackbar } = useCustomSnackbar();
  const { store } = useAppContext();
  const [selectedDate, setSelectedDate] = useState(null);
  const [allTimes, setAllTimes] = useState([]);
  const [reservationSelected, setReservationSelected] = useState({
    state: "first",
    reservationStart: null,
    duration: null,
  });

  useGuardedRoute("/app", [store.currentTestInstance]);

  let currentLanguage = getCurrentLanguage();
  let dateFormat;
  if (currentLanguage === "English") {
    dateFormat = enCA;
  } else if (currentLanguage === "French") {
    dateFormat = frCA;
  }

  // Retrieve available reservation timeslots
  const { data: rtData, loading: rtLoading } = useQuery(
    FIND_TEST_INSTANCE_TIMESLOTS,
    {
      variables: {
        orgId: store.currentTestInstance?.testTemplate.org.id,
        instanceId: store.currentTestInstance?.id,
        duration: store.currentTestInstance?.testTemplate.allowPause ? 900 : 
          store.currentTestInstance?.workTimeRemaining,
      },
      onError: (e) => {
        lsEnqueueSnackbar(e.message, {
          variant: "error",
        });
      },
    }
  );

  const [reserveTestInstanceTimeslot] = useMutation(
    RESERVE_TEST_INSTANCE_TIMESLOT,
    {
      update: (cacheClient, response) => {
        if (response?.data) {
          handleCacheUpdate(
            "create",
            cacheClient,
            response.data.reserveTestInstanceTimeslot,
            "id",
            FIND_TEST_INSTANCE_TIMESLOTS,
            "findTestInstanceTimeslots",
            null,
            {}
          );
        }
      },
    }
  );

  const handleStartTest = () => {
    navigate("/app/testInstructionsBeforeTest");
  };

  function disableSelectionInvalidDates(date) {
      return date > new Date(store.currentTestInstance.finalTargetCompletionDate) ||
      (store.currentTestInstance.earliestStartDate && 
        date < new Date(store.currentTestInstance.earliestStartDate).setHours(0, 0, 0, 0));
  }

  const selectedLocalDayStart = (selectedDate) => {
    let localDayStart = startOfDay(selectedDate).getTime();
    return localDayStart;
  };

  const designateAllTimes = (selectedLocalDate, availableSlots) => {
    let allTimes = [];
    for (let i = 0; i < 24 * 4; ++i) {
      let start = selectedLocalDate + i * 15 * 60 * 1000;
      allTimes.push({
        start: start,
        selected: false,
        selectable: false,
        available: determineIsAvailable(availableSlots, start),
      });
    }
    setAllTimes(allTimes);
  };

  const handleSelectReservationTime = (s, allTimes) => {
    let resStart = reservationSelected.reservationStart;
    let newResStart;
    if (reservationSelected.state === "first" ) {
      if (s.available === true) {
        for (let time of allTimes) {
          if (time.start === s.start) {
            time.selected = true;
          } else if (
            time.start < s.start &&
            s.start - time.start <
              store.currentTestInstance.workTimeRemaining * 1000
          ) {
            time.selectable = true;
          } else if (
            time.start > s.start &&
            time.start - s.start <
              store.currentTestInstance.workTimeRemaining * 1000
          ) {
            time.selectable = true;
          }
        }
        setReservationSelected({
          state: "second",
          reservationStart: s.start,
          duration: 900,
        });
        setAllTimes(allTimes);
      }
    } else if (reservationSelected.state === "second") {
      if (s.available === true && s.selectable === true && s.start !== resStart) {
        let duration = (s.start > resStart) ? (s.start - resStart) / 1000 + 900 : 
          (resStart - s.start) / 1000 + 900;

        // If pause is not permitted for the test, the test taker must reserve the entire test duration 
        if (!store.currentTestInstance?.testTemplate.allowPause && 
          duration < store.currentTestInstance?.workTimeRemaining) {
            lsEnqueueSnackbar(t("MustReserveEntireTestTime"), {
              variant: "error",
            });     
        } else {
          for (let time of allTimes) {
            time.selectable = false;
            if (s.start > resStart) {
              if (time.start <= s.start && time.start > resStart) {
                if (store.currentTestInstance?.testTemplate.allowPause || 
                  duration >= store.currentTestInstance?.workTimeRemaining) {
                  newResStart = resStart;
                  time.selected = true;

                }
              }
            } else if (s.start < resStart) {
              if (time.start >= s.start && time.start < resStart) {
                if (store.currentTestInstance?.testTemplate.allowPause || 
                  duration >= store.currentTestInstance?.workTimeRemaining) {
                  newResStart = s.start;
                  time.selected = true;
                }
              }
            }
          }

          setReservationSelected({
            state: "third",
            reservationStart: newResStart,
            duration: duration
          });
          setAllTimes(allTimes);  
        }
      } else if (s.available === true && s.selectable === false && s.start !== resStart) {
        lsEnqueueSnackbar(t("SelectedTimeExceedsWorkTimeRemaining"), {
          variant: "error",
        }); 
      } 
    } else if (reservationSelected.state === "third" && s.available === true) {
      for (let time of allTimes) {
        time.selected = false;
        time.selectable = false;
        if (time.start === s.start) {
          time.selected = true;
        } else if (
          time.start < s.start &&
          s.start - time.start <
            store.currentTestInstance.workTimeRemaining * 1000
        ) {
          time.selectable = true;
        } else if (
          time.start > s.start &&
          time.start - s.start <
            store.currentTestInstance.workTimeRemaining * 1000
        ) {
          time.selectable = true;
        }
      }
      setReservationSelected({
        state: "second",
        reservationStart: s.start,
        duration: 900,
      });
      setAllTimes(allTimes);
    }
  };

  const determineIsAvailable = (availableSlots, start) => {
    let isAvailable = false;
    for (let slot of availableSlots) {
      let availableSlotStart = new Date(slot.start).getTime();
      if (
        start >= availableSlotStart &&
        start + 900000 <= availableSlotStart + slot.duration * 1000 &&
        start >= Date.now() &&
        (!store.currentTestInstance.earliestStartDate || 
          start >= new Date(store.currentTestInstance.earliestStartDate).getTime())
      ) {
        isAvailable = true;
        break;
      }
    }
    return isAvailable;
  };

  const determineCellColor = (s) => {
    let color;
    if (s.selected === true) {
      color = "lightYellow";
    } else if (s.available === true) {
      color = "white";
    } else {
      color = "#eee";
    }
    return color;
  };

  const determineTopEdge = (s) => {
    let edge;
    if (
      s.available === true &&
      s.selectable === true &&
      reservationSelected.reservationStart - s.start ===
        store.currentTestInstance.workTimeRemaining * 1000 - 900000
    ) {
      edge = 2;
    }
    return edge;
  };

  const determineBottomEdge = (s) => {
    let edge;
    if (
      s.available === true &&
      s.selectable === true &&
      s.start - reservationSelected.reservationStart ===
        store.currentTestInstance.workTimeRemaining * 1000 - 900000
    ) {
      edge = 2;
    }
    return edge;
  };

  const handleMakeReservation = () => {
    reserveTestInstanceTimeslot({
      variables: {
        orgId: store.currentTestInstance?.testTemplate.org.id,
        instanceId: store.currentTestInstance.id,
        start: new Date(reservationSelected.reservationStart),
        duration: reservationSelected.duration,
      },
    })
      .then((res) => {
        if (res.errors) {
          lsEnqueueSnackbar(res.errors[0].message, {
            variant: "error",
          });
        } else {
          lsEnqueueSnackbar(t("ReservationMade"), {
            variant: "success",
          });
          navigate("/app");
        }
      })
      .catch((e) => {
        lsEnqueueSnackbar(e.message, {
          variant: "error",
        });
      });
  };

  let pageResults;

  if (rtLoading) {
    pageResults = <Waiting />;
  } else if (rtData) {
    pageResults = (
      <Grid container spacing={2} paddingLeft="20%" paddingRight="20%">
        <Grid item xs={12}>
          <Typography
            component="h1"
            variant="h5"
            sx={{ marginBottom: 3, fontWeight: "bold" }}
          >
            {t("MakeReservationTitle")}
          </Typography>
        </Grid>

        {determineIsAvailable(
          rtData.findTestInstanceTimeslots,
          Math.ceil(Date.now() / 900000) * 900000
        ) ? (
          <>
            <Grid item xs={12}>
              <Typography component="p">{t("StartTestExplanation")}</Typography>
            </Grid>

            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ marginTop: 3, marginRight: 0, marginBottom: 1.5 }}
                  onClick={handleStartTest}
                >
                  {t("StartTestNow")}
                </Button>
              </Grid>
            </Grid>
          </>
        ) : (
          ""
        )}

        <Grid item xs={12}>
          <Typography component="p">
            {t("MakeReservationExplanation")}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label={t("ReservationDate")}
              value={selectedDate}
              disablePast
              shouldDisableDate={disableSelectionInvalidDates}
              onChange={(newValue) => {
                designateAllTimes(
                  selectedLocalDayStart(newValue),
                  rtData.findTestInstanceTimeslots
                );
                setSelectedDate(newValue);
              }}
              sx={{ width: '100%' }}
              inputVariant="outlined"
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="simple table"
            >
              <TableBody>
                {allTimes ? (
                  allTimes.map((s, idx) => {
                    return (
                      <TableRow key={"row" + idx}>
                        <TableCell
                          bgcolor={determineCellColor(s)}
                          sx={{
                            borderTop: determineTopEdge(s),
                            borderBottom: determineBottomEdge(s),
                          }}
                          onClick={() => {
                            handleSelectReservationTime(s, allTimes);
                          }}
                        >
                          {format(new Date(s.start), "p", {
                            locale: dateFormat,
                          })}
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell>{""}</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              sx={{ marginTop: 3, marginRight: 0, marginBottom: 1.5 }}
              onClick={handleMakeReservation}
            >
              {t("ReserveTime")}
            </Button>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography component="p">
            {t("DoNotStartOrMakeReservationExplanation")}
          </Typography>
        </Grid>

        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              sx={{ marginTop: 3, marginRight: 0, marginBottom: 1.5 }}
              onClick={() => navigate("/app")}
            >
              {t("ReturnToDashboard")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return pageResults;
};

export default MakeReservation;
