import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  FormControl,
  FormHelperText
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import * as Yup from "yup";
import { useFormik } from "formik";
import { t } from "i18next";
import { useMutation } from "@apollo/client";
import { handleCacheUpdate } from "../../cache";
import { GET_TEST_INSTANCES_ASSIGNED_BY_ME } from "../../graphql/queries";
import { EXTEND_TEST_INSTANCE } from "../../graphql/mutations";
import { useCustomSnackbar } from "../../components/CustomSnackbar";
import { useAppContext } from "../../context/AppContext";


export default function ExtendTestInstanceForm({
  open,
  handleClose,
  orgId,
  instanceId
}) {

  const { lsEnqueueSnackbar } = useCustomSnackbar();
  const { store } = useAppContext();

  const [extendTestInstance] = useMutation(EXTEND_TEST_INSTANCE, {
    update: (cacheClient, response) => {
      if (response?.data) {
        handleCacheUpdate(
          "update",
          cacheClient,
          response.data.extendTestInstance,
          "id",
          GET_TEST_INSTANCES_ASSIGNED_BY_ME,
          "getTestInstancesAssignedByMe",
          null,
          { 
            orgId: orgId,
            options: {
              filtering: store.assignedByMeFilter?.length > 0 ? store.assignedByMeFilter : null,
            }
          }
        );
      }
    },
  });

  const validationSchema = Yup.object({
    targetCompletionDate: Yup.date().required(t("Required")),
  });

  const formik = useFormik({
    initialValues: {
      targetCompletionDate: new Date()
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const vars = {
        variables: {
          orgId: orgId,
          instanceId: instanceId,
          completionDate: values.targetCompletionDate,
        },
      };

      extendTestInstance(vars)
        .then((res) => {
          if (res.errors) {
            lsEnqueueSnackbar(res.errors[0].message, {
              variant: "error",
            });
            setSubmitting(false);
          } else {
            lsEnqueueSnackbar(t("TestInstanceExtended"), {
              variant: "success",
            });
            setSubmitting(false);
            handleClose();
          }
        })
        .catch((e) => {
          lsEnqueueSnackbar(t("ErrorWithTestInstanceExtension"), {
            variant: "error",
          });
          setSubmitting(false);
          handleClose();
        }); // catch network errors
    },
  });

  let pageContents = (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle id="form-dialog-title">
          {t("ExtendTestInstanceTitle")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("ExtendTestInstanceExplanation")}
          </DialogContentText>
          <Box sx={{ paddingTop: 3 }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <FormControl
                fullWidth
                error={formik.touched.targetCompletionDate && Boolean(formik.errors.targetCompletionDate)}
              >
                <DatePicker
                  id="targetCompletionDate"
                  name="targetCompletionDate"
                  label={t("TargetCompletionDate")}
                  value={formik.values.targetCompletionDate}
                  onChange={(value) =>
                    formik.setFieldValue("targetCompletionDate", value)
                  }
                  sx={{ width: '100%' }}
                  inputVariant="outlined"
                />
                <FormHelperText>
                  {formik.touched.targetCompletionDate && formik.errors.targetCompletionDate}
                </FormHelperText>
              </FormControl>
            </LocalizationProvider>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="secondary"
            variant="contained"
          >
            {t("Cancel")}
          </Button>
          <Button
            onClick={formik.handleSubmit}
            color="primary"
            variant="contained"
          >
            {t("Confirm")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );

  return pageContents;
}