import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Typography, Button, Grid, TextField, Dialog } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useCustomSnackbar } from "../../components/CustomSnackbar";
import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { Waiting } from "../../components/Waiting";
import ConfirmationForm from "../../components/ConfirmationForm";

export default function CheckEmailForm(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { lsEnqueueSnackbar } = useCustomSnackbar();
  const  [confirmSignupComplete, setConfirmSignupComplete] = useState(false);

  const handleConfirmSignupCompleteClose = () => {
    setConfirmSignupComplete(false);
    navigate("/app/signin");
  };

  // Set up the signUp LeadStory API to
  const CONFIRM_SIGN_UP = gql`
    mutation ConfirmSignUp($user: String!, $code: String!) {
      confirmSignUp(username: $user, confirmationCode: $code)
    }
  `;
  const [confirmSignUp, { loading: confirmSignUpLoading }] =
    useMutation(CONFIRM_SIGN_UP);

  const validationSchema = Yup.object({
    username: Yup.string().required(t("Required")),
    verificationcode: Yup.string().required(t("Required")),
  });

  const formik = useFormik({
    initialValues: {
      username: props.getSignUpUsername(),
      verificationcode: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const vars = {
        variables: {
          code: values.verificationcode.trim(),
          user: values.username.trim(),
        },
      };

      // Invoke the sign up confirmation routine
      confirmSignUp(vars)
        .then((res) => {
          if (res.errors) {
            lsEnqueueSnackbar(res.errors[0].message, {
              variant: "error",
            });
            setSubmitting(false);
          } else {
            setConfirmSignupComplete(true);
            props.setSignUpUsername("");
            // lsEnqueueSnackbar(t("SignUpConfirmed"), {
            //   variant: "success",
            // });
            // navigate("/app");
          }
        })
        .catch((e) => {
          lsEnqueueSnackbar(e.message, {
            variant: "error",
          });
          setSubmitting(false);
        });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      {confirmSignUpLoading ? <Waiting /> : ""}
      <Grid container spacing={2} paddingLeft="20%" paddingRight="20%">
        <Grid item xs={12}>
          <Typography
            component="h1"
            variant="h5"
            sx={{ marginBottom: 3, fontWeight: "bold" }}
          >
            {t("CheckYourEmail")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography component="p" sx={{ marginBottom: 3 }}>
            {t("ShouldHaveReceivedEmail")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            required
            fullWidth
            id="username"
            label={t("UserName")}
            name="username"
            value={formik.values.username}
            onChange={formik.handleChange}
            error={formik.touched.username && Boolean(formik.errors.username)}
            helperText={formik.touched.username && formik.errors.username}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            variant="outlined"
            required
            fullWidth
            name="verificationcode"
            label={t("VerificationCode")}
            id="verificationcode"
            value={formik.values.verificationcode}
            onChange={formik.handleChange}
            error={
              formik.touched.verificationcode &&
              Boolean(formik.errors.verificationcode)
            }
            helperText={
              formik.touched.verificationcode && formik.errors.verificationcode
            }
          />
        </Grid>

        <Grid container justifyContent="flex-end">
          <Grid item sx={{ textAlign: "right" }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{ marginTop: 3, marginRight: 0, marginBottom: 1.5 }}
            >
              {t("ConfirmAccount")}
            </Button>

            <br />

            <Link to="/app/resendcodesignup">{t("DidntReceiveEmail")}</Link>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        open={confirmSignupComplete}
        onClose={handleConfirmSignupCompleteClose}
      >
        <ConfirmationForm
          handleConfirmationClose={handleConfirmSignupCompleteClose}
          dialogTitle={t("SignUpConfirmed")}
          dialogContent={t("OrganizationPending")}
          showCancel={false}
        />
      </Dialog>
    </form>
  );
}
