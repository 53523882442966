import { createContext, useContext, useState, useRef } from "react";

const AppStreamContext = createContext(null);

export const AppStreamContextWrapper = (props) => {
  const [store, setStore] = useState({
    appStreamContainerElement: useRef(null),
    appStreamEmbededRef: useRef(null),
    isWelcomePopupShown: false,
    isAppStreamFullscreen: false,
    isFileBrowserPopupShown: false,
    isInstructionsPopupShown: false,
    hasInstructionsAutoPoppedUp: false
  });

  const [actions] = useState({
    setIsWelcomePopupShown: (value) => {
      setStore((prevStore) => ({ ...prevStore, isWelcomePopupShown: value }));
    },
    setIsAppStreamFullscreen: (value) => {
      setStore((prevStore) => ({ ...prevStore, isAppStreamFullscreen: value }));
    },
    setIsFileBrowserPopupShown: (value) => {
      setStore((prevStore) => ({ ...prevStore, isFileBrowserPopupShown: value }));
    },
    setIsInstructionsPopupShown: (value) => {
      setStore((prevStore) => ({ ...prevStore, isInstructionsPopupShown: value }));
    },
    setHasInstructionsAutoPoppedUp: (value) => {
      setStore((prevStore) => ({ ...prevStore, hasInstructionsAutoPoppedUp: value }));
    },
  });

  return <AppStreamContext.Provider value={{ store, actions }}>{props.children}</AppStreamContext.Provider>;
};

export const useAppStreamContext = () => useContext(AppStreamContext);
