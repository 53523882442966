import { useEffect, useState } from "react";
import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import { useAppStreamContext } from "../../context/AppStreamContext";
import ScopedPopup from "../ScopedPopup";
import { t } from "i18next";

const AppStream = ({ sessionUrl, hideToolbar, children, setSessionActive, toolbarHeight }) => {
  const { store, actions } = useAppStreamContext();
  const [isSessionLoading, setIsSessionLoading] = useState(false);
  const [isSessionEnded, setIsSessionEnded] = useState(false);
  const [isSessionError, setIsSessionError] = useState(false);
  const [time, setTime] = useState(0);
  const startupTimeRemaining = 180 - (time % 180);

  const formatTime = (time) =>
    `${String(Math.floor(time / 60)).padStart(1, "0")}:${String(
      time % 60
    ).padStart(2, "0")}`;

  useEffect(() => {
    if (sessionUrl) {
      initAppStream();
    }
  }, [sessionUrl]);

  useEffect(() => {
    const timerId = setInterval(() => {
      setTime((t) => t + 1);
    }, 1000);
    return () => clearInterval(timerId);
  }, []);

  const handleAppStreamSessionStateChange = (
    event,
    sessionTerminationReason,
    sessionDisconnectionReason
  ) => {
    let status = event[window.AppStream.Embed.EventParams.STATUS];
    switch (window.AppStream.Embed.SessionStatus[status]) {
      case window.AppStream.Embed.SessionStatus.Unknown:
        console.log("Session Unknown");
        break;
      case window.AppStream.Embed.SessionStatus.Reserved:
        console.log("Session Reserved");
        break;
      case window.AppStream.Embed.SessionStatus.Started:
        console.log("Session Started");
        setIsSessionLoading(false);
        setSessionActive(true);
        setTimeout(() => actions.setIsWelcomePopupShown(true), 1000);
        break;
      case window.AppStream.Embed.SessionStatus.Disconnected:
        console.log("Session Disconnected", sessionDisconnectionReason);
        setIsSessionEnded(true);
        setSessionActive(false);
        break;
      case window.AppStream.Embed.SessionStatus.Ended:
        console.log("Session Ended", sessionTerminationReason);
        setIsSessionEnded(true);
        break;

      default:
        break;
    }
  };

  const handleAppStreamError = (event) => {
    console.log(`AppStream Error`, event);
    if (event.errorCode >= 400 && event.errorCode < 500) {
      console.log(`AppStream 4XX Error, closing the session...`);
      setIsSessionError(true);
      setIsSessionLoading(false);
      setSessionActive(false);
      if (store.appStreamEmbededRef.current) {
        store.appStreamEmbededRef.current.destroy();
        store.appStreamEmbededRef.current = null;
      }
    } else {
      console.log(`AppStream non 4XX Error, ignoring...`);
    }
  };

  const initAppStream = () => {
    console.log(`Initiating App Stream`);
    setIsSessionLoading(true);
    setIsSessionError(false);
    setSessionActive(false);

    const appStreamOptions = {
      sessionURL: sessionUrl,
      userInterfaceConfig: {
        [window.AppStream.Embed.Options.HIDDEN_ELEMENTS]: hideToolbar
          ? [window.AppStream.Embed.Elements.TOOLBAR]
          : [
              window.AppStream.Embed.Elements.CLIPBOARD_BUTTON,
              window.AppStream.Embed.Elements.SETTINGS_BUTTON,
              window.AppStream.Embed.Elements.STREAMING_MODE_BUTTON,
              window.AppStream.Embed.Elements.REGIONAL_SETTINGS_BUTTON,
              window.AppStream.Embed.Elements.COPY_LOCAL_BUTTON,
              window.AppStream.Embed.Elements.PASTE_REMOTE_BUTTON,
              window.AppStream.Embed.Elements.FILES_BUTTON,
            ],
      },
    };
    if (store.appStreamEmbededRef.current) {
      store.appStreamEmbededRef.current.destroy();
      store.appStreamEmbededRef.current = null;
    }

    // Remove any old appStream iframes that haven't already been closed
    let oldAppStreamIframes = document.querySelectorAll('[id=appstream-streaming-session]');
    console.log(`oldAppStreamIframes`, oldAppStreamIframes);
    if (oldAppStreamIframes.length > 0) {
      oldAppStreamIframes.forEach((oldAppStreamIframe, i) => {
        if (oldAppStreamIframe) {
          oldAppStreamIframe.parentNode.removeChild(oldAppStreamIframe);
        }
      });
    }

    const appStreamInstance = new window.AppStream.Embed(
      "appStream-container",
      appStreamOptions
    );

    appStreamInstance.addEventListener(
      window.AppStream.Embed.Events.SESSION_STATE_CHANGE,
      handleAppStreamSessionStateChange
    );

    appStreamInstance.addEventListener(
      window.AppStream.Embed.Events.SESSION_ERROR,
      handleAppStreamError
    );

    // store.appStreamInstance.addEventListener(
    //   window.AppStream.Embed.Events.SESSION_INTERFACE_STATE_CHANGE,
    //   updateUserInterfaceStateCallback
    // );

    store.appStreamEmbededRef.current = appStreamInstance;
  };

  return (
    <Grid container>
      <Grid item xs={12} sx={{ position: "relative" }}>
        {isSessionLoading && (
          <div
            style={{
              position: "absolute",
              background: "rgba(220,220,220,1)",
              top: "0",
              bottom: "0",
              left: "0",
              right: "0",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column"
            }}
          >
            <CircularProgress />
            <Typography component="h1" variant="h5" sx={{ marginTop: 3 }}>
              {t("EnvironmentStarting")}
            </Typography>
            <Typography component="h2" variant="h6">
              {formatTime(startupTimeRemaining)}
            </Typography>
          </div>
        )}
        {isSessionEnded && (
          <ScopedPopup
            open={true}
            persistent={true}
            title={t("TestSessionEnded")}
            description={t("TestSessionEndedDescription")}
            backdrop="solid"
          />
        )}
        {isSessionError && (
          <ScopedPopup
            open={true}
            persistent={true}
            title={t("TestSessionErrorTitle")}
            description={t("TestSessionErrorText")}
            backdrop="solid"
          >
            <Button variant="contained" onClick={initAppStream}>
              {t("RefreshEnvironment")}
            </Button>
          </ScopedPopup>
        )}
        {children}
        <div
          id="appStream-container"
          style={{
            height: store.isAppStreamFullscreen
              ? "calc(100vh)"
              : `calc(100vh - ${toolbarHeight}px)`
          }}
        />
      </Grid>
    </Grid>
  );
};

export default AppStream;
