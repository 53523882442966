import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
  ListItem,
  ListItemAvatar,
  ListItemText
} from "@mui/material";

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../context/AppContext";
import { useCustomSnackbar } from "../../components/CustomSnackbar";
import { useAppStreamContext } from "../../context/AppStreamContext";
import ScopedPopup from "../../components/ScopedPopup";
import { InstructionsType } from "../../graphql/enums";
import { GET_TEST_INSTANCE_INSTRUCTIONS_DOWNLOAD_URL } from "../../graphql/mutations";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DescriptionIcon from "@mui/icons-material/Description";
import { formatBytesToString } from "../../utils";

import {
  parseInstructionsString,
  downloadInstructions,
  getInstructionsDownloadURL,
} from "../../instructions";

import { useMutation } from "@apollo/client";

const TestInstructions = ({
  toolbarHeight
}) => {
  const { store } = useAppContext();
  const { store: asStore, actions: asActions } = useAppStreamContext();
  const { lsEnqueueSnackbar } = useCustomSnackbar();
  const { t } = useTranslation();
  const [isUrlLoading, setIsUrlLoading] = useState(false);
  const [instructionsURL, setInstructionsURL] = useState(null);

  const [getTestInstanceInstructionsDownloadURL] = useMutation(
    GET_TEST_INSTANCE_INSTRUCTIONS_DOWNLOAD_URL
  );

  const handleInstructionsDownload = () => {
    downloadInstructions(
      setIsUrlLoading,
      getTestInstanceInstructionsDownloadURL,
      store.currentTestInstance?.testTemplate.org.id,
      store.currentTestInstance.id,
      lsEnqueueSnackbar
    );
  };

  const testInstructions = store.currentTestInstance?.testTemplate.instructions;

  useEffect(() => {
    if (
      testInstructions &&
      testInstructions.type === InstructionsType.Asset &&
      testInstructions.asset
    ) {
      getInstructionsDownloadURL(
        getTestInstanceInstructionsDownloadURL,
        store.currentTestInstance?.testTemplate.org.id,
        store.currentTestInstance.id
      )
        .then((url) => {
          setInstructionsURL(url);
        })
        .catch((e) => {
          console.log("Error getting instructions URL: ", e);
          setInstructionsURL(null);
        });
    }
  }, [
    testInstructions,
    getTestInstanceInstructionsDownloadURL,
    store.currentTestInstance,
  ]);

  return (
    <ScopedPopup
      open={asStore.isInstructionsPopupShown}
      onClose={() => asActions.setIsInstructionsPopupShown(false)}
      persistent={false}
      title={t("TestInstructions")}
      backdrop="transparent"
    >
      {testInstructions && (
        <>
          {testInstructions.type === InstructionsType.Text && (
            <Grid item>
              <Box
                sx={{
                  overflowY: "auto",
                  height: `calc(80vh - ${toolbarHeight}px)`,
                  width: "90vw"
                }}
              >
                {parseInstructionsString(testInstructions.text)}
              </Box>
            </Grid>
          )}
          {testInstructions.type === InstructionsType.Asset && (
            <Grid item sx={{ width: "90vw", height: `calc(80vh - ${toolbarHeight}px)` }}>
              <Grid item>
                <object
                  width="100%"
                  height="350px"
                  data={instructionsURL + "#view=FitH"}
                  type="application/pdf"
                >
                  {t("TestInstructions")}
                </object>
              </Grid>
              <Grid item sx={{ marginTop: 3 }}>
                <Typography component="p" sx={{ marginBottom: 3 }}>
                  {t("DownloadTestIntroductionFileExplanation")}
                </Typography>
              </Grid>
              <Grid item>
                <ListItem
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="download"
                      onClick={handleInstructionsDownload}
                      disabled={isUrlLoading}
                    >
                      {isUrlLoading ? (
                        <CircularProgress size={24} />
                      ) : (
                        <FileDownloadIcon />
                      )}
                    </IconButton>
                  }
                >
                  <ListItemAvatar>
                    <DescriptionIcon />
                  </ListItemAvatar>
                  <ListItemText
                    primary={testInstructions.asset.filename}
                    secondary={formatBytesToString(
                      testInstructions.asset.size
                    )}
                  />
                </ListItem>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </ScopedPopup>);
};

export default TestInstructions;