import React from "react";
import {
  Typography,
  Button,
  Grid,
  Checkbox,
  IconButton,
  Divider,
  Drawer,
  Toolbar,
  Tooltip,
  FormControlLabel,
} from "@mui/material";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useCustomSnackbar } from "../../components/CustomSnackbar";
import { Waiting } from "../../components/Waiting";
import CloseIcon from "@mui/icons-material/Close";
import { UPDATE_USER_ROLES } from "../../graphql/mutations";
import { useMutation } from "@apollo/client";
import { refreshUserProfile } from "../../App";
import { useAppContext } from "../../context/AppContext";
import { Roles } from "../../components/Dashboard/DashboardTabs"

export default function EditUserRolesForm(props) {
  const { t } = useTranslation();
  const { lsEnqueueSnackbar } = useCustomSnackbar();

  const { store } = useAppContext();

  // API to update an OrgRole both in the database and in the cache
  // const [updateOrgRole] = useMutation(UPDATE_ORG_ROLE);

  const getCurrentRoles = () => props.activeUserData?.rights
    .find((r) => r.org.id === store.currentOrg.org.id).roles
    .map((role) => role.id) || [];

  // Handle closing of dialog
  const closeForm = () => {
    props.setActiveUserData(null);
    props.setOpenEditUserRoles(false);
  };

  let roleData = [];
  let pageResults;
  let activeUserData;

  const [updateUserRoles] = useMutation(UPDATE_USER_ROLES);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      roles: getCurrentRoles(),
    },
    onSubmit: async (values, { setSubmitting }) => {
      const vars = {
        variables: {
          orgId: store.currentOrg.org.id,
          ui: activeUserData.id,
          roles: values.roles,
        },
      };
      updateUserRoles(vars)
        .then((res) => {
          if (res.errors) {
            lsEnqueueSnackbar(res.errors[0].message, {
              variant: "error",
            });
            setSubmitting(false);
          } else {
            refreshUserProfile();
            lsEnqueueSnackbar(t("UserRolesUpdated"), {
              variant: "success",
            });
            setSubmitting(false);
            closeForm();
          }
        })
        .catch((e) => {
          lsEnqueueSnackbar(e.message, {
            variant: "error",
          });
          setSubmitting(false);
        });
    },
  });

  if (props.arLoading) {
    pageResults = <Waiting />;
  } else if (props.arData) {
    roleData = props.arData.orgRoles.slice();
    activeUserData = props.activeUserData;
    pageResults = (
      <Drawer anchor="right" open={props.openEditUserRoles} onClose={closeForm}>
        <form onSubmit={formik.handleSubmit}>
          <Toolbar>
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              {t("EditUserRoles")}
            </Typography>
            <IconButton
              aria-label="close"
              edge="end"
              sx={{ position: "absolute", right: 1, top: 1, color: "grey" }}
              onClick={closeForm}>
              <CloseIcon />
            </IconButton>
          </Toolbar>

          <Divider />

          <Grid container spacing={2} sx={{ width: 640, flexShrink: 0, padding: 5 }}>
            <Grid item xs={12}>
              <Typography paragraph>
                {activeUserData
                  ? t("SettingRolesFor") + " " + activeUserData.firstname + " " + activeUserData.lastname
                  : ""}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {roleData
                .sort((a, b) => a.name.toUpperCase().localeCompare(b.name.toUpperCase()))
                .map((r) => {
                  return (
                    <Grid item xs={12} key={r.id + "-grid"}>
                      <Tooltip
                        key={r.id}
                        sx={{
                          backgroundColor: "#fff",
                          color: "rgba(0, 0, 0, 0.87)",
                          maxWidth: 400,
                          fontSize: 12,
                          border: "1px solid #dadde9",
                        }}
                        placement="right"
                        title={
                          <React.Fragment>
                            <Typography variant="body1" color="inherit">
                              {r.isSystem ? t(r.description) : r.description}
                            </Typography>
                          </React.Fragment>
                        }>
                        <span>
                          <FormControlLabel
                            key={r.id + "-label"}
                            control={
                              <Checkbox
                                key={r.id}
                                size="small"
                                name="roles"
                                value={r.id}
                                disabled = {formik.values.roles.indexOf(r.id) !== -1 &&
                                            r.name === Roles.Owner && 
                                            store.userDetails.user.id === activeUserData?.id} 
                                onChange={formik.handleChange}
                                checked={formik.values.roles.indexOf(r.id) !== -1}
                              />
                            }
                            label={r.isSystem ? t(r.name.replaceAll(" ","")) : r.name}
                          />
                        </span>
                      </Tooltip>
                    </Grid>
                  );
                })}
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2} justifyContent="flex-end">
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={closeForm}
                    color="secondary"
                    sx={{ marginTop: 3, marginRight: 0, marginBottom: 1.5 }}>
                    {t("Cancel")}
                  </Button>
                  &nbsp;&nbsp;&nbsp;
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{ marginTop: 3, marginRight: 0, marginBottom: 1.5 }}>
                    {t("Update")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {formik.isSubmitting ? <Waiting /> : ""}
        </form>
      </Drawer>
    );
  } else {
    pageResults = "";
  }
  return pageResults;
}
