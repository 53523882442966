//  import { useState } from "react";
import {
  Button,
  Grid,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useCustomSnackbar } from "../../../components/CustomSnackbar";
import { UPDATE_ORG_SUBDOMAIN } from "../../../graphql/mutations";
import { useMutation } from "@apollo/client";
import { useAppContext } from "../../../context/AppContext";


export default function OrgSubdomainForm({
  opData,
  refreshOrgDetails
}) {
  const { t } = useTranslation();
  const { lsEnqueueSnackbar } = useCustomSnackbar();
  const { store } = useAppContext();
  const [updateOrgSubdomain] = useMutation(UPDATE_ORG_SUBDOMAIN);

  const validationSchema = Yup.object({
    customsubdomain: Yup.string().nullable().matches(/^[a-z0-9-]+$/, t("ValidSubdomain")).max(20, t("TwentyOrFewerChars")).notRequired(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      customsubdomain: opData?.orgProfile?.customsubdomain && 
        opData?.orgProfile?.customsubdomain !== opData?.orgProfile?.subdomain ? 
          opData.orgProfile.customsubdomain.trim() : "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      if (values.customsubdomain !== opData?.orgProfile?.customsubdomain) {
        const vars = {
          variables: {
            orgId: store.currentOrg.org.id,
            subdomain: (values.customsubdomain === "") ? null : values.customsubdomain,
          },
        };

        // Update the org subdomain
        updateOrgSubdomain(vars)
          .then((res) => {
            if (res.data) {
              lsEnqueueSnackbar(t("SubdomainChanged"), {
                variant: "success",
              });
            } else if (res.errors) {
              lsEnqueueSnackbar(res.errors[0].message, {
                variant: "error",
              });

              setSubmitting(false);
            }
            refreshOrgDetails();
          })
          .catch((e) => {
            lsEnqueueSnackbar(e.message, {
              variant: "error",
            });
            setSubmitting(false);
            refreshOrgDetails();
          }); // catch network errors
      }
    },
  });

  let pageResults;
  if (opData) {
    pageResults = (
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              id="customsubdomain"
              label={t("CustomSubdomain")}
              name="customsubdomain"
              value={formik.values.customsubdomain}
              onChange={formik.handleChange}
              error={formik.touched.customsubdomain && Boolean(formik.errors.customsubdomain)}
              helperText={formik.touched.customsubdomain && formik.errors.customsubdomain}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
              <Grid item xs={12} textAlign="right">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={formik.handleSubmit}
                >
                  {t("Update")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    );
  } else {
    pageResults = "";
  }
  return <div>{pageResults}</div>;
}
