import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  Button,
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useCustomSnackbar } from "../../components/CustomSnackbar";
import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { Waiting } from "../../components/Waiting";
import { ORG_ROLES } from "../../graphql/queries";
import { useQuery } from "@apollo/client";
import { useAppContext } from "../../context/AppContext";

export default function InviteStaffForm(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { lsEnqueueSnackbar } = useCustomSnackbar();

  const { store } = useAppContext();

  const INVITE_STAFF = gql`
    mutation InviteStaff($orgId: GUID!, $inv: Invitee!, $roles: [GUID!]!) {
      inviteStaff(orgId: $orgId, invitee: $inv, roles: $roles)
    }
  `;
  const [inviteStaff] = useMutation(INVITE_STAFF);

  const { data: orData, loading: orLoading } = useQuery(ORG_ROLES, {
    variables: {
      orgId: store.currentOrg.org.id,
    },
    onError: (e) => {
      lsEnqueueSnackbar(e.message, {
        variant: "error",
      });
    },
  });

  let roleData = orData?.orgRoles;

  const validationSchema = Yup.object({
    firstname: Yup.string()
      .max(15, t("FifteenOrFewerChars"))
      .required(t("Required")),
    lastname: Yup.string()
      .max(20, t("TwentyOrFewerChars"))
      .required(t("Required")),
    email: Yup.string().email(t("MustBeValidEmail")).required(t("Required")),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      firstname: "",
      lastname: "",
      roles: [],
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const vars = {
        variables: {
          orgId: store.currentOrg.org.id,
          inv: {
            firstname: values.firstname,
            lastname: values.lastname,
            email: values.email,
          },
          roles: values.roles,
        },
      };
      // Initiate the invite co-owner process
      inviteStaff(vars)
        .then((res) => {
          if (res.errors) {
            lsEnqueueSnackbar(res.errors[0].message, {
              variant: "error",
            });
            setSubmitting(false);
          } else {
            lsEnqueueSnackbar(t("UserInvited"), {
              variant: "success",
            });
            navigate("/app");
          }
        })
        .catch((e) => {
          lsEnqueueSnackbar(e.message, {
            variant: "error",
          });
          setSubmitting(false);
        });
    },
  });

  let pageResults;
  if (orLoading) {
    pageResults = <Waiting />;
  } else if (orData) {
    roleData = orData.orgRoles.slice();
    pageResults = (
      <>
        {formik.isSubmitting ? <Waiting /> : ""}
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2} paddingLeft="20%" paddingRight="20%">
            <Grid item xs={12}>
              <Typography
                component="h1"
                variant="h5"
                sx={{ marginBottom: 3, fontWeight: "bold" }}
              >
                {t("InviteToOrganization")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography component="p">{t("InviteUserText")}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="firstname"
                variant="outlined"
                required
                fullWidth
                id="firstname"
                label={t("FirstName")}
                value={formik.values.firstname}
                onChange={formik.handleChange}
                error={
                  formik.touched.firstname && Boolean(formik.errors.firstname)
                }
                helperText={formik.touched.firstname && formik.errors.firstname}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="lastname"
                label={t("LastName")}
                name="lastname"
                value={formik.values.lastname}
                onChange={formik.handleChange}
                error={
                  formik.touched.lastname && Boolean(formik.errors.lastname)
                }
                helperText={formik.touched.lastname && formik.errors.lastname}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label={t("EmailAddress")}
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                sx={{ marginTop: 3, textDecoration: "underline" }}
              >
                {t("AssignRoles")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {roleData
                .sort((a, b) =>
                  a.name.toUpperCase().localeCompare(b.name.toUpperCase())
                )
                .map((d) => {
                  return (
                    <FormControlLabel
                      key={d.id + "-label"}
                      control={
                        <Checkbox
                          key={d.id}
                          size="small"
                          name="roles"
                          value={d.id}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.confirm &&
                            Boolean(formik.errors.confirm)
                          }
                        />
                      }
                      label={d.isSystem ? t(d.name) : d.name}
                    />
                  );
                })}
            </Grid>
            <Grid container justifyContent="flex-end">
              <Grid item sx={{ textAlign: "right" }}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  sx={{ marginTop: 3, marginRight: 0, marginBottom: 1.5 }}
                >
                  {t("SendInvitation")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </>
    );
  } else {
    pageResults = "";
  }
  return <div>{pageResults}</div>;
}
