import React, { useState, useRef } from "react";
import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
  IconButton,
  Tabs,
  Tab,
  Box,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Waiting } from "../../../components/Waiting";
import { useTranslation } from "react-i18next";
import { useCustomSnackbar } from "../../../components/CustomSnackbar";
import { GET_ORG_PROFILE, GET_TEST_ASSETS } from "../../../graphql/queries";
import { NetworkStatus, useQuery } from "@apollo/client";
import { useAppContext } from "../../../context/AppContext";
import OrgLogoForm from "./OrgLogoForm";
import OrgDetailsForm from "./OrgDetailsForm";
import OrgSubdomainForm from "./OrgSubdomainForm";
import OrgEmailForm from "./OrgEmailForm";
import OrgTsAndCsForm from "./OrgTsAndCsForm";

function OrgConfigTabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      style={{ width: "100%" }}
      {...other}
    >
      {value === index &&
        <Grid container spacing={2} paddingLeft="10%" paddingRight="10%">
          {(children)}
        </Grid>
      }
    </div>
  );
}

OrgConfigTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}


export default function OrgConfigForm(props) {
  const { t } = useTranslation();
  const { lsEnqueueSnackbar } = useCustomSnackbar();
  const [tabNo, setTabNo] = useState(0);
  const { store } = useAppContext();

  const {
    data: getOPData,
    loading: getOPLoading,
    refetch: refreshOrgDetails,
    networkStatus
  } = useQuery(GET_ORG_PROFILE, {
    fetchPolicy: "network-only",
    variables: {
      orgId: store.currentOrg.org.id,
    },
    notifyOnNetworkStatusChange: true,
    onError: (e) => {
      lsEnqueueSnackbar(e.message, {
        variant: "error",
      });
    }
  });

  const { data: asData, loading: asLoading } = useQuery(GET_TEST_ASSETS, {
    variables: {
      orgId: store.currentOrg.org.id,
      folderId: null,
      assetStatus: [],
    },
    onError: (e) => {
      lsEnqueueSnackbar(e.message, {
        variant: "error",
      });
    },
  });

  let orgProfile = useRef(getOPData?.orgProfile);
  let setOrgProfile = (op) => {
    orgProfile.current = op;
  };

  const handleRefreshOrgDetails = async () => {
    await refreshOrgDetails();
  };

  const handleChangeTab = (event, newTabNo) => {
    setTabNo(newTabNo);
  };

  let pageResults;
  if (getOPLoading || asLoading || networkStatus === NetworkStatus.refetch) {
    pageResults = <Waiting />;
  } else if (getOPData && asData) {
    if (!orgProfile.current) {
      orgProfile.current = getOPData.orgProfile;
    }
    pageResults = (
      <>
        <Grid container spacing={2} paddingLeft="10%" paddingRight="10%">
          <Grid item xs={12}>
            <Grid container spacing={2} justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography
                  component="h1"
                  variant="h5"
                  sx={{ marginBottom: 3, fontWeight: "bold" }}
                >
                  {t("ConfigureOrganization")}
                </Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={handleRefreshOrgDetails}>
                  <RefreshIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box sx={{ flexGrow: 1, display: "flex" }}>
          <Tabs
            orientation="vertical"
            value={tabNo}
            onChange={handleChangeTab}
            aria-label="Vertical tabs"
            sx={{ borderRight: 1, borderColor: 'divider' }}
          >
            <Tab label={t("Logo")} {...a11yProps(0)} />
            <Tab label={t("Details")} {...a11yProps(1)} />
            <Tab label={t("Subdomain")} {...a11yProps(2)} />
            <Tab label={t("Email")} {...a11yProps(3)} />
            <Tab label={t("TermsAndConditions")} {...a11yProps(4)} />
          </Tabs>
          <OrgConfigTabPanel value={tabNo} index={0}>
            <Grid item xs={12}>
              <OrgLogoForm
                orgProfile={orgProfile}
                setOrgProfile={setOrgProfile}
                siteConfig={props.siteConfig}
                setSiteConfig={props.setSiteConfig}
                siteConfigRef={props.siteConfigRef}
                logo={props.logo}
                setLogo={props.setLogo}
              />
            </Grid>
          </OrgConfigTabPanel>
          <OrgConfigTabPanel value={tabNo} index={1}>
            <OrgDetailsForm
              siteConfig={props.siteConfig}
              setSiteConfig={props.setSiteConfig}
              siteConfigRef={props.siteConfigRef}
              setOrgName={props.setOrgName}
              opData={getOPData}
              asData={asData}
            />
          </OrgConfigTabPanel>
          <OrgConfigTabPanel value={tabNo} index={2}>
            <Grid item xs={12}>
              <OrgSubdomainForm 
                opData={getOPData}
                refreshOrgDetails={refreshOrgDetails}
              />
            </Grid>
          </OrgConfigTabPanel>
          <OrgConfigTabPanel value={tabNo} index={3}>
            <Grid item xs={12}>
              <OrgEmailForm
                opData={getOPData}
              />
            </Grid>
          </OrgConfigTabPanel>
          <OrgConfigTabPanel value={tabNo} index={4}>
            <Grid item xs={12}>
              <OrgTsAndCsForm
                opData={getOPData}
              />
            </Grid>
          </OrgConfigTabPanel>
        </Box >
      </>
    );
  } else {
    pageResults = "";
  }
  return <div>{pageResults}</div>;
}
