import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText
} from "@mui/material";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useCustomSnackbar } from "../../components/CustomSnackbar";
import * as Yup from "yup";
import { ASSIGN_TEST_INSTANCE_JUROR } from "../../graphql/mutations";
import { GET_ORG_ROLES, GET_USERS } from "../../graphql/queries";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import useGuardedRoute from "../../hooks/useGuardedRoute";
import { useAppContext } from "../../context/AppContext";
import Autocomplete from '@mui/material/Autocomplete';
import { Waiting } from "../../components/Waiting";
import { handleCacheUpdate } from "../../cache";
import {
  GET_TEST_INSTANCES_ASSIGNED_BY_ME,
  GET_TEST_INSTANCES_FOR_ME_TO_MARK,
} from "../../graphql/queries";

const AssignCandidateForm = () => {
  const { t } = useTranslation();
  const { lsEnqueueSnackbar } = useCustomSnackbar();
  const navigate = useNavigate();

  const { store } = useAppContext();

  useGuardedRoute("/app", [store.currentTestInstance]);

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  function handleOpenDialog() {
    setOpenConfirmationDialog(true);
  }

  function handleCloseDialog() {
    setOpenConfirmationDialog(false);
  }

  const [assignTestInstanceJuror] = useMutation(ASSIGN_TEST_INSTANCE_JUROR, {
    update: (cacheClient, response) => {
      if (response?.data) {
        let instance = response.data.assignJuror;
        handleCacheUpdate(
          "update",
          cacheClient,
          instance,
          "id",
          GET_TEST_INSTANCES_ASSIGNED_BY_ME,
          "getTestInstancesAssignedByMe",
          null,
          { 
            orgId: store.currentOrg?.org.id,
            options: {
              filtering: store.assignedByMeFilter?.length > 0 ? store.assignedByMeFilter : null,
            } 
          }
        );

        // if the user has assigned the test to themselves to mark, add the scorecard to the 
        // READY TO REVIEW dashboard as well.
        if (instance.scorecards.find((sc) => {
          return sc.juror.id === store.userDetails.user.id &&
            ['assigned', 'active', 'created', 'active_pending'].indexOf(sc.status) >= 0;
        })) {
          handleCacheUpdate(
            "create",
            cacheClient,
            instance,
            "id",
            GET_TEST_INSTANCES_FOR_ME_TO_MARK,
            "getTestInstancesForMeToMark",
            null,
            { 
              options: {
                filtering: [
                  {
                    field: 'status',
                    operation: 'eq',
                    values: {
                      stringValues: [
                        'unmarked'
                      ]
                    }
                  }
                ]
              }
            }
          );
        }
      }
    },
  });

  let [
    getUsers,
    {
      data: jurorsData,
      loading: jurorsLoading,
      refetch: refreshJurors,
    },
  ] = useLazyQuery(GET_USERS, {
    onError: (e) => {
      lsEnqueueSnackbar(e.message, {
        variant: "error",
      });
    },
    fetchPolicy: "no-cache"
  });

  const {
    data: roleData,
    loading: roleLoading,
    refetch: refreshRoles,
  } = useQuery(GET_ORG_ROLES, {
    variables: {
      orgId: store.currentOrg.org.id,
    },
    onError: (e) => {
      lsEnqueueSnackbar(e.message, {
        variant: "error",
      });
    },
  });

  useEffect(() => {
    if (roleData) {
      let jurorRoles = roleData.orgRoles.filter(role => ['Owner', 'Juror'].indexOf(role.name) > -1)
        .map(jr => jr.id);
      getUsers({
        variables: {
          orgId: store.currentOrg.org.id,
          roles: jurorRoles,
        },
      });
    }
  }, [roleData]);

  const validationSchema = Yup.object().shape({
    juror: Yup.object().shape({
      id: Yup.string().required(t("Required")),
    })
      .nullable()
      .required(t("Required")),
    targetCompletionDate: Yup.date().required(t("Required")),
  });

  const formik = useFormik({
    initialValues: {
      juror: {},
      targetCompletionDate: new Date(new Date().setHours(23, 59, 59, 999))
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const vars = {
        variables: {
          orgId: store.currentOrg.org.id,
          instanceId: store.currentTestInstance.id,
          judgingOptions: {
            targetCompletionDate: new Date(values.targetCompletionDate.setHours(23, 59, 59, 999)), // set to the end of the day
          },
          jurorId: values.juror.id,
        },
      };

      // Do something with the API
      assignTestInstanceJuror(vars)
        .then((res) => {
          if (res.errors) {
            lsEnqueueSnackbar(res.errors[0].message, {
              variant: "error",
            });
            setSubmitting(false);
          } else {
            lsEnqueueSnackbar(t("TestAssignedJuror"), {
              variant: "success",
            });
            setSubmitting(false);
            navigate("/app");
          }
        })
        .catch((e) => {
          lsEnqueueSnackbar(t("TestAssignedJurorError"), {
            variant: "error",
          });
          setSubmitting(false);
          setOpenConfirmationDialog(false);
        });
    },
  });
  let pageContents;
  if (roleLoading || jurorsLoading) {
    pageContents = <Waiting />;
  } else if (!jurorsData) {
    pageContents = (
      <Grid container spacing={2} paddingLeft="20%" paddingRight="20%">
        <Grid item xs={12}>
          <Typography
            component="h1"
            variant="h5"
            sx={{ marginBottom: 3, fontWeight: "bold" }}
          >
            {t("NoJurorsFound")}
          </Typography>
        </Grid>
      </Grid>
    )
  }
  else {
    pageContents = (
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2} paddingLeft="20%" paddingRight="20%">
          <Grid item xs={12}>
            <Typography
              component="h1"
              variant="h5"
              sx={{ marginBottom: 3, fontWeight: "bold" }}
            >
              {t("AssignJuror")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography component="p" sx={{ marginBottom: 3 }}>
              {t("AssignJurorExplanation")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              id="juror"
              name="juror"
              options={jurorsData.getUsers
                .sort((a, b) => (a.lastname + a.firstname).localeCompare(b.lastname + b.firstname))}
              getOptionLabel={option => `${option.firstname} ${option.lastname} ${option.email}`}
              style={{ width: 300 }}
              onChange={(e, value) => {
                formik.setFieldValue(
                  "juror",
                  value !== null ? value : formik.initialValues.juror
                );
              }}
              renderInput={params => (
                <TextField
                  margin="normal"
                  label="Jurors"
                  fullWidth
                  name="juror"
                  error={Boolean(formik.errors.juror?.id)}
                  helperText={formik.errors.juror?.id}
                  {...params}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <FormControl
                fullWidth
                error={formik.touched.targetCompletionDate && Boolean(formik.errors.targetCompletionDate)}
              >
                <DatePicker
                  id="targetCompletionDate"
                  name="targetCompletionDate"
                  label={t("TargetCompletionDate")}
                  value={formik.values.targetCompletionDate}
                  onChange={(value) =>
                    formik.setFieldValue("targetCompletionDate", value)
                  }
                  disablePast
                  sx={{ width: '100%' }}
                  inputVariant="outlined"
                />
                <FormHelperText>
                  {formik.touched.targetCompletionDate && formik.errors.targetCompletionDate}
                </FormHelperText>
              </FormControl>
            </LocalizationProvider>
          </Grid>
          <Grid container justifyContent="flex-end">
            <Grid item sx={{ textAlign: "right" }}>
              <Button
                onClick={() => {
                  handleOpenDialog();
                }}
                variant="contained"
                color="primary"
                sx={{ marginTop: 3 }}
              >
                {t("Confirm")}
              </Button>
            </Grid>
          </Grid>
          <Dialog
            open={openConfirmationDialog}
            onClose={handleCloseDialog}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              {t("AssignJurorConfirmation")}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {t("AssignJurorConfirmationExplanation")}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleCloseDialog}
                color="secondary"
                variant="contained"
              >
                {t("Cancel")}
              </Button>
              <Button
                onClick={formik.handleSubmit}
                color="primary"
                variant="contained"
              >
                {t("Confirm")}
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </form >

    );
  }

  return pageContents;
};

export default AssignCandidateForm;
