import React, { useState, useRef } from "react";
import {
  Box,
  Button,
  Grid,
  Popover,
  ToggleButton,
  Tooltip,
  Typography
} from "@mui/material";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import InstanceFilter from "./InstanceFilter";

import { useAppContext } from "../../context/AppContext";
import { t } from "i18next";

export default function DashboardInstanceFilter() {
  const { store, actions } = useAppContext();
  const [filterPopoverOpen, setFilterPopoverOpen] = useState(false);
  const [filter, setFilter] = useState([]);
  const filterButtonRef = useRef(null);
  const [filterValid, setFilterValid] = useState(false);

  const handleFilterSelected = () => {
    actions.setAssignedByMeFilter(filter);
    setFilterPopoverOpen(false);
    return;
  }

  const handleFilterClose = () => {
    setFilterPopoverOpen(false);
    return;
  }

  const handleFilterToggleChange = () => {
    if (filterPopoverOpen) {
      handleFilterClose();
    } else {
      setFilter(store.assignedByMeFilter);
      setFilterPopoverOpen(true);
    }
    return;
  }

  return (
    <React.Fragment>
      <ToggleButton
        ref={filterButtonRef}
        selected={filterPopoverOpen}
        onChange={handleFilterToggleChange}
        value={filterPopoverOpen}
      >
        <Tooltip title={t("FilterList")}>
          <FilterAltIcon 
            color={store.assignedByMeFilter?.length > 0 ? "primary" : "inherrit"}
          />
        </Tooltip>
      </ToggleButton>
      <Popover
        open={filterPopoverOpen}
        onClose={handleFilterClose}
        anchorEl={filterButtonRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box sx={{
          width: "75vw",
          minHeight: "100px",
          p: 2
        }}>
          <Grid container spacing={2}>
            <Grid item xs={9}>
              <Typography variant="h6">
                {t("FilterExercises")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <InstanceFilter
                filter={filter}
                setFilter={setFilter}
                setFilterValid={setFilterValid}
              />
            </Grid>
            {!filter?.length && 
              <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary">
                  {t("NoFilterApplied")}
                </Typography>
              </Grid>
            }
            <Grid item xs={12}>
              <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
                <Grid item>
                  <Button
                    onClick={handleFilterClose}
                    color="secondary"
                    variant="contained"
                  >
                    {t("Cancel")}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    onClick={handleFilterSelected}
                    color="primary"
                    variant="contained"
                    disabled={!filterValid}
                  >
                    {t("Confirm")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Popover>
    </React.Fragment>
  );
}