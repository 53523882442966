import { useState, useCallback } from "react";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Grid,
  FormControl,
  FormLabel,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
  FormHelperText,
  Tooltip,
  Radio,
  RadioGroup
} from "@mui/material";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import * as Yup from "yup";
import { useFormik } from "formik";
import { useMutation, useQuery } from "@apollo/client";
import { 
  GET_TEST_INSTANCES_REPORT_DOWNLOAD_URL,
  GET_TEST_INSTANCES_ROLLUP_REPORT_DOWNLOAD_URL
 } from "../../graphql/mutations";
import { GET_TEST_ASSETS } from "../../graphql/queries";
import { useCustomSnackbar } from "../../components/CustomSnackbar";
import { Waiting } from "../../components/Waiting";
import AddAssetForm from "./AddAsset";
import InstanceFilter from "../../components/InstanceQuery/InstanceFilter";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../context/AppContext";

export default function DownloadTestInstanceReportForm({
  open,
  handleClose,
  orgId,
  instanceIds,
  testTemplate
}) {
  const { lsEnqueueSnackbar } = useCustomSnackbar();
  const [getTestInstancesReportDownloadURL, { loading: grUrlLoading }] = 
    useMutation(GET_TEST_INSTANCES_REPORT_DOWNLOAD_URL);
  const [getTestInstancesRollupReportDownloadURL, { loading: grrUrlLoading }] = 
    useMutation(GET_TEST_INSTANCES_ROLLUP_REPORT_DOWNLOAD_URL);
  const [isUploadReportTemplateOpen, setIsUploadReportTemplateOpen] = useState(false);
  const [reportList, setReportList] = useState([]);
  const [hasCustomTemplate, setHasCustomTemplate] = useState(false);
  const [hasCustomCompareWith, setHasCustomCompareWith] = useState(false);
  const { t } = useTranslation();
  const { store } = useAppContext();

  const defaultCompareWith = [{
    field: "testTemplateId",
    operation: "eq",
    values: {
      stringValues: [testTemplate?.id]
    }
  }];

  // Retrieve all assets
  const { loading: asLoading } = useQuery(GET_TEST_ASSETS, {
    variables: {
      orgId: orgId || store.currentOrg.org.id,
      folderId: null,
      assetStatus: [],
    },
    fetchPolicy: "network-only",
    onError: (e) => {
      lsEnqueueSnackbar(e.message, {
        variant: "error",
      });
    },
    onCompleted: (data) => {
      setReportList(data.getTestAssets
        .filter((asset) => asset.mimetype === 'text/x-handlebars-template'));
    }
  });

  const handleOpenUploadReportTemplate = () => {
    setIsUploadReportTemplateOpen(true);
  };

  const handleReportTemplateUploadSuccess = (asset) => {
    formik.setFieldValue("reportTemplateId", asset.id);
  };

  const handleSetHasCustomTemplate = (event) => {
    const checked = event.target.checked ?? false;
    setHasCustomTemplate(checked);
    if (!checked) {
      formik.setFieldValue("reportTemplateId", null);
      formik.setFieldTouched("reportTemplateId", false);
      formik.setFieldError("reportTemplateId", "");  // Clear the error state
    }
  }

  const handleSetHasCustomCompareWith = (event) => {
    setHasCustomCompareWith(event.target.checked ?? false);

    if (event.target.checked && !formik.values.compareWith) {
      formik.setFieldValue("compareWith", defaultCompareWith);
    }
  }

  const formik = useFormik({
    initialValues: {
      reportType: "instance",
      reportTemplateId: null,
      compareWith: defaultCompareWith
    },
    enableReinitialize: true,
    validationSchema: () => {
      if (hasCustomTemplate) {
        return Yup.object().shape({
          reportTemplateId: Yup.string().required(t("Required")),
          compareWith: Yup.array().when("hasCustomCompareWith", {
            is: true,
            then: Yup.array().required(t("Required")),
          })
        });
      } else {
        return Yup.object().shape({
          compareWith: Yup.array().when("hasCustomCompareWith", {
            is: true,
            then: Yup.array().required(t("Required")),
          })
        });
      }
    },
    validate: (values) => {
      let errors = {};
      if (hasCustomCompareWith && values.compareWith.some(rule => !isFilterRuleComplete(rule))) {
        errors.compareWith = t("IncompleteFilterRules");
      }
      return errors;
    },
    onSubmit: async (values, { setSubmitting }) => {
      let vars = {
        variables: {
          orgId: orgId,
          instanceIds: instanceIds,
          reportTemplateId: hasCustomTemplate ? values.reportTemplateId : null
        }
      };
      let reportAPI;
      if (values.reportType === "instance") {
        vars.variables.compareWith = hasCustomCompareWith ? values.compareWith : null;
        reportAPI = getTestInstancesReportDownloadURL;
      } else {
        reportAPI = getTestInstancesRollupReportDownloadURL;
      }

      reportAPI(vars)
        .then((res) => {
          if (res.errors) {
            lsEnqueueSnackbar(res.errors[0].message, {
              variant: "error",
            });
            setSubmitting(false);
          } else {
            let url = (values.reportType === "instance") ? 
              res.data.getTestInstancesReportDownloadURL :
              res.data.getTestInstancesRollupReportDownloadURL;
            window.open(url, "_blank");
            setSubmitting(false);
            handleClose();
          }
        })
        .catch((e) => {
          lsEnqueueSnackbar(t("ErrorDownloadingReport"), {
            variant: "error",
          });
          setSubmitting(false);
          handleClose();
        }); // catch network errors
    },
  });

  const isFilterRuleComplete = useCallback((rule) => {
    return (rule.values.dateValues && rule.values.dateValues.length) ||
      (rule.values.stringValues && rule.values.stringValues.length)
  }, []);

  let pageContents;
  if (asLoading) {
    pageContents = <Waiting />;
  } else {
    pageContents = (
      <>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          maxWidth={"md"}
          fullWidth={true}
        >
          <form onSubmit={formik.handleSubmit}>
            <DialogTitle id="form-dialog-title">
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                  {t("DownloadTestInstanceReportTitle")}
                </Grid>
                <Grid item>
                  {(grUrlLoading || grrUrlLoading) && <CircularProgress size={24} />}
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent>
              <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                <Grid item sx={{ mb: 3 }}>
                  <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label">{t("ReportType")}</FormLabel>
                    <RadioGroup
                      aria-labelledby="exercise-report-or-roll-up-report"
                      row
                      defaultValue="instance"
                      name="reportType"
                      onChange={formik.handleChange}
                      value={formik.values.reportType}
                    >
                      <FormControlLabel value="instance" control={<Radio />} label={t("TestInstanceReport")} />
                      <FormControlLabel
                        value="rollup"
                        control={<Radio />}
                        label={t("RollupReport")}
                        disabled={instanceIds.length === 1}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
              <DialogContentText>
                {instanceIds.length > 1 ?
                  (formik.values.reportType === 'instance' ? 
                    t("DownloadTestInstanceReportExplanationMultiple", { numReports: instanceIds.length }) :
                    t("DownloadRollupReportExplanation", { numReports: instanceIds.length })
                  ) : t("DownloadTestInstanceReportExplanation")}
              </DialogContentText>
              <Box sx={{ paddingTop: 3 }}>
                <Grid container spaceing={2}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={hasCustomTemplate}
                          onChange={handleSetHasCustomTemplate}
                          name="customTemplate"
                          color="primary"
                        />
                      }
                      label={t("ChooseReportTemplate")}
                      sx={{
                        mb: 1
                      }}
                    />
                  </Grid>
                  <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item xs={10}>
                      <FormControl
                        variant="outlined"
                        disabled={!hasCustomTemplate}
                        error={hasCustomTemplate && formik.touched.reportTemplateId && Boolean(formik.errors.reportTemplateId)}
                        sx={{
                          margin: 0,
                          width: "100%",
                          display: "flex",
                          flexWrap: "nowrap",
                        }}
                      >
                        <InputLabel id="reportTemplates-label">
                          {t("ReportTemplates")}
                        </InputLabel>
                        <Select
                          label={t("ReportTemplates")}
                          labelId="reportTemplates-label"
                          id="reportTemplates"
                          name="reportTemplateId"
                          defaultValue=""
                          value={formik.values.reportTemplateId || ""}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          sx={{ mr: 2 }}
                        >
                          {reportList.map((i, index) => (
                            <MenuItem key={index} value={i.id}>
                              {i.name}
                            </MenuItem>
                          ))}
                        </Select>
                        {hasCustomTemplate && formik.touched.reportTemplateId &&
                          formik.errors.reportTemplateId ? (
                          <FormHelperText>{t("Required")}</FormHelperText>
                        ) : null}
                      </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                      <Button
                        disabled={!hasCustomTemplate}
                        style={{ height: "56px" }}
                        variant="outlined"
                        onClick={() => {
                          handleOpenUploadReportTemplate();
                        }}
                      >
                        {t("Upload")}
                      </Button>
                    </Grid>
                  </Grid>
                  {formik.values.reportType === "instance" && (
                    <Grid item xs={12}>
                      <Grid container alignItems="center" sx={{ mt: 3, mb: 1 }}>
                        <Grid item>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={hasCustomCompareWith}
                                onChange={handleSetHasCustomCompareWith}
                                name="customCompareWith"
                                color="primary"
                              />
                            }
                            label={t("DefineReportCompareWith")}
                          />
                        </Grid>
                        <Grid item>
                          <Tooltip 
                            title={t("CompareWithHelp")} 
                            disabled={formik.values.reportType === "rollup"} 
                            arrow
                          >
                            <HelpOutlineIcon fontSize="small" sx={{ display: 'block', margin: 'auto' }} />
                          </Tooltip>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <InstanceFilter
                          filter={formik.values.compareWith}
                          setFilter={(value) => formik.setFieldValue('compareWith', value)}
                          disabled={!hasCustomCompareWith}
                          allowSelectTestTemplate={false}
                          allowStatus={false}
                          allowDeleteAllRules={false}
                          testTemplateId={testTemplate?.id}
                          defaultFilter={defaultCompareWith[0]}
                        />
                      </Grid>
                    </Grid>
                  )} 
                </Grid>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleClose}
                color="secondary"
                variant="contained"
              >
                {t("Cancel")}
              </Button>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={grUrlLoading || grrUrlLoading}
              >
                {t("Confirm")}
              </Button>
            </DialogActions>
          </form>
        </Dialog>
        <AddAssetForm
          isAddAssetOpen={isUploadReportTemplateOpen}
          setIsAddAssetOpen={setIsUploadReportTemplateOpen}
          onUploadSuccess={handleReportTemplateUploadSuccess}
          zIndex={1500}
        />
      </>
    );
  }
  return pageContents;
}