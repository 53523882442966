import React from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function NonExistentPage(props) {
  const { t } = useTranslation();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography component="h1" variant="h5">
          {t("NoPageFound")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">{t("NoPageFoundText")}</Typography>
      </Grid>
    </Grid>
  );
}
