import { Grid, Switch } from "@mui/material";
import FileSystem from "../components/FileSystem/FileSystem";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import TestInstanceAnswerDroppable from "../components/TestInstance/TestInstanceAnswerDroppable";
import { useState } from "react";

const TestView = () => {
  const [draggable, setDraggable] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  
  return (
    <DndProvider backend={HTML5Backend}>
      <Grid container>
        <Grid item xs={12}>
          Dragging
          <Switch onClick={() => setDraggable((prevDraggable) => !prevDraggable)} />
        </Grid>
        <Grid item xs={6}>
          <FileSystem 
            draggable={draggable} 
            droppable={false}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
           />
        </Grid>
        <Grid item xs={6}>
          <TestInstanceAnswerDroppable></TestInstanceAnswerDroppable>
        </Grid>
      </Grid>
    </DndProvider>
  );
};

export default TestView;
