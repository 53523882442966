import React from "react";

import { Backdrop, CircularProgress } from "@mui/material";

export function Waiting() {
  return (
    <Backdrop open sx={{ zIndex: 2, color: "white" }}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
