import React from "react";
import {
  Grid,
  Typography,
  Switch
} from "@mui/material";

import { t } from "i18next";
import DashboardInstanceFilter from "../InstanceQuery/DashboardInstanceFilter";

export default function DashboardAssignedByMeToolbar({
  isSelectingEnabled,
  setIsSelectingEnabled
}) {
  return (
    <React.Fragment>
      <Grid item sx={{ marginRight: 2 }}>
        <DashboardInstanceFilter />
      </Grid>
      <Grid item sx={{ marginRight: 2 }}>
        <Typography component="label" variant="body2">
          {t("BulkActions")}
        </Typography>
        <Switch
          checked={isSelectingEnabled}
          onChange={(e) => setIsSelectingEnabled(e.target.checked)}
          color="primary"
        />
      </Grid>
    </React.Fragment>
  );
}
