import i18n from "i18next";

export const formatSecondsToTimeUnits = (timeInSeconds = 0) => {
  const days = timeInSeconds / (24 * 60 * 60);
  const hours = (timeInSeconds % (24 * 60 * 60)) / (60 * 60);
  const minutes = ((timeInSeconds % (24 * 60 * 60)) % (60 * 60)) / 60;
  const seconds = (((timeInSeconds % (24 * 60 * 60)) % (60 * 60)) % 60) / 60;
  return {
    days: Math.floor(days),
    hours: Math.floor(hours),
    minutes: Math.floor(minutes),
    seconds: Math.floor(seconds)
  };
};

export const formatSecondsToString = (timeInSeconds) => {
  const { days, hours, minutes } = formatSecondsToTimeUnits(timeInSeconds);
  let formattedString = "";

  if (days) {
    formattedString += `${days} ${
      days !== 1 ? i18n.t("Days") : i18n.t("Day")
    }, `;
  }
  // show hours and minutes
  formattedString += `${hours} ${
    hours !== 1 ? i18n.t("Hours") : i18n.t("Hour")
  }, ${minutes} ${minutes !== 1 ? i18n.t("Minutes") : i18n.t("Minute")}`;

  return formattedString;
};

export const formatBytesToString = (bytes) => {
  if (bytes >= 1024 * 1024 * 1024) {
    // First we convert bytes to gigabytes.
    // By multiplying the converted value by 10, then rounding it, then dividing by 10
    // we make sure the value is rounded to 1 decimal place only if needed.
    return `${Math.round((bytes / (1024 * 1024 * 1024)) * 10) / 10} GB`;
  }
  if (bytes >= 1024 * 1024) {
    return `${Math.round((bytes / (1024 * 1024)) * 10) / 10} MB`;
  }
  if (bytes >= 1024) {
    return `${Math.round((bytes / 1024) * 10) / 10} KB`;
  }
  return `${Math.round((bytes * 10) / 10)} B`;
};
