import React from "react";
import { useMutation } from "@apollo/client";
import { Button, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { handleCacheUpdate } from "../../cache";
import TestTemplateForm from "../../components/TestTemplate/TestTemplateForm";
import { GET_TEST_TEMPLATES } from "../../graphql/queries";
import { UPDATE_TEST_TEMPLATE } from "../../graphql/mutations";
import { TestTemplateStatus } from "../../graphql/enums";
import { useCustomSnackbar } from "../../components/CustomSnackbar";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../context/AppContext";
import { Waiting } from "../../components/Waiting";

const EditTestTemplate = (props) => {
  const { t } = useTranslation();
  const { lsEnqueueSnackbar } = useCustomSnackbar();
  const navigate = useNavigate();
  const { store } = useAppContext();

  const handleReturnToDashboard = () => {
    navigate("/app");
  };

  const [updateTestTemplate, { loading: uttLoading }] = useMutation(UPDATE_TEST_TEMPLATE, {
    update: (cache, results) => {
      if (results?.data) {
        handleCacheUpdate(
          "update",
          cache,
          results.data.updateTestTemplate,
          "id",
          GET_TEST_TEMPLATES,
          "getTestTemplates",
          null,
          {
            orgId: store.currentOrg?.org.id,
            templateStatus: [
              TestTemplateStatus.Draft,
              TestTemplateStatus.Active,
              TestTemplateStatus.Deprecated,
              TestTemplateStatus.Retired
            ],
          }
        );
      }
    },
  });

  const handleSubmitForm = (vars, setSubmitting) => {
    // Update the test template
    updateTestTemplate(vars)
      .then((res) => {
        if (res.errors) {
          lsEnqueueSnackbar(res.errors[0].message, {
            variant: "error",
          });
          setSubmitting(false);
        } else {
          lsEnqueueSnackbar(t("TestTemplateUpdated"), {
            variant: "success",
          });
          setSubmitting(false);
          navigate("/app");
        }
      })
      .catch((e) => {
        lsEnqueueSnackbar(e.message, {
          variant: "error",
        });
        setSubmitting(false);
      }); // catch network errors
  };

  let testTemplateFormRef = null;

  return (
    <React.Fragment>
      {uttLoading && <Waiting />}
      <Grid container spacing={2} paddingLeft="5%" paddingRight="5%">
        <Grid item xs={12}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography
                component="h1"
                variant="h5"
                sx={{ marginBottom: 3, fontWeight: "bold" }}
              >
                {t("EditTestTemplate")}
              </Typography>
            </Grid>
            <Grid item>
              <Grid container spacing={2}>
                <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    onClick={handleReturnToDashboard}
                  >
                    {t("Cancel")}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => testTemplateFormRef?.handleSubmitForm()}
                  >
                    {t("Update")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TestTemplateForm
            ref={(ref) => (testTemplateFormRef = ref)}
            handleSubmit={handleSubmitForm}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default EditTestTemplate;
