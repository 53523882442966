import { gql } from "@apollo/client";
import { getSiteOrgId, refreshUserProfile } from "./App";
import {
  INITIATE_SIGNIN,
  RESPOND_TO_SIGNIN_CHALLENGE,
} from "./graphql/queries";
import { copySiteConfig } from "./siteConfig";
import { enqueueMsg } from "./router";
import { createSubscriptionClient } from "./initApollo";

const ASRP = require("amazon-user-pool-srp-client");
export var gqlCli = null;
var setAppForceSignOut = null;
let jwt = null;
let conEventsSubscription = null; // connection events
let siteCfgEventsSubscription = null; // site configuration events
let subClient = null;
export let apolloSubClient = null;

// Sign in to the server.
// Inputs:
//   username  - Required String - The username or email address of the user signing in
//   password  - Required String - The user's account password. Note: password is not sent over
//                                 the web to the server. The API uses the SPA protocol instead.
//   gqlClient - Required Object - The Apollo client. The component that calls this function can
//                                 get the Apollo client using the withApollo() API.
//   setforceSignOut - Required function - the application function to set the forceSignedOut state
//   siteConfigRef - Required object - A referenence to the site configuration object
//   setSiteConfig - Required function - The application function to set the site configuration
// Returns: none
export async function initiateSignIn(
  username,
  password,
  gqlClient,
  setForceSignOut,
  siteConfigRef,
  setSiteConfig
) {
  const siteConfig = siteConfigRef.current;
  gqlCli = gqlClient; // save the GQL client for when we need to refresh tokens
  setAppForceSignOut = setForceSignOut; // save the setForceSignOut function for forced signout subscription message

  // initiate the sign in by passing the username to the server
  const orgId = siteConfig.orgId;
  const poolId = siteConfig.poolId;
  const userPoolId = poolId.split("_")[1];
  const srp = new ASRP.SRPClient(userPoolId);
  const srp_a = srp.calculateA();

  let vars = {
    un: username,
    srpa: srp_a,
  };
  let results;
  try {
    results = await gqlClient.query({
      query: INITIATE_SIGNIN,
      fetchPolicy: "no-cache",
      variables: vars,
    });

    if (results.errors) {
      console.log(results.errors[0]);
      throw results.errors[0];
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
  let challenge = results.data.initiateSignIn;
  let cp = {};
  for (let p of challenge.challengeParameters) {
    cp[p.paramName] = p.paramValue;
  }

  // respond to any challenges from the server. At a minimum we will respond
  // to a password challenge. In the future, we will have to pass other challenges
  // back to the user to let them respond approriately.
  switch (challenge.challengeName) {
    case "PASSWORD_VERIFIER":
      let hkdf;
      let dateNow;
      let signatureString;
      try {
        // generate the password verifier (i.e. the signatureString)
        hkdf = srp.getPasswordAuthenticationKey(
          cp.USER_ID_FOR_SRP,
          password,
          cp.SRP_B,
          cp.SALT
        );
        dateNow = ASRP.getNowString();
        signatureString = ASRP.calculateSignature(
          hkdf,
          userPoolId,
          cp.USER_ID_FOR_SRP,
          cp.SECRET_BLOCK,
          dateNow
        );
      } catch (e) {
        e.extensions = { code: "UNAUTHENTICATED" };
        throw e;
      }
      // build the challenge response variables
      vars = {
        org: orgId,
        resp: {
          username: username,
          session: challenge.session,
          challengeName: challenge.challengeName,
          challengeResponses: [
            {
              paramName: "PASSWORD_CLAIM_SIGNATURE",
              paramValue: signatureString,
            },
            {
              paramName: "PASSWORD_CLAIM_SECRET_BLOCK",
              paramValue: cp.SECRET_BLOCK,
            },
            { paramName: "TIMESTAMP", paramValue: dateNow },
          ],
        },
      };
      break;
    // note the server currently throws an exception for anything but PASSWORD_VERIFIER
    default:
      break;
  }
  // Call the respondToSignInChallenge API
  try {
    results = await gqlClient.query({
      query: RESPOND_TO_SIGNIN_CHALLENGE,
      variables: vars,
      fetchPolicy: "no-cache",
    });

    if (results.errors) {
      throw results.errors[0];
    }
  } catch (e) {
    throw e;
  }

  // only store the refresh token if it wasn't returned in a cookie.
  const token = (await isCookieSet())
    ? {
        idToken: results.data.respondToSignInChallenge.token.idToken,
        accessToken: results.data.respondToSignInChallenge.token.accessToken,
      }
    : results.data.respondToSignInChallenge.token;
  initToken(token);

  // set up the websocket connection all connection related subscriptions
  try {
    await setUpConnectionSubs(gqlClient, siteConfigRef, setSiteConfig, enqueueMsg);
  } catch (e) {
    console.log(e);
  }

  return token;
}

// Try to sign in automatically using an existing token
// Inputs: gqlClient - Required Object - the Apollo CLient Object
//         setForceSignOut - Required function - the application function to set the forceSignOut state
//         siteConfigRef - Required object - a reference to the site configuration
//         setSiteConfig - Required function - the application set site configuration function
// Returns: Boolean value indicating if the sign in was successful
export async function autoSignIn(
  gqlClient,
  setForceSignOut,
  siteConfigRef,
  setSiteConfig
) {
  gqlCli = gqlClient; // save the GQL client for when we need to refresh tokens
  setAppForceSignOut = setForceSignOut; // save the setForceSignOut function for forced signout subscription message

  let success = false;
  let token;
  try {
    token = await getNewToken();
  } catch (e) {
    if (e.extensions?.code !== 'NO_REFRESH_TOKEN') { 
      console.log(e);
    }
  }
  if (token && token !== "") {
    success = true;
    // set up the websocket connection all all connection related subscriptions
    try {
      await setUpConnectionSubs(gqlClient, siteConfigRef, setSiteConfig, enqueueMsg);
    } catch (e) {
      console.log(e);
    }
  }
  return success;
}

// Determines if the refreshToken cookie is set.
// Input: none
// Returns: true if the refreshToken cookie is set. false otherwise
export async function isCookieSet() {
  let cookieSet = false;
  const REFRESH_COOKIE_SET = gql`
    query RefreshCookieSet($oid: GUID) {
      refreshCookieSet(orgId: $oid)
    }
  `;
  let csResults = false;
  try {
    csResults = await gqlCli.query({
      variables: {
        oid: getSiteOrgId(),
      },
      query: REFRESH_COOKIE_SET,
      fetchPolicy: "no-cache",
    });
    if (csResults.errors) {
      cookieSet = false;
    } else {
      cookieSet = csResults.data.refreshCookieSet;
    }
  } catch (e) {
    cookieSet = false;
  }
  return cookieSet;
}

// Stores the token in session memory where it can be use for subsequent API calls
// Inputs: token - required Object - the token returned from initiateSignIn
export function initToken(token) {
  jwt = token;
}

// Clears the token from session memory after logout
export function clearToken() {
  jwt = null;

  // unsubscribe from connection events
  if (conEventsSubscription) {
    conEventsSubscription.unsubscribe();
    conEventsSubscription = null;
  }

  // unsubscribe from site configuration events
  if (siteCfgEventsSubscription) {
    siteCfgEventsSubscription.unsubscribe();
    siteCfgEventsSubscription = null;
  }

  subClient = null;
  apolloSubClient = null;
}

// Returns the access token from session memory or null if not logged in.
export function getAccessToken() {
  return jwt ? jwt.accessToken : null;
}

// Returns the id token from session memory or null if not logged in.
export function getIdToken() {
  return jwt ? jwt.idToken : null;
}

// Get new tokens using the refresh token
// Inputs: none.
// Expects initiateSignIn or AutoSignIn to have been successfully called.
// Expects the stringified token object to be stored in session storage as 'token'
// Returns: String - the new ID token.
export async function getNewToken() {
  let newToken = "";

  // Try to refresh the tokens
  try {
    const cookieSet = await isCookieSet();
    if (jwt || cookieSet) {
      let REFRESH_TOKENS;
      let vars = {};

      // if the Refresh Token cookie is set, then we only want to save the
      // id token and access token in memory. If not, then we need to store
      // the refresh token as well.
      if (cookieSet) {
        REFRESH_TOKENS = gql`
          query RefreshTokens {
            refreshTokens {
              idToken
              accessToken
            }
          }
        `;
      } else {
        REFRESH_TOKENS = gql`
          query RefreshTokens($rt: String) {
            refreshTokens(refreshToken: $rt) {
              idToken
              accessToken
              refreshToken
            }
          }
        `;
        vars = {
          rt: jwt.refreshToken
        };
      }

      let result;
      try {
        result = await gqlCli.query({
          query: REFRESH_TOKENS,
          fetchPolicy: "no-cache",
          variables: vars,
        });
      } catch (e) {
        console.log(e);
      }
      if (result.errors) {
        throw result.errors[0];
      } else {
        let token = result.data.refreshTokens;
        newToken = token.idToken;
        initToken(token);
      }
    }
  } catch (e) {
    if (e.extensions.code !== 'NO_REFRESH_TOKEN' && 
        e.extensions.code !== 'TOKEN_EXPIRED') {
      console.log(e);
    }
    throw(e);
  }
  return newToken;
}

// Set up a subscription to connection related events. These include: token updates,
// and signouts events.
async function subscribeToConnectionEvents(gqlClient) {
  // There can only be one connection event subscription per client
  // if (!conEventsSubscription) {
    // set up the onConnectionEvent Subscription
    const ON_CONNECTION_SUBSCRIPTION = gql`
      subscription OnConnection {
        onConnection {
          type
        }
      }
    `;

    let conEvents;
    try {
      conEvents = await apolloSubClient.subscribe({
        query: ON_CONNECTION_SUBSCRIPTION,
        fetchPolicy: "no-cache"
      });
    } catch (e) {
      console.log(e);
      throw e;
    }

    if (conEvents) {
      conEventsSubscription = conEvents.subscribe((data) => {
        onConnectionEvent(gqlClient, data.data.onConnection.type);
      });
    }
  // }
}

// Respond to connection events. If the event is a Signout, signout of the site. If the
// event as a RefreshTokens, force a refresh of the site tokens.
// Inputs:
//   gqlClient - required Object - the Apollo client
//   type      - required String - the type of event: RefreshTokens or SignOut
async function onConnectionEvent(gqlClient, type) {
  switch (type) {
    case "RefreshTokens":
      try {
        await getNewToken();
        refreshUserProfile();
      } catch (e) {
        console.log(e);
      }
      break;

    case "SignOut":
      if (subClient) {
        subClient = null;
        apolloSubClient = null;
      }
      setAppForceSignOut(true);
      break;

    default:
      console.log("OnConnectionEvent: ", type);
      break;
  }
}

// Set up a subscription to site related events. These include updates to the site's
// name, brand colours and logo.
async function subscribeToSiteConfigEvents(siteConfigRef, setSiteConfig) {
  // Subscribe to site updates If the user is logged into an org site,
  // and isn't yet subscribed
  if (!siteCfgEventsSubscription && getSiteOrgId()) {
    // set up the onConnectionEvent Subscription
    const ON_SITECONFIGUPDATE_SUBSCRIPTION = gql`
      subscription OnSiteConfigUpdate($orgId: GUID!) {
        onSiteConfigUpdate(orgId: $orgId) {
          name
          value
          message {
            type
            content
          }
        }
      }
    `;

    let siteCfgEvents;
    try {
      siteCfgEvents = await apolloSubClient.subscribe({
        variables: {
          orgId: getSiteOrgId(),
        },
        query: ON_SITECONFIGUPDATE_SUBSCRIPTION,
        fetchPolicy: "no-cache"
      });
    } catch (e) {
      console.log(e);
      throw e;
    }

    if (siteCfgEvents) {
      siteCfgEventsSubscription = siteCfgEvents.subscribe((data) => {
        onSiteConfigEvent(
          data.data.onSiteConfigUpdate,
          siteConfigRef,
          setSiteConfig
        );
      });
    }
  }
}

// Respond to site config events by changing site visuals. If there is a message attached
// to the event, enqueue the message so it appears in a snackbar
// Inputs:
//   siteConfigUpdates - required Object - the siteConfig update event data
//   siteConfigRef     - Required Object - a React reference to the current siteConfig object
//   setSiteConfig     - required Function - useState function to set the siteConfig state
function onSiteConfigEvent(siteConfigUpdates, siteConfigRef, setSiteConfig) {
  let newSiteConfig = copySiteConfig(siteConfigRef.current);
  for (let update of siteConfigUpdates) {
    switch (update.name) {
      case "name":
        newSiteConfig.siteName = update.value;
        break;
      case "brandColors":
        newSiteConfig.brandcolors = JSON.parse(update.value);
        break;
      case "logo":
        newSiteConfig.logo = update.value;
        break;
      default:
        console.log("onSiteConfigEvent: ", update.name);
    }
    if (update.message) {
      enqueueMsg(update.message);
    }
  }
  setSiteConfig(newSiteConfig);
  siteConfigRef.current = newSiteConfig;
}

// Initialize connection subscriptions for siteconfig and connection events.
// Inputs:
//   gqlClient         - required Object - the Apollo HTTP client
//   siteConfigRef     - Required Object - a React reference to the current siteConfig object
//   setSiteConfig     - required Function - useState function to set the siteConfig state
//   enqueueMsg        - required Function - the snackbar function to enqueue a message
//   shouldCreateSubClient - Optional Boolean - set to false if the subscription client already
//                                              exists
export async function setUpConnectionSubs(
  gqlClient,
  siteConfigRef,
  setSiteConfig,
  enqueueMsg,
  shouldCreateSubClient = true
) {
  if (shouldCreateSubClient) {
    // Set up the websocket connection for subscriptions
    let client;
    try {
      client = await createSubscriptionClient();
    } catch (e) {
      throw e;
    }
    subClient = client.subClient;
    apolloSubClient = client.apolloSubClient;
  }

  // set up a subscription to connection related events
  try {
    await subscribeToConnectionEvents(gqlClient);
  } catch (e) {
    console.log("Could not set up subscription to connection events.");
    console.log(e);
  }

  // set up a subscription to siteConfig updates
  try {
    await subscribeToSiteConfigEvents(siteConfigRef, setSiteConfig, enqueueMsg);
  } catch (e) {
    console.log("Could not set up subscription to siteConfig events.");
    console.log(e);
  }
  return;
}
