import React, { useState, useEffect } from "react";
import {
  Avatar,
  Button,
  Checkbox,
  Divider,
  FormHelperText,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  ListItemAvatar,
  Typography
} from "@mui/material";
import { useField } from "formik";
import { useTranslation } from "react-i18next";

const TestTemplateApplicationsForm = React.memo(({
  testPlatform,
  applications,
  handleUpdateOutputOptions,
  handleContinue
}) => {
  const { t } = useTranslation();
  const [appsField, appsMeta, appsHelpers] = useField("apps");
  const [applicationsData] = useState([...applications].sort((a, b) => {
    if ((!a.disabled && b.disabled) ||
      (a.testPlatform === testPlatform && b.testPlatform !== testPlatform)) {
      return -1;
    } else if ((a.disabled && !b.disabled) ||
      (b.testPlatform === testPlatform && a.testPlatform !== testPlatform)) {
      return 1;
    } else {
      return a.displayName
        .toUpperCase()
        .localeCompare(b.displayName.toUpperCase());
    }
  }));
  const [applicationsChanged, setApplicationsChanged] = useState(false);


  useEffect(() => {
    if (applicationsChanged) {
      handleUpdateOutputOptions();
      setApplicationsChanged(false);
    }
  },[handleUpdateOutputOptions, applicationsChanged, setApplicationsChanged]);  

  const handleAppSelectCheckboxChange = (event, app) => {
    if (event.target.checked) {
      appsHelpers.setValue([...appsField.value, { ...app, autoStart: false }]);
      appsHelpers.setError(null);
    } else {
      appsHelpers.setValue(appsField.value.filter(a => a.id !== app.id));
    }
    setApplicationsChanged(true);
  };

  const handleAutoStartChange = (event, app) => {
    //There can only be one autoStart app selected. Automatically sets all others to false
    const updatedApps = appsField.value.map(a => {
      if (a.id === app.id) {
        return { ...a, autoStart: event.target.checked };
      } else {
        return { ...a, autoStart: false };
      }
    });
    appsHelpers.setValue(updatedApps);
  };

  let pageResults = (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography component="p">
          {t("ApplicationsAvailableDuringTest")}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        {!!appsMeta.error && (
          <FormHelperText error={true}>
            {appsMeta.error}
          </FormHelperText>
        )}
        <List>
          {applicationsData
            .map((r, i, apps) => {
              const isAppSelected = appsField.value.some(app => app.id === r.id);
              return (
                <React.Fragment key={r.id}>
                  {(i > 0 && r.disabled && !apps[i - 1].disabled) &&
                    <div key={`premium-divider-${i}`}>
                      <Divider orientation="horizontal" sx={{ backgroundColor: "darkgrey" }}
                        key={`divider-${i}`}
                      />
                      <Typography key={`premium-text-${i}`} style={{ textTransform: 'uppercase' }}>
                        {t("PremiumApplications")}
                      </Typography>
                    </div>
                  }
                  <ListItem key={`list-item-${r.id}`}>
                    <Checkbox
                      size="small"
                      name="apps"
                      value={r}
                      onChange={(event) => handleAppSelectCheckboxChange(event, r)}
                      checked={isAppSelected}
                      disabled={r.testPlatform !== testPlatform || r.disabled}
                    />
                    <ListItemAvatar>
                      <Avatar
                        alt={r.displayName}
                        src={r.icon}
                        variant="square"
                        sx={{
                          filter: (r.testPlatform !== testPlatform || r.disabled) ?
                            'grayscale(100%)' : 'none', // Apply grayscale filter when disabled
                          opacity: (r.testPlatform !== testPlatform || r.disabled) ?
                            0.5 : 1 // Reduce opacity when disabled 
                        }}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={r.displayName}
                      secondary={
                        r.developer && r.version
                          ? r.developer + ", Version " + r.version
                          : null
                      }
                    />

                    <ListItemSecondaryAction>
                      {isAppSelected && testPlatform === 'appstream' && (
                        <>
                          <Typography component="span" style={{ marginRight: '10px' }}>
                            {t("AutoStart")}
                          </Typography>
                          <Checkbox
                            size="small"
                            name="autoStart"
                            onChange={(event) => handleAutoStartChange(event, r)}
                            checked={appsField.value.find(app => app.id === r.id)?.autoStart || false}
                          />
                        </>
                      )}
                    </ListItemSecondaryAction>
                  </ListItem>
                </React.Fragment>
              );
            })}
        </List>
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleContinue}
              sx={{ marginTop: 1 }}
            >
              {t("Continue")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
  return pageResults;
});

export default TestTemplateApplicationsForm;            