import { useSnackbar } from "notistack";
const gqlErrPrefix = "GraphQL error: ";

export function useCustomSnackbar() {
  const { enqueueSnackbar } = useSnackbar();
  return ({ 
    lsEnqueueSnackbar: (message, properties) => {
      if (message.indexOf(gqlErrPrefix) === 0) {
        message = message.substr(gqlErrPrefix.length);
      }
      return enqueueSnackbar(message, properties);
    }
  });
}

