import {
  Grid,
  Chip,
  Link,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  CircularProgress,
  TablePagination,
  Checkbox,
  Toolbar,
  TableSortLabel,
  IconButton,
  Collapse
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import React, { useEffect, useState, useRef } from "react";
import { Box } from "@mui/system";
import { visuallyHidden } from "@mui/utils";
import { t } from "i18next";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { format } from "date-fns";
import { getCurrentDateFormat } from "../../App";


// Table actions

const DashboardTableActions = ({
  selectedRows,
  generateBulkActions,
  onBulkActionClick,
}) => {
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      {generateBulkActions(selectedRows).map((actionItem) => {
        if (actionItem.visible) {
          return (
            <Button
              key={actionItem.key}
              onClick={() => onBulkActionClick(actionItem.key, selectedRows)}
              disabled={actionItem.disabled}
            >
              {actionItem.text}
            </Button>
          );
        } else { return "" }
      })}
    </Toolbar>
  );
};

const DashboardTableRow = ({
  row,
  index,
  handleSelectRow,
  isSelectingEnabled,
  isSelected,
  isCollapsible,
  drawerContents,
  handleClickAction,
  handleToggleDrawerState,
  isDrawerOpen
}) => {

  // const [drawerOpen, setDrawerOpen] = useState(false);
  const isItemSelected = isSelected(row.id);
  const drawerOpen = isDrawerOpen(row.id);
  const labelId = `table-checkbox-${row.id}`;
  let dateFormat = getCurrentDateFormat();

  return (
    <React.Fragment key={row.id}>
      <TableRow
        key={`${row.id}_tableRow`}
        onClick={() => handleSelectRow(row)}
        sx={{
          backgroundColor: index % 2 === 0 ? "rgba(240,240,240,1)" : "white"
        }}
      >
        {isSelectingEnabled && (
          <TableCell
            key={`${row.id}_checkbox`}
          >
            <Checkbox
              color="primary"
              checked={isItemSelected}
              inputProps={{
                "aria-labelledby": labelId,
              }}
            />
          </TableCell>
        )}
        {isCollapsible && drawerContents && (
          <TableCell
            key={`${row.id}_collapse`}
          >
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={(e) => {
                handleToggleDrawerState(row);
                e.stopPropagation();
              }}
            >
              {drawerOpen ? <KeyboardArrowUpIcon fontSize="small" /> :
                <KeyboardArrowDownIcon fontSize="small" />}
            </IconButton>
          </TableCell>
        )}
        {row.fields.map((field) => (
          <TableCell
            key={`${row.id}_${field.key}`}
            align="left"
          >
            {field.key === "status" ? <Chip size="small" variant="outline" label={field.value} /> : 
              field.type === "date" ? 
                (field.value ? format(new Date(field.value), "PPP", { locale: dateFormat }) : "") :
              field.value}
          </TableCell>
        ))}
        {(row.actions || row.statusPending || row.statusWaiting) && (
          <TableCell align="left" key={`${row.id}_actions`}>
            {row.statusPending || row.statusWaiting ? 
              <CircularProgress 
                key={`${row.id}_waiting`}
                size={16} 
              /> :
              row.actions.map((action, index) => (
                <span key={`${row.id}_span_${action.key}`}>
                  {['jurorAssigned'].includes(action.key) ? 
                    <Typography
                      key={`${row.id}_${action.key}`}
                      component="span"
                      variant="body2"
                      sx={{
                        fontStyle: "italic"
                      }}
                    >
                      {action.value}
                    </Typography> :
                    <Link
                      key={`${row.id}_${action.key}`}
                      component="button"
                      variant="body2"
                      underline={action.disabled ? "none" : "hover"}
                      color={action.disabled ? "lightgrey" : (action.color || "primary")}
                      onClick={(e) =>
                        !action.disabled
                          ? handleClickAction(e, action.key, row)
                          : null
                      }
                    >
                      {action.value}
                    </Link>
                  } 
                  {index < row.actions.length - 1 && (
                    <span key={`${row.id}_${action.key}_divider`}>
                      &nbsp;|&nbsp;
                    </span>
                  )}
                </span>
              ))}
          </TableCell>
        )}
      </TableRow>
      {isCollapsible && drawerContents && (
        <TableRow
          key={`${row.id}_drawer`}
          sx={{
            backgroundColor: index % 2 === 0 ? "rgba(240,240,240,1)" : "white"
          }}
        >
          <TableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
            }}
            colSpan={row.fields.length + ((row.actions) ? 1 : 0) + 1}
          >
            <Collapse in={drawerOpen} timeout="auto" unmountOnExit>
              <Box sx={{
                margin: 1
              }}>
                {drawerContents(row)}
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
};

const DashboardTable = ({
  title,
  headers,
  data,
  onRowActionClick,
  isLoading,
  emptyText,
  generateBulkActions,
  onBulkActionClick,
  onRefresh,
  isCollapsible,
  drawerContents,
  toolbar,
  defaultOrderBy,
  defaultOrderType,
  defaultOrder
}) => {
  const [order, setOrder] = useState(defaultOrder || "asc");
  const [orderBy, setOrderBy] = useState(defaultOrderBy || null);
  const [orderType, setOrderType] = useState(defaultOrderType || "string");
  const [selected, setSelected] = useState([]);
  const [drawerState, setDrawerState] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isSelectingEnabled, setIsSelectingEnabled] = useState(false);
  const timerId = useRef(null);

  // useEffect(() => {
  //   if (headers) {
  //     setOrderBy(headers?.[0].keys);
  //     setOrderType(headers?.[0].type || "string");
  //   }
  // }, [headers]);

  const handleClickAction = (e, key, row) => {
    e.stopPropagation();

    onRowActionClick(key, row);
  };

  const isSelected = (id) =>
    selected.findIndex((item) => item.id === id) !== -1;


  const handleToggleRowControl = (row, controlState, setControlState, isControlEnabled) => {
    if (!isControlEnabled) return;

    const controlIndex = controlState.findIndex((item) => item.id === row.id);
    let newControlState = [];

    if (controlIndex === -1) {
      newControlState = newControlState.concat(controlState, row);
    } else if (controlIndex === 0) {
      newControlState = newControlState.concat(controlState.slice(1));
    } else if (controlIndex === controlState.length - 1) {
      newControlState = newControlState.concat(controlState.slice(0, -1));
    } else if (controlIndex > 0) {
      newControlState = newControlState.concat(
        controlState.slice(0, controlIndex),
        controlState.slice(controlIndex + 1)
      );
    }

    setControlState(newControlState);
  };

  const handleSelectRow = (row) => {
    handleToggleRowControl(row, selected, setSelected, isSelectingEnabled);
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const newSelected = data ? [...data] : [];
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const isDrawerOpen = (id) =>
    drawerState.findIndex((item) => item.id === id) !== -1;

  const handleToggleDrawerState = (row) => {
    handleToggleRowControl(row, drawerState, setDrawerState, isCollapsible);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property, type) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setOrderType(type);
  };

  const descendingComparator = (a, b) => {
    const valueA = a.fields.find((field) => field.key === orderBy).value;
    const valueB = b.fields.find((field) => field.key === orderBy).value;

    switch (orderType) {
      case "number":
        return valueB - valueA;
      case "date":
        return new Date(valueB).getTime() - new Date(valueA).getTime();  
      case "string":
      default:
        return valueB.localeCompare(valueA);
    }
  };

  const sortComparator = () => {
    if (!order || !orderBy) {
      return;
    }
    return order === "desc"
      ? (a, b) => descendingComparator(a, b)
      : (a, b) => -descendingComparator(a, b);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data?.length) : 0;

  // If there are pending items, refresh the data every 3 seconds until there are no more pending items.
  // Note: this polling is a backup mechanism, in case the subscription fails.
  useEffect(() => {
    if (data) {
      let pendingData = data?.filter((item) => item.statusPending) || [];
      if (pendingData.length && onRefresh) {
        if (!timerId.current) {
          timerId.current = setInterval(async () => { 
            await onRefresh(); 
          }, 3000);
        }
      } else if (timerId.current) {
        clearInterval(timerId.current)
        timerId.current = null;
      }
    }
  }, [data, onRefresh]);

  return (
    <Grid item xs={12}>
      <Grid container alignItems="baseline">
        <Grid item xs={12}>
          <Grid container alignItems="center" justifyContent="flex-end">
            {toolbar && toolbar(isSelectingEnabled, setIsSelectingEnabled)}
            <Grid item>
              <IconButton onClick={onRefresh}>
                <RefreshIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {isLoading ? (
        <Grid item xs={12} align="center">
          <CircularProgress color="secondary"/>
        </Grid>
      ) : data?.length ? (
        <>
          <TableContainer>
            <Table
              sx={{
                minWidth: 650,
                boxShadow: "none",
                [`& .MuiTableCell-root`]: {
                  borderBottom: "none",
                  padding: "6px",
                },
                [`& .MuiTableHead-root .MuiTableCell-root`]: {
                  textDecoration: "underline",
                  padding: "16px 16px 6px 6px",
                },
              }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead
                key="table-headings"
              >
                <TableRow
                  key="table-headings-row"
                >
                  {isSelectingEnabled && (
                    <TableCell
                      key="checkbox-heading"
                    >
                      <Checkbox
                        color="primary"
                        indeterminate={
                          selected.length > 0 && selected.length < data?.length
                        }
                        checked={
                          data?.length > 0 && selected.length === data?.length
                        }
                        onChange={handleSelectAll}
                        inputProps={{
                          "aria-label": "select all items",
                        }}
                      />
                    </TableCell>
                  )}
                  {isCollapsible && drawerContents && (<TableCell />)}
                  {headers?.map((header) => (
                    <TableCell
                      key={header.key}
                      align="left"
                      sortDirection={orderBy === header.key ? order : false}
                    >
                      {header.sortable ? (
                        <TableSortLabel
                          active={orderBy === header.key}
                          direction={orderBy === header.key ? order : "asc"}
                          onClick={(event) =>
                            handleRequestSort(event, header.key, header.type)
                          }
                        >
                          {header.value}
                          {orderBy === header.key ? (
                            <Box component="span" sx={visuallyHidden}>
                              {order === "desc"
                                ? "sorted descending"
                                : "sorted ascending"}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      ) : (
                        header.value
                      )}
                    </TableCell>
                  ))}
                  {data?.[0].actions && (
                    <TableCell align="left">{t("Actions")}</TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {data
                  ?.sort(sortComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <DashboardTableRow
                        key={index}
                        row={row}
                        index={index}
                        handleSelectRow={handleSelectRow}
                        isSelectingEnabled={isSelectingEnabled}
                        isSelected={isSelected}
                        isCollapsible={isCollapsible}
                        handleToggleDrawerState={handleToggleDrawerState}
                        isDrawerOpen={isDrawerOpen}
                        drawerContents={drawerContents}
                        handleClickAction={handleClickAction}
                      />
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 33 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={1} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {selected.length > 0 && isSelectingEnabled && (
            <DashboardTableActions
              selectedRows={selected}
              generateBulkActions={generateBulkActions}
              onBulkActionClick={onBulkActionClick}
            />
          )}
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      ) : (
        <Grid item xs={12} align="center">
          <Typography component="p">{emptyText}</Typography>
        </Grid>
      )}
    </Grid>
  );
};

// determine if the status for a test instances should show a waiting spinner
export function isStatusWaiting(testData, testInstance) {
  if (!testInstance || !testData) return false;
  let rowIndex = testData.findIndex((row) => row.id === testInstance.id);
  if (rowIndex === -1) {
    return false;
  } else {
    return testData[rowIndex].statusWaiting;
  }
};

// Sets statusWaiting for the row representing testInstance
export function setRowStatusWaiting(testData, setTestData, testInstance, isWaiting) {
  let rowIndex = testData.findIndex((row) => row.id === testInstance.id);
  let row = testData[rowIndex];
  let updatedRow = { ...row, statusWaiting: isWaiting };

  let updatedTableData
  if (rowIndex === 0) {
    updatedTableData = [updatedRow, ...testData.slice(1)];
  } else if (rowIndex === testData.length - 1) {
    updatedTableData = [...testData.slice(0, -1), updatedRow];
  } else {
    updatedTableData = [...testData.slice(0, rowIndex), updatedRow, 
      ...testData.slice(rowIndex + 1)];
  }
  setTestData(updatedTableData);
}


export default DashboardTable;
