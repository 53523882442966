// ScopedPopup.js
import Modal from './Modal';

const ScopedPopup = (props) => {
  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      persistent={props.persistent}
      backdrop={props.backdrop}
      title={props.title}
      description={props.description}
    >
      {props.children}
    </Modal>
  );
};

export default ScopedPopup;
