import React from "react";

import {
  Button,
  Grid,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useField } from "formik";

const TestTemplateBasicSettingsForm = React.memo(({
  testPlatform,
  handleContinue
}) => {
  const { t } = useTranslation();
  const [maxWorkTimeHoursField, maxWorkTimeHoursMeta] = useField("maxWorkTimeHours");
  const [maxWorTimeMinutesField, maxWorkTimeMinutesMeta] = useField("maxWorkTimeMinutes");
  const [allowDownloadField] = useField("allowDownload");
  const [allowUploadField] = useField("allowUpload");
  const [allowPauseField] = useField("allowPause");

  let pageResults = (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography component="p">
            {t("TimeCandidateHasToCompleteTest")}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <TextField
            {...maxWorkTimeHoursField}
            variant="outlined"
            fullWidth
            id="maxWorkTimeHours"
            label={t("Hours")}
            error={!!maxWorkTimeHoursMeta.error}
            helperText={maxWorkTimeHoursMeta.error}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            {...maxWorTimeMinutesField}
            variant="outlined"
            fullWidth
            id="maxWorkTimeMinutes"
            label={t("Minutes")}
            error={!!maxWorkTimeMinutesMeta.error}
            helperText={maxWorkTimeMinutesMeta.error}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography component="p" sx={{ marginTop: 3 }}>
            {t("OptionsDuringTest")}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                {...allowDownloadField}
                checked={allowDownloadField.value}
                color="primary"
                disabled={testPlatform === 'website'}
              />
            }
            label={t("DownloadAllowed")}
          />
        </Grid>

        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                {...allowUploadField}
                checked={allowUploadField.value}
                color="primary"
                disabled={testPlatform === 'website'}
              />
            }
            label={t("UploadAllowed")}
          />
        </Grid>

        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                {...allowPauseField}
                checked={allowPauseField.value}
                color="primary"
              />
            }
            label={t("PauseAllowed")}
          />
        </Grid>

        <Grid item xs={12}>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleContinue}
                sx={{ marginTop: 1 }}
              >
                {t("Continue")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
  return pageResults;
});

export default TestTemplateBasicSettingsForm;
