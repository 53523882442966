import { IconButton, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useDrag } from "react-dnd";
import DescriptionIcon from "@mui/icons-material/Description";
import DeleteIcon from "@mui/icons-material/Delete";
import LevelIndicator from "./LevelIndicator";
import { FileSystemItemType } from "./FileSystem";
import { FileDownload } from "@mui/icons-material";

const FileAssetItem = ({ level = 1, selected, onClick, item, draggable, droppable, onDownload, onDelete, immutable }) => {
  const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
    type: FileSystemItemType.File,
    item: {
      ...item,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }), [item]);

  const levelIndicators = (() => {
    const indicators = [];
    for (let i = 0; i < level; i++) {
      indicators.push(<LevelIndicator left={i * 24} key={i + Date.now()} />);
    }
    return indicators;
  })();

  const opacity = isDragging ? 0.4 : 1;

  return (
    <div ref={(draggable && !immutable) ? dragPreview : null} style={{ opacity }}>
      <ListItemButton
        ref={(draggable && !immutable) ? drag : null}
        onClick={onClick}
        selected={selected}
        sx={{ paddingLeft: `${40 + (level - 1) * 24}px` }}>
        {levelIndicators}
        <ListItemIcon>
          <DescriptionIcon />
        </ListItemIcon>
        <ListItemText primary={item.title} secondary={item.subtitle} />
        {selected && (
          <>
            { onDownload ? 
              <IconButton edge="end" aria-label="download" size="small" onClick={(event) => onDownload(event, item)}>
                <FileDownload />
              </IconButton> 
              : ""}
            { (onDelete && !immutable) ? 
              <IconButton edge="end" aria-label="delete" size="small" onClick={(event) => onDelete(event, item)}>
                <DeleteIcon />
              </IconButton>
              : ""}
          </>
        )}
      </ListItemButton>
    </div>
  );
};

export default FileAssetItem;
