import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip
} from "@mui/material";
import { Waiting } from "../../../components/Waiting";
import { useTranslation } from "react-i18next";
import { useCustomSnackbar } from "../../../components/CustomSnackbar";
import { GET_ORG_EMAIL_TEMPLATES } from "../../../graphql/queries";
import { UPDATE_ORG_EMAIL_TEMPLATE } from "../../../graphql/mutations";
import { useQuery, useMutation } from "@apollo/client";
import { useAppContext } from "../../../context/AppContext";
import { getCurrentLanguage } from "../../../App";
import EditOrgEmailTemplateForm from "./EditOrgEmailTemplateForm";
import Editor from '@monaco-editor/react';


export default function OrgEmailTemplatesForm() {
  const { t } = useTranslation();
  const { lsEnqueueSnackbar } = useCustomSnackbar();
  const { store } = useAppContext();
  const [messagesLanguage, setMessagesLanguage] = useState(getCurrentLanguage());
  const [selectedOET, setSelectedOET] = useState(null);
  const [emailMessage, setEmailMessage] = useState("");
  const [emailSubject, setEmailSubject] = useState("");
  const [emailMessageForEdit, setEmailMessageForEdit] = useState("");
  const [emailSubjectForEdit, setEmailSubjectForEdit] = useState("");
  const [inEditMode, setInEditMode] = useState(false);

  const {
    data: oetData,
    loading: getOETLoading
  } = useQuery(GET_ORG_EMAIL_TEMPLATES, {
    fetchPolicy: "network-only",
    variables: {
      orgId: store.currentOrg.org.id,
    },
    onError: (e) => {
      lsEnqueueSnackbar(e.message, {
        variant: "error",
      });
    }
  });

  const [updateOrgEmailTemplate] = useMutation(UPDATE_ORG_EMAIL_TEMPLATE, {
    onError: (e) => {
      lsEnqueueSnackbar(e.message, {
        variant: "error",
      });
    }
  });

  const handleOnChangeMessagesLanguage = (event) => {
    setMessagesLanguage(event.target.value);
    if (selectedOET) {
      const newOET = oetData.getOrgEmailTemplates.find((oet) =>
        oet.emailTemplate.key === selectedOET.emailTemplate.key &&
        oet.emailTemplate.language === event.target.value);
      setSelectedOET(newOET);
      setEmailMessage(newOET.body || newOET.emailTemplate.body);
      setEmailSubject(newOET.subject || newOET.emailTemplate.subject);
    }
  };

  const handleEnterEditMode = () => {
    setEmailMessageForEdit(emailMessage);
    setEmailSubjectForEdit(emailSubject);
    setInEditMode(true);
  };


  const handleSelectEmailTemplate = (event) => {
    const newOET = oetData.getOrgEmailTemplates.find((oet) => oet.emailTemplate.key === event.target.value && 
      oet.emailTemplate.language === messagesLanguage);
    setSelectedOET(newOET);
    setEmailMessage(newOET.body || newOET.emailTemplate.body);
    setEmailSubject(newOET.subject || newOET.emailTemplate.subject);
  };

  const handleUpdateEmailTemplate = () => {
    let message;
    if (emailSubjectForEdit === selectedOET.emailTemplate.subject &&
      emailMessageForEdit === selectedOET.emailTemplate.body) {
      message = null;
    } else {
      message = {
        subject: emailSubjectForEdit,
        body: emailMessageForEdit
      };
    }
    updateOrgEmailTemplate({
      variables: {
        orgId: store.currentOrg.org.id,
        key: selectedOET.emailTemplate.key,
        language: messagesLanguage,
        message: message
      }
    }).then((res) => {
      if (res.data) {
        setInEditMode(false);
        lsEnqueueSnackbar(t("EmailTemplateUpdated"), {
          variant: "success",
        });
        setEmailMessage(emailMessageForEdit);
        setEmailSubject(emailSubjectForEdit);
        setEmailMessageForEdit("");
        setEmailSubjectForEdit("");
        setInEditMode(false);
      } else if (res.errors) {
        lsEnqueueSnackbar(res.errors[0].message, {
          variant: "error",
        });
      }
    }).catch((e) => {
      lsEnqueueSnackbar(e.message, {
        variant: "error",
      });
    });
  };


  let pageResults;
  if (getOETLoading) {
    pageResults = <Waiting />;
  } else if (oetData) {
    let languages = oetData.getOrgEmailTemplates.reduce((uniqueLangs, curOET) => {
      if (!uniqueLangs.find((lang) => lang === curOET.emailTemplate.language)) {
        uniqueLangs.push(curOET.emailTemplate.language);
      }
      return uniqueLangs;
    }, []);

    pageResults = (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" sx={{ mt: 3 }}>
              {t("EmailTemplates")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="language-field-label">{t("Language")}</InputLabel>
              <Select
                labelId="language-field-label"
                id="language-field"
                value={messagesLanguage}
                label={t("Language")}
                onChange={handleOnChangeMessagesLanguage}
              >
                {
                  languages
                    .sort((a, b) => a.localeCompare(b))
                    .map((lang) => (
                      <MenuItem key={lang} value={lang}>
                        {t(lang + "_translated")}
                      </MenuItem>
                    ))
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="email-template-field-label">{t("EmailTemplate")}</InputLabel>
                  <Select
                    labelId="email-template-field-label"
                    id="email-template-field"
                    value={selectedOET?.emailTemplate.key || ""}
                    label={t("EmailTemplate")}
                    onChange={handleSelectEmailTemplate}
                  >
                    {
                      oetData.getOrgEmailTemplates
                        .filter((oet) => oet.emailTemplate.language === messagesLanguage)
                        .sort((a, b) => {
                          let aNL = a.emailTemplate.language === getCurrentLanguage() ?
                            a.emailTemplate.name :
                            oetData.getOrgEmailTemplates.find((srcOET) => srcOET.emailTemplate.key === a.emailTemplate.key
                              && srcOET.emailTemplate.language === getCurrentLanguage()).emailTemplate.name;
                          let bNL = b.emailTemplate.language === getCurrentLanguage() ?
                            b.emailTemplate.name :
                            oetData.getOrgEmailTemplates.find((srcOET) => srcOET.emailTemplate.key === b.emailTemplate.key
                              && srcOET.emailTemplate.language === getCurrentLanguage()).emailTemplate.name
                          return aNL.localeCompare(bNL);
                        })
                        .map((oet) => {
                          let nlOET = oet.emailTemplate.language === getCurrentLanguage() ? oet :
                            oetData.getOrgEmailTemplates.find((srcOET) =>
                              srcOET.emailTemplate.key === oet.emailTemplate.key &&
                              srcOET.emailTemplate.language === getCurrentLanguage());
                          let toolTip = nlOET.emailTemplate.description;
                          return (

                            <MenuItem key={oet.emailTemplate.key} value={oet.emailTemplate.key}>
                              <Tooltip
                                key={`${oet.emailTemplate.key}_templateDesc`}
                                title={toolTip}
                                placement="left"
                              >
                                <span>{nlOET.emailTemplate.name}</span>
                              </Tooltip>
                            </MenuItem>
                          );
                        })
                    }
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          {selectedOET &&
            <Grid item xs={12}>
              <Grid container spacing={2} justifyContent="flex-start" alignItems="flex-start">
                <Grid item xs={9}>
                  <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        id="emailSubject"
                        label={t("EmailSubject")}
                        name="emailSubject"
                        value={emailSubject}
                        disabled={true}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Box sx={{
                        backgroundColor: "#f0f0f0 !important",
                        opacity: 0.4,
                        border: 1,
                        overflow: "auto",
                        height: "25vh"
                      }}>
                        <Editor
                          height="100%"
                          defaultLanguage="handlebars"
                          value={emailMessage}
                          options={{
                            wordWrap: "on",
                            readOnly: true,
                            scrollBeyondLastLine: false,
                            automaticLayout: true
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={3}>
                  <Button
                    variant="outlined"
                    onClick={handleEnterEditMode}
                    color="primary"
                  >
                    {t("EditTemplate")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          }
        </Grid>
        <EditOrgEmailTemplateForm
          emailMessage={emailMessage}
          emailSubject={emailSubject}
          emailMessageForEdit={emailMessageForEdit}
          setEmailMessageForEdit={setEmailMessageForEdit}
          emailSubjectForEdit={emailSubjectForEdit}
          setEmailSubjectForEdit={setEmailSubjectForEdit}
          selectedOET={selectedOET}
          oetData={oetData}
          inEditMode={inEditMode}
          setInEditMode={setInEditMode}
          handleUpdateEmailTemplate={handleUpdateEmailTemplate}
        />
      </>
    );
  } else {
    pageResults = "";
  }
  return <div>{pageResults}</div>;
}