import React, { useEffect, useState } from "react";
import { 
  Box,
  Dialog
} from "@mui/material";
import DashboardTable, { isStatusWaiting, setRowStatusWaiting } from "./DashboardTable";
import { useNavigate } from "react-router-dom";
import { Waiting } from "../Waiting";
import { t } from "i18next";
import { useAppContext } from "../../context/AppContext";
import { TestInstanceStatus } from "../../graphql/enums";
import { formatSecondsToString } from "../../utils";
import { format } from "date-fns";
import { getCurrentDateFormat } from "../../App";
import { useMutation } from "@apollo/client";
import { ACCEPT_TEST_INSTANCE, REJECT_TEST_INSTANCE } from "../../graphql/mutations";
import { GET_TEST_INSTANCES_ASSIGNED_TO_ME } from "../../graphql/queries";
import { handleCacheUpdate } from "../../cache";
import { useCustomSnackbar } from "../CustomSnackbar";
import ConfirmationForm from "../ConfirmationForm"
import TestTermsAndConditions from "../../views/Candidate/TestTermsAndConditions"
import { calculateTestInstanceRemainingTime } from "../../utils/testInstanceUtils";

export default function DashboardAssignedToMe({
  activeTab,
  tData,
  tLoading,
  refreshMyTestInstances
}) {
  const { actions, store } = useAppContext();
  const { lsEnqueueSnackbar } = useCustomSnackbar();
  const navigate = useNavigate();
  const [candidateToSubmitTableData, setCandidateToSubmitTableData] = useState([]);
  const [confirmAcceptOpen, setConfirmAcceptOpen] = useState(false);
  const [confirmDeclineOpen, setConfirmDeclineOpen] = useState(false);
  const [currentDatetime, setCurrentDatetime] = useState(Date.now());
  let dateFormat = getCurrentDateFormat();

  const [acceptTestInstance] = useMutation(ACCEPT_TEST_INSTANCE, {
    update: (cacheClient, response) => {
      if (response?.data) {
        handleCacheUpdate(
          "update",
          cacheClient,
          response.data.acceptTestInstance,
          "id",
          GET_TEST_INSTANCES_ASSIGNED_TO_ME,
          "getTestInstancesAssignedToMe",
          null,
          {}
        );
        setRowStatusWaiting(candidateToSubmitTableData, setCandidateToSubmitTableData, 
          response.data.acceptTestInstance, false);
      }
    },
  });

  const [rejectTestInstance] = useMutation(REJECT_TEST_INSTANCE, {
    update: (cacheClient, response) => {
      if (response?.data) {
        handleCacheUpdate(
          "delete",
          cacheClient,
          response.data.rejectTestInstance,
          "id",
          GET_TEST_INSTANCES_ASSIGNED_TO_ME,
          "getTestInstancesAssignedToMe",
          null,
          {}
        );
      }
    },
  });

  const candidateToSubmitTableHeaders = [
    { key: "name", value: t("TestName"), sortable: true, type: "string" },
    { key: "assignedBy", value: t("AssignedBy"), sortable: true, type: "string" },
    { key: "status", value: t("Status"), sortable: false, type: "string" },
    { key: "dueDate", value: t("DueDate"), sortable: true, type: "date" },
    { key: "timeRemaining", value: t("TimeRemaining"), sortable: false, type: "number" },
  ];

  const handleRefreshMyTestInstances = () => {
    refreshMyTestInstances();
  };

  const handleCloseConfirmAccept = (response) => {
    if (response === 'Confirm') {
      handleConfirmAccept(store.currentTestInstance);
    } else {
      setRowStatusWaiting(candidateToSubmitTableData, setCandidateToSubmitTableData,
        store.currentTestInstance, false);
    }
    setConfirmAcceptOpen(false);
  };

  const handleCloseConfirmDecline = (response) => {
    if (response === 'Confirm') {
      handleConfirmDecline(store.currentTestInstance);
    } else {
      setRowStatusWaiting(candidateToSubmitTableData, setCandidateToSubmitTableData,
        store.currentTestInstance, false);
    }
    setConfirmDeclineOpen(false);
  };

  const handleRowActionClick = (action, row) => {
    switch (action) {
      case "resume":
        handleResumeTest(row.instance);
        break;
      case "reserve":
        handleMakeReservation(row.instance);
        break;
      case "cancel":
        handleCancelReservation(row.instance);
        break;
      case "submit":
        handleSubmitTest(row.instance);
        break;
      case "accept":
        handleAcceptTest(row.instance);
        break;
      case "decline":
        handleDeclineTest(row.instance);
        break;
      case "discard":
        handleDiscardTest(row.instance);
        break;
      case "revoke":
        handleRevokeTest(row.instance);
        break;
      default:
        console.log(action, row);
    }
  };


  useEffect(() => {
    var timerId = setInterval(() => setCurrentDatetime(Date.now()), 1000);
    return () => clearInterval(timerId);
  });

  // map the received data to table row friendly data for both tables
  useEffect(() => {
    let testAvailable = {};
    let allowCancel = false;

    if (tData?.getTestInstancesAssignedToMe) {
      const testInstances = tData.getTestInstancesAssignedToMe;
      const toSubmit = [];

      const CandidateTestInstanceStatusText = {
        [TestInstanceStatus.Active]: t("InProgress"),
        [TestInstanceStatus.Suspended]: t("DeadlinePassed"),
        [TestInstanceStatus.Assigned]: t("NotYetStarted"),
        [TestInstanceStatus.Marked]: t("Evaluated"),
        [TestInstanceStatus.Unmarked]: t("PendingEvaluation"),
        [TestInstanceStatus.ActivePending]: t("Accepted"),
        [TestInstanceStatus.AssignedPending]: t("NotYetStarted"),
        [TestInstanceStatus.UnmarkedPending]: t("PendingEvaluation"),
        [TestInstanceStatus.MarkedPending]: t("Evaluated")
      };

      const getCandidateTestInstanceActionsPerStatus = (
        testInstance,
        isDeadlineReached
      ) => {
        let instanceId = testInstance.id;
        let testActions;
        switch (testInstance.status) {
          case TestInstanceStatus.Created:
            testActions = [
              {
                key: "accept",
                value: t("Accept"),
                disabled: isDeadlineReached,
              }
            ];
            if (testInstance.allowAssigneeReject) {
              testActions.push({
                key: "decline",
                value: t("Decline"),
                color: "error",
              });
            }
            return testActions;
          case TestInstanceStatus.Assigned:
            testActions = [
              {
                key: "accept",
                value: t("Accept"),
                // disabled: isDeadlineReached,
              }
            ];
            if (testInstance.allowAssigneeReject) {
              testActions.push({
                key: "decline",
                value: t("Decline"),
                color: "error",
              });
            }
            return testActions;
          case TestInstanceStatus.Active: {
            testActions = [];
            if (testAvailable[instanceId]) {
              testActions.push({
                key: testAvailable[instanceId].key,
                value: t(testAvailable[instanceId].value),
              });
            }
            if (allowCancel) {
              testActions.push({
                key: "cancel",
                value: t("CancelReservation"),
                color: "error"
              });
            }
            if (testInstance.workTimeRemaining < testInstance.testTemplate.maxWorkTime) {
              testActions.push({ key: "submit", value: t("Submit") });
            }
            if (testInstance.allowAssigneeReject) {
              testActions.push({
                key: "decline",
                value: t("Decline"),
                color: "error",
              });
            }
            return testActions;
          }
          case TestInstanceStatus.Suspended:
            testActions = [
              { key: "submit", value: t("Submit") }
            ];
            if (testInstance.allowAssigneeReject) {
              testActions.push({
                key: "discard", 
                value: t("Discard"),
                color: "error" 
              });
            }
            return testActions;
          case TestInstanceStatus.Marked:
            return [
              { key: "viewEvaluation", value: t("ViewEvaluation") }
            ];
          default:
            return [];
        }
      };

      testInstances.forEach((testInstance) => {
        const deadlineDatetime = new Date(
          testInstance.finalTargetCompletionDate
        ).getTime();

        const currentReservation = testInstance.reservations?.find((r) => {
          let now = Date.now();
          let start = new Date(r.timeslot.start).getTime();
          return start + r.timeslot.duration * 1000 > now;
        });

        const currentReservationTime = new Date(currentReservation?.timeslot.start).getTime();

        // accept action is disabled when the deadline has passed or the test doesn't have work time left.
        const isDeadlineReached =
          currentDatetime > deadlineDatetime ||
          testInstance.workTimeRemaining === 0;

        // resume action is enabled only when the test has an active reservation
        const currentReservationDurationWithGrace = currentReservation ?
          currentReservation.timeslot.duration + 60 * 5 : 0;

        const earliestStartDate = testInstance.earliestStartDate ?
          new Date(testInstance.earliestStartDate).getTime() : 0;

        if (
          currentDatetime < deadlineDatetime &&
          testInstance.workTimeRemaining === testInstance.testTemplate.maxWorkTime &&
          ((currentReservation && currentReservationTime <= currentDatetime &&
            currentReservationTime + currentReservationDurationWithGrace * 1000 >
            currentDatetime) ||
            !testInstance.testTemplate.requiresReservation)
        ) {
          if (currentDatetime > earliestStartDate) {
            testAvailable[testInstance.id] = {
              key: "resume",
              value: "StartTest",
            };
            if (currentReservationTime <= currentDatetime &&
              currentReservationTime + currentReservationDurationWithGrace * 1000 > currentDatetime) {
              allowCancel = true;
            }
          } else {
            testAvailable[testInstance.id] = {
              key: "",
              value:
                t("StartTest") +
                " " +
                format(new Date(testInstance.earliestStartDate), "PPp", {
                  locale: dateFormat,
                }),
            };
          }
        } else if (currentDatetime < deadlineDatetime &&
          testInstance.workTimeRemaining > 0 &&
          ((currentReservation && currentReservationTime <= currentDatetime &&
            currentReservationTime + currentReservationDurationWithGrace * 1000 >
            currentDatetime) ||
            !testInstance.testTemplate.requiresReservation)
        ) {
          testAvailable[testInstance.id] = {
            key: "resume",
            value: "Resume",
          };
          if (currentReservationTime <= currentDatetime &&
            currentReservationTime + currentReservationDurationWithGrace * 1000 > currentDatetime) {
            allowCancel = true;
          }
        } else if (
          currentDatetime < deadlineDatetime &&
          currentReservationTime > currentDatetime &&
          testInstance.workTimeRemaining > 0
        ) {
          testAvailable[testInstance.id] = {
            key: "",
            value:
              t("UpcomingReservation") +
              " " +
              format(new Date(currentReservation.timeslot.start), "PPp", {
                locale: dateFormat,
              }),
          };
          allowCancel = true;
        } else if (
          !currentReservation &&
          currentDatetime < deadlineDatetime &&
          testInstance.workTimeRemaining > 0
        ) {
          testAvailable[testInstance.id] = {
            key: "reserve",
            value: "ReserveTime",
          };
          allowCancel = false;
        }

        const actions = getCandidateTestInstanceActionsPerStatus(
          testInstance,
          isDeadlineReached
        );

        // To submit table
        if (
          [
            TestInstanceStatus.Assigned,
            TestInstanceStatus.Active,
            TestInstanceStatus.Suspended,
            TestInstanceStatus.Created,
            TestInstanceStatus.AssignedPending,
            TestInstanceStatus.ActivePending,
          ].includes(testInstance.status)
        ) {

          const testTableRow = {
            id: testInstance.id,
            statusPending: testInstance.status.includes("_pending"),
            statusWaiting: isStatusWaiting(candidateToSubmitTableData, testInstance),
            fields: [
              { key: "name", value: testInstance.testTemplate.name },
              {
                key: "assignedBy",
                value:
                  // testInstance.assignedBy.firstname +
                  // " " +
                  // testInstance.assignedBy.lastname,
                  testInstance.testTemplate.org.name,
                type: "string"  
              },
              {
                key: "status",
                value: CandidateTestInstanceStatusText[testInstance.status],
                type: "string"  
              },
              {
                key: "dueDate",
                value: testInstance.finalTargetCompletionDate,
                type: "date"  
              },
              {
                key: "timeRemaining",
                value: formatSecondsToString(calculateTestInstanceRemainingTime(testInstance)),
                type: "number"  
              },
            ],
            actions,
            instance: testInstance,
          };
          toSubmit.push(testTableRow);
        }
      });
      // refreshMyTestInstances();
      setCandidateToSubmitTableData(toSubmit);
    }
  }, [tData, dateFormat, refreshMyTestInstances, currentDatetime]);

  const handleAcceptTest = (testInstance) => {
    setRowStatusWaiting(candidateToSubmitTableData, setCandidateToSubmitTableData, testInstance, true);
    actions.setCurrentTestInstance(testInstance);
    setConfirmAcceptOpen(true);
  }

  const handleConfirmAccept = (testInstance) => {
    acceptTestInstance({
      variables: {
        orgId: testInstance?.testTemplate.org.id,
        instanceId: testInstance.id,
      },
    })
      .then((res) => {
        if (res.errors) {
          lsEnqueueSnackbar(res.errors[0].message, {
            variant: "error",
          });
        } else {
          lsEnqueueSnackbar(t("TestAccepted"), {
            variant: "success",
          });
        }
      })
      .catch((e) => {
        lsEnqueueSnackbar(e.message, {
          variant: "error",
        });
      }); // catch network errors
  };

  const handleDeclineTest = (testInstance) => {
    setRowStatusWaiting(candidateToSubmitTableData, setCandidateToSubmitTableData,
      testInstance, true);
    actions.setCurrentTestInstance(testInstance);
    setConfirmDeclineOpen(true);
  };

  const handleConfirmDecline = (testInstance) => {
    rejectTestInstance({
      variables: {
        orgId: testInstance?.testTemplate.org.id,
        instanceId: testInstance.id,
      },
    })
      .then((res) => {
        if (res.errors) {
          lsEnqueueSnackbar(res.errors[0].message, {
            variant: "error",
          });
        } else {
          lsEnqueueSnackbar(t("TestDeclinedSuccessfully"), {
            variant: "success",
          });
        }
      })
      .catch((e) => {
        lsEnqueueSnackbar(e.message, {
          variant: "error",
        });
      }); // catch network errors
  };

  const handleDiscardTest = (testInstance) => {
    actions.setCurrentTestInstance(testInstance);
    navigate("/app/discardTest");
  };

  const handleRevokeTest = (testInstance) => {
    actions.setCurrentTestInstance(testInstance);
    navigate("/app/revokeTest");
  };

  const handleResumeTest = (testInstance) => {
    actions.setCurrentTestInstance(testInstance);
    navigate("/app/testInstructionsBeforeTest");
  };

  const handleMakeReservation = (testInstance) => {
    actions.setCurrentTestInstance(testInstance);
    navigate("/app/makeReservation");
  };

  const handleCancelReservation = (testInstance) => {
    actions.setCurrentTestInstance(testInstance);
    navigate("/app/cancelReservation");
  };

  const handleSubmitTest = (testInstance) => {
    actions.setCurrentTestInstance(testInstance);
    navigate("/app/submitTest");
  };

  let pageContents;
  if (tLoading) {
    pageContents = <Waiting />;
  } else if (tData) {
    pageContents = (
      <>
        <div
          role="tabpanel"
          hidden={activeTab !== 3}
          id="tabpanel-3"
          aria-labelledby="tab-3"
        >
          <Box>
            <DashboardTable
              title={t("CandidateTableHeader")}
              headers={candidateToSubmitTableHeaders}
              data={candidateToSubmitTableData}
              onRowActionClick={handleRowActionClick}
              isLoading={tLoading}
              emptyText={t("NoTestsMessage")}
              onRefresh={handleRefreshMyTestInstances}
              isCollapsible={true}
              defaultOrder="asc"
              defaultOrderBy="dueDate"
              defaultOrderType="date"
            />
          </Box>
        </div>
        <Dialog
          open={confirmAcceptOpen}
          onClose={handleCloseConfirmAccept}
        >
          <TestTermsAndConditions
            handleConfirmationClose={handleCloseConfirmAccept}
          />
        </Dialog>
        <Dialog
          open={confirmDeclineOpen}
          onClose={handleCloseConfirmDecline}
        >
          <ConfirmationForm
            handleConfirmationClose={handleCloseConfirmDecline}
            dialogTitle={t("DeclineTestHeading")}
            dialogContent={t("DeclineTestBody")}
            showCancel={true}
            confirmText={t("Confirm")}
          />
        </Dialog>
      </>
    );
  } else {
    pageContents = "";
  }

  return pageContents;
}
