import {
    Box,
    CircularProgress,
    IconButton,
    Typography,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Button,
    Container,
    Grid
  } from "@mui/material";
  
  import React, { useState, useEffect } from "react";
  import { useTranslation } from "react-i18next";
  import { useAppContext } from "../../context/AppContext";
  import { useCustomSnackbar } from "../../components/CustomSnackbar";
  import { InstructionsType } from "../../graphql/enums";
  import { GET_TEST_INSTANCE_INSTRUCTIONS_DOWNLOAD_URL } from "../../graphql/mutations";
  import FileDownloadIcon from "@mui/icons-material/FileDownload";
  import DescriptionIcon from "@mui/icons-material/Description";
  import { formatBytesToString } from "../../utils";
  import {
    parseInstructionsString,
    downloadInstructions,
    getInstructionsDownloadURL,
  } from "../../instructions";
  
  import { useMutation } from "@apollo/client";
  import { useNavigate } from "react-router-dom";
  import { config } from "../../config";
  
  const TestInstructionsBeforeTest = ({ toolbarHeight }) => {
    const { store } = useAppContext();
    const { lsEnqueueSnackbar } = useCustomSnackbar();
    const { t } = useTranslation();
    const [isUrlLoading, setIsUrlLoading] = useState(false);
    const [instructionsURL, setInstructionsURL] = useState(null);
    const navigate = useNavigate();
  
    const [getTestInstanceInstructionsDownloadURL] = useMutation(
      GET_TEST_INSTANCE_INSTRUCTIONS_DOWNLOAD_URL
    );
  
    const handleInstructionsDownload = () => {
      downloadInstructions(
        setIsUrlLoading,
        getTestInstanceInstructionsDownloadURL,
        store.currentTestInstance?.testTemplate.org.id,
        store.currentTestInstance.id,
        lsEnqueueSnackbar
      );
    };
  
    const testInstructions = store.currentTestInstance?.testTemplate.instructions;
  
    useEffect(() => {
      if (
        testInstructions &&
        testInstructions.type === InstructionsType.Asset &&
        testInstructions.asset
      ) {
        getInstructionsDownloadURL(
          getTestInstanceInstructionsDownloadURL,
          store.currentTestInstance?.testTemplate.org.id,
          store.currentTestInstance.id
        )
          .then((url) => {
            setInstructionsURL(url);
          })
          .catch((e) => {
            console.log("Error getting instructions URL: ", e);
            setInstructionsURL(null);
          });
      }
    }, [
      testInstructions,
      getTestInstanceInstructionsDownloadURL,
      store.currentTestInstance,
    ]);
  
    useEffect(() => {
      if (!config.showInstructionsBeforeTest) {
        navigate(`/app/testinstance/`);
      }
    });
  
    const handleStartTest = () => {
      navigate(`/app/testinstance/`);
    };
  
    const handleGoToDashboard = () => {
      navigate("/app");
    };
  
    return (
      <Container maxWidth="md" sx={{ textAlign: "center", mt: 4, display: 'flex', flexDirection: 'column', maxHeight: '80vh' }}>
        {testInstructions && (
          <>
            <Typography variant="h4" gutterBottom>
              {t("TestInstructions")}
            </Typography>
  
            <Box sx={{ flexGrow: 1, overflowY: "auto", textAlign: "left", padding: 2, border: "1px solid #ddd", borderRadius: 2, backgroundColor: "#f9f9f9" }}>
              {testInstructions.type === InstructionsType.Text && (
                <Box>
                  {parseInstructionsString(testInstructions.text)}
                </Box>
              )}
              {testInstructions.type === InstructionsType.Asset && (
                <>
                  <object
                    width="100%"
                    height="350px"
                    data={instructionsURL + "#view=FitH"}
                    type="application/pdf"
                    style={{ border: "1px solid #ddd", borderRadius: "4px" }}
                  >
                    {t("TestInstructions")}
                  </object>
                  <Typography component="p" sx={{ marginTop: 3, marginBottom: 2 }}>
                    {t("DownloadTestIntroductionFileExplanation")}
                  </Typography>
                  <ListItem
                    secondaryAction={
                      <IconButton
                        edge="end"
                        aria-label="download"
                        onClick={handleInstructionsDownload}
                        disabled={isUrlLoading}
                      >
                        {isUrlLoading ? (
                          <CircularProgress size={24} />
                        ) : (
                          <FileDownloadIcon />
                        )}
                      </IconButton>
                    }
                  >
                    <ListItemAvatar>
                      <DescriptionIcon />
                    </ListItemAvatar>
                    <ListItemText
                      primary={testInstructions.asset.filename}
                      secondary={formatBytesToString(testInstructions.asset.size)}
                    />
                  </ListItem>
                </>
              )}
            </Box>
            <Grid container spacing={2} justifyContent="flex-end" alignItems="center" sx={{ mt: 3 }}>
              <Grid item>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleGoToDashboard}
                >
                    {t("ReturnToDashboard")}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                      variant="contained"
                      color="primary"
                      onClick={handleStartTest}
                  >
                      {t("StartTest")}
                  </Button>
                </Grid>
            </Grid>
          </>
        )}
      </Container>
    );
  };
  
  export default TestInstructionsBeforeTest;
  