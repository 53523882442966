import { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  FormHelperText
} from "@mui/material";
import InstanceFilterRule from "./InstanceFilterRule";
import { useCustomSnackbar } from "../CustomSnackbar";
import { useQuery } from "@apollo/client";
import { GET_TEST_TEMPLATES, GET_TAGS } from "../../graphql/queries";
import { TestTemplateStatus } from "../../graphql/enums";
import { useAppContext } from "../../context/AppContext";
import { useTranslation } from "react-i18next";

// Evaluate an instance against a filter
export function evaluateFilter(filter, instance) {
  let result = true;

  for (let rule of filter) {
    let values;
    let fields;
    if (['assignmentDate', 'startDate', 'completionDate'].includes(rule.field)) {  // Date fields
      values = rule.values.dateValues.map((v) => new Date(v).setHours(0, 0, 0, 0));
      fields = [new Date(instance[rule.field]).setHours(0, 0, 0, 0)];
    } else {   // string fields
      values = rule.values.stringValues;
      if (rule.field === 'testTemplateId') {
        fields = [instance.testTemplate.id];
      } else if (rule.field === 'tags') {
        fields = instance.tags?.map((t) => t.name);
      } else {
        fields = [instance[rule.field]];
      }
    }

    if (rule.operation === 'eq') {
      result = result && fields[0] === values[0];
    } else if (rule.operation === 'ne') {
      result = result && fields[0] !== values[0];
    } else if (rule.operation === 'gt') {
      result = result && fields[0] > values[0];
    } else if (rule.operation === 'lt') {
      result = result && fields[0] < values[0];
    } else if (rule.operation === 'ge') {
      result = result && fields[0] >= values[0];
    } else if (rule.operation === 'le') {
      result = result && fields[0] <= values[0];
    } else if (rule.operation === 'in') {
      result = result && fields?.some(field => values.includes(field));
    } else if (rule.operation === 'notin') {
      result = result && !fields?.some(field => values.includes(field));
    } else if (rule.operation === 'inall') {
      result = result && values.every(value => fields?.includes(value));
    }
  }
  return result;
}

const InstanceFilter = ({
  filter,
  setFilter,
  disabled = false,
  allowSelectTestTemplate = true,
  allowStatus = true,
  allowDeleteAllRules = true,
  testTemplateId = '',
  defaultFilter = null,
  setFilterValid = null
}) => {
  const { lsEnqueueSnackbar } = useCustomSnackbar();
  const { store } = useAppContext();
  const { t } = useTranslation();
  const [testTemplates, setTestTemaplates] = useState([]);
  const [isValid, setIsValid] = useState(true);

  // Fetch test templates
  const {
    data: utData,
    loading: utLoading,
  } = useQuery(GET_TEST_TEMPLATES, {
    variables: {
      orgId: store.currentOrg?.org.id,
      templateStatus: [
        TestTemplateStatus.Draft,
        TestTemplateStatus.Active,
        TestTemplateStatus.Deprecated,
        TestTemplateStatus.Retired
      ],
    },
    onError: (e) => {
      lsEnqueueSnackbar(e.message, {
        variant: "error",
      });
    },
  });

  // Get the list of available tags
  const {
    loading: gtLoading,
    data: gtData
  } = useQuery(GET_TAGS, {
    variables: {
      orgId: store.currentOrg?.org.id
    },
    onError: (e) => {
      lsEnqueueSnackbar(e.message, {
        variant: "error",
      });
    }
  });

  useEffect(() => {
    if (utData?.getTestTemplates) {
      let sortableTemplates = [...utData.getTestTemplates]
        .filter((template) => template.status !== TestTemplateStatus.Draft);
      setTestTemaplates(sortableTemplates.sort((a, b) =>
        `${a.name.toUpperCase()} (${a.status})`.localeCompare(`${b.name.toUpperCase()} (${b.status})`)));
    }
  }, [utData]);

  useEffect(() => {
    if (setFilterValid) {
      setFilterValid(isValid);
    }  
  }, [isValid, setFilterValid]);

  // Define Validation Logic
  const isFilterRuleComplete = useCallback((rule) => {
    return (rule.values.dateValues && rule.values.dateValues.length) ||
      (rule.values.stringValues && rule.values.stringValues.length && !rule.values.stringValues.includes(''))
  }, []);

  const validateFilter = (newFilter) => {
    setIsValid(newFilter.every(isFilterRuleComplete));
  };

  const handleAddRule = () => {
    let newFilter;
    if (defaultFilter) {
      newFilter = [...filter, defaultFilter];
    } else {
      newFilter = [...filter, { field: 'testTemplateId', operation: 'eq', values: { stringValues: [testTemplateId] } }];
    }
    validateFilter(newFilter);
    setFilter(newFilter);
  };

  const handleDeleteRule = (index) => {
    let newFilter = filter.filter((_, i) => i !== index)
    validateFilter(newFilter);
    setFilter(newFilter);
  };

  const handleChangeRule = (index, newRule) => {
    let newFilter = filter.map((rule, i) => (i === index ? newRule : rule));
    validateFilter(newFilter);
    setFilter(newFilter);
  };


  let pageResults = (
    <Box>
      {(utLoading || gtLoading || testTemplates.length === 0) ?
        <Grid container spacing={2} justifyContent="flex-end" alignItems="flex-end" >
          <Grid item>
            <CircularProgress size={24}/> 
          </Grid>
        </Grid> : 
        (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2} justifyContent="flex-end" alignItems="flex-end">
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleAddRule}
                    disabled={disabled || !isValid} // Disable if filter is incomplete
                  >
                    {t("AddFilter")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            {filter.map((rule, index) => (
              <Grid item xs={12} key={index}>
                <InstanceFilterRule
                  key={index}
                  filterRule={rule}
                  index={index}
                  ruleCount={filter.length}
                  onChange={handleChangeRule}
                  onDelete={handleDeleteRule}
                  templates={testTemplates}
                  tags={gtData.getTags}
                  allowSelectTestTemplate={allowSelectTestTemplate}
                  allowStatus={allowStatus}
                  allowDeleteAllRules={allowDeleteAllRules}
                  testTemplateId={testTemplateId}
                  disabled={disabled}
                />
              </Grid>
            ))}
            {!isValid && !disabled && (
              <Grid item xs={12}>
                <FormHelperText error>{t("IncompleteFilterRules")}</FormHelperText>
              </Grid>
            )}
          </Grid>
        )
      }

    </Box>
  );
  return pageResults;
};

export default InstanceFilter;