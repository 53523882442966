import { memo, useState, useEffect, useCallback } from "react";
import {
  Grid,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Autocomplete,
  Typography
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useTranslation } from "react-i18next";


// Valid operators for the selected field
const validFieldFilterOperations = {
  assignmentDate: ["eq", "ne", "gt", "lt", "ge", "le"],
  completionDate: ["eq", "ne", "gt", "lt", "ge", "le"],
  status: ["in", "notin"],
  testTemplateId: ["eq", "ne"],
  tags: ["in", "notin", "inall"]
};

const statusValues = [
  "active",
  "assigned",
  "cancelled",
  "created",
  "inactive",
  "marked",
  "suspended",
  "unmarked",
  "unmarked_complete"
];

/**
 * Filter Rule Field Select Component
 * @param {Object} props
 * @param {String} props.field - Field to filter on
 * @param {Function} props.onChange - Callback to update the field
 * @param {Boolean} props.disabled - Disable the component
 */
const InstanceFilterRuleFieldSelect = memo(({
  field,
  onChange,
  disabled = false,
  allowStatus
}) => {
  const { t } = useTranslation();

  // map of filter fields to labels
  const filterFields = [
    { id: "assignmentDate", label: t("AssignmentDate") },
    { id: "completionDate", label: t("CompletionDate") },
    { id: "testTemplateId", label: t("TestTemplate") },
    { id: "status", label: t("Status") },
    { id: "tags", label: t("Tags") },
  ];

  return (
    <FormControl fullWidth disabled={disabled}>
      <InputLabel id="filter-field-label">{t("FilterField")}</InputLabel>
      <Select
        labelId="filter-field-label"
        id="filter-field"
        value={field}
        label={t("FilterField")}
        onChange={onChange}
        disabled={disabled}
      >
        {filterFields
          .filter(field => field.id !== "status" || allowStatus)
          .map(field => (<MenuItem key={field.id} value={field.id}>{field.label}</MenuItem>))
        }
      </Select>
    </FormControl>
  );
});

/**
 * Filter Rule Operator Select Component
 * @param {Object} props
 * @param {String} props.operator - Operator to filter on
 * @param {Array} props.validOperations - Valid operations for the field
 * @param {Function} props.onChange - Callback to update the operator
 * @param {Boolean} props.disabled - Disable the component
 */
const InstanceFilterRuleOperatorSelect = memo(({
  operator,
  validOperations,
  onChange,
  disabled = false,
}) => {
  const { t } = useTranslation();
  return (
    <FormControl fullWidth disabled={disabled}>
      <InputLabel id="filter-op-label">{t("FilterOperator")}</InputLabel>
      {validOperations.length === 1 ? (
        <Select
          labelId="filter-op-label"
          id="filter-op"
          value={operator}
          label={t("FilterOperator")}
          onChange={onChange}
          disabled={disabled}
          sx={{
            pointerEvents: 'none',
            backgroundColor: 'inherit',
            '& .MuiSelect-icon': {
              display: 'none',
            },
            textAlign: "center"
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                '& .MuiMenuItem-root': {
                  textAlign: 'center', 
                },
              },
            },
          }}
        >
          {validOperations.map(op => (
            <MenuItem key={op.id} value={op.id}>{op.label}</MenuItem>
          ))}
        </Select>
      ) : (
        <Select
          labelId="filter-op-label"
          id="filter-op"
          value={operator}
          label={t("FilterOperator")}
          onChange={onChange}
          disabled={disabled}
          sx={{
            textAlign: "center"
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                '& .MuiMenuItem-root': {
                  textAlign: 'center', // Center text in the dropdown
                },
              },
            },
          }}
        >
          {validOperations.map(op => (
            <MenuItem key={op.id} value={op.id}>{op.label}</MenuItem>
          ))}
        </Select>
      )}
    </FormControl>
  );
});


/**
 * Filter Rule Value Select Component
 * @param {Object} props
 * @param {String} props.field - Field to filter on
 * @param {Array} props.values - Values to filter on
 * @param {Function} props.onChange - Callback to update the values
 * @param {Array} props.templates - Test templates to filter on
 * @param {Array} props.tags - Tags to filter on
 * @param {Boolean} props.disabled - Disable the component
 * @param {Boolean} [props.allowSelectTestTemplate] - Allow the user to select a test template. If set to
 *                                                    false, the only test template permitted will be the
 *                                                    value passed in the testTemplateId prop.
 * @param {String} [props.testTemplateId] - The test template id to use if allowSelectTestTemplate is false.
 */
const InstanceFilterRuleValueSelect = memo(({
  field,
  values,
  onChange,
  templates,
  tags,
  disabled = false,
  allowSelectTestTemplate = false,
  testTemplateId
}) => {
  const { t } = useTranslation();

  let pageResults;
  if (!field) {
    pageResults = null;
  } else if (field === 'assignmentDate' || field === 'completionDate') {
    pageResults = (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          value={values.dateValues[0]}
          onChange={(event) => onChange(event, field)}
          sx={{ width: '100%' }}
          disabled={disabled}
        />
      </LocalizationProvider>
    );
  } else if (field === 'status') {
    pageResults = (
      <Autocomplete
        multiple
        id="filter-status"
        options={statusValues}
        value={values.stringValues}
        onChange={(event, newValue) => onChange(newValue, field)}
        renderInput={(params) => <TextField {...params} label={t("Status")} />}
        disabled={disabled}
      />
    );
  } else if (field === 'testTemplateId') {
    // If the field is testTemplateId, and allowSelectTestTemplate is false, we need to 
    // restrict the test templates to only the one passed in the testTemplateId prop, and 
    // we need to disable the select control. In this case, we are disabling input so the 
    // value of the test template remains readable.
    pageResults = (
      <FormControl fullWidth disabled={disabled}>
        <InputLabel 
          id="filter-value-template-label"
          sx={{
            backgroundColor: "white"
          }}
        >
          {t("TestTemplate")}&nbsp;
        </InputLabel>
        {allowSelectTestTemplate ?
          (<Select
            labelId="filter-value-template-label"
            id="filter-value-template"
            value={allowSelectTestTemplate ? values?.stringValues[0] : testTemplateId}
            label={t("FilterValues")}
            onChange={(event) => onChange(event, field)}
            disabled={disabled}
          >
            {templates.map(template => (
              <MenuItem key={template.id} value={template.id}>{`${template.name} (${template.status})`}</MenuItem>
            ))}
          </Select>) :
          (<Select
            labelId="filter-value-template-label"
            id="filter-value-template"
            value={allowSelectTestTemplate ? values?.stringValues[0] : testTemplateId}
            label={t("FilterValues")}
            onChange={(event) => onChange(event, field)}
            disabled={disabled}
            sx={{
              pointerEvents: 'none',
              backgroundColor: 'inherit',
              '& .MuiSelect-icon': {
                display: 'none',
              },
            }}
          >
            {templates.map(template => (
              <MenuItem key={template.id} value={template.id}>{`${template.name} (${template.status})`}</MenuItem>
            ))}
          </Select>)
        }
      </FormControl>

    );
  } else if (field === 'tags') {
    let tagValues = tags.filter(tag => values.stringValues.includes(tag.name));
    pageResults = (
      <Autocomplete
        multiple
        id="filter-tags"
        options={tags}
        getOptionLabel={(tag) => tag.name}
        value={tagValues}
        onChange={(event, newValue) => onChange(newValue, field)}
        renderInput={(params) => <TextField {...params} label={t("Tags")} />}
        disabled={disabled}

      />
    );
  }
  return pageResults;
});


/**
 * Filter Rule Component
 * @param {Object} props
 * @param {Object} props.filterRule - Filter rule object
 * @param {Number} props.index - Index of the filter rule
 * @param {Number} props.ruleCount - Total number of filter rules
 * @param {Function} props.onChange - Callback to update the filter rule
 * @param {Function} props.onDelete - Callback to delete the filter rule
 * @param {Array} props.templates - Availlable Test templates to filter on 
 * @param {Array} props.tags - Available tags to filter on
 * @param {Boolean} [props.disabled] - Disable the filter rule
 * @param {Boolean} [props.allowSelectTestTemplate] - Allow the user to select a test template. If set to
 *                                                    false, the only test template permitted will be the
 *                                                    value passed in the testTemplateId prop.
 * @param {Boolean} [props.allowDeleteAllRules] - Allow the user to delete all rules. If set to false,
 *                                                there must be at least one rule.
 * @param {String} [props.testTemplateId] - The test template id to use if allowSelectTestTemplate is false.
 * @param {Boolean} [props.allowStatus] - Allow the user to filter on status. If set to false, the user will
 *                                       not be able to filter on status.
 */
const InstanceFilterRule = memo(({
  filterRule,
  index,
  ruleCount,
  onChange,
  onDelete,
  templates,
  tags,
  disabled = false,
  allowSelectTestTemplate = false,
  allowDeleteAllRules = true,
  testTemplateId = '',
  allowStatus = true
}) => {
  const { t } = useTranslation();

  const determineValidOperations = useCallback(() => {
    // map of filter operation to labels
    const filterOperations = {
      "eq": "=",
      "ne": "<>",
      "gt": ">",
      "lt": "<",
      "ge": ">=",
      "le": "<=",
      "in": t("InAny"),
      "notin": t("NotIn"),
      "inall": t("InAll"),
      "startswith": t("StartsWith"),
      "notstartswith": t("NotStartsWith")
    };

    let validOperations;
    if (!filterRule.field) {
      return [];
    } else {
      validOperations = validFieldFilterOperations[filterRule.field].map((op) => {
        return {
          id: op,
          label: filterOperations[op]
        };
      });
      if (filterRule.field === 'testTemplateId' && !allowSelectTestTemplate) {
        return validOperations.filter(op => op.id === 'eq');
      } else {
        return validOperations;
      }
    }
  }, [allowSelectTestTemplate, filterRule.field, t]);

  const [fieldFilterOperations, setFieldFilterOperations] = useState(determineValidOperations());

  const handleChangeFilterField = (event) => {
    if (filterRule.field !== event.target.value) {
      let newValues;
      switch (event.target.value) {
        case 'assignmentDate':
        case 'completionDate':
          newValues = { dateValues: [new Date(Date.now())] };
          break;
        default:
          newValues = { stringValues: [] };
      }
      onChange(index, {
        ...filterRule,
        field: event.target.value,
        operation: validFieldFilterOperations[event.target.value][0],
        values: newValues
      });
    }
  };

  const handleChangeFilterOperator = (event) => {
    onChange(index, {
      ...filterRule,
      operation: event.target.value
    });
  };

  const handleChangeFilterValues = (event, field) => {
    let values;
    switch (field) {
      case 'assignmentDate':
      case 'completionDate':
        values = { dateValues: [event] };
        break;
      case 'status':
        values = { stringValues: event };
        break;
      case 'tags':
        values = { stringValues: event.map(tag => tag.name) };
        break;
      default:
        if (typeof event.target.value === 'object' && Object.isArray(event.target.value)) {
          values = { stringValues: event.target.value };
        } else {
          values = { stringValues: [event.target.value] };
        }
    }

    onChange(index, {
      ...filterRule,
      values: values
    });
  };

  useEffect(() => {
    setFieldFilterOperations(determineValidOperations());
  }, [determineValidOperations]);

  let pageContents = (
    <Grid container columns={24} spacing={2} justifyContent="flex-start" alignItems="center">
      <Grid item xs={1}>
        <Typography  align="center" variant="p">
          {index > 0 ? t("And") : ""}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <InstanceFilterRuleFieldSelect
          field={filterRule.field}
          onChange={handleChangeFilterField}
          disabled={disabled}
          allowStatus={allowStatus}
        />
      </Grid>
      <Grid item xs={3}>
        <InstanceFilterRuleOperatorSelect
          operator={(fieldFilterOperations.find((op) => op.id === filterRule.operation)) ?
            filterRule.operation : fieldFilterOperations[0].id}
          validOperations={fieldFilterOperations}
          onChange={handleChangeFilterOperator}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12}>
        <InstanceFilterRuleValueSelect
          field={filterRule.field}
          values={filterRule.values}
          onChange={handleChangeFilterValues}
          templates={templates}
          tags={tags}
          disabled={disabled}
          testTemplateId={testTemplateId}
          allowSelectTestTemplate={allowSelectTestTemplate}
        />
      </Grid>
      {(index > 0 || ruleCount > 1 || allowDeleteAllRules) && (   
        <Grid item xs={2}>
          <IconButton
            onClick={() => onDelete(index)}
            aria-label="delete"
            disabled={disabled}
          >
            <DeleteIcon />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );

  return pageContents;
});

export default InstanceFilterRule;