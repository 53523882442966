import { Button, CircularProgress, Grid } from "@mui/material";
import { useAppStreamContext } from "../../context/AppStreamContext";
import { useState, useEffect, useCallback } from "react";
import ScopedPopup from "../ScopedPopup";
import { t } from "i18next";


const WebsiteTest = ({ 
  sessionUrl, 
  toolbarHeight, 
  setSessionActive, 
  testInstance
}) => {
  const { store } = useAppStreamContext();
  const [isSessionLoading, setIsSessionLoading] = useState(true);
  const [isSessionEnded, setIsSessionEnded] = useState(false);
  const [isSessionError, setIsSessionError] = useState(false);

  const initializeIFrame = useCallback(() => {
    const iframe = document.getElementById("website-test-iframe");
    if (!iframe) return;

    // Set up a listener for the "ready" message from the child
    const handleChildReady = (event) => {        
      if (event.data === "ready") {
          // Child is ready, send the testInstance
          iframe.contentWindow.postMessage({ testInstance: testInstance }, sessionUrl);
          window.removeEventListener("message", handleChildReady);
      }
    };

    window.addEventListener("message", handleChildReady);

    // Set iframe src
    iframe.src = sessionUrl;
}, [sessionUrl, testInstance]);

  useEffect(() => {
    initializeIFrame();
  }, [initializeIFrame]);

  const handleWebsiteLoaded = () => {
    setIsSessionLoading(false);
    if (setSessionActive) {
      setSessionActive(true);
    }
  };

  const handleWebsiteError = () => {
    setIsSessionLoading(setIsSessionError);
    if (setSessionActive) {
      setSessionActive(false);
    }
  };

  const handleRestartTest = () => {
    setIsSessionLoading(true);
    setIsSessionError(false);

    // reload iFrame using streaming URL  
    initializeIFrame();
  };

  return (
    <Grid container>
      <Grid item xs={12} sx={{ position: "relative" }}>
        {isSessionLoading && (
          <div
            style={{
              position: "absolute",
              background: "rgba(220,220,220,1)",
              top: "0",
              bottom: "0",
              left: "0",
              right: "0",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column"
            }}
          >
            <CircularProgress />
          </div>
        )}
        {isSessionEnded && (
          <ScopedPopup
            open={true}
            persistent={true}
            title={t("TestSessionEnded")}
            description={t("TestSessionEndedDescription")}
            backdrop="solid"
          />
        )}
        {isSessionError && (
          <ScopedPopup
            open={true}
            persistent={true}
            title={t("TestSessionErrorTitle")}
            description={t("TestSessionErrorText")}
            backdrop="solid"
          >
            <Button variant="contained" onClick={handleRestartTest}>
              {t("RefreshEnvironment")}
            </Button>
          </ScopedPopup>
        )}
        <div
          id="appStream-container"
          style={{
            height: store.isAppStreamFullscreen
              ? "calc(100vh)"
              : `calc(100vh - ${toolbarHeight}px)`,
          }}
        >
          <iframe
            src={sessionUrl}
            title={store.currentTestInstance?.testTemplate.name}
            id="website-test-iframe"
            loading="eager"
            onLoad={handleWebsiteLoaded}
            onError={handleWebsiteError}
            style={{
              backgroundColor: "white",
              height: "100%",
              width: "100%",
              border: "none",
            }}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default WebsiteTest;