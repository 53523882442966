import React, { useState, forwardRef, useImperativeHandle, useEffect, useCallback, useRef } from "react";
import {
  Box,
  Grid,
  MenuItem,
  Tab
} from "@mui/material";
import { TabPanel, TabContext, TabList } from "@mui/lab";
import { useCustomSnackbar } from "../../components/CustomSnackbar";
import { Waiting } from "../Waiting";
import { useFormik, FormikProvider } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import {
  GET_TEST_APPS,
  GET_TEST_ASSETS,
  GET_TEST_TEMPLATE,
} from "../../graphql/queries";
import { useQuery } from "@apollo/client";
import useGuardedRoute from "../../hooks/useGuardedRoute";
import { useAppContext } from "../../context/AppContext";
import TestTemplateGeneralInformationForm from "./TestTemplateGeneralInformationForm";
import TestTemplateBasicSettingsForm from "./TestTemplateBasicSettingsForm";
import TestTemplateRubricForm from "./TestTemplateRubricForm";
import TestTemplateInstructionsForm from "./TestTemplateInstructionsForm";
import TestTemplateInputsForm from "./TestTemplateInputsForm";
import TestTemplateApplicationsForm from "./TestTemplateApplicationsForm";
import TestTemplateOutputsForm from "./TestTemplateOutputsForm";


const TestTemplateForm = React.memo(forwardRef(({
  isCopyMode,
  handleSubmit
}, ref) => {
  const { store } = useAppContext();
  const { t } = useTranslation();
  const { lsEnqueueSnackbar } = useCustomSnackbar();
  const [newTestPlatform, setNewTestPlatform] = useState(store.currentTestTemplate?.testPlatform);
  const [activeTab, setActiveTab] = useState("0");
  const [outputOptions, setOutputOptions] = useState([]);
  const previousOutputsRef = useRef([]);


  // Retrieve test applications
  const { data: taData, loading: taLoading } = useQuery(GET_TEST_APPS, {
    variables: {
      orgId: store.currentOrg.org.id,
    },
    onError: (e) => {
      lsEnqueueSnackbar(e.message, {
        variant: "error",
      });
    },
  });

  // Retrieve test data
  const { data: ttData, loading: ttLoading } = useQuery(GET_TEST_TEMPLATE, {
    variables: {
      orgId: store.currentOrg.org.id,
      templateId: store.currentTestTemplate?.id,
    },
    onError: (e) => {
      lsEnqueueSnackbar(e.message, {
        variant: "error",
      });
    },
  });

  // Retrieve all assets
  const { data: asData, loading: asLoading } = useQuery(GET_TEST_ASSETS, {
    variables: {
      orgId: store.currentOrg.org.id,
      folderId: null,
      assetStatus: [],
    },
    onError: (e) => {
      lsEnqueueSnackbar(e.message, {
        variant: "error",
      });
    },
  });

  useGuardedRoute("/app", [store.currentTestTemplate]);

  const handleConfirmChangePlatformClose = (response) => {
    if (response === 'Confirm') {
      // wipe out the apps, inputs and outputs
      formik.setFieldValue('apps', []);
      formik.setFieldValue('inputs', []);
      formik.setFieldValue('inputEmptyFolders', []);
      formik.setFieldValue('outputs', [
        {
          sequence: 1,
          name: "",
          extension: "",
          autoSelect: false,
          autoSelectPath: "",
        },
      ]);
      formik.setFieldValue('allowDownload', false);
      formik.setFieldValue('allowUpload', false);
      formik.setFieldValue('testPlatform', newTestPlatform);
    } else {
      setNewTestPlatform(formik.values.testPlatform);
    }
  };

  let testTemplateData = ttData?.getTestTemplate || [];
  let pageResults;

  const validationSchema = Yup.object()
    .shape({
      name: Yup.string().required(t("Required")),
      maxWorkTimeHours: Yup.number()
        .typeError(t("IntegerRequired"))
        .integer(t("IntegerRequired"))
        .positive(t("GreaterThanZeroRequired")).notRequired(),
      maxWorkTimeMinutes: Yup.number(t("IntegerRequired"))
        .typeError(t("IntegerRequired"))
        .integer(t("IntegerRequired"))
        .positive(t("GreaterThanZeroRequired"))
        .notRequired(),
      outputs: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required(t("Required")),
          extension: Yup.string().required(t("Required")),
          autoSelect: Yup.boolean(),
          autoSelectPath: Yup.string().when('autoSelect', {
            is: (autoSelect) => autoSelect === true,
            then: Yup.string().required(t("Required")),
            otherwise: Yup.string().notRequired()
          })
        })
      ),
      apps: Yup.array().min(1, t("ApplicationRequired")),
      instructions: Yup.object().shape({
        isText: Yup.boolean(),
        assetId: Yup.string().when("isText", {
          is: (isText) => isText === false,
          then: Yup.string().required(t("Required")),
        }),
        text: Yup.string().when("isText", {
          is: (isText) => isText === true,
          then: Yup.string().required(t("Required")),
        }),
      }),
      hasOverallScore: Yup.boolean(),
      overallScoreDetails: Yup.array()
        .when("hasOverallScore", {
          is: true,
          then: Yup.array()
            .of(
              Yup.object().shape({
                score: Yup.number(), // No validation needed
                name: Yup.string(), 
                description: Yup.string().required(t("Required")), 
              })
            )
            .required(t("Required")),
          otherwise: Yup.array().notRequired().nullable(), 
        }),
      hasCategoryScore: Yup.boolean(),
      categories: Yup.array()
        .when("hasCategoryScore", {
          is: true,
          then: Yup.array()
            .of(
              Yup.object().shape({
                sequence: Yup.number(), 
                name: Yup.string().required(t("Required")),
                description: Yup.string(),
                scoreRange: Yup.array().of(Yup.number()),
                weight: Yup.number()
                  .typeError(t("IntegerRequired"))
                  .integer(t("IntegerRequired"))
                  .min(0, t("PositiveRequired"))
                  .required(t("Required")),
                categoryScoreDetails: Yup.array().of(
                  Yup.object().shape({
                    score: Yup.number(),
                    name: Yup.string(),
                    description: Yup.string().required(t("Required"))
                  })
                ),
              })
            )
            .required(t("Required")),
          otherwise: Yup.array().notRequired().nullable()
        }),
    })
    .test(
      'at-least-one',
      t("HoursOrMinutesRequired"),
      function (values) {
        const hours = Number(values.maxWorkTimeHours) || 0;
        const minutes = Number(values.maxWorkTimeMinutes) || 0;
        if (hours <= 0 && minutes <= 0) {
          return this.createError({
            path: 'maxWorkTimeHours', 
            message: t("HoursOrMinutesRequired"),
          });
        } else {
          return true;
        }
      }
    );


  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: true,
    initialValues: {
      name: isCopyMode
        ? ""
        : testTemplateData.name
          ? testTemplateData.name.trim()
          : "",
      description: testTemplateData.description
        ? testTemplateData.description.trim()
        : "",
      testPlatform: testTemplateData.testPlatform || "appstream",
      maxWorkTimeHours: testTemplateData.maxWorkTime
        ? Math.floor(testTemplateData.maxWorkTime / 60 / 60) || ""
        : "",
      maxWorkTimeMinutes: testTemplateData.maxWorkTime
        ? (testTemplateData.maxWorkTime / 60) % 60 || ""
        : "",
      allowDownload: testTemplateData.allowDownload ?? false,
      allowUpload: testTemplateData.allowUpload ?? false,
      allowPause: testTemplateData.allowPause ?? true,
      instructions: testTemplateData?.instructions
        ? {
          isText: testTemplateData.instructions.type === "text",
          assetId: testTemplateData.instructions.asset?.id || "",
          text: testTemplateData.instructions.text || "",
        }
        : {
          isText: false,
          assetId: "",
          text: "",
        },
      apps: testTemplateData.apps
        ? testTemplateData.apps
        : [],
      inputs: testTemplateData?.inputs?.length
        ? testTemplateData.inputs
          .filter((inp) => inp.asset)
          .map((inp) => ({
            asset: inp.asset,
            id: inp.id,
            targetPath: inp.targetPath || "",
          }))
        : [],
      inputEmptyFolders: testTemplateData?.inputs?.length
        ? testTemplateData.inputs
          .filter((inp) => !inp.asset)
          .map((inp) => ({
            asset: null,
            id: inp.id,
            targetPath: inp.targetPath || "",
          }))
        : [],
      outputs:
        testTemplateData.outputs && testTemplateData.outputs.length > 0
          ? testTemplateData.outputs.map(output => ({
            ...output,
            autoSelect: output.autoSelectPath ? true : false,
            // Remove /Test Files/ from the outputs, it will get readded during submission
            autoSelectPath: output.autoSelectPath ? output.autoSelectPath.substring(12) : "",
          }))
          : [
            {
              sequence: 1,
              name: "",
              extension: "",
              autoSelect: false,
              autoSelectPath: "",
            },
          ],
      hasOverallScore: testTemplateData.rubric?.hasOverallScore || false,
      hasCategoryScore: testTemplateData.rubric?.hasCategoryScore || false,
      overallScoreRange: testTemplateData.rubric?.overallScoreRange
        ? [
          testTemplateData.rubric.overallScoreRange.low,
          testTemplateData.rubric.overallScoreRange.high,
        ]
        : [0, 1],
      overallScoreDetails:
        testTemplateData.rubric?.overallScoreDetails &&
          testTemplateData.rubric?.overallScoreDetails.length > 0
          ? testTemplateData.rubric.overallScoreDetails.map((osd) => ({
            score: osd.score,
            name: osd.name,
            description: osd.description,
          }))
          : [
            {
              score: 0,
              name: "",
              description: "",
            },
            {
              score: 1,
              name: "",
              description: "",
            },
          ],
      categories:
        testTemplateData.rubric?.categories &&
          testTemplateData.rubric?.categories.length > 0
          ? testTemplateData.rubric.categories.map((category) => ({
            sequence: category.sequence,
            name: category.name,
            description: category.description,
            scoreRange: [category.scoreRange.low, category.scoreRange.high],
            weight: category.weight,
            categoryScoreDetails: category.scoreDetails.map((sd) => ({
              score: sd.score,
              name: sd.name,
              description: sd.description,
            })),
          }))
          : [
            {
              sequence: 1,
              name: "",
              description: "",
              scoreRange: [0, 1],
              weight: "",
              categoryScoreDetails: [
                {
                  score: 0,
                  name: "",
                  description: "",
                },
                {
                  score: 1,
                  name: "",
                  description: "",
                },
              ],
            },
          ],
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      let inputs = [...(values.inputs.map(({ asset, targetPath }) => ({
        assetId: asset.id,
        targetPath,
      }))), ...(values.inputEmptyFolders.map((item) => ({
        assetId: null,
        targetPath: item.targetPath || item.fullpath
      })))];

      let outputs = [];
      for (let o of values.outputs) {
        if (o.name !== "" && o.extension !== "") {
          outputs.push({
            sequence: values.outputs.indexOf(o),
            name: o.name,
            extension: o.extension,
            isFolder: o.extension === "directory" ? true : false,
            //Add back the Test Files prefix
            autoSelectPath: o.autoSelectPath ? `/Test Files/${o.autoSelectPath}` : null,
          });
        }
      }

      let categories = [];
      for (let c of values.categories) {
        if (c.name !== "") {
          categories.push({
            sequence: values.categories.indexOf(c),
            name: c.name,
            description: c.description,
            scoreRange: {
              low: Number(c.scoreRange[0]),
              high: Number(c.scoreRange[1]),
            },
            weight: Number(c.weight),
            scoreDetails: c.categoryScoreDetails,
          });
        }
      }

      let overallScoreDetails = [];
      for (let s of values.overallScoreDetails) {
        if (s.score !== "") {
          overallScoreDetails.push({
            score: s.score,
            name: s.name,
            description: s.description,
          });
        }
      }

      const instructions = {
        type: values.instructions.isText ? "text" : "asset",
        text: values.instructions.text,
        assetId: values.instructions.assetId,
      };
      // text is null if the type is asset, else it has to be length > 0 (validation)
      if (!values.instructions.isText) {
        instructions.text = null;
      }
      // assetId is null if it's falsy value or the type is text
      if (!values.instructions.assetId || values.instructions.isText) {
        instructions.assetId = null;
      }

      const vars = {
        variables: {
          orgId: store.currentOrg.org.id,
          testTemplate: {
            id: testTemplateData.id,
            name: values.name.trim(),
            description:
              values.description === "" ? null : values.description.trim(),
            maxWorkTime:
              Number(values.maxWorkTimeHours) * 60 * 60 +
              Number(values.maxWorkTimeMinutes) * 60,
            allowDownload: values.allowDownload,
            allowUpload: values.allowUpload,
            allowPause: values.allowPause,
            instructions,
            inputs: inputs.length ? inputs : [],
            apps: values.apps.length ? values.apps.map((app) => ({ "id": app.id, "autoStart": app.autoStart })) : null,
            outputs: outputs.length === 0 ? null : outputs,
            rubric: (values.hasOverallScore || values.hasCategoryScore) ? {
              hasOverallScore: values.hasOverallScore,
              hasCategoryScore: values.hasCategoryScore,
              overallScoreRange: {
                low: Number(values.overallScoreRange[0]),
                high: Number(values.overallScoreRange[1]),
              },
              overallScoreDetails:
                overallScoreDetails.length === 0 ? null : overallScoreDetails,
              categories: categories.length === 0 ? null : categories,
            } : null,
            testPlatform: values.testPlatform
          },
        },
      };
      handleSubmit(vars, setSubmitting);
    }
  });

  const determineOutputOptions = useCallback(() => {
    let options = [];
    formik.values.apps.forEach((application) => {
      if (application && application.extensions) {
        options.push(
          application.extensions.map((e, index) => (
            <MenuItem key={application.id + "-" + index} value={e.extension}>
              {e.extension + " - " + e.description}
            </MenuItem>
          ))
        );
      }
    });

    let flattenedOutputOptions = options.flat(1)
      .filter(
        (value, index, self) =>
          index === self.findIndex((e) => e.props.value === value.props.value)
      )
      .sort((a, b) =>
        a.props.value.toUpperCase().localeCompare(b.props.value.toUpperCase())
      );

    return flattenedOutputOptions;
  }, [formik.values.apps]);

  const updateOutputOptions = useCallback(() => {
    const options = determineOutputOptions();
    setOutputOptions(options);

    return options;
  }, [setOutputOptions, determineOutputOptions]);

  useEffect(() => {
    if (formik.values.apps) {
      const options = updateOutputOptions();
      const currentOutputs = formik.values.outputs;

      if (currentOutputs) {
        const updatedOutputs = currentOutputs.map((output) => {
          if (!options.find((option) =>
            option.props.value === output.extension || output.extension === ""
          )) {
            return { ...output, extension: "" }; // Clear invalid extensions
          }
          return output; // Keep valid extensions
        });

        // Only update if outputs have changed
        if (JSON.stringify(previousOutputsRef.current) !== JSON.stringify(updatedOutputs)) {
          previousOutputsRef.current = updatedOutputs;
          formik.setFieldValue("outputs", updatedOutputs);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.apps, updateOutputOptions, formik.values.outputs]);

  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleNextTab = () => {
    handleChangeTab(null, (parseInt(activeTab) + 1).toString());
  };

  useImperativeHandle(ref, () => ({
    handleSubmitForm: () => {
      formik.validateForm().then(errors => {
        if (Object.keys(errors).length > 0) {
          // Map fields to their respective tabs
          const tabErrorMapping = {
            0: ['name', 'description'],
            1: ['maxWorkTimeHours', 'maxWorkTimeMinutes'],
            2: ['instructions'],
            3: ['inputs'],
            4: ['apps'],
            5: ['outputs'],
            6: ['overallScoreDetails', 'categories'],
          };

          // Find the first tab with an error
          const firstTabWithError = Object.keys(tabErrorMapping).find(tab =>
            tabErrorMapping[tab].some(field => errors[field])
          );

          if (firstTabWithError !== undefined) {
            setActiveTab(firstTabWithError);
          }
        } else {
          formik.submitForm();
        }
      });
    }
  }));

  if (taLoading || ttLoading || asLoading) {
    pageResults = <Waiting />;
  } else if (taData && ttData && asData) {
    testTemplateData = ttData.getTestTemplate;
    pageResults = (
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <TabContext value={activeTab}>
            <Box sx={{ flexGrow: 1, display: "flex" }}>
              <Grid container>
                <Grid item xs={2}>
                  <TabList
                    orientation="vertical"
                    value={activeTab}
                    onChange={handleChangeTab}
                    sx={{
                      borderRight: 1,
                      borderColor: "divider",
                    }}
                  >
                    <Tab
                      label={t("GeneralInformation")}
                      id="tab-0"
                      aria-controls="tabpanel-0"
                      value="0"
                    />
                    <Tab
                      label={t("BasicSettings")}
                      id="tab-1"
                      aria-controls="tabpanel-1"
                      value="1"
                    />
                    <Tab
                      label={t("Instructions")}
                      id="tab-2"
                      aria-controls="tabpanel-2"
                      value="2"
                    />
                    <Tab
                      label={t("Inputs")}
                      id="tab-3"
                      aria-controls="tabpanel-3"
                      value="3"
                    />
                    <Tab
                      label={t("Applications")}
                      id="tab-4"
                      aria-controls="tabpanel-4"
                      value="4"
                    />
                    <Tab
                      label={t("OutputsTitle")}
                      id="tab-5"
                      aria-controls="tabpanel-5"
                      value="5"
                    />
                    <Tab
                      label={t("RubricsTitle")}
                      id="tab-6"
                      aria-controls="tabpanel-6"
                      value="6"
                    />
                  </TabList>
                </Grid>
                <Grid item xs={10} sx={{
                  paddingLeft: "5%",
                  paddingRight: "5%",
                  height: "70vh",
                  overflowY: "auto"
                }}>
                  <TabPanel value="0">
                    <div
                      role="tabpanel"
                      id="tabpanel-0"
                      aria-labelledby="tab-0"
                    >
                      <TestTemplateGeneralInformationForm
                        newTestPlatform={newTestPlatform}
                        setNewTestPlatform={setNewTestPlatform}
                        handleConfirmChangePlatformClose={handleConfirmChangePlatformClose}
                        handleContinue={handleNextTab}
                      />
                    </div>
                  </TabPanel>
                  <TabPanel value="1">
                    <div
                      role="tabpanel"
                      id="tabpanel-1"
                      aria-labelledby="tab-1"
                    >
                      <TestTemplateBasicSettingsForm
                        testPlatform={formik.values.testPlatform}
                        handleContinue={handleNextTab}
                      />
                    </div>
                  </TabPanel>
                  <TabPanel value="2">
                    <div
                      role="tabpanel"
                      id="tabpanel-2"
                      aria-labelledby="tab-2"
                    >
                      <TestTemplateInstructionsForm
                        assets={asData.getTestAssets}
                        handleContinue={handleNextTab}
                      />
                    </div>
                  </TabPanel>
                  <TabPanel value="3">
                    <div
                      role="tabpanel"
                      id="tabpanel-3"
                      aria-labelledby="tab-3"
                    >
                      <TestTemplateInputsForm
                        testPlatform={formik.values.testPlatform}
                        handleContinue={handleNextTab}
                      />
                    </div>
                  </TabPanel>
                  <TabPanel value="4">
                    <div
                      role="tabpanel"
                      id="tabpanel-4"
                      aria-labelledby="tab-4"
                    >
                      <TestTemplateApplicationsForm
                        testPlatform={formik.values.testPlatform}
                        handleContinue={handleNextTab}
                        applications={taData.getTestApps}
                        handleUpdateOutputOptions={updateOutputOptions}
                      />
                    </div>
                  </TabPanel>
                  <TabPanel value="5">
                    <div
                      role="tabpanel"
                      id="tabpanel-5"
                      aria-labelledby="tab-5"
                    >
                      <TestTemplateOutputsForm
                        testPlatform={formik.values.testPlatform}
                        outputOptions={outputOptions}
                        handleContinue={handleNextTab}
                      />
                    </div>
                  </TabPanel>
                  <TabPanel value="6">
                    <div
                      role="tabpanel"
                      id="tabpanel-6"
                      aria-labelledby="tab-6"
                    >
                      <TestTemplateRubricForm />
                    </div>
                  </TabPanel>
                </Grid>
              </Grid>
            </Box>
          </TabContext>
        </form>
      </FormikProvider>
    );
  } else {
    pageResults = "";
  }
  return pageResults;
}));

export default TestTemplateForm;
