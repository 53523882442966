import React from "react";
import {
  Button,
  Grid,
  Typography,
  FormControl,
  FormControlLabel,
  MenuItem,
  InputLabel,
  IconButton,
  TextField,
  Select,
  Checkbox,
  FormHelperText,
  InputAdornment,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useField } from "formik";
import { useTranslation } from "react-i18next";

const TestTemplateOutputsForm = React.memo(({
  testPlatform,
  outputOptions,
  handleContinue
}) => {
  const { t } = useTranslation();
  const [outputsField, , outputsHelpers] = useField("outputs");

  const handleAddOutput = () => {
    const outputs = [...outputsField.value];
    outputs.push({
      sequence: "",
      name: "",
      extension: "",
      autoSelect: false,
      autoSelectPath: "",
    });

    outputsHelpers.setValue(outputs);
  };

  let pageResults = (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
        >
          <Grid item xs={(testPlatform === 'appstream') ? 10 : 12}>
            <Typography component="p" sx={{ marginBottom: 3 }}>
              {t("OutputsFromTest")}
            </Typography>
          </Grid>
          {testPlatform === 'appstream' &&
            <Grid item xs={2}>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Button
                    variant="outlined"
                    onClick={handleAddOutput}
                    sx={{ display: { xs: "none", sm: "inline-flex" } }}
                  >
                    {t("AddOutput")}
                  </Button>
                  <IconButton
                    onClick={handleAddOutput}
                    sx={{ display: { xs: "inline-flex", sm: "none" } }}
                  >
                    <AddIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          }
        </Grid>
        <OutputsList
          outputs={outputsField.value}
          outputOptions={outputOptions}
          testPlatform={testPlatform}
          t={t}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleContinue}
              sx={{ marginTop: 1 }}
            >
              {t("Continue")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
  return pageResults;
});

const OutputsList = React.memo(({
  outputs,
  outputOptions,
  testPlatform,
  t
}) => {
  return outputs.map((value, index) => (
    <Output
      value={value}
      index={index}
      key={index}
      outputOptions={outputOptions}
      testPlatform={testPlatform}
      numOuputs={outputs.length}
      t={t}
    />
  ));
});

const Output = React.memo(({
  value,
  index,
  outputOptions,
  testPlatform,
  numOuputs,
  t
}) => {

  const [outputsField, , outputsHelpers] = useField("outputs");
  const [sequenceField] = useField(`outputs.${index}.sequence`);
  const [nameField, nameMeta] = useField(`outputs.${index}.name`);
  const [extensionField, extensionMeta, extensionHelpers] = useField(`outputs.${index}.extension`);
  const [autoSelectField] = useField(`outputs.${index}.autoSelect`);
  const [autoSelectPathField, autoSelectPathMeta] = useField(`outputs.${index}.autoSelectPath`);

  const handleRemoveOutput = (index) => {
    const newOutputs = [...outputsField.value];
    newOutputs.splice(index, 1);
    outputsHelpers.setValue(newOutputs);
  };

  // Clear the extension field if the extensions is no longer in the outputOptions list
  // useEffect(() => {
  //   if (!outputOptions.find(option => option?.prop.value === extensionField.value || 
  //     extensionField.value === "")) {
  //     extensionHelpers.setValue("");
  //   }
  // }, [outputOptions, extensionField.value, extensionHelpers]);

  return (
    <div style={{ marginBottom: "8px" }} key={index}>
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <TextField
              {...sequenceField}
              variant="outlined"
              fullWidth
              id={"sequence" + index}
              label={t("Sequence")}
              value={value.sequence}
              sx={{ display: "none" }}
            />
            <TextField
              {...nameField}
              variant="outlined"
              fullWidth
              required
              id={"name" + index}
              label={t("Name")}
              value={value.name}
              error={!!nameMeta.error}
              helperText={nameMeta.error}
            />
          </Grid>
          <Grid item xs={4}>
            <FormControl
              variant="outlined"
              sx={{
                margin: 0,
                width: "100%",
                display: "flex",
                flexWrap: "nowrap",
              }}
            >
              <InputLabel id={"extension" + index + "-label"}>
                {t("Extension")}
              </InputLabel>
              <Select
                {...extensionField}
                label={t("Extension")}
                labelId={"extension" + index + "-label"}
                id={"extension" + index}
                value={value.extension}
                error={!!extensionMeta.error}
                required
                onChange={(e) => {
                  extensionHelpers.setError(null);
                  extensionHelpers.setValue(e.target.value);
                }}
              >
                <MenuItem key="directory" value="directory">
                  Directory
                </MenuItem>
                {outputOptions}
              </Select>
              {!!extensionMeta.error && (
                <FormHelperText error={true}>
                  {extensionMeta.touched && extensionMeta.error}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          {testPlatform === 'appstream' && numOuputs > 1 &&
            <Grid item xs={2}>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <IconButton
                    onClick={() => handleRemoveOutput(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          }
        </Grid>
        {testPlatform === 'appstream' &&
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  {...autoSelectField}
                  checked={value.autoSelect}
                />
              }
              label={t("AutoSelect")}
            />
            {value.autoSelect && (
              <TextField
                {...autoSelectPathField}
                variant="outlined"
                fullWidth
                id={"autoSelectPath" + index}
                label={t("AutoSelectPath")}
                value={value.autoSelectPath}
                InputProps={{
                  startAdornment: <InputAdornment position="start">Test Files/</InputAdornment>
                }}
                error={!!autoSelectPathMeta.error}
                helperText={autoSelectPathMeta.error}
                sx={{ mb: "30px" }}
              />
            )}
          </Grid>
        }
      </Grid>
    </div>
  );
});

export default TestTemplateOutputsForm;      