import { Button, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../context/AppContext";
import { useCustomSnackbar } from "../../components/CustomSnackbar";
import { Waiting } from "../../components/Waiting";
import { useMutation } from "@apollo/client";
import { GET_TEST_INSTANCES_ASSIGNED_TO_ME } from "../../graphql/queries";
import { RELEASE_RESERVATION } from "../../graphql/mutations";
import { handleCacheUpdate } from "../../cache";
import useGuardedRoute from "../../hooks/useGuardedRoute";

const CancelReservationConfirmation = ({ mode }) => {
  const { t } = useTranslation();
  const { lsEnqueueSnackbar } = useCustomSnackbar();
  const navigate = useNavigate();

  const { store } = useAppContext();

  const [releaseReservation] = useMutation(RELEASE_RESERVATION, {
    update: (cacheClient, response) => {
      if (response?.data) {
        handleCacheUpdate(
          "update",
          cacheClient,
          response.data.releaseReservation,
          "id",
          GET_TEST_INSTANCES_ASSIGNED_TO_ME,
          "getTestInstancesAssignedToMe",
          null,
          {}
        );
      }
    },
  });

  useGuardedRoute("/app", [store.currentTestInstance]);

  const currentReservation = store.currentTestInstance?.reservations?.find(
    (r) => {
      let now = Date.now();
      let start = new Date(r.timeslot.start).getTime();
      return now <= start + r.timeslot.duration * 1000;
    }
  );

  const handleConfirm = () => {
    releaseReservation({
      variables: {
        orgId: store.currentTestInstance.testTemplate.org.id,
        instanceId: store.currentTestInstance.id,
        reservationId: currentReservation.id,
      },
    })
      .then((res) => {
        if (res.errors) {
          lsEnqueueSnackbar(res.errors[0].message, {
            variant: "error",
          });
        } else {
          lsEnqueueSnackbar(t("ReservationDeclined"), {
            variant: "success",
          });
          navigate("/app");
        }
      })
      .catch((e) => {
        lsEnqueueSnackbar(e.message, {
          variant: "error",
        });
      }); // catch network errors
  };

  let pageResults;

  if (currentReservation) {
    pageResults = (
      <Grid container spacing={2} paddingLeft="20%" paddingRight="20%">
        <Grid item xs={12}>
          <Typography
            component="h1"
            variant="h5"
            sx={{ marginBottom: 3, fontWeight: "bold" }}
          >
            {t("CancelReservationHeading")}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography component="p" sx={{ marginBottom: 3 }}>
            {t("CancelReservationBody")}
          </Typography>
        </Grid>

        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              sx={{ marginTop: 3, marginRight: 0, marginBottom: 1.5 }}
              onClick={() => navigate(-1)}
            >
              {t("Cancel")}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              sx={{ marginTop: 3, marginRight: 0, marginBottom: 1.5 }}
              onClick={handleConfirm}
            >
              {t("Confirm")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  } else {
    pageResults = <Waiting />;
  }

  return pageResults;
};

export default CancelReservationConfirmation;
