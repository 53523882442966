import {
  Typography,
  Grid,
  Avatar,
  Tooltip
} from "@mui/material";
import { t } from "i18next";
import { format } from "date-fns";
import { getCurrentDateFormat } from "../../App";
import { formatSecondsToString } from "../../utils";
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';

export default function DashBoardAvailableToAssignDrawer({
  testTemplate
}) {
  let dateFormat = getCurrentDateFormat();

  return (
    <Grid container spacing={3}>
      {/* Left Column */}
      <Grid item xs={7}>
        {/* Applications */}
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            <Typography variant="body1">
              <strong>{t("Applications")}: &nbsp;</strong>
            </Typography>
          </Grid>
          {testTemplate.apps?.length > 0 ? (
            testTemplate.apps.map((app, index) => (
              <Grid item key={index}>
                <Tooltip title={app.displayName}>
                  <Avatar
                    alt={app.displayName}
                    src={app.icon || app.displayName.charAt(0)}
                    sx={{
                      height: 25,
                      width: 25,
                    }}
                    variant="square"
                  />
                </Tooltip>
              </Grid>
            ))            
          ) : (
            <Grid item>
              <Typography variant="body1">{t("None")}</Typography>
            </Grid>
          )}
        </Grid>
        {/* Work Time */}
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            <Typography variant="body1">
              <strong>{t("WorkTime")}:&nbsp;</strong>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">
              {formatSecondsToString(testTemplate.maxWorkTime)}
            </Typography>
          </Grid>
        </Grid>

        {/* Last Update Date */}
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            <Typography variant="body1">
              <strong>{t("LastUpdated")}:&nbsp;</strong>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">
              {format(new Date(testTemplate.lastUpdateDate), "PPPp", { locale: dateFormat })}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      {/* Right Column */}
      <Grid item xs={5}>
        {/* Upload Allowed */}
        <Grid container alignItems="flex-end" spacing={1}>
          <Grid item>
            <Typography variant="body1">
              <strong>{t("UploadAllowed")}:&nbsp;</strong>
            </Typography>
          </Grid>
          <Grid item>
            {testTemplate.allowUpload ? (
              <CheckBoxOutlinedIcon sx={{ fontSize: 16 }} />
            ) : (
              <CheckBoxOutlineBlankOutlinedIcon sx={{ fontSize: 16 }} />
            )}
          </Grid>
        </Grid>

        {/* Download Allowed */}
        <Grid container alignItems="flex-end" spacing={1}>
          <Grid item>
            <Typography variant="body1">
              <strong>{t("DownloadAllowed")}:&nbsp;</strong>
            </Typography>
          </Grid>
          <Grid item>
            {testTemplate.allowDownload ? (
              <CheckBoxOutlinedIcon sx={{ fontSize: 16 }} />
            ) : (
              <CheckBoxOutlineBlankOutlinedIcon sx={{ fontSize: 16 }} />
            )}
          </Grid>
        </Grid>

        {/* Pause Allowed */}
        <Grid container alignItems="flex-end" spacing={1}>
          <Grid item>
            <Typography variant="body1">
              <strong>{t("PauseAllowed")}:&nbsp;</strong>
            </Typography>
          </Grid>
          <Grid item>
            {testTemplate.allowPause ? (
              <CheckBoxOutlinedIcon sx={{ fontSize: 16 }} />
            ) : (
              <CheckBoxOutlineBlankOutlinedIcon sx={{ fontSize: 16 }} />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}