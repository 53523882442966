import React, { useState, useLayoutEffect, useRef, useEffect } from "react";
import MainRouter from "./router";
import { withApollo } from "@apollo/client/react/hoc";
import { autoSignIn } from "./identity";
import { useTranslation } from "react-i18next";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import { GET_USER_PROFILE, SITE_CONFIG } from "./graphql/queries";
import { UPDATE_USER_PROFILE } from "./graphql/mutations";
import { createTheme, ThemeProvider } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { SnackbarProvider } from "notistack";
import { config } from "./config";
import { useAppContext } from "./context/AppContext";
import { enCA, frCA } from "date-fns/locale";

let currentLanguage = "English";
function setCurrentLanguage(language) {
  currentLanguage = language;
}
export function getCurrentLanguage() {
  return currentLanguage;
}
export function getCurrentDateFormat() {
  let dateFormat;
  if (currentLanguage === "English") {
    dateFormat = enCA;
  } else if (currentLanguage === "French") {
    dateFormat = frCA;
  }
  return dateFormat;
}

var siteOrgId = null;
export function getSiteOrgId() {
  return siteOrgId;
}

export var refreshUserProfile = null;

export const autoSignInStages = {
  haveNotTried: 0,
  trying: 1,
  tried: 2,
};

const getUPStages = {
  callingGetUP: 0,
  calledGetUP: 1,
  gotUP: 2,
};

export var setSiteConfig;
export var siteConfigRef;
export var signedInRef;

// This component tries to sign in to the user's account automatically at startup
// time by using data in cookies. It sets the signedIn state which is then used by
// the core MainRouter component.
export function App(props) {
  const gqlClient = props.client;

  const { i18n } = useTranslation();

  const { actions, store } = useAppContext();

  const [signedIn, setSignedIn] = useState(false); //tells you when in signed in state
  signedInRef = useRef(signedIn);
  const [forceSignOut, setForceSignOut] = useState(false); // set to true to force a signout
  const [signingIn, setSigningIn] = useState(true); //used by UI to prevent input during sign in process
  const [autoSignInStage, setAutoSignInStage] = useState(
    autoSignInStages.haveNotTried
  );
  const [upStage, setUPStage] = useState(getUPStages.callingGetUP);
  const [wasSignedIn, setWasSignedIn] = useState(false); // makes sure user profile is only queried once after auto-signin
  const [siteConfiguration, setSiteConfiguration] = useState({});
  siteConfigRef = useRef(siteConfiguration);
  setSiteConfig = setSiteConfiguration;
  const [tryingAutoSignIn, setTryingAutoSignIn] = useState(false);
  const [tryAutoSignInTrigger, setTryAutoSignInTrigger] = useState(false);
  const [refreshUserProfileTrigger, setRefreshUserProfileTrigger] =
    useState(false);


  function appSetSignedIn(signedInState) {
    setSignedIn(signedInState);
    signedInRef.current = signedInState;
  }  

  function resetAppState() {
    appSetSignedIn(false);
    setForceSignOut(false);
    setSigningIn(true);
    actions.setUserDetails({ user: {} });
    setAutoSignInStage(autoSignInStages.haveNotTried);
    setWasSignedIn(false);
    setTryingAutoSignIn(false);
    setTryAutoSignInTrigger(false);
    setRefreshUserProfileTrigger(false);
    actions.setCurrentOrg(null);
    setUPStage(getUPStages.callingGetUP);
    gqlClient.clearStore();
  }

  refreshUserProfile = () => {
    setRefreshUserProfileTrigger(true);
  }; // will force the useEffect hook to re-get the user profile

  // Get the site confuigration so we can set up the environment for the
  // user's organization based on their subdomain or www.examplery.xx
  // depending on the current URL
  let subdomain;
  const host = window.location.host;

  // an organization site unless it's www or root
  subdomain = host.substring(0, host.indexOf("."));
  if (
    host === config.orgDomain ||
    subdomain === "www" ||
    host.substring(host.indexOf(".")).startsWith(".ngrok.io") ||
    host.substring(host.indexOf(".")).startsWith(".cloudfront.net")
  ) {
    subdomain = null;
  }
 
  const siteConfig = useQuery(SITE_CONFIG, {
    variables: {
      sd: subdomain,
    },
    fetchPolicy: "no-cache",
  });

  let [
    getUserProfile,
    {
      data: getUPData,
      loading: getUPLoading,
      error: getUPError,
      refetch: getUPRefetch,
    },
  ] = useLazyQuery(GET_USER_PROFILE, {
    fetchPolicy: "no-cache",
  });

  const [updateUserProfile] = useMutation(UPDATE_USER_PROFILE);

  // once we have the site configuration, try auto-signing in once. Only do this if we're not
  // signed in.
  useLayoutEffect(() => {
    if (!signedIn) {
      // if we couldn't get the site configuration, don't try to auto-signin
      if (siteConfig.error) {
        setAutoSignInStage(autoSignInStages.tried);
        setSigningIn(false);
      }

      // if we got the site config and we haven't tried to auto-signin, attempt auto-signin
      if (
        !siteConfig.loading &&
        !siteConfig.error &&
        siteConfig.data &&
        autoSignInStage === autoSignInStages.haveNotTried
      ) {
        // parse the URL query string and add the resulting object to the site config.
        siteConfig.data.siteConfig.urlQueryParms = parseQueryString();
        setSiteConfiguration(siteConfig.data.siteConfig);
        if (!siteConfigRef.current.siteName) {
          // this means the site config has never been loaded
          siteConfigRef.current = siteConfig.data.siteConfig;
          actions.setSiteConfig(siteConfig.data.siteConfig);
          actions.setSubdomain(subdomain);
        }
        siteOrgId = siteConfig.data.siteConfig.orgId;
        setAutoSignInStage(autoSignInStages.trying); // don't try to auto sign in again
        setTryAutoSignInTrigger(true);
      }
    }
  }, [signedIn, signingIn, siteConfig, autoSignInStage, tryAutoSignInTrigger, actions, subdomain]);

  // This hook gets triggered by the above hook once the site configuration is set.
  // we need a second hook because the dependency is on the siteConfiguration state
  // variable, that the previous hook modifies.
  useLayoutEffect(() => {
    // call back function to attempt sign in from an existing server side cookie
    async function tryAutoSignIn() {
      if (!tryingAutoSignIn) {
        setTryingAutoSignIn(true);
        let isSignedIn;
        try {
          isSignedIn = await autoSignIn(
            gqlClient,
            setForceSignOut,
            siteConfigRef,
            setSiteConfiguration
          );
        } catch (e) {
          console.log(e);
        }
        setAutoSignInStage(autoSignInStages.tried);
        appSetSignedIn(isSignedIn); // current signed in state
        setSigningIn(false); // signing in is complete (for displaying circular progress)
        setWasSignedIn(isSignedIn); // remember if auto-signin was successful
        setTryingAutoSignIn(false);
      }
    }

    if (tryAutoSignInTrigger) {
      setTryAutoSignInTrigger(false);
      tryAutoSignIn();
    }
  }, [tryAutoSignInTrigger, siteConfiguration, gqlClient, tryingAutoSignIn]);

  // If auto-signin was successful, get the user profile so we can override the site
  // language with the user's preferences and set up the avatar. We only want to do this once.
  useLayoutEffect(() => {
    if (wasSignedIn) {
      if (getUPData && getUPRefetch) {
        getUPRefetch()
          .then((res) => {
            if (res.errors) {
              setUPStage(getUPStages.gotUP);
              setSigningIn(false);
              // need to handle the error somehow
              console.log(res.errors[0].message);
            } else if (res.data) {
              setUPStage(getUPStages.calledGetUP);
            }
          })
          .catch((e) => {
            setUPStage(getUPStages.gotUP);
            setSigningIn(false);
            // need to handle the error somehow
            console.log(e.message);
          });
      } else {
        getUserProfile();
        setUPStage(getUPStages.calledGetUP);
      }
      setWasSignedIn(false);
    }
  }, [wasSignedIn, getUserProfile, getUPRefetch, setUPStage, getUPData]);

  useLayoutEffect(() => {
    if (upStage === getUPStages.calledGetUP) {
      if (!getUPLoading) {
        if (getUPData) {
          actions.setUserDetails(getUPData.userProfile);
          setCurrentLanguage(getUPData.userProfile.user.language);
          i18n.changeLanguage(getUPData.userProfile.user.language);
          // force the subscription language to the current language
          updateUserProfile({
            variables: {
              up: { language: getUPData.userProfile.user.language },
            },
          });
          setSigningIn(false);
          setUPStage(getUPStages.gotUP);
        } else if (getUPError) {
          setSigningIn(false);
          setUPStage(getUPStages.gotUP);
        }
      }
    }
  }, [
    getUPData,
    getUPLoading,
    getUPError,
    getUserProfile,
    i18n,
    updateUserProfile,
    upStage,
    actions,
  ]);

  // Force a refresh of the user profile. This generally happens when a server event occurs
  // that forces a client to refresh their token (e.g. access rights changed);
  useEffect(() => {
    if (refreshUserProfileTrigger) {
      getUPRefetch()
        .then((res) => {
          if (res.errors) {
            setRefreshUserProfileTrigger(false);
          } else if (res.data) {
            actions.setUserDetails(res.data.userProfile);
            // refresh currentOrg
            let currOrg = res.data.userProfile.user.rights.find(
              (o) => o.org.id === store.currentOrg.org.id
            );
            if (currOrg) {
              actions.setCurrentOrg(currOrg);
            }
            setRefreshUserProfileTrigger(false);
          }
        })
        .catch((e) => {
          setRefreshUserProfileTrigger(false);
        });
    }
  }, [
    refreshUserProfileTrigger,
    getUPRefetch,
    store.currentOrg?.org?.id,
    actions,
  ]);

  let pageResults;
  if (siteConfig.loading || autoSignInStage !== autoSignInStages.tried) {
    pageResults = (
      <Backdrop open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  } else if (siteConfig.error) {
    pageResults = (
      <Typography component="h1" variant="h5">
        {siteConfig.error.message}
      </Typography>
    );
  } else {
    // If siteConfig is loaded, extract the user interface theme elements and display the page
    const theme = createTheme({
      palette: {
        primary: {
          main: siteConfiguration.brandcolors.primarycolor,
          // main: "#0340d1",
        },
        secondary: {
          main: siteConfiguration.brandcolors.secondarycolor,
          // main: "#ff8c73",
        },
        background: {
          default: "#fff",
        },
      },
    });
    pageResults = (
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <MainRouter
            resetAppState={resetAppState}
            signedIn={signedIn}
            setSignedIn={appSetSignedIn}
            setWasSignedIn={setWasSignedIn}
            signingIn={signingIn}
            setSigningIn={setSigningIn}
            getCurrentLanguage={getCurrentLanguage}
            setCurrentLanguage={setCurrentLanguage}
            siteConfig={siteConfiguration}
            setSiteConfig={setSiteConfiguration}
            siteConfigRef={siteConfigRef}
            forceSignOut={forceSignOut}
            setForceSignOut={setForceSignOut}
            getUserProfile={getUserProfile}
            getUPData={getUPData}
            getUPLoading={getUPLoading}
            getUPError={getUPError}
            autoSignInStage={autoSignInStage}
          />
        </SnackbarProvider>
      </ThemeProvider>
    );
  }
  return pageResults;
}

// Parse the URL Query String and format as an object where each query parameter is
// set to the objects property name and the value is the property's value.
// Inputs: None
// Response: The parsed query object.  If there is no query string or no parameters
// then the returned value is an empty object {}
function parseQueryString() {
  let qObject = {};
  let qString = window.location.search.replace(/&amp;/g, '&');

  if (qString && qString !== "") {
    // remove the leading ?
    qString = qString.substr(qString.indexOf("?") + 1);
    let name;
    let value;
    let len;
    let done = false;
    do {
      len = qString.indexOf("=");
      if (len === -1) {
        done = true;
      } else {
        name = decodeURI(qString.substr(0, len));
        if (len < qString.length) {
          qString = qString.substr(len + 1);
          len =
            qString.indexOf("&") === -1 ? qString.length : qString.indexOf("&");
          value = decodeURI(qString.substr(0, len));
          qObject[name] = value;
          if (len < qString.length) {
            qString = qString.substr(len + 1);
          } else {
            done = true;
          }
        }
      }
    } while (!done);
  }
  return qObject;
}

export default withApollo(App);
