import React from "react";
import { Grid, Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import candidateImage from "../../assets/candidate_new.png";
import organizationImage from "../../assets/organization_new.png";
import recruiterImage from "../../assets/recruiter_new.png";

export default function SplashPage() {
  const navigate = useNavigate();

  return (
    <Grid container justifyContent="center">
      <Grid container sx={{ width: "80%" }}>
        <Grid item xs={12}>
          <Box>
            <Typography
              variant="h3"
              align="center"
              sx={{ fontWeight: "bold", marginBottom: 2 }}
            >
              {t("DashboardTitle")}
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="h5"
              align="center"
              sx={{ color: "text.secondary", marginBottom: 2 }}
            >
              {t("DashboardSubtitle")}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            columnSpacing={10}
            alignItems="flex-end"
          >
            <Grid item xs={12} lg={4}>
              <Grid container justifyContent="center" sx={{ marginTop: 2 }}>
                <Grid item>
                  <img src={recruiterImage} alt="" />
                </Grid>
                <Grid item>
                  <Typography sx={{ color: "text.secondary", marginTop: 2 }}>
                    {t("RecruiterDashDescription")}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Grid container justifyContent="center" sx={{ marginTop: 2 }}>
                <Grid item>
                  <img src={candidateImage} alt="" />
                </Grid>
                <Grid item>
                  <Typography sx={{ color: "text.secondary", marginTop: 2 }}>
                    {t("CandidateDashDescription")}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Grid container justifyContent="center" sx={{ marginTop: 2 }}>
                <Grid item>
                  <img src={organizationImage} alt="" />
                </Grid>
                <Grid item>
                  <Typography sx={{ color: "text.secondary", marginTop: 2 }}>
                    {t("OrganizationDashDescription")}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ marginTop: 4 }}
          >
            <Typography
              variant="h5"
              align="center"
              sx={{ color: "text.secondary" }}
            >
              {t("ReadyToSignUp")}
            </Typography>
            &nbsp;&nbsp;&nbsp;
            <Button
              color="primary"
              variant="contained"
              onClick={() => navigate("/app/signup")}
              disabled={true}
            >
              {t("SignUp")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}