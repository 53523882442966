import {
  Button,
  Grid,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../context/AppContext";
import { useCustomSnackbar } from "../../components/CustomSnackbar";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_TEST_INSTANCE, GET_TEST_INSTANCES_ASSIGNED_TO_ME } from "../../graphql/queries";
import {
  SET_TEST_INSTANCE_ANSWER,
  SUBMIT_TEST_INSTANCE,
} from "../../graphql/mutations";
import { handleCacheUpdate } from "../../cache";
import { Waiting } from "../../components/Waiting";
import useGuardedRoute from "../../hooks/useGuardedRoute";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import TestInstanceAnswerDroppable from "../../components/TestInstance/TestInstanceAnswerDroppable";
import TestInstanceFileBrowser from "../../components/TestInstance/TestInstanceFileBrowser";
import RefreshIcon from "@mui/icons-material/Refresh";
import { rootFolderPath } from "../../components/FileSystem/FileSystem";
import { gqlCli } from "../../identity";

const SubmitTest = () => {
  const { t } = useTranslation();
  const { lsEnqueueSnackbar } = useCustomSnackbar();
  const navigate = useNavigate();

  const { store, actions } = useAppContext();

  const [outputs, setOutputs] = useState([]);

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [fhGet, setFHGet] = useState(true);
  const [fhData, setFHData] = useState(null);
  const [autoSelectRequired, setAutoSelectRequired] = useState(false);
  const [autoSelectComplete, setAutoSelectComplete] = useState(false);
  const [outputsInitialized, setOutputsInitialized] = useState(false);
  const [testOutOfTime, setTestOutOfTime] = useState(false);

  function handleOpenDialog() {
    setOpenConfirmationDialog(true);
  }

  function handleCloseDialog() {
    setOpenConfirmationDialog(false);
  }

  // refresh the test instance before starting the test to get accurate time remaining data
  const [getTestInstance, { data: instData, loading: instLoading }] = useLazyQuery(GET_TEST_INSTANCE, {
    variables: {
      orgId: store.currentTestInstance?.testTemplate.org.id,
      instanceId: store.currentTestInstance?.id,
    },
    onError: (e) => {
      lsEnqueueSnackbar(e.message, {
        variant: "error",
      });
    },
    fetchPolicy: "network-only",
  });


  const [setTestInstanceAnswer] = useMutation(SET_TEST_INSTANCE_ANSWER, {
    update: (cacheClient, response) => {
      if (response?.data) {
        handleCacheUpdate(
          "update",
          cacheClient,
          response.data?.setTestInstanceAnswer,
          "id",
          GET_TEST_INSTANCES_ASSIGNED_TO_ME,
          "getTestInstancesAssignedToMe",
          null,
          {}
        );
      }
    },
  });

  const [submitTestInstance, { loading: stLoading }] = useMutation(SUBMIT_TEST_INSTANCE, {
    update: (cacheClient, response) => {
      if (response?.data) {
        handleCacheUpdate(
          "update",
          cacheClient,
          response.data.submitTestInstance,
          "id",
          GET_TEST_INSTANCES_ASSIGNED_TO_ME,
          "getTestInstancesAssignedToMe",
          null,
          {}
        );
      }
    }
  });

  useGuardedRoute("/app", [store.currentTestInstance]);

  const handleReturnToDashboard = () => {
    navigate("/app");
  };

  const handleReturnToTest = () => {
    navigate("/app/testinstance");
  }

  const setAnswer = useCallback((path, id, successMessage) => {
    const vars = {
      variables: {
        orgId: store.currentTestInstance?.testTemplate.org.id,
        instanceId: store.currentTestInstance.id,
        outputId: id,
        path: path,
      },
    };

    setTestInstanceAnswer(vars)
      .then((res) => {
        if (res.errors) {
          lsEnqueueSnackbar(res.errors[0].message, {
            variant: "error",
          });
        } else {
          // Update the currentTestInstance 
          actions.setCurrentTestInstance(res.data.setTestInstanceAnswer);
          if (successMessage) {
            lsEnqueueSnackbar((successMessage), {
              variant: "success",
            });
          }
        }
      })
      .catch((e) => {
        lsEnqueueSnackbar(e.message, {
          variant: "error",
        });
      }); // catch network errors;
  }, [
    actions,
    lsEnqueueSnackbar,
    setTestInstanceAnswer,
    store.currentTestInstance?.testTemplate.org.id,
    store.currentTestInstance.id
  ]);

  const handleDropTestAnswer = (item, id) => {
    let index = outputs.findIndex((o) => o.id === id);
    let updatedOutputs = [...outputs];
    updatedOutputs[index].path = item.id;
    setOutputs(updatedOutputs);
    setAnswer(item.id, id, t("TestInstanceAnswerSubmitted"));
  };

  const handleRemoveTestAnswer = (id) => {
    let index = outputs.findIndex((o) => o.id === id);
    let updatedOutputs = [...outputs];
    updatedOutputs[index].path = null;
    setOutputs(updatedOutputs);
    setAnswer(null, id, t("TestInstanceAnswerRemoved"));
  };

  const handleSubmit = () => {
    const vars = {
      variables: {
        orgId: store.currentTestInstance?.testTemplate.org.id,
        instanceId: store.currentTestInstance.id,
      },
    };
    submitTestInstance(vars)
      .then((res) => {
        if (res.errors) {
          lsEnqueueSnackbar(res.errors[0].message, {
            variant: "error",
          });
        } else {
          lsEnqueueSnackbar(t("TestSubmitted"), {
            variant: "success",
          });
          navigate("/app");
        }
      })
      .catch((e) => {
        lsEnqueueSnackbar(e.message, {
          variant: "error",
        });
      }); // Catch network errors
  };
  useEffect(() => {
    getTestInstance();
  }, [getTestInstance]);

  useEffect(() => {
    if (instData) {
      // update the instance in the cache
      let testInstance = instData.getTestInstance;
      handleCacheUpdate(
        "update",
        gqlCli,
        testInstance,
        "id",
        GET_TEST_INSTANCES_ASSIGNED_TO_ME,
        "getTestInstancesAssignedToMe",
        null,
        {}
      );
      // update the instance in the store
      actions.setCurrentTestInstance(testInstance);
      setTestOutOfTime(testInstance.workTimeRemaining === 0);
    }
  }, [instData, actions]);

  // Initialize outputs
  useEffect(() => {
    if (!outputsInitialized) {
      setOutputsInitialized(true);
      let updatedOutputs = [...store.currentTestInstance?.outputs || []]
        .sort((a, b) => a.testOutput.sequence - b.testOutput.sequence)
        .map((output) => {
          return {
            name: output.testOutput.name || "",
            extension: output.testOutput.extension || "",
            path: output.path || "",
            id: output.id,
            isFolder: output.testOutput.isFolder,
            testOutput: output.testOutput
          };
        });

      setAutoSelectRequired(updatedOutputs.reduce((prevValue, curValue) => {
        return prevValue || (curValue.testOutput.autoSelectPath && curValue.testOutput.autoSelectPath0 !== "");
      }, false));

      setOutputs(updatedOutputs);
    }
  }, [outputsInitialized, outputs, store.currentTestInstance?.outputs]);


  // Autoselect an outputs if required
  useEffect(() => {
    if (outputs.length && fhData && !autoSelectComplete && outputsInitialized) {
      setAutoSelectComplete(true);

      for (let output of outputs) {
        let autoSelectPath;
        if (!output.path && output.testOutput.autoSelectPath &&
          output.testOutput.autoSelectPath !== "") {
          autoSelectPath = fhData.find((fo) =>
            `${rootFolderPath}${fo.folder}${fo.filename}`.toUpperCase() ===
            output.testOutput.autoSelectPath.toUpperCase());
        }
        if (autoSelectPath && output.testOutput.isFolder === autoSelectPath.isFolder) {
          let fullpath = `${rootFolderPath}${autoSelectPath.folder}${autoSelectPath.filename}`;


          let index = outputs.findIndex((o) => o.id === output.id);
          let updatedOutputs = [...outputs];
          updatedOutputs[index].path = fullpath;
          setOutputs(updatedOutputs);
          setAnswer(fullpath, output.id);
        }
      }
    }
  }, [
    fhData,
    outputs,
    autoSelectComplete,
    outputsInitialized,
    setAnswer
  ]);

  let pageResults = (
    <>
      {(stLoading || instLoading) && <Waiting/>} 
      <DndProvider backend={HTML5Backend}>
        <Grid container spacing={2} paddingLeft="5%" paddingRight="5%"
          direction="column"
          justifyContent="space-between"
          style={{ minHeight: "80vh" }}
        >
          <Grid item xs={10}>
            <Grid container spacing={2} direction="row"
              justifyContent="flex-start" alignItems="flex-start">
              <Grid item xs={12}>
                <Typography component="h1" variant="h5" sx={{ fontWeight: "bold" }}>
                  {t("SubmitTestHeading")}
                </Typography>
                {
                  testOutOfTime ?
                  <Typography component="p" sx={{ marginBottom: 3 }}>
                    {store.currentTestInstance?.testTemplate.testPlatform === 'appstream' ? 
                      t("SubmitTestBodyNoTimeRemaining") : 
                      t("SubmitWebsiteTestBodyNoTimeRemaining")
                    }
                  </Typography> :
                  <Typography component="p" sx={{ marginBottom: 3 }}>
                    {store.currentTestInstance?.testTemplate.testPlatform === 'appstream' ? 
                      t("SubmitTestBodyTimeRemaining") :
                      t("SubmitWebsiteTestBodyTimeRemaining")
                    }
                  </Typography>
                }
              </Grid>
              {store.currentTestInstance?.testTemplate.testPlatform === 'appstream' &&
                (<Grid item xs={12}>
                  <Grid container justifyContent="space-between" alignItems="flex-start">
                    <Grid item xs={7}>
                      <Grid container justifyContent="flex-start" alignItems="flex-start">
                        <Grid item xs={10} sx={{
                          maxHeight: "50vh",
                          overflow: "auto"
                        }}>
                          <TestInstanceFileBrowser
                            draggable={true}
                            droppable={false}
                            selectable={false}
                            fhGet={fhGet}
                            setFHGet={setFHGet}
                            fhData={fhData}
                            setFHData={setFHData}
                            submittingTest={true}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          {fhData && <IconButton onClick={() => { setFHGet(true); }}>
                            <RefreshIcon />
                          </IconButton>}
                        </Grid>
                      </Grid>
                    </Grid>
                    {(!autoSelectRequired || autoSelectComplete) &&
                      <Grid item xs={5} sx={{
                        maxHeight: "50vh",
                        overflow: "auto",
                        paddingRight: "2%"
                      }}>
                        {outputs?.map((output) => {
                          return (
                            <TestInstanceAnswerDroppable
                              key={output.id}
                              id={output.id}
                              name={output.name}
                              extension={output.extension}
                              path={output.path}
                              isFolder={output.isFolder}
                              onDrop={handleDropTestAnswer}
                              onRemoveAnswer={handleRemoveTestAnswer}
                            />
                          );
                        })}
                      </Grid>
                    }
                  </Grid>
                </Grid>)
              }
            </Grid>
          </Grid>
          <Grid item xs={2} style={{ alignSelf: "flex-end" }}>
            <Grid container justifyContent="flex-end" alignItems="flex-end">
              <Grid item sx={{ textAlign: "right" }}>
                {
                  !testOutOfTime && 
                  <>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleReturnToTest}
                      sx={{ marginTop: 3, marginRight: 1 }}
                    >
                      {t("ReturnToTest")}
                    </Button>
                    &nbsp;&nbsp;&nbsp;
                  </>
                }
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleReturnToDashboard}
                  sx={{ marginTop: 3, marginRight: 1 }}
                >
                  {t("ReturnToDashboard")}
                </Button>
                &nbsp;&nbsp;&nbsp;
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ marginTop: 3 }}
                  onClick={() => {
                    handleOpenDialog();
                  }}
                >
                  {t("Submit")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DndProvider>
      <Dialog
        open={openConfirmationDialog}
        onClose={handleCloseDialog}
        aria-labelledby="dialog-title"
      >
        <DialogTitle id="dialog-title">
          {t("SubmitTestConfirmation")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("SubmitTestConfirmationExplanation")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            color="secondary"
            variant="contained"
          >
            {t("Cancel")}
          </Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            variant="contained"
          >
            {t("Confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );


  return pageResults;
};

export default SubmitTest;
