import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useCustomSnackbar } from "../../components/CustomSnackbar";
import { usePasswordReqs } from "../../components/PasswordReqs";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  Grid,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { getAccessToken } from "../../identity";
import { Waiting } from "../../components/Waiting";

export default function ChangePasswordDialog(props) {
  const { t } = useTranslation();
  const { lsEnqueueSnackbar } = useCustomSnackbar();
  const [openPassword, setOpenPassword] = useState(false);

  // Handle opening and closing of password dialog
  const handleClickOpenPassword = () => {
    setOpenPassword(true);
  };

  const handleClosePassword = () => {
    formik.resetForm();
    setOpenPassword(false);
  };

  // Set up the change password mutation
  const CHANGE_PASSWORD = gql`
    mutation ChangePassword($op: String!, $np: String!, $at: String!) {
      changePassword(oldPassword: $op, newPassword: $np, accessToken: $at)
    }
  `;
  const [changePassword] = useMutation(CHANGE_PASSWORD);

  let passwordReqs;
  passwordReqs = usePasswordReqs(props.siteConfig);

  const validationSchema = Yup.object({
    currentpassword: passwordReqs,
    password: passwordReqs,
    passwordconfirm: Yup.string()
      .oneOf([Yup.ref("password"), null], t("PasswordsMustMatch"))
      .required(t("Required")),
  });

  const formik = useFormik({
    initialValues: {
      currentpassword: "",
      password: "",
      passwordconfirm: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const vars = {
        variables: {
          op: values.currentpassword,
          np: values.password,
          at: getAccessToken(),
        },
      };
      // Change the user's password on the server
      changePassword(vars)
        .then((res) => {
          if (res.errors) {
            lsEnqueueSnackbar(res.errors[0].message, {
              variant: "error",
            });
          } else {
            lsEnqueueSnackbar(t("PasswordChanged"), {
              variant: "success",
            });
            handleClosePassword();
          }
        })
        .catch((e) => {
          lsEnqueueSnackbar(e.message, {
            variant: "error",
          });
          handleClosePassword();
        }); // catch network errors
      setSubmitting(false);
    },
  });

  return (
    <React.Fragment>
      <div>
        <Typography
          component="h1"
          variant="h5"
          sx={{ marginTop: 3, marginBottom: 3 }}
        >
          {t("ChangePassword")}
        </Typography>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleClickOpenPassword}
        >
          {t("ChangePassword")}
        </Button>
      </div>
      <Dialog
        open={openPassword}
        onClose={handleClosePassword}
        aria-labelledby="form-dialog-title"
      >
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle id="form-dialog-title">
            {t("ChangePassword")}
            <IconButton
              aria-label="close"
              sx={{ position: "absolute", right: 1, top: 1, color: "grey" }}
              onClick={handleClosePassword}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ marginBottom: 2 }}>
              {t("ChangePasswordDialogText")}
            </DialogContentText>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="currentpassword"
                  label={t("CurrentPassword")}
                  type="password"
                  id="currentpassword"
                  value={formik.values.currentpassword}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.currentpassword &&
                    Boolean(formik.errors.currentpassword)
                  }
                  helperText={
                    formik.touched.currentpassword &&
                    formik.errors.currentpassword
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="password"
                  label={t("Password")}
                  type="password"
                  id="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="passwordconfirm"
                  label={t("ConfirmPassword")}
                  type="password"
                  id="passwordconfirm"
                  value={formik.values.passwordconfirm}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.passwordconfirm &&
                    Boolean(formik.errors.passwordconfirm)
                  }
                  helperText={
                    formik.touched.passwordconfirm &&
                    formik.errors.passwordconfirm
                  }
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={handleClosePassword}
              color="secondary"
              sx={{ marginRight: 0, marginBottom: 1.5 }}
            >
              {t("Cancel")}
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{ marginRight: 0, marginBottom: 1.5 }}
            >
              {t("Update")}
            </Button>
          </DialogActions>
          {formik.isSubmitting ? <Waiting /> : ""}
        </form>
      </Dialog>
    </React.Fragment>
  );
}
