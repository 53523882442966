import { useDrop } from "react-dnd";
import { FileSystemItemType } from "../components/FileSystem/FileSystem";

const useDroppable = (onDrop, accept, ...args) => {
  const [{ canDrop, isOverCurrent }, drop] = useDrop(() => ({
    accept: accept || [FileSystemItemType.File, FileSystemItemType.AssetFolder, FileSystemItemType.CreatedFolder],
    collect: (monitor) => ({
      // isOver - When the item is dragged on top of the drop area + shallow = on the specific child only
      isOverCurrent: monitor.isOver({ shallow: true }),
      // canDrop: When the item is dragging
      canDrop: monitor.canDrop(),
    }),
    drop: (item, monitor) => {
      if (monitor.canDrop() && monitor.isOver({ shallow: true })) onDrop(item, ...args);
    },
  }), [onDrop]);

  const isActive = canDrop && isOverCurrent;
  let backgroundColor = "#fff";
  if (isActive) {
    backgroundColor = "lightgreen";
  } else if (canDrop) {
    backgroundColor = "lightyellow";
  }

  return {
    dropRef: drop,
    style: { backgroundColor },
  };
};

export default useDroppable;
