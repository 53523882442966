import {
  Box,
  CircularProgress,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Radio,
  Tabs,
  Tab,
  Typography,
  Button
} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DescriptionIcon from "@mui/icons-material/Description";
import FolderIcon from "@mui/icons-material/Folder";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../context/AppContext";
import useGuardedRoute from "../../hooks/useGuardedRoute";
import { InstructionsType } from "../../graphql/enums";
import { formatBytesToString, formatSecondsToString } from "../../utils";
import { parseInstructionsString, downloadInstructions } from "../../instructions";
import { useState } from "react";
import { LIST_TEST_INSTANCE_RECORDING_FILES } from "../../graphql/queries";
import { useMutation, useQuery } from "@apollo/client";
import { useCustomSnackbar } from "../../components/CustomSnackbar";
import {
  GET_TEST_INSTANCE_OUTPUT_DOWNLOAD_URL,
  GET_TEST_INSTANCE_RECORDING_DOWNLOAD_URL,
  GET_TEST_INSTANCE_INSTRUCTIONS_DOWNLOAD_URL
} from "../../graphql/mutations";
import { Roles } from "../../components/Dashboard/DashboardTabs";
import useSessionUrl from "../../hooks/useSessionUrl";
import { useNavigate } from "react-router-dom";
import WebsiteTest from "../../components/TestInstance/WebsiteTest";
import { Waiting } from "../../components/Waiting";
import ScopedPopup from "../../components/ScopedPopup";

const ReviewSubmittedTest = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { store } = useAppContext();
  const { lsEnqueueSnackbar } = useCustomSnackbar();

  const [recordingURLLoaders, setRecordingURLLoaders] = useState({});
  const [outputURLLoaders, setOutputURLLoaders] = useState({});
  const [isUrlLoading, setIsUrlLoading] = useState(false);

  // const testInstructions = store.currentTestInstance?.testTemplate.instructions;
  const testInstructions = store.currentTestInstanceTemplate?.instructions;
  // const rubric = store.currentTestInstance?.testTemplate.rubric;
  const rubric = store.currentTestInstanceTemplate?.rubric;
  const scorecard = store.currentTestInstance?.scorecards?.find((sc) => {
    return sc.status === 'marked';
  });


  const {
    data: testInstanceSessionUrl,
    error: tisurlError,
    loading: tisurlLoading
  } = useSessionUrl({ readOnly: true });

  let totalWeightedScore = 0;
  let weightedCategoryScorePercentage = 0;
  if (rubric?.hasCategoryScore) {
    for (let category of rubric.categories) {
      totalWeightedScore += category.weight;
    }
    if (totalWeightedScore > 0) {
      weightedCategoryScorePercentage = scorecard?.weightedCategoryScore / totalWeightedScore * 100;
    }
  }

  const [activeTab, setActiveTab] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
  };

  useGuardedRoute("/app", [store.currentTestInstance]);

  // Fetch test recordings
  const { data: rData, loading: rLoading } = useQuery(
    LIST_TEST_INSTANCE_RECORDING_FILES,
    {
      variables: {
        orgId: store.currentTestInstanceTemplate?.org.id,
        instanceId: store.currentTestInstance?.id,
      },
      onError: (e) => {
        lsEnqueueSnackbar(e.message, {
          variant: "error",
        });
      },
    }
  );

  const [getTestInstanceOutputDownloadURL] = useMutation(
    GET_TEST_INSTANCE_OUTPUT_DOWNLOAD_URL
  );
  const [getTestInstanceRecordingDownloadURL] = useMutation(
    GET_TEST_INSTANCE_RECORDING_DOWNLOAD_URL
  );
  const [getTestInstanceInstructionsDownloadURL] = useMutation(
    GET_TEST_INSTANCE_INSTRUCTIONS_DOWNLOAD_URL
  );

  const handleInstructionsDownload = () => {
    downloadInstructions(
      setIsUrlLoading,
      getTestInstanceInstructionsDownloadURL,
      store.currentTestInstanceTemplate?.org.id,
      store.currentTestInstance.id,
      lsEnqueueSnackbar);
  };

  const handleDownloadOutput = (output) => {
    setOutputURLLoaders((prevState) => ({ ...prevState, [output.id]: true }));
    getTestInstanceOutputDownloadURL({
      variables: {
        orgId: store.currentTestInstanceTemplate?.org.id,
        instanceId: store.currentTestInstance.id,
        outputId: output.id,
      },
    })
      .then((res) => {
        if (res.errors) {
          lsEnqueueSnackbar(res.errors[0].message, {
            variant: "error",
          });
        } else {
          let url = res.data.getTestInstanceOutputDownloadURL;
          window.open(url, "_blank");
          // win.focus();
        }
        setOutputURLLoaders((prevState) => ({
          ...prevState,
          [output.id]: false,
        }));
      })
      .catch((e) => {
        lsEnqueueSnackbar(e.message, {
          variant: "error",
        });
        setOutputURLLoaders((prevState) => ({
          ...prevState,
          [output.id]: false,
        }));
      }); // catch network errors;
  };

  const handleDownloadRecording = (file) => {
    setRecordingURLLoaders((prevState) => ({
      ...prevState,
      [file.filename]: true,
    }));
    getTestInstanceRecordingDownloadURL({
      variables: {
        orgId: store.currentTestInstanceTemplate?.org.id,
        instanceId: store.currentTestInstance.id,
        path: file.filename,
      },
    })
      .then((res) => {
        if (res.errors) {
          lsEnqueueSnackbar(res.errors[0].message, {
            variant: "error",
          });
        } else {
          let url = res.data.getTestInstanceRecordingDownloadURL;
          window.open(url, "_blank");
          // win.focus();
        }
        setRecordingURLLoaders((prevState) => ({
          ...prevState,
          [file.filename]: false,
        }));
      })
      .catch((e) => {
        lsEnqueueSnackbar(e.message, {
          variant: "error",
        });
        setRecordingURLLoaders((prevState) => ({
          ...prevState,
          [file.filename]: false,
        }));
      }); // catch network errors;
  };

  const timeRemaining = store.currentTestInstance?.workTimeRemaining;
  const maxWorkTime = store.currentTestInstanceTemplate?.maxWorkTime;
  const workTimeElapsedString = formatSecondsToString(
    maxWorkTime - timeRemaining
  );
  const totalTimeElapsedString = formatSecondsToString(maxWorkTime);
  const percentageTimeUsed = (maxWorkTime - timeRemaining) / maxWorkTime * 100;

  let pageResults;
  if (store.currentOrg?.roles?.find((r) =>
    [Roles.Owner, Roles.HiringManager, Roles.Juror].includes(r.name))) {
    pageResults = (
      <>
        <Grid container paddingLeft="5%" paddingRight="5%">
          <Grid item xs={12}>
            <Typography
              component="h1"
              variant="h5"
              sx={{ marginBottom: 3, fontWeight: "bold" }}
            >
              {t("ViewResults")}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={activeTab}
                  onChange={handleChangeTab}
                  aria-label="tabs"
                >
                  <Tab
                    label={t("TestInstructions")}
                    id="tab-0"
                    aria-controls="tabpanel-0"
                  />
                  <Tab
                    label={t("TestOutputs")}
                    id="tab-1"
                    aria-controls="tabpanel-1"
                  />
                  <Tab
                    label={t("ScorecardTitle")}
                    id="tab-2"
                    aria-controls="tabpanel-2"
                  />
                  {/*} */}
                </Tabs>
              </Box>
              <div
                role="tabpanel"
                id="tabpanel-0"
                aria-labelledby="tab-0"
                style={{
                  overflowY: "auto",
                  height: "65vh",
                  display: activeTab === 0 ? 'block' : 'none'
                }}
              >
                <Box sx={{ p: 3 }}>
                  {testInstructions && (
                    <>
                      <Grid item>
                        <Typography variant="p" sx={{ fontWeight: "bold" }}>
                          {t("TestInstructions")}
                        </Typography>
                      </Grid>
                      <Grid item sx={{ marginTop: 2 }}>
                        <Typography variant="p">
                          {t("TestInstructionsJurorComment")}
                        </Typography>
                      </Grid>
                      {testInstructions.type === InstructionsType.Text && (
                        <Grid item sx={{ marginTop: 2 }}>
                          {parseInstructionsString(testInstructions.text)}
                        </Grid>
                      )}
                      {testInstructions.type === InstructionsType.Asset &&
                        testInstructions.asset && (
                          <>
                            <Grid item sx={{ marginTop: 2 }}>
                              <Typography
                                component="p"
                                sx={{ marginBottom: 3 }}
                              >
                                {t("DownloadTestIntroductionFileExplanation")}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <ListItem
                                secondaryAction={
                                  <IconButton
                                    edge="end"
                                    aria-label="download"
                                    onClick={handleInstructionsDownload}
                                    disabled={isUrlLoading}
                                  >
                                    {isUrlLoading ? (
                                      <CircularProgress size={24} />
                                    ) : (
                                      <FileDownloadIcon />
                                    )}
                                  </IconButton>
                                }
                              >
                                <ListItemAvatar>
                                  <DescriptionIcon />
                                </ListItemAvatar>
                                <ListItemText
                                  primary={testInstructions.asset.filename}
                                  secondary={formatBytesToString(
                                    testInstructions.asset.size
                                  )}
                                />
                              </ListItem>
                            </Grid>
                          </>
                        )}
                    </>
                  )}
                </Box>
              </div>
              <div
                role="tabpanel"
                id="tabpanel-1"
                aria-labelledby="tab-1"
                style={{
                  overflowY: "auto",
                  height: "65vh",
                  display: activeTab === 1 ? 'block' : 'none'
                }}
              >
                {store.currentTestInstanceTemplate?.testPlatform === 'appstream' ? (
                  <Box sx={{ p: 3 }}>
                    <Grid item>
                      <Grid container columnSpacing={4}>
                        {/* Test & Candidate Info */}
                        <Grid item xs={4}>
                          <Typography component="p" sx={{ marginTop: 3 }}>
                            <strong>{t("TestName") + ":"}</strong>
                          </Typography>
                          <Typography component="p">
                            {store.currentTestInstanceTemplate?.name}
                          </Typography>
                          <Typography component="p" sx={{ marginTop: 3 }}>
                            <strong>{t("Candidate") + ":"}</strong>
                          </Typography>
                          <Typography component="p">
                            {`${store.currentTestInstance?.assignee.firstname} ${store.currentTestInstance?.assignee.lastname}`}
                          </Typography>
                          <Typography component="p" sx={{ marginTop: 3 }}>
                            <strong>{t("WorkTimeElapsed") + ":"}</strong>
                          </Typography>
                          <Typography component="p">
                            {workTimeElapsedString}<br /><strong>{t("OutOf") + ":"}</strong><br />{totalTimeElapsedString} {t("AvailableForTest")} ({Math.round(percentageTimeUsed)}%)
                          </Typography>
                        </Grid>
                        {/* Outputs */}
                        <Grid item xs={4}>
                          <List
                            sx={{
                              width: "100%",
                            }}
                          >
                            <ListItem>
                              <ListItemText
                                primary={t("TestAnswers")}
                                sx={{ textDecoration: "underline" }}
                              />
                            </ListItem>
                            {store.currentTestInstance?.outputs?.map((output) => {
                              return (
                                <ListItem
                                  key={output.id}
                                  secondaryAction={
                                    <IconButton
                                      edge="end"
                                      aria-label="download"
                                      onClick={() => handleDownloadOutput(output)}
                                      disabled={!!outputURLLoaders[output.id]}
                                    >
                                      {!!outputURLLoaders[output.id] ? (
                                        <CircularProgress size={24} />
                                      ) : (
                                        output.path ? <FileDownloadIcon /> : ""
                                      )}
                                    </IconButton>
                                  }
                                >
                                  <ListItemAvatar>
                                    {output.testOutput.isFolder ? (
                                      <FolderIcon />
                                    ) : (
                                      <DescriptionIcon />
                                    )}
                                  </ListItemAvatar>
                                  <ListItemText
                                    primary={output.testOutput.name}
                                    secondary={output.path || <em>{t("OutputNotSubmitted")}</em>}
                                  />
                                </ListItem>
                              );
                            })}
                          </List>
                        </Grid>
                        {/* Recordings */}
                        <Grid item xs={4}>
                          <List
                            sx={{
                              width: "100%",
                            }}
                          >
                            <ListItem>
                              <ListItemText
                                primary={t("TestRecordings")}
                                sx={{ textDecoration: "underline" }}
                              />
                            </ListItem>

                            {rLoading && (
                              <Grid item xs={12} align="center">
                                <CircularProgress color="secondary" />
                              </Grid>
                            )}

                            {rData?.listTestInstanceRecordingFiles.map((file) => {
                              return (
                                <ListItem
                                  key={file.filename}
                                  secondaryAction={
                                    <IconButton
                                      edge="end"
                                      aria-label="download"
                                      onClick={() =>
                                        handleDownloadRecording(file)
                                      }
                                      disabled={
                                        !!recordingURLLoaders[file.filename]
                                      }
                                    >
                                      {!!recordingURLLoaders[file.filename] ? (
                                        <CircularProgress size={24} />
                                      ) : (
                                        <FileDownloadIcon />
                                      )}
                                    </IconButton>
                                  }
                                >
                                  <ListItemAvatar>
                                    <PlayCircleFilledWhiteIcon />
                                  </ListItemAvatar>
                                  <ListItemText
                                    primary={file.filename}
                                    secondary={formatBytesToString(file.size)}
                                  />
                                </ListItem>
                              );
                            })}
                          </List>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                ) :
                  (
                    <Grid container alignContent="flex-start" justifyContent="flex-start">
                      {/* Test & Candidate Info */}
                      <Grid item xs={12} sx={{marginLeft: 2}}>
                        <Typography sx={{ marginTop: 3 }}>
                          <strong>{t("TestName") + ": "}</strong>{store.currentTestInstanceTemplate?.name}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <strong>{t("Candidate") + ": "}</strong>{store.currentTestInstance?.assignee.firstname} {store.currentTestInstance?.assignee.lastname}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sx={{marginLeft: 2}}>
                        <Typography>
                          <strong>{t("WorkTimeElapsed") + ": "}</strong>{workTimeElapsedString} <strong>{t("OutOf")}:</strong> {totalTimeElapsedString} {t("AvailableForTest")} ({Math.round(percentageTimeUsed)}%)
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        {tisurlLoading ?
                          <Waiting /> :
                          (tisurlError ?
                            <ScopedPopup
                              open={true}
                              persistent={true}
                              title={t("TestSessionErrorTitle")}
                              description={t("TestSessionErrorText")}
                              backdrop="solid"
                            >
                              <Button variant="contained" onClick={() => navigate("/app")}>
                                {t("ReturnToDashboard")}
                              </Button>
                            </ScopedPopup> :
                            <WebsiteTest
                              sessionUrl={testInstanceSessionUrl}
                              toolbarHeight={0}
                              testInstance={store.currentTestInstance}
                            />)}
                      </Grid>
                    </Grid>
                  )}
              </div>
              <div
                role="tabpanel"
                id="tabpanel-2"
                aria-labelledby="tab-2"
                style={{
                  overflowY: "auto",
                  height: "65vh",
                  display: activeTab === 2 ? 'block' : 'none'
                }}
              >
                <Box sx={{ p: 3 }}>
                  {!rubric?.overallScoreDetails && !rubric?.categories ? (
                    <Typography variant="body1">
                      {t("NoScoreProvided")}
                    </Typography>
                  ) : (
                    ""
                  )}
                  {rubric?.overallScoreDetails ? (
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="p" sx={{ fontWeight: "bold" }}>
                          {t("OverallScore")}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl>
                          <RadioGroup
                            aria-labelledby="overallScore-label"
                            value={scorecard?.overallScore}
                          >
                            {rubric?.overallScoreDetails.map((sd) => {
                              return (
                                <FormControlLabel
                                  key={sd.id}
                                  control={<Radio />}
                                  value={
                                    sd.score === scorecard?.overallScore
                                      ? sd.score
                                      : ""
                                  }
                                  label={(
                                    <div style={{ whiteSpace: 'pre-line' }}>
                                      {sd.score + (sd.name !== '' ? " - " +
                                        sd.name : "") + ": " + sd.description}
                                    </div>
                                  )}
                                />
                              );
                            })}
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sx={{ marginBottom: 2 }}>
                        <Typography>
                          {t("Comments") + ": " + (scorecard?.comments || "")}
                        </Typography>
                      </Grid>
                    </Grid>
                  ) : (
                    ""
                  )}
                  {rubric?.categories ? (
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="p" sx={{ fontWeight: "bold" }}>
                          {t("WeightedCategoryScore")}<br />
                        </Typography>
                        <Typography variant="p">
                          {Math.round(scorecard?.weightedCategoryScore * 100) / 100 + " " + t("OutOf") +
                            " " + totalWeightedScore + " (" +
                            Math.round(weightedCategoryScorePercentage) + "%)"}
                        </Typography>

                      </Grid>

                      <Grid item xs={12}>
                        {rubric?.categories.map((c, cindex) => {
                          return (
                            <div key={c.id}>
                              <Grid item xs={12}>
                                <Typography
                                  variant="p"
                                  sx={{ fontWeight: "bold" }}
                                >
                                  {c.name}
                                </Typography>
                                <Typography variant="p">
                                  {" (" + t("Weight") + ": " + c.weight + ")"}
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography variant="body1" style={{ whiteSpace: 'pre-line' }}>
                                  {c.description}
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <FormControl sx={{ marginBottom: 2 }}>
                                  <RadioGroup
                                    aria-labelledby={
                                      c.id + "-" + cindex + "-label"
                                    }
                                    value={
                                      scorecard?.categoryScores.find(cs =>
                                        cs.rubricCategory === c.id
                                      )?.score
                                    }
                                    disabled
                                  >
                                    {c.scoreDetails.map((d) => {
                                      return (
                                        <FormControlLabel
                                          key={d.id}
                                          control={<Radio />}
                                          value={
                                            d.score ===
                                              scorecard?.categoryScores.find(cs =>
                                                cs.rubricCategory === c.id
                                              )?.score
                                              ? d.score
                                              : ""
                                          }
                                          label={(
                                            <div style={{ whiteSpace: 'pre-line' }}>
                                              {d.score + (d.name !== '' ? " - " +
                                                d.name : "") +
                                                ": " + d.description}
                                            </div>
                                          )}
                                        />
                                      );
                                    })}
                                  </RadioGroup>
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} sx={{ marginBottom: 4 }}>
                                <Typography variant="p">
                                  {t("Comments") +
                                    ": " +
                                    (scorecard?.categoryScores.find(cs =>
                                      cs.rubricCategory === c.id
                                    )?.comments || "")}
                                </Typography>
                              </Grid>
                            </div>
                          );
                        })}
                      </Grid>
                    </Grid>
                  ) : (
                    ""
                  )}
                </Box>
              </div>
            </Box>
          </Grid>
        </Grid>
      </>
    );
  } else {
    pageResults = "";
  }
  return pageResults;
};

export default ReviewSubmittedTest;
