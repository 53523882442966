import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useCustomSnackbar } from "../../components/CustomSnackbar";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  Grid,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Collapse,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { Waiting } from "../../components/Waiting";

export default function ChangeEmailDialog(props) {
  const { t } = useTranslation();
  const { lsEnqueueSnackbar } = useCustomSnackbar();
  const [openEmail, setOpenEmail] = useState(false);

  // Move to second block of form
  const [codeWasSent, setCodeWasSent] = useState(false);

  // Handle opening and closing of change email dialog
  const handleClickOpenEmail = () => {
    setOpenEmail(true);
  };
  const handleCloseEmail = () => {
    formikStage1.resetForm();
    setOpenEmail(false);
    setCodeWasSent(false);
  };

  // Set up the changeEmailAddress and confirmEmailAddress mutations
  const CHANGE_EMAIL_ADDRESS = gql`
    mutation ChangeEmailAddress($nea: EmailAddress!) {
      changeEmailAddress(newEmailAddress: $nea)
    }
  `;
  const [changeEmailAddress] = useMutation(CHANGE_EMAIL_ADDRESS);

  const CONFIRM_EMAIL_ADDRESS = gql`
    mutation ConfirmEmailAddress($code: String!) {
      confirmEmailAddress(confirmationCode: $code)
    }
  `;
  const [confirmEmailAddress] = useMutation(CONFIRM_EMAIL_ADDRESS);

  const validationSchemaStage1 = Yup.object({
    email: Yup.string().email(t("MustBeValidEmail")).required(t("Required")),
    emailconfirm: Yup.string()
      .oneOf([Yup.ref("email"), null], t("EmailsMustMatch"))
      .required(t("Required")),
  });

  const formikStage1 = useFormik({
    initialValues: {
      email: "",
      emailconfirm: "",
    },
    validationSchema: validationSchemaStage1,
    onSubmit: async (values, { setSubmitting }) => {
      const vars = {
        variables: {
          nea: values.email,
        },
      };
      // Call the changeEmailAddress API
      changeEmailAddress(vars)
        .then((res) => {
          if (res.data) {
            lsEnqueueSnackbar(t("CodeResent"), {
              variant: "success",
            });
            // Clear Formik form to prevent data from first part of form showing in second
            formikStage1.resetForm();
            // Move to second part of form
            setCodeWasSent(true);
          } else if (res.errors) {
            lsEnqueueSnackbar(res.errors[0].message, {
              variant: "error",
            });
            if (res.errors[0].extensions) {
              switch (res.errors[0].extensions.code) {
                case "EMAIL_IN_USE":
                  break;

                default:
                  handleCloseEmail();
                  break;
              }
            } else {
              // Clear state variable related to movement between form parts and close dialog
              setCodeWasSent(false);
              handleCloseEmail();
            }
          }
        })
        .catch((e) => {
          lsEnqueueSnackbar(e.message, {
            variant: "error",
          });
        }); // catch network errors
      setSubmitting(false);
    },
  });

  const validationSchemaStage2 = Yup.object({
    verificationcode: Yup.string().required(t("Required")),
  });

  const formikStage2 = useFormik({
    initialValues: {
      verificationcode: "",
    },
    validationSchema: validationSchemaStage2,
    onSubmit: async (values, { setSubmitting }) => {
      const vars = {
        variables: {
          code: values.verificationcode.trim(),
        },
      };
      // Finalize the email change by sending the verification code
      confirmEmailAddress(vars)
        .then((res) => {
          if (res.data) {
            lsEnqueueSnackbar(t("EmailChanged"), {
              variant: "success",
            });
            setSubmitting(false);
            handleCloseEmail();
            setCodeWasSent(false);
          } else if (res.errors) {
            lsEnqueueSnackbar(res.errors[0].message, {
              variant: "error",
            });
            if (res.errors[0].extensions) {
              switch (res.errors[0].extensions.code) {
                case "INCORRECT_CODE":
                  setSubmitting(false);
                  break;

                case "EMAIL_IN_USE":
                  setCodeWasSent(false);
                  setSubmitting(false);
                  break;

                default:
                  setCodeWasSent(false);
                  setSubmitting(false);
                  handleCloseEmail();
                  break;
              }
            } else {
              setCodeWasSent(false);
              setSubmitting(false);
              handleCloseEmail();
            }
          }
        })
        .catch((e) => {
          // catch network errors
          lsEnqueueSnackbar(e.message, {
            variant: "error",
          });
        });
    },
  });

  return (
    <React.Fragment>
      <Typography
        component="h1"
        variant="h5"
        sx={{ marginTop: 3, marginBottom: 3 }}
      >
        {t("ChangeEmail")}
      </Typography>

      <Button variant="outlined" color="primary" onClick={handleClickOpenEmail}>
        {t("ChangeEmail")}
      </Button>

      <Dialog
        open={openEmail}
        onClose={handleCloseEmail}
        aria-labelledby="form-dialog-title"
      >
        <Collapse in={!codeWasSent}>
          <form onSubmit={formikStage1.handleSubmit}>
            <DialogTitle id="form-dialog-title">
              {t("ChangeEmail")}
              <IconButton
                aria-label="close"
                sx={{ position: "absolute", right: 1, top: 1, color: "grey" }}
                onClick={handleCloseEmail}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <DialogContentText sx={{ marginBottom: 2 }}>
                {t("ChangeEmailDialogText")}
              </DialogContentText>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    label={t("EmailAddress")}
                    name="email"
                    value={formikStage1.values.email}
                    onChange={formikStage1.handleChange}
                    error={
                      formikStage1.touched.email &&
                      Boolean(formikStage1.errors.email)
                    }
                    helperText={
                      formikStage1.touched.email && formikStage1.errors.email
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="emailconfirm"
                    label={t("ConfirmEmailAddress")}
                    name="emailconfirm"
                    value={formikStage1.values.emailconfirm}
                    onChange={formikStage1.handleChange}
                    error={
                      formikStage1.touched.emailconfirm &&
                      Boolean(formikStage1.errors.emailconfirm)
                    }
                    helperText={
                      formikStage1.touched.emailconfirm &&
                      formikStage1.errors.emailconfirm
                    }
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={handleCloseEmail}
                color="secondary"
                sx={{ marginRight: 0, marginBottom: 1.5 }}
              >
                {t("Cancel")}
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ marginRight: 0, marginBottom: 1.5 }}
              >
                {t("Update")}
              </Button>
            </DialogActions>
            {formikStage1.isSubmitting ? <Waiting /> : ""}
          </form>
        </Collapse>
        <Collapse in={codeWasSent}>
          <form onSubmit={formikStage2.handleSubmit}>
            <DialogTitle id="form-dialog-title">
              {t("ChangeEmail")}
              <IconButton
                aria-label="close"
                sx={{ position: "absolute", right: 1, top: 1, color: "grey" }}
                onClick={handleCloseEmail}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {t("ChangeEmailDialogText")}
              </DialogContentText>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="verificationcode"
                    label={t("VerificationCode")}
                    id="verificationcode"
                    value={formikStage2.values.verificationcode}
                    onChange={formikStage2.handleChange}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={handleCloseEmail}
                color="secondary"
                sx={{ marginRight: 0, marginBottom: 1.5 }}
              >
                {t("Cancel")}
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ marginRight: 0, marginBottom: 1.5 }}
              >
                {t("Update")}
              </Button>
            </DialogActions>
            {formikStage2.isSubmitting ? <Waiting /> : ""}
          </form>
        </Collapse>
      </Dialog>
    </React.Fragment>
  );
}
