import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { CssBaseline, Box, Toolbar } from "@mui/material";
import MainAppBar from "./mainAppBar";
import CheckEmailForm from "./views/User/CheckEmail";
import SignUpForm from "./views/User/SignUp";
import SignInForm from "./views/User/SignIn";
import ForgotPasswordForm from "./views/User/ForgotPassword";
import ResetPasswordForm from "./views/User/ResetPassword";
import AccountDetailsForm from "./views/User/AccountDetails";
import OrgConfigForm from "./views/Owner/org/OrgConfigForm";
import CreateOrgForm from "./views/User/CreateOrg";
import Dashboard from "./dashboard";
import InviteStaffForm from "./views/Owner/InviteStaff";
import NonExistentPage from "./noPageFound";
import ResendCodeSignUpForm from "./views/User/ResendCodeSignUp";
import JoinOrgForm from "./views/User/JoinOrg";
import SignUpInviteeForm from "./views/User/SignUpInvitee";
import SignUpTestTakerForm from "./views/User/SignUpTestTaker";
import ManageUsersForm from "./views/Owner/ManageUsers";
import { useCustomSnackbar } from "./components/CustomSnackbar";
import { autoSignInStages } from "./App";
import { Waiting } from "./components/Waiting";
import CreateTestTemplateForm from "./views/HiringManager/CreateTestTemplate";
import EditTestTemplate from "./views/HiringManager/EditTestTemplate";
import ManageAssetsForm from "./views/HiringManager/ManageAssets";
import lslogo from "./assets/examplery_logo.png";
import ApplicationTermsAndConditions from "./views/User/ApplicationTermsAndConditions";
import AssignCandidateForm from "./views/HiringManager/AssignCandidateForm";
import AssignJurorForm from "./views/HiringManager/AssignJurorForm";
import SubmitTest from "./views/Candidate/SubmitTest";
import MakeReservation from "./views/Candidate/MakeReservation";
import CancelReservationConfirmation from "./views/Candidate/CancelReservation";
import RejectTestConfirmation from "./views/Candidate/RejectTestConfirmation";
import CopyTestTemplate from "./views/HiringManager/CopyTestTemplate";
import TestInstance from "./views/Candidate/TestInstance";
import { useAppContext } from "./context/AppContext";
import ReviewSubmittedTest from "./views/HiringManager/ReviewSubmittedTest";
import MarkTestForm from "./views/Juror/MarkTest";
import TestView from "./views/TestView";
import SendEmailVerifiedSuccessForm from "./views/Owner/SendEmailVerifiedSuccessForm";
import SendEmailVerifiedFailureForm from "./views/Owner/SendEmailVerifiedFailureForm";
import SendEmailVerificationForm from "./views/Owner/SendEmailVerificationForm";
import TestInstructionsBeforeTest from "./views/Candidate/TestInstructionsBeforeTest";

var signUpUsername = "";

// Handle setting of username to be passed into next form where email is verified
const setSignUpUsername = (value) => {
  signUpUsername = value;
};
const getSignUpUsername = () => {
  return signUpUsername;
};

var snackBarMessages = [];

// add a new message to the pending sanckbar message queue
export const enqueueMsg = (msg) => {
  snackBarMessages.push(msg);
};

export default function MainRouter(props) {
  const [currentUsername, setCurrentUsername] = useState(null);
  const { lsEnqueueSnackbar } = useCustomSnackbar();
  const [logo, setLogo] = useState(
    props.siteConfigRef && props.siteConfigRef.current.orgId
      ? props.siteConfigRef.current.logo
      : lslogo
  ); // appbar logo
  const [selectedTab, setSelectedTab] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const drawerWidth = props.signedIn ? 270 : 0;

  const { store } = useAppContext();
  const [orgName, setOrgName] = useState(null);

  const lsEnqueueMsg = (msg) => {
    enqueueMsg(msg);
  };

  // Display the next pending Snackbar message
  useEffect(() => {
    if (snackBarMessages.length) {
      for (let msg of snackBarMessages) {
        // display the message
        lsEnqueueSnackbar(msg.content, { variant: msg.type });
      }
      // clear the messages queue
      snackBarMessages = [];
    }
  }, [lsEnqueueSnackbar]);

  let pageResults;

  if (!props.signedIn) {
    if (
      props.autoSignInStage === autoSignInStages.trying ||
      props.autoSignInStage === autoSignInStages.haveNotTried
    ) {
      pageResults = <Waiting />;
    } else if (props.autoSignInStage === autoSignInStages.tried) {
      pageResults = (
        <Routes>
          <Route
            path="/app/signin"
            element={
              <SignInForm
                signedIn={props.signedIn}
                setSignedIn={props.setSignedIn}
                setWasSignedIn={props.setWasSignedIn}
                signingIn={props.signingIn}
                setSigningIn={props.setSigningIn}
                siteConfig={props.siteConfig}
                setSiteConfig={props.setSiteConfig}
                siteConfigRef={props.siteConfigRef}
                setForceSignOut={props.setForceSignOut}
                enqueueMsg={lsEnqueueMsg}
                getUserProfile={props.getUserProfile}
                getUPData={props.getUPData}
                getUPLoading={props.getUPLoading}
                getUPError={props.getUPError}
              />
            }
          />
          <Route
            path="/app/signup"
            element={
              <SignUpForm
                siteConfig={props.siteConfig}
                setSignUpUsername={setSignUpUsername}
              />
            }
          />
          <Route
            path="/app/signupstaff"
            element={<SignUpInviteeForm siteConfig={props.siteConfig} />}
          />
          <Route
            path="/app/signuptaketest"
            element={<SignUpTestTakerForm siteConfig={props.siteConfig} />}
          />
          <Route
            path="/app/sendemailverifiedsuccess"
            element={
              <SendEmailVerifiedSuccessForm
                siteConfig={props.siteConfig}
                setCurrentLanguage={props.setCurrentLanguage}
              />
            }
          />
          <Route
            path="/app/sendemailverifiedfailure"
            element={
              <SendEmailVerifiedFailureForm
                siteConfig={props.siteConfig}
                setCurrentLanguage={props.setCurrentLanguage}
              />
            }
          />
          <Route
            path="/app/sendemailverification"
            element={
              <SendEmailVerificationForm
                siteConfig={props.siteConfig}
                setCurrentLanguage={props.setCurrentLanguage}
              />
            }
          />
          <Route
            path="/app/terms"
            element={<ApplicationTermsAndConditions />}
          />
          <Route
            path="/app/resetpassword"
            element={
              <ResetPasswordForm
                siteConfig={props.siteConfig}
                currentUsername={currentUsername}
                setCurrentUsername={setCurrentUsername}
              />
            }
          />
          <Route
            path="/app/forgotpassword"
            element={
              <ForgotPasswordForm
                siteConfig={props.siteConfig}
                currentUsername={currentUsername}
                setCurrentUsername={setCurrentUsername}
              />
            }
          />
          <Route
            path="/app/checkemail"
            element={
              <CheckEmailForm
                getSignUpUsername={getSignUpUsername}
                setSignUpUsername={setSignUpUsername}
                siteConfig={props.siteConfig}
              />
            }
          />
          <Route
            path="/app/resendcodesignup"
            element={<ResendCodeSignUpForm siteConfig={props.siteConfig} />}
          />
          <Route
            path="/app/joinOrg"
            element={<JoinOrgForm siteConfig={props.siteConfig} />}
          />
          <Route
            path="/app"
            element={
              <Dashboard
                signedIn={props.signedIn}
                signingIn={props.signingIn}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
              />
            }
          />
          <Route
            path="/"
            element={
              <Dashboard
                signedIn={props.signedIn}
                signingIn={props.signingIn}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
              />
            }
          />
          <Route path="*" element={<NonExistentPage />} />
        </Routes>
      );
    }
  } else if (!store.currentOrg) {
    pageResults = (
      <Routes>
        <Route
          path="/"
          element={
            <Dashboard
              signedIn={props.signedIn}
              signingIn={props.signingIn}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
          }
        />
        <Route
          path="/app"
          element={
            <Dashboard
              signedIn={props.signedIn}
              signingIn={props.signingIn}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
          }
        />
        <Route
          path="/app/account"
          element={
            <AccountDetailsForm
              siteConfig={props.siteConfig}
              getCurrentLanguage={props.getCurrentLanguage}
              setCurrentLanguage={props.setCurrentLanguage}
            />
          }
        />
        <Route
          path="/app/createorganization"
          element={<CreateOrgForm />}
        />
        <Route
          path="/app/joinOrg"
          element={<JoinOrgForm siteConfig={props.siteConfig} />}
        />
        <Route
          path="/app/testInstructionsBeforeTest"
          element={<TestInstructionsBeforeTest />}
        />
        
        <Route
          path="/app/testinstance"
          element={<TestInstance siteConfig={props.siteConfig} />}
        />
        <Route path="/app/submitTest" element={<SubmitTest />} />
        <Route path="/app/reviewtest" element={<ReviewSubmittedTest />} />
        <Route path="/app/makeReservation" element={<MakeReservation />} />
        <Route
          path="/app/cancelReservation"
          element={<CancelReservationConfirmation />}
        />
        <Route
          path="/app/declineTest"
          element={<RejectTestConfirmation mode="decline" />}
        />
        <Route
          path="/app/discardTest"
          element={<RejectTestConfirmation mode="discard" />}
        />
        <Route
          path="/app/revokeTest"
          element={<RejectTestConfirmation mode="revoke" />}
        />
        <Route path="*" element={<NonExistentPage />} />
      </Routes>
    );
  } else {
    pageResults = (
      <Routes>
        <Route
          path="/app/signin"
          element={
            <SignInForm
              signedIn={props.signedIn}
              setSignedIn={props.setSignedIn}
              setWasSignedIn={props.setWasSignedIn}
              signingIn={props.signingIn}
              setSigningIn={props.setSigningIn}
              siteConfig={props.siteConfig}
              setSiteConfig={props.setSiteConfig}
              siteConfigRef={props.siteConfigRef}
              setForceSignOut={props.setForceSignOut}
              enqueueMsg={lsEnqueueMsg}
              getUserProfile={props.getUserProfile}
              getUPData={props.getUPData}
              getUPLoading={props.getUPLoading}
              getUPError={props.getUPError}
            />
          }
        />
        <Route
          path="/app/account"
          element={
            <AccountDetailsForm
              siteConfig={props.siteConfig}
              getCurrentLanguage={props.getCurrentLanguage}
              setCurrentLanguage={props.setCurrentLanguage}
            />
          }
        />
        <Route
          path="/app/createorganization"
          element={<CreateOrgForm />}
        />
        <Route
          path="/app/organization"
          element={
            <OrgConfigForm
              siteConfig={props.siteConfig}
              setSiteConfig={props.setSiteConfig}
              siteConfigRef={props.siteConfigRef}
              orgName={orgName}
              setOrgName={setOrgName}
              logo={logo}
              setLogo={setLogo}
            />
          }
        />
        <Route path="/app/invitestaff" element={<InviteStaffForm />} />
        <Route path="/app/users" element={<ManageUsersForm />} />
        <Route path="/app/assignCandidate" element={<AssignCandidateForm />} />
        <Route path="/app/assignJuror" element={<AssignJurorForm />} />
        <Route
          path="/app/createtesttemplate"
          element={<CreateTestTemplateForm />}
        />
        <Route path="/app/edittesttemplate" element={<EditTestTemplate />} />
        <Route path="/app/copytesttemplate" element={<CopyTestTemplate />} />
        <Route path="/app/reviewtest" element={<ReviewSubmittedTest />} />
        <Route path="/app/marktest" element={<MarkTestForm />} />
        <Route path="/app/assets" element={<ManageAssetsForm />} />
        <Route
          path="/app/testInstructionsBeforeTest"
          element={<TestInstructionsBeforeTest />}
        />
        <Route
          path="/app/testinstance"
          element={<TestInstance siteConfig={props.siteConfig} />}
        />
        <Route path="/app/submitTest" element={<SubmitTest />} />
        <Route
          path="/app/declineTest"
          element={<RejectTestConfirmation mode="decline" />}
        />
        <Route
          path="/app/discardTest"
          element={<RejectTestConfirmation mode="discard" />}
        />
        <Route
          path="/app/revokeTest"
          element={<RejectTestConfirmation mode="revoke" />}
        />
        <Route path="/app/makeReservation" element={<MakeReservation />} />
        <Route
          path="/app/cancelReservation"
          element={<CancelReservationConfirmation />}
        />
        <Route path="/app/testView" element={<TestView />} />
        <Route
          path="/app"
          element={
            <Dashboard
              signedIn={props.signedIn}
              signingIn={props.signingIn}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
          }
        />
        <Route
          path="/"
          element={
            <Dashboard
              signedIn={props.signedIn}
              signingIn={props.signingIn}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
          }
        />
        <Route path="*" element={<NonExistentPage />} />
      </Routes>
    );
  }

  return (
    <Router>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <MainAppBar
          resetAppState={props.resetAppState}
          signedIn={props.signedIn}
          setSignedIn={props.setSignedIn}
          getCurrentLanguage={props.getCurrentLanguage}
          setCurrentLanguage={props.setCurrentLanguage}
          siteConfigRef={props.siteConfigRef}
          logo={logo}
          setLogo={setLogo}
          orgName={orgName}
          forceSignOut={props.forceSignOut}
          setForceSignOut={props.setForceSignOut}
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
        />
        <Box
          sx={{
            flexGrow: 1,
            p: (store.takingTest) ? 0 : 3,
            transition:
              (["margin", "width"],
              {
                easing: "sharp",
                duration: "leavingScreen",
              }),
            ...(drawerOpen && {
              width: `calc(100% - ${drawerWidth}px)`,
              transition:
                (["margin", "width"],
                {
                  easing: "easeOut",
                  duration: "enteringScreen",
                }),
            }),
          }}
        >
          {!store.takingTest && <Toolbar />}
          {pageResults}
        </Box>
      </Box>
    </Router>
  );
}
