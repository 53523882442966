/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import useDroppable from "../../hooks/useDroppable";
import { IconButton, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { FileSystemItemType } from "../FileSystem/FileSystem";
import DescriptionIcon from "@mui/icons-material/Description";
import DeleteIcon from "@mui/icons-material/Delete";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import { useEffect, useState } from "react";

const TestInstanceAnswerDroppable = ({ onDrop, id, name, extension, path, isFolder, onRemoveAnswer }) => {
  const accepts = isFolder
    ? [FileSystemItemType.AssetFolder, FileSystemItemType.CreatedFolder]
    : [FileSystemItemType.File];
  const { dropRef, style } = useDroppable(onDrop, accepts, id, name);

  const [itemName, setItemName] = useState(null);

  useEffect(() => {
    if (path) {
      const pathParts = path.split("/").filter((p) => p);
      setItemName(pathParts[pathParts.length - 1]);
    }
  }, [path]);

  return (
    <>
      <Typography component="label" variant="p">
        {name + " - (" + extension + ")"}
      </Typography>
      <div ref={dropRef} css={styles.dropzone} style={{ ...style }}>
        {path ? (
          <ListItem
            secondaryAction={
              <IconButton aria-label="delete" size="small" onClick={() => onRemoveAnswer(id)}>
                <DeleteIcon />
              </IconButton>
            }>
            <ListItemIcon>{isFolder ? <FolderOpenIcon /> : <DescriptionIcon />}</ListItemIcon>
            <ListItemText primary={itemName} />
          </ListItem>
        ) : (
          <Typography component="p" variant="p">
            Drag your output from the file system
          </Typography>
        )}
      </div>
    </>
  );
};

const styles = (() => {
  const variables = {
    $grey: `rgba(0, 0, 0, .5)`,
  };

  return {
    dropzone: css`
      border-radius: 3px;
      background-image: repeating-linear-gradient(
          0deg,
          ${variables.$grey},
          ${variables.$grey} 23px,
          transparent 23px,
          transparent 43px,
          ${variables.$grey} 43px
        ),
        repeating-linear-gradient(
          90deg,
          ${variables.$grey},
          ${variables.$grey} 23px,
          transparent 23px,
          transparent 43px,
          ${variables.$grey} 43px
        ),
        repeating-linear-gradient(
          180deg,
          ${variables.$grey},
          ${variables.$grey} 23px,
          transparent 23px,
          transparent 43px,
          ${variables.$grey} 43px
        ),
        repeating-linear-gradient(
          270deg,
          ${variables.$grey},
          ${variables.$grey} 23px,
          transparent 23px,
          transparent 43px,
          ${variables.$grey} 43px
        );
      background-size: 3px 100%, 100% 3px, 3px 100%, 100% 3px;
      background-position: 0 0, 0 0, 100% 0, 0 100%;
      background-repeat: no-repeat;

      height: 64px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 16px;
      margin-bottom: 32px;
    `,
  };
})();

export default TestInstanceAnswerDroppable;
