import React, { useEffect, useState } from 'react';
import DOMPurify from 'dompurify';
import { getSiteOrgId } from '../App';
import { useMutation } from '@apollo/client';
import { useAppContext } from '../context/AppContext';
import { Waiting } from './Waiting';
import { GET_ORG_SPLASH_PAGE_DOWNLOAD_URL } from '../graphql/mutations';
const CustomSplashPage = ({
  defaultComponent: DefaultComponent,
}) => {
  const [htmlContent, setHtmlContent] = useState('');
  const [isLoadingSplashPage, setIsLoadingSplashPage] = useState(false);
  const { store } = useAppContext();

  const [getCustomSplashPageDownloadUrl, { loading: customSplashPageUrlLoading }] = useMutation(
    GET_ORG_SPLASH_PAGE_DOWNLOAD_URL
  );

  useEffect(() => {
    async function asyncProcess () {
      setIsLoadingSplashPage(true);
      try {
        let orgId = store.currentOrg?.org.id || getSiteOrgId();
        if (!orgId) {
          setHtmlContent('');
          setIsLoadingSplashPage(false);
          return;
        }
        let res = await getCustomSplashPageDownloadUrl({variables: {orgId}});
        let url = res.data.getOrgSplashPageDownloadURL;
        const response = await fetch(url);
        const data = await response.text();
        const sanitizedHTML = DOMPurify.sanitize(data, {
          FORBID_TAGS: ['script', 'iframe'],
          FORBID_ATTR: ['onload', 'onclick', 'onerror', 'onmouseover', 'onfocus'],
      });
      setHtmlContent(sanitizedHTML);
      setIsLoadingSplashPage(false);
    } catch (e) {
        setHtmlContent('');
        setIsLoadingSplashPage(false);
        console.error(e);
      }
    }
    asyncProcess();
  }, [getCustomSplashPageDownloadUrl, store.currentOrg?.org.id]);

  let pageResult;

  if (customSplashPageUrlLoading || isLoadingSplashPage) {
    pageResult = <Waiting />;
  } else if (htmlContent) {
    pageResult = <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
  } else {
    pageResult = <DefaultComponent />
  }

  return ( pageResult );
};

export default CustomSplashPage;
