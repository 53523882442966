import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useCustomSnackbar } from "../../components/CustomSnackbar";
import { Button, Grid, Typography, TextField } from "@mui/material";
import { Waiting } from "../../components/Waiting";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { initiateSignIn } from "../../identity";
import { withApollo } from "@apollo/client/react/hoc";

function SignInForm(props) {
  const { t } = useTranslation();
  const { lsEnqueueSnackbar } = useCustomSnackbar();
  const navigate = useNavigate();

  const validationSchema = Yup.object({
    username: Yup.string().required(t("Required")),
    password: Yup.string().required(t("Required")),
  });

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const username = values.username;
      const password = values.password;
      // setErrorMsg("");

      // Sign in to the LeadStory server
      try {
        props.setSigningIn(true);
        await initiateSignIn(
          username,
          password,
          props.client,
          props.setForceSignOut,
          props.siteConfigRef,
          props.setSiteConfig,
          props.enqueueMsg
        );
        props.setSignedIn(true);
        props.setWasSignedIn(true);
        navigate("/app");
      } catch (e) {
        if (e.extensions && e.extensions.code === "UNAUTHENTICATED") {
          lsEnqueueSnackbar(t("InvalidCredentials"), {
            variant: "error",
          });
        } else {
          console.log(e);
        }
        props.setSigningIn(false);
        setSubmitting(false);
      }
    },
  });

  return (
    <div>
      {props.signingIn ? <Waiting /> : ""}
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2} paddingLeft="20%" paddingRight="20%">
          <Grid item xs={12}>
            <Typography component="h1" variant="h5" sx={{ marginBottom: 3, fontWeight: "bold" }}>
              {t("SignIn")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="username"
              label={t("UserNameOrEmailAddress")}
              name="username"
              value={formik.values.username}
              onChange={formik.handleChange}
              error={formik.touched.username && Boolean(formik.errors.username)}
              helperText={formik.touched.username && formik.errors.username}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              name="password"
              label={t("Password")}
              type="password"
              id="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
          </Grid>

          <Grid container justifyContent="flex-end">
            <Grid item sx={{ textAlign: "right" }}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                sx={{ marginTop: 3, marginRight: 0, marginBottom: 1.5 }}
              >
                {t("SignIn")}
              </Button>
              <br />
              <Link to="/app/forgotpassword">{t("ForgotPasswordLink")}</Link>
              {/* <br />
              <Link to="/app/signup">{t("NoAccount")}</Link> */}
            </Grid>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

export default withApollo(SignInForm);
