import React, { useState } from "react";
import {
  Button,
  Grid,
  FormControlLabel,
  TextField,
  FormLabel,
  RadioGroup,
  Radio,
  Dialog,
} from "@mui/material";
import ConfirmationForm from "../ConfirmationForm";
import { useTranslation } from "react-i18next";
import { useField } from "formik";

const TestTemplateGeneralInformationForm = React.memo(({
  newTestPlatform,
  setNewTestPlatform,
  handleConfirmChangePlatformClose,
  handleContinue
}) => {
  const { t } = useTranslation();
  const [confirmChangeTestPlatform, setConfirmChangeTestPlatform] = useState(false);
  const [nameField, nameMeta] = useField('name');
  const [descriptionField] = useField('description');
  const [testPlatformField] = useField('testPlatform');

  const handleChangeTestPlatform = (event) => {
    let testPlatform = event.target.value;
    if (testPlatform !== newTestPlatform) {
      setNewTestPlatform(testPlatform);
      setConfirmChangeTestPlatform(true);
    }
  };
  
  const handleConfirmationClose = (response) => {
    setConfirmChangeTestPlatform(false);
    handleConfirmChangePlatformClose(response);
  };

  let pageResults = (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            {...nameField}
            variant="outlined"
            required
            fullWidth
            id="name"
            label={t("Name")}
            error={!!nameMeta.error}
            helperText={nameMeta.error}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            {...descriptionField}
            variant="outlined"
            multiline
            fullWidth
            id="description"
            label={t("Description")}
          />
        </Grid>

        <Grid item xs={12}>
          <FormLabel id="test-platform-radio-label">{t("TestPlatform")}</FormLabel>
          <RadioGroup
            {...testPlatformField}
            aria-labelledby="test-platform-radio-label"
            id="testPlatform"
            onChange={handleChangeTestPlatform}
          >
            <FormControlLabel value="appstream" control={<Radio />} label={t("AppStream")} />
            <FormControlLabel value="website" control={<Radio />} label={t("WebSite")} />
          </RadioGroup>
        </Grid>

        <Grid item xs={12}>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleContinue}
                sx={{ marginTop: 1 }}
              >
                {t("Continue")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Dialog
        open={confirmChangeTestPlatform}
        onClose={handleConfirmationClose}
      >
        <ConfirmationForm
          handleConfirmationClose={handleConfirmationClose}
          dialogTitle={t("ChangeTestPlatformTitle")}
          dialogContent={t("ChangeTestPlatformExplanation")}
        />
      </Dialog>
    </React.Fragment>
  );
  return pageResults;
});

export default TestTemplateGeneralInformationForm;