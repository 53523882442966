import { useState, useEffect } from 'react';
import {
  Button,
  Grid,
  TextField,
  Chip
} from "@mui/material";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { t } from "i18next";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_TAGS } from "../../graphql/queries";
import { CREATE_TAG } from "../../graphql/mutations"
import { useAppContext } from "../../context/AppContext";
import { useCustomSnackbar } from "../CustomSnackbar";
import { handleCacheUpdate } from "../../cache";

const filter = createFilterOptions();


function TagControl({
  startInEditMode = true,
  readOnly = false,
  tags = [],
  setTags,
  onApplyTag,
  onRemoveTag,
  disabled = false
}) {

  const [editMode, setEditMode] = useState(startInEditMode && !readOnly);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [autocompleteValue, setAutocompleteValue] = useState(''); 
  const [gotTagsAutomatically, setGotTagsAutomatically] = useState(false);



  const { store } = useAppContext();
  const { lsEnqueueSnackbar } = useCustomSnackbar();

  const [createTag] = useMutation(CREATE_TAG, {
    update: (cacheClient, response) => {
      if (response?.data) {
        handleCacheUpdate(
          "create",
          cacheClient,
          response.data.createTag,
          "id",
          GET_TAGS,
          "getTags",
          null,
          { orgId: store.currentOrg?.org.id }
        );
      }
    },
  });

  // Get the list of available tags
  const [getTags, {
    loading: gtLoading,
  }] = useLazyQuery(GET_TAGS, {
    variables: {
      orgId: store.currentOrg?.org.id
    },
    fetchPolicy: "cache-and-network",
    onError: (e) => {
      lsEnqueueSnackbar(e.message, {
        variant: "error",
      });
    },
    onCompleted: (data) => {
      let newOptions = data?.getTags || [];
      setOptions(newOptions);
    }
  });
  
  useEffect(() => {
    if (startInEditMode && !readOnly && !gotTagsAutomatically) {
      getTags();
      setGotTagsAutomatically(true);
    }
  }, [startInEditMode, readOnly, gotTagsAutomatically, getTags]);

  const handleCreateTag = async () => {
    const name = inputValue.trim();
    if (name !== '') {
      let resp;
      try {
        resp = await createTag({
          variables: {
            orgId: store.currentOrg?.org.id,
            tag: {
              name: name
            }
          }
        });
        return resp.data.createTag;
      } catch (e) {
        console.error(`error creating tag: '${name}' for org: ${store.currentOrg?.org.id}`, e);
        lsEnqueueSnackbar(e.message, {
          variant: "error",
        });
      }
    }
  };

  const handleApplyTag = (tag) => {
    if (onApplyTag) {
      onApplyTag(tag);
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    setTags((prevTags) => prevTags.filter((tag) => tag !== tagToRemove));
    if (onRemoveTag) {
      onRemoveTag(tagToRemove);
    }
  };

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  const handleEditTags = () => {
    getTags();
    setEditMode(true);
  };

  const handleCloseEdit = () => {
    setEditMode(false);
  };

  const handleTagSelect = async (event, newValue) => {
    setAutocompleteValue(''); 

    if (newValue && newValue.inputValue) {
      if (inputValue.trim() !== '') {
        let createdTag = await handleCreateTag();
        if (createdTag) {
          setTags([...tags, createdTag]);
          setOptions([...options, createdTag]);
          handleApplyTag(createdTag);
        }
      }
    } else if (newValue && !tags.includes(newValue) && options.includes(newValue)) {
      setTags([...tags, newValue]);
      handleApplyTag(newValue);
    }
    setInputValue(''); 
  };

  return (
    <Grid item xs={12}>
      {tags.map((tag) => (
        <Chip
          key={tag.id}
          label={tag.name}
          onDelete={editMode ? () => handleRemoveTag(tag) : undefined}
          style={{ margin: '0.2rem' }}
        />
      ))}
      {!editMode && !readOnly && (
        <Button 
          variant="text" 
          onClick={handleEditTags} 
          disabled={disabled} 
          sx={{ ml: tags?.length ? '1rem' : 0 }}
        >
          {t("EditTags")}
        </Button>
      )}
      {editMode && (
        <Grid container justifyContent="flex-start" alignItems="center" spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              readOnly={!editMode}
              freeSolo
              loading={gtLoading}
              options={options}
              onChange={handleTagSelect}
              inputValue={inputValue}
              value={autocompleteValue} 
              onInputChange={handleInputChange}
              getOptionLabel={(option) => option.name || ''}
              renderInput={(params) => (
                <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                  <Grid item xs={10} sx={{mt: '1rem'}}>
                    <TextField
                      {...params}
                      variant="outlined"
                      label={t("Tags")}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Button variant="text" onClick={handleCloseEdit} style={{ marginTop: '1rem' }}>
                      {t("Close")}
                    </Button>
                  </Grid>
                </Grid>
              )}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                const { inputValue } = params;

                // Suggest the creation of a new value
                // const isExisting = options.some((option) => inputValue === option?.label);
                const isExisting = options.some((option) => inputValue === option?.name);
                if (inputValue !== '' && !isExisting) {
                  filtered.push({
                    inputValue,
                    name: t("AddNewTag", { newTagName: inputValue })
                  });
                }
                return filtered;
              }}
              disabled={disabled}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

export default TagControl;
