import React, { useState, useEffect } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Avatar,
  Button,
  Grid,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Waiting } from "../../components/Waiting";
import { useCustomSnackbar } from "../../components/CustomSnackbar";
import { put as axiosput } from "axios";
import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../context/AppContext";
import SelectFileButton from "../../components/FileSystem/SelectFileButton";

export default function AvatarForm(props) {
  const { actions, store } = useAppContext();
  const { t } = useTranslation();
  const { lsEnqueueSnackbar } = useCustomSnackbar();
  const origAvatar = store.userDetails ? store.userDetails.user.avatar : "";
  const [input, setInput] = useState({});
  const [avatarLoaded, setAvatarLoaded] = useState(false);
  const [openAvatar, setOpenAvatar] = useState(false);
  const [newAvatarSrc, setNewAvatarSrc] = useState("");
  const [newAvatarSrcFile, setNewAvatarSrcFile] = useState(null);
  const [avatarSrc, setAvatarSrc] = useState("");
  const [avatarImageUploading, setAvatarImageUploading] = useState(false);

  // Ensure avatar becomes visible where it is displayed upon being set
  useEffect(() => {
    if (store.userDetails) {
      setAvatarSrc(store.userDetails.user.avatar);
    }
  }, [store.userDetails]);

  useEffect(() => {
    if (!avatarLoaded && store.userDetails.user.avatar !== "") {
      setInput({ ...input, avatarPath: store.userDetails.user.avatar });
      setAvatarLoaded(true);
    }
  }, [avatarLoaded, props, input, store.userDetails]);

  // Set up the get Avatar Upload URL mutation
  const GET_AVATAR_UPLOAD_URL = gql`
    mutation GetAvatarUploadURL($av: FileInfo!) {
      getAvatarUploadURL(avatar: $av) {
        uploadURL
        downloadURL
      }
    }
  `;

  const [getAvatarUploadURL, { loading: getAvatarUploadURLLoading }] =
    useMutation(GET_AVATAR_UPLOAD_URL);

  // Handle selection of a new avatar. The client request the avatar upload URL and then
  // uploads the file.
  const handleConfirmAvatar = async () => {
    if (newAvatarSrc !== "") {
      const vars = {
        variables: {
          av: {
            size: newAvatarSrcFile.size,
            mimetype: newAvatarSrcFile.type,
          },
        },
      };

      setAvatarImageUploading(true);
      // get the upload (and download) urls
      let result;
      try {
        result = await getAvatarUploadURL(vars);
      } catch (e) {
        lsEnqueueSnackbar(e.message, {
          variant: "error",
        });
      }
      if (!getAvatarUploadURLLoading) {
        if (result.errors && result.errors.length !== 0) {
          lsEnqueueSnackbar(result.errors[0].message, {
            variant: "error",
          });
        } else {
          const uploadURL = result.data.getAvatarUploadURL.uploadURL;
          const downloadURL = result.data.getAvatarUploadURL.downloadURL;

          // extract the cache-control setting from the upload URL
          let cacheControl = uploadURL.substr(uploadURL.indexOf("max-age%3D"));
          cacheControl = cacheControl.substr(
            "max-age%3D".length,
            cacheControl.indexOf("&") - "max-age%3D".length
          );

          // upload the image using the upload URL
          const options = {
            headers: {
              "Content-Type": newAvatarSrcFile.type,
              "x-amz-acl": "public-read",
              "cache-control": "max-age=" + cacheControl,
            },
          };

          try {
            await axiosput(uploadURL, newAvatarSrcFile, options);
          } catch (e) {
            lsEnqueueSnackbar(e.message, {
              variant: "error",
            });
          }
          setAvatarSrc(downloadURL);

          let tempUserProfile = { ...store.userDetails };
          tempUserProfile.user.avatar = downloadURL;
          actions.setUserDetails(tempUserProfile);
        }
      }
      setNewAvatarSrc("");
      setNewAvatarSrcFile(null);
      setAvatarImageUploading(false);
    }
    setOpenAvatar(false);
  };

  // Manage opening and closing of avatar dialog
  const handleClickOpenAvatar = () => {
    setOpenAvatar(true);
  };

  const handleCloseAvatar = () => {
    setInput({ ...input, avatarPath: origAvatar });
    setOpenAvatar(false);
  };

  // Populate new avatar on interaction with input field
  const handleInputChange = (e) => {
    if (e.target) {
      let value = e.target.value;
      if (e.target.name === "avatarPath") {
        if (
          e.target.files &&
          e.target.files.length !== 0 &&
          e.target.validity.valid
        ) {
          value = URL.createObjectURL(e.target.files[0]);
          setNewAvatarSrc(value);
          setNewAvatarSrcFile(e.target.files[0]);
          setInput({ ...input, [e.target.name]: value });
        }
      } else {
        setInput({ ...input, [e.target.name]: value });
      }
    }
  };

  return (
    <React.Fragment>
      <Grid container sx={{ marginBottom: 3 }}>
        <Grid item xs={1}>
          <Avatar
            alt={
              store.userDetails.user.firstname +
              " " +
              store.userDetails.user.lastname
            }
            src={avatarSrc}
          />
        </Grid>
        <Grid item xs={11}>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleClickOpenAvatar}
          >
            {t("ChangeAvatar")}
          </Button>
        </Grid>
      </Grid>
      <Dialog
        open={openAvatar}
        onClose={handleCloseAvatar}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {t("ChangeAvatar")}
          <IconButton
            aria-label="close"
            sx={{ position: "absolute", right: 1, top: 1, color: "grey" }}
            onClick={handleCloseAvatar}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ marginBottom: 2 }}>
            {t("ChangeAvatarText")}
          </DialogContentText>
          <Box sx={{ marginLeft: "30%" }}>
            <Avatar
              alt={
                store.userDetails.user.firstname +
                " " +
                store.userDetails.user.lastname
              }
              src={input.avatarPath}
              sx={{ width: 100, height: 100 }}
            />
            {getAvatarUploadURLLoading || avatarImageUploading ? (
              <Waiting />
            ) : (
              ""
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <SelectFileButton
            onOpen={handleInputChange}
            title={t("SelectImage")}
            buttonVariant="contained"
            accept="image/*"
            inputName="avatarPath"
            buttonProps={{ sx: { marginRight: 1, marginBottom: 1.5 } }}
          />
          <Button
            onClick={handleConfirmAvatar}
            variant="contained"
            color="primary"
            sx={{ marginRight: 0, marginBottom: 1.5 }}
          >
            {t("Confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
